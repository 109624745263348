import { IResultWorldCheckHistoryDAO } from '../Modules/WorldCheck/DAO/worldCheck.dao';
import instance from '../Services/interceptor';

export const getWorldCheckListAPI = async (): Promise<
  IResultWorldCheckHistoryDAO[]
> => {
  const { data } = await instance.get(
    `/Candidate/file/upload/history?uploadHistoryType=WorldCheck`
  );
  return data;
};

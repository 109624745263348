import React from 'react';
import { FormattedMessage } from 'react-intl';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Alert, Col, Row } from 'antd';
import moment from 'moment';

interface IProps {
  selectedData: any;
  modalInfoIsShow: boolean;
  handleCancel: () => void;
}
export default function CityHistoryDetailModalComponent(props: IProps) {
  const { selectedData, modalInfoIsShow, handleCancel } = props;
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="cityUploadModalResult" />
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    if (selectedData.uniqueID) {
      let totalError = 0;
      const selectedStatus =
        Object.keys(selectedData.regionFailedDetails).length > 0
          ? 'regionFailedDetails'
          : 'regionSuccessDetails';
      const status =
        selectedStatus === 'regionFailedDetails' ? 'error' : 'success';

      const errMessage = selectedData[selectedStatus];
      let errMessageLoop: any = null;
      if (errMessage !== null) {
        errMessageLoop = errMessage.map((key: any, index: any) => {
          totalError++;
          return (
            <li key={index} value={key}>
              {key.errorMessage}
            </li>
          );
        });
      }
      return (
        <React.Fragment>
          <div className="divInfoUpload">
            <Row>
              <Col span={6} className="titleHistoryDetailUpload">
                File Name
              </Col>
              <Col span={1}>:</Col>
              <Col span={17}>{errMessage[0].originalFilename}</Col>
            </Row>
            <Row>
              <Col span={6} className="titleHistoryDetailUpload">
                Upload Date
              </Col>
              <Col span={1}>:</Col>
              <Col span={17}>
                {moment(errMessage[0].uploadDate).format('DD-MM-YYYY HH:mm:ss')}
              </Col>
            </Row>
            <Row>
              <Col span={6} className="titleHistoryDetailUpload">
                Status
              </Col>
              <Col span={1}>:</Col>
              <Col span={17}>{status === 'error' ? 'failed' : 'success'}</Col>
            </Row>
            {errMessage !== null ? (
              <Alert
                className="alertErrorModuleUploadHistory"
                message={`${totalError} row data ${status} to upload`}
                type={status}
                showIcon
              />
            ) : null}
            {selectedStatus === 'regionFailedDetails' ? (
              <ul>{errMessageLoop}</ul>
            ) : null}
          </div>
        </React.Fragment>
      );
    }
    return null;
  };
  return (
    <CModalRFF
      modalIsShow={modalInfoIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={() => {}}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={{}}
    />
  );
}

import React from 'react';
import { Alert, Col, Row } from 'antd';
import CModalRFF from '../../../../Assets/Components/CModalRFF';

interface IProps {
  handleCancel: () => void;
  showModal: boolean;
  licenseDetailUpload: any;
}

export default function ModalUploadDetailLicenseComponent(props: IProps) {
  const { handleCancel, showModal, licenseDetailUpload } = props;

  const detailUploadValue = licenseDetailUpload ? licenseDetailUpload : '';
  const uploadStatus =
    detailUploadValue.uploadStatus === 'Failed' ? 'error' : 'success';

  const renderDetailUpload = (label: string, value: string) => {
    return (
      <Row style={{ marginBottom: 5 }}>
        <Col span={8}>{label}</Col>
        <Col span={1}>:</Col>
        <Col span={10}>{value}</Col>
      </Row>
    );
  };

  const notifyUploadStatus = () => {
    if (detailUploadValue.uploadStatus === 'Success') {
      return <span>File uploaded successfully</span>;
    } else {
      return (
        <span>{`${detailUploadValue.totalError} row data failed to upload`}</span>
      );
    }
  };

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Detail Unggahan</span>
      </h3>
    );
  };

  const renderLooping = () => {
    const dataComponent: any = [];
    for (const key in detailUploadValue.errorMessage) {
      if (
        Object.prototype.hasOwnProperty.call(
          detailUploadValue.errorMessage,
          key
        )
      ) {
        const element = detailUploadValue.errorMessage[key];
        dataComponent.push(
          element === '' ? null : (
            <ul key={key}>
              <li>{element}</li>
            </ul>
          )
        );
      }
    }
    return dataComponent;
  };

  const contentComponent = () => {
    return (
      <>
        {renderDetailUpload('Nama Berkas', detailUploadValue.originalFilename)}
        {renderDetailUpload('Format', detailUploadValue.formatFile)}
        {renderDetailUpload('Status Unggah', detailUploadValue.uploadStatus)}
        {renderDetailUpload('Tanggal Unggah', detailUploadValue.uploadDate)}
        <hr className="lineUploadLicense" />
        <Alert
          className="alertErrorModuleUploadHistory"
          message={notifyUploadStatus()}
          type={uploadStatus}
          showIcon
        />
        {renderLooping()}
      </>
    );
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      initialValues={null}
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      isForm={false}
      formLayout="vertical"
    />
  );
}

import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import ModalLibraryFileComponent from '../Component/ModaLibraryFileComponent';
import { mutateFileLibraryAPI } from '../../../Services/library.api';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { IntlShape } from 'react-intl';
import { IResultLibraryListDAO } from '../DAO/library.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  list: any;
  libraryList: IResultLibraryListDAO[];
  intl: IntlShape;
  modalUploadIsShow: boolean;
  selectedPath: string;
  selectedData: any;
  refetch: any;
  setSelectedMovePath: any;
  setModalUploadIsShow: Dispatch<SetStateAction<boolean>>;
  setLibrarySelected: Dispatch<SetStateAction<never[]>>;
  setIsRoot: Dispatch<SetStateAction<boolean>>;
  setSelectedData: Dispatch<SetStateAction<undefined>>;
  setModalMoveShow: Dispatch<SetStateAction<boolean>>;
  setLibraryCollection: Dispatch<SetStateAction<undefined>>;
  setMoveType: Dispatch<SetStateAction<string>>;
}

export default function ModalLibraryFileContainer(props: IProps) {
  const {
    list,
    intl,
    modalUploadIsShow,
    setModalUploadIsShow,
    selectedPath,
    setLibrarySelected,
    setIsRoot,
    selectedData,
    setSelectedData,
    refetch,
    setModalMoveShow,
    setLibraryCollection,
    setSelectedMovePath,
    setMoveType,
  } = props;
  const [totalUpload, setTotalUpload] = useState(1);
  const [initialValues, setinitialValues] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const {
    mutate: mutateFile,
    data: listFile,
    isLoading: isLoadingFile,
  } = useMutation(mutateFileLibraryAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        <span className="toastSuccessBackground">
          {!selectedData ? (
            <FormattedMessage id="createFileSuccess" />
          ) : (
            <FormattedMessage id="updateFileSuccess" />
          )}
        </span>
      );
      refetch();
      setIsRoot(true);
      setModalUploadIsShow(false);
      setTotalUpload(1);
      setEditMode(false);
      setinitialValues(null);
      setSelectedData(undefined);
    },
  });

  useEffect(() => {
    if (listFile) {
      setLibrarySelected(listFile);
    }
    // eslint-disable-next-line
  }, [listFile]);

  const initialValue: any = {};
  useEffect(() => {
    if (selectedData) {
      initialValue[`embededLinkTitle-0`] = selectedData.title
        ? selectedData.title
        : '';
      initialValue[`embededLink-0`] = selectedData.link;
      initialValue[`isPublish-0`] = selectedData.status;
      initialValue[`startDate-0`] = selectedData.startDate;
      initialValue[`endDate-0`] = selectedData.endDate;
      initialValue[`name-0`] = selectedData.name;
      initialValue[`size-0`] = selectedData.size;
      setinitialValues(initialValue);
    } else {
      setinitialValues(null);
    }
    // eslint-disable-next-line
  }, [selectedData]);

  const onSubmit = (values: any) => {
    let payloadUpdate: any = {};
    let payloadSubmit: any = {};
    for (let currentUpload = 0; currentUpload < totalUpload; currentUpload++) {
      if (!selectedData) {
        const Title = values[`embededLinkTitle-${currentUpload}`];
        const Link = values[`embededLink-${currentUpload}`];
        const Status = !values[`isPublish-${currentUpload}`]
          ? values[`isPublish-${currentUpload}`] === false
          : true;
        const StartDateFormatted = moment(
          values[`startDate-${currentUpload}`]?._d
        ).format();
        const endDateFormatted = moment(
          values[`endDate-${currentUpload}`]?._d
        ).format();
        const Size = values[`uploadLibrary-${currentUpload}`]?.[0]?.size;
        const Type = values[`uploadLibrary-${currentUpload}`]?.[0]?.type;
        const Name = values[`uploadLibrary-${currentUpload}`]?.[0]?.name;
        const StartDate = StartDateFormatted.split('+')?.[0];
        const EndDate = endDateFormatted.split('+')?.[0];
        const File = values[`uploadLibrary-${currentUpload}`]?.[0];

        payloadSubmit = {
          Title: Title ? Title : null,
          Link: Link ? Link : null,
          Status: Status ? Status : false,
          StartDate: values[`startDate-${currentUpload}`] ? StartDate : '',
          EndDate: values[`endDate-${currentUpload}`] ? EndDate : '',
          Path:
            selectedPath === 'Home' ? '/' : selectedPath.replace(/Home/g, ''),
          Size: Size ? Size : 0,
          Type: Type ? Type : null,
          Name: Name ? Name : Title ? Title : null,
          File: File ? File : null,
        };
      } else {
        const title = values[`embededLinkTitle-0`];
        const link = values[`embededLink-0`];
        const startDate = values[`startDate-0`];
        const endDate = values[`endDate-0`];
        const status = values[`isPublish-0`];
        payloadUpdate = {
          title: title ? title : '',
          link: link ? link : '',
          startDate: values[`startDate-0`] ? startDate : '',
          endDate: values[`endDate-0`] ? endDate : '',
          status: status ? status : false,
        };
      }
      mutateFile({
        payloadSubmit,
      });
    }
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      if (selectedData?.id) {
        mutateFile({
          payloadUpdate,
          id: selectedData?.id,
        });
      }
    }
  };

  const handleCancel = () => {
    setModalUploadIsShow(false);
    setTotalUpload(1);
    setinitialValues(null);
    setSelectedData(undefined);
    setEditMode(false);
    setSelectedMovePath(['Home']);
  };

  const handleAddFile = () => {
    setTotalUpload((prev: number) => prev + 1);
  };

  const handleMove = () => {
    const filteredData: any = [];
    for (const key in list) {
      if (Object.prototype.hasOwnProperty.call(list, key)) {
        const element = list[key];
        if (element.type === 'folder') {
          filteredData.push(element);
        }
      }
    }
    setMoveType('file');
    setLibraryCollection(filteredData);
    setModalMoveShow(true);
    setSelectedMovePath(['Home']);
  };

  return (
    <ModalLibraryFileComponent
      intl={intl}
      handleCancel={handleCancel}
      modalUploadIsShow={modalUploadIsShow}
      totalUpload={totalUpload}
      handleAddFile={handleAddFile}
      onSubmit={onSubmit}
      isLoadingFile={isLoadingFile}
      initialValues={initialValues}
      selectedData={selectedData}
      editMode={editMode}
      setTotalUpload={setTotalUpload}
      handleMove={handleMove}
    />
  );
}

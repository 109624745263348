import { Col, Row, Steps } from 'antd';

import CCard from '../CCard';
import React from 'react';
import { convertObjectToArray } from '../../../App/Helper';
import { removeElemenIsHidden } from './ConvertObjecyToArray';
import CIcon from '../CIcon';
import CButton from '../CButton';
import { renderComponentForm } from './renderComponent';

const { Step } = Steps;
interface IProps {
  schema: any;
  footerStep?: any;
  formModulName?: any;
  dataButton?: any;
  propsDynamic?: any;
  handlers?: any;
  modalAction?: string;
  currentSteps?: any;
  handleStep?: any;
  documentList?: any;
  documents?: any;
  formAction?: any;
  isEdited?: boolean;
  globalValidation?: any;
  disableSaveButton?: any;
  disabledCandidateLevel?: any;
  disabledRecruiterDL?: any;
  disabledAgency?: any;
  totalOtherattachment?: any;
  handleNext?: () => void;
  handleBack?: () => void;
  handleEdit?: any;
  handleSave?: any;
  handleSelectHaveNPWP?: any;
  onClickOtherDocument?: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  handleOnChange: (values: any, form: any) => void;
  additionalOnChange: (domicileaddress: any, formValue: any, form: any) => void;
}

export default function DynamicForm(props: IProps) {
  const {
    schema,
    footerStep,
    formModulName,
    dataButton,
    propsDynamic,
    currentSteps,
    handleStep,
    documentList,
    documents,
    formAction,
    isEdited,
    handlers,
    modalAction,
    disabledCandidateLevel,
    disabledRecruiterDL,
    disabledAgency,
    disableSaveButton,
    globalValidation,
    totalOtherattachment,
    handleNext,
    handleBack,
    handleEdit,
    handleSave,
    handleSelectHaveNPWP,
    onClickOtherDocument,
    handleUploadContent,
    handleOnChange,
    additionalOnChange,
  } = props;
  const addConditionInArray = (list: any) => {
    const newList: any = [];
    list.forEach((data: any, index: number) => {
      if (data.type === 'object') {
        newList.splice(index, 1);
        const newComponentList = convertObjectToArray(
          data.properties,
          data.name
        );
        newList.push(newComponentList);
      } else {
        newList.push(data);
      }
    });
    return newList;
  };

  const listStepData = removeElemenIsHidden(
    schema.properties,
    currentSteps,
    modalAction,
    propsDynamic,
    disableSaveButton,
    isEdited,
    globalValidation
  );

  const renderButton = (list: any) => {
    const listLength = list.length;
    const contentButtonNext = () => {
      if (listLength - 1 !== currentSteps) {
        return (
          <div>
            Lanjutkan
            <CIcon type="ArrowRightOutlined" style={{ marginLeft: '3px' }} />
          </div>
        );
      }
      return <div>Kirim</div>;
    };

    const dataButton = [
      isEdited && modalAction !== 'register'
        ? {
            type: 'primary',
            onClick: () => handleSave(propsDynamic.values),
            content: 'Simpan',
            id: 'btnSaveForm',
            className: 'btnSaveRegsitration',
            loading: propsDynamic.isLoading,
            disabled: globalValidation,
            htmlType: 'submit',
          }
        : null,
      (modalAction === 'register' && currentSteps !== 0) ||
      (!isEdited && currentSteps !== 0)
        ? {
            type: 'primary',
            onClick: handleBack,
            content: 'Kembali',
            id: 'btnBackForm',
            className: 'btnBackRegsitration',
            icon: 'ArrowLeftOutlined',
          }
        : null,
      !isEdited && formAction === 'update'
        ? {
            type: 'primary',
            onClick: handleEdit,
            content: 'Edit',
            id: 'btnEditForm',
            className: 'btnEditRegsitration',
            icon: 'EditOutlined',
          }
        : null,
      modalAction === 'register' ||
      (!isEdited && listLength - 1 !== currentSteps)
        ? {
            type: 'primary',
            onClick:
              listLength - 1 !== currentSteps
                ? handleNext
                : () => handleSave(propsDynamic.values),
            content: contentButtonNext(),
            id: 'btnNextForm',
            className: 'btnNextRegistration',
            htmlType: 'submit',
            disabled:
              modalAction === 'register'
                ? disableSaveButton(currentSteps)
                : false,
          }
        : null,
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderComponent = (formName: string, data: any, element: any) => {
    return renderComponentForm(
      formName,
      data,
      element,
      formModulName,
      disabledCandidateLevel,
      disabledRecruiterDL,
      disabledAgency,
      propsDynamic,
      documentList,
      documents,
      isEdited,
      handleSelectHaveNPWP,
      totalOtherattachment,
      onClickOtherDocument,
      handlers,
      modalAction,
      handleUploadContent,
      handleOnChange,
      additionalOnChange
    );
  };

  const renderStep = (list: any) => {
    if (list instanceof Array) {
      return list.map(data => {
        return (
          <Step
            key={data.key}
            title={data.title}
            description={data.description}
            icon={data.icon}
            disabled={data.disabled}
          />
        );
      });
    }
    return null;
  };
  const renderHeaderCard = (title: string, desc: any) => {
    return (
      <div>
        <div className="titleCardRegistration">{title}</div>
        <div className="descCardRegistraition">{desc}</div>
      </div>
    );
  };
  const renderBodyCard = () => {
    return (
      <div className="containerCardBodyRegistration">
        {renderFormRegistration(
          listStepData[currentSteps].key,
          listStepData[currentSteps].content
        )}
        {renderButton(listStepData)}
        {footerStep ? (
          <div className="containerStepsRegistration">
            <Steps
              current={currentSteps}
              labelPlacement="vertical"
              onChange={handleStep}
            >
              {renderStep(listStepData)}
            </Steps>
          </div>
        ) : null}
      </div>
    );
  };
  const renderFormRegistration = (formName: string, content: any) => {
    const properties = content.properties;
    const listKeyContent =
      formName === 'documents'
        ? ['documents']
        : Object.keys(content.properties);
    return listKeyContent.map(data => {
      const listComponent = convertObjectToArray(
        formName === 'documents' ? properties : properties[data].properties
      );
      if (data === 'addresseducation') {
        const listRenderComponent = addConditionInArray(listComponent);
        const listIdLeft: any = [];
        const listIdRight: any = [];
        const listDomicileLeft: any = [];
        const listDomicileRight: any = [];
        for (const iterator of listRenderComponent) {
          const { name } = iterator;
          switch (name) {
            case 'idcardaddress':
            case 'province':
            case 'city': {
              listIdLeft.push(iterator);
              break;
            }
            case 'district':
            case 'subdistrict':
            case 'postalcode': {
              listIdRight.push(iterator);
              break;
            }
            case 'domicileaddress':
            case 'provincedomicile':
            case 'citydomicile':
            case 'formaleducation': {
              listDomicileLeft.push(iterator);
              break;
            }
            case 'districtdomicile':
            case 'subdistrictdomicile':
            case 'postalcodedomicile': {
              listDomicileRight.push(iterator);
              break;
            }
          }
        }
        return (
          <div
            key={`section${data}`}
            className="containerComponentRegistration"
          >
            <div className="titleSectionRegis">
              {formName === 'documents'
                ? content.title
                : properties[data].title}
            </div>
            <>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    {listIdLeft.map((element: any) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    {listIdRight.map((element: any) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    {listDomicileLeft.map((element: any) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    {listDomicileRight.map((element: any) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </>
            <hr className="lineSectionRegis" />
          </div>
        );
      } else if (data === 'taxbankaccount') {
        const listAddres = addConditionInArray(listComponent);
        const listBank: any = [];
        const listTax: any = [];
        for (const iterator of listAddres) {
          if (iterator instanceof Array) {
            for (const element of iterator) {
              if (element.secondName === 'bank') {
                listBank.push(element);
              } else if (element.secondName === 'tax') {
                listTax.push(element);
              }
            }
          } else if (iterator.type && iterator.type === 'boolean') {
            listTax.push(iterator);
          }
        }

        return (
          <div
            key={`section${data}`}
            className="containerComponentRegistration"
          >
            <div className="titleSectionBank">
              {formName === 'documents'
                ? content.title
                : properties[data].title}
            </div>
            <p className="descSectionBank">
              *Pemilik Rekening Adalah Agen Yang Bersangkutan
            </p>
            <>
              <Row gutter={32}>
                <Col span={12}>
                  <Row>
                    {listBank.map((element: any, index: number) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
                <Col span={12}>
                  <Row>
                    {listTax.map((element: any, index: number) => {
                      return (
                        <Col key={`component${element.name}`} span={24}>
                          {renderComponent(formName, data, element)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              </Row>
            </>
          </div>
        );
      }
      return (
        <div key={`section${data}`} className="containerComponentRegistration">
          <div className="titleSectionRegis">
            {formName === 'documents' ? content.title : properties[data].title}
          </div>
          {renderContainerComponent(
            formName,
            data,
            addConditionInArray(listComponent)
          )}
          <hr className="lineSectionRegis" />
        </div>
      );
    });
  };
  const renderContainerComponent = (
    formName: string,
    sectionName: string,
    listComponent: any
  ) => {
    return (
      <>
        <Row gutter={32}>
          {listComponent.map((element: any, index: number) => {
            if (element instanceof Array) {
              return (
                <Col key={`object${element[index].name}`} span={24}>
                  <Row gutter={32}>
                    {element.map(data => {
                      if (
                        data.config &&
                        data.config.web &&
                        data.config.web.isHidden
                      ) {
                        return null;
                      }
                      return (
                        <Col key={`component${data.name}`} span={12}>
                          {renderComponent(formName, sectionName, data)}
                        </Col>
                      );
                    })}
                  </Row>
                </Col>
              );
            } else {
              if (
                element.config &&
                element.config.web &&
                element.config.web.isHidden
              ) {
                return null;
              }
              return (
                <Col
                  key={`component${element.name}`}
                  span={
                    sectionName === 'list' ||
                    element.name === 'otherattachments'
                      ? 24
                      : 12
                  }
                >
                  {renderComponent(formName, sectionName, element)}
                </Col>
              );
            }
          })}
        </Row>
        {sectionName === 'form' ? (
          <CButton buttonData={dataButton[formName]} />
        ) : null}
      </>
    );
  };
  return (
    <div>
      <div className="containerStepsRegistration">
        <Steps
          current={currentSteps}
          labelPlacement="vertical"
          onChange={handleStep}
        >
          {renderStep(listStepData)}
        </Steps>
      </div>
      <CCard
        cardTitle={renderHeaderCard(
          listStepData[currentSteps].title,
          listStepData[currentSteps].description
        )}
        cardContent={renderBodyCard()}
        cardClassName="RegistrationForm"
      />
    </div>
  );
}

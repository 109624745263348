import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import { contentManagementSubmoduleAPI } from '../../../../Services/ElearningContentManagement/submodule.api';
import SubmoduleComponent from '../Component/SubmoduleComponent';
import SubmoduleModalContainer from './SubmoduleModalContainer';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  stage: any;
  module: any;
  allDataList: {
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  };
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  token?: any;
  selectedStatus?: string;
  selectedData?: any;
}

export default function SubmoduleContainer({
  channel,
  stage,
  module,
  allDataList,
  setTabData,
  setAllDataList,
}: IProps) {
  const [filter, setFilter] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedData, setSelectedData] = useState<any>();
  const [modalIsShow, setModalIsShow] = useState(false);
  const [modalAction, setModalAction] = useState('');

  const {
    data: dataSubmodule,
    refetch: refetchSubmodule,
    isLoading: isLoadingSubmodule,
    isRefetching: isRefetchingSubmodule,
  } = useQuery(
    ['GetContentManagementSubmoduleAPI', { moduleId: module?.id }],
    contentManagementSubmoduleAPI.getSubmodule,
    {
      enabled: module?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );
  const {
    data: detailSubmodule,
    isLoading: isLoadingDetail,
    refetch: refetchDetail,
  } = useQuery(
    [
      'GetContentManagementSubmoduleDetailAPI',
      { submoduleId: selectedData?.id },
    ],
    contentManagementSubmoduleAPI.getSubmoduleDetail,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const mutationDelete = useMutation(
    contentManagementSubmoduleAPI.deleteSubmodule,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage id="deleteElearningSubModuleSuccess" />
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        refetchSubmodule();
      },
    }
  );

  const mutationStatus = useMutation(
    contentManagementSubmoduleAPI.mutateSubmoduleStatus,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage id="updateElearningSubModuleStatusSuccess" />
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        refetchSubmodule();
      },
    }
  );

  const processStatusChannel = (id: number, status: any) => {
    mutationStatus.mutate({ status, submoduleId: id });
  };

  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const action = status === true ? 'meng-aktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusChannel(id, status),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Sub-Module "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isPublish}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => mutationDelete.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Sub-Module "${rows.name}"`, toastrConfirmOptions);
    };
    const handleCopy = () => {
      setModalAction('copy');
      setSelectedData(rows);
      setModalIsShow(true);
    };

    const dataButton = [
      {
        id: `btnDeleteChannel`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditChannel`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalAction('update');
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
      {
        id: `btnDuplicateSubModule`,
        type: 'primary',
        icon: 'CopyOutlined',
        onClick: handleCopy,
        className: 'btnDuplicateSubModule',
      },
      {
        id: 'btnNextChannel',
        icon: 'ArrowRightOutlined',
        onClick: () => {
          setSelectedStatus(undefined);
          setFilter(undefined);
          setTabData(prev => ({ ...prev, submodule: rows }));
          setAllDataList(prev => ({ ...prev, submoduleList: dataSubmodule }));
        },
        className: 'btnNext',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { effectiveDateStart, effectiveDateEnd, totalContent } = row.original;
    const publishDate = moment(effectiveDateStart).format('DD-MMM-YYYY');
    const takeDownDate = moment(effectiveDateEnd).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Publish Date',
          publishDate === '01-Jan-0001' ? '-' : publishDate
        )}
        {renderCol(
          'Take Down Date',
          takeDownDate === '01-Jan-0001' ? '-' : takeDownDate
        )}
        {renderCol(
          'Content',
          totalContent === null || totalContent === '' ? '-' : totalContent
        )}
      </div>
    );
  }, []);

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Sub-Module Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Predecessor',
      accessor: 'predecessorDesc',
    },
    {
      Header: 'Sequence',
      accessor: 'sequence',
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const handleApplyfilter = () => {
    setFilter(selectedStatus);
  };

  const handleAdd = () => {
    setModalAction('register');
    setModalIsShow(true);
  };

  const handleRefresh = () => {
    refetchSubmodule();
    setSelectedStatus(undefined);
    setFilter(undefined);
  };

  const submoduleList = dataSubmodule
    ? filter === 'active'
      ? dataSubmodule.filter(item => item.isPublish)
      : filter === 'inactive'
      ? dataSubmodule.filter(item => !item.isPublish)
      : dataSubmodule
    : [];

  return (
    <>
      <SubmoduleComponent
        columnData={columnData}
        submodule={submoduleList
          .sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
          .sort((a, b) => a.sequence - b.sequence)}
        isLoading={
          isLoadingSubmodule ||
          isRefetchingSubmodule ||
          mutationDelete.isLoading ||
          mutationStatus.isLoading
        }
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
        renderRowSubComponent={renderRowSubComponent}
        setSelectedStatus={setSelectedStatus}
        handleApplyfilter={handleApplyfilter}
        selectedStatus={selectedStatus}
      />
      {modalIsShow && (
        <SubmoduleModalContainer
          allDataList={allDataList}
          channel={channel}
          stage={stage}
          module={module}
          submodule={dataSubmodule}
          setShowModal={setModalIsShow}
          setSelectedData={setSelectedData}
          setModalAction={setModalAction}
          selectedData={selectedData}
          detailSubmodule={detailSubmodule}
          modalAction={modalAction}
          refetch={refetchSubmodule}
          refetchDetail={refetchDetail}
          isLoading={isLoadingDetail}
        />
      )}
    </>
  );
}

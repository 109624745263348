import React, { useState } from 'react';
import CategoryBankModalComponent from '../Component/CategoryBankModalComponent';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { mutateCategoryBankAPI } from '../../../Services/categorybank.api';
import ErrorHandler from '../../../App/ErrorHandler';
interface IProps {
  handleRefresh: () => void;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
}

export default function CategoryBankModalContainer({
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  handleRefresh,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };
  const mutate = useMutation(mutateCategoryBankAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} category
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setShowModal(false);
      handleRefresh();
      setEditMode(false);
    },
  });
  const initialValues: any = selectedData;
  const onSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };
  return (
    <CategoryBankModalComponent
      categoryBankModalAction={selectedData ? 'update' : 'register'}
      editMode={editMode}
      handleCancel={handleCancel}
      initialValues={initialValues}
      isLoading={mutate.isLoading}
      isShow={showModal}
      onSubmit={onSubmit}
    />
  );
}

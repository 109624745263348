import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { contentManagementContentFeedbackAPI } from '../../../../../Services/ElearningContentManagement/contentFeedback';
import FeedbackSelfLearningModalComponent from '../../Component/Modal/FeedbackSelfLearningModalComponent';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  data: any;
  stage: any;
  submodule: any;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  handleRefresh: () => void;
  selectedData: any;
}

export default function FeedbackSelfLearningModalContainer({
  data,
  stage,
  submodule,
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  handleRefresh,
}: IProps) {
  const {
    data: dataFeedbackBank,
    isLoading: isLoadingFeedback,
    isRefetching: isRefetchFeedback,
  } = useQuery(
    [
      'getContentManagementFeedbackBankByIdAPI',
      { id: submodule?.id, type: 'SelfLearning' },
    ],
    contentManagementContentFeedbackAPI.getFeedbackBankById,
    {
      enabled: submodule?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const [editMode, setEditMode] = useState(false);
  const [listFeedback, setListFeedback] = useState<any[]>([]);
  const [selectedFeedback, setSelectedFeedback] = useState([]);
  const initialValues: any = selectedData;

  useEffect(() => {
    if (dataFeedbackBank) setListFeedback(dataFeedbackBank);
  }, [dataFeedbackBank]);

  const mutateFeedback = useMutation(
    contentManagementContentFeedbackAPI.mutateFeedback,
    {
      onSuccess: () => {
        setShowModal(false);
        handleRefresh();
        toast.success(
          <span className="capitalFirst">Feedback Berhasil Ditambahkan!</span>
        );
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const mutateStatus = useMutation(
    contentManagementContentFeedbackAPI.mutateFeedbackStatus,
    {
      onSuccess: () => {
        setShowModal(false);
        // refetch();
        handleRefresh();
        toast.success(
          <span className="capitalFirst">Feedback Berhasil Diupdate!</span>
        );
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };

  const handleResetQuestion = () => {
    setListFeedback(dataFeedbackBank ? dataFeedbackBank : []);
    setSelectedFeedback([]);
  };

  const onSubmit = (vals: any) => {
    if (selectedData) {
      if (editMode)
        mutateStatus.mutate({
          status: vals.isActive,
          id: selectedData?.id,
        });
      else setEditMode(true);
    } else
      mutateFeedback.mutate({
        feedbacks: selectedFeedback,
        channelId: submodule.channelId,
        stageId: submodule.stageId,
        moduleId: submodule.moduleId,
        subModuleId: submodule.id,
        type: 'SelfLearning',
        codemiModuleId: stage.codemiUserLevelId,
      });
  };

  return (
    <FeedbackSelfLearningModalComponent
      // dataDetail={dataDetail}
      disabled={false}
      isShow={showModal}
      handleCancel={handleCancel}
      handleResetQuestion={handleResetQuestion}
      handleSetListBank={setListFeedback}
      handleSetListSelected={setSelectedFeedback}
      onSubmit={onSubmit}
      initialValues={initialValues}
      modalAction={selectedData ? 'update' : 'register'}
      editMode={editMode}
      isLoading={selectedData ? false : isLoadingFeedback || isRefetchFeedback}
      listFeedback={listFeedback}
      selectedFeedback={selectedFeedback}
    />
  );
}

import React from 'react';
import CCard from '../../../Assets/Components/CCard';
import { Col, Form as FormAntd, Row, Tabs } from 'antd';
import CButton from '../../../Assets/Components/CButton';
import Table from '../../../Assets/Components/CTable';
import { Field, Form } from 'react-final-form';
import Select from '../../../Assets/Components/Select';
import moment from 'moment';
import { formatReport } from '../../../App/Enums';
import {
  IResultHistoryTrainingProcessDAO,
  IResultUpcomingTrainingProcessDAO,
} from '../DAO/dashboard.dao';

interface IProps {
  columnData: any;
  detailTrainer: any;
  handleDownload: (tabKey: string, values: any) => void;
  handleRefresh: (tabKey: string) => void;
  isLoading: boolean;
  listHistoryTraining: IResultHistoryTrainingProcessDAO[];
  listUpcomingTraining: IResultUpcomingTrainingProcessDAO[];
  renderRowSubComponent: any;
}

const { TabPane } = Tabs;

export default function DashboardTrainerComponent(props: IProps) {
  const {
    isLoading,
    columnData,
    renderRowSubComponent,
    listUpcomingTraining,
    listHistoryTraining,
    handleRefresh,
    handleDownload,
    detailTrainer,
  } = props;

  const contentTabPane = tabKey => {
    const dataButtonRefresh = [
      {
        type: 'primary',
        content: 'Refresh',
        id: 'btnRefreshDashboardTrainer',
        className: 'btnRefreshDashboardTrainer',
        onClick: () => handleRefresh(tabKey),
      },
    ];

    return (
      <div className="containerContentTabPaneDashboardTrainer">
        <CButton buttonData={dataButtonRefresh} buttonFloat="right" />
        <Table
          isLoading={isLoading}
          columns={columnData}
          data={
            tabKey === 'upcomingTraining'
              ? listUpcomingTraining
              : listHistoryTraining
          }
          pagination={true}
          useFilterGlobal={true}
          renderRowSubComponent={renderRowSubComponent}
        />
        <Form onSubmit={() => {}} keepDirtyOnReinitialize={true}>
          {formProps => {
            const { handleSubmit: handleSubmitForm, values } = formProps;

            const dataButtonDownload = [
              {
                type: 'primary',
                content: 'Download berkas',
                icon: 'DownloadOutlined',
                id: 'btnDownloadTrainer',
                className: 'btnDownloadTrainer',
                disabled: values && values.downloadType ? false : true,
                onClick: () => handleDownload(tabKey, values),
              },
            ];
            return (
              <Row>
                <Col span={5}>
                  <FormAntd layout="vertical" onFinish={handleSubmitForm}>
                    <Field
                      name="downloadType"
                      component={Select}
                      label="Download Report Format"
                      defaultItemName="Select Format"
                      dataOption={formatReport}
                    />
                  </FormAntd>
                </Col>
                <Col span={5}>
                  <CButton buttonData={dataButtonDownload} />
                </Col>
              </Row>
            );
          }}
        </Form>
      </div>
    );
  };

  const renderRowDetailTrainer = (title: string, value: any) => {
    return (
      <thead>
        <tr>
          <th className="titleDetailDashboardTrainer">{title}</th>
          <td>:</td>
          <td className="descDetailDashboardTrainer">{value}</td>
        </tr>
      </thead>
    );
  };

  const cardContent = () => {
    return (
      <div className="containerContentDashboardTrainer">
        <table className="detailDashboardTrainer">
          {renderRowDetailTrainer(
            'Finished Training',
            listHistoryTraining?.length
          )}
          {renderRowDetailTrainer(
            'Last Login',
            detailTrainer
              ? moment(detailTrainer.lastLogin).format('DD MMMM YYYY')
              : '-'
          )}
          {renderRowDetailTrainer(
            'First Login',
            detailTrainer
              ? moment(detailTrainer.joinData).format('DD MMMM YYYY')
              : '-'
          )}
        </table>
        <Tabs className="dynamicTabs" type="card">
          <TabPane
            tab="Upcoming Training"
            key="upcomingTraining"
            className="tabsPaneDynamic"
          >
            {contentTabPane('upcomingTraining')}
          </TabPane>
          <TabPane
            tab="History Training"
            key="historyTraining"
            className="tabsPaneDynamic"
          >
            {contentTabPane('historyTraining')}
          </TabPane>
        </Tabs>
      </div>
    );
  };

  return (
    <div style={{ width: '100%' }}>
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Training Schedule</span>
        }
        cardContent={cardContent()}
      />
    </div>
  );
}

import CButton from '../../../../../Assets/Components/CButton';
import ClassModalScheduleContainer from '../../Container/ClassModalScheduleContainer';
import React from 'react';
import Table from '../../../../../Assets/Components/CTable';

interface IProps {
  columnData: any;
  dataClassNonSelf?: any;
  disabled: boolean;
  isLoading: boolean;
  listSchedule: any[];
  modalAction: 'update' | 'register';
  refetchSchedule: () => void;
  selectedSchedule: any;
  setListSchedule: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedSchedule: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
}

export default function ScheduleComponent({
  columnData,
  dataClassNonSelf,
  disabled,
  isLoading,
  listSchedule,
  modalAction,
  refetchSchedule,
  selectedSchedule,
  setListSchedule,
  setSelectedSchedule,
  setShowModal,
  showModal,
}: IProps) {
  const buttonData = [
    {
      type: 'default',
      className: 'btnTransferText',
      icon: 'PlusOutlined',
      content: 'Add Data',
      id: 'btnAddData',
      onClick: () => {
        setShowModal(true);
        setSelectedSchedule(undefined);
      },
      disabled,
    },
  ];
  return (
    <div>
      <CButton
        buttonData={buttonData}
        isLoading={isLoading}
        buttonFloat={'left'}
      />
      <Table
        isLoading={isLoading}
        columns={columnData}
        pagination={true}
        data={listSchedule || []}
      />
      <ClassModalScheduleContainer
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedSchedule}
        setSelectedData={setSelectedSchedule}
        listSchedule={listSchedule}
        setListSchedule={setListSchedule}
        modalAction={modalAction}
        refetchSchedule={refetchSchedule}
        dataClassNonSelf={dataClassNonSelf}
      />
    </div>
  );
}

const channelValidation = ({
  code,
  signOffCaption,
  name,
  headOfTrainer,
  trainerName,
}) => {
  const errors: any = {};
  if (!code) errors.code = 'Channel Code Wajib Diisi!';
  else if (code && !/^[^\s].*/.test(code))
    errors.code = 'Gunakan huruf/angka di awal kata!';
  else if (code && !/^[A-Za-z0-9]+$/.test(code))
    errors.code = 'Hanya boleh menginputkan huruf dan angka!';

  if (!name) errors.name = 'Channel Name Wajib Diisi!';
  else if (name && !/^[^\s].*/.test(name))
    errors.name = 'Gunakan huruf/angka di awal kata!';

  if (!headOfTrainer) errors.headOfTrainer = 'Head Of Trainer Wajib Diisi!';

  if (!trainerName) errors.trainerName = 'Trainer Name Wajib Diisi!';
  else if (trainerName && !/^[^\s].*/.test(trainerName))
    errors.trainerName = 'Gunakan huruf/angka di awal kata!';
  else if (trainerName && !/^[A-Za-z .,]+$/.test(trainerName))
    errors.trainerName = `Hanya boleh menginputkan huruf dan karakter titik(.), koma(,)`;

  if (!signOffCaption)
    errors.signOffCaption = 'Sign Off Caption Name Wajib Diisi!';

  return errors;
};

export default channelValidation;

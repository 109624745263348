const MappingUserRoleValidation = (
  { role, userList, name, channel }: any,
  authRoleList
) => {
  let isTrainer = false;

  const trainerData = authRoleList?.find(val => val.name === 'Trainer');

  if (trainerData && trainerData?.id === role) {
    isTrainer = true;
  }

  const errors: any = {};
  if (!role) {
    errors.role = 'Role required!';
  }
  if (!userList || userList.length === 0) {
    errors.userList = 'Username required!';
  }
  if (!userList || userList.length === 0) {
    errors.userList = 'Username required!';
  }
  if (isTrainer) {
    if (!channel) {
      errors.channel = 'Channel required!';
    }
    if (!name) {
      errors.name = 'Name required!';
    } else if (name && !/^[^\s].*/.test(name)) {
      errors.name = 'Use letters/numbers at the beginning of words!';
    } else {
      if (name.trim() === '') {
        errors.name = 'Name required!';
      }
    }
  }
  return errors;
};
export default MappingUserRoleValidation;

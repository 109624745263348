import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import ModalDetailHistoryContainer from '../Container/ModalDetailHistoryContainer';
import { IResultHistoryPrivyDAO } from '../DAO/privy.dao';

interface IProps {
  columnData: any;
  privyHistoryList: IResultHistoryPrivyDAO[];
  isLoading: boolean;
  handleRefresh: () => void;
  modalDetailIsShow: boolean;
  setModalDetailIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: any;
}

export default function HistoryPrivyComponent(props: IProps) {
  const {
    columnData,
    privyHistoryList,
    isLoading: isLoadingList,
    handleRefresh,
    modalDetailIsShow,
    setModalDetailIsShow,
    selectedData,
  } = props;
  const buttonData = [
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshHistoryPrivy',
    },
  ];

  return (
    <div className="containerWhiteZurich">
      <CButton
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={isLoadingList}
      />
      <Table
        isLoading={isLoadingList}
        columns={generateColumnData(columnData)}
        data={privyHistoryList}
        pagination={true}
        useFilterGlobal
      />
      <ModalDetailHistoryContainer
        modalDetailIsShow={modalDetailIsShow}
        setModalDetailIsShow={setModalDetailIsShow}
        selectedData={selectedData}
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import ModalLibraryFolderComponent from '../Component/ModalLibraryFolderComponent';
import { useMutation } from '@tanstack/react-query';
import { mutateFolderLibraryAPI } from '../../../Services/library.api';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { IntlShape } from 'react-intl';
import { IResultLibraryListDAO } from '../DAO/library.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  intl: IntlShape;
  libraryList: IResultLibraryListDAO[];
  selectedPath: string;
  selectedData: any;
  refetch: any;
  list: any;
  setSelectedMovePath: any;
  modalFolderIsShow: boolean;
  handleRefresh: (record: any) => void;
  setModalFolderIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setLibraryCollection: React.Dispatch<React.SetStateAction<undefined>>;
  setModalMoveShow: React.Dispatch<React.SetStateAction<boolean>>;
  setMoveType: React.Dispatch<React.SetStateAction<string>>;
  setIsRoot: React.Dispatch<React.SetStateAction<boolean>>;
  setLibrarySelected: React.Dispatch<React.SetStateAction<never[]>>;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
}
export default function ModalLibraryFolderContainer(props: IProps) {
  const {
    modalFolderIsShow,
    setModalFolderIsShow,
    intl,
    selectedPath,
    setIsRoot,
    setLibrarySelected,
    selectedData,
    setSelectedData,
    refetch,
    list,
    setLibraryCollection,
    setModalMoveShow,
    setMoveType,
    setSelectedMovePath,
  } = props;
  const [initialValues, setInitialValues] = useState(null);
  const [editMode, setEditMode] = useState(false);

  const {
    mutate: mutateFolder,
    data: libraryFolder,
    isLoading,
  } = useMutation(mutateFolderLibraryAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        <span className="toastSuccessBackground">
          {!selectedData ? (
            <FormattedMessage id="createFolderSuccess" />
          ) : (
            <FormattedMessage id="updateFolderSuccess" />
          )}
        </span>
      );
      refetch();
      setIsRoot(true);
      setModalFolderIsShow(false);
      setEditMode(false);
      setInitialValues(null);
      setSelectedData(undefined);
    },
  });

  useEffect(() => {
    if (libraryFolder) {
      setLibrarySelected(libraryFolder?.data);
    }
    // eslint-disable-next-line
  }, [libraryFolder]);

  const initialValue: any = {};
  useEffect(() => {
    if (selectedData) {
      initialValue.foldername = selectedData.name;
      setInitialValues(initialValue);
    } else {
      setInitialValues(null);
    }
    // eslint-disable-next-line
  }, [selectedData]);

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateFolder({
        name: vals.foldername,
        type: 'folder',
        selectedPath,
        id: selectedData?.id,
      });
    }
  };

  const handleCancel = () => {
    setModalFolderIsShow(false);
    setSelectedData(undefined);
    setInitialValues(null);
    setEditMode(false);
  };
  const handleMove = () => {
    const filteredData: any = [];
    for (const key in list) {
      if (Object.prototype.hasOwnProperty.call(list, key)) {
        const element = list[key];
        if (element.type === 'folder') {
          filteredData.push(element);
        }
      }
    }
    setMoveType('folder');
    setLibraryCollection(filteredData);
    setModalMoveShow(true);
    setSelectedMovePath(['Home']);
  };

  return (
    <ModalLibraryFolderComponent
      modalFolderIsShow={modalFolderIsShow}
      handleCancel={handleCancel}
      intl={intl}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      initialValues={initialValues}
      editMode={editMode}
      handleMove={handleMove}
    />
  );
}

import React, { useState } from 'react';
import CatatanPendingComponent from '../Component/CatatanPendingComponent';
import { useQuery } from '@tanstack/react-query';
import {
  getPendingRemarksAPI,
  getSchemaFormAPI,
} from '../../../Services/catatanPending.api';
import { injectIntl } from 'react-intl';
import CButton from '../../../Assets/Components/CButton';

interface IProps {
  intl: any;
}
function CatatanPendingContainer(props: IProps) {
  const { intl } = props;
  const [modalPendingIsShow, setModalPendingIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: remarksList,
    fetchStatus,
    isLoading,
    isRefetching,
    refetch: refetchList,
  } = useQuery(['getPendingRemarks'], getPendingRemarksAPI, {
    refetchOnWindowFocus: false,
  });

  const { data: schema } = useQuery(['getSchemaForm'], getSchemaFormAPI, {
    refetchOnWindowFocus: false,
  });

  const renderCategory = (row: any) => {
    if (row.row.original.category) {
      return (
        <div>
          {intl.formatMessage({
            id: row.row.original.category,
          })}
        </div>
      );
    }
    return '-';
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailPendingRemarks',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: () => {
          setSelectedData(rows);
          setModalPendingIsShow(true);
        },
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Judul',
      accessor: 'title',
    },
    {
      Header: 'Kategori',
      Cell: (row: any) => renderCategory(row),
      disableSort: true,
    },
    {
      Header: 'Catatan',
      accessor: 'note',
      disableSort: true,
    },
    {
      Header: ' ',
      Cell: (row: any) => renderAction(row),
    },
  ];

  return (
    <CatatanPendingComponent
      columnData={columnData}
      remarksList={remarksList}
      handleRefresh={refetchList}
      isLoadingList={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      schema={schema}
      modalPendingIsShow={modalPendingIsShow}
      setModalPendingIsShow={setModalPendingIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
    />
  );
}
export default injectIntl(CatatanPendingContainer);

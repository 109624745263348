import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { mutateAgreementMappingAPI } from '../../../Services/Agreement/agreementMapping.api';
import ModalAgreementMappingComponent from '../Component/ModalAgreementMappingComponent';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  showModal: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  intl: any;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  agreementContentList: any[];
  agreementModuleList: any[];
  handleRefresh: () => void;
}

export default function ModalAgreementMappingContainer(props: IProps) {
  const {
    showModal,
    setModalIsShow,
    intl,
    selectedData,
    setSelectedData,
    agreementContentList,
    agreementModuleList,
    handleRefresh,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const modalAction = selectedData ? 'update' : 'register';
  let initialValues: any = selectedData;
  const mutateDisclaimerMapping = useMutation(mutateAgreementMappingAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} agreement mapping!
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });
  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateDisclaimerMapping.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };
  const handleCancel = () => {
    setSelectedData(undefined);
    setModalIsShow(false);
    setEditMode(false);
  };

  return (
    <ModalAgreementMappingComponent
      {...props}
      isShow={showModal}
      intl={intl}
      modalAction={modalAction}
      initialValues={initialValues}
      handleCancel={handleCancel}
      agreementContentList={agreementContentList}
      agreementModuleList={agreementModuleList}
      editMode={editMode}
      handleSubmit={handleSubmit}
      disabled={editMode === false && modalAction === 'update' ? true : false}
    />
  );
}

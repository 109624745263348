import React, { useState } from 'react';
import ModalCatatanPendingComponent from '../Component/ModalCatatanPendingComponent';
import { useMutation } from '@tanstack/react-query';
import { mutateCatatanPendingAPI } from '../../../Services/catatanPending.api';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  modalPendingIsShow: boolean;
  setModalPendingIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  schema: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  handleRefresh: () => void;
}
export default function ModalCatatanPendingContainer(props: IProps) {
  const {
    schema,
    modalPendingIsShow,
    setModalPendingIsShow,
    selectedData,
    setSelectedData,
    handleRefresh,
  } = props;
  const [editMode, setEditMode] = useState(false);

  const mutate = useMutation(mutateCatatanPendingAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">Berhasil ditambah!</span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalPendingIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  let initialValues: any;
  if (selectedData) {
    const newDetail: any = selectedData;
    if (
      selectedData.pathForm === 'beneficiary' ||
      selectedData.pathForm === 'workexperience'
    ) {
      newDetail.pathForm = '';
    }
    initialValues = newDetail;
  }

  const handleCancel = () => {
    setModalPendingIsShow(false);
    setEditMode(false);
    setSelectedData(undefined);
  };

  const handleSelectCategory = (form: FormApi<any, Partial<any>>) => {
    form.change('pathForm', null);
  };

  return (
    <ModalCatatanPendingComponent
      modalPendingIsShow={modalPendingIsShow}
      handleCancel={handleCancel}
      schema={schema}
      initialValues={initialValues}
      editMode={editMode}
      isLoading={mutate.isLoading}
      modalAction={selectedData ? 'update' : 'register'}
      onSubmit={handleSubmit}
      handleSelectCategory={handleSelectCategory}
    />
  );
}

import { FormApi } from 'final-form';
import React from 'react';
import ModuleModalParticipantComponent from '../../Component/Modal/ModuleModalParticipantComponent';
import { useMutation } from '@tanstack/react-query';
import { contentManagementModuleAPI } from '../../../../../Services/ElearningContentManagement/module.api';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

interface IProps {
  editMode: boolean;
  disabled: boolean;
  modalAction: string;
  selectedData: any;
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  form?: FormApi<any, Partial<any>>;
  formError?: any;
  formValues?: any;
}

export default function ModuleModalParticipantContainer(props: IProps) {
  const { form, selectedData, setEditMode, setModalIsShow, setSelectedData } =
    props;

  const { mutate } = useMutation(contentManagementModuleAPI.assignParticipant, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="assignParticipantModuleSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setModalIsShow(false);
      setEditMode(false);
      setSelectedData(undefined);
    },
  });

  const handleReset = () => {
    form?.change('participant.agentLevels', []);
    form?.change('participant.participantCondition', null);
    form?.change('participant.conditionLogicEffectiveDate', null);
    form?.change('participant.conditionLogicAfterGetAgentCode', null);
    form?.change('participant.effectiveDateDays', null);
    form?.change('participant.afterGetAgentCodeDays', null);
  };

  const handleAssignParticipant = () => mutate(selectedData?.id);

  return (
    <ModuleModalParticipantComponent
      handleReset={handleReset}
      handleAssignParticipant={handleAssignParticipant}
      {...props}
    />
  );
}

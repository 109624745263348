import { Alert } from 'antd';
import React from 'react';
import { typePrivyStatement } from '../../../App/Enums';
import Select from '../../../Assets/Components/Select';
import Store from '../../../Store';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { localeUppy } from '../../../Modules/Internationalization/translations/id';
import Dashboard from '@uppy/react/lib/Dashboard';
import history from '../../../App/History';
import { toast } from 'react-toastify';

interface IProps {
  selectedType?: string;
  setSelectedType: React.Dispatch<React.SetStateAction<any>>;
  renewToken: () => void;
}
export default function UploadPrivyComponent(props: IProps) {
  const { selectedType, setSelectedType, renewToken } = props;
  const authState: any = Store.getState();
  const agreementType = selectedType;
  const uppyUploadPrivy = new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.pdf'],
    },
    locale: localeUppy,
  }).use(XHRUpload, {
    id: `uppyFileGalleryXHR`,
    headers: {
      Authorization: `bearer ${authState.token}`,
    },
    endpoint: `${process.env.REACT_APP_API_URL}/Candidate/file/upload/privyagreement?agreementType=${agreementType}`,
    method: 'post',
    formData: true,
    fieldName: 'file',
    allowedMetaFields: ['originalFilename', 'agreementType'],
    disableThumbnailGenerator: true,
    showRemoveButtonAfterComplete: true,
    inline: true,
    timeout: 300 * 1000,
  });
  uppyUploadPrivy.on('file-added', file => {
    uppyUploadPrivy.setFileMeta(file.id, {
      originalFilename: file.name,
      agreementType: selectedType,
    });
    uppyUploadPrivy.on('upload-success', () => {
      toast.success('Berkas Berhasil Diunggah');
      uppyUploadPrivy.cancelAll();
      setSelectedType(null);
    });
  });
  uppyUploadPrivy.on('upload-error', (file: any, error: any, response: any) => {
    if (response.status === 401) {
      renewToken();
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      authState.logOut();
    }
  });
  return (
    <>
      <div className="containerTabWhiteZurich">
        <Alert
          message="Berkas unggahan baru akan mengganti/menghapus berkas lama (tipe statement yang sama)"
          type="warning"
          showIcon
        />
        <div>
          <label className="labelBtnFilter">Tipe Statement</label>
          <Select
            dataOption={typePrivyStatement}
            defaultItemName="Semua"
            idComponent="filterCategoryBank"
            meta={{ touched: false, error: false }}
            input={{
              onBlur: null,
              onChange: setSelectedType,
              value: selectedType ? selectedType : 'Pilih',
            }}
            className="btnFeedbackBank"
          />
          <hr className="linePrivy" />
          <p className="TitleUploadPrivy">Unggah Berkas</p>
          <Dashboard
            id="uppyUploadPrivy"
            uppy={uppyUploadPrivy}
            width={'100%'}
            height={300}
            proudlyDisplayPoweredByUppy={true}
          />
        </div>
      </div>
    </>
  );
}

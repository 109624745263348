import { Alert, Button } from 'antd';
import { Field, FormRenderProps } from 'react-final-form';
import {
  InputText,
  InputTimePicker,
} from '../../../../Assets/Components/CInput';

import CIcon from '../../../../Assets/Components/CIcon';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../../App/HasPermission';
import { InputDate } from '../../../../Assets/Components/InputDate';
import React from 'react';
import Select from '../../../../Assets/Components/Select';
import Table from '../../../../Assets/Components/CTable';
import { classScheduleType, platformOnlineType } from '../../../../App/Enums';
import { classScheduleValidation } from '../../Validation/ClassValidation';
import moment from 'moment';

interface IProps {
  classAction: 'register' | 'update';
  columnData: any[];
  dataCity: any[];
  dataProvince: any[];
  dataScheduleDetail: any;
  editMode: boolean;
  formRef: any;
  handleAddSession: any;
  handleCancel: () => void;
  handleClose: () => void;
  initialValues: any;
  isLoading: boolean;
  isLoadingTable: boolean;
  isShow: boolean;
  listSession: any[];
  modalAction: 'register' | 'update';
  onSubmit: (val: any) => void;
  setSelectedProvince: React.Dispatch<React.SetStateAction<any>>;
}

const modalStyle = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '1500px',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '99',
  },
};

export default function ClassModalScheduleComponent({
  classAction,
  columnData,
  dataCity,
  dataProvince,
  dataScheduleDetail,
  editMode,
  formRef,
  handleAddSession,
  handleCancel,
  handleClose,
  initialValues,
  isLoading,
  isLoadingTable,
  isShow,
  listSession,
  modalAction,
  onSubmit,
  setSelectedProvince,
}: IProps) {
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailClassScheduleNonSelf" />
        ) : (
          <FormattedMessage id="addClassScheduleNonSelf" />
        )}
      </h3>
    );
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { form, values } = propsContent;
    const formApi = form;
    const type = values.type;
    const locationDetailsLength = values?.locationDetails?.length || 0;

    const disabled = modalAction === 'update' && !editMode;

    return (
      <>
        {classAction === 'update' && (
          <Alert
            message="Perubahan pada data akan langsung otomatis tersimpan kedalam sistem"
            type="warning"
            showIcon
          />
        )}
        <div className="containerGridTwo">
          <div>
            <Field
              name="type"
              component={Select}
              idComponent="type"
              dataOption={classScheduleType}
              disabled={
                disabled ||
                (classAction === 'update' && modalAction === 'update')
              }
              label="Type"
              defaultItemName="-Select-"
            />
            {!(classAction === 'update' && modalAction === 'update') && (
              <>
                <Field
                  name="date"
                  component={InputDate}
                  label="Date"
                  className="inputSubModul"
                  idComponent="modalDate"
                  typeDate="dateRangePicker"
                  labelIsRequired={true}
                  disabled={disabled}
                  formatInput="DD-MMM-YYYY"
                  disabledDate={current =>
                    current.isBefore(moment().subtract(1, 'day'))
                  }
                />
                <div style={{ display: 'flex', gap: '20px' }}>
                  <Field
                    name="startTime"
                    component={InputTimePicker}
                    label="Start Time"
                    className="inputSubModul"
                    idComponent="modalStartTime"
                    disabled={disabled}
                  />
                  <Field
                    name="endTime"
                    component={InputTimePicker}
                    label="End Time"
                    className="inputSubModul"
                    idComponent="modalEndTime"
                    disabled={disabled}
                  />
                </div>
              </>
            )}
          </div>
          {type ? (
            type === 'onsite' ? (
              <div>
                <Field
                  name="venue"
                  component={InputText}
                  placeholder="Building Name"
                  label="Venue"
                  disabled={disabled}
                  labelIsRequired={true}
                />
                <Field
                  name="province"
                  component={Select}
                  idComponent="province"
                  dataOption={dataProvince || []}
                  disabled={disabled}
                  label="Province"
                  onSelect={val => {
                    setSelectedProvince(val);
                    formApi.change('city', '');
                  }}
                  defaultItemName="-Select-"
                  labelIsRequired={true}
                />
                <Field
                  name="city"
                  component={Select}
                  idComponent="city"
                  dataOption={dataCity || []}
                  disabled={disabled}
                  label="City"
                  defaultItemName="-Select-"
                  labelIsRequired={true}
                />
                <Field
                  name="locationDetails"
                  component={InputText}
                  placeholder="Street name, building number, floor, postal code, etc"
                  label="Location Details"
                  disabled={disabled}
                  labelIsRequired={true}
                  textArea={true}
                  maxlength={255}
                  addtionalCaption={`${locationDetailsLength}/255`}
                />
                <Field
                  name="locationLink"
                  component={InputText}
                  placeholder="Paste link here"
                  label="Location Link"
                  disabled={disabled}
                  icon="LinkOutlined"
                />
              </div>
            ) : (
              <div>
                <Field
                  name="platform"
                  component={Select}
                  idComponent="platform"
                  dataOption={platformOnlineType}
                  disabled={disabled}
                  label="Platform"
                  defaultItemName="-Select-"
                  labelIsRequired={true}
                />
                <Field
                  name="meetingLink"
                  component={InputText}
                  placeholder="Paste link here"
                  label="Meeting Link"
                  disabled={disabled}
                  icon="LinkOutlined"
                  labelIsRequired={true}
                />
              </div>
            )
          ) : null}
        </div>
        <div>
          {dataScheduleDetail && (
            <>
              <div
                style={{
                  background: '#444444',
                  padding: 10,
                  marginBottom: 30,
                  color: 'white',
                }}
              >
                Session
              </div>
              <Table
                isLoading={isLoadingTable}
                columns={columnData}
                data={listSession}
              />
              <Button
                className="btnAddSubModule"
                icon={<CIcon type="PlusOutlined" />}
                disabled={disabled}
                id="btnCancel"
                onClick={handleAddSession}
              >
                Add Data
              </Button>
            </>
          )}
        </div>
      </>
    );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = propsFooter => {
    const { invalid } = propsFooter;

    if (HasPermission('u') || HasPermission('c')) {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '20px',
          }}
        >
          <Button
            className="btnResetSubModule"
            icon={<CIcon type="CloseOutlined" />}
            disabled={false}
            id="btnCancel"
            htmlType="button"
            onClick={handleCancel}
            loading={isLoading}
          >
            Cancel
          </Button>
          <Button
            className={
              editMode
                ? invalid
                  ? 'btnSubmitDisabled'
                  : 'btnSubmit'
                : 'btnSubmit'
            }
            icon={<CIcon type="CheckOutlined" />}
            disabled={modalAction === 'update' && !editMode ? false : invalid}
            id="btnSubmit"
            htmlType="submit"
            loading={isLoading}
          >
            {buttonContent(modalAction, editMode)}
          </Button>
        </div>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleClose}
      handleValidation={val =>
        classScheduleValidation(val, classAction, modalAction, editMode)
      }
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      isLoading={isLoading}
      initialValues={initialValues}
      modalstyles={modalStyle}
      formRef={formRef}
    />
  );
}

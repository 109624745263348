function beforeMonth(day, value) {
  return day.isBefore(value, 'month');
}

function afterMonth(day, value) {
  return day.isAfter(value, 'month');
}

function isToday(day) {
  return day.isSame(new Date(), 'day');
}

export default function dayStyles(day, value) {
  if (beforeMonth(day, value) || afterMonth(day, value)) {
    return 'notMonth';
  }
  if (isToday(day)) {
    return 'today';
  }
  return 'day';
}

import { Button } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import backgroundImage from '../Images/Background404.jpg';
import notFoundImage from '../Images/404Text.png';

interface IProps {
  isRecruitment: boolean;
}
export default function NotFoundComponent(props: IProps) {
  const { isRecruitment } = props;
  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className="container404"
    >
      <div className="divNotFoundImage">
        <img src={notFoundImage} alt="404" className="notFoundImage" />
      </div>
      <h1 className="h1NotFound">Something Went Wrong</h1>
      <p className="pNotFound">
        We can't find the page that you are looking for
      </p>
      {!isRecruitment ? (
        <div className="btnBack404">
          <Link to={`${process.env.REACT_APP_PUBLIC_URL}/`}>
            <Button className="buttonNotFound" size="large" id="btnBackHome">
              Back to Home
            </Button>
          </Link>
        </div>
      ) : null}
    </div>
  );
}

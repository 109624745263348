import HeaderComponent from '../Component/HeaderComponent';
import React, { Dispatch, SetStateAction } from 'react';
import { toastr } from 'react-redux-toastr';
import Store from '../../../Store';
interface IProps {
  setSiderIsCollapse: Dispatch<SetStateAction<boolean>>;
  siderIsCollapse: boolean;
}
export default function HeaderContainer(props: IProps) {
  const { setSiderIsCollapse, siderIsCollapse } = props;
  const state: any = Store.getState();
  const username =
    state.auth['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];

  const handleCollapseSider = () => {
    setSiderIsCollapse(!siderIsCollapse);
  };

  const logOut = () => {
    const toastrConfirmOptions = {
      onOk: () => {
        state.logOut();
      },
      id: 'toastRConfirm',
    };
    toastr.confirm('Are you sure to log out?', toastrConfirmOptions);
  };

  return (
    <HeaderComponent
      handleCollapseSider={handleCollapseSider}
      logOut={logOut}
      username={username}
      siderIsCollapse={siderIsCollapse}
    />
  );
}

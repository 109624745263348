import React, { Dispatch, SetStateAction } from 'react';
import header from '../../../Assets/Images/header-recruitment.png';
import zurichLogo from '../../../Assets/Images/zurichBlueNew.png';
import moment from 'moment';
import packageJson from './../../../../package.json';
import { Skeleton } from 'antd';
import NotFoundComponent from '../../../Assets/Components/NotFoundComponent';
import TermsConditionsContainer from '../Container/TermsConditionsContainer';
import FormContainer from '../Container/FormContainer';
import SuccessfulNotificationContainer from '../Container/SuccessfulNotificationContainer';

interface IProps {
  linkStatus: any;
  isLoadingLink: boolean;
  currentStep: number;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  showSuccessPage: boolean;
  setShowSuccessPage: Dispatch<SetStateAction<boolean>>;
  agentData: string[];
}
export default function RecruitmentComponent(props: IProps) {
  const {
    linkStatus,
    isLoadingLink,
    currentStep,
    setCurrentStep,
    showSuccessPage,
    setShowSuccessPage,
    agentData,
  } = props;
  const year = moment().format('YYYY');

  return (
    <Skeleton active loading={isLoadingLink}>
      {linkStatus === true ? (
        <>
          {showSuccessPage ? (
            <SuccessfulNotificationContainer />
          ) : (
            <div className="recruitmentpage">
              <img
                src={header}
                className="imgHeaderRecruitment"
                alt="imgHeaderRecruitment"
              />
              {currentStep === 1 ? (
                <img
                  src={zurichLogo}
                  className="logoZurichRecruitment"
                  alt="logoZurich"
                />
              ) : null}
              <div className="contentRecruitment">
                {currentStep === 1 ? (
                  <TermsConditionsContainer setCurrentStep={setCurrentStep} />
                ) : (
                  <FormContainer
                    setShowSuccessPage={setShowSuccessPage}
                    agentData={agentData}
                  />
                )}
              </div>

              <div className="footerRecruitment">
                <p>Copyright © {year} Zurich Indonesia</p>
                <p className="textBold">
                  Zurich Recruitment v {packageJson.version}
                </p>
              </div>
            </div>
          )}
        </>
      ) : (
        <NotFoundComponent isRecruitment={true} />
      )}
    </Skeleton>
  );
}

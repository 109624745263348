import { FormattedMessage, injectIntl } from 'react-intl';
import { InputText } from '../../../Assets/Components/CInput';

import { Button } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import HasPermission from '../../../App/HasPermission';
import React from 'react';
import { paramterTypeConfig } from '../../../App/Enums';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Field } from 'react-final-form';
import Select from '../../../Assets/Components/Select';
import validate from '../Validation/ConfigurationValidation';

interface IProps {
  modalConfigIsShow: boolean;
  handleCancel: () => void;
  intl: any;
  initialValues: any;
  handleSubmit: (values: any) => void;
  editMode: boolean;
  disabled: boolean;
}

function FormModalConfigurationComponent(props: IProps) {
  const {
    handleCancel,
    modalConfigIsShow,
    intl,
    initialValues,
    handleSubmit,
    editMode,
    disabled,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="configurationForm" />
      </h3>
    );
  };
  const contentComponent = (formProps: any) => {
    return (
      <React.Fragment>
        <Field
          name="parameter"
          component={InputText}
          idComponent="inputParameter"
          icon={<CIcon type="FileTextOutlined" />}
          label={intl.formatMessage({ id: 'labelParameterConfiguration' })}
          disabled={true}
          disableOnChange={true}
        />
        <Field
          name="parameterType"
          component={Select}
          idComponent="inputParameterType"
          dataOption={paramterTypeConfig}
          defaultItemName="Select parameter type"
          label={intl.formatMessage({ id: 'parameterType' })}
          editMode={editMode}
          disabled={disabled}
        />
        <Field
          name="parameterValue"
          component={InputText}
          idComponent="inputParameterValue"
          icon={<CIcon type="FileTextOutlined" />}
          label={intl.formatMessage({ id: 'labelParameterValueConfiguration' })}
          disabled={disabled}
          editMode={editMode}
        />
      </React.Fragment>
    );
  };
  const buttonContent = () => {
    if (editMode === false) {
      return 'Edit';
    }
    return 'Save Change';
  };
  const footerComponent = (formProps: any) => {
    const { invalid } = formProps;

    if (HasPermission('u')) {
      return (
        <Button
          icon={<CIcon type="CheckOutlined" />}
          className={
            editMode
              ? invalid
                ? 'btnSubmitDisabled'
                : 'btnSubmit'
              : 'btnSubmit'
          }
          id="btnSubmit"
          htmlType="submit"
          disabled={invalid}
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={modalConfigIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="horizontal"
      initialValues={initialValues}
    />
  );
}

export default injectIntl(FormModalConfigurationComponent);

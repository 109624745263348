import instance from './interceptor';

export const searchLDAP = async params => {
  const username = params.queryKey[1];
  const { data } = await instance.get(`/User/searchldap/${username}`);
  return data;
};

export const getUserRoleListAPI = async () => {
  const { data } = await instance.get('/user/roles');
  return data;
};

export const getRoleAPI = async () => {
  const { data } = await instance.get('/role');
  return data;
};

export const mutateMappingAuthUserRole = async values => {
  const { userList, role } = values;
  const dataUser: any[] = [];
  for (const username of userList) {
    const x = {
      roleId: role,
      username,
    };
    dataUser.push(x);
  }
  return await instance.put('/user/assign', {
    roleUsernames: dataUser,
  });
};

export const mutateUnassignAuthUserRoleAPI = async values => {
  const { username, roleId } = values;
  return await instance.put(`user/${username}/${roleId}/unassign`, null);
};
export const mutateRegisterTrainerAPI = async values => {
  const { channel: channelCode, trainerCode, name } = values;
  return await instance.post(`Elearning/user/register/trainer`, {
    name,
    trainerCode,
    channelCode,
    userType: 'Trainer',
  });
};
export const mutateDeleteTrainerAPI = async code => {
  return await instance.delete(`Elearning/user/trainer?code=${code}`);
};

import React from 'react';
import SiderComponent from '../Component/SiderComponent';
import { useLocation } from 'react-router-dom';
interface IProps {
  siderIsCollapse: boolean;
}
export default function SiderContainer(props: IProps) {
  const { siderIsCollapse } = props;
  const location = useLocation();

  return (
    <SiderComponent siderIsCollapse={siderIsCollapse} location={location} />
  );
}

import CButton from '../../../Assets/Components/CButton';
import CICon from '../../../Assets/Components/CIcon';
import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import CModalRFF from '../../../Assets/Components/CModalRFF';

interface IProps {
  handleCancel: () => void;
  handleOnClickDetail: (data: any) => void;
  listExamSchedule: any;
  modalIsShow: boolean;
  selectedDate: any;
}

export default function ModalListExamScheduleComponent(props: IProps) {
  const {
    handleCancel,
    handleOnClickDetail,
    listExamSchedule,
    modalIsShow,
    selectedDate,
  } = props;

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Detail Schedule</h3>;
  };

  const contentComponent = () => {
    return (
      <>
        <div className="selectedDateModal">
          {moment(selectedDate).format('dddd')},{' '}
          {moment(selectedDate).format('DD-MMM-YYYY')}
        </div>
        <div className="containerListExamSchedule">
          {listExamSchedule.map((data, index) => {
            const dataButton = [
              {
                type: 'link',
                id: `btnDetailSchedule${index}`,
                className: 'btnDetailSchedule',
                content: 'Detail',
                onClick: () => handleOnClickDetail(data),
              },
            ];

            return (
              <div
                key={`${data.code}${index}`}
                className="containerExamSchedule"
              >
                {data.examType === 0 ? (
                  <>
                    <Tag
                      color={'#FB6915'}
                      style={{
                        borderRadius: 5,
                        width: 120,
                        textAlign: 'center',
                      }}
                    >
                      Examination
                    </Tag>
                    <CICon
                      type="FieldTimeOutlined"
                      style={{ marginLeft: 10, marginRight: 10 }}
                    />
                    {data.startTime} - {data.endTime}
                    <CICon
                      type="DesktopOutlined"
                      style={{
                        marginLeft: 30,
                        marginRight: 10,
                        color: '#1DB227',
                      }}
                    />
                    <span style={{ color: '#1DB227' }}>Online</span>
                    <CICon
                      type="EnvironmentOutlined"
                      style={{ marginLeft: 30, marginRight: 10 }}
                    />
                    {data.city}
                  </>
                ) : (
                  <>
                    <Tag
                      color={'#FB6915'}
                      style={{
                        borderRadius: 5,
                        width: 120,
                        textAlign: 'center',
                      }}
                    >
                      Examination
                    </Tag>
                    <CICon
                      type="MobileOutlined"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                        color: '#009EE0',
                        fontWeight: 'bold',
                      }}
                    />
                    <span style={{ color: '#009EE0', fontWeight: 'bold' }}>
                      Mobile Apps
                    </span>
                  </>
                )}
                <CButton buttonData={dataButton} buttonFloat="right" />
              </div>
            );
          })}
        </div>
      </>
    );
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      handleCancel={handleCancel}
      modalIsShow={modalIsShow}
      initialValues={[]}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
    />
  );
}

import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import CCard from '../../../Assets/Components/CCard';
import CDashboardComponent from '../../../Assets/Components/CDashboardComponent';
import CIcon from '../../../Assets/Components/CIcon';
import { injectIntl } from 'react-intl';

function DashboardUniqueLoginComponent({
  data,
  intl,
}: {
  data?: any;
  intl: any;
}) {
  const dateData = () => (
    <p className="periodDashboard">
      <CIcon className="iconPeriodDashboard" type="CalendarOutlined" />
      {moment().format('MMMM YYYY')}
    </p>
  );
  const dateDataPrev = () => (
    <p className="periodDashboard">
      <CIcon className="iconPeriodDashboard" type="CalendarOutlined" />
      {moment().subtract(1, 'months').format('MMMM YYYY')}
    </p>
  );
  const content = () => (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <CDashboardComponent
            title="Previous Period"
            description={dateDataPrev()}
            value={data?.totalPrevPeriodUniqueLogin}
          />
        </Col>
        <Col span={12}>
          <CDashboardComponent
            title="Current Period"
            description={dateData()}
            value={
              moment().format('DD') === '01'
                ? 0
                : data?.totalCurrPeriodUniqueLogin
            }
          />
        </Col>
      </Row>
    </React.Fragment>
  );
  return (
    <CCard
      cardClassName="cardDashboard"
      cardTitle={
        <span className="titleCardDashboard">
          {intl.formatMessage({
            id: 'dashboardUniqueLoginTitle',
          })}
        </span>
      }
      cardContent={content()}
    />
  );
}
export default injectIntl(DashboardUniqueLoginComponent);

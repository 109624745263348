import { Button } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import { InputChecbox } from '../../../Assets/Components/CInputCheckbox';

interface IProps {
  handleNext: () => void;
  termsIsChecked: boolean;
  setTermsIsChecked: Dispatch<SetStateAction<boolean>>;
  disclaimer: any;
}

export default function TermsConditionsComponent(props: IProps) {
  const { handleNext, termsIsChecked, setTermsIsChecked, disclaimer } = props;
  let div = document.createElement('div');
  div.innerHTML = disclaimer?.disclaimerDesc;
  let disclaimerDesc = div.textContent || div.innerText || '';

  return (
    <div className="termsContentRecruitment">
      <div className="termsTitleRecruitment">Pernyataan Calon Leads</div>
      <div className="descTerms">{disclaimerDesc || ''}</div>
      <div>
        <InputChecbox
          disabled={false}
          idComponent="checkboxTerms"
          meta={{ touched: false, error: false }}
          input={{
            onBlur: null,
            onChange: setTermsIsChecked,
            value: termsIsChecked || false,
          }}
          content="Saya telah Baca dan Setuju"
        />
      </div>
      <hr className="hrTerms" />
      <div className="btnTerms">
        <Button
          id="btnTermsNext"
          type="primary"
          onClick={handleNext}
          disabled={!termsIsChecked}
          className="btnTermsNext"
        >
          Lanjutkan
        </Button>
      </div>
    </div>
  );
}

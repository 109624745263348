import { DatePicker, Form } from 'antd';

import React from 'react';
import moment from 'moment';

const formatDate = 'YYYY-MM-DD';

interface IPropsDatePicker {
  input?: any;
  meta?: any;
  placeholder?: any;
  idComponent?: string;
  icon?: any;
  label?: string;
  data?: any;
  disabled?: any;
  typeDate?: string;
  disabledDate?: any;
  update?: any;
  formClassName?: any;
  formatInput?: any;
  formatPicker?: any;
  addtionalLabel?: any;
  className?: string;
  labelIsRequired?: boolean;
}

const convertToMoment: any = val => {
  return moment(val);
};

const processDateRangePicker = params => {
  if (moment.isMoment(params)) {
    return params;
  } else if (params) {
    return convertToMoment(params);
  } else {
    return undefined;
  }
};

const handleDefaultValue = (inputValue, typeDate) => {
  if (inputValue) {
    if (typeDate === 'dateRangePicker') {
      if (typeof inputValue === 'object') {
        const startDate = processDateRangePicker(inputValue[0]);
        const endDate = processDateRangePicker(inputValue[1]);
        return [startDate, endDate];
      }
    } else {
      if (moment.isMoment(inputValue)) {
        return inputValue;
      } else {
        return convertToMoment(inputValue);
      }
    }
  }
  return undefined;
};

export const InputDate = (props: IPropsDatePicker) => {
  const {
    input,
    meta,
    idComponent,
    label,
    disabled,
    typeDate,
    disabledDate,
    update,
    formClassName,
    formatInput,
    formatPicker,
    placeholder,
    addtionalLabel,
    className,
    labelIsRequired,
  } = props;

  const { error, touched } = meta || {};

  const handleOnChange = e => {
    input.onChange(e);
  };
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };

  const labelTitle = (
    <span>
      <b className="capital">{label}</b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
        {lebelRequired()}
      </span>
    </span>
  );
  const inputValue = input.value;
  const renderPicker = () => {
    if ((update && typeof inputValue === 'object') || !update) {
      if (typeDate === 'dateRangePicker') {
        return (
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            id={idComponent}
            format={formatInput ? formatInput : formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
            picker={formatPicker}
            placeholder={placeholder}
            className={className}
            onBlur={input.onBlur}
          />
        );
      }
      return null;
    }
    return null;
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      {renderPicker()}
    </Form.Item>
  );
};

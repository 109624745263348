import React from 'react';
import CityUploadModalComponent from '../Component/CityUploadModalComponent';

interface IProps {
  modalUploadIsShow: boolean;
  setModalUploadIsShow: any;
}
export default function CityUploadModalContainer(props: IProps) {
  const { modalUploadIsShow, setModalUploadIsShow } = props;
  const handleCancel = () => {
    setModalUploadIsShow(false);
  };
  return (
    <CityUploadModalComponent
      modalUploadIsShow={modalUploadIsShow}
      handleCancel={handleCancel}
    />
  );
}

import { Tabs } from 'antd';
import { injectIntl } from 'react-intl';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import ContestTabHistoryContainer from '../Container/ContestTabHistoryContainer';
import React from 'react';
import ContestTabUploadFileContainer from '../Container/ContestTabUploadFileContainer';

function ContestUploadComponent(props: { intl: any }) {
  const { intl } = props;
  const { TabPane } = Tabs;

  const componentTabPane = (title: string, id: string) => {
    return (
      <div className="tabPaneContestUpload" id={id}>
        {title}
      </div>
    );
  };

  const cardContestTargetContent = () => {
    return (
      <Tabs defaultActiveKey="uploadFile" className="tabContestUpload">
        <TabPane
          tab={componentTabPane('New Upload', 'tabUploadFile')}
          key="uploadFile"
        >
          <ContestTabUploadFileContainer />
        </TabPane>
        <TabPane
          tab={componentTabPane('Upload History', 'tabUploadHistory')}
          key="historyUpload"
        >
          <ContestTabHistoryContainer />
        </TabPane>
      </Tabs>
    );
  };

  return (
    <>
      <CLabelCard leftText="Monthly" rightText="Upload" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'contestTargetCardTitle',
            })}
          </span>
        }
        cardContent={cardContestTargetContent()}
      />
    </>
  );
}

export default injectIntl(ContestUploadComponent);

import React from 'react';
import { Tag } from 'antd';
import moment from 'moment';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import CICon from '../../../Assets/Components/CIcon';

interface IProps {
  detailExamSchedule: any;
  handleCancel: () => void;
  modalIsShow: boolean;
}

export default function ModalDetailExamScheduleComponent(props: IProps) {
  const { handleCancel, modalIsShow, detailExamSchedule } = props;

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Detail Schedule</h3>;
  };

  const contentComponent = () => {
    if (detailExamSchedule) {
      if (detailExamSchedule.examType === 0)
        return (
          <>
            <div className="selectedDateModal">
              {moment(detailExamSchedule.examDate).format('dddd')},{' '}
              {moment(detailExamSchedule.examDate).format('DD-MMM-YYYY')}
            </div>
            <div className="containerDetailExamSchedule">
              <Tag
                color={'#FB6915'}
                style={{ borderRadius: 5, width: 120, textAlign: 'center' }}
              >
                Examination
              </Tag>
              <CICon
                type="DesktopOutlined"
                style={{
                  marginLeft: 30,
                  marginRight: 10,
                  color: '#1DB227',
                }}
              />
              <span style={{ color: '#1DB227' }}>Online</span>
              <div style={{ marginTop: 5 }}>
                <CICon type="FieldTimeOutlined" style={{ marginRight: 10 }} />
                <span>
                  {detailExamSchedule.startTime} - {detailExamSchedule.endTime}
                </span>
              </div>
              <div style={{ marginTop: 5 }}>
                <CICon type="EnvironmentOutlined" style={{ marginRight: 10 }} />
                <span>{detailExamSchedule.city}</span>
              </div>
              <div style={{ marginTop: 5 }}>{detailExamSchedule.address}</div>
              <hr className="lineDetailSchedule" />
              <div>
                <span style={{ fontWeight: 'bold' }}>Peserta </span>(
                {detailExamSchedule.participants.length} Orang)
                <div className="containerParticipants">
                  <ol>
                    {detailExamSchedule.participants.map((data, index) => {
                      return (
                        <li key={`participants${index}`}>
                          {data.candidateName}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </>
        );
      else
        return (
          <>
            <div className="selectedDateModal"></div>
            <div className="containerDetailExamSchedule">
              <Tag
                color={'#FB6915'}
                style={{ borderRadius: 5, width: 120, textAlign: 'center' }}
              >
                Examination
              </Tag>
              <CICon
                type="MobileOutlined"
                style={{
                  marginLeft: 10,
                  marginRight: 10,
                  color: '#009EE0',
                  fontWeight: 'bold',
                }}
              />
              <span style={{ color: '#009EE0', fontWeight: 'bold' }}>
                Mobile Apps
              </span>
              <hr className="lineDetailSchedule" />
              <div>
                <span style={{ fontWeight: 'bold' }}>Peserta </span>(
                {detailExamSchedule.participants.length} Orang)
                <div className="containerParticipants">
                  <ol>
                    {detailExamSchedule.participants.map((data, index) => {
                      return (
                        <li key={`participants${index}`}>
                          {data.candidateName}
                        </li>
                      );
                    })}
                  </ol>
                </div>
              </div>
            </div>
          </>
        );
    } else return null;
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      handleCancel={handleCancel}
      initialValues={[]}
      modalIsShow={modalIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
    />
  );
}

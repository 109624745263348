import moment from 'moment';
import instance from '../interceptor';
import { IResultContestUploadDAO } from '../../Modules/ContestUpload/DAO/contestUpload.dao';

export const getContestUploadAPI = async (): Promise<
  IResultContestUploadDAO[]
> => {
  const { data } = await instance.get(`/contest/final/upload`);
  return data;
};

export const mutateContestUploadAPI = async (params: any) => {
  const {
    chanel,
    contestLevel,
    contestType,
    dateContest,
    group,
    id,
    monitoringPeriode,
    numberOfTarget,
  } = params;

  const targets: object[] = [];

  for (let index = 0; index < numberOfTarget; index++) {
    const dataDetailTarget = {
      rewardId: params[`rewardId${index}`] ? params[`rewardId${index}`] : 0,
      name: params[`targetName${index}`],
      fyp: params[`rewardFyp${index}`].toString(),
      nbFyp: params[`rewardNbFyp${index}`].toString(),
      persistency: params[`rewardPersistency${index}`],
      numberOfTicket: params[`rewardNumberOfTicket${index}`]
        ? params[`rewardNumberOfTicket${index}`]
        : 0,
    };
    targets.push(dataDetailTarget);
  }

  const payload: any = {
    chanel,
    contestLevel,
    contestType,
    endDate: moment(dateContest[1]).format('YYYY-MM-DD'),
    group,
    monitoringPeriode: parseInt(monitoringPeriode),
    startDate: moment(dateContest[0]).format('YYYY-MM-DD'),
    targets,
  };

  if (id) {
    return await instance.put(`/contest/${id}`, payload);
  } else {
    return await instance.post(`/contest`, payload);
  }
};

export const uploadContestFileAPI = async (params: any) => {
  const { contestType, year, monthlyUpload } = params;
  const formData = new FormData();
  const newd = {
    ...monthlyUpload[0].file,
    year,
    contestType,
    originalFilename: monthlyUpload[0].name,
  };
  formData.append('file', monthlyUpload[0].file);
  console.log('params', params, formData, newd);

  var object = {};
  formData.forEach((value, key) => {
    object[key] = value;
  });
  var json = JSON.stringify(object);
  console.log('json', json);
};

import { Col, Row, Skeleton, Tag } from 'antd';
import React, { Dispatch, SetStateAction } from 'react';
import CButton from '../../../../Assets/Components/CButton';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { DynamicTabs } from '../../../../Assets/Components/CDynamicTabs';
import { IntlShape } from 'react-intl';
import moment from 'moment';
import ModalCancelCandidateContainer from '../../Container/Modal/ModalCancelCandidateContainer';
import ModalContentCleanContainer from '../../Container/Modal/ModalContent/ModalContentCleanContainer';
import ModalContentAAJIContainer from '../../Container/Modal/ModalContent/ModalContentAAJIContainer';

interface IProps {
  intl: IntlShape;
  modalDetailIsShow: boolean;
  handleCancel: () => void;
  selectedData: any;
  column: any;
  schema: any;
  schemaClean: any;
  agreementList: any;
  detailCandidate: any;
  handleEdit: () => void;
  modalCancelIsShow: boolean;
  setModalCancelIsShow: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setModalDetailIsShow: Dispatch<SetStateAction<boolean>>;
  refreshList: any;
  modalContentCleanIsShow: any;
  setModalContentCleanIsShow: Dispatch<SetStateAction<boolean>>;
  qaStatus: string;
  documentsContent: any;
  setQaStatus: Dispatch<SetStateAction<string>>;
  refreshSchema: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  documentMeta: any;
  setValuesAAJI: Dispatch<SetStateAction<undefined>>;
  valuesAAJI: any;
  candidateApprovers: any;
  isUploading: boolean;
}
export default function ModalDetailQAComponent(props: IProps) {
  const {
    intl,
    modalDetailIsShow,
    handleCancel,
    selectedData,
    column,
    schema,
    schemaClean,
    agreementList,
    detailCandidate,
    handleEdit,
    modalCancelIsShow,
    setModalCancelIsShow,
    setModalDetailIsShow,
    refreshList,
    modalContentCleanIsShow,
    setModalContentCleanIsShow,
    qaStatus,
    documentsContent,
    setQaStatus,
    refreshSchema,
    handleUploadContent,
    documentMeta,
    isLoading,
    setValuesAAJI,
    valuesAAJI,
    candidateApprovers,
    isUploading,
  } = props;

  const agencyData =
    detailCandidate?.dataSubmission?.submission?.personaldata?.agency;
  const taxBankData =
    detailCandidate?.dataSubmission?.submission?.personaldata?.taxbankaccount;
  const profileAgency = {
    candidateLevel: agencyData?.candidateLevel,
    agencyLevel: candidateApprovers?.approverLevel,
    recruiter: selectedData?.refAgentName,
    agency: agencyData?.agency,
    directLeader: agencyData?.directLeader,
    salesOffice: agencyData?.salesOffice,
  };

  const personalData = {
    agency: profileAgency,
    profile: detailCandidate?.dataSubmission?.submission?.personaldata?.profile,
    addresseducation:
      detailCandidate?.dataSubmission?.submission?.personaldata
        ?.addresseducation,
    taxbankaccount: {
      bank: taxBankData?.bank,
      tax: {
        taxpayer: taxBankData?.tax?.taxpayer,
        taxownership: taxBankData?.tax?.taxownership,
        taxdependents: taxBankData?.tax?.taxdependents,
      },
    },
  };

  const jsonTemp = {
    personaldata: personalData,
    beneficiary: detailCandidate?.dataSubmission?.submission?.beneficiary,
    workexperience: detailCandidate?.dataSubmission?.submission?.workexperience,
    documents: detailCandidate?.dataSubmission?.submission?.documents,
    useragreements: detailCandidate?.dataSubmission?.submission?.useragreements,
    domicileaddress:
      detailCandidate?.dataSubmission?.submission?.domicileaddress,
  };

  const dataButton = [
    {
      type: 'primary',
      id: 'btnCancelQa',
      className: 'btnCancelQa',
      content: 'Batalkan',
      onClick: () => {
        setModalCancelIsShow(true);
      },
    },
    {
      type: 'primary',
      id: 'btnEditQa',
      className: 'btnEditQa',
      icon: 'EditOutlined',
      content: 'Edit',
      onClick: handleEdit,
    },
  ];

  const tagStat = (tagStatus: string, color: string, fontColor: string) => {
    return (
      <Tag color={color}>
        <div style={{ color: fontColor, width: 100, textAlign: 'center' }}>
          {tagStatus}
        </div>
      </Tag>
    );
  };

  const renderStatus = (status: any) => {
    switch (status) {
      case 'AAJI Screening': {
        return tagStat('AAJI Screening', '#4066B3', '#FFFFFF');
      }
      case 'QA Not Clean': {
        return tagStat('QA Not Clean', '#FB6915', '#FFFFFF');
      }
      case 'QA Process': {
        return tagStat('QA Process', '#A9A9A9', '#000000');
      }
      case 'Pending Update': {
        return tagStat('Pending Update', '#F69C00', '#FFFFFF');
      }
    }
    return null;
  };

  const headerComponent = () => {
    return <h3 className="headerTitle capital">QA Detail</h3>;
  };

  const detailCandidateLevel = selectedData ? selectedData.candidateLevel : '';

  const renderDetailCandidate = (label: string, value: any) => {
    return (
      <Row style={{ marginBottom: 5 }}>
        <Col span={10}>{label}</Col>
        <Col span={1}>:</Col>
        <Col span={10}>{value}</Col>
      </Row>
    );
  };

  const contentComponent = (formProps: any) => {
    const renderContent = (status: any) => {
      if (status === 'AAJI Screening') {
        return (
          <ModalContentAAJIContainer
            formValue={formProps.values}
            intl={intl}
            documentsContent={documentsContent}
            submission={detailCandidate?.dataSubmission?.submission}
            aajiSubmission={detailCandidate?.aajiSubmission}
            setModalDetailIsShow={setModalDetailIsShow}
            refreshList={refreshList}
            handleUploadContent={handleUploadContent}
            documentMeta={documentMeta}
            setValuesAAJI={setValuesAAJI}
            isUploading={isUploading}
          />
        );
      } else {
        return (
          <ModalContentCleanContainer
            modalContentCleanIsShow={modalContentCleanIsShow}
            setModalContentCleanIsShow={setModalContentCleanIsShow}
            qaStatus={qaStatus}
            intl={intl}
            formValue={formProps.values}
            form={formProps.form}
            selectedData={selectedData}
            schema={schemaClean}
            schemaCandidate={schema}
            setQaStatus={setQaStatus}
            setModalDetailIsShow={setModalDetailIsShow}
            refreshList={refreshList}
            refreshSchema={refreshSchema}
          />
        );
      }
    };

    return (
      <>
        <Skeleton
          active
          loading={selectedData && !valuesAAJI ? isLoading : false}
        >
          <div className="containerContentDetailQa">
            <div className="containerCardDetailRegistration">
              {renderContent(selectedData ? selectedData?.candidateStatus : '')}
            </div>
            <div className="containerCardDetailRegistration">
              <Row>
                <Col span={6}>
                  <div className="titleDetailCandidate">Data Kandidat</div>
                </Col>
                <Col span={8}>
                  <CButton buttonData={dataButton} />
                </Col>
                <Col span={10}>
                  <div className="containerStatusQa">
                    <span>Status Saat ini:</span>
                    <br />
                    {renderStatus(
                      selectedData ? selectedData?.candidateStatus : ''
                    )}
                  </div>
                </Col>
              </Row>
              <hr className="lineDetailQa" />
              <Row gutter={12} style={{ marginBottom: 20 }}>
                <Col span={12}>
                  {renderDetailCandidate(
                    'Nomor Aplikasi',
                    selectedData ? selectedData.contractNumber : ''
                  )}
                  {renderDetailCandidate('Sub Channel', 'AG')}
                  {renderDetailCandidate(
                    'Nama Kandidat',
                    selectedData ? selectedData.name : ''
                  )}
                  {renderDetailCandidate(
                    'Level Kandidat',
                    selectedData ? selectedData.candidateLevel : ''
                  )}
                </Col>
                <Col span={12}>
                  {renderDetailCandidate(
                    'Perekrut',
                    selectedData ? selectedData.refAgentName : ''
                  )}
                  {renderDetailCandidate(
                    'Level Perekrut',
                    candidateApprovers ? candidateApprovers?.approverLevel : '-'
                  )}
                  {renderDetailCandidate(
                    'Tanggal Direkrut',
                    selectedData
                      ? moment(selectedData.timestamp).format('DD-MMM-YYYY')
                      : ''
                  )}
                  {renderDetailCandidate(
                    'Agensi',
                    selectedData ? selectedData.refAgencyName : ''
                  )}
                  {renderDetailCandidate(
                    'Kantor Sales',
                    detailCandidate?.dataSubmission
                      ? String(
                          detailCandidate?.dataSubmission?.submission
                            ?.personaldata?.agency?.salesOffice
                        ).split('|')[0]
                      : '-'
                  )}
                </Col>
              </Row>
              {detailCandidate && detailCandidate?.dataSubmission && schema ? (
                <>
                  <DynamicTabs
                    json={jsonTemp}
                    intl={intl}
                    column={column}
                    schema={schema.schema.properties}
                    candidateLevel={detailCandidateLevel}
                    documents={detailCandidate?.documentContentData || []}
                    docAgreement={agreementList}
                  />
                </>
              ) : null}
            </div>
          </div>
        </Skeleton>
      </>
    );
  };

  return (
    <>
      <CModalRFF
        modalIsShow={modalDetailIsShow}
        headerComponent={headerComponent()}
        contentComponent={(formProps: any) => contentComponent(formProps)}
        footerComponent={null}
        handleCancel={handleCancel}
        handleValidation={() => {}}
        isForm={true}
        handleSubmit={() => {}}
        formLayout="vertical"
        initialValues={selectedData}
        modalstyles={{
          content: {
            position: 'relative',
            background: 'none',
            maxWidth: '95vw',
            width: '85vw',
            padding: '0px',
            border: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.75)',
            zIndex: '99',
          },
        }}
      />
      <ModalCancelCandidateContainer
        modalCancelIsShow={modalCancelIsShow}
        setModalCancelIsShow={setModalCancelIsShow}
        detailCandidate={selectedData}
        setModalDetailIsShow={setModalDetailIsShow}
        refreshList={refreshList}
      />
    </>
  );
}

import React from 'react';
import NotificationComponent from '../Component/NotificationComponent';
import { useQuery } from '@tanstack/react-query';
import { getNotificationListAPI } from '../../../Services/notification.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function NotificationContainer() {
  const {
    data: notificationList,
    isLoading,
    isRefetching,
    fetchStatus,
  } = useQuery(['getNotificationList'], getNotificationListAPI, {
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const columnData = [
    {
      Header: 'Notification Type',
      accessor: 'notificationType',
    },
    {
      Header: 'Notification Content',
      accessor: 'notificationContent',
    },
  ];
  return (
    <NotificationComponent
      notificationList={notificationList || []}
      columnData={columnData}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
    />
  );
}

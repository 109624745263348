import Table, { generateColumnData } from '../../../Assets/Components/CTable';

import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import ModalDetailUploadContainer from '../Container/ModalDetailUploadSheduleContainer';
import ModalUploadContainer from '../Container/ModalUploadScheduleContainer';
import React from 'react';
import { injectIntl } from 'react-intl';

interface IProps {
  uploadScheduleList: any;
  columnData: any;
  handleRefresh: any;
  isLoading: boolean;
  modalDetailScheduleIsShow: boolean;
  setModalDetailScheduleIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  modalUploadScheduleIsShow: boolean;
  setModalUploadScheduleIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

function UploadScheduleComponent(props: IProps) {
  const {
    uploadScheduleList,
    columnData,
    handleRefresh,
    isLoading,
    modalDetailScheduleIsShow,
    setModalDetailScheduleIsShow,
    selectedData,
    modalUploadScheduleIsShow,
    setModalUploadScheduleIsShow,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2',
      icon: 'UploadOutlined',
      onClick: () => setModalUploadScheduleIsShow(true),
      content: 'Unggah',
      id: 'btnUploadSchedule',
    },
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshUploadList',
    },
  ];
  const uploadScheduleContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
        />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={uploadScheduleList || []}
          pagination={true}
          useFilterGlobal
        />
        <ModalUploadContainer
          modalUploadScheduleIsShow={modalUploadScheduleIsShow}
          setModalUploadScheduleIsShow={setModalUploadScheduleIsShow}
        />
        <ModalDetailUploadContainer
          modalDetailScheduleIsShow={modalDetailScheduleIsShow}
          setModalDetailScheduleIsShow={setModalDetailScheduleIsShow}
          selectedData={selectedData}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Schedule" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Daftar & Unggah Schedule</span>
        }
        cardContent={uploadScheduleContent()}
      />
    </React.Fragment>
  );
}

export default injectIntl(UploadScheduleComponent);

import { Col, Form, Row } from 'antd';
import { InputText, TextInput } from '../../Components/CInput';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';

import CButton from '../CButton';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { InputDatePicker } from '../../Components/CInputDate';
import { RadioButton } from '../../../Assets/Components/CRadio';
import React from 'react';
import { ViewImage } from '../CViewImageFormRegis';
import { createListSelectBox } from '../../../App/Helper';
import { InputChecbox } from '../CInputCheckbox';
import Select from '../Select';
import { InputFileRegisForm } from '../InputFileRegisForm';

const required = (value: any) =>
  value || value === false ? undefined : 'Wajib diisi!';

const validationAddres = (value: any) =>
  value && !/^[A-Za-z0-9 ]+$/.test(value)
    ? 'Alamat anda mengandung tanda baca'
    : '';
const validationSpesialCaracter = (value: any) =>
  value && !/^[A-Za-z 0-9/._, ]+$/.test(value)
    ? 'Alamat tempat tinggal mengandung tanda kutip [‘]'
    : undefined;

const addtionalLabel = (required: any, secondName: any, propsDynamic: any) => {
  if (secondName === 'bank') {
    if (
      propsDynamic.values &&
      propsDynamic.values.personaldata &&
      propsDynamic.values.personaldata.taxbankaccount &&
      propsDynamic.values.personaldata.taxbankaccount.bank &&
      propsDynamic.values.personaldata.taxbankaccount.bank.name
    ) {
      return null;
    } else {
      return '(Optional)';
    }
  } else if (secondName === 'tax' || required) {
    return null;
  } else {
    return '(Optional)';
  }
};

export const renderComponentForm = (
  formName: string,
  sectionKey: any,
  detail: any,
  formModulName: string,
  disabledCandidateLevel: any,
  disabledRecruiterDL: any,
  disabledAgency: any,
  propsDynamic: any,
  documentList: any,
  documents: any,
  isEdited: any,
  handleSelectHaveNPWP: any,
  totalOtherattachment: number,
  onClickOtherDocument: any,
  handlers: any,
  modalAction: any,
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void,
  handleOnChange: (values: any, form: any) => void,
  additionalOnChange: (domicileaddress: any, formValue: any, form: any) => void
) => {
  switch (detail.template) {
    case 'TextInput': {
      const disabledTextInput = () => {
        if (!isEdited) {
          return !isEdited;
        } else {
          return false;
        }
      };
      const lengthTextInput = () => {
        if (detail.maxLength) {
          return detail.maxLength;
        } else {
          return null;
        }
      };
      const validateTextInput = () => {
        if (detail.secondName === 'tax') {
          return undefined;
        } else if (detail.isRequired) {
          return required;
        } else {
          return undefined;
        }
      };
      return (
        <div id={`InputComponent${detail.name}`}>
          <Field
            name={
              detail.secondName
                ? `${formName}.${sectionKey}.${detail.secondName}.${detail.name}`
                : `${formName}.${sectionKey}.${detail.name}`
            }
            component={InputText}
            idComponent={`InputComponent${detail.name}`}
            placeholder={detail.config.placeholder}
            label={detail.title}
            addtionalLabel={addtionalLabel(
              detail.isRequired,
              detail.secondName,
              propsDynamic
            )}
            textPrefix={detail.prefix}
            validate={validateTextInput()}
            className="inputRegistration"
            formClassName="registrationForm"
            textArea={detail.config.multiline}
            maxlength={lengthTextInput()}
            disabled={disabledTextInput()}
            labeldisclaimer={detail.config?.labelDisclaimer}
          />
        </div>
      );
    }
    case 'SelectBox': {
      let data: any;
      if (detail.enumValue) {
        data = createListSelectBox(detail.enum, detail.enumValue);
      } else if (detail.enum) {
        data = createListSelectBox(detail.enum);
      } else {
        data = [];
      }
      const disabledSelectBox = () => {
        if (detail.name === 'agencyLevel') {
          return true;
        } else if (!isEdited) {
          return !isEdited;
        } else if (detail.name === 'agency') {
          return disabledAgency;
        } else if (
          detail.name === 'recruiter' ||
          detail.name === 'directLeader'
        ) {
          return disabledRecruiterDL;
        } else if (detail.name === 'candidateLevel') {
          return disabledCandidateLevel;
        } else {
          return false;
        }
      };
      const dataSelectBox = () => {
        if (detail.secondName === 'bank' && detail.name === 'name') {
          return propsDynamic.bank;
        } else if (propsDynamic[detail.name] !== undefined) {
          return propsDynamic[detail.name];
        } else {
          return data;
        }
      };

      const validateSelectBox = () => {
        if (detail.secondName === 'tax' || detail.name === 'directLeader') {
          return undefined;
        } else if (detail.isRequired) {
          return required;
        } else {
          return undefined;
        }
      };
      const customOptionSelectBox = () => {
        if (detail.name === 'agencyLevel') {
          return 'labelOnly';
        } else {
          return null;
        }
      };
      return (
        <Field
          name={
            detail.secondName
              ? `${formName}.${sectionKey}.${detail.secondName}.${detail.name}`
              : `${formName}.${sectionKey}.${detail.name}`
          }
          component={Select}
          idComponent={`InputComponent${detail.name}`}
          defaultItemName={detail.config ? detail.config.placeholder : ''}
          label={detail.title}
          addtionalLabel={addtionalLabel(
            detail.isRequired,
            detail.secondName,
            propsDynamic
          )}
          dataOption={dataSelectBox()}
          validate={validateSelectBox()}
          formClassName="registrationForm"
          disabled={disabledSelectBox()}
          onSelect={
            detail.events && handlers && handlers[detail.name]
              ? handlers[detail.name]
              : null
          }
          customOption={customOptionSelectBox()}
          showSearch={detail.config ? detail.config.searchable : false}
          filterOption={true}
        />
      );
    }
    case 'DatePicker': {
      return (
        <Field
          name={
            detail.secondName
              ? `${formName}.${sectionKey}.${detail.secondName}.${detail.name}`
              : `${formName}.${sectionKey}.${detail.name}`
          }
          component={InputDatePicker}
          idComponent={`InputComponent${detail.name}`}
          typeDate={detail?.customTemplate ? 'dateRangePicker' : 'datePicker'}
          label={detail?.title ? detail?.title : detail?.config?.web?.title}
          addtionalLabel={addtionalLabel(
            detail.isRequired,
            detail.secondName,
            propsDynamic
          )}
          formatPicker={detail.config.format ? 'year' : undefined}
          formatInput={detail.config.format ? 'YYYY' : undefined}
          validate={detail.isRequired ? required : undefined}
          formClassName="registrationForm"
          disabled={!isEdited ? !isEdited : false}
        />
      );
    }
    case 'RadioGroup': {
      const listData = detail.enumValue
        ? createListSelectBox(detail.enum, detail.enumValue)
        : createListSelectBox(detail.enum);
      return (
        <Field
          name={
            detail.secondName
              ? `${formName}.${sectionKey}.${detail.secondName}.${detail.name}`
              : `${formName}.${sectionKey}.${detail.name}`
          }
          component={RadioButton}
          idComponent={`InputComponent${detail.name}`}
          label={detail.title}
          addtionalLabel={addtionalLabel(
            detail.isRequired,
            detail.secondName,
            propsDynamic
          )}
          dataOption={listData}
          validate={detail.isRequired ? required : undefined}
          formClassName="registrationForm"
          disabled={!isEdited ? !isEdited : false}
          addtionalFunction={
            detail.name === 'hastax' ? handleSelectHaveNPWP : null
          }
          defaultValue={detail.name === 'hastax' ? true : null}
        />
      );
    }
    case 'MultipleInput': {
      const labelTitle = detail.title ? (
        <div style={{ width: '100%' }}>
          <div className={`labelMultipleInput`}>{detail.title}</div>
          {detail.buttons ? (
            <Field
              name={detail.name}
              component={InputChecbox}
              content={'Sama seperti alamat KTP'}
              className="checkboxAddres"
              disabled={!isEdited ? !isEdited : false}
              handleOnChange={handleOnChange(
                propsDynamic?.values,
                propsDynamic?.form
              )}
              additionalOnChange={(e: any) =>
                additionalOnChange(e, propsDynamic?.values, propsDynamic?.form)
              }
            />
          ) : null}
        </div>
      ) : null;
      const components: any = [];
      const formError =
        propsDynamic.values &&
        Object.keys(propsDynamic.values).length > 0 &&
        propsDynamic.values[formName] &&
        propsDynamic.values[formName][sectionKey] &&
        propsDynamic.values[formName][sectionKey][detail.name]
          ? propsDynamic.errors &&
            propsDynamic.errors[formName] &&
            propsDynamic.errors[formName][sectionKey] &&
            propsDynamic.errors[formName][sectionKey][detail.name]
          : undefined;
      const textValidation = (textError: any) => {
        let text: any;
        for (const iterator of textError) {
          if (iterator !== undefined) {
            text = iterator;
            break;
          }
        }
        return text;
      };
      return (
        <Form.Item
          style={{ marginBottom: 5 }}
          label={labelTitle}
          validateStatus={formError !== undefined ? 'error' : ''}
          help={formError !== undefined ? textValidation(formError) : ''}
        >
          {detail.properties.map((data: any, index: number) => {
            components.push(`${detail.name}_${index}`);
            return (
              <div key={`${detail.name}_${index}`}>
                <Field
                  name={`${formName}.${sectionKey}.${detail.name}.${index}`}
                  component={TextInput}
                  idComponent={`InputComponent${detail.name}`}
                  placeholder={data.config ? data.config.placeholder : ''}
                  disabled={!isEdited || false}
                  validate={
                    index === 0
                      ? validationAddres
                      : validationSpesialCaracter || undefined
                  }
                />
              </div>
            );
          })}
        </Form.Item>
      );
    }
    case 'FileInput': {
      const uppyConfig: any = {};
      let nameField: any;
      if (detail.secondName) {
        nameField = `${formName}.${sectionKey}.${detail.secondName}.${detail.name}`;
      } else if (formName === 'documents') {
        nameField = `${formName}.${detail.name}`;
      } else {
        nameField = `${formName}.${sectionKey}.${detail.name}`;
      }
      for (const iterator of documentList) {
        if (iterator.name === detail.name) {
          uppyConfig.maxFile = 1;
          uppyConfig.minFile = 1;
          uppyConfig.allowedFile = iterator.extension;
          uppyConfig.maxSize = parseInt(iterator.maxSize, 10) * 1048576;
          uppyConfig.documentCode = iterator.code;
          uppyConfig.documentType = iterator.type;
          uppyConfig.name = iterator.name;
          uppyConfig.sectionKey = formName;
          uppyConfig.fileName = iterator.fileName;
          uppyConfig.documentGroup = iterator.documentGroup;
        }
        if (documents[detail.name]) {
          uppyConfig.contentId = documents[detail.name].id;
          uppyConfig.folderId = documents[detail.name].folderId;
          uppyConfig.repositoryId = documents[detail.name].repositoryId;
        }
      }

      const valueForm = () => {
        if (
          propsDynamic.values &&
          Object.keys(propsDynamic.values).length > 0
        ) {
          if (
            propsDynamic.values[formName] &&
            Object.keys(propsDynamic.values[formName]).length > 0
          ) {
            if (sectionKey === formName) {
              return propsDynamic.values[formName][detail.name];
            } else if (
              propsDynamic.values[formName][sectionKey] &&
              Object.keys(propsDynamic.values[formName][sectionKey]).length > 0
            ) {
              return propsDynamic.values[formName][sectionKey][detail.name];
            } else {
              return undefined;
            }
          } else {
            return undefined;
          }
        } else {
          return undefined;
        }
      };

      const optionaLabel = () => {
        if (
          propsDynamic.values &&
          propsDynamic.values.personaldata &&
          propsDynamic.values.personaldata.taxbankaccount &&
          propsDynamic.values.personaldata.taxbankaccount.bank &&
          propsDynamic.values.personaldata.taxbankaccount.bank.name &&
          detail.name === 'bankpassbook'
        ) {
          return null;
        } else {
          return addtionalLabel(
            detail.isRequired,
            detail.secondName,
            propsDynamic
          );
        }
      };

      if (detail.name === 'otherattachments') {
        const docOtther: any = [];
        const configOther: any = [];
        for (let index = 0; index < totalOtherattachment; index++) {
          const valueDocOther = () => {
            if (
              propsDynamic.values &&
              Object.keys(propsDynamic.values).length > 0
            ) {
              if (
                propsDynamic.values[formName] &&
                Object.keys(propsDynamic.values[formName]).length > 0
              ) {
                return propsDynamic.values[formName][`${detail.name}${index}`];
              } else {
                return undefined;
              }
            } else {
              return undefined;
            }
          };

          for (const iterator of documentList) {
            if (iterator.name === `${detail.name}${index}`) {
              configOther.push({
                maxFile: 1,
                minFile: 1,
                allowedFile: iterator.extension,
                maxSize: parseInt(iterator.maxSize, 10) * 1048576,
                documentCode: iterator.code,
                documentType: iterator.type,
                name: iterator.name,
                sectionKey: formName,
                fileName: iterator.fileName,
                documentGroup: iterator.documentGroup,
                contentId: documents[`${detail.name}${index}`]
                  ? documents[`${detail.name}${index}`].id
                  : undefined,
                folderId: documents[`${detail.name}${index}`]
                  ? documents[`${detail.name}${index}`].folderId
                  : undefined,
                repositoryId: documents[`${detail.name}${index}`]
                  ? documents[`${detail.name}${index}`].repositoryId
                  : undefined,
              });
            }
          }
          docOtther.push(
            <Col span={12} key={`docComponent${detail.name}${index}`}>
              {documents[`${detail.name}${index}`] && valueDocOther() ? (
                <ViewImage
                  documents={documents}
                  name={`${detail.name}${index}`}
                  imgClassName="imgEdit"
                  label={<FormattedMessage id={`${detail.name}${index}`} />}
                  addtionalLabel={optionaLabel()}
                  fileName={
                    documents[`${detail.name}${index}`].originalFileName
                  }
                  size={documents[`${detail.name}${index}`].properties.size}
                  path={`${formName}.${detail.name}${index}`}
                  formName="editQa"
                  disabled={!isEdited || false}
                  extension={
                    documents[`${detail.name}${index}`].properties.extension
                  }
                  width={450}
                  height={300}
                  form={propsDynamic.form}
                />
              ) : (
                <Field
                  name={`${formName}.${detail.name}${index}`}
                  component={InputFileRegisForm}
                  idComponent={`input${detail.name}${formModulName}`}
                  label={`${detail.name}${index}`}
                  addtionalLabel={optionaLabel()}
                  id={`input${detail.name}${formModulName}`}
                  formName="editQa"
                  fieldName={`${formName}.${detail.name}${index}`}
                  uppyConfig={configOther[index]}
                  base64ToMeta={true}
                  formClassName="registrationForm"
                  disabled={!isEdited || false}
                  defaultLabel={true}
                  useFile={true}
                  isQA={true}
                  handleOnChange={handleUploadContent}
                />
              )}
            </Col>
          );
        }
        const detailButtonOttherDoc = [
          {
            type: 'primary',
            content: 'Tambah Lampiran',
            id: 'btnAddDocOtther',
            className: 'btnAddRegisForm',
            icon: 'PlusOutlined',
            onClick: () => onClickOtherDocument(),
            disabled: !isEdited
              ? !isEdited
              : totalOtherattachment >= 5
              ? true
              : false,
          },
        ];
        return (
          <React.Fragment>
            <Row gutter={32}>{docOtther}</Row>
            <CButton buttonData={detailButtonOttherDoc} />
          </React.Fragment>
        );
      } else {
        if (documents[detail.name] && valueForm()) {
          return (
            <ViewImage
              documents={documents}
              name={detail.name}
              imgClassName="imgEdit"
              label={detail.title}
              addtionalLabel={optionaLabel()}
              fileName={documents?.[detail.name].originalFileName}
              size={documents?.[detail.name].properties.size}
              path={nameField}
              formName="editQa"
              disabled={!isEdited ? !isEdited : false}
              extension={documents?.[detail.name].properties.extension}
              width={450}
              height={300}
              form={propsDynamic.form}
            />
          );
        }
        return (
          <Field
            name={nameField}
            component={InputFileRegisForm}
            idComponent={`input${detail.name}${formModulName}`}
            label={detail.name}
            addtionalLabel={optionaLabel()}
            id={`input${detail.name}${formModulName}`}
            formName="editQa"
            fieldName={nameField}
            uppyConfig={uppyConfig}
            base64ToMeta={true}
            formClassName="registrationForm"
            disabled={!isEdited ? !isEdited : false}
            validate={detail.isRequired ? required : undefined}
            formValue={valueForm()}
            documentValue={documents?.[detail.name]}
            defaultLabel={true}
            useFile={true}
            isQA={true}
            handleOnChange={handleUploadContent}
          />
        );
      }
    }
    case 'ListData': {
      return (
        <Table
          columns={generateColumnData(propsDynamic['column'][formName])}
          data={propsDynamic[`list${formName}`] || []}
        />
      );
    }
  }
  const labelTitle = detail.title ? (
    <span>
      <div className={`capitalTitle`}>{detail.title}</div>
    </span>
  ) : null;
  const radioValue =
    propsDynamic.values &&
    propsDynamic.values[formName] &&
    propsDynamic.values[formName][sectionKey] &&
    propsDynamic.values[formName][sectionKey][detail.name]
      ? propsDynamic.values[formName][sectionKey][detail.name][0]
      : undefined;
  if (detail.customTemplate === 'QuizInput') {
    return (
      <Form.Item label={labelTitle}>
        {detail.properties.map((data, index) => {
          if (data.template === 'TextInput') {
            return (
              <div key={`component${detail.name}_${index}`}>
                <Field
                  name={`${formName}.${sectionKey}.${detail.name}.${index}`}
                  component={InputText}
                  idComponent={`input${detail.name}_${index}`}
                  label={data.title}
                  placeholder={data.config.placeholder}
                  formClassName="registrationForm"
                  disabled={!isEdited ? !isEdited : !radioValue}
                  validate={radioValue === true ? required : undefined}
                  additionalValue={!radioValue ? '' : undefined}
                />
              </div>
            );
          } else {
            const listData = data.enumValue
              ? createListSelectBox(data.enum, data.enumValue)
              : createListSelectBox(data.enum);
            return (
              <div key={`component${detail.name}_${index}`}>
                <Field
                  name={`${formName}.${sectionKey}.${detail.name}.${index}`}
                  idComponent={`input${detail.name}_${index}`}
                  component={RadioButton}
                  dataOption={listData}
                  validate={detail.isRequired ? required : undefined}
                  layout="vertical"
                  formClassName="registrationForm"
                  disabled={!isEdited ? !isEdited : false}
                />
              </div>
            );
          }
        })}
      </Form.Item>
    );
  }
  return null;
};

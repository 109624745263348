import React, { useState } from 'react';
import TemplateComponent from '../Component/TemplateComponent';

export default function TemplateContainer(props) {
  const [siderIsCollapse, setSiderIsCollapse] = useState(false);

  return (
    <TemplateComponent
      siderIsCollapse={siderIsCollapse}
      setSiderIsCollapse={setSiderIsCollapse}
      isGlobalLoading={false}
      {...props}
    />
  );
}

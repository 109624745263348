import { Button, Col, Row, Skeleton } from 'antd';

import CIcon from '../../../Assets/Components/CIcon';
import { CInputSwitch } from '../../../Assets/Components/CInputSwitch';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import validate from '../Validation/CategoryBankValidation';

interface IProps {
  categoryBankModalAction: string;
  editMode: boolean;
  handleCancel: () => void;
  initialValues: any;
  isLoading: any;
  isShow: boolean;
  onSubmit: (vals: any) => void;
}

export default function CategoryBankModalComponent(props: IProps) {
  const {
    categoryBankModalAction,
    editMode,
    handleCancel,
    initialValues,
    isLoading,
    isShow,
    onSubmit,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {categoryBankModalAction === 'update' ? (
          <FormattedMessage id="editCategoryBank" />
        ) : (
          <FormattedMessage id="addCategoryBank" />
        )}
      </h3>
    );
  };

  const contentComponent = (propsContent: any) => {
    const formValues = propsContent.values;
    const spanStatus = () => {
      if (formValues.isActive === undefined || formValues.isActive === true) {
        return 'Active';
      } else {
        return 'Not Active';
      }
    };
    if (formValues.isActive === undefined) {
      propsContent.form.change('isActive', true);
    }
    const disabled =
      editMode === false && categoryBankModalAction === 'update' ? true : false;
    return (
      <Skeleton active loading={isLoading}>
        <Field
          name="name"
          component={InputText}
          placeholder="Type Here"
          label="Category Name"
          disabled={disabled}
          maxlength={40}
        />
        <Row>
          <Col span={2}>
            <b>Status</b>
          </Col>
          <Col span={2}>
            <Field
              name="isActive"
              idComponent="inputStatusCategoryBank"
              component={CInputSwitch}
              disabled={disabled}
              checked
              className="statusCategoryBank"
            />
          </Col>
          <Col span={4}>
            <span className="labelInfoStatus">{spanStatus()}</span>
          </Col>
        </Row>
      </Skeleton>
    );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (propsFooter: any) => {
    const { invalid } = propsFooter;
    return (
      <Button
        loading={isLoading}
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        icon={<CIcon type="CheckOutlined" />}
        disabled={
          categoryBankModalAction === 'update' && !editMode ? false : invalid
        }
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(categoryBankModalAction, editMode)}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

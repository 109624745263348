import React from 'react';
import ModalContestUploadComponent from '../Component/ModalContestUploadComponent';

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<any>;
  selectedData?: any;
}

export default function ModalContestUploadContainer(props: IProps) {
  const { showModal, setShowModal, selectedData, setSelectedData } = props;

  const handleCancel = () => {
    setShowModal(false);
    setSelectedData(undefined);
  };

  return (
    <ModalContestUploadComponent
      handleCancel={handleCancel}
      showModal={showModal}
      initialValues={selectedData}
    />
  );
}

import React from 'react';
import HasPermission from '../../../App/HasPermission';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { injectIntl } from 'react-intl';
import { IResultContestTargetDAO } from '../DAO/contestTarget.dao';

interface IProps {
  handleOnClick: (e: any) => void;
  handleRefresh: () => void;
  isLoading: boolean;
  contestTargetList: IResultContestTargetDAO[];
  columnData: any;
  intl: any;
}

function ContestTargetComponent(props: IProps) {
  const {
    intl,
    handleOnClick,
    isLoading,
    contestTargetList,
    handleRefresh,
    columnData,
  } = props;

  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'PlusOutlined',
          onClick: handleOnClick,
          content: 'Add Contest',
          id: 'btnAddContestTarget',
        }
      : null,
  ];

  const cardContestTargetContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={contestTargetList}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Target" rightText="Configuration" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'contestTargetCardTitle',
            })}
          </span>
        }
        cardContent={cardContestTargetContent()}
      />
    </>
  );
}
export default injectIntl(ContestTargetComponent);

import React from 'react';
import { Col, Row, Tabs } from 'antd';
import { ViewImage } from '../../../../../Assets/Components/CViewImageFormRegis';
import { Field, Form } from 'react-final-form';
import { InputChecbox } from '../../../../../Assets/Components/CInputCheckbox';
import Select from '../../../../../Assets/Components/Select';
import { worldCheck } from '../../../../../App/Enums';
import { InputText } from '../../../../../Assets/Components/CInput';
import CButton from '../../../../../Assets/Components/CButton';
import listDocument from '../../../../../Assets/JSON/documentRegistration.json';
import { IntlShape } from 'react-intl';
import { InputFileRegisForm } from '../../../../../Assets/Components/InputFileRegisForm';

interface IProps {
  intl: IntlShape;
  documentMeta: any;
  aajiSubmission: any;
  documentsContent:
    | {
        data: any;
        documents: any;
      }
    | undefined;
  handleSubmit: (vals: any) => void;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  setValuesAAJI: React.Dispatch<React.SetStateAction<undefined>>;
  isLoadingAAJI: boolean;
}

export default function ModalContentAAJIComponent(props: IProps) {
  const {
    intl,
    documentMeta,
    aajiSubmission,
    documentsContent,
    handleSubmit,
    handleUploadContent,
    setValuesAAJI,
    isLoadingAAJI,
  } = props;

  const { TabPane } = Tabs;
  const listAaji = ['screenshotAaji1', 'screenshotAaji2', 'screenshotAaji3'];

  return (
    <>
      <Form onSubmit={handleSubmit}>
        {formProps => {
          const { values, form } = formProps;
          setValuesAAJI(values);
          if (documentsContent?.data) {
            form.change(documentMeta.field, documentsContent.data);
          }
          if (typeof aajiSubmission === 'object') {
            form.change('statusWorld', aajiSubmission.result);
            form.change('finalAssessment', aajiSubmission.finalAssessment);
          }
          const dataButtonUpload = [
            {
              type: 'primary',
              id: 'btnUploadDocument',
              className: 'btnUploadQa',
              content: 'Unggah & Lanjutkan ke Proses QA',
              disabled:
                (!values?.screenshotAaji1 &&
                  !values?.screenshotAaji2 &&
                  !values?.screenshotAaji3) ||
                isLoadingAAJI,
              onClick: () => handleSubmit(values),
              loading: isLoadingAAJI,
            },
          ];

          const renderUpload = () => {
            return listAaji.map((data, index) => {
              const uppyAajiConfig: any = {};
              for (const iterator of listDocument.data) {
                if (iterator.name === data) {
                  uppyAajiConfig.maxFile = 1;
                  uppyAajiConfig.minFile = 1;
                  uppyAajiConfig.allowedFile = iterator.extension;
                  uppyAajiConfig.maxSize = iterator.maxSize * 1048576;
                  uppyAajiConfig.documentCode = iterator.code;
                  uppyAajiConfig.documentType = iterator.type;
                  uppyAajiConfig.name = iterator.name;
                  uppyAajiConfig.sectionKey = 'qaAajiScreening';
                  uppyAajiConfig.fileName = iterator.fileName;
                  uppyAajiConfig.documentGroup = iterator.documentGroup;
                }
              }
              const formValueName = () => {
                if (values) {
                  if (values && values[data]) {
                    return values[data];
                  } else {
                    return undefined;
                  }
                } else {
                  return undefined;
                }
              };

              return (
                <Col span={8} key={`screnshootAaji${index}`}>
                  {formValueName() &&
                  documentsContent &&
                  documentsContent[data] ? (
                    <ViewImage
                      documents={documentsContent}
                      name={data}
                      imgClassName="imgEdit"
                      label={intl.formatMessage({
                        id: `screenshotAaji${index + 1}`,
                      })}
                      fileName={documentsContent[data].originalFileName}
                      size={documentsContent[data].properties.size}
                      path={data}
                      formName="qaAajiScreening"
                      extension={documentsContent[data].properties.extension}
                      formClassName="uploadAajiQa"
                    />
                  ) : (
                    <Field
                      name={data}
                      component={InputFileRegisForm}
                      label={`screenshotAaji${index + 1}`}
                      idComponent="inputScreenshotAaji1"
                      id="inputScreenshotAaji1"
                      formName="qaAajiScreening"
                      fieldName={data}
                      uppyConfig={uppyAajiConfig}
                      base64ToMeta={true}
                      formClassName="uploadAajiQa"
                      handleOnChange={handleUploadContent}
                      useFile={true}
                    />
                  )}
                </Col>
              );
            });
          };

          return (
            <div>
              <Tabs className="dynamicTabs" type="card">
                <TabPane
                  tab="Unggah Screenshot AAJI"
                  key="documentAAJIF"
                  className="tabsPaneDynamic"
                >
                  <Row gutter={20}>{renderUpload()}</Row>
                  <hr className="lineDetailDocumentQa" />
                  <Field
                    name="agentTengarai"
                    component={InputChecbox}
                    idComponent="inputAgentIsActive"
                    content="Agen masih aktif di perusahaan lain(tengarai)"
                    className="checkAgentActive"
                  />
                  <br />
                  <Field
                    name="agentBlackList"
                    component={InputChecbox}
                    idComponent="inputBlackList"
                    content="Agen masuk daftar hitam"
                    className="checkAgentActive"
                  />
                  <br />
                  <Field
                    name="agentRef"
                    component={InputChecbox}
                    idComponent="inputBlackList"
                    content="Agen Referral - Konsultan"
                    className="checkAgentActive"
                  />
                  <br />
                  <Field
                    name="statusWorld"
                    component={Select}
                    idComponent="inputStatusWorld"
                    label="Status World Check"
                    defaultItemName="Pilih"
                    dataOption={worldCheck}
                    className="selectStatusWorld"
                  />
                  <Field
                    name="finalAssessment"
                    component={InputText}
                    idComponent="inputFinalAssessment"
                    label="Final Assessment"
                    className="inputCustomWorldCheck"
                    maxlength={100}
                    disabled={true}
                  />
                  <CButton
                    buttonData={dataButtonUpload}
                    isLoading={isLoadingAAJI}
                  />
                </TabPane>
              </Tabs>
            </div>
          );
        }}
      </Form>
    </>
  );
}

import { Col, Row } from 'antd';
import React from 'react';
import { filterStatus } from '../../../App/Enums';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import Select from '../../../Assets/Components/Select';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import CategoryBankModalContainer from '../Container/CategoryBankModalContainer';
import { IResultCategoryBankDAO } from '../DAO/categoryBank.dao';

interface IProps {
  columnData: any;
  selectedStatus?: any;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  listCategoryBank: IResultCategoryBankDAO[];
  handleRefresh: () => void;
  handleApplyfilter: () => void;
  isLoading: boolean;
  modalIsShow: boolean;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
}

export default function CategoryBankComponent(props: IProps) {
  const {
    columnData,
    setSelectedStatus,
    selectedStatus,
    listCategoryBank,
    handleRefresh,
    isLoading,
    handleApplyfilter,
    setModalIsShow,
    modalIsShow,
    selectedData,
    setSelectedData,
  } = props;

  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchCategoryBank',
      onClick: handleApplyfilter,
    },
  ];

  const buttonData = [
    {
      type: 'primary',
      onClick: () => setModalIsShow(true),
      content: 'Tambah Baru',
      id: 'btnAddCategoryBank',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshCategoryBank',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];

  const tabContent = () => {
    return (
      <div className="containerWhiteZurich">
        <Row gutter={5}>
          <Col span={4}>
            <label className="labelBtnFilter">Status</label>
            <Select
              dataOption={filterStatus || []}
              defaultItemName="Semua"
              idComponent="filterCategoryBank"
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setSelectedStatus,
                value: selectedStatus ? selectedStatus : 'Semua',
              }}
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={4} className={'btnFilterAplicationList'}>
            <CButton
              isLoading={isLoading}
              buttonData={buttonDataSearch}
              buttonFloat={'left'}
            />
          </Col>
          <Col span={16} className={'btnFilterAplicationList'}>
            <CButton buttonData={buttonData} buttonFloat={'right'} />
          </Col>
        </Row>
        <Table
          columns={generateColumnData(columnData)}
          pagination={true}
          data={listCategoryBank}
          useFilterGlobal
          isLoading={isLoading}
        />
        <CategoryBankModalContainer
          showModal={modalIsShow}
          setShowModal={setModalIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          handleRefresh={handleRefresh}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Category Bank" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Category List</span>}
        cardContent={tabContent()}
      />
    </React.Fragment>
  );
}

import React from 'react';
import ModalDetailHistoryComponent from '../Component/ModalDetailHistoryComponent';

interface IProps {
  modalDetailIsShow: boolean;
  selectedData: any;
  setModalDetailIsShow: any;
}
export default function ModalDetailHistoryContainer(props: IProps) {
  const { modalDetailIsShow, selectedData, setModalDetailIsShow } = props;

  const handleCancel = () => {
    setModalDetailIsShow(false);
  };

  return (
    <ModalDetailHistoryComponent
      modalDetailIsShow={modalDetailIsShow}
      selectedData={selectedData}
      handleCancel={handleCancel}
    />
  );
}

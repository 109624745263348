import React from 'react';
import { Badge, Button, Col, Row, Tabs, Tag } from 'antd';
import { Field } from 'react-final-form';
import Select from '../../../../../Assets/Components/Select';
import {
  pendingRemarks,
  qaAgentClassification,
  qaAgentType,
  templatePendingRemarks,
} from '../../../../../App/Enums';
import { InputText } from '../../../../../Assets/Components/CInput';
import CButton from '../../../../../Assets/Components/CButton';
import { DeleteOutlined } from '@ant-design/icons';
import { CInputSwitch } from '../../../../../Assets/Components/CInputSwitch';
import { ViewImage } from '../../../../../Assets/Components/CViewImageFormRegis';
import { IntlShape } from 'react-intl';

interface IProps {
  intl: IntlShape;
  documentsAaji: boolean;
  statusQa: string;
  pendingRemarksList: any;
  formValue: any;
  listPendingItem: any;
  listItemPending: any;
  handleSelectNotes: () => void;
  handleAddItemPending: () => void;
  handleDelete: (id: any) => void;
  handleNotClean: () => void;
  handleClean: () => void;
  handleChangePendingStatus: (e: any, path: any) => void;
  handleSubmitPendingQA: (x: any, vals: any) => void;
  isLoading: boolean;
  modalContentCleanIsShow: any;
}
export default function ModalContentCleanComponent(props: IProps) {
  const {
    documentsAaji,
    intl,
    statusQa,
    pendingRemarksList,
    formValue,
    listPendingItem,
    listItemPending,
    handleSelectNotes,
    handleAddItemPending,
    handleDelete,
    handleNotClean,
    handleClean,
    handleChangePendingStatus,
    handleSubmitPendingQA,
    isLoading,
  } = props;

  const listValue = Object.values(documentsAaji);

  const itemPendingIsActive = listItemPending?.filter((obj: any) => {
    return obj.config.pendingStatus === 'active';
  });

  const dataButton = [
    {
      type: 'primary',
      id: 'btnNotClean',
      className: 'btnNotClean',
      content: 'Not Clean',
      onClick: handleNotClean,
      disabled: statusQa === 'Not Clean',
    },
    {
      type: 'primary',
      id: 'btnClean',
      className: 'btnClean',
      content: 'Clean',
      onClick: handleClean,
      disabled: statusQa === 'Clean',
    },
  ];
  const dataButtonPendingRemarks = [
    {
      type: 'primary',
      id: 'btnAddPendingRemarks',
      className: 'btnAddPendingRemarks',
      content: 'Tambah Catatan',
      icon: 'PlusOutlined',
      disabled:
        formValue.pendingRemarks === 'template'
          ? !formValue?.pendingTemplate
          : !formValue.pendingCustom,
      onClick: handleAddItemPending,
    },
  ];

  const dataButtonSubmitTabQA = [
    {
      type: 'primary',
      id: 'btnSubmitQa',
      className: 'btnSubmitQa',
      content: 'Ajukan',
      onClick: () => handleSubmitPendingQA('qaProcess', formValue),
      disabled:
        (statusQa === 'Clean' &&
          formValue &&
          (!formValue.agentType || !formValue.agentClassification)) ||
        isLoading
          ? true
          : false,
    },
  ];
  const dataButtonSubmitPendingItem = [
    {
      type: 'primary',
      id: 'btnSubmitQa',
      className: 'btnSubmitQa',
      content: 'Ajukan',
      onClick: () => handleSubmitPendingQA('pendingItem', formValue),
    },
  ];
  const { TabPane } = Tabs;
  const tagStat = (tagStatus: any, color: any) => {
    return (
      <Tag color={color}>
        <div
          style={{
            color: '#FFFFFF',
            width: 120,
            textAlign: 'center',
          }}
        >
          {tagStatus}
        </div>
      </Tag>
    );
  };

  const renderStatus = (status: any) => {
    switch (status) {
      case 'active': {
        return tagStat('Pending Aktif', '#D82D2D');
      }
      case 'finish': {
        return tagStat('Pending Selesai', '#1DB227');
      }
      case 'update': {
        return tagStat('Pending Diperbarui', '#F8B720');
      }
    }
    return null;
  };
  const renderContentQa = (
    status: string,
    formValue: any,
    handleSelectNotes: () => void,
    pendingRemarksList: any,
    dataButtonPendingRemarks: {
      type: string;
      id: string;
      className: string;
      content: string;
      icon: string;
      disabled: boolean;
      onClick: () => void;
    }[]
  ) => {
    if (status === 'Not Clean') {
      return (
        <React.Fragment>
          <Field
            name="pendingRemarks"
            component={Select}
            label="Tipe Catatan"
            idComponent="inputPendingRemarks"
            defaultItemName="Pilih"
            dataOption={pendingRemarks}
            additionalHandleChangeFunc={handleSelectNotes}
            defaultValue="template"
          />
          <div
            hidden={
              (formValue && formValue.pendingRemarks === 'template') ||
              !formValue.pendingRemarks
                ? false
                : true
            }
          >
            <Field
              name="pendingTemplate"
              component={Select}
              defaultItemName="Pilih"
              label="Template Catatan"
              idComponent="inputPendingTemplate"
              dataOption={templatePendingRemarks(pendingRemarksList)}
            />
          </div>
          <div
            hidden={
              formValue && formValue.pendingRemarks === 'custom' ? false : true
            }
          >
            <Field
              name="pendingCustom"
              component={InputText}
              placeholder="Ketik di sini"
              label="Kustomisasi Catatan"
              idComponent="inputPendingCustom"
              maxlength={300}
            />
          </div>
          <CButton buttonData={dataButtonPendingRemarks} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Field
          name="agentType"
          component={Select}
          defaultItemName="Pilih"
          label="Tipe Agen"
          idComponent="inputAgentType"
          dataOption={qaAgentType}
        />
        <Field
          name="agentClassification"
          component={Select}
          defaultItemName="Pilih"
          label="Klasifikasi Agen"
          idComponent="inputAgentClassification"
          dataOption={qaAgentClassification}
        />
      </React.Fragment>
    );
  };
  return (
    <>
      <Tabs className="dynamicTabs" type="card">
        <TabPane
          tab="QA Process"
          key="qaProcess"
          className="modalDetailQAProcess"
        >
          <div className="txtTitleStatusClean">Status saat ini:</div>
          <div
            className={
              statusQa === 'Not Clean' ? 'txtStatusNotClean' : 'txtStatusClean'
            }
          >
            {statusQa}
          </div>
          <CButton buttonData={dataButton} />
          <hr className="lineDetailQaStatus" />
          <div className="modalDetailQAProcess">
            {renderContentQa(
              statusQa,
              formValue,
              handleSelectNotes,
              pendingRemarksList,
              dataButtonPendingRemarks
            )}
          </div>
          <div>
            {listPendingItem?.map((data: any, index: number) => {
              return (
                <div
                  key={`itemPending_${index}`}
                  className="containerItemPending"
                >
                  <Button
                    id={`btnDeleteItemPending_${index}`}
                    className="btnDeleteItemPending"
                    icon={<DeleteOutlined style={{ fontSize: 20 }} />}
                    onClick={() => handleDelete(data.id)}
                  />
                  <div className="txtTitleItemPending">
                    {data.config && data.config.note
                      ? data.config.note
                      : data.title}
                  </div>
                </div>
              );
            })}
          </div>
          <hr className="lineDetailQaStatus" />
          <CButton buttonData={dataButtonSubmitTabQA} isLoading={isLoading} />
        </TabPane>
        <TabPane
          tab={
            <Badge count={itemPendingIsActive.length} offset={[12, 5]}>
              Item Pending{`  `}
            </Badge>
          }
          key="itemPending"
          className="tabsPaneDynamic"
        >
          {listItemPending?.map((data, index) => {
            return (
              <div
                key={`itemPending_${index}`}
                className="containerItemPending"
              >
                <Row>
                  <Col span={1}>
                    <div style={{ marginTop: 25 }}>{`${index + 1}.`}</div>
                  </Col>
                  <Col span={15}>
                    <div>{renderStatus(data.config.pendingStatus)}</div>
                    {data.config.note ? (
                      <div className="containerTxtItemPending">
                        <div className="titleItemPending">Label</div>
                        <div className="txtItemPendingRightContent">
                          {data.title}
                        </div>
                        <div className="titleItemPending">Catatan</div>
                        <div className="txtItemPendingRightContent">
                          {data.config.note}
                        </div>
                      </div>
                    ) : (
                      <div className="txtItemPending">{data.title}</div>
                    )}
                  </Col>
                  <Col span={2} offset={6}>
                    <Field
                      name={`pendingItem.${index}`}
                      component={CInputSwitch}
                      idComponent={`inputSwitch_${index}`}
                      labelTitle={
                        data.config.pendingStatus === 'finish'
                          ? 'Tidak Aktif'
                          : 'Aktif'
                      }
                      defaultChecked={
                        data.config.pendingStatus === 'finish' ? false : true
                      }
                      path={
                        data.config.path ? data.config.path : data.config.name
                      }
                      addtionalFunction={handleChangePendingStatus}
                    />
                  </Col>
                </Row>
              </div>
            );
          })}
          <hr className="lineDetailQaStatus" />
          <CButton
            buttonData={dataButtonSubmitPendingItem}
            isLoading={isLoading}
          />
        </TabPane>
        <TabPane
          tab="Hasil Pemeriksaan AAJI"
          key="resultAaji"
          className="tabsPaneDynamic"
        >
          <Row gutter={12}>
            {listValue.map((data, index) => {
              const newData: any = data;
              return (
                <Col span={8} key={`documentsAaji_${index}`}>
                  <ViewImage
                    label={intl.formatMessage({
                      id: newData.name,
                    })}
                    fileName={newData.originalFileName}
                    size={newData.properties.size}
                    path={newData.name}
                    formName="qaClean"
                    formClassName="uploadAajiQa"
                    base64={newData.content}
                    visibleButton={true}
                  />
                </Col>
              );
            })}
          </Row>
        </TabPane>
      </Tabs>
    </>
  );
}

import { Alert, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';

interface IProps {
  selectedData: any;
  modalDetailIsShow: boolean;
  handleCancel: () => void;
}
export default function ModalDetailHistoryComponent(props: IProps) {
  const { selectedData, modalDetailIsShow, handleCancel } = props;
  const uploadStatus = selectedData?.status === 'Failed' ? 'error' : 'success';
  const notifyUploadStatus = () => {
    const arrayError: any = [];
    const errorMsg = selectedData.errorMessage.split('|');
    for (const iterator of errorMsg) {
      arrayError.push(iterator);
    }
    const totalError = errorMsg[0] !== '' ? errorMsg.length - 1 : null;
    if (selectedData?.status === 'Success') {
      return <span>Berkas berhasil diunggah</span>;
    } else {
      return <span>{`${totalError} row data failed to upload`}</span>;
    }
  };

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Detail Unggahan</span>
      </h3>
    );
  };
  const renderErrorMsg = () => {
    const arrayError: any = [];
    const errorMsg = selectedData.errorMessage.split('|');
    for (const iterator of errorMsg) {
      arrayError.push(iterator);
    }
    const dataComponent: any = [];
    for (const key in errorMsg) {
      if (Object.prototype.hasOwnProperty.call(errorMsg, key)) {
        const element = errorMsg[key];
        dataComponent.push(
          element === '' ? null : (
            <ul>
              <li>{element}</li>
            </ul>
          )
        );
      }
    }
    return dataComponent;
  };
  const renderDetailUpload = (label: string, value: any) => {
    return (
      <Row style={{ marginBottom: 5 }}>
        <Col span={8}>{label}</Col>
        <Col span={1}>:</Col>
        <Col span={10}>{value}</Col>
      </Row>
    );
  };
  const contentComponent = (formProps: any) => {
    return (
      <React.Fragment>
        {renderDetailUpload('Nama Berkas', selectedData.originalFilename)}
        {renderDetailUpload('Tipe Statement', selectedData.agreementType)}
        {renderDetailUpload('Status Unggah', selectedData.status)}
        {renderDetailUpload(
          'Tanggal Unggah',
          moment(selectedData.uploadDate).format('DD-MMM-YYYY')
        )}
        <hr className="lineUploadLicense" />
        <Alert
          className="alertErrorModuleUploadHistory"
          message={notifyUploadStatus()}
          type={uploadStatus}
          showIcon
        />
        {renderErrorMsg()}
      </React.Fragment>
    );
  };

  return (
    <CModalRFF
      modalIsShow={modalDetailIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={() => {}}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={{}}
    />
  );
}

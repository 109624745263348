import React, { useState } from 'react';
import CButton from '../../../Assets/Components/CButton';
import CategoryBankComponent from '../Component/CategoryBankComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  deleteCategoryBankAPI,
  getCategoryBankAPI,
  mutateCategoryBankAPI,
} from '../../../Services/categorybank.api';
import { Switch } from 'antd';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import ErrorHandler from '../../../App/ErrorHandler';

export default function CategoryBankContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState();
  const [filter, setFilter] = useState();
  const [selectedData, setSelectedData] = useState();

  const {
    data: listCategoryBank,
    isLoading: isLoadingCategoryBank,
    isRefetching: isRefetchCategoryBank,
    refetch,
  } = useQuery(['getCategoryBank', filter], getCategoryBankAPI, {
    refetchOnWindowFocus: false,
  });

  const mutate = useMutation(mutateCategoryBankAPI, {
    onSuccess: () => {
      setModalIsShow(false);
      refetch();
      toast.success(
        <span className="capitalFirst">Category Berhasil Diupdate!</span>
      );
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const deleteCategoryBank = useMutation(
    (vals: any) => {
      return deleteCategoryBankAPI(vals);
    },
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Category Berhasil Dihapus</span>
        );
        refetch();
      },
    }
  );

  const handleApplyfilter = () => {
    setFilter(selectedStatus);
  };

  const processStatusCategory = (id: number, status: any, body: any) => {
    mutate.mutate({ ...body, isActive: status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const body = {
      name: oriObj.name,
      isActive: status,
    };
    const action = status === true ? 'mengaktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusCategory(id, status, body),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Category "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = (row: any) => {
    const rowData = row.row.original;
    const processEnabled = (isChecked: any) =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteCategoryBank.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Category "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteCategoryBank`,
        icon: 'DeleteOutlined',
        className: 'btnDelete',
        onClick: handleDelete,
      },
      {
        id: `btnEditCategoryBank`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Category Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  return (
    <CategoryBankComponent
      columnData={columnData}
      setSelectedStatus={setSelectedStatus}
      selectedStatus={selectedStatus}
      listCategoryBank={listCategoryBank || []}
      handleRefresh={() => {
        refetch();
        setSelectedStatus(undefined);
        setFilter(undefined);
      }}
      isLoading={isLoadingCategoryBank || isRefetchCategoryBank}
      handleApplyfilter={handleApplyfilter}
      modalIsShow={modalIsShow}
      setModalIsShow={setModalIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
    />
  );
}

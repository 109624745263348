import React from 'react';
import HasPermission from '../../../App/HasPermission';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import CityHistoryDetailModalContainer from '../Container/CityHistoryDetailModalContainer';
import CityUploadModalContainer from '../Container/CityUploadModalContainer';
import { IResultCityHistoryListDAO } from '../DAO/city.dao';

interface IProps {
  columnData: any;
  isLoadingList: boolean;
  historyList: IResultCityHistoryListDAO[];
  handleRefresh: () => void;
  selectedData: any;
  modalInfoIsShow: boolean;
  setModalInfoIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalUploadIsShow: boolean;
  setModalUploadIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function CityHistoryTabComponent(props: IProps) {
  const {
    isLoadingList,
    historyList,
    handleRefresh,
    columnData,
    selectedData,
    modalInfoIsShow,
    setModalInfoIsShow,
    modalUploadIsShow,
    setModalUploadIsShow,
  } = props;

  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'UploadOutlined',
          onClick: () => setModalUploadIsShow(true),
          content: 'Upload',
          id: `btnUpload`,
        }
      : null,
  ];
  return (
    <div className="containerTabWhiteZurich">
      <CButton
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={isLoadingList}
        handleRefresh={handleRefresh}
      />
      <Table
        isLoading={isLoadingList}
        columns={generateColumnData(columnData)}
        data={historyList}
        pagination={true}
      />
      <CityHistoryDetailModalContainer
        selectedData={selectedData}
        modalInfoIsShow={modalInfoIsShow}
        setModalInfoIsShow={setModalInfoIsShow}
      />
      <CityUploadModalContainer
        modalUploadIsShow={modalUploadIsShow}
        setModalUploadIsShow={setModalUploadIsShow}
      />
    </div>
  );
}

import React, { useState } from 'react';

import CButton from '../../../Assets/Components/CButton';
import WorldCheckComponent from '../Component/WorldCheckComponent';

import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getWorldCheckListAPI } from '../../../Services/worldCheck.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function WorldCheckContainer() {
  const [selectedData, setSelectedData] = useState(false);
  const [modalDetailWorldIsShow, setModalDetailWorldIsShow] = useState(false);
  const [modalUploadWorldIsShow, setModalUploadWorldIsShow] = useState(false);

  const {
    data: worldCheckList,
    isLoading,
    isRefetching,
    fetchStatus,
    refetch,
  } = useQuery(['getWorldCheckList'], getWorldCheckListAPI, {
    enabled: !modalUploadWorldIsShow,
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const renderFormat = (row: any) => {
    const name = row.row.original.originalFilename.split('.')[1];
    return <span>{`.${name}`}</span>;
  };

  const renderStatus = (row: any) => {
    return (
      <div className="spanContestStatus">
        <span
          className={`worldCheckStatusColor worldCheckStatusColor${row.row.original.status}`}
        />
        <span className={`worldCheckStatusName`}>
          {row.row.original.status}
        </span>
      </div>
    );
  };

  const renderAction = (row: any) => {
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailWorldCheck',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: () => {
          setSelectedData(row.row.original);
          setModalDetailWorldIsShow(true);
        },
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Nama Berkas',
      accessor: 'originalFilename',
    },
    {
      Header: 'Format',
      Cell: (row: any) => renderFormat(row),
    },
    {
      Header: 'Status Unggah',
      Cell: (row: any) => renderStatus(row),
      disableSort: true,
    },
    {
      Header: 'Tanggal Unggah',
      accessor: (row: any) => moment(row.uploadDate).format('DD-MMM-YYYY'),
      disableSort: true,
    },
    {
      Header: ' ',
      Cell: (row: any) => renderAction(row),
    },
  ];

  return (
    <WorldCheckComponent
      worldCheckList={worldCheckList || []}
      columnData={columnData}
      modalDetailWorldIsShow={modalDetailWorldIsShow}
      setModalDetailWorldIsShow={setModalDetailWorldIsShow}
      handleRefresh={() => refetch()}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      selectedData={selectedData}
      modalUploadWorldIsShow={modalUploadWorldIsShow}
      setModalUploadWorldIsShow={setModalUploadWorldIsShow}
    />
  );
}

import DashboardDosdComponent from '../Component/DashboardDosdComponent';
import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { getDashboardDosdDataAPI } from '../../../Services/Dashboard/dashboard.api';

export default function DashboardDosdContainer() {
  const { data } = useQuery(
    ['getLoginDosdDashboardAPI'],
    getDashboardDosdDataAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  return <DashboardDosdComponent data={data || []} />;
}

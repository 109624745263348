import React, { useState } from 'react';

import CButton from '../../../../../Assets/Components/CButton';
import ScheduleComponent from '../../Component/MainModal/ScheduleComponent';
import { deleteNonSelfScheduleAPI } from '../../../../../Services/scheduleNonSelf.api';
import moment from 'moment';
import { toast } from 'react-toastify';
import { toastr } from 'react-redux-toastr';
import { useMutation } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import ErrorHandler from '../../../../../App/ErrorHandler';
interface IProps {
  dataClassNonSelf?: any;
  disabled: boolean;
  isLoading: boolean;
  listSchedule: any[];
  modalAction: 'update' | 'register';
  refetchSchedule: () => void;
  setListSchedule: React.Dispatch<React.SetStateAction<any[]>>;
  form?: FormApi<any, Partial<any>>;
}

export default function ScheduleContainer(props: IProps) {
  const {
    dataClassNonSelf,
    disabled,
    isLoading,
    listSchedule,
    modalAction,
    refetchSchedule,
    setListSchedule,
  } = props;
  const [showModal, setShowModal] = useState(false); // Untuk modal session
  const [selectedSchedule, setSelectedSchedule] = useState();

  const scheduleFiltered = listSchedule.filter(
    item => item.status !== 'canceled'
  );

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const rowIndex = row.row.index;
    const now = moment().toISOString();
    const startDate = moment(rows.start_date).toISOString();

    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => {
          if (modalAction === 'register') {
            const tempData = [...scheduleFiltered];
            tempData.splice(rowIndex, 1);
            setListSchedule(tempData);
          } else {
            deleteScheduleMutation.mutate(rows.id);
          }
        },
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Are you sure to delete this schedule?`,
        toastrConfirmOptions
      );
    };
    const dataButton = [
      {
        id: `btnEditClass`,
        icon: 'EditOutlined',
        onClick: () => {
          setShowModal(true);
          setSelectedSchedule({
            ...rows,
            index: rowIndex,
            scheduleType: rows.type,
            trainerName: dataClassNonSelf?.trainerName || [],
          });
        },
        className: 'btnEdit',
        disabled,
      },
      {
        id: `btnDeleteSchedule`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
        disabled: moment(startDate).isBefore(now) || disabled,
      },
    ];

    return <CButton buttonData={dataButton} />;
  };
  const columnData = [
    {
      Header: 'Type',
      accessor: 'type',
      Cell: (row: any) => {
        const val = row.value;
        const dataRow = row.row.original;
        return (
          <span className="capital">
            {modalAction === 'register'
              ? val
              : dataRow.info === 'ONSITE'
              ? 'Onsite'
              : 'Online'}
          </span>
        );
      },
    },
    {
      Header: 'Date',
      accessor: 'date',
      Cell: (row: any) => {
        const dataRow = row.row.original;
        const { start_date, end_date, date } = dataRow;
        if (start_date) {
          return `${moment(start_date).format('DD-MMM-YYYY')} - ${moment(
            end_date
          ).format('DD-MMM-YYYY')}`;
        } else if (!start_date && !end_date && modalAction === 'update') {
          return '-';
        } else {
          return `${moment(date?.[0]).format('DD-MMM-YYYY')} - ${moment(
            date?.[1]
          ).format('DD-MMM-YYYY')}`;
        }
      },
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const deleteScheduleMutation = useMutation(deleteNonSelfScheduleAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      refetchSchedule();
      toast.success(
        <span className="capitalFirst">Schedule Berhasil Dihapus</span>
      );
    },
  });

  return (
    <ScheduleComponent
      columnData={columnData}
      dataClassNonSelf={dataClassNonSelf}
      disabled={disabled}
      isLoading={isLoading || deleteScheduleMutation.isLoading}
      listSchedule={scheduleFiltered}
      modalAction={modalAction}
      refetchSchedule={refetchSchedule}
      selectedSchedule={selectedSchedule}
      setListSchedule={setListSchedule}
      setSelectedSchedule={setSelectedSchedule}
      setShowModal={setShowModal}
      showModal={showModal}
    />
  );
}

import React, { Dispatch, SetStateAction } from 'react';
import { IntlShape } from 'react-intl';
import { injectIntl } from 'react-intl';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import Table from '../../../Assets/Components/CTable';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import moment from 'moment';
import { Col, Row } from 'antd';
import Select from '../../../Assets/Components/Select';
import { candidateLevel, listJourneyCandidate } from '../../../App/Enums';
import CButton from '../../../Assets/Components/CButton';
import listDocument from '../../../Assets/JSON/documentRegistration.json';
import ModalCandidateContainer from '../Container/Modal/ModalCandidateContainer';
import ModalDetailQAContainer from '../Container/Modal/ModalDetailQAContainer';

interface IProps {
  intl: IntlShape;
  columnData: any;
  dataFilter: any;
  isFetchingQA: boolean;
  handleRefresh: () => void;
  handleFilter: () => void;
  handleDownloadAAJI: () => void;
  statusJourney: any;
  setStatusJourney: Dispatch<SetStateAction<undefined>>;
  dateFilter: any;
  setDateFilter: Dispatch<SetStateAction<undefined>>;
  level: any;
  setLevel: Dispatch<SetStateAction<undefined>>;
  modalCandidateIsShow: boolean;
  setModalCandidateIsShow: Dispatch<SetStateAction<boolean>>;
  renderRowSubComponent: ({ row }: any) => JSX.Element;
  selectedData: any;
  setSelectedData: Dispatch<SetStateAction<undefined>>;
  schemaData: any;
  isEditedCandidate: boolean;
  setIsEditedCandidate: Dispatch<SetStateAction<boolean>>;
  totalOtherattachments: any;
  setTotalOtherattachments: Dispatch<SetStateAction<any>>;
  documentSubmission: any;
  modalDetailIsShow: boolean;
  setModalDetailIsShow: Dispatch<SetStateAction<boolean>>;
  detailCandidate: any;
  recruiter: any;
  directLeaderList: any;
  setAgentCode: any;
  setAgentLevel: any;
  setCandidateLevel: any;
  isFetchingSubmission: boolean;
  refreshList: any;
  qaStatus: string;
  setQaStatus: any;
  documentsContent: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  setContentDocument: any;
  contentDocument: any;
  documentMeta: any;
  newListBeneficiary: any;
  setNewListBeneficiary: Dispatch<SetStateAction<never[]>>;
  isUploading: any;
  newCityDomicile: any[];
  setNewCityDomicile: Dispatch<SetStateAction<never[]>>;
  newDistrictDomicile: any[];
  setNewDistrictDomicile: Dispatch<SetStateAction<never[]>>;
  newVillageDomicile: any[];
  setNewVillageDomicile: Dispatch<SetStateAction<never[]>>;
  newCity: any[];
  setNewCity: Dispatch<SetStateAction<never[]>>;
  newDistrict: any[];
  setNewDistrict: Dispatch<SetStateAction<never[]>>;
  newVillage: any[];
  setNewVillage: Dispatch<SetStateAction<never[]>>;
  candidateApprovers: any;
}

function QAProcessComponent(props: IProps) {
  const {
    intl,
    columnData,
    dataFilter,
    isFetchingQA,
    handleRefresh,
    handleFilter,
    handleDownloadAAJI,
    statusJourney,
    setStatusJourney,
    dateFilter,
    setDateFilter,
    level,
    setLevel,
    modalCandidateIsShow,
    setModalCandidateIsShow,
    renderRowSubComponent,
    selectedData,
    setSelectedData,
    schemaData,
    isEditedCandidate,
    setIsEditedCandidate,
    totalOtherattachments,
    setTotalOtherattachments,
    documentSubmission,
    modalDetailIsShow,
    setModalDetailIsShow,
    detailCandidate,
    recruiter,
    directLeaderList,
    setAgentCode,
    setAgentLevel,
    setCandidateLevel,
    isFetchingSubmission,
    refreshList,
    qaStatus,
    setQaStatus,
    documentsContent,
    handleUploadContent,
    setContentDocument,
    contentDocument,
    documentMeta,
    newListBeneficiary,
    setNewListBeneficiary,
    isUploading,
    newCityDomicile,
    setNewCityDomicile,
    newDistrictDomicile,
    setNewDistrictDomicile,
    newVillageDomicile,
    setNewVillageDomicile,
    newCity,
    setNewCity,
    newDistrict,
    setNewDistrict,
    newVillage,
    setNewVillage,
    candidateApprovers,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2',
      icon: 'SearchOutlined',
      onClick: handleFilter,
      content: 'Cari',
      id: 'btnSearchQAProcess',
      disabled: !dateFilter,
    },
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshQAProcess',
    },
  ];

  const document = listDocument.data;
  const buttonDataSecondRow = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2',
      icon: null,
      content: 'Tambah Baru',
      id: 'btnAddQAProcess',
      onClick: (value: any) => {
        setSelectedData(undefined);
        setIsEditedCandidate(true);
        setModalCandidateIsShow(true);
      },
    },
    {
      type: 'ghost',
      icon: 'DownloadOutlined',
      onClick: handleDownloadAAJI,
      content: 'Unduh AAJI Checking',
      id: 'btnUnduhAAJI',
    },
  ];

  const cardContentQA = () => {
    const isDisabledDate = (d: any) =>
      !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'));
    return (
      <div className="containerWhiteZurich">
        <Row gutter={20}>
          <Col span={10}>
            <div className="labelBtnFilter">Tanggal</div>
            <InputDatePicker
              name="dateFilter"
              idComponent="inputDateFilterQA"
              typeDate="dateRangePicker"
              disabledDate={isDisabledDate}
              placeholder={['Tanggal Mulai', 'Tanggal Berakhir']}
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setDateFilter,
                value: dateFilter ? dateFilter : null,
              }}
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={4}>
            <div className="labelBtnFilter">Status</div>
            <Select
              name="statusJourney"
              dataOption={listJourneyCandidate}
              defaultItemName="Semua"
              idComponent="inputStatusJourney"
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setStatusJourney,
                value: statusJourney ? statusJourney : 'Semua',
              }}
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={4}>
            <div className="labelBtnFilter">Level</div>
            <Select
              name="level"
              dataOption={candidateLevel}
              defaultItemName="Semua"
              idComponent="inputLevelQA"
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setLevel,
                value: level ? level : 'Semua',
              }}
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={6} className={'btnFilterAplicationList'}>
            <CButton
              buttonData={buttonData}
              buttonFloat={'right'}
              isLoading={isFetchingQA}
            />
          </Col>
        </Row>
        <Row className={'spacingBtnSecondRow'}>
          <CButton
            buttonData={buttonDataSecondRow}
            buttonFloat={'right'}
            isLoading={isFetchingQA}
          />
        </Row>
        <Table
          isLoading={isFetchingQA}
          columns={columnData}
          data={dataFilter || []}
          pagination={true}
          useFilterGlobal
          renderRowSubComponent={renderRowSubComponent}
        />
        <ModalCandidateContainer
          modalCandidateIsShow={modalCandidateIsShow}
          setModalCandidateIsShow={setModalCandidateIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          schemaData={schemaData}
          isEditedCandidate={isEditedCandidate}
          setIsEditedCandidate={setIsEditedCandidate}
          documentList={document}
          totalOtherattachments={totalOtherattachments}
          setTotalOtherattachments={setTotalOtherattachments}
          documentSubmission={documentSubmission}
          detailCandidate={detailCandidate}
          recruiter={recruiter}
          directLeaderList={directLeaderList}
          setAgentCode={setAgentCode}
          setAgentLevel={setAgentLevel}
          setCandidateLevel={setCandidateLevel}
          documentsContent={documentsContent}
          handleUploadContent={handleUploadContent}
          setContentDocument={setContentDocument}
          contentDocument={contentDocument}
          setModalDetailIsShow={setModalDetailIsShow}
          documentMeta={documentMeta}
          refreshList={refreshList}
          newListBeneficiary={newListBeneficiary}
          setNewListBeneficiary={setNewListBeneficiary}
          isUploading={isUploading}
          newCityDomicile={newCityDomicile}
          setNewCityDomicile={setNewCityDomicile}
          newDistrictDomicile={newDistrictDomicile}
          setNewDistrictDomicile={setNewDistrictDomicile}
          newVillageDomicile={newVillageDomicile}
          setNewVillageDomicile={setNewVillageDomicile}
          newCity={newCity}
          setNewCity={setNewCity}
          newDistrict={newDistrict}
          setNewDistrict={setNewDistrict}
          newVillage={newVillage}
          setNewVillage={setNewVillage}
          candidateApprovers={candidateApprovers}
        />
        <ModalDetailQAContainer
          intl={intl}
          modalDetailIsShow={modalDetailIsShow}
          setModalDetailIsShow={setModalDetailIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          schema={schemaData}
          detailCandidate={detailCandidate}
          setModalCandidateIsShow={setModalCandidateIsShow}
          isFetchingSubmission={isFetchingSubmission}
          refreshList={refreshList}
          qaStatus={qaStatus}
          setQaStatus={setQaStatus}
          documentsContent={documentsContent}
          handleUploadContent={handleUploadContent}
          documentMeta={documentMeta}
          setContentDocument={setContentDocument}
          setNewListBeneficiary={setNewListBeneficiary}
          candidateApprovers={candidateApprovers}
          isUploading={isUploading}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="QA Process" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Daftar Aplikasi</span>
        }
        cardContent={cardContentQA()}
      />
    </React.Fragment>
  );
}
export default injectIntl(QAProcessComponent);

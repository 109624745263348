import { Button, Col, Layout, Row } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import React from 'react';

interface IProps {
  siderIsCollapse: boolean;
  handleCollapseSider: () => void;
  logOut: () => void;
  username: any;
}
export default function HeaderComponent(props: IProps) {
  const { Header } = Layout;
  const { siderIsCollapse, handleCollapseSider, logOut, username } = props;

  return (
    <Header className="headerTemplate">
      <Row>
        <Col span={1}>
          <CIcon
            className="iconTriggerColapse"
            type={siderIsCollapse ? 'RightOutlined' : 'LeftOutlined'}
            onClick={handleCollapseSider}
          />
        </Col>
        <Col span={20}>
          <p className="roleStatusHeader">Admin</p>
          <p className="usernameHeader capital">{username}</p>
        </Col>
        <Col span={3}>
          <Button
            shape="round"
            className="btnLogoutHeader"
            onClick={logOut}
            id="btnLogOut"
          >
            Logout
            <CIcon type="ExportOutlined" />
          </Button>
        </Col>
      </Row>
    </Header>
  );
}

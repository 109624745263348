const invitationValidation = values => {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'Template name required!';
  } else {
    if (
      !values.templateSms &&
      !values.templateEmail &&
      !values.templateSocialMedia
    ) {
      errors.templateData = 'Must fill at least 1 invitation content!';
    }
  }
  if (values.emailSubject && !values.templateEmail) {
    errors.templateEmail = 'If email subject filled, email body required!';
  }
  if (!values.emailSubject && values.templateEmail) {
    errors.emailSubject = 'If email body filled, email subject required!';
  }
  return errors;
};
export default invitationValidation;

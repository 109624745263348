import React from 'react';
import ModalContentAAJIComponent from '../../../Component/Modal/ModalContent/ModalContentAAJIComponent';
import { IntlShape } from 'react-intl';
import {
  getLookupBlacklistAPI,
  mutateSubmitAAJIAPI,
} from '../../../../../Services/qaProcessAAJI';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  intl: IntlShape;
  documentsContent: any;
  submission: any;
  setModalDetailIsShow: any;
  refreshList: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  documentMeta: any;
  formValue: any;
  aajiSubmission: any;
  setValuesAAJI: React.Dispatch<React.SetStateAction<undefined>>;
  isUploading: boolean;
}
export default function ModalContentAAJIContainer(props: IProps) {
  const {
    intl,
    documentsContent,
    submission,
    setModalDetailIsShow,
    refreshList,
    handleUploadContent,
    documentMeta,
    formValue,
    aajiSubmission,
    setValuesAAJI,
    isUploading,
  } = props;

  const { data: blacklistData } = useQuery(
    ['getLookupBlacklist', { submission }],
    getLookupBlacklistAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateSubmitAAJI, isLoading: isLoadingAAJI } = useMutation(
    mutateSubmitAAJIAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            Data berhasil dilanjutkan ke proses QA
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        setModalDetailIsShow(false);
        refreshList();
      },
    }
  );

  const handleSubmit = (vals: any) => {
    mutateSubmitAAJI({
      ...vals,
      ...formValue,
      duplicateData: blacklistData?.duplicateData,
    });
  };
  return (
    <ModalContentAAJIComponent
      intl={intl}
      documentsContent={documentsContent}
      handleSubmit={handleSubmit}
      handleUploadContent={handleUploadContent}
      documentMeta={documentMeta}
      aajiSubmission={aajiSubmission}
      setValuesAAJI={setValuesAAJI}
      isLoadingAAJI={isLoadingAAJI || isUploading}
    />
  );
}

import React from 'react';
import { downloadFileAPI, getFileListAPI } from '../../../Services/privy.api';
import DownloadPrivyComponent from '../Component/DownloadPrivyComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import moment from 'moment';
import CButton from '../../../Assets/Components/CButton';
import ErrorHandler from '../../../App/ErrorHandler';

export default function DownloadPrivyContainer() {
  const {
    data: fileList,
    fetchStatus,
    isLoading,
    isRefetching,
    refetch: refetchList,
  } = useQuery(['getFileList'], getFileListAPI, {
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateDownloadPrivy, isLoading: isDownloading } = useMutation(
    downloadFileAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    }
  );

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'primary',
        id: 'btnDownloadPrivy',
        className: 'btnDownloadPrivy',
        icon: 'DownloadOutlined',
        content: 'Download berkas',
        onClick: () =>
          mutateDownloadPrivy({
            id: rows?.id,
          }),
      },
    ];
    return <CButton buttonData={dataButton} isLoading={isDownloading} />;
  };

  const renderStatementType = (row: any) => {
    const statementType = row.row.original.properties.agreementtype;
    if (statementType === 'leader') {
      return 'Leader';
    } else if (statementType === 'nonleader') {
      return 'Non Leader';
    } else {
      return '';
    }
  };

  const renderSearchType = (row: any) => {
    if (row?.properties?.agreementtype === 'nonleader') {
      return 'Non Leader';
    } else {
      return 'Leader';
    }
  };

  const columnData = [
    {
      Header: 'Nama Berkas',
      accessor: 'originalFileName',
    },
    {
      Header: 'Tipe Statement',
      accessor: (row: any) => renderSearchType(row),
      Cell: (row: any) => renderStatementType(row),
    },
    {
      Header: 'Versi',
      Cell: (row: any) =>
        moment(row.row.original.properties.version).format(
          'DD-MMM-YYYY, HH:mm'
        ),
    },
    {
      Header: ' ',
      Cell: (row: any) => renderAction(row),
    },
  ];
  return (
    <DownloadPrivyComponent
      fileList={fileList || []}
      handleRefresh={refetchList}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      columnData={columnData}
    />
  );
}

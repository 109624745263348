const ConfigurationValidation = ({ parameterType, parameterValue }) => {
  const errors: any = {};
  if (!parameterType) {
    errors.parameterType = 'Parameter type is required!';
  }
  if (!parameterValue) {
    errors.parameterValue = 'Parameter value is required!';
  } else if (parameterValue && !/^[^\s].*/.test(parameterValue)) {
    errors.parameterValue = 'Use letters/numbers at the beginning of words!';
  }
  return errors;
};
export default ConfigurationValidation;

import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { contentManagementModuleAPI } from '../../../../Services/ElearningContentManagement/module.api';
import ModuleModalComponent from '../Component/ModuleModalComponent';
import ModuleModalGeneralContainer from './Modal/ModuleModalGeneralContainer';
import ModuleModalParticipantContainer from './Modal/ModuleModalParticipantContainer';
import { FormattedMessage } from 'react-intl';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  stage: any;
  moduleList: any;
  modalAction: string;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  refetchModule: () => void;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  modalIsShow: boolean;
  selectedData?: any;
}

export default function ModuleModalContainer(props: IProps) {
  const {
    channel,
    stage,
    moduleList,
    modalAction,
    setModalIsShow,
    refetchModule,
    modalIsShow,
    selectedData,
    setSelectedData,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [currentSteps, setCurrentSteps] = useState(0);
  const [initialValues, setInitialValues] = useState<any>({
    participant: { agentLevels: ['A1', 'A2', 'A3', 'A4'] },
  });

  useEffect(() => {
    if (!selectedData) return;
    const general = {
      name: selectedData.name,
      predecessor: selectedData.predecessor,
      sequence: selectedData.sequence,
      isCertificate: selectedData.isCertificate,
      certificateId: selectedData.certificateId,
      isRefreshment: selectedData.isRefreshment,
      isCpdStatus: selectedData.isCpdStatus,
      cpdRefreshName: selectedData.cpdRefreshName,
      cpdPoint: selectedData.cpdPoint,
      defaultLearningHour: selectedData.defaultLearningHour,
      isActive: selectedData.isActive,
    };
    const participant = {
      agentLevels: ['A1', 'A2', 'A3', 'A4'],
      participantCondition: selectedData.participantCondition,
      [selectedData.participantCondition === 'EffectiveDate'
        ? 'conditionLogicEffectiveDate'
        : 'conditionLogicAfterGetAgentCode']: selectedData.conditionLogic,
      [selectedData.participantCondition === 'EffectiveDate'
        ? 'effectiveDateDays'
        : 'afterGetAgentCodeDays']: selectedData.conditionDays,
    };
    setInitialValues({ general, participant });
  }, [selectedData]);

  const {
    data: certificateList,
    isLoading: isLoadingCertif,
    fetchStatus: fetchStatusCertif,
  } = useQuery(
    ['GetContentManagementCertificateAPI'],
    contentManagementModuleAPI.getCertificate,
    {
      refetchOnWindowFocus: false,
    }
  );

  const mutate = useMutation(contentManagementModuleAPI.mutateModule, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage
            id={
              selectedData
                ? 'updateElearningModuleStatusSuccess'
                : 'createElearningModuleSuccess'
            }
          />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
      refetchModule();
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const handleCancel = () => {
    setCurrentSteps(0);
    setSelectedData(undefined);
    setEditMode(false);
    setModalIsShow(false);
  };

  const handleSubmit = vals => {
    if (initialValues && !editMode) setEditMode(true);
    else
      mutate.mutate({
        ...vals.general,
        ...vals.participant,
        codemiUserLevelId: stage.codemiUserLevelId,
        moduleId: selectedData?.id,
        channelId: selectedData?.channelId || channel.id,
        stageId: selectedData?.stageId || stage.id,
      });
  };

  const predecessorList: any = [];
  if (selectedData) {
    const filterListPredecessor = moduleList.filter(
      x => !x.name.includes(selectedData.name)
    );
    filterListPredecessor.forEach(item => {
      const { codemiLearningPathId, name, sequence } = item;
      predecessorList.push({
        id: codemiLearningPathId,
        name,
        sequence: sequence + 1,
      });
    });
  } else
    moduleList.forEach(item => {
      const { codemiLearningPathId, name, sequence } = item;
      predecessorList.push({
        id: codemiLearningPathId,
        name,
        sequence: sequence + 1,
      });
    });

  const disabledTabs = (index: number, step: number) => {
    if (editMode) return false;
    else {
      if (index <= step) return false;
      else return true;
    }
  };

  const steps = [
    {
      id: 'general',
      title: 'General',
      content: (
        <ModuleModalGeneralContainer
          certificateList={certificateList || []}
          disabled={
            editMode === false && modalAction === 'update' ? true : false
          }
          predecessorList={predecessorList}
          selectedData={initialValues}
          {...props}
        />
      ),
      disabled: disabledTabs(0, currentSteps),
    },
    {
      id: 'participant',
      title: 'Add Participant',
      content: (
        <ModuleModalParticipantContainer
          disabled={
            editMode === false && modalAction === 'update' ? true : false
          }
          editMode={editMode}
          selectedData={initialValues}
          setEditMode={setEditMode}
          {...props}
        />
      ),
      disabled: disabledTabs(1, currentSteps),
    },
  ];

  const handleSteps = step => setCurrentSteps(step);

  const handleNext = () => {
    if (currentSteps !== steps.length - 1) setCurrentSteps(currentSteps + 1);
  };
  const handleBack = () => {
    if (currentSteps !== 0) setCurrentSteps(currentSteps - 1);
  };

  const handleEditClick = () => setEditMode(true);

  return (
    <ModuleModalComponent
      currentSteps={currentSteps}
      isShow={modalIsShow}
      editMode={editMode}
      modalAction={selectedData ? 'update' : 'register'}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleStep={handleSteps}
      handleNext={handleNext}
      handleBack={handleBack}
      handleEditClick={handleEditClick}
      predecessorList={predecessorList}
      certificateList={certificateList}
      initialValues={initialValues}
      isLoading={isLoadingCertif || fetchStatusCertif === 'fetching'}
      isLoadingSubmit={mutate.isLoading}
      steps={steps}
    />
  );
}

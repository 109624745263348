const LibraryFolderValidation = ({ foldername }) => {
  const errors: any = {};
  if (!foldername) {
    errors.foldername = 'Name required!';
  } else if (foldername && !/^[^\s].*/.test(foldername)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  } else {
    if (foldername.replace(/\s/g, '') === '') {
      errors.foldername = 'Name required!';
    }
  }
  return errors;
};
export default LibraryFolderValidation;

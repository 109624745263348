import React from 'react';
import {
  dosdListCompleted,
  dosdListOnProgress,
  dosdListToDoList,
} from '../../../App/Enums';

import { Col, Row } from 'antd';
import { injectIntl } from 'react-intl';
import CCard from '../../../Assets/Components/CCard';
import { IResultDashboardDosdDAO } from '../DAO/dashboard.dao';

interface IProps {
  data: IResultDashboardDosdDAO[];
  intl?: any;
}

function DashboardDosdComponent({ intl, data }: IProps) {
  const renderCard = (
    title: string,
    statusList: {
      id: string;
      name: string;
    }[],
    dosdList: any[]
  ) => {
    const countValue = value => {
      return dosdList.reduce((counter, obj) => {
        if (obj.candidateStatus === value) counter += 1;
        return counter;
      }, 0);
    };
    return (
      <CCard
        cardClassName="cardDashboard"
        cardTitle={<span className="titleCardDashboard">{title}</span>}
        cardContent={
          <div className="dashboardValueContainer2">
            <div className="dashboardCardDosd">
              {statusList.map(data => (
                <div key={data.id}>
                  <Row className="dashboardCardValue">
                    <Col span={20}>
                      <p>{data.name}</p>
                    </Col>
                    <Col span={4}>
                      <p style={{ float: 'right' }}>
                        {countValue(data.id) === 0 ? '-' : countValue(data.id)}
                      </p>
                    </Col>
                  </Row>
                  {data.name === 'Agent Activated' ||
                  data.name === 'Pending Update' ||
                  data.name === 'Uploading Payment Slip' ? (
                    ''
                  ) : (
                    <hr />
                  )}
                </div>
              ))}
            </div>
          </div>
        }
      />
    );
  };

  return (
    <>
      <Col span={12}>
        {renderCard(
          intl.formatMessage({
            id: 'dashboardDosdOnProgressTitle',
          }),
          dosdListOnProgress,
          data
        )}
        <div className="cardCompleted">
          {renderCard(
            intl.formatMessage({
              id: 'dashboardCompletedTitle',
            }),
            dosdListCompleted,
            data
          )}
        </div>
      </Col>
      <Col span={12}>
        {renderCard(
          intl.formatMessage({
            id: 'dashboardDosdToDoListTitle',
          }),
          dosdListToDoList,
          data
        )}
      </Col>
    </>
  );
}
export default injectIntl(DashboardDosdComponent);

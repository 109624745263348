import { DatePicker, Form } from 'antd';

import React from 'react';
import moment from 'moment';

const formatDate = 'YYYY-MM-DD';

interface IPropsDatePicker {
  input?;
  meta?;
  placeholder?;
  idComponent?;
  icon?;
  label?;
  data?;
  disabled?;
  typeDate?;
  disabledDate?;
  update?;
  formClassName?;
  formatInput?;
  formatPicker?;
  addtionalLabel?;
  addtionalLabelPos?: 'side' | 'bottom';
  className?;
  labelIsRequired?;
  defaultValue?;
  additionalOnChange?: (e) => void;
}

const convertToMoment: any = val => {
  return moment(val);
};

const processDateRangePicker = params => {
  if (moment.isMoment(params)) {
    return params;
  } else if (params) {
    return convertToMoment(params);
  } else {
    return undefined;
  }
};

const handleDefaultValue = (inputValue, typeDate) => {
  if (inputValue) {
    if (typeDate === 'dateRangePicker') {
      if (typeof inputValue === 'object') {
        const startDate = processDateRangePicker(inputValue[0]);
        const endDate = processDateRangePicker(inputValue[1]);
        return [startDate, endDate];
      }
    } else {
      if (moment.isMoment(inputValue)) {
        return inputValue;
      } else {
        return convertToMoment(inputValue);
      }
    }
  }
  return undefined;
};

export const InputDatePicker = (props: IPropsDatePicker) => {
  const {
    input,
    meta: { touched, error },
    idComponent,
    label,
    disabled,
    typeDate,
    disabledDate,
    update,
    formClassName,
    formatInput,
    formatPicker,
    placeholder,
    addtionalLabel,
    className,
    labelIsRequired,
    defaultValue,
    addtionalLabelPos,
    additionalOnChange,
  } = props;

  const handleOnChange = e => {
    if(additionalOnChange) additionalOnChange(e)
    input.onChange(e);
  };
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };

  const labelTitle = () => {
    if (addtionalLabelPos && addtionalLabelPos === 'bottom')
      return (
        <span>
          <b className="capital">
            {label}
            {lebelRequired()}
          </b>
          <p style={{ marginBottom: '0px' }}>
            {addtionalLabel ? addtionalLabel : null}
          </p>
        </span>
      );
    else
      return (
        <span>
          <b className="capital">{label}</b>
          <span style={{ color: '#BFBFBF' }}>
            {addtionalLabel ? ` ${addtionalLabel}` : null}
            {lebelRequired()}
          </span>
        </span>
      );
  };

  const inputValue = input.value;
  const renderPicker = () => {
    if ((update && typeof inputValue === 'object') || !update) {
      if (typeDate === 'datePicker') {
        return (
          <DatePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            format={formatInput ? formatInput : formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
            picker={formatPicker}
            placeholder={placeholder}
            className={className}
          />
        );
      } else if (typeDate === 'dateRangePicker') {
        return (
          <DatePicker.RangePicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            id={idComponent}
            format={formatInput ? formatInput : formatDate}
            value={handleDefaultValue(inputValue, typeDate)}
            disabled={disabled}
            disabledDate={disabledDate}
            picker={formatPicker}
            placeholder={placeholder}
            className={className}
            defaultValue={defaultValue}
          />
        );
      } else if (typeDate === 'monthPicker') {
        return (
          <DatePicker.MonthPicker
            style={{ width: '100%' }}
            onChange={handleOnChange}
            onBlur={input.onBlur}
            id={idComponent}
            disabled={disabled}
            disabledDate={disabledDate}
          />
        );
      }
      return null;
    }
    return null;
  };
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle()}
      colon={false}
      className={formClassName}
    >
      {renderPicker()}
    </Form.Item>
  );
};

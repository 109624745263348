import React from 'react';
import ModalUploadDetailLicenseComponent from '../../Component/Modal/ModalUploadDetailLicenseComponent';

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  licenseDetailUpload: any;
}

export default function ModalUploadDetailLicenseContainer(props: IProps) {
  const { showModal, setShowModal, licenseDetailUpload } = props;
  const handleCancel = () => setShowModal(false);

  return (
    <ModalUploadDetailLicenseComponent
      handleCancel={handleCancel}
      showModal={showModal}
      licenseDetailUpload={licenseDetailUpload}
    />
  );
}

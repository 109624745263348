import { Col, Form, Row, Tag } from 'antd';
import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import { DynamicTabs } from '../../../Assets/Components/CDynamicTabs';
import CIcon from '../../../Assets/Components/CIcon';
import CTable from '../../../Assets/Components/CTable';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Field, FormRenderProps } from 'react-final-form';
import {
  IResultCandidateFormDao,
  IResultCandidateGroupDao,
  IResultContentInquiryDao,
  IResultDocumentAgreementDao,
} from '../../License/DAO/license.dao';
import { IResultAgentTaskHistoryDAO } from '../DAO/newAgentMonitoring.dao';
import { injectIntl } from 'react-intl';
import Select from '../../../Assets/Components/Select';

interface IProps {
  candidateApprovers: any;
  column: any;
  columnData: any;
  detailCandidate: any;
  documents: any;
  handleCancel: () => void;
  isLoading: boolean;
  listAgreement: IResultDocumentAgreementDao[];
  listContent: IResultContentInquiryDao[];
  listFilenet: { id: string; name: string }[];
  listHistory: IResultAgentTaskHistoryDAO[];
  modalIsShow: boolean;
  schema?: IResultCandidateFormDao;
  submission?: IResultCandidateGroupDao;
  intl?: any;
}

const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '85vw',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '99',
  },
};

function ModalNewAgentMonitoringComponent(props: IProps) {
  const {
    candidateApprovers,
    column,
    columnData,
    detailCandidate,
    documents,
    handleCancel,
    isLoading,
    intl,
    listAgreement,
    listContent,
    listFilenet,
    listHistory,
    modalIsShow,
    schema,
    submission,
  } = props;

  const newSub = submission?.submission;
  const taxBankData = newSub?.personaldata?.taxbankaccount;
  const jsonTemp = {
    ...newSub,
    personaldata: {
      ...newSub?.personaldata,
      agency: {
        ...newSub?.personaldata.agency,
        agencyLevel: candidateApprovers?.approverLevel,
        recruiter: candidateApprovers?.name,
      },
      taxbankaccount: {
        bank: taxBankData?.bank,
        tax: {
          taxpayer: taxBankData?.tax?.taxpayer,
          taxownership: taxBankData?.tax?.taxownership,
          taxdependents: taxBankData?.tax?.taxdependents,
        },
      },
    },
  };
  const detailCandidateValue = detailCandidate ? detailCandidate : '';

  const headerModalComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Detail Info</span>
      </h3>
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { values } = formProps;

    const detailCandidateLevel = detailCandidate
      ? detailCandidate.candidateLevel
      : '';

    const headerDetailCandidateComponent = () => {
      return (
        <Row>
          <Col span={1}>
            <CIcon type={'UserOutlined'} className={'iconUserCustomSize'} />
          </Col>
          <Col span={18}>
            <Col>
              <span className="titleCandidateAgentMonitoring">
                {detailCandidateValue.candidateName}
              </span>
              <br />
              <span className="titleContractNumberAgent">
                {detailCandidateValue.contractNumber}
              </span>
            </Col>
          </Col>
          <Col span={4}>
            <span className={'titlePopupAgentMonitoringStatus'}>
              Current Status:
            </span>
            <br />
            <span className={'tagStatPopupAgentMonitoring'}>
              {renderStatus(detailCandidateValue.candidateStatus)}
            </span>
          </Col>
        </Row>
      );
    };

    const renderFileNet = () => {
      const headerCard = () => {
        return <span className="titleLicenseModal">Filenet</span>;
      };
      const contentCard = () => {
        const onclickFilenet = () => {
          const objectId = values?.filenetId.split('_')[0];
          window.open(
            `http://hkwapp69:9080/navigator/bookmark.jsp?desktop=User&repositoryId=Zurich&docid=${objectId}`
          );
        };
        const dataButtonFileNet = [
          {
            type: 'primary',
            id: 'btnFilenet',
            className: 'buttonViewFileNet',
            content: 'Lihat File',
            disabled: values && values.filenetId ? false : true,
            onClick: () => onclickFilenet(),
          },
        ];
        return (
          <Form layout="vertical">
            <Row gutter={16}>
              <Col span={10}>
                <Field
                  name="filenetId"
                  component={Select}
                  idComponent="inputFileNetId"
                  label="Pilih File"
                  defaultItemName={'Pilih'}
                  dataOption={listFilenet}
                />
              </Col>
              <Col span={4}>
                <CButton buttonData={dataButtonFileNet} />
              </Col>
            </Row>
          </Form>
        );
      };
      return (
        <CCard
          cardClassName="cardLicense"
          cardTitle={headerCard()}
          cardContent={contentCard()}
        />
      );
    };

    const taskHistoriesContent = () => {
      return (
        <div className={'containerWhiteAgentMonitoring'}>
          <h1 className="titleAgentMonitoring">
            <span>Riwayat Status</span>
          </h1>
          <div className={'containerWhiteAgentMonitoringTable'}>
            <CTable
              isLoading={false}
              columns={columnData}
              data={listHistory}
              pagination={false}
            />
          </div>
        </div>
      );
    };

    const detailCandidateContent = () => {
      return (
        <Row gutter={12} className="contentLicenseDetailCandidate">
          <Col span={12}>
            {renderDetailCandidate(
              'Sub Channel',
              detailCandidateValue.subChannel
            )}
            {renderDetailCandidate(
              'Level Kandidat',
              candidateApprovers?.approverLevel
            )}
            {renderDetailCandidate(
              'Tanggal Direkrut',
              detailCandidateValue.insertDate === '01-Jan-0001'
                ? '-'
                : detailCandidateValue.insertDate
            )}
            {renderDetailCandidate(
              'Direct Leader',
              detailCandidateValue.dlName
            )}
            {renderDetailCandidate(
              'Direct Leader Level',
              detailCandidateValue.dlLevel
            )}
          </Col>
        </Row>
      );
    };

    const renderDetailCandidate = (label: string, value: string) => {
      return (
        <Row className={'detailCandidateLicense'}>
          <Col span={10}>{label}</Col>
          <Col span={1}>:</Col>
          <Col span={10}>{value}</Col>
        </Row>
      );
    };
    const tagStat = (tagStatus: string, color: string, fontColor: string) => {
      return (
        <Tag color={color}>
          <div style={{ color: fontColor, width: 150, textAlign: 'center' }}>
            {tagStatus}
          </div>
        </Tag>
      );
    };

    const renderStatus = (status: string) => {
      switch (status) {
        case 'Agent Activated': {
          return tagStat('Agent Activated', '#3ACE3A', '#FFFFFF');
        }
        default: {
          return tagStat(status, '#00000029', '#000000');
        }
      }
    };

    return (
      <>
        {renderFileNet()}
        <CCard
          cardClassName="cardAgentMonitoring"
          cardTitle={headerDetailCandidateComponent()}
          cardContent={detailCandidateContent()}
        />
        {taskHistoriesContent()}
        {documents && submission && schema ? (
          <DynamicTabs
            json={jsonTemp}
            intl={intl}
            column={column}
            schema={schema.schema.properties}
            candidateLevel={detailCandidateLevel}
            documents={documents}
            docAgreement={listAgreement}
            listContent={listContent}
          />
        ) : null}
      </>
    );
  };

  return (
    <CModalRFF
      contentClassName="contentLicense"
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={null}
      handleCancel={handleCancel}
      handleSubmit={() => {}}
      handleValidation={() => {}}
      headerComponent={headerModalComponent()}
      initialValues={[]}
      isForm={true}
      isLoading={isLoading}
      modalIsShow={modalIsShow}
      modalstyles={defaultModalstyles}
    />
  );
}
export default injectIntl(ModalNewAgentMonitoringComponent);

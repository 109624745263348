import React, { Dispatch, SetStateAction, useState } from 'react';
import TermsConditionsComponent from '../Component/TermsConditionsComponent';
import { useQuery } from '@tanstack/react-query';
import { getDisclaimerListAPI } from '../../../Services/recruitment.api';

interface IProps {
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

export default function TermsConditionsContainer(props: IProps) {
  const { setCurrentStep } = props;
  const [termsIsChecked, setTermsIsChecked] = useState(false);

  const { data: disclaimerMappingList } = useQuery(
    ['getDisclaimerMappinglist'],
    getDisclaimerListAPI,
    {
      refetchOnWindowFocus: false,
    }
  );
  const disclaimer = disclaimerMappingList?.find(
    (val: any) => val.disclaimerModuleCode === 'DM037'
  );

  const handleNext = () => {
    setCurrentStep(2);
  };

  return (
    <TermsConditionsComponent
      handleNext={handleNext}
      termsIsChecked={termsIsChecked}
      setTermsIsChecked={setTermsIsChecked}
      disclaimer={disclaimer}
    />
  );
}

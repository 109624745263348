import React from 'react';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import { injectIntl } from 'react-intl';
import { Breadcrumb, Col, Row, Tree, Input } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import HasPermission from '../../../App/HasPermission';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import ModalLibraryFolderContainer from '../Container/ModalLibraryFolderContainer';
import ModalLibraryFileContainer from '../Container/ModalLibraryFileContainer';
import ModalMoveLibraryContainer from '../Container/ModalMoveLibraryContainer';
import { IntlShape } from 'react-intl';
import { IResultLibraryListDAO } from '../DAO/library.dao';

interface IProps {
  list: IResultLibraryListDAO[];
  intl: IntlShape;
  columnData: {
    Header: string;
    accessor: string;
    Cell: (row: any) => JSX.Element;
    width?: undefined;
  }[];
  selectedPath: string;
  handleOnClickBreadcrumb: (path: any) => void;
  libraryList: IResultLibraryListDAO[];
  handleOnDoubleClickRow: (record: any) => void;
  libraryListData: any;
  handleRefresh: () => void;
  isLoadingList: boolean;
  handleOnSelectTree: any;
  handleOnSearchLibrary: (value: any) => void;
  modalFolderIsShow: boolean;
  setModalFolderIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalUploadIsShow: boolean;
  setModalUploadIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setIsRoot: React.Dispatch<React.SetStateAction<boolean>>;
  setLibrarySelected: React.Dispatch<React.SetStateAction<never[]>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
  refetch: () => void;
  modalMoveShow: boolean;
  setModalMoveShow: React.Dispatch<React.SetStateAction<boolean>>;
  libraryCollection: any;
  setLibraryCollection: React.Dispatch<React.SetStateAction<undefined>>;
  selectedMovePath: never[];
  setSelectedMovePath: React.Dispatch<React.SetStateAction<undefined>>;
  moveType: string;
  setMoveType: React.Dispatch<React.SetStateAction<string>>;
}
function LibraryComponent(props: IProps) {
  const {
    list,
    intl,
    columnData,
    selectedPath,
    handleOnClickBreadcrumb,
    libraryList,
    handleOnDoubleClickRow,
    libraryListData,
    handleRefresh,
    isLoadingList,
    handleOnSelectTree,
    handleOnSearchLibrary,
    modalFolderIsShow,
    setModalFolderIsShow,
    modalUploadIsShow,
    setModalUploadIsShow,
    setIsRoot,
    setLibrarySelected,
    selectedData,
    setSelectedData,
    refetch,
    modalMoveShow,
    setModalMoveShow,
    libraryCollection,
    setLibraryCollection,
    selectedMovePath,
    setSelectedMovePath,
    moveType,
    setMoveType,
  } = props;

  const { DirectoryTree } = Tree;
  const { Search } = Input;

  const buttonData = () => {
    return [
      HasPermission('c')
        ? {
            type: 'primary',
            icon: 'UploadOutlined',
            onClick: () => setModalUploadIsShow(true),
            content: 'New Upload',
            id: 'btnAddLibrary',
          }
        : null,
    ];
  };

  const renderBreadcrumb = () => {
    const splitBreadcrumb = selectedPath.split('/');
    let bPath: any;
    return splitBreadcrumb?.map((val, index) => {
      bPath = index === 0 ? `${val}` : `${bPath}/${val}`;
      const breadcrumbPath = index === 0 ? `${val}` : `${bPath}`;
      return (
        <Breadcrumb.Item
          onClick={() => handleOnClickBreadcrumb(breadcrumbPath)}
          key={`breadcrumb-${val}-${index}`}
          className="breadcrumbList"
        >
          {val}
        </Breadcrumb.Item>
      );
    });
  };

  const cardLibraryContent = () => {
    return (
      <div className="containerWhiteZurich">
        <Row gutter={30}>
          <Col span={6}>
            <div className="divTitleCollection">
              <p>Collection</p>
              {HasPermission('c') ? (
                <CIcon
                  className="iconTitleCollection"
                  type="FolderAddFilled"
                  onClick={() => {
                    setModalFolderIsShow(true);
                    setSelectedData(undefined);
                  }}
                />
              ) : null}
            </div>
            <DirectoryTree
              className="directoryTreeLib"
              onSelect={handleOnSelectTree}
              treeData={libraryListData || []}
            />
          </Col>
          <Col span={18}>
            <Breadcrumb className="breadcrumbLibrary">
              {renderBreadcrumb()}
            </Breadcrumb>
            <CButton
              buttonData={buttonData()}
              buttonFloat={'right'}
              isLoading={false}
              handleRefresh={handleRefresh}
            />
            <Search
              name="name"
              placeholder="Search all file"
              onSearch={handleOnSearchLibrary}
              size="large"
              className="searchLibrary"
            />
            <Table
              isLoading={isLoadingList}
              columns={generateColumnData(columnData)}
              data={libraryList || []}
              handleOnDoubleClick={handleOnDoubleClickRow}
              useFilterGlobal={true}
              manualGlobalFilter={true}
              pagination={true}
            />
          </Col>
        </Row>
        <ModalLibraryFolderContainer
          {...props}
          list={list}
          setLibraryCollection={setLibraryCollection}
          setModalMoveShow={setModalMoveShow}
          setMoveType={setMoveType}
          setSelectedMovePath={setSelectedMovePath}
          modalFolderIsShow={modalFolderIsShow}
          setIsRoot={setIsRoot}
          setLibrarySelected={setLibrarySelected}
          selectedData={selectedData}
          refetch={refetch}
        />
        <ModalLibraryFileContainer
          {...props}
          modalUploadIsShow={modalUploadIsShow}
        />
        <ModalMoveLibraryContainer
          {...props}
          modalMoveShow={modalMoveShow}
          libraryCollection={libraryCollection}
          selectedMovePath={selectedMovePath}
          moveType={moveType}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Library" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'libraryCardTitle',
            })}
          </span>
        }
        cardContent={cardLibraryContent()}
      />
    </>
  );
}
export default injectIntl(LibraryComponent);

import React from 'react';
import { FormattedMessage } from 'react-intl';
import Uppy from '@uppy/core';
import { localeUppy } from '../../../Modules/Internationalization/translations/en';
import XHRUpload from '@uppy/xhr-upload';
import Store from '../../../Store';
import Dashboard from '@uppy/react/lib/Dashboard';
import CModalFile from '../../../Assets/Components/CModalFile';
import history from '../../../App/History';
import { toast } from 'react-toastify';

interface IProps {
  modalUploadIsShow: boolean;
  handleCancel: () => void;
}
export default function CityUploadModalComponent(props: IProps) {
  const { modalUploadIsShow, handleCancel } = props;
  const authState: any = Store.getState();
  const uppyUploadCity = new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.xlsx'],
    },
    locale: localeUppy,
  }).use(XHRUpload, {
    id: `uppyFileGalleryXHR`,
    headers: {
      Authorization: `bearer ${authState.token}`,
    },
    endpoint: `${process.env.REACT_APP_API_URL}/configuration/regions/upload/excel`,
    method: 'post',
    formData: true,
    fieldName: 'file',
    allowedMetaFields: ['originalFilename'],
    disableThumbnailGenerator: true,
    showRemoveButtonAfterComplete: true,
    inline: true,
    timeout: 300 * 1000,
  });
  uppyUploadCity.on('file-added', file => {
    uppyUploadCity.setFileMeta(file.id, {
      originalFilename: file.name,
    });
  });
  uppyUploadCity.on('upload-success', () => {
    toast.success('Berkas Berhasil Diunggah');
    handleCancel();
  });
  uppyUploadCity.on('upload-error', (file, error, response: any) => {
    if (response.status === 401) {
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      authState.logOut();
    }
  });

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="newUpload" />
      </h3>
    );
  };

  const contentComponent = () => {
    return (
      <React.Fragment>
        <p className="titleUploadFileCityModal">Upload File</p>
        <div>
          <Dashboard
            id="uppyUploadCity"
            uppy={uppyUploadCity}
            width={'100%'}
            disableThumbnailGenerator={true}
            showRemoveButtonAfterComplete={true}
            inline={true}
            proudlyDisplayPoweredByUppy={true}
            height={300}
          />
        </div>
      </React.Fragment>
    );
  };

  return (
    <CModalFile
      modalIsShow={modalUploadIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      isForm={true}
      formLayout="horizontal"
    />
  );
}

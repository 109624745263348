import { Button, Skeleton } from 'antd';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, injectIntl } from 'react-intl';
import { typeAgreement } from '../../../App/Enums';
import CIcon from '../../../Assets/Components/CIcon';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import Select from '../../../Assets/Components/Select';
import validate from '../Validation/AgreementMappingValidation';

interface IProps {
  isShow: boolean;
  initialValues: any;
  modalAction: string;
  intl: any;
  editMode: boolean;
  disabled: boolean;
  isLoading: any;
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
  agreementContentList: any[];
  agreementModuleList: any[];
}

function ModalAgreementMappingComponent(props: IProps) {
  const {
    isShow,
    initialValues,
    modalAction,
    intl,
    editMode,
    disabled,
    isLoading,
    handleCancel,
    handleSubmit,
    agreementContentList,
    agreementModuleList,
  } = props;
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailAgreementMapping" />
        ) : (
          <FormattedMessage id="addAgreementMapping" />
        )}
      </h3>
    );
  };
  const contentComponent = (formProps: any) => {
    return (
      <>
        <Skeleton active loading={isLoading}>
          <h1 className="titleModalAgreement">AGREEMENT</h1>
          <Field
            name="disclaimerId"
            component={Select}
            idComponent="inputAgreementId"
            dataOption={agreementContentList || []}
            defaultItemName="Select agreement"
            label={intl.formatMessage({ id: 'agreementName' })}
            disabled={disabled}
            combineValue={['|', 'code', 'name']}
            disabledByKey="isActive"
            disabledByKeyNegation={true}
          />
          <Field
            name="agreementType"
            component={Select}
            idComponent="inputAgreementType"
            dataOption={typeAgreement}
            defaultItemName="Pilih"
            label={intl.formatMessage({ id: 'type' })}
            disabled={disabled}
          />
          <h1 className="titleModalAgreement">MODULE</h1>
          <Field
            name="disclaimerModuleId"
            component={Select}
            idComponent="inputAgreementModuleId"
            dataOption={agreementModuleList || []}
            defaultItemName="Select module"
            label={intl.formatMessage({ id: 'moduleType' })}
            disabled={disabled}
            combineValue={['|', 'code', 'name']}
          />
        </Skeleton>
      </>
    );
  };
  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };
  const footerComponent = (propsFooter: any) => {
    const { invalid } = propsFooter;
    return (
      <Button
        loading={isLoading}
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        icon={<CIcon type="CheckOutlined" />}
        disabled={modalAction === 'update' && !editMode ? false : invalid}
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(modalAction, editMode)}
      </Button>
    );
  };
  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}
export default injectIntl(ModalAgreementMappingComponent);

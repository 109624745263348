import { Col, Row } from 'antd';

import CIcon from '../../../Assets/Components/CIcon';
import React from 'react';
import moment from 'moment';
import CModalRFF from '../../../Assets/Components/CModalRFF';

interface IProps {
  selectedData: any;
  modalDetailWorldIsShow: boolean;
  handleCancel: () => void;
}

export default function ModalDetailWorldCheckComponent(props: IProps) {
  const { selectedData, modalDetailWorldIsShow, handleCancel } = props;
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Detail Unggah</span>
      </h3>
    );
  };
  const contentComponent = () => {
    const error = selectedData ? selectedData.errorMessage.split('Row') : [];
    const detailError: any = [];
    for (const iterator of error) {
      if (iterator !== '') {
        detailError.push(iterator);
      }
    }
    const renderValue = (label: string, name: any) => {
      const valueDetailWorldCheck = () => {
        if (selectedData) {
          if (label === 'Format') {
            return `.${selectedData[name].split('.')[1]}`;
          } else if (label === 'Tanggal Unggah') {
            return moment(selectedData[name]).format('DD-MMM-YYYY');
          } else {
            return selectedData[name];
          }
        } else {
          return '-';
        }
      };
      return (
        <Row style={{ width: '100%', marginBottom: 7 }}>
          <Col span={5}>
            <b>{label}</b>
          </Col>
          <Col span={1}> : </Col>
          <Col span={5}>{valueDetailWorldCheck()}</Col>
        </Row>
      );
    };
    const renderStatus = () => {
      if (selectedData) {
        return (
          <div className={`containerStatusWorldCheck${selectedData.status}`}>
            <div
              className={`containerIconStatusWorldCheck${selectedData.status}`}
            >
              <CIcon
                type={
                  selectedData.status === 'Success'
                    ? 'CheckOutlined'
                    : 'CloseOutlined'
                }
              />
            </div>
            <div className="txtDetailStatus">
              {selectedData.status === 'Success'
                ? 'Berkas Berhasil diunggah'
                : `${detailError.length} baris data gagal diunggah`}
            </div>
          </div>
        );
      }
      return null;
    };
    return (
      <div>
        {renderValue('Nama Berkas', 'originalFilename')}
        {renderValue('Format', 'originalFilename')}
        {renderValue('Status Unggah', 'status')}
        {renderValue('Tanggal Unggah', 'uploadDate')}
        <hr className="lineDetailWorldCheck" />
        {renderStatus()}
        <ul style={{ marginTop: 10 }}>
          {detailError.map((data: any) => {
            return <li style={{ marginBottom: 5 }}>{`Row ${data}`}</li>;
          })}
        </ul>
      </div>
    );
  };
  return (
    <CModalRFF
      modalIsShow={modalDetailWorldIsShow}
      headerComponent={headerComponent()}
      contentComponent={() => contentComponent()}
      footerComponent={() => {}}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={{}}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '600px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

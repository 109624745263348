import React from 'react';
import HasPermission from '../../../App/HasPermission';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { injectIntl } from 'react-intl';
import { IResultContestRewardDAO } from '../DAO/contestReward.dao';

interface IProps {
  intl: any;
  handleOnClick: () => void;
  handleRefresh: () => void;
  contestRewardList: IResultContestRewardDAO[];
  isLoading: boolean;
  columnData: any;
}

function ContestRewardComponent(props: IProps) {
  const {
    intl,
    handleOnClick,
    contestRewardList,
    isLoading,
    columnData,
    handleRefresh,
  } = props;
  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'PlusOutlined',
          onClick: handleOnClick,
          content: 'Add Contest Reward',
          id: 'btnAddContestReward',
        }
      : null,
  ];

  const cardContestRewardContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={contestRewardList}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Reward" rightText="Configuration" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'contestRewardCardTitle',
            })}
          </span>
        }
        cardContent={cardContestRewardContent()}
      />
    </>
  );
}

export default injectIntl(ContestRewardComponent);

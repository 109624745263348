import FieldComponent from './MainModal/FieldComponent';
import HandoutComponent from './MainModal/HandoutComponent';
import ParticipantContainer from '../Container/MainModal/ParticipantContainer';
import React, { useEffect } from 'react';
import ScheduleContainer from '../Container/MainModal/ScheduleContainer';
import { Skeleton } from 'antd';

interface IProps {
  channelCode: string;
  classDetail: any;
  contentList: any[];
  dataCertificate: any[];
  dataClassNonSelf?: any;
  disabled: boolean;
  form: any;
  formValues?: any;
  handleDeletecontent: (id) => void;
  indexLink: number;
  isLoading: boolean;
  isLoadingSchedule: boolean;
  listSchedule: any[];
  modalAction: 'update' | 'register';
  refetchClassDetail: () => void;
  refetchSchedule: () => void;
  selectedAgentManual: any[];
  setIndexLink: React.Dispatch<React.SetStateAction<number>>;
  setListSchedule: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedAgentManual: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ClassModalGeneralComponent(props: IProps) {
  const {
    channelCode,
    classDetail,
    contentList,
    dataCertificate,
    dataClassNonSelf,
    disabled,
    form,
    formValues,
    handleDeletecontent,
    indexLink,
    isLoading,
    isLoadingSchedule,
    listSchedule,
    modalAction,
    refetchClassDetail,
    refetchSchedule,
    selectedAgentManual,
    setIndexLink,
    setListSchedule,
    setSelectedAgentManual,
  } = props;

  useEffect(() => {
    if (
      formValues?.haveCertificate !== undefined &&
      formValues?.haveCertificate === false
    ) {
      form.change('codemiCetificateId', null);
    }
    //eslint-disable-next-line
  }, [formValues?.haveCertificate]);

  return (
    <Skeleton active loading={isLoading}>
      <FieldComponent
        disabled={disabled}
        dataCertificate={dataCertificate}
        formValues={formValues}
        modalAction={modalAction}
      />
      <h2 className="TitleStepsSubModul">Schedule</h2>
      <ScheduleContainer
        dataClassNonSelf={dataClassNonSelf}
        disabled={disabled}
        isLoading={isLoadingSchedule}
        listSchedule={listSchedule}
        modalAction={modalAction}
        refetchSchedule={refetchSchedule}
        setListSchedule={setListSchedule}
      />
      <h2 className="TitleStepsSubModul">Participant</h2>
      <ParticipantContainer
        channelCode={channelCode}
        classDetail={classDetail}
        dataClassNonSelf={dataClassNonSelf}
        disabled={disabled}
        form={form}
        formValues={formValues}
        isLoading={false}
        modalAction={modalAction}
        refetchClassDetail={refetchClassDetail}
        selectedAgentManual={selectedAgentManual}
        setSelectedAgentManual={setSelectedAgentManual}
      />
      <h2 className="TitleStepsSubModul">Handout</h2>
      <HandoutComponent
        disabled={disabled}
        indexLink={indexLink}
        setIndexLink={setIndexLink}
        formValues={formValues}
        contentList={contentList}
        form={form}
        handleDeletecontent={handleDeletecontent}
      />
    </Skeleton>
  );
}

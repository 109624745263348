import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import Select from '../../../Assets/Components/Select';
import { Button, Skeleton } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import validate from '../Validation/DisclaimerMappingValidation';
import { IResultDisclaimerContentListDAO, IResultDisclaimerModuleListDAO } from '../DAO/disclaimer.dao';

interface IProps {
  disabled: boolean;
  disclaimerContentList: IResultDisclaimerContentListDAO[];
  disclaimerModuleList: IResultDisclaimerModuleListDAO[];
  editMode: boolean;
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
  initialValues: any;
  intl: any;
  isLoading: boolean;
  isShow: boolean;
  modalAction: 'update' | 'register';
}

export default function ModalDisclaimerMappingComponent(props: IProps) {
  const {
    disabled,
    disclaimerContentList,
    disclaimerModuleList,
    editMode,
    handleCancel,
    handleSubmit,
    initialValues,
    intl,
    isLoading,
    isShow,
    modalAction,
  } = props;
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailDisclaimerMapping" />
        ) : (
          <FormattedMessage id="addDisclaimerMapping" />
        )}
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    return (
      <>
        <Skeleton active loading={isLoading}>
          <Field
            name="disclaimerId"
            component={Select}
            idComponent="inputDisclaimerId"
            dataOption={disclaimerContentList || []}
            defaultItemName="Select disclaimer"
            label={intl.formatMessage({ id: 'disclaimer' })}
            disabled={disabled}
            combineValue={['|', 'code', 'name']}
            disabledByKey="isActive"
            disabledByKeyNegation={true}
          />
          <Field
            name="disclaimerModuleId"
            component={Select}
            idComponent="inputDisclaimerModuleId"
            dataOption={disclaimerModuleList || []}
            defaultItemName="Select module"
            label={intl.formatMessage({ id: 'moduleName' })}
            disabled={disabled}
            combineValue={['|', 'code', 'name']}
          />
        </Skeleton>
      </>
    );
  };
  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };
  const footerComponent = (propsFooter: any) => {
    const { invalid } = propsFooter;
    return (
      <Button
        loading={isLoading}
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        icon={<CIcon type="CheckOutlined" />}
        disabled={modalAction === 'update' && !editMode ? false : invalid}
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(modalAction, editMode)}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="horizontal"
      initialValues={initialValues}
    />
  );
}

const CategoryBankValidation = ({ name }) => {
  const errors: any = {};
  if (!name) {
    errors.name = 'Category Name Is Required!';
  } else if (name && !/^[^\s].*/.test(name)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  }

  return errors;
};
export default CategoryBankValidation;

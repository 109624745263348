import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Switch } from 'antd';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import { contentManagementStageAPI } from '../../../../Services/ElearningContentManagement/stage.api';
import StageComponent from '../Component/StageComponent';
import StageModalContainer from './StageModalContainer';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  token?: any;
  selectedStatus?: string;
  selectedData?: any;
}

export default function StageContainer({
  channel,
  setTabData,
  setAllDataList,
}: IProps) {
  const [filter, setFilter] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedData, setSelectedData] = useState();
  const [modalIsShow, setModalIsShow] = useState(false);

  const {
    data: dataStage,
    refetch: refetchStage,
    isLoading: isLoadingStage,
    isRefetching: isRefetchingStage,
  } = useQuery(
    ['Get Stage Data', { channelId: channel?.id }],
    contentManagementStageAPI.getStage,
    {
      enabled: channel?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const mutate = useMutation(contentManagementStageAPI.mutateStage, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="updateStageStatusSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchStage();
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const mutationDeleteChannel = useMutation(
    contentManagementStageAPI.deleteStage,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage id="deleteStageElearningSuccess" />
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        refetchStage();
      },
    }
  );

  const handleOnClick = () => setModalIsShow(true);

  const processStatusChannel = (id: number, status: any, body: any) => {
    mutate.mutate({ ...body, isActive: status, id });
  };

  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const body = {
      name: oriObj.name,
      description: oriObj.description,
      sequence: oriObj.sequence,
      predecessor: oriObj.predecessor,
      isActive: status,
      channelId: oriObj.channelId,
    };
    const action = status === true ? 'meng-aktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusChannel(id, status, body),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Stage "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => mutationDeleteChannel.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Stage "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteStage`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditStage`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
      {
        id: 'btnNextStage',
        icon: 'ArrowRightOutlined',
        onClick: () => {
          setFilter(undefined);
          setSelectedStatus(undefined);
          setTabData(prev => ({ ...prev, stage: rows }));
          setAllDataList(prev => ({ ...prev, stageList: dataStage }));
        },
        className: 'btnNext',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { createdDate, totalModule, totalSubModule, totalContent } =
      row.original;
    const createDate = moment(createdDate).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Created Date',
          createDate === '01-Jan-0001' ? '-' : createDate
        )}
        {renderCol(
          'Total Module',
          totalModule === null || totalModule === '' ? '-' : totalModule
        )}

        {renderCol(
          'Total Sub-Module',
          totalSubModule === null || totalSubModule === ''
            ? '-'
            : totalSubModule
        )}
        {renderCol(
          'Total Content',
          totalContent === null || totalContent === '' ? '-' : totalContent
        )}
      </div>
    );
  }, []);

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },

    {
      Header: 'Stage Name',
      accessor: 'name',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Predecessor',
      accessor: 'predecessorDesc',
    },
    {
      Header: 'Sequence',
      accessor: 'sequence',
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const handleApplyfilter = () => {
    setFilter(selectedStatus);
  };

  const stage = dataStage
    ? filter === 'active'
      ? dataStage.filter(item => item.isActive)
      : filter === 'inactive'
      ? dataStage.filter(item => !item.isActive)
      : dataStage
    : [];
  stage
    .sort((a, b) => moment(b.createdDate).diff(moment(a.createdDate)))
    .sort((a, b) => a.sequence - b.sequence);

  return (
    <>
      <StageComponent
        columnData={columnData}
        dataStage={stage}
        isLoading={
          isLoadingStage ||
          isRefetchingStage ||
          mutationDeleteChannel.isLoading ||
          mutate.isLoading
        }
        handleAdd={handleOnClick}
        handleRefresh={() => {
          refetchStage();
          setSelectedStatus(undefined);
          setFilter(undefined);
        }}
        renderRowSubComponent={renderRowSubComponent}
        setSelectedStatus={setSelectedStatus}
        handleApplyfilter={handleApplyfilter}
        selectedStatus={selectedStatus}
      />
      {modalIsShow && (
        <StageModalContainer
          channel={channel}
          stage={dataStage}
          modalAction="register"
          modalIsShow={modalIsShow}
          setModalIsShow={setModalIsShow}
          refetchStage={refetchStage}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
        />
      )}
    </>
  );
}

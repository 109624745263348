import React from 'react';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { Alert } from 'antd';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';

interface IProps {
  modalIsShow: boolean;
  handleCancel: () => void;
}

export default function ModalNotifIsZelComponent(props: IProps) {
  const { modalIsShow, handleCancel } = props;
  const defaultModalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      maxWidth: '95vw',
      width: '400px',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '100px',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '99',
    },
  };
  const buttonData = [
    {
      type: 'primary',
      className: 'btnCloseNotificationModal',
      onClick: handleCancel,
      content: 'Tutup',
      id: 'btnCloseNotification',
    },
  ];

  const massageAlert = () => {
    return (
      <div
        style={{ textAlign: 'center', fontSize: '20px', paddingTop: '10px' }}
      >
        <CIcon
          type="InfoCircleFilled"
          style={{ fontSize: '1.5em', color: '#ffe17d' }}
        />
        <p style={{ marginTop: '5px' }}>
          Calon agen ini <br />
          <span style={{ fontWeight: 'bold' }}>Belum lulus training!</span>
        </p>
      </div>
    );
  };

  const contentComponent = () => {
    return (
      <>
        <Alert message={massageAlert()} type="warning" />
        <p style={{ textAlign: 'center' }}>
          Pengajuan aktivasi agen belum dapat <br /> dilakukan, silahkan coba
          lagi nanti
        </p>
        <div style={{ textAlign: 'center' }}>
          <CButton buttonData={buttonData} />
        </div>
      </>
    );
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      initialValues={[]}
      headerComponent={null}
      contentComponent={contentComponent()}
      footerComponent={null}
      modalIsShow={modalIsShow}
      handleCancel={handleCancel}
      modalstyles={defaultModalstyles}
    />
  );
}

import { Button, Form } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import HasPermission from '../../../App/HasPermission';
import CIcon from '../../../Assets/Components/CIcon';
import { InputText } from '../../../Assets/Components/CInput';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import InputFile from '../../../Assets/Components/InputFile';
import { injectIntl } from 'react-intl';
import contestRewardValidation from '../Validation/contestRewardValidation';
import { IResultContestRewardDetailDAO } from '../DAO/contestReward.dao';

interface IProps {
  disabled: boolean;
  editMode: boolean;
  handleCancel: () => void;
  handleSubmit: (vals: any) => void;
  initialValues: IResultContestRewardDetailDAO;
  intl: any;
  isLoading: boolean;
  isShow: boolean;
  modalAction: 'register' | 'update';
}

function ModalContestRewardComponent(props: IProps) {
  const {
    disabled,
    editMode,
    handleCancel,
    handleSubmit,
    initialValues,
    intl,
    isLoading,
    isShow,
    modalAction,
  } = props;

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    return (
      <>
        <Field
          name="name"
          component={InputText}
          idComponent="inputrewardName"
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeholderrewardName',
          })}
          label={intl.formatMessage({ id: 'rewardName' })}
          editMode={editMode}
          disabled={disabled}
        />
        {!disabled ? (
          <Field
            name="rewardPicture"
            component={InputFile}
            idComponent="inputrewardPicture"
            label={<b>Reward Picture Upload</b>}
            uppyConfig={{
              minFile: 1,
              maxFile: 1,
              allowedFile: ['image/png', 'image/jpg', 'image/jpeg'],
              maxSize: 5242880,
            }}
            useFile={true}
          />
        ) : null}
        {modalAction === 'update' && initialValues.documentType ? (
          <Form.Item
            label={
              <span>
                <b className="capital">
                  {intl.formatMessage({ id: 'rewardPictureImg' })}
                </b>
              </span>
            }
            colon={false}
          >
            <div
              className="imageContestReward"
              style={{
                backgroundImage: `url(data:${initialValues.documentType};base64,${initialValues.fileDocument})`,
              }}
            />
          </Form.Item>
        ) : null}
      </>
    );
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid, error } = propsFooter;
    const buttonContent = () => {
      if (editMode === false) {
        if (modalAction === 'register') {
          return 'Create';
        }
        return 'Edit';
      }
      return 'Save Change';
    };

    const handleDisableButton = () => {
      if (modalAction === 'register') {
        if (invalid || error) {
          return true;
        }
        return false;
      } else {
        if (editMode === false) {
          return false;
        } else {
          if (invalid || error) {
            return true;
          }
          return false;
        }
      }
    };

    if (
      (modalAction === 'register' && HasPermission('c')) ||
      (modalAction === 'update' && HasPermission('u'))
    ) {
      return (
        <Button
          className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
          icon={<CIcon type="CheckOutlined" />}
          disabled={handleDisableButton()}
          htmlType="submit"
          loading={isLoading}
          id="btnSubmit"
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="contestRewardForm" />
      </h3>
    );
  };
  return (
    <CModalRFF
      formLayout="horizontal"
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      isForm={true}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      handleValidation={vals => contestRewardValidation(vals, modalAction)}
    />
  );
}

export default injectIntl(ModalContestRewardComponent);

import React, { useEffect, useState } from 'react';
import RecruitmentComponent from '../Component/RecruitmentComponent';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  getLinkStatusAPI,
  getNewLinkDataAPI,
} from '../../../Services/recruitment.api';

export default function RecruitmentContainer() {
  let params: any = useParams();
  const base64regex =
    /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
  const isBase64 = base64regex.test(params?.encode);
  const decoded = isBase64
    ? atob(params?.encode)
    : encodeURIComponent(params?.encode);
  const agentData = decoded?.split('|');
  const [errorCode, setErrorCode] = useState('');
  const [currentStep, setCurrentStep] = useState(1);
  const [showSuccessPage, setShowSuccessPage] = useState(false);
  const [newLinkIdentifier, setNewLinkIdentifier] = useState('');

  const { data: linkStatus, isLoading: isLoadingLink } = useQuery(
    [
      'getLinkStatus',
      {
        agentCode: agentData?.[0],
        encodedData: newLinkIdentifier ? newLinkIdentifier : params?.encode,
      },
    ],
    getLinkStatusAPI,
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        const responseData = error?.response?.data;
        setErrorCode(responseData?.ErrorCode);
      },
    }
  );

  const { data: linkData } = useQuery(
    ['getNewLinkData', { agentCode: agentData?.[0] }],
    getNewLinkDataAPI,
    {
      refetchOnWindowFocus: false,
      enabled: errorCode === '8001',
      onError: (error: any) => {
        const responseData = error?.response?.data;
        setErrorCode(responseData?.ErrorCode);
      },
    }
  );

  useEffect(() => {
    if (linkData) {
      setNewLinkIdentifier(linkData?.linkIdentifier);
    }
  }, [linkData]);

  return (
    <RecruitmentComponent
      linkStatus={linkStatus}
      isLoadingLink={isLoadingLink}
      currentStep={currentStep}
      setCurrentStep={setCurrentStep}
      showSuccessPage={showSuccessPage}
      setShowSuccessPage={setShowSuccessPage}
      agentData={agentData}
    />
  );
}

export const localeUppy: any = {};

export default {
  unknownError: 'Unknown error. Please contact admin!',
  loginFailedTypeAdmin:
    'Failed login! Your account not permited to access this application',
  badCredential: 'Wrong username or password',
  loginSuccess: 'Login success!',
  sessionExpired: 'Session expired! Please re-login!',
  contestRewardCardTitle: 'Reward contest',
  trackerCardTitle: 'Tracker Report',
  contestRewardForm: 'Contest reward',
  placeholderrewardName: 'Reward name',
  rewardName: 'Reward name',
  rewardPicture: 'Reward picture upload',
  rewardPictureImg: 'Picture',
  createContestRewardSuccess: 'Success add new contest reward!',
  updateContestRewardSuccess: 'Success update contest reward!',
  updateContestTargetSuccess: 'Success update contest!',
  createFileSuccess: 'Success add new file!',
  updateMappingAuthUserRoleSuccess: 'Success update mapping user roles!',
  contestRewardCardListTitle: 'Contest reward list',
  contestTargetCardTitle: 'Contest target',
  contestTargetForm: 'Contest target',
  contestType: 'Contest type',
  contestGroup: 'Contest group',
  contestLevel: 'Contest level',
  contestChanel: 'Contest chanel',
  dateContest: 'Date contest',
  cardTitleContestDetail: 'Contest detail',
  numberOfTargetContest: 'Number of target',
  cardTitleContestTarget: 'Target contest',
  placeholderTargetName: 'Target name',
  targetName: 'Target name',
  contestReward: 'Contest reward',
  contestTargetFyp: 'Target Fyp',
  contestTargetNbFyp: 'Target Nbfyp',
  contestTargetPersistency: 'Target persistency',
  contestNumberOfTicket: 'Number of ticket',
  cardTitleRewardTarget: 'Target',
  timeStart: 'Time start',
  timeEnd: 'Time End',
  createContestTargetSuccess: 'Success create new contest!',
  trackerType: 'Tracker type',
  dateFilter: 'Date filter',
  formatTrackerType: 'Download report format',
  monitoringPeriodContest: 'Monitoring period (month)',
  disclaimerCardTitle: 'Content list',
  disclaimerForm: 'Disclaimer content form',
  addDisclaimerContent: 'New Disclaimer',
  detailDisclaimerContent: 'Disclaimer Detail',
  disclaimerName: 'Disclaimer name',
  disclaimerContent: 'Disclaimer content',
  placeholderDisclaimerName: 'Disclaimer name',
  disclaimerMappingCardTitle: 'Mapping list',
  moduleName: 'Module',
  disclaimer: 'Disclaimer',
  disclaimerMappingForm: 'Disclaimer mapping form',
  addDisclaimerMapping: 'New Mapping',
  detailDisclaimerMapping: 'Mapping Detail',
  createDisclaimerSuccess: 'Create disclaimer success!',
  createDisclaimerMappingSuccess: 'Create disclaimer mapping success!',
  updateDisclaimerMappingSuccess: 'Update disclaimer mapping success!',
  'disclaimer.module.already.exist':
    'Cannot configure disclaimer mapping. Module already used!',
  moduleType: 'Module Type',
  type: 'Type',
  agreementCardTitle: 'Daftar Agreement',
  agreementForm: 'Agreement Form',
  addAgreementContent: 'New Agreement',
  detailAgreementContent: 'Agreement Detail',
  agreementName: 'Agreement Name',
  agreementContent: 'Agreement content',
  placeholderAgreementName: 'Agreement name',
  agreementMappingCardTitle: 'Daftar Mapping',
  agreement: 'Agreement Type',
  agreementMappingForm: 'Agreement Mapping Form',
  addAgreementMapping: 'New Mapping',
  detailAgreementMapping: 'Mapping Detail',
  createAgreementSuccess: 'Agreement added successfully!',
  createAgreementMappingSuccess: 'Mapping added successfully!',
  updateAgreementMappingSuccess: 'Update agreement mapping success!',
  'agreement.module.already.exist':
    'Cannot configure agreement mapping. Module already used!',
  configurationCardTitle: 'Configuration list',
  labelParameterConfiguration: 'Parameter',
  labelParameterValueConfiguration: 'Parameter value',
  parameterType: 'Paramater type',
  configurationForm: 'Configuration form',
  updateConfigurationSuccess: 'Update configuration success!',
  dashboardMostVisitedTitle: 'Most Visited Menu',
  'contest.schedule.update': 'Contest cannot updated!',
  contestRewardIsLocked: 'Reward is locked',
  updateDisclaimerStatusSuccess: 'Update status disclaimer success!',
  updateAgreementStatusSuccess: 'Update status agreement success!',
  dashboardDosdOnProgressTitle: 'On Progress',
  dashboardDosdToDoListTitle: 'To Do List',
  dashboardCompletedTitle: 'Completed',
  dashboardLoginTrackerTitle: 'Login tracker',
  dashboardUniqueLoginTitle: 'Unique login',
  'contest.date.intersect':
    'Cannot create contest! Date already have another contest',
  contestUploadModalResult: 'History Detail',
  deleteContestRewardSuccess: 'Delete contest reward success!',
  'contest.reward.drop': 'Contest reward used!',
  createElearningModuleSuccess: 'Module added successfully!',
  assignParticipantModuleSuccess: 'Assign Participant successfully!',
  updateElearningModuleSuccess: 'Success update module!',
  updateElearningModuleMigrationSuccess: 'Migrate module successfully!',
  updateElearningModuleStatusSuccess: 'Module updated successfully!',
  deleteElearningModuleSuccess: 'Module deleted successfully!',
  addElearningModuleContent: 'Module Baru',
  detailElearningModuleContent: 'Edit Module',
  ElearningModuleName: 'Module Name',
  placeHolderModuleName: 'Type here',
  ElearningModulePredecessor: 'Predecessor',
  ElearningModuleSequence: 'Sequence',
  placeHolderModuleSequence: '0',
  ElearningModuleStatus: 'Status',
  ElearningModuleCertificate: 'Have Certificate',
  deleteElearningSubModuleSuccess: 'Success delete sub module!',
  updateElearningSubModuleStatusSuccess: 'Success update sub module status!',
  addElearningContentSlide: 'Slide Baru',
  detailElearningContentSlide: 'Edit Slide',
  ElearningSlideName: 'Slide Content Name',
  placeHolderSlideName: 'Type here',
  ElearningSlideSequence: 'Sequence',
  placeHolderSlideSequence: '0',
  ElearningSlideStatus: 'Status',
  placeholderSlideEmbedLink: 'Type or paste link here',
  createElearningContentSlideSuccess: 'Content Slide added successfully!',
  updateElearningContentSlideSuccess: 'Success Update Content Slide!',
  addQuestionBank: 'Question Bank Baru',
  detailQuestionBank: 'Edit Question Bank',
  createQuestionBankElearningSuccess: 'Question added successfully!',
  feedbackBankCardTitle: 'Feedback List',
  deleteFeedbackBankSuccess: 'Feedback deleted successfully!',
  addSelfLearning: 'Feedback Bank (Self Learning) Baru',
  detailSelfLearning: 'Edit Feedback Bank (Self Learning)',
  addNonSelfLearning: 'Feedback Bank (Non Self Learning) Baru',
  detailNonSelfLearning: 'Edit Feedback Bank (Non Self Learning)',
  submitFeedbackBankSuccess: 'Feedback added successfully!',
  updateFeedbackBankSuccess: 'Feedback updated successfully!',
  learningReportCardTitle: 'Report Data',

  roleForm: 'Role form',
  roleCardTitle: 'Role List',
  labelRoleName: 'Role name',
  labelUserList: 'User list',
  forbiddenError: 'You do not have permission!',
  createPermissionSuccess: 'Success create role and mapping!',
  updatePermissionSuccess: 'Success update role and mapping!',
  userRoleCardTitle: 'User List',
  placeholderUserRoleName: 'Username here',
  userRoleForm: 'User Role',
  updateAgreementSuccess: 'Success update agreement content!',
  updateDisclaimerSuccess: 'Success update disclaimer content!',
  'contest.date.validation': 'Start date cannot greater than end date',
  role: 'Role',
  createMappingAuthUserRoleSuccess: 'Success create mapping user roles!',
  cityCardTitle: 'City List',
  newUpload: 'New Upload',
  uploadFile: 'Upload File',
  libraryCardTitle: 'File List',
  libraryForm: 'New Upload',
  inputFileUpload: 'File upload',
  effectiveDate: 'Effective date',
  publish: 'Publish',
  placeHolderEmbededLinkTitle: 'Input embeded link title here',
  embededLinkTitle: 'Embeded link title',
  placeHolderEmbededLink: 'Input embeded link here',
  embededLink: 'Embeded link',
  cityUploadModalResult: 'File Detail',
  folderLibraryForm: 'Folder Form',
  placeHolderFolderName: 'Type new folder here',
  folderName: 'Folder name',
  createFolderSuccess: 'Success add new folder!',
  updateFileLibraryForm: 'File Detail',
  startDate: 'Start date',
  endDate: 'End date',
  updateFolderSuccess: 'Success update folder!',
  collectionLibraryForm: 'Move',
  moveSuccess: 'Move success!',
  updateFileSuccess: 'Success update file',
  successDeleteFile: 'Success delete file',
  successDeleteFolder: 'Success delete folder',
  notificationCardTitle: 'Notification List',
  errorUpload: 'Oh no, something bad happened!',
  reportCardTitle: 'Report List',
  reportType: 'Report Type',
  invitationCardTitle: 'Invitation Template',
  templateName: 'Template name',
  formatReportType: 'Download Report Format',
  notificationType: 'Notification Type',
  placeholderTemplateName: 'Input here',
  emailContent: 'Email Content',
  invitationForm: 'Invitation Template',
  templateSms: 'SMS Content',
  templateEmailSubject: 'Email Subject',
  placeholderTemplateEmailSubject: 'Email subject here',
  emailBody: 'Email Body',
  generateBarcode: 'Generate barcode',
  createInvitationSuccess: 'Success add new invitation!',
  updateInvitationSuccess: 'Success update invitation',
  deleteInvitationSuccess: 'Success delete invitation',
  effectiveDateInd: 'Tanggal Efektif',
  expiredDate: 'Tanggal Kadaluarsa',
  registrationDate: 'Tanggal Pendaftaran',
  screenshotAaji1: 'Unggah Berkas 1',
  screenshotAaji2: 'Unggah Berkas 2',
  screenshotAaji3: 'Unggah Berkas 3',
  personaldata: 'Data Pribadi',
  beneficiary: 'Data Ahli Waris',
  workexperience: 'Pengalaman Kerja',
  documents: 'Dokumen Pendukung',
  useragreements: 'Pernyataan',
  agency: 'Agensi',
  profile: 'Profil',
  addresseducation: 'Alamat & Pendidikan',
  recruiter: 'Perekrut',
  directLeader: 'Direct Leader',
  salesOffice: 'Kantor Sales',
  agencyLevel: 'Level Agen',
  candidateLevel: 'Kandidat Level',
  idcard: 'Upload KTP',
  fullname: 'Nama Calon Agen (Sesuai KTP)',
  nik: 'NIK (Sesuai KTP)',
  dob: 'Tanggal lahir',
  pob: 'Tempat lahir',
  email: 'Email',
  mobilephone: 'Telepon HP',
  gender: 'Jenis Kelamin',
  maritalstatus: 'Status Pernikahan',
  religion: 'Agama',
  homephone: 'Telepon Rumah',
  workphone: 'Telepon Kantor',
  domicileaddress: 'Alamat Tempat Tinggal Domisili',
  idcardaddress: 'Alamat Tempat Tinggal (Sesuai KTP)',
  province: 'Provinsi',
  city: 'Kota/Kabupaten',
  district: 'Kecamatan',
  subdistrict: 'Desa/Kelurahan',
  postalcode: 'Kode Pos',
  provincedomicile: 'Provinsi',
  citydomicile: 'Kota/Kabupaten',
  districtdomicile: 'Kecamatan',
  subdistrictdomicile: 'Desa/Kelurahan',
  postalcodedomicile: 'Kode Pos',
  privyidstatus: 'Privy ID Status',
  formaleducation: 'Pendidikan Formal Terakhir',
  taxbankaccount: 'Bank & Pajak',
  bankname: 'Bank',
  bankaccountnumber: 'Nomor Rekening',
  bankbranch: 'Cabang',
  bankaccountholder: 'Nama Pemilik Rekening',
  hastax: 'Memiliki NPWP',
  taxtaxnumber: 'Nomor NPWP',
  taxtaxpayer: 'Nama Wajib Pajak',
  taxtaxownership: 'Kepemilikan NPWP',
  taxtaxdependents: 'Jumlah Tanggungan',
  bankandtaxdisclaimer: ' ',
  questionnaire: 'Kuesioner',
  question1: 'Pertanyaan 1',
  question2: 'Pertanyaan 2',
  question3: 'Pertanyaan 3',
  formapplication: 'Aplikasi Keagenan',
  formappointmentletter: 'Surat Penunjukan Keagenan',
  formtncagreement: 'Syarat dan Ketentuan Perjanjian Keagenan',
  formcandidatestatement: 'Surat Pernyataan Calon Agen',
  formagencyagreement: 'Perjanjian Keagenan',
  formtncagreementleader: 'Syarat dan Ketentuan Perjanjian Agency Leader',
  signature: 'Tanda Tangan',
  documentsphoto: 'Pas Foto (Berwarna, 3x4 )',
  documentsbankpassbook: 'Buku Tabungan',
  documentstaxpassbook: 'NPWP',
  documentsfamilycard: 'Kartu Keluarga',
  documentslocalcoverletter: 'Surat Kelurahan',
  documentsreferenceletter: 'Surat Referensi',
  documentsotherattachments0: 'Lampiran Tambahan 1',
  documentsotherattachments1: 'Lampiran Tambahan 2',
  documentsotherattachments2: 'Lampiran Tambahan 3',
  documentsotherattachments3: 'Lampiran Tambahan 4',
  documentsotherattachments4: 'Lampiran Tambahan 5',
  photo: 'Pas Foto (Berwarna, 3x4 )',
  bankpassbook: 'Buku Tabungan',
  taxpassbook: 'NPWP',
  familycard: 'Kartu Keluarga',
  localcoverletter: 'Surat Kelurahan',
  referenceletter: 'Surat Referensi',
  otherattachments0: 'Lampiran Tambahan 1',
  otherattachments1: 'Lampiran Tambahan 2',
  otherattachments2: 'Lampiran Tambahan 3',
  otherattachments3: 'Lampiran Tambahan 4',
  otherattachments4: 'Lampiran Tambahan 5',
  uploadPaymentSlip: 'Unggah Berkas',
  'link.not.valid': 'Link not valid format',
  'contest.schedule.drop': 'Cannot delete completed/current contest!',
  'folder.already.exist': 'Folder already exist',
  'name.not.valid': 'Name not valid format',
  'document.already.exist': 'File upload already exists!',
  'file.cannot.deleted':
    'Document cannot be deleted, because it`s being published',
  'contest.reward.update': 'Contest reward. Already used',
  'folder.cannot.deleted':
    'Cannot delete folder! Folder contain folder or file!',
  uploadAgreement: 'Unggah Agreement',
  createChannelElearningSuccess: 'Channel added successfully!',
  addChannelElearning: 'Channel Baru',
  detailChannelElearning: 'Edit Channel',
  codeChannel: 'Channel Code',
  placeholderCodeChannel: 'Type here',
  nameChannel: 'Channel Code',
  nameChannelSelf: 'Channel Name',
  placeholderNameChannel: 'Cth. Agency',
  headOfTrainerChannel: 'Head Of Trainer',
  signOfCaptionChannel: 'Sign Of Caption',
  placeholderSignOffCaption: 'Type here',
  statusChannel: 'Status',
  updateChannelStatusSuccess: 'Channel Status uptated successfully!',
  updateChannelSuccess: 'Channel updated successfully!',
  deleteChannelElearningSuccess: 'Channel deleted successfully!',
  detailStageElearning: 'Edit Stage',
  addStageElearning: 'Stage Baru',
  placeholderNameStage: 'Type here',
  nameStage: 'Stage Name',
  placeholderDescStage: 'Type here',
  descStage: 'Description',
  predecessorStage: 'Predecessor',
  placeholderSequence: '0',
  sequenceStage: 'Sequence',
  statusStage: 'Status',
  createStageElearningSuccess: 'Stage added successfully!',
  updateStageSuccess: 'Stage updated successfully!',
  deleteStageElearningSuccess: 'Stage deleted successfully!',
  updateStageStatusSuccess: 'Stage Status updated successfully!',
  placeholderHeadOfTrainer: 'Type here',
  addContentQuestion: 'Question Baru',
  detailContentQuestion: 'Edit Question',
  firstAnswer: 'Jawaban 1',
  createQuestionElearningSuccess: 'Question added successfully!',
  updateQuestionElearningSuccess: 'Question updated successfully!',
  deleteQuestionBankSuccess: 'Question Bank deleted successfully!',
  addClassScheduleNonSelf: 'Add Schedule',
  detailClassScheduleNonSelf: 'Edit Schedule',
  addCategoryBank: 'Add New Category',
  editCategoryBank: 'Edit Category',
  name: 'Name',
  errorDeleteCategoryBank: 'Category name already used on question bank data!',
  channelDuplicated: 'Channel code is already used!',
  failedDeleteLibrary:
    'Document cannot be deleted, because it`s being published',
};

localeUppy.strings = {
  addMoreFiles: 'Add more file',
  addingMoreFiles: 'Add more file',
  allowAccessDescription:
    'To take pictures or record videos with your camera, please allow camera access for this site.',
  allowAccessTitle: 'Please allow access to your camera',
  authenticateWith: 'Conencting to %{pluginName}',
  authenticateWithTitle:
    'Please authentication with %{pluginName} to select file',
  back: 'Back',
  browse: 'search',
  cancel: 'Cancel',
  cancelUpload: 'Cancel upload',
  chooseFiles: 'Select file',
  closeModal: 'Close popup',
  companionAuthError: 'Need authorization',
  companionError: 'Failed connect to Companion',
  complete: 'Complete',
  connectedToInternet: 'Connecting to internet',
  copyLink: 'Copy link',
  copyLinkToClipboardFallback: 'Copy URL below',
  copyLinkToClipboardSuccess: 'Link copied to clipboard',
  creatingAssembly: 'Ready for upload...',
  creatingAssemblyFailed: 'Transloadit: Cannot create Assembly',
  dashboardTitle: 'Uploaded file',
  dashboardWindowTitle: 'File upload section (Press escape to close)',
  dataUploadedOfTotal: '%{complete} of %{total}',
  done: 'Done',
  dropHereOr: 'Drop file here or %{browse}',
  dropHint: 'Drop file here',
  dropPaste: 'Drop file here to upload, or %{browse} file from computer',
  dropPasteImport: 'Drop file here, salin, %{browse} or import from',
  edit: 'Ubah',
  editFile: 'Edit file',
  editing: 'Edit %{file}',
  emptyFolderAdded: 'No file added from empty folder',
  encoding: 'Encoding file...',
  enterCorrectUrl:
    'Incorrect URL: Make sure you enter a direct link to the file',
  enterUrlToImport: 'Enter the URL to import the file',
  exceedsSize: 'This file exceeds the maximum allowable size of %{size}',
  failedToFetch: 'Companion failed fetch from this url, please recheck',
  failedToUpload: 'Failed upload %{file}',
  fileSource: 'Source file: %{name}',
  filesUploadedOfTotal: {
    '0': '%{complete} from %{smart_count} file uploaded',
    '1': '%{complete} from %{smart_count} file uploaded',
    '2': '%{complete} from %{smart_count} file uploaded',
  },
  filter: 'Filter',
  finishEditingFile: 'Finish editing file',
  folderAdded: {
    '0': 'Success add %{smart_count} file from %{folder}',
    '1': 'Success add %{smart_count} file from %{folder}',
    '2': 'Success add %{smart_count} file from %{folder}',
  },
  import: 'Import',
  importFrom: 'Import from %{name}',
  link: 'Link',
  loading: 'Loading...',
  logOut: 'Log out',
  myDevice: 'My device',
  noFilesFound: 'No file found',
  noInternetConnection: 'No internet connection',
  pause: 'Pause',
  pauseUpload: 'Pause upload',
  paused: 'Paused',
  poweredBy: 'Powered by',
  preparingUpload: 'Preparing upload...',
  processingXFiles: {
    '0': 'Processing %{smart_count} file',
    '1': 'Processing %{smart_count} file',
    '2': 'Processing %{smart_count} file',
  },
  removeFile: 'Remove file',
  resetFilter: 'Reset filter',
  resume: 'Resume',
  resumeUpload: 'Continue upload',
  retry: 'Retry',
  retryUpload: 'Retry upload',
  saveChanges: 'Save change',
  selectXFiles: {
    '0': 'Select %{smart_count} file',
    '1': 'Select %{smart_count} file',
    '2': 'Select %{smart_count} file',
  },
  smile: 'Smile!',
  startRecording: 'Start record video',
  stopRecording: 'Stop record video',
  takePicture: 'Pick picture',
  timedOut: 'Upload stop for %{seconds} second, cancel.',
  upload: 'Upload',
  uploadComplete: 'Upload success',
  uploadFailed: 'Failed upload',
  uploadPaused: 'Upload paused',
  uploadXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  uploadXNewFiles: {
    '0': 'Upload +%{smart_count} file',
    '1': 'Upload +%{smart_count} file',
    '2': 'Upload +%{smart_count} file',
  },
  uploading: 'Upload',
  uploadingXFiles: {
    '0': 'Upload %{smart_count} file',
    '1': 'Upload %{smart_count} file',
    '2': 'Upload %{smart_count} file',
  },
  xFilesSelected: {
    '0': '%{smart_count} file selected',
    '1': '%{smart_count} file selected',
    '2': '%{smart_count} file selected',
  },
  xMoreFilesAdded: {
    '0': '%{smart_count} file added',
    '1': '%{smart_count} file added',
    '2': '%{smart_count} file added',
  },
  xTimeLeft: '%{time} left',
  youCanOnlyUploadFileTypes: 'Only can upload: %{types}',
  youCanOnlyUploadX: {
    '0': 'Only can upload %{smart_count} file',
    '1': 'Only can upload %{smart_count} file',
    '2': 'Only can upload %{smart_count} file',
  },
  youHaveToAtLeastSelectX: {
    '0': 'Please select file at least %{smart_count} file',
    '1': 'Please select file at least %{smart_count} file',
    '2': 'Please select file at least %{smart_count} file',
  },
  pluginNameCamera: 'Camera',
  noCameraTitle: 'Camera Not Available',
};

import React from 'react';
import Dashboard from '@uppy/react/lib/Dashboard';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { localeUppy } from '../../../../Modules/Internationalization/translations/en';
import Store from '../../../../Store';
import CModalFile from '../../../../Assets/Components/CModalFile';
import history from '../../../../App/History';
import { toast } from 'react-toastify';

interface IProps {
  handleCancel: () => void;
  showModal: boolean;
}

export default function ModalUploadLicenseComponent(props: IProps) {
  const { showModal, handleCancel } = props;

  const authState: any = Store.getState();
  const uppyLicenseReport = new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.xlsx'],
    },
    locale: localeUppy,
  }).use(XHRUpload, {
    id: `uppyFileGalleryXHR`,
    headers: {
      Authorization: `bearer ${authState.token}`,
    },
    endpoint: `${process.env.REACT_APP_API_URL}/Candidate/file/upload/license`,
    method: 'post',
    formData: true,
    fieldName: 'file',
    allowedMetaFields: ['originalFilename'],
    disableThumbnailGenerator: true,
    showRemoveButtonAfterComplete: true,
    inline: true,
    timeout: 300 * 1000,
  });
  uppyLicenseReport.on('file-added', file => {
    uppyLicenseReport.setFileMeta(file.id, {
      originalFilename: file.name,
    });
  });
  uppyLicenseReport.on('upload-success', () => {
    toast.success('Lisensi Berhasil Diunggah');
    handleCancel();
  });
  uppyLicenseReport.on('upload-error', (file, error, response) => {
    if (response && response.status === 401) {
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      authState.logOut();
    }
  });

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Unggah Lisensi</span>
      </h3>
    );
  };

  const contentComponent = () => {
    return (
      <>
        <p className="titleUploadFileLicenseModal">Unggah Lisensi</p>
        <Dashboard
          id="uppyUploadLicense"
          uppy={uppyLicenseReport}
          width={'100%'}
          disableThumbnailGenerator={true}
          showRemoveButtonAfterComplete={true}
          inline={true}
          proudlyDisplayPoweredByUppy={true}
          height={300}
        />
      </>
    );
  };

  return (
    <CModalFile
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      isForm={true}
      formLayout="horizontal"
    />
  );
}

import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { IResultCityListDAO } from '../DAO/city.dao';

interface IProps {
  isLoadingList: boolean;
  cityList: IResultCityListDAO[];
  handleRefresh: () => void;
  columnData: any;
}
export default function CityListTabComponent(props: IProps) {
  const { isLoadingList, cityList, handleRefresh, columnData } = props;

  return (
    <div className="containerTabWhiteZurich">
      <CButton
        buttonFloat={'right'}
        isLoading={isLoadingList}
        handleRefresh={handleRefresh}
      />
      <div style={{ textAlign: 'center' }}>
        <Table
          isLoading={isLoadingList}
          columns={generateColumnData(columnData)}
          data={cityList}
          useFilterGlobal={true}
          pagination={true}
        />
      </div>
    </div>
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import ModalPermissionComponent from '../Component/ModalPermissionComponent';
import HasPermission from '../../../App/HasPermission';
import {
  getModuleDataAPI,
  getUserByRoleIdAPI,
  mutateRoleAndPermissionAPI,
} from '../../../Services/Permission/permission.api';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  handleRefresh: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<any>;
  selectedData?: any;
}

function getDataPermissionList(rolePermissions: any) {
  const permissionSetting = {};
  for (const key in rolePermissions) {
    if (Object.prototype.hasOwnProperty.call(rolePermissions, key)) {
      const element = rolePermissions[key].permission;
      const moduleName = rolePermissions[key].moduleCode;
      const moduleNameReplaced = moduleName.replace('.', '/');
      permissionSetting[moduleNameReplaced] = {};
      for (const x in element) {
        if (Object.prototype.hasOwnProperty.call(element, x)) {
          const y = element[x];
          permissionSetting[moduleNameReplaced][x] = y === 1 ? true : false;
        }
      }
    }
  }
  return permissionSetting;
}

function checkSecurityPermissionCanCreate() {
  const strPermission = '/sec/per';
  if (HasPermission('r', strPermission) && HasPermission('c', strPermission)) {
    return true;
  }
  return false;
}

function checkSecurityPermissionCanUpdate() {
  const strPermission = '/sec/per';
  if (HasPermission('r', strPermission) && HasPermission('u', strPermission)) {
    return true;
  }
  return false;
}

export default function ModalPermissionContainer(props: IProps) {
  const {
    handleRefresh,
    showModal,
    setShowModal,
    setSelectedData,
    selectedData,
  } = props;

  let initialValues = {};
  const [editMode, setEditMode] = useState(false);
  const canRenderPermissionCreate = checkSecurityPermissionCanCreate();
  const canRenderPermissionUpdate = checkSecurityPermissionCanUpdate();

  const { data: dataModule, isLoading: isLoadingModule } = useQuery(
    ['getMenuModuleAPI'],
    getModuleDataAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataUser, isLoading: isLoadingUser } = useQuery(
    ['getUserByRoleIdAPI', selectedData?.id],
    getUserByRoleIdAPI,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  if (dataUser) {
    const permissionSetting = getDataPermissionList(dataUser.rolePermissions);
    initialValues = {
      roleName: selectedData?.name,
      permissionSetting,
    };
  }

  const mutateRole = useMutation(mutateRoleAndPermissionAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          {editMode ? (
            <FormattedMessage id="updatePermissionSuccess" />
          ) : (
            <FormattedMessage id="createPermissionSuccess" />
          )}
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleClose();
    },
  });

  const handleClose = () => {
    setShowModal(false);
    setEditMode(false);
    setSelectedData(undefined);
    handleRefresh();
  };

  const handleSubmit = (values: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else mutateRole.mutate({ ...values, roleId: selectedData?.id });
  };

  return (
    <ModalPermissionComponent
      authModuleList={dataModule || []}
      canRenderPermissionCreate={canRenderPermissionCreate}
      canRenderPermissionUpdate={canRenderPermissionUpdate}
      editMode={editMode}
      handleCancel={handleClose}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={editMode ? isLoadingModule || isLoadingUser : isLoadingModule}
      isLoadingSubmit={mutateRole.isLoading}
      modalAction={selectedData ? 'update' : 'register'}
      showModal={showModal}
    />
  );
}

import { Form, Select } from 'antd';

import React from 'react';

interface ISearchBox {
  input: any;
  meta: { touched; error };
  placeholder: string;
  idComponent: string;
  label: string;
  disabled: boolean;
  handleSearch: (values) => void;
  customKeyValue: string;
  customKeyLabel: string;
  forceHelpLabel: string;
  classnameForm: string;
  dataItem: any[];
  optionDisable?: boolean;
  maxItem?: number;
}

export default function SearchBox({
  input,
  meta: { touched, error },
  placeholder,
  idComponent,
  label,
  disabled,
  handleSearch,
  customKeyValue,
  customKeyLabel,
  forceHelpLabel,
  classnameForm,
  dataItem,
  optionDisable,
  maxItem,
}: ISearchBox) {
  const handleOnChange = e => {
    return input.onChange(e);
  };
  const labelTitle = label ? (
    <span>
      <b className={`capital`}>{label}</b>
    </span>
  ) : null;
  const itemSelect = dataItem || [];

  const options = itemSelect.map((item, key) => {
    return (
      <Select.Option
        id={`optionValue${label}-${key}`}
        key={customKeyValue ? item[customKeyValue] : item.id}
        value={customKeyValue ? item[customKeyValue] : item.id}
        disabled={optionDisable}
      >
        {customKeyLabel ? item[customKeyLabel] : item.name}
      </Select.Option>
    );
  });

  return (
    <React.Fragment>
      <Form.Item
        className={`${classnameForm}`}
        validateStatus={touched && error !== undefined ? 'error' : ''}
        help={touched && error !== undefined ? error : ''}
        label={labelTitle}
        colon={false}
      >
        <Select
          id={idComponent}
          showSearch
          value={
            typeof input.value === 'object'
              ? maxItem
                ? input.value.slice(0, maxItem)
                : input.value
              : []
          }
          placeholder={placeholder}
          defaultActiveFirstOption={false}
          showArrow={false}
          filterOption={false}
          onSearch={handleSearch}
          onChange={handleOnChange}
          notFoundContent={null}
          mode="multiple"
          disabled={disabled}
          onBlur={input.onBlur}
        >
          {options}
        </Select>
      </Form.Item>
      <div className="forceHelpLabel">{forceHelpLabel}</div>
    </React.Fragment>
  );
}

import { Col, Row } from 'antd';

import CButton from '../../../../Assets/Components/CButton';
import React from 'react';
import StageModalContainer from '../Container/StageModalContainer';
import Table from '../../../../Assets/Components/CTable';
import { filterStatus } from '../../../../App/Enums';
import Select from '../../../../Assets/Components/Select';
import { IResultStageNonSelfTrainingDAO } from '../DAO/stageNonSelfTraining.dao';

interface IProps {
  channel: any;
  columnData: any;
  dataStage: IResultStageNonSelfTrainingDAO[];
  handleAdd: () => void;
  handleApplyfilter: () => void;
  handleRefresh: () => void;
  isLoading: boolean;
  modalIsShow: boolean;
  refetchStage: () => void;
  renderRowSubComponent: any;
  selectedData: any;
  selectedStatus?: string;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function StageComponent(props: IProps) {
  const {
    dataStage,
    columnData,
    modalIsShow,
    setModalIsShow,
    handleAdd,
    handleRefresh,
    isLoading,
    renderRowSubComponent,
    selectedStatus,
    channel,
    refetchStage,
    handleApplyfilter,
    setSelectedStatus,
    selectedData,
    setSelectedData,
  } = props;

  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchCategoryBank',
      onClick: handleApplyfilter,
    },
  ];

  const buttonData = [
    {
      type: 'primary',
      content: 'Tambah Baru',
      id: 'btnModalAddNewStage',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      onClick: handleAdd,
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshStageList',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];

  return (
    <div className="containerWhiteZurich">
      <Row gutter={5}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus || []}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            idComponent="inputStageStatus"
            meta={{ touched: false, error: false }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={4} className={'btnFilterAplicationList'}>
          <CButton
            isLoading={isLoading}
            buttonData={buttonDataSearch}
            buttonFloat={'left'}
          />
        </Col>
        <Col span={16} className={'btnFilterAplicationList'}>
          <CButton
            buttonData={buttonData}
            buttonFloat={'right'}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        columns={columnData}
        pagination={true}
        data={dataStage}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
      />
      <StageModalContainer
        channel={channel}
        stageElearningModalAction="register"
        modalIsShow={modalIsShow}
        setModalIsShow={setModalIsShow}
        refetchStage={refetchStage}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
      />
    </div>
  );
}

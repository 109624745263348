import React from 'react';
import LoginComponent from '../Component/LoginComponent';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import history from '../../../App/History';
import Store from '../../../Store';
import { mutateLoginAPI } from '../../../Services/login.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function LoginContainer() {
  const store: any = Store();
  let toastMessage: any;

  const { mutate, isLoading } = useMutation(mutateLoginAPI, {
    onSuccess: (data: any) => {
      store.setToken(data);
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/`);
      toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="loginSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const handleSubmit = (values: any) => {
    mutate({
      ...values,
    });
  };

  return <LoginComponent handleSubmit={handleSubmit} isLoading={isLoading} />;
}

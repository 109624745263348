import React from 'react';
import { FormattedMessage } from 'react-intl';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Alert, Button, Col, Divider, Row } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import { Field } from 'react-final-form';
import InputFile from '../../../Assets/Components/InputFile';
import { InputSwitch, InputText } from '../../../Assets/Components/CInput';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import CCard from '../../../Assets/Components/CCard';
import validate from '../Validation/LibraryFileValidation';
import convertSize from 'convert-size';
import { IntlShape } from 'react-intl';

interface IProps {
  intl: IntlShape;
  totalUpload: number;
  isLoadingFile: boolean;
  initialValues: any;
  selectedData: any;
  editMode: boolean;
  modalUploadIsShow: boolean;
  handleMove: () => void;
  onSubmit: (values: any) => void;
  handleAddFile: () => void;
  handleCancel: () => void;
  setTotalUpload: React.Dispatch<React.SetStateAction<number>>;
}

export default function ModalLibraryFileComponent(props: IProps) {
  const {
    modalUploadIsShow,
    handleCancel,
    intl,
    totalUpload,
    handleAddFile,
    onSubmit,
    isLoadingFile,
    initialValues,
    selectedData,
    editMode,
    setTotalUpload,
    handleMove,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {!selectedData ? (
          <FormattedMessage id="libraryForm" />
        ) : (
          <FormattedMessage id="updateFileLibraryForm" />
        )}
      </h3>
    );
  };
  const renderForm = (index: number) => {
    const messageAlert = () => {
      /* eslint-disable */
      return (
        <ul>
          <li>{`Name must not contain  \\\:*?"<>/|`}</li>
          <li>Link must start with http:// or https://</li>
        </ul>
      );
    };
    const isDisabled = !editMode && selectedData ? true : false;
    return (
      <React.Fragment>
        {initialValues && selectedData?.type !== 'folder' ? (
          <Row>
            <Col span={2}>
              <CIcon type="FileOutlined" className="iconFileTreeDetail" />
            </Col>
            <Col span={22}>
              <p className="fileNameDetailLibrary">{initialValues[`name-0`]}</p>
              <p className="fileSizeDetailLibrary">
                {initialValues[`size-0`]
                  ? convertSize(initialValues[`size-0`], 'MB', {
                      stringify: true,
                    })
                  : null}
              </p>
            </Col>
          </Row>
        ) : null}
        <Divider />
        <Alert
          message="Note"
          type="warning"
          showIcon
          description={messageAlert()}
        />
        {!initialValues && selectedData?.type !== 'folder' ? (
          <>
            <p className="textBold">File Upload</p>
            <Field
              name={`uploadLibrary-${index}`}
              component={InputFile}
              idComponent="inputrewardPicture"
              id="inputLibrary1"
              uppyConfig={{
                minFile: 1,
                maxFile: 1,
                maxSize: 1073741824,
              }}
              height="300px"
              useFile={true}
            />
          </>
        ) : null}
        <Field
          name={`embededLinkTitle-${index}`}
          component={InputText}
          idComponent={`inputLinkTitle-${index}`}
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeHolderEmbededLinkTitle',
          })}
          label={intl.formatMessage({ id: 'embededLinkTitle' })}
          disabled={isDisabled}
        />
        <Field
          name={`embededLink-${index}`}
          component={InputText}
          idComponent={`inputLink-${index}`}
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeHolderEmbededLink',
          })}
          label={intl.formatMessage({ id: 'embededLink' })}
          disabled={isDisabled}
        />
        <Field
          name={`isPublish-${index}`}
          component={InputSwitch}
          idComponent={`inputPublished-${index}`}
          label={intl.formatMessage({ id: 'publish' })}
          disabled={isDisabled}
        />
        <Row gutter={30}>
          <Col span={12}>
            <Field
              name={`startDate-${index}`}
              component={InputDatePicker}
              idComponent={`inputStartDate-${index}`}
              label={intl.formatMessage({ id: 'startDate' })}
              typeDate="datePicker"
              disabled={isDisabled}
            />
          </Col>
          <Col span={12}>
            <Field
              name={`endDate-${index}`}
              component={InputDatePicker}
              idComponent={`inputEndDate-${index}`}
              label={intl.formatMessage({ id: 'endDate' })}
              typeDate="datePicker"
              disabled={isDisabled}
            />
          </Col>
        </Row>
        {selectedData ? (
          <>
            <Button
              icon={<CIcon type="ExportOutlined" />}
              id="btnAddfile"
              style={{ float: 'left' }}
              onClick={() => handleMove()}
              loading={isLoadingFile}
            >
              Move
            </Button>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  const contentComponent = (formProps: any) => {
    const { values } = formProps;
    const fieldNameForm = [
      'embededLinkTitle-',
      'embededLink-',
      'isPublish-',
      'startDate-',
      'endDate-',
    ];

    const handleDeleteUpload = (index: number) => {
      setTotalUpload((prev: any) => prev - 1);
      for (let j = 1; j < totalUpload; j++) {
        for (let i = 0; i < fieldNameForm.length; i++) {
          values[`${fieldNameForm[i]}${index + (j - 1)}`] =
            values[`${fieldNameForm[i]}${index + j}`];
          delete values[`${fieldNameForm[i]}${index + j}`];
        }
      }
    };
    const renderLibraryForm = () => {
      const contentModal: any = [];
      for (let index = 0; index < totalUpload; index++) {
        const btnCancelCard = () => {
          if (index !== 0 && totalUpload === index + 1) {
            return (
              <CIcon
                type="CloseOutlined"
                onClick={() => handleDeleteUpload(index)}
              />
            );
          }
          return null;
        };
        const cardTitle = () => {
          return (
            <span className="cardTitleUploadLibrary">
              {`Upload ${index + 1}`}
            </span>
          );
        };
        contentModal.push(
          <CCard
            key={`card-${index}`}
            cardTitle={cardTitle()}
            cardContent={renderForm(index)}
            cardExtra={btnCancelCard()}
          />
        );
      }
      return (
        <React.Fragment>
          <span>{contentModal}</span>
          <Button
            icon={<CIcon type="PlusOutlined" />}
            disabled={totalUpload >= 5}
            id="btnAddfile"
            style={{ float: 'left' }}
            onClick={() => handleAddFile()}
            loading={isLoadingFile}
          >
            Add File
          </Button>
        </React.Fragment>
      );
    };

    if (!selectedData) {
      return <React.Fragment>{renderLibraryForm()}</React.Fragment>;
    } else {
      return (
        <React.Fragment>
          <CCard cardTitle="" cardContent={renderForm(0)} />
        </React.Fragment>
      );
    }
  };

  const buttonContent = () => {
    if (editMode === false) {
      if (!selectedData) {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (formProps: any) => {
    return (
      <Button
        icon={<CIcon type="CheckOutlined" />}
        className={'btnSubmit'}
        id="btnSubmit"
        htmlType="submit"
        disabled={selectedData && !editMode ? false : formProps.invalid}
        loading={isLoadingFile}
      >
        {buttonContent()}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={modalUploadIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={(values: any) =>
        validate(values, totalUpload, selectedData)
      }
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

import React from 'react';
import { InputText } from '../../../../Assets/Components/CInput';
import { Field } from 'react-final-form';
import CButton from '../../../../Assets/Components/CButton';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import validate from '../../Validation/RejectValidation';

interface IProps {
  modalCancelIsShow: boolean;
  handleCancel: () => void;
  handleReject: (vals: any) => void;
  isLoading: boolean;
}
export default function ModalCancelCandidateComponent(props: IProps) {
  const { modalCancelIsShow, handleCancel, handleReject, isLoading } = props;

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Pembatalan Kandidat</h3>;
  };

  const contentComponent = (formProps: any) => {
    const dataButton = [
      {
        type: 'primary',
        id: 'btnUndoCandidate',
        className: 'btnUndoCandidate',
        content: 'Urungkan',
        onClick: handleCancel,
      },
      {
        type: 'primary',
        id: 'btnRejectCandidate',
        className: 'btnRejectCandidate',
        content: 'Batalkan Kandidat',
        disabled: formProps.invalid,
        htmlType: 'submit',
        onClick: handleReject,
      },
    ];

    return (
      <>
        <p className="textBold">Catatan</p>
        <Field
          name="reason"
          component={InputText}
          placeholder="Masukan Catatan"
          idComponent="InputReasonCancel"
          textArea={true}
          maxlength={300}
        />
        <div className="containerContentCancelCandidat">
          <CButton buttonData={dataButton} isLoading={isLoading} />
        </div>
      </>
    );
  };

  return (
    <CModalRFF
      modalIsShow={modalCancelIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={null}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleReject}
      formLayout="vertical"
      initialValues={{}}
    />
  );
}

import { IResultLibraryListDAO } from '../Modules/Library/DAO/library.dao';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getLibraryListAPI = async (
  params: UseQueryOptions
): Promise<IResultLibraryListDAO[]> => {
  const selectedPath: any = params?.queryKey?.[1];
  const isRoot: any = params?.queryKey?.[2];
  const path =
    selectedPath === 'Home' || isRoot
      ? encodeURIComponent('/')
      : encodeURIComponent(selectedPath.replace(/Home/g, ''));
  const { data } = await instance.get(`/library?path=${path}`);

  return data;
};

export const findLibraryListAPI = async (
  params: UseQueryOptions
): Promise<IResultLibraryListDAO[]> => {
  const name = params?.queryKey?.[1];
  const { data } = await instance.get(`/library/find?name=${name}`);
  return data;
};

export const deleteLibraryFileAPI = async (id: string) => {
  return await instance.delete(`/library/file/${id}`);
};

export const deleteLibraryFolderAPI = async (id: string) => {
  return await instance.delete(`/library/folder/${id}`);
};

export const mutateFolderLibraryAPI = async (params: any) => {
  const { name, type, selectedPath, id } = params;
  const path =
    selectedPath === 'Home' ? '/' : selectedPath.replace(/Home/g, '');
  const payload: any = { name, type, path };
  if (id) {
    return await instance.put(`/library/folder/${id}`, payload);
  } else {
    return await instance.post(`/library/folder`, payload);
  }
};

export const mutateFileLibraryAPI = async (params: any) => {
  const { payloadSubmit, id, payloadUpdate } = params;
  const formData = new FormData();
  if (!id) {
    formData.append('Size', payloadSubmit.Size);
    formData.append('Name', payloadSubmit.Name);
    if (payloadSubmit.Link) {
      formData.append('Link', payloadSubmit.Link);
    }
    if (payloadSubmit.Title) {
      formData.append('Title', payloadSubmit.Title);
    }
    formData.append('StartDate', payloadSubmit.StartDate);
    formData.append('EndDate', payloadSubmit.EndDate);
    formData.append('Type', payloadSubmit.Type);
    formData.append('Status', payloadSubmit.Status);
    formData.append('path', payloadSubmit.Path);
    formData.append('file', payloadSubmit.File?.file);
  }
  if (id) {
    const { data } = await instance.put(
      `/library/file/upload/${id}`,
      payloadUpdate
    );
    return data;
  } else {
    const { data } = await instance.post(`/library/file/upload`, formData);
    return data;
  }
};

export const mutateMoveLibraryAPI = async (params: any) => {
  const { selectedMovePath, selectedData } = params;
  const dataPath: any = selectedMovePath;
  dataPath.shift();
  const dataUri = dataPath.join('/');
  const path = encodeURIComponent(dataUri === '' ? '/' : `/${dataUri}`);
  const { data } = await instance.put(
    `/library/path/${selectedData.id}?path=${path}`,
    {}
  );
  return data;
};

import React from 'react';
import ModalDownloadNotificationComponent from '../Component/ModalDownloadNotificationComponent';

interface IProps {
  isShow: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalDownloadNotificationContainer(props: IProps) {
  const { isShow, setShowModal } = props;
  const handleCancel = () => setShowModal(false);

  return (
    <ModalDownloadNotificationComponent
      modalIsShow={isShow}
      handleCancel={handleCancel}
    />
  );
}

import React, { useState } from 'react';
import NewAgentMonitoringComponent from '../Component/NewAgentMonitoringComponent';
import moment from 'moment';
import { Col, Row, Tag } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import CButton from '../../../Assets/Components/CButton';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  downloadAgentMonitoringReportAPI,
  getAgentMonitoringDataAPI,
} from '../../../Services/newAgentMonitoring';
import { FormApi } from 'final-form';
import ModalNewAgentMonitoringContainer from './ModalNewAgentMonitoringContainer';
import { IResultAgentMonitoringDAO } from '../DAO/newAgentMonitoring.dao';
import ErrorHandler from '../../../App/ErrorHandler';

export default function NewAgentMonitoringContainer() {
  const [filter, setFilter] = useState<any>({
    date: [moment().startOf('month'), moment().endOf('month')],
  });
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const formatDOB = 'DD-MMM-YYYY';

  const {
    data: agentMonitoringList,
    isLoading,
    fetchStatus,
  } = useQuery(
    ['GetNewAgentMonitoringAPI', filter],
    getAgentMonitoringDataAPI,
    {
      refetchOnWindowFocus: false,
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const downloadReport = useMutation(downloadAgentMonitoringReportAPI);

  const renderAction = row => {
    const handleClick = () => {
      const detail: IResultAgentMonitoringDAO = row.row.original;
      const detailCandidate = {
        ...detail,
        code: detail.candidateCode,
        insertDate: moment(detail.insertDate).format(formatDOB),
        subChannel: 'AG',
        sob: 'AG',
        salesOffice: detail.salesOfficeCode ? detail.salesOfficeCode : '-',
        agentNumber: detail.payeeId,
      };
      setSelectedData(detailCandidate);
      setModalIsShow(true);
    };
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailQa',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: handleClick,
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const handleDownload = () => {
    downloadReport.mutate({
      dateFilter: filter?.date,
      status: filter?.status,
    });
  };

  const handleRefresh = (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    const initDate = [moment().startOf('month'), moment().endOf('month')];
    form.change('dateFilter', initDate);
    form.change('journey', null);
    setFilter({ date: initDate });
  };

  const handleFilter = (values: any) => {
    setFilter({ date: values?.dateFilter, status: values?.journey || '' });
  };

  const tagStat = (tagStatus: string, color: string, fontColor: string) => {
    return (
      <Tag color={color}>
        <div style={{ color: fontColor, width: 150, textAlign: 'center' }}>
          {tagStatus}
        </div>
      </Tag>
    );
  };

  const renderProgress = (status: string) => {
    switch (status) {
      case 'Agent Activated': {
        return tagStat('Agent Activated', '#3ACE3A', '#FFFFFF');
      }
      default: {
        return tagStat(status, '#00000029', '#000000');
      }
    }
  };
  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Kode Agen/No. Apl',
      accessor: row =>
        row.payeeId !== null
          ? row.payeeId
          : row.contractNumber !== null
          ? row.contractNumber
          : '-',
    },
    {
      Header: 'Nama Kandidat',
      accessor: 'candidateName',
    },
    {
      Header: 'Status',
      accessor: 'candidateStatus',
      disableSort: true,
      Cell: row => renderProgress(row.row.original.candidateStatus),
    },
    {
      Header: 'Tanggal Pengajuan',
      accessor: row =>
        row.dosdStartDate ? moment(row.dosdStartDate).format(formatDOB) : '-',
      disableSort: true,
    },
    {
      Header: 'Nama Agensi',
      accessor: 'refAgencyName',
    },
    {
      Header: ' ',
      Cell: row => renderAction(row),
    },
  ];
  const renderCol = (label: string, content: string) => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={4}>
          <b>{label}</b>
        </Col>
        <Col span={8}>{': ' + content}</Col>
      </Row>
    );
  };
  const renderRowSubComponent = React.useCallback(({ row }) => {
    const {
      approverName,
      contractNumber,
      activationDate,
      origin,
      salesOfficeDesc,
    } = row.original;
    const registrationDate = moment(activationDate).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Nama Approver',
          approverName === null || approverName === '' ? '-' : approverName
        )}

        {renderCol(
          'Level Kandidat',
          contractNumber === null || contractNumber === ''
            ? '-'
            : contractNumber
        )}

        {renderCol('Origin', origin === null || origin === '' ? '-' : origin)}
        {renderCol(
          'Registration Date',
          registrationDate === '01-Jan-0001' ? '-' : registrationDate
        )}
        {renderCol(
          'Kantor Sales',
          salesOfficeDesc === null || salesOfficeDesc === ''
            ? '-'
            : salesOfficeDesc
        )}
      </div>
    );
  }, []);

  return (
    <>
      <NewAgentMonitoringComponent
        column={columnData}
        handleDownload={handleDownload}
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        isLoading={isLoading || fetchStatus === 'fetching'}
        newAgentMonitoringList={agentMonitoringList || []}
        renderRowSubComponent={renderRowSubComponent}
        selectedFilter={filter}
        setFilter={setFilter}
      />
      <ModalNewAgentMonitoringContainer
        modalIsShow={modalIsShow}
        selectedData={selectedData}
        setModalIsShow={setModalIsShow}
        setSelectedData={setSelectedData}
      />
    </>
  );
}

import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { getLoginTrackerDataAPI } from '../../../Services/Dashboard/dashboard.api';
import DashboardLoginTrackerComponent from '../Component/DashboardLoginTrackerComponent';

export default function DashboardLoginTrackerContainer() {
  const { data: dataLoginTracker } = useQuery(
    ['getLoginTrackerDashboardAPI'],
    getLoginTrackerDataAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  return <DashboardLoginTrackerComponent data={dataLoginTracker || []} />;
}

import React, { useState } from 'react';
import ExamScheduleComponent from '../Component/ExamScheduleComponent';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getExamScheduleAPI } from '../../../Services/examSchedule.api';
import ModalListExamScheduleContainer from './ModalListExamScheduleContainer';
import ModalDetailExamScheduleContainer from './ModalDetailExamScheduleContainer';

export default function ExamScheduleContainer() {
  const [year, setYear] = useState(moment().format('YYYY'));
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalList, setShowModalList] = useState(false);
  const [selectedDetail, setSelectedDetail] = useState<any>();
  const [selectedList, setSelectedList] = useState<any[]>([]);
  const [modalAction, setModalAction] = useState('');
  const [selectedDate, setSelectedDate] = useState<any>();

  const initialValue = {
    scheduleType: 'month',
    yearList: Number(moment().format('YYYY')),
  };

  const { data } = useQuery(['getExamScheduleAPI'], getExamScheduleAPI, {
    refetchOnWindowFocus: false,
  });

  const selectDateSchedule = (dataList: any, date: any) => {
    setSelectedDate(date);
    setSelectedList(dataList);
    setShowModalList(true);
  };

  const handleOnClickDetail = (exam: any) => {
    setModalAction('year');
    setSelectedDetail(exam);
    setShowModalDetail(true);
  };

  const handleSelectedYear = (e: number) => {
    if (e) {
      const date = moment().format('DD');
      const month = moment().format('MM');
      setYear(moment(`${e}-${month}-${date}`).format('YYYY'));
    }
  };

  const listExamYear: any[] = (data || []).filter(obj => {
    return moment(obj.examDate).format('YYYY') === year;
  });

  const newListExamYear: any = [];
  for (const iterator of listExamYear) {
    iterator.examDate = new Date(
      moment(iterator.examDate).format('DD-MMM-YYYY')
    );
    newListExamYear.push(iterator);
  }

  const sortLitExamYear = newListExamYear.sort(
    (a, b) => a.examDate - b.examDate
  );

  return (
    <>
      <ExamScheduleComponent
        handleOnclickDetail={handleOnClickDetail}
        handleSelectedYear={handleSelectedYear}
        initialValues={initialValue}
        listExamSchedule={data || []}
        listExamYear={sortLitExamYear}
        selectDateSchedule={selectDateSchedule}
      />
      <ModalDetailExamScheduleContainer
        detailExamSchedule={selectedDetail}
        modalAction={modalAction}
        showModal={showModalDetail}
        setShowModalDetail={setShowModalDetail}
        setShowModalList={setShowModalList}
        setSelectedDetail={setSelectedDetail}
      />
      <ModalListExamScheduleContainer
        listExamSchedule={selectedList}
        setModalAction={setModalAction}
        setSelectedList={setSelectedList}
        setShowModalDetail={setShowModalDetail}
        setShowModalList={setShowModalList}
        setSelectedDetail={setSelectedDetail}
        showModal={showModalList}
        selectedDate={selectedDate}
      />
    </>
  );
}

import ModalUploadScheduleComponent from '../Component/ModalUploadScheduleComponent';
import React from 'react';
interface IProps {
  modalUploadScheduleIsShow: boolean;
  setModalUploadScheduleIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalUploadScheduleContainer(props: IProps) {
  const { modalUploadScheduleIsShow, setModalUploadScheduleIsShow } = props;
  const handleCancel = () => {
    setModalUploadScheduleIsShow(false);
  };
  return (
    <ModalUploadScheduleComponent
      handleCancel={handleCancel}
      modalUploadScheduleIsShow={modalUploadScheduleIsShow}
    />
  );
}

import { regExEmail } from '../../QAProcess/Validation/RegistrationValidation';

const FormValidation = (values: any, response: any[]) => {
  const { name, email, ageRange, gender, selfie, city, phoneNumber, idNumber } =
    values;
  const errors: any = {};
  const regexNumber = /^-?\d+\.?\d*$/;

  if (!name) {
    errors.name = 'Nama wajib diisi';
  } else if (name && !/^[^\s].*/.test(name)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  }
  if (idNumber && !regexNumber.test(idNumber)) {
    errors.idNumber = 'Hanya diperbolehkan angka';
  } else if (idNumber && idNumber.length !== 16) {
    errors.idNumber = 'NIK harus 16 Digit';
  }
  if (!email) errors.email = 'Email wajib diisi';
  else {
    if (!/^[^\s].*/.test(email))
      errors.email = 'Use letters/numbers at the beginning of words!';
    if (!regExEmail.test(email)) errors.email = 'Masukan email dengan benar';
  }
  if (!ageRange) {
    errors.ageRange = 'Kisaran usia wajib diisi';
  }
  if (!gender) {
    errors.gender = 'Jenis kelamin wajib diisi';
  }
  if (!selfie) {
    errors.selfie = 'Foto selfie wajib diisi';
  }
  if (!city) {
    errors.city = 'Kota wajib diisi';
  }
  if (!phoneNumber) {
    errors.phoneNumber = 'Nomor HP wajib diisi';
  } else {
    if (phoneNumber.charAt(0) === '0')
      errors.phoneNumber = 'Masukan telepon HP dengan benar';
    if (!regexNumber.test(phoneNumber))
      errors.phoneNumber = 'Hanya diperbolehkan angka';
  }

  if (response) {
    response.forEach(item => {
      if (item.name === 'Email' && item.value === email)
        errors.email = 'Alamat email telah digunakan';
      else if (
        item.name === 'PhoneNumber' &&
        item.value === `+62${phoneNumber}`
      )
        errors.phoneNumber = 'Nomor handphone telah digunakan';
    });
  }

  return errors;
};
export default FormValidation;

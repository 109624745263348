import { CHANGE_LOCALE } from './types';
import { DEFAULT_LOCALE } from './i18n';

export const initialState = {
  locale: DEFAULT_LOCALE
};

function languageProviderReducer(state = initialState, action) {
  const actionType = action.type;
  let stateLocale = state.locale;
  if (actionType === CHANGE_LOCALE) {
    stateLocale = action.locale;
    return stateLocale;
  }
  return state;
}

export default languageProviderReducer;

import React, { useState } from 'react';
import ReportComponent from '../Component/ReportComponent';
import { useMutation } from '@tanstack/react-query';
import {
  downloadReportAsFile,
  getReportListAPI,
} from '../../../Services/report.api';
import moment from 'moment';
import { FormApi } from 'final-form';
import { Col, Row } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import ErrorHandler from '../../../App/ErrorHandler';

export default function ReportContainer() {
  const [notifType, setNotifType] = useState();
  const [selectedType, setSelectedType] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedNotif, setSelectedNotif] = useState();
  const [selectedFormat, setSelectedFormat] = useState('xlsx');

  const {
    mutate: mutateReportList,
    isLoading: isLoadingReport,
    data: reportList,
  } = useMutation(getReportListAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {},
  });

  const { mutate: mutateDownloadReport, isLoading: isDownloading } =
    useMutation(downloadReportAsFile, {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    });

  let isNotif = false;
  if (reportList?.[0] && reportList?.[0]?.titleNotif) {
    isNotif = true;
  }

  const handleSubmit = (values: any) => {
    mutateReportList({
      ...values,
      formatReport: selectedFormat,
      notificationType: selectedNotif,
    });
    setSelectedType(values?.reportType);
    setSelectedDate(values?.dateFilter);
  };

  const handleDownload = () => {
    mutateDownloadReport({
      reportType: selectedType,
      formatReport: selectedFormat,
      reportDate: selectedDate,
      notificationType: selectedNotif,
    });
  };

  const handleSelectType = (form: FormApi<any, Partial<any>>) => {
    form.change('dateFilter', null);
  };

  const handleSelectNotif = (e: any) => {
    mutateReportList({
      reportType: selectedType,
      notificationType: e,
      dateFilter: selectedDate,
    });
  };
  const formatDOB = 'DD-MMM-YYYY';
  const generateColumn = () => {
    let columnData: object = [];
    if (selectedType === 'leadInvitationReport') {
      columnData = [
        {
          id: 'expander',
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <CIcon type="CaretDownOutlined" />
              ) : (
                <CIcon type="CaretRightOutlined" />
              )}
            </span>
          ),
        },
        {
          Header: 'Leads Name',
          accessor: 'leadName',
        },
        {
          Header: 'Send Via',
          accessor: 'sendVia',
        },
        {
          Header: 'Invitation',
          accessor: 'invitation',
        },
        {
          Header: 'Sent',
          accessor: 'sendDate',
          Cell: (row: any) =>
            row.row.original.sendDate
              ? moment(row.row.original.sendDate).format(formatDOB)
              : '-',
        },
        {
          Header: 'Status',
          accessor: 'status',
        },
      ];
    } else if (selectedType === 'leadReport') {
      columnData = [
        {
          id: 'expander',
          Cell: ({ row }) => (
            <span {...row.getToggleRowExpandedProps()}>
              {row.isExpanded ? (
                <CIcon type="CaretDownOutlined" />
              ) : (
                <CIcon type="CaretRightOutlined" />
              )}
            </span>
          ),
        },
        {
          Header: 'Leads Name',
          accessor: 'name',
        },
        {
          Header: 'City',
          accessor: 'city',
        },
        {
          Header: 'DOB',
          accessor: (row: any) =>
            row.dateOfBirth ? moment(row.dateOfBirth).format(formatDOB) : '-',
        },
        {
          Header: 'Gender',
          accessor: 'gender',
        },
        {
          Header: 'Scoring',
          accessor: 'scoring',
        },
      ];
    } else if (selectedType === 'notificationReport') {
      columnData = [
        {
          Header: 'Notification Type',
          accessor: 'titleNotif',
        },
        {
          Header: 'Agent Code',
          accessor: 'agentCode',
        },
        {
          Header: 'Content Message',
          accessor: 'contentNotif',
        },
        {
          Header: 'Received Date',
          accessor: 'receivedDate',
          Cell: (row: any) =>
            row.row.original.receivedDate
              ? moment(row.row.original.receivedDate).format(formatDOB)
              : '-',
        },
      ];
    } else {
      columnData = [
        {
          Header: 'Candidate Name',
          accessor: 'name',
        },
        {
          Header: 'Application Number',
          accessor: (row: any) =>
            row.contractNumber ? row.contractNumber : '-',
        },
        {
          Header: 'Agent Code',
          accessor: (row: any) => (row.preAgentCode ? row.preAgentCode : '-'),
        },
      ];
    }
    return columnData;
  };

  const renderCol = (label: string, content: any) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };
  const renderRowSubLeaderRpt = React.useCallback(({ row }) => {
    const {
      insertDate,
      leadContacts,
      refAgentCode,
      refAgentName,
      refAgencyName,
      placeOfBirth,
      bloodType,
      address,
      subDistrict,
      village,
      religion,
      maritalStatus,
      nationality,
      expiryDate,
      idNumber,
      occupation,
      additionalAddress,
    } = row.original;
    let email = '-';
    let phone = '-';
    leadContacts.forEach((element: any) => {
      if (element.contactType === 1) {
        phone = element.contact;
      } else if (element.contactType === 2) {
        email = element.contact;
      }
    });

    return (
      <div>
        {renderCol('Email', email)}
        {renderCol('Phone', phone)}

        {renderCol(
          'Recruiter Code',
          refAgentCode === null || refAgentCode === '' ? '-' : refAgentCode
        )}

        {renderCol(
          'Recruiter Name',
          refAgentName === null || refAgentName === '' ? '-' : refAgentName
        )}

        {renderCol(
          'Recruiter Agency Name',
          refAgencyName === null || refAgencyName === '' ? '-' : refAgencyName
        )}

        {renderCol(
          'Date added',
          insertDate === null || insertDate === '-'
            ? '-'
            : moment(insertDate).format(formatDOB)
        )}

        {renderCol(
          'NIK',
          idNumber === null || idNumber === '' ? '-' : idNumber
        )}

        {renderCol(
          'Birthplace',
          placeOfBirth === null || placeOfBirth === '' ? '-' : placeOfBirth
        )}

        {renderCol(
          'Blood type',
          bloodType === null || bloodType === '' ? '-' : bloodType
        )}

        {renderCol(
          'Address',
          address === null || address === '' ? '-' : address
        )}

        {renderCol(
          'RT/RW',
          additionalAddress === null || additionalAddress === ''
            ? '-'
            : additionalAddress
        )}

        {renderCol(
          'Village',
          village === null || village === '' ? '-' : village
        )}

        {renderCol(
          'Sub-district',
          subDistrict === null || subDistrict === '' ? '-' : subDistrict
        )}

        {renderCol(
          'Religion',
          religion === null || religion === '' ? '-' : religion
        )}

        {renderCol(
          'Marital status',
          maritalStatus === null || maritalStatus === '' ? '-' : maritalStatus
        )}

        {renderCol(
          'Job',
          occupation === null || occupation === '' ? '-' : occupation
        )}

        {renderCol(
          'Nationaly',
          nationality === null || nationality === '' ? '-' : nationality
        )}

        {renderCol(
          'Expiry date',
          expiryDate === null || expiryDate === '' ? '-' : expiryDate
        )}
      </div>
    );
  }, []);

  const renderRowSubLeadInvRpt = React.useCallback(({ row }) => {
    const { contact, recruiterCode } = row.original;
    return (
      <div>
        {renderCol(
          'Email/Phone',
          contact === null || contact === '' ? '-' : contact
        )}

        {renderCol(
          'Recruiter Code',
          recruiterCode === null || recruiterCode === '' ? '-' : recruiterCode
        )}
      </div>
    );
  }, []);

  return (
    <ReportComponent
      columnData={generateColumn}
      reportList={reportList}
      isLoading={isLoadingReport}
      isNotif={isNotif}
      notifType={notifType}
      setNotifType={setNotifType}
      handleSubmit={handleSubmit}
      selectedType={selectedType}
      handleSelectType={handleSelectType}
      renderRowSubComponent={
        selectedType === 'leadInvitationReport'
          ? renderRowSubLeadInvRpt
          : selectedType === 'leadReport'
          ? renderRowSubLeaderRpt
          : null
      }
      setSelectedFormat={setSelectedFormat}
      selectedFormat={selectedFormat}
      handleDownloadReport={handleDownload}
      isDownloading={isDownloading}
      handleSelectNotif={handleSelectNotif}
      selectedNotif={selectedNotif}
      setSelectedNotif={setSelectedNotif}
    />
  );
}

import '../../Css/calender.css';
import { Col, Row, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import { monthList, yearList } from '../../../App/Enums';
import buildCalender from './Build';
import dayStyles from './style';
import moment from 'moment';
import Select from '../Select';
import { Field } from 'react-final-form';
import { FormApi } from 'final-form';

interface IProps {
  form: FormApi<Record<string, any>, Partial<Record<string, any>>>;
  listSchedule: any[];
  onClickDate: (e: any, x: any) => void;
}

export default function CCalenderScheduleRFF(props: IProps) {
  const { form, listSchedule, onClickDate } = props;

  const [calender, setCalender] = useState([]);
  const [value, setValue] = useState(moment());
  const [month, setMonth] = useState('');
  const [year, setYear] = useState(0);

  useEffect(
    () => {
      const calender: any = buildCalender(value);
      setCalender(calender);

      // month
      const monthCalender = moment(value).format('MM');
      setMonth(monthCalender);
      form.change('monthCalender', monthCalender);
      //   dispatch(change(formName, 'monthCalender', monthCalender));

      // year
      const yearCalender = Number(moment(value).format('YYYY'));
      setYear(yearCalender);
      form.change('yearCalender', yearCalender);
      //   dispatch(change(formName, 'yearCalender', yearCalender));
    }, // eslint-disable-next-line
    [value]
  );

  const filterDate = () => {
    const monthSelect = e => {
      if (e) {
        const date = moment(value).format('D');
        setValue(moment(`${date}-${e}-${year}`, 'DD-MM-YYYY'));
      }
    };
    const yearSelect = e => {
      if (e) {
        const date = moment(value).format('D');
        setValue(moment(`${date}-${month}-${e}`, 'DD-MM-YYYY'));
      }
    };
    return (
      <Row gutter={12}>
        <Col span={6}>
          <Field
            name="monthCalender"
            component={Select}
            dataOption={monthList}
            idComponent="InputMonthCalender"
            onSelect={e => monthSelect(e)}
            className="selectExamSchedule"
            defaultItemName="Pilih Bulan"
          />
        </Col>
        <Col span={5}>
          <Field
            name="yearCalender"
            component={Select}
            dataOption={yearList()}
            idComponent="InputYearCalender"
            onSelect={e => yearSelect(e)}
            className="selectExamSchedule"
            defaultItemName="Pilih Tahun"
          />
        </Col>
      </Row>
    );
  };

  return (
    <div className="calender">
      {filterDate()}
      <div className="containerDay">
        <div className="dayName">Minggu</div>
        <div className="dayName">Senin</div>
        <div className="dayName">Selasa</div>
        <div className="dayName">Rabu</div>
        <div className="dayName">Kamis</div>
        <div className="dayName">Jumat</div>
        <div className="dayName">Sabtu</div>
      </div>
      {calender.map(week => {
        const newWeek: any = week;
        return newWeek.map(day => {
          const myArray = listSchedule.filter(obj => {
            return (
              moment(obj.examDate).format('YYYY-MM-DD') ===
              moment(day).format('YYYY-MM-DD')
            );
          });
          const selectDate = day => {
            setValue(day);
            if (myArray.length > 0) {
              onClickDate(myArray, day);
            }
          };

          const renderItemMore = myArray => {
            if (myArray.length > 2) {
              return (
                <div style={{ textDecoration: 'underline' }}>{`+${
                  myArray.length - 2
                } Lainnya`}</div>
              );
            }
            return null;
          };

          const renderItem = () => {
            return (
              <>
                {myArray.map((data, index) => {
                  if (index <= 1) {
                    return (
                      <Tag
                        key={`${data.examDate}${index}`}
                        color={'#FB6915'}
                        style={{
                          width: '100%',
                          borderRadius: 5,
                          marginBottom: 5,
                        }}
                      >
                        {data.examType === 0 ? 'Online' : 'Mobile Apps'}
                      </Tag>
                    );
                  }
                  return null;
                })}
                {renderItemMore(myArray)}
              </>
            );
          };
          return (
            <div
              key={`day${day.format('D')}`}
              className={dayStyles(day, value)}
              onClick={() => selectDate(day)}
            >
              <div
                style={{ textAlign: 'right', fontSize: 18, fontWeight: 'bold' }}
              >
                {day.format('D').toString()}
              </div>
              {renderItem()}
            </div>
          );
        });
      })}
    </div>
  );
}

import { Button, Empty } from 'antd';
import { DeleteOutlined, FileOutlined } from '@ant-design/icons';
import React, { useState } from 'react';

import CIcon from '../../../../../Assets/Components/CIcon';
import { Field } from 'react-final-form';
import InputFile from '../../../../../Assets/Components/InputFile';
import { InputText } from '../../../../../Assets/Components/CInput';
import { formatBytes } from '../../../../../App/Helper';
import { toastr } from 'react-redux-toastr';
import { useDebouncedCallback } from 'use-debounce';

interface IProps {
  indexLink: number;
  setIndexLink: React.Dispatch<React.SetStateAction<number>>;
  disabled: boolean;
  formValues: any;
  contentList: any[];
  form: any;
  handleDeletecontent: any;
}

function LinkItemComponent({
  item,
  disabled,
  embed,
  form,
  indexLink,
  setIndexLink,
}) {
  const [link, setLink]: any = useState();
  const debounced: any = useDebouncedCallback(value => {
    setLink(value);
  }, 1000);

  debounced[0](embed?.[item]?.link);
  const handleFrameSrc = () => {
    let embedId;
    if (link) {
      const srcToArray = link.split('/');
      embedId = srcToArray[srcToArray.length - 1].replace('watch?v=', '');
    }
    const src = `https://www.youtube.com/embed/${embedId}`;
    return src;
  };
  const renderDelete = () => {
    const handleDeleteEmbed = () => {
      form.change(`embed.${item}.link`, undefined);
      form.mutators.remove('embed', item);
      setIndexLink(indexLink - 1);
    };
    return disabled ? null : (
      <Button
        style={{ position: 'absolute', left: '110px', zIndex: 1, top: -7 }}
        icon={<DeleteOutlined />}
        danger
        type="primary"
        onClick={handleDeleteEmbed}
      >
        Delete
      </Button>
    );
  };

  return (
    <div
      style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}
      key={item}
    >
      <div style={{ position: 'relative' }}>
        {item !== 0 && renderDelete()}
        <Field
          name={`embed.${item}.link`}
          component={InputText}
          placeholder="Paste Link Here"
          label={`Link Source ${item + 1}`}
          disabled={disabled}
          addtionalCaption="*Youtube link or other source (Google Drive, bitly, etc)"
        />
      </div>
      <div
        style={{
          display: 'flex',
          gap: '20px',
          padding: '10px',
          backgroundColor: '#F5F7F7',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {link && link.indexOf('youtube') !== -1 ? (
          <iframe
            width="100%"
            height="200"
            src={handleFrameSrc()}
            title="Embed link player"
            frameBorder="0"
            allowFullScreen
          />
        ) : (
          <Empty />
        )}
      </div>
    </div>
  );
}

export default function HandoutComponent({
  disabled,
  indexLink,
  setIndexLink,
  formValues,
  contentList,
  form,
  handleDeletecontent,
}: IProps) {
  const maxFileSize = 20971520;

  const { file, embed } = formValues;
  let totalFileSize = 0;
  if (file) {
    if (file instanceof Array) {
      file.forEach(fileItem => {
        totalFileSize += fileItem.file.size;
      });
    } else {
      totalFileSize += file.file.size;
    }
  }

  function LinkComponent() {
    const arr = Array.from({ length: indexLink }, (_, i) => i);
    return (
      <div>
        {arr.map((item, index) => {
          return (
            <LinkItemComponent
              key={`LinkItemComponent-${index}`}
              item={item}
              disabled={disabled}
              embed={embed}
              form={form}
              indexLink={indexLink}
              setIndexLink={setIndexLink}
            />
          );
        })}
      </div>
    );
  }

  return (
    <div>
      <p className="titleUploadFileCityModal">Upload File</p>
      {!disabled && (
        <>
          <Field
            name="file"
            component={InputFile}
            idComponent="inputFileLetterOfStatement"
            fieldName="letterOfStatement"
            uppyConfig={{
              maxTotalFileSize: maxFileSize,
            }}
            formName="RegistrationPersonalData"
            useFile={true}
            height={300}
          />
          <div>
            <span className="labelRequired">*</span>
            <span>.pdf, .ppt, .docs, .xls, .jpg format</span>
          </div>
        </>
      )}

      <div className="borderSolid"></div>
      {contentList && contentList.length > 0 && (
        <div className="divClassHandoutFile">
          {contentList.map((item, index) => {
            totalFileSize += item.size;

            return (
              <div
                key={`handOutList-${index}`}
                style={{ display: 'flex', marginBottom: 20 }}
              >
                <div style={{ flexBasis: '40px', paddingTop: 6 }}>
                  <FileOutlined style={{ fontSize: 26 }} />
                </div>
                <div style={{ width: '100%' }}>
                  <p style={{ marginBottom: 0 }}>{item.name}</p>
                  <p style={{ marginBottom: 0, color: '#cacfd1' }}>
                    {formatBytes(item.size)}
                  </p>
                </div>
                {!disabled && (
                  <div style={{ flexBasis: '40px', paddingTop: 9 }}>
                    <DeleteOutlined
                      style={{
                        fontSize: 20,
                        color: '#d82d2d',
                        cursor: 'pointer',
                      }}
                      onClick={() => {
                        const toastrConfirmOptions = {
                          okText: 'Ok',
                          cancelText: 'Batal',
                          onOk: () => handleDeletecontent(item.id),
                          id: 'toastRConfirm',
                        };
                        toastr.confirm(
                          `Anda yakin ingin hapus file ${item.name}?`,
                          toastrConfirmOptions
                        );
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )}
      <div>
        <div>
          <span>Total Size: </span>
          <span>{formatBytes(totalFileSize)}</span> {/* valuenya dinamis */}
        </div>
        <div>
          <span className="labelRequired">*</span>
          <span>Max file size : 20 MB</span>
        </div>
      </div>

      <p className="titleUploadFileCityModal" style={{ marginTop: '20px' }}>
        Embed Link
      </p>
      {LinkComponent()}
      <Button
        className="btnTransferText"
        icon={<CIcon type="PlusOutlined" />}
        disabled={disabled}
        id="btnAddLink"
        htmlType="button"
        onClick={() => {
          setIndexLink(prev => prev + 1);
        }}
      >
        Add Other Link
      </Button>
    </div>
  );
}

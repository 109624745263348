import { Card } from 'antd';
import React from 'react';

interface IProps {
  cardTitle;
  cardExtra?;
  cardContent;
  cardClassName?;
}

export default function CCard(props: IProps) {
  const { cardTitle, cardContent, cardClassName, cardExtra } = props;
  return (
    <Card
      className={`commonCardStyle ${cardClassName}`}
      title={cardTitle}
      extra={cardExtra}
    >
      {cardContent}
    </Card>
  );
}

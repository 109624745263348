import { getELearningCertificate } from '../../../../Services/elearning.api';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';

import ClassModalGeneralComponent from '../Component/ClassModalGeneralComponent';
import React from 'react';
import { deleteContentAPI } from '../../../../Services/content.api';
import { searchLDAP } from '../../../../Services/user.api';
import { useDebounce } from 'use-debounce';

interface IProps {
  channelCode: string;
  classDetail: any;
  contentList: any[];
  dataClassNonSelf?: any;
  dataSchedule: any;
  editMode: boolean;
  indexLink: number;
  isLoading: boolean;
  isLoadingSchedule: boolean;
  listSchedule: any[];
  modalAction: 'update' | 'register';
  refetchClassDetail: () => void;
  refetchDataContent: () => void;
  refetchSchedule: () => void;
  selectedAgentManual: any[];
  setIndexLink: React.Dispatch<React.SetStateAction<number>>;
  setListSchedule: any;
  setSelectedAgentManual: React.Dispatch<React.SetStateAction<any[]>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  form?: any;
  formError?: any;
  formValues?: any;
}

export default function ClassModalGeneralContainer(props: IProps) {
  const [searchUsernameLDAP, setSearchUsernameLDAP] = useState();
  const [valuesSearch] = useDebounce(searchUsernameLDAP, 1000);
  const [listUserLDAP, setListUserLDAP] = useState([]);

  const {
    channelCode,
    classDetail,
    contentList,
    dataClassNonSelf,
    dataSchedule,
    editMode,
    form,
    formError,
    formValues,
    indexLink,
    isLoading,
    isLoadingSchedule,
    listSchedule,
    modalAction,
    refetchClassDetail,
    refetchDataContent,
    refetchSchedule,
    selectedAgentManual,
    setIndexLink,
    setListSchedule,
    setSelectedAgentManual,
    setShowModal,
  } = props;

  const {
    data: dataUserLDAP,
    isLoading: isLoadingUserLDAP,
    isRefetching: isRefetchingUserLDAP,
  } = useQuery(['getUserLDAP', valuesSearch], searchLDAP, {
    enabled: valuesSearch !== undefined,
    refetchOnWindowFocus: false,
  });

  const {
    data: dataCertificate,
    isLoading: isLoadingCertificate,
    isRefetching: isRefetchingCertificate,
  } = useQuery(['getListCertificate'], getELearningCertificate, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (dataUserLDAP && dataUserLDAP.id !== 0) {
      const tempDataUserLDAP: any = [...listUserLDAP];
      tempDataUserLDAP.push(dataUserLDAP);
      const unique = tempDataUserLDAP.filter(
        (v, i, a) => a.findIndex(v2 => v2.id === v.id) === i
      );
      for (const iterator of unique) {
        iterator.name = iterator.username;
      }
      setListUserLDAP(unique);
    }
    // eslint-disable-next-line
  }, [dataUserLDAP]);

  const handleDeletecontent = id => {
    mutateDeleteContent.mutate(id);
  };

  const mutateDeleteContent = useMutation(deleteContentAPI, {
    onSuccess: refetchDataContent,
  });

  return (
    <ClassModalGeneralComponent
      channelCode={channelCode}
      classDetail={classDetail}
      contentList={contentList}
      dataCertificate={dataCertificate || []}
      dataClassNonSelf={dataClassNonSelf}
      disabled={modalAction === 'update' && !editMode}
      form={form}
      formError={formError}
      formValues={formValues}
      handleDeletecontent={handleDeletecontent}
      indexLink={indexLink}
      isLoading={isLoading}
      isLoadingCertificate={isLoadingCertificate || isRefetchingCertificate}
      isLoadingSchedule={isLoadingSchedule}
      isLoadingUserLDAP={isLoadingUserLDAP || isRefetchingUserLDAP}
      listSchedule={dataSchedule || listSchedule}
      listUserLDAP={listUserLDAP}
      modalAction={modalAction}
      refetchSchedule={refetchSchedule}
      setIndexLink={setIndexLink}
      setListSchedule={setListSchedule}
      setSearchUsernameLDAP={setSearchUsernameLDAP}
      setShowModal={setShowModal}
      selectedAgentManual={selectedAgentManual}
      setSelectedAgentManual={setSelectedAgentManual}
      refetchClassDetail={refetchClassDetail}
    />
  );
}

import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import HasPermission from '../../../App/HasPermission';
import React from 'react';
import Table from '../../../Assets/Components/CTable';

interface IProps {
  columns: any;
  handleCreate: () => void;
  handleRefresh: () => void;
  intl: any;
  isLoading: boolean;
  userRoleList: any[];
}

export default function AuthUserRoleComponent(props: IProps) {
  const {
    columns,
    handleCreate,
    handleRefresh,
    intl,
    isLoading,
    userRoleList,
  } = props;

  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'PlusOutlined',
          onClick: handleCreate,
          content: 'Add mapping user role',
          id: 'btnAddMappingUserRole',
        }
      : null,
  ];
  const cardUserRoleContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
        <Table
          isLoading={isLoading}
          columns={columns}
          data={userRoleList}
          pagination={true}
          useFilterGlobal={true}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="User" rightText="Roles" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'userRoleCardTitle',
            })}
          </span>
        }
        cardContent={cardUserRoleContent()}
      />
    </>
  );
}

import { Col, Row } from 'antd';
import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import Select from '../../../Assets/Components/Select';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import ModalQuestionBankContainer from '../Container/ModalQuestionBankContainer';
import { IResultQuestionBankDAO } from '../DAO/questionBank.dao';
import { IResultCategoryBankDAO } from '../../ElearningCategoryBank/DAO/categoryBank.dao';
import { IResultChannelElearningDAO } from '../../ElearningContentManagement/Channel/DAO/channelElearning.dao';
interface IProps {
  columnData: any;
  handleRefresh: () => void;
  handleReset: () => void;
  handleFilter: () => void;
  isLoading: boolean;
  listQuestionBank: IResultQuestionBankDAO[];
  listElearningChannel: IResultChannelElearningDAO[];
  listCategoryBank: IResultCategoryBankDAO[];
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsShow: boolean;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedChannel?: string;
  setSelectedChannel: React.Dispatch<React.SetStateAction<any>>;
  selectedCategory?: string;
  setSelectedCategory: React.Dispatch<React.SetStateAction<any>>;
  questionFilter?: any;
}

export default function QuestionBankComponent(props: IProps) {
  const {
    columnData,
    listQuestionBank,
    handleRefresh,
    isLoading,
    listElearningChannel,
    listCategoryBank,
    setModalIsShow,
    modalIsShow,
    selectedData,
    setSelectedData,
    selectedChannel,
    setSelectedChannel,
    selectedCategory,
    setSelectedCategory,
    handleFilter,
    questionFilter,
    handleReset,
  } = props;

  const buttonDataFilter = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchQuestionBankList',
      onClick: handleFilter,
      disabled: selectedChannel === undefined && selectedCategory === undefined,
    },
    {
      className: 'btnReset btnFeedbackBank',
      content: 'Reset',
      id: 'btnResetQuestionBankList',
      onClick: handleReset,
      disabled: selectedChannel === undefined && selectedCategory === undefined,
    },
  ];

  const buttonData = [
    {
      type: 'primary',
      onClick: () => setModalIsShow(true),
      content: 'Tambah Baru',
      id: 'btnAddQuestionBank',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshQuestionBank',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];
  const tabContent = () => {
    return (
      <div className="containerWhiteZurich">
        <Row gutter={5}>
          <Col span={4}>
            <label className="labelBtnFilter">Channel</label>
            <Select
              dataOption={listElearningChannel || []}
              idComponent="filterChannel"
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setSelectedChannel,
                value: selectedChannel ? selectedChannel : 'Semua',
              }}
              defaultItemName="Semua"
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={8}>
            <label className="labelBtnFilter">Category</label>
            <Select
              dataOption={listCategoryBank || []}
              idComponent="filterCategory"
              meta={{ touched: false, error: false }}
              input={{
                onBlur: null,
                onChange: setSelectedCategory,
                value: selectedCategory ? selectedCategory : 'Semua',
              }}
              defaultItemName="Semua"
              className="btnFeedbackBank"
            />
          </Col>
          <Col span={6} className={'btnFilterAplicationList'}>
            <CButton
              isLoading={isLoading}
              buttonData={buttonDataFilter}
              buttonFloat={'left'}
            />
          </Col>
          <Col span={6} className={'btnFilterAplicationList'}>
            <CButton
              isLoading={isLoading}
              buttonData={buttonData}
              buttonFloat={'right'}
            />
          </Col>
        </Row>
        <Table
          columns={generateColumnData(columnData)}
          pagination={true}
          data={questionFilter ? questionFilter : listQuestionBank}
          useFilterGlobal
          isLoading={isLoading}
        />
        <ModalQuestionBankContainer
          showModal={modalIsShow}
          setShowModal={setModalIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          handleRefresh={handleRefresh}
          listElearningChannel={listElearningChannel}
          listCategoryBank={listCategoryBank}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Question Bank" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Question List</span>}
        cardContent={tabContent()}
      />
    </React.Fragment>
  );
}

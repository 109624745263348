import { IResultChannelNonSelfTrainingDAO } from '../Modules/ElearningNonSelfTraining/Channel/DAO/channelNonSelfTraining.dao';
import { UseQueryOptions } from '@tanstack/react-query';
import instance from './interceptor';

export const getNonSelfTrainingChannelAPI = async (
  params: UseQueryOptions
): Promise<IResultChannelNonSelfTrainingDAO[]> => {
  const isActive = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Elearning/Channel/NonSelf${
      isActive === 'active'
        ? '?isActive=true'
        : isActive === 'inactive'
        ? '?isActive=false'
        : ''
    }`
  );
  return data;
};

export const deleteNonSelfTrainingChannelAPI = async (id: string) => {
  return await instance.delete(`/Elearning/Channel/NonSelf/${id}`);
};

export const mutateNonSelfTrainingChannelAPI = async params => {
  const { code, name, headOfTrainer, signOffCaption, isActive, id } = params;
  const payload: any = { code, name, headOfTrainer, signOffCaption, isActive };
  if (!isActive) {
    payload.isActive = false;
  }
  payload.trainerName = headOfTrainer;
  payload.isNonSelf = true;

  if (id) {
    return await instance.put(`/Elearning/Channel/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/Channel`, payload);
  }
};

import React from 'react';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { Col, Row } from 'antd';
import { Field } from 'react-final-form';
import Select from '../../../../Assets/Components/Select';
import { InputText } from '../../../../Assets/Components/CInput';
import CButton from '../../../../Assets/Components/CButton';
import { createListSelectBox } from '../../../../App/Helper';
import validate from '../../Validation/BeneficiaryValidation';

interface IProps {
  modalEditBeneficiaryIsShow: boolean;
  handleCancel: any;
  schema: any;
  selectedBeneficiary: any;
  handleReset: () => void;
  handleUpdate: (values: any) => void;
}

export default function ModalEditBeneficiaryComponent(props: IProps) {
  const {
    modalEditBeneficiaryIsShow,
    handleCancel,
    schema,
    selectedBeneficiary,
    handleReset,
    handleUpdate,
  } = props;
  const required = (value: any) => (value ? undefined : 'Wajib Diisi!');

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Edit Ahli Waris</h3>;
  };

  const contentComponent = (formProps: any) => {
    const formValues = formProps.values;
    const formError = formProps.errors;
    const databutton = [
      {
        type: 'primary',
        content: 'Simpan',
        id: 'btnSaveEditBeneficary',
        className: 'btnSaveEditBeneficary',
        onClick: () => handleUpdate(formValues),
        disabled: Object.keys(formError).length > 0 ? true : false,
      },
      {
        type: 'primary',
        content: 'Reset',
        id: 'btnResetEditBeneficary',
        className: 'btnResetRegisForm',
        onClick: handleReset,
      },
    ];
    const buttonBack = [
      {
        type: 'primary',
        onClick: handleCancel,
        content: 'Kembali',
        id: 'btnBackForm',
        className: 'btnBackRegsitration',
        icon: 'ArrowLeftOutlined',
      },
    ];

    if (schema) {
      const formBeneficiary =
        schema.schema.properties.beneficiary.properties.form.properties;
      const valueForm = Object.values(formBeneficiary);
      const keysForm = Object.keys(formBeneficiary);
      const listFormBeneficiary: any = [];
      valueForm.forEach((data, index) => {
        const newData: any = data;
        newData.name = keysForm[index];
        listFormBeneficiary.push(newData);
      });
      return (
        <>
          <Row gutter={32}>
            {listFormBeneficiary.map((data, index) => {
              if (data.template === 'SelectBox') {
                return (
                  <Col span={12} key={`formBeneficiar${index}`}>
                    <Field
                      name={data.name}
                      component={Select}
                      idComponent={`InputEditComponent${data.name}`}
                      defaultItemName={data.config.placeholder}
                      label={data.title}
                      addtionalLabel={data.isRequired ? null : '(Optional)'}
                      validate={data.isRequired ? required : undefined}
                      dataOption={createListSelectBox(data.enum)}
                    />
                  </Col>
                );
              } else {
                return (
                  <Col span={12} key={`formBeneficiar${index}`}>
                    <Field
                      name={data.name}
                      component={InputText}
                      idComponent={`InputEditComponent${data.name}`}
                      placeholder={data.config.placeholder}
                      label={data.title}
                      addtionalLabel={data.isRequired ? null : '(Optional)'}
                      validate={data.isRequired ? required : undefined}
                      textArea={data.config.multiline}
                      textPrefix={data.prefix}
                    />
                  </Col>
                );
              }
            })}
          </Row>
          <CButton buttonData={databutton} />
          <hr className="lineSectionRegis" />
          <div className="containerButtonBack">
            <CButton buttonData={buttonBack} />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={modalEditBeneficiaryIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={null}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={selectedBeneficiary}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '70vw',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

import ModalUploadWorldCheckComponent from '../Component/ModalUploadWorldCheckComponent';
import React from 'react';

interface IProps {
  modalUploadWorldIsShow: any;
  setModalUploadWorldIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function ModalUploadWorldCheckContainer(props: IProps) {
  const { modalUploadWorldIsShow, setModalUploadWorldIsShow } = props;
  const handleCancel = () => {
    setModalUploadWorldIsShow(false);
  };
  return (
    <ModalUploadWorldCheckComponent
      handleCancel={handleCancel}
      modalUploadWorldIsShow={modalUploadWorldIsShow}
    />
  );
}

import { Alert, Button, Skeleton } from 'antd';
import {
  InputText,
  InputTextNumber,
  TextInput,
} from '../../../Assets/Components/CInput';

import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CIcon from '../../../Assets/Components/CIcon';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { RadioButton } from '../../../Assets/Components/CRadio';
import React from 'react';
import Select from '../../../Assets/Components/Select';
import validate from '../Validation/QuestionBankValidation';
import { IResultCategoryBankDAO } from '../../ElearningCategoryBank/DAO/categoryBank.dao';
import { IResultChannelElearningDAO } from '../../ElearningContentManagement/Channel/DAO/channelElearning.dao';

interface IProps {
  editMode: boolean;
  handleAddQuestion: () => void;
  handleCancel: () => void;
  initialValues: any;
  isLoading: boolean;
  isShow: boolean;
  listCategoryBank: IResultCategoryBankDAO[];
  listElearningChannel: IResultChannelElearningDAO[];
  onSubmit: (vals: any) => void;
  questionBankModalAction: 'update' | 'register';
  setTotalQuestion: React.Dispatch<React.SetStateAction<number>>;
  totalQuestion: number;
}

export default function ModalQuestionBankComponent(props: IProps) {
  const {
    isShow,
    isLoading,
    handleCancel,
    onSubmit,
    questionBankModalAction,
    editMode,
    listElearningChannel,
    listCategoryBank,
    handleAddQuestion,
    setTotalQuestion,
    totalQuestion,
    initialValues,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {questionBankModalAction === 'update' ? (
          <FormattedMessage id="detailContentQuestion" />
        ) : (
          <FormattedMessage id="addContentQuestion" />
        )}
      </h3>
    );
  };

  const renderForm = (index: number) => {
    const indexName = index ? index : '';
    const disabled =
      editMode === false && questionBankModalAction === 'update' ? true : false;
    const renderOptionAnswer = (title: string, indexAnswer: number) => {
      return (
        <React.Fragment>
          <span className="inputAnswer">{title}</span>
          <Field
            name={`answerOption${indexName}.${indexAnswer}`}
            className="inputNumberSubModuleInline"
            component={TextInput}
            disabled={disabled}
          />
        </React.Fragment>
      );
    };

    const dataAnswer = [
      {
        id: 'answer_1',
        component: renderOptionAnswer('Jawaban 1', 0),
      },
      {
        id: 'answer_2',
        component: renderOptionAnswer('Jawaban 2', 1),
      },
      {
        id: 'answer_3',
        component: renderOptionAnswer('Jawaban 3', 2),
      },
      {
        id: 'answer_4',
        component: renderOptionAnswer('Jawaban 4', 3),
      },
    ];

    return (
      <React.Fragment>
        <Skeleton loading={isLoading} active>
          <div className="containerGridTwo">
            <div>
              <Field
                name={`channelId${indexName}`}
                idComponent="inputChannel"
                component={Select}
                label="Channel"
                disabled={disabled}
                dataOption={listElearningChannel}
                defaultItemName="-Select-"
                filterOption={true}
              />
              <Field
                name={`categoryId${indexName}`}
                idComponent="category"
                component={Select}
                label="Category"
                dataOption={listCategoryBank}
                disabled={disabled}
                filterOption={true}
                defaultItemName="-Select-"
              />
              <Field
                name={`title${indexName}`}
                component={InputText}
                idComponent="inputQuestionTitle"
                className="inputQuestionForm"
                placeholder={'Type Here'}
                label={'Question Title'}
                textArea={true}
                disabled={disabled}
              />
            </div>
            <div>
              <Field
                name={`point${indexName}`}
                label="Point"
                component={InputTextNumber}
                idComponent="examPoint"
                placeholder="0"
                disabled={disabled}
                min={1}
              />
            </div>
          </div>
          <div>
            <div className="statusQuestion">Answer List</div>
            <Alert message="Pilih salah satu untuk jawaban yang benar" banner />
            <Field
              name={`answer${indexName}`}
              component={RadioButton}
              layout="vertical"
              disabled={disabled}
              className="multipleRadioButton"
              dataOption={dataAnswer}
            />
          </div>
        </Skeleton>
      </React.Fragment>
    );
  };
  const contentComponent = (formProps: any) => {
    const { values } = formProps;
    const fieldNameForm = [
      'channelId',
      'categoryId',
      'title',
      'point',
      'answer',
      'answerOption',
    ];
    const handleDeleteQuestion = (index: number) => {
      setTotalQuestion(prev => prev - 1);
      for (let j = 1; j < totalQuestion; j++) {
        for (let i = 0; i < fieldNameForm.length; i++) {
          values[`${fieldNameForm[i]}${index + (j - 1)}`] =
            values[`${fieldNameForm[i]}${index + j}`];
          delete values[`${fieldNameForm[i]}${index + j}`];
        }
      }
    };

    const renderFormQuestionBankUpload = () => {
      const formData: any = [];
      for (let index = 0; index < totalQuestion; index++) {
        const cardQuestionForm = () => {
          if (index !== 0 && totalQuestion === index + 1) {
            return <CIcon type="DeleteOutlined" />;
          }
          return null;
        };
        const cardQuestionTitle = () => {
          return (
            <div>
              <span>{`Question ${index + 1}`}</span>
              {index !== 0 ? (
                <Button
                  className={'btnDeleteQuestionForm'}
                  icon={<CIcon type="DeleteOutlined" />}
                  id={`btnDeleteQuestionForm${index}`}
                  onClick={() => handleDeleteQuestion(index)}
                  loading={isLoading}
                  danger
                >
                  Delete
                </Button>
              ) : null}
            </div>
          );
        };
        formData.push(
          <CCard
            cardClassName={'cardTitleQuestionForm'}
            key={`card-${index}`}
            cardTitle={cardQuestionTitle()}
            cardContent={renderForm(index)}
            cardExtra={cardQuestionForm}
          />
        );
      }
      const buttonData = [
        {
          id: 'btnAddQuestionForm',
          icon: 'PlusOutlined',
          className: 'btnAddQuestionForm',
          onClick: handleAddQuestion,
          content: 'Add New Question',
          disabled: totalQuestion >= 10,
        },
      ];
      return (
        <React.Fragment>
          <div>{formData}</div>
          <CButton buttonData={buttonData} />
          <div className="labelRequiredQuestion">
            <span className="labelRequired">*</span>
            <span>Max. 10 Question</span>
          </div>
        </React.Fragment>
      );
    };
    if (questionBankModalAction === 'register') {
      return <React.Fragment>{renderFormQuestionBankUpload()}</React.Fragment>;
    } else {
      const cardTitle = () => {
        return <span>{`Question`}</span>;
      };
      return (
        <React.Fragment>
          <CCard
            cardClassName={'cardTitleQuestionForm'}
            cardTitle={cardTitle()}
            cardContent={renderForm(0)}
          />
        </React.Fragment>
      );
    }
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (propsFooter: any) => {
    const { invalid } = propsFooter;
    return (
      <Button
        loading={isLoading}
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        icon={<CIcon type="CheckOutlined" />}
        disabled={
          questionBankModalAction === 'update' && !editMode ? false : invalid
        }
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(questionBankModalAction, editMode)}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={(values: any) =>
        validate(
          values,
          questionBankModalAction,
          totalQuestion,
          questionBankModalAction === 'update' && !editMode
        )
      }
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

import { FormApi } from 'final-form';
import React from 'react';
import SubmoduleModalExamComponent from '../../Component/Modal/SubmoduleModalExamComponent';

interface IProps {
  disabled: boolean;
  modalAction: string;
  submodule: any;
  selectedData: any;
  form?: FormApi<any, Partial<any>>;
  formError?: any;
  formValues?: any;
  editMode?: boolean;
}

export default function SubmoduleModalExamContainer(props: IProps) {
  const { form, editMode } = props;

  const handleAddtionalChangeTypeExam = (e: any) => {
    if (e.target.value === 'Quiz') form?.change('exam.maxAttempt', null);
  };
  const handleResetExamValue = () => {
    form?.change('exam', undefined);
  };

  return (
    <SubmoduleModalExamComponent
      handleAddtionalChangeTypeExam={handleAddtionalChangeTypeExam}
      handleResetExamValue={handleResetExamValue}
      editMode={editMode}
      {...props}
    />
  );
}

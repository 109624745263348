import moment from 'moment';

export const convertObjectToArray = (obj, secondName: any = null) => {
  const listKey = Object.keys(obj);
  const listValue = Object.values(obj);
  const listObj: any = [];
  listValue.forEach((element, index) => {
    const data: any = element;
    data.name = listKey[index];
    data.secondName = secondName;
    listObj.push(data);
  });
  return listObj;
};

export const createListSelectBox = (listEnum, enumValue: any = null) => {
  const listSelect: any = [];
  if (enumValue === null) {
    for (const iterator of listEnum) {
      if (Object.keys(iterator).length === 2 && iterator.text) {
        if (iterator.value !== '') {
          listSelect.push({ id: iterator.value, name: iterator.text });
        }
      } else {
        listSelect.push({ id: iterator, name: iterator });
      }
    }
  } else {
    listEnum.forEach((element, index) => {
      listSelect.push({ id: enumValue[index], name: element });
    });
  }
  return listSelect;
};
export function convertBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => resolve((reader.result as string).split(',')[1]);
  });
}

export const trimPhoneNumber = phone => {
  const trimPhone = phone.replace('+62', '');
  const firstPhone = trimPhone.substring(0, 2);
  const fourDigitNumber = trimPhone.substring(0, 4);
  if (fourDigitNumber === '6208') {
    return trimPhone.slice(3);
  } else if (firstPhone === '62') {
    return trimPhone.slice(2);
  } else if (firstPhone === '08') {
    return trimPhone.slice(1);
  }
  return trimPhone;
};

export const downloadFile = response => {
  const base64 = `data:${response.properties.contenttype};base64,${response.content}`;
  fetch(`${base64}`)
    .then(res => res.blob())
    .then(conversionResult => {
      const url = window.URL.createObjectURL(conversionResult);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', response.originalFileName);
      link.click();
    });
};

export function formatBytes(bytes) {
  const marker = 1024; // Change to 1000 if required
  const decimal = 3; // Change as required
  const kiloBytes = marker; // One Kilobyte is 1024 bytes
  const megaBytes = marker * marker; // One MB is 1024 KB
  const gigaBytes = marker * marker * marker; // One GB is 1024 MB
  const teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return bytes + ' Bytes';
  // return KB if less than a MB
  else if (bytes < megaBytes)
    return (bytes / kiloBytes).toFixed(decimal) + ' KB';
  // return MB if less than a GB
  else if (bytes < gigaBytes)
    return (bytes / megaBytes).toFixed(decimal) + ' MB';
  // return GB if less than a TB
  else if (bytes < teraBytes)
    return (bytes / gigaBytes).toFixed(decimal) + ' GB';
  else return (bytes / teraBytes).toFixed(decimal) + ' TB';
}

export function convertUrlToFilename(url) {
  let filename;
  if (url) {
    const splitedUrl = url.split('/');
    const orignalFilename = splitedUrl[splitedUrl.length - 1];
    const id = orignalFilename.split('-')[0];
    filename = orignalFilename.replace(`${id}-`, '');
  }
  return filename;
}

export const convertUrlToFilesize = url => {
  return new Promise(resolve => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'blob';
    xhr.onload = () => {
      resolve(xhr.response);
    };
    xhr.send();
  });
};

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

export function deleteUTCToGlobal(date: any) {
  let dataTemp = date;
  if (moment.isMoment(date)) {
    dataTemp = date.toISOString();
  }
  const splitDate = dataTemp.split('.');
  return splitDate[0].replace('T', ' ');
}

export function convertAgentLevel(level: string) {
  switch (level) {
    case 'A1':
      return 'FC';

    case 'A2':
      return 'AM';

    case 'A3':
      return 'SAM';

    case 'A4':
      return 'AD';

    default:
      return '-';
  }
}

export const groupObjectBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

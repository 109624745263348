import moment from 'moment';

export function generateLeadCandidate(
  formValue: any,
  documents: any,
  listRecruiter: any,
  listDirectLeader: any,
  listKeyDocuments: any,
  listValueDocuments: any
) {
  const x: any = {};
  // ADD LEAD
  const lead: any = {};
  const personaldata = formValue?.personaldata;
  lead.idNumber = personaldata?.profile?.nik;
  lead.name = personaldata?.profile?.fullname;
  lead.dateOfBirth = new Date(personaldata?.profile?.dob);
  lead.placeOfBirth = personaldata?.profile?.pob;
  lead.bloodType = null;

  const candidateAge = moment(personaldata.profile.dob, 'YYYYMMDD')
    .fromNow()
    .split(' ');
  lead.age =
    candidateAge[1] === 'days' ||
    candidateAge[1] === 'day' ||
    candidateAge[1] === 'months' ||
    candidateAge[1] === 'month'
      ? 0
      : candidateAge?.[0] === 'a' && candidateAge?.[1] === 'year'
      ? 1
      : Number(candidateAge[0]);
  lead.gender = personaldata.profile.gender;
  lead.occupation = null;
  lead.nationality = null;
  lead.address = personaldata.addresseducation.idcardaddress[0];
  lead.additionalAddress = personaldata.addresseducation.idcardaddress[0];
  lead.region = personaldata.addresseducation.province.split('|')[0];
  lead.city = personaldata.addresseducation.city.split('|')[0];
  lead.subDistrict = personaldata.addresseducation.district.split('|')[0];
  lead.village = personaldata.addresseducation.subdistrict.split('|')[0];
  lead.expiryDate = null;
  lead.religion = personaldata.profile.religion.split('|')[0];
  lead.maritalStatus = personaldata.profile.maritalstatus.split('|')[0];
  lead.refAgentCode = personaldata.agency.recruiter.split('|')[1];
  lead.refAgentName = personaldata.agency.recruiter.split('|')[0];
  lead.refAgencyName = personaldata.agency.agency.split('|')[0];
  lead.refAgencyCode = personaldata.agency.agency.split('|')[1];
  lead.mobileId = null;
  lead.enthusiasm = 0;
  lead.scoring = 0;
  const contacts: any = [];
  contacts.push({
    contactType: 'MobilePhone',
    contact: personaldata.profile.mobilephone,
  });
  contacts.push({
    contactType: 'PersonalEmail',
    contact: personaldata.profile.email,
  });
  lead.leadContacts = contacts;
  const listDocuments: any = [];
  listValueDocuments.forEach((element: any, index) => {
    const documentKey = listKeyDocuments[index];
    listDocuments.push({
      documentType:
        documentKey === 'idcard'
          ? 'NationalId'
          : documentKey === 'photo'
          ? 'PersonalPhoto'
          : 'Others',
      name: element.originalFileName,
      mimeType: element.properties?.mime,
      fileName: element.originalFileName,
      size: element.properties?.size,
      extension: element.properties?.extension,
      fileDocument: element.content?.split(',')?.[1],
    });
  });
  lead.leadDocuments = listDocuments;
  x.addLead = lead;

  // Promote
  const promote: any = {};
  promote.candidateLevel = personaldata.agency.candidateLevel;
  promote.origin = 'Manual';
  promote.salesOfficeCode = personaldata.agency.salesOffice.split('|')[1];
  promote.salesOfficeDesc = personaldata.agency.salesOffice.split('|')[0];
  const approvers: any = [];
  const recruiterCode = personaldata.agency.recruiter.split('|')[1];
  const directLeaderCode = personaldata.agency.directLeader
    ? personaldata.agency.directLeader?.split('|')[1]
    : null;
  for (const iterator of listRecruiter) {
    if (iterator.agentCode === recruiterCode) {
      approvers.push({
        approverLevel:
          iterator.agentLevel === 'A1'
            ? 'FC'
            : iterator.agentLevel === 'A2'
            ? 'AM'
            : iterator.agentLevel === 'A3'
            ? 'SAM'
            : 'AD',
        approverRole: 'RECRUITER',
        code: iterator.agentCode,
        name: iterator.agentName,
        email: iterator.agentEmail,
        agencyCode: iterator.agencyCode,
      });
    }
  }
  for (const iterator of listDirectLeader) {
    if (iterator.agentCode === directLeaderCode) {
      approvers.push({
        approverLevel: iterator.descriptions,
        approverRole: 'DL',
        code: iterator.agentCode,
        name: iterator.agentName,
        email: iterator.agentEmail,
        agencyCode: iterator.agencyCode,
      });
    }
  }
  promote.candidateApprovers = approvers;

  x.promoteLead = promote;

  return x;
}

export function generateSubmissionAddForm(
  formValue: any,
  listbeneficiary: any,
  listworkexperience: any
) {
  const convertDob = formValue?.personaldata?.profile?.dob
    ?.startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');
  for (const iterator of listbeneficiary) {
    delete iterator.id;
  }
  for (const iterator of listworkexperience) {
    delete iterator.id;
  }
  const x: any = formValue;

  for (let index = 0; index < 3; index++) {
    if (
      !formValue.personaldata.addresseducation.domicileaddress[index] ||
      formValue.personaldata.addresseducation.domicileaddress[index] ===
        undefined ||
      formValue.personaldata.addresseducation.domicileaddress[index] === null
    ) {
      x.personaldata.addresseducation.domicileaddress[index] = '';
    }
  }

  for (let index = 0; index < 3; index++) {
    if (
      !formValue.personaldata.addresseducation.idcardaddress[index] ||
      formValue.personaldata.addresseducation.idcardaddress[index] ===
        undefined ||
      formValue.personaldata.addresseducation.idcardaddress[index] === null
    ) {
      x.personaldata.addresseducation.idcardaddress[index] = '';
    }
  }

  const profileData = formValue.personaldata.profile;
  if (profileData.dob) {
    x.personaldata.profile.dob = convertDob;
  }
  if (profileData.mobilephone.substr(0, 3) !== '+62') {
    x.personaldata.profile.mobilephone = `+62${formValue.personaldata.profile.mobilephone}`;
  }
  if (profileData.homephone) {
    if (profileData.homephone.substr(0, 3) !== '+62') {
      x.personaldata.profile.homephone = `+62${formValue.personaldata.profile.homephone}`;
    }
  } else {
    x.personaldata.profile.homephone = '';
  }
  if (profileData.workphone) {
    if (profileData.workphone.substr(0, 3) !== '+62') {
      x.personaldata.profile.workphone = `+62${formValue.personaldata.profile.workphone}`;
    }
  } else {
    x.personaldata.profile.workphone = '';
  }
  if (!formValue.personaldata.addresseducation.postalcode) {
    x.personaldata.addresseducation.postalcode = '';
  }
  if (!formValue.personaldata.addresseducation.postalcodedomicile) {
    x.personaldata.addresseducation.postalcodedomicile = '';
  }
  if (!formValue.personaldata.taxbankaccount) {
    x.personaldata.taxbankaccount = {};
  }
  if (!formValue.personaldata.taxbankaccount.bank) {
    x.personaldata.taxbankaccount.bank = {};
  }
  if (!formValue.personaldata.taxbankaccount.tax) {
    x.personaldata.taxbankaccount.tax = {};
  }
  if (!formValue.personaldata.taxbankaccount.hastax) {
    x.personaldata.taxbankaccount.hastax = false;
  }
  if (!formValue.personaldata.taxbankaccount.bank.branch) {
    x.personaldata.taxbankaccount.bank.branch = '';
  }
  if (!formValue.personaldata.taxbankaccount.bank.accountnumber) {
    x.personaldata.taxbankaccount.bank.accountnumber = '';
  }
  if (!formValue.personaldata.taxbankaccount.bank.accountholder) {
    x.personaldata.taxbankaccount.bank.accountholder = '';
  }
  if (!formValue.personaldata.taxbankaccount.bank.name) {
    x.personaldata.taxbankaccount.bank.name = '';
  }
  if (!formValue.personaldata.taxbankaccount.tax.taxnumber) {
    x.personaldata.taxbankaccount.tax.taxnumber =
      formValue.personaldata.profile.nik;
  }
  if (!formValue.personaldata.taxbankaccount.tax.taxpayer) {
    x.personaldata.taxbankaccount.tax.taxpayer = '';
  }
  if (!formValue.personaldata.taxbankaccount.tax.taxownership) {
    x.personaldata.taxbankaccount.tax.taxownership = '';
  }
  if (!formValue.personaldata.taxbankaccount.tax.taxdependents) {
    x.personaldata.taxbankaccount.tax.taxdependents = '';
  }
  x.beneficiary = {
    form: null,
    list: {
      values: [],
    },
  };
  if (listbeneficiary.length > 0) {
    x.beneficiary.list.values = listbeneficiary;
  }
  if (!formValue.workexperience?.questionnaire?.question1[0]) {
    x.workexperience.questionnaire.question1[1] = '';
    x.workexperience.questionnaire.question1[2] = '';
    x.workexperience.questionnaire.questionnairefilled = true;
  }
  if (!formValue.workexperience?.questionnaire?.question2[0]) {
    x.workexperience.questionnaire.question2[1] = '';
  }
  if (!formValue.workexperience?.questionnaire?.question3[0]) {
    x.workexperience.questionnaire.question3[1] = '';
  }
  if (!formValue.workexperience?.questionnaire?.question3[0]) {
    x.workexperience.questionnaire.question3[1] = '';
  }
  if (!formValue.workexperience.list) {
    x.workexperience.form = null;
    x.workexperience.list = { values: [] };
  }
  if (listworkexperience.length > 0) {
    x.workexperience.list.values = listworkexperience;
  }
  delete x.domicileaddress;
  x.useragreements = {
    list: {
      application: true,
      appointmentletter: true,
      tncagreement: true,
      candidatestatement: true,
      agencyagreement: true,
      tncagreementleader:
        formValue.personaldata.agency.candidateLevel === 'FC' ? false : true,
      signature: '',
    },
  };
  x.useragreements.formapplication = {
    agreement: true,
  };
  x.useragreements.formappointmentletter = {
    agreement: true,
  };
  x.useragreements.formtncagreement = {
    agreement: true,
  };
  x.useragreements.formcandidatestatement = {
    agreement: true,
  };
  x.useragreements.formagencyagreement = {
    agreement: true,
  };
  x.useragreements.formtncagreementleader = {
    agreement:
      formValue.personaldata.agency.candidateLevel === 'FC' ? false : true,
  };
  const payloadSubmission = {
    personaldata: x.personaldata,
    beneficiary: x.beneficiary,
    workexperience: x.workexperience,
    documents: x.documents,
    useragreements: x.useragreements,
  };
  return payloadSubmission;
}

export function generateSubmissionEditForm(formValue: any) {
  const taxbankaccountData = formValue?.personaldata?.taxbankaccount;
  const convertDob = moment(formValue?.personaldata?.profile?.dob)
    .startOf('day')
    .format('YYYY-MM-DDTHH:mm:ss');
  const payloadSubmissionEdit = {
    ...formValue,
    personaldata: {
      ...formValue?.personaldata,
      profile: {
        ...formValue?.personaldata.profile,
        dob: convertDob,
      },
      taxbankaccount: {
        ...formValue?.personaldata?.taxbankaccount,
        bank: {
          branch: taxbankaccountData?.bank?.branch || '',
          accountnumber: taxbankaccountData?.bank?.accountnumber || '',
          accountholder: taxbankaccountData?.bank?.accountholder || '',
          name: taxbankaccountData?.bank?.name || '',
        },
        tax: {
          taxnumber: formValue.personaldata.profile.nik || '',
          taxpayer: taxbankaccountData?.tax?.taxpayer || '',
          taxownership: taxbankaccountData?.tax?.taxownership || '',
          taxdependents: taxbankaccountData?.tax?.taxdependents || '',
        },
      },
    },
  };
  return payloadSubmissionEdit;
}

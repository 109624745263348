const DisclaimerContentValidation = ({ name, desc }) => {
  const errors: any = {};
  if (!name) {
    errors.name = 'Disclaimer name required!';
  } else if (name && !/^[^\s].*/.test(name)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  }
  if (!desc) {
    errors.desc = 'Disclaimer content required!';
  } else if (desc && !/^[^\s].*/.test(desc)) {
    errors.desc = 'Use letters/numbers at the beginning of words!';
  }
  return errors;
};
export default DisclaimerContentValidation;

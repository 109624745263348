import React from 'react';
import ModalDetailExamScheduleComponent from '../Component/ModalDetailExamScheduleComponent';

interface IProps {
  detailExamSchedule: any;
  modalAction: string;
  showModal: boolean;
  setShowModalDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalList: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDetail: React.Dispatch<any>;
}

export default function ModalDetailExamScheduleContainer(props: IProps) {
  const {
    detailExamSchedule,
    modalAction,
    showModal,
    setShowModalDetail,
    setShowModalList,
    setSelectedDetail
  } = props;

  const handleCancel = () => {
    setShowModalDetail(false);
    setSelectedDetail(undefined);
    if (modalAction === 'month') setShowModalList(true);
  };

  return (
    <ModalDetailExamScheduleComponent
      detailExamSchedule={detailExamSchedule}
      handleCancel={handleCancel}
      modalIsShow={showModal}
    />
  );
}

import { Button, Col, Empty, Row, Form as FormAntd } from 'antd';
import moment from 'moment';
import React from 'react';
import { Field, Form } from 'react-final-form';
import CCard from '../../../Assets/Components/CCard';
import CIcon from '../../../Assets/Components/CIcon';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import Select from '../../../Assets/Components/Select';
import {
  classCompletionStatus,
  classResult,
  classScheduleType,
  formatReport,
  formatReportFeedback,
  learningReportType,
} from '../../../App/Enums';
import { FormApi } from 'final-form';
import learningReportValidation from '../Validation/learningReportValidation';
import { IResultClassNonSelfListDAO, IResultModuleListDAO } from '../DAO/learningReport.dao';
import { IResultChannelNonSelfTrainingDAO } from '../../ElearningNonSelfTraining/Channel/DAO/channelNonSelfTraining.dao';

interface IProps {
  columnData: any;
  channelList: any[];
  channelNonSelfList: IResultChannelNonSelfTrainingDAO[];
  classList: IResultClassNonSelfListDAO[];
  moduleList: IResultModuleListDAO[];
  learningReportList: any;
  handleSubmit: () => void;
  handleOnChangeChannelWithModuleFilter: (
    e: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleOnChangeChannelWithClassFilter: (
    e: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleSearch: (
    form: FormApi<any, Partial<any>>,
    values: any,
    type: 'main' | 'filter'
  ) => void;
  handleResetFilter: (form: FormApi<any, Partial<any>>) => void;
  handleDownloadLearningReport: (reportType: string, formValues?: any) => void;
  handleOnChangeReportType: (
    form: FormApi<any, Partial<any>>,
    reportType: string
  ) => void;
  handleOnChangeDownloadFile: (e: string) => void;
  isFilter: boolean;
  isLoading: boolean;
  isLoadingDownload: boolean;
  isLoadingFilter: boolean;
  renderRowSubComponentJourney: any;
  renderRowSubComponentProgress: any;
  renderRowSubComponentExam: any;
  renderRowSubComponentFeedback: any;
  renderRowSubComponentReportPin: any;
  renderRowSubComponentTrainingAttendance: any;
}

export default function LearningReportComponent(props: IProps) {
  const {
    channelList,
    channelNonSelfList,
    classList,
    columnData,
    handleOnChangeReportType,
    handleDownloadLearningReport,
    handleOnChangeChannelWithClassFilter,
    handleOnChangeChannelWithModuleFilter,
    handleOnChangeDownloadFile,
    handleResetFilter,
    handleSearch,
    handleSubmit,
    isFilter,
    isLoading,
    isLoadingDownload,
    isLoadingFilter,
    learningReportList,
    moduleList,
    renderRowSubComponentExam,
    renderRowSubComponentFeedback,
    renderRowSubComponentJourney,
    renderRowSubComponentTrainingAttendance,
    renderRowSubComponentProgress,
    renderRowSubComponentReportPin,
  } = props;

  const renderFilterLearningReportResult = (
    form: FormApi<any, Partial<any>>,
    formValues: any
  ) => {
    const learningReportTypeForm = formValues
      ? formValues?.learningReportType
      : null;
    const formValueReportType = formValues ? formValues.formatReport : null;
    const renderRowSubComponent =
      learningReportTypeForm === 'journey'
        ? renderRowSubComponentJourney
        : learningReportTypeForm === 'progress'
        ? renderRowSubComponentProgress
        : learningReportTypeForm === 'exam'
        ? renderRowSubComponentExam
        : learningReportTypeForm === 'selfLearning' ||
          learningReportTypeForm === 'nonSelfLearning'
        ? renderRowSubComponentFeedback
        : learningReportTypeForm === 'sessionPin'
        ? renderRowSubComponentReportPin
        : learningReportTypeForm === 'trainingAttendance'
        ? renderRowSubComponentTrainingAttendance
        : null;

    return learningReportList && isFilter ? (
      <>
        <Table
          columns={
            learningReportTypeForm === 'learningHour'
              ? generateColumnData(columnData(learningReportTypeForm))
              : columnData(learningReportTypeForm)
          }
          data={learningReportList}
          pagination={true}
          useFilterGlobal={true}
          renderRowSubComponent={renderRowSubComponent}
          isLoading={isLoading}
        />
        <Row gutter={20} className="rowDownloadReport">
          <Col span={24}>
            <Field
              name="formatReport"
              component={Select}
              idComponent="inputLearningReportFormat"
              dataOption={
                learningReportTypeForm === 'selfLearning' ||
                learningReportTypeForm === 'nonSelfLearning'
                  ? formatReportFeedback
                  : formatReport
              }
              label={'Download Report'}
              defaultItemName="Select format report"
              className="selectReportType"
              onSelect={(e: string) => handleOnChangeDownloadFile(e)}
            />
          </Col>
          <Col span={24}>
            <Button
              onClick={() =>
                handleDownloadLearningReport(learningReportTypeForm)
              }
              icon={<CIcon type="DownloadOutlined" />}
              type="primary"
              disabled={!formValueReportType}
              loading={isLoading || isLoadingDownload}
              id="btnDownloadLearningReport"
            >
              Download as file
            </Button>
          </Col>
        </Row>
      </>
    ) : (
      <Empty style={{ margin: 'auto' }} />
    );
  };

  const cardFilterLearningReportContent = () => {
    return (
      <div className="containerWhiteZurich">
        <Form
          onSubmit={handleSubmit}
          validate={learningReportValidation}
          keepDirtyOnReinitialize={true}
        >
          {formProps => {
            const {
              form,
              handleSubmit: handleSubmitForm,
              values,
              invalid,
            } = formProps;
            const disabledFilter = isLoadingFilter || !values?.channelFilter;
            const learningReportTypeForm = values?.learningReportType;
            const disabledDate = d =>
              learningReportTypeForm !== 'sessionPin'
                ? !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                : false;

            return (
              <FormAntd
                values={values}
                onFinish={handleSubmitForm}
                layout={'vertical'}
              >
                <Row gutter={22}>
                  <Col span={6}>
                    <Field
                      name="learningReportType"
                      component={Select}
                      idComponent="inputLearningReportType"
                      dataOption={learningReportType}
                      defaultItemName="-Select-"
                      label={'Report Type'}
                      onSelect={(e: string) =>
                        handleOnChangeReportType(form, e)
                      }
                    />
                  </Col>
                  <Col span={8}>
                    <Field
                      name="dateFilter"
                      component={InputDatePicker}
                      idComponent="inputDateFilter"
                      label="Date"
                      typeDate="dateRangePicker"
                      disabled={learningReportTypeForm === 'learningHour'}
                      disabledDate={disabledDate}
                      placeholder={['Start Date', 'End Date']}
                    />
                  </Col>
                  <Col span={10}>
                    {learningReportTypeForm === 'learningHour' ||
                    learningReportTypeForm === 'trainingAttendance' ? (
                      <Button
                        type="primary"
                        icon={<CIcon type="DownloadOutlined" />}
                        onClick={() =>
                          handleDownloadLearningReport(
                            learningReportTypeForm,
                            values
                          )
                        }
                        loading={isLoading || isLoadingDownload}
                        id="btnSearch"
                        className="btnCari"
                        disabled={invalid}
                      >
                        {isLoading || isLoadingDownload
                          ? 'Mendownload..'
                          : 'Download'}
                      </Button>
                    ) : (
                      <Button
                        type="primary"
                        icon={<CIcon type="SearchOutlined" />}
                        onClick={() => handleSearch(form, values, 'main')}
                        loading={isLoading}
                        id="btnSearch"
                        className="btnCari"
                        disabled={invalid}
                      >
                        Cari
                      </Button>
                    )}
                    <Button
                      onClick={() => handleResetFilter(form)}
                      loading={isLoading}
                      id="btnReset"
                      className="btnResetReport"
                      disabled={invalid}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>

                {learningReportList &&
                isFilter &&
                learningReportTypeForm !== 'sessionPin' &&
                learningReportTypeForm !== 'trainingAttendance' &&
                learningReportTypeForm !== 'learningHour' ? (
                  <Row gutter={20}>
                    <Col span={4}>
                      <Field
                        name="channelFilter"
                        component={Select}
                        idComponent="inputChannelFilter"
                        defaultItemName="Semua"
                        label={'Channel'}
                        dataOption={channelList}
                        onSelect={(e: any) =>
                          handleOnChangeChannelWithModuleFilter(e, form)
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="moduleFilter"
                        component={Select}
                        idComponent="inputModuleFilter"
                        defaultItemName="Semua"
                        label={'Module'}
                        dataOption={moduleList}
                        disabled={disabledFilter}
                        loading={
                          values?.channelFilter ? isLoadingFilter : false
                        }
                      />
                    </Col>
                    <Button
                      type="primary"
                      icon={<CIcon type="SearchOutlined" />}
                      onClick={() => handleSearch(form, values, 'filter')}
                      id="btnSearchChannelModule"
                      className="btnCari"
                      disabled={disabledFilter}
                      loading={values?.channelFilter ? isLoadingFilter : false}
                    >
                      Cari
                    </Button>
                  </Row>
                ) : null}

                {learningReportList &&
                isFilter &&
                learningReportTypeForm === 'sessionPin' ? (
                  <Row gutter={20}>
                    <Col span={4}>
                      <Field
                        name="typeScheduleFilter"
                        component={Select}
                        idComponent="inputTypeScheduleFilter"
                        defaultItemName="Semua"
                        label={'Tipe schedule'}
                        dataOption={classScheduleType}
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="channelIdFilter"
                        component={Select}
                        idComponent="inputChannelFilter"
                        defaultItemName="Semua"
                        label={'Channel'}
                        dataOption={channelNonSelfList || []}
                        loading={isLoading}
                        onSelect={(e: any) =>
                          handleOnChangeChannelWithClassFilter(e, form)
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="classFilter"
                        component={Select}
                        idComponent="inputClassFilter"
                        defaultItemName="Semua"
                        label={'Nama Kelas'}
                        dataOption={classList}
                        disabled={isLoadingFilter || !values?.channelIdFilter}
                        loading={isLoadingFilter}
                      />
                    </Col>
                    <Button
                      type="primary"
                      icon={<CIcon type="SearchOutlined" />}
                      onClick={() => handleSearch(form, values, 'filter')}
                      loading={isLoading}
                      id="btnSearchChannelModule"
                      className="btnCari"
                    >
                      Cari
                    </Button>
                  </Row>
                ) : null}

                {learningReportTypeForm === 'trainingAttendance' ? (
                  <Row gutter={32}>
                    <Col span={4}>
                      <Field
                        name="statusFilter"
                        component={Select}
                        idComponent="inputStatusFilter"
                        defaultItemName="Semua"
                        label="Status"
                        dataOption={classCompletionStatus}
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="resultFilter"
                        component={Select}
                        idComponent="inputResultFilter"
                        defaultItemName="Semua"
                        label="Result"
                        dataOption={classResult}
                        loading={isLoading}
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="channelIdFilter"
                        component={Select}
                        idComponent="inputChannelFilter"
                        defaultItemName="Semua"
                        label="Channel"
                        dataOption={channelNonSelfList}
                        onSelect={(e: any) =>
                          handleOnChangeChannelWithClassFilter(e, form)
                        }
                      />
                    </Col>
                    <Col span={4}>
                      <Field
                        name="classFilter"
                        component={Select}
                        idComponent="inputClassFilter"
                        defaultItemName="Semua"
                        label={'Nama Kelas'}
                        dataOption={classList}
                        disabled={isLoadingFilter || !values?.channelIdFilter}
                        loading={isLoadingFilter}
                      />
                    </Col>
                  </Row>
                ) : null}
                {renderFilterLearningReportResult(form, values)}
              </FormAntd>
            );
          }}
        </Form>
      </div>
    );
  };

  return (
    <>
      <CLabelCard leftText="Learning Report" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Report Data</span>}
        cardContent={cardFilterLearningReportContent()}
      />
    </>
  );
}

import React from 'react';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import history from './History';
import Store from '../Store';

export default function ErrorHandler(error: any, customError?: boolean) {
  const responseStatus = error?.response?.status;
  const errorData = error?.response?.data;
  const authState: any = Store.getState();
  if (error?.response) {
    if (responseStatus === 500) {
      if (
        errorData?.Message === 'Bad Ldap Binding Credential' ||
        errorData?.ErrorCode === '5002'
      ) {
        toast.error(
          <span className="capitalFirst">
            <FormattedMessage id={'badCredential'} />
          </span>
        );
      } else if (
        errorData?.ErrorCode === '5100' ||
        errorData?.ErrorCode === '5110'
      ) {
        toast.error(
          <span className="capitalFirst">
            <FormattedMessage id={'sessionExpired'} />
          </span>
        );
        authState.logOut();
        history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      } else if (
        errorData?.MessageKey === 'stage.validation.exception' ||
        errorData?.MessageKey === 'module.validation.exception' ||
        errorData?.MessageKey === 'submodule.validation.exception' ||
        errorData?.MessageKey === 'content.slide.validation.exception'
      ) {
        toast.error(
          <span className="capitalFirst">
            <FormattedMessage id={errorData?.Message} />
          </span>
        );
      } else {
        if (!customError)
          toast.error(
            <span className="capitalFirst">
              <FormattedMessage
                id={errorData?.MessageKey || errorData?.statusText}
              />
            </span>
          );
      }
    } else if (responseStatus === 400) {
      toast.error(errorData?.data[0]?.message || errorData?.message);
    } else if (responseStatus === 403) {
      toast.error(
        <span className="capitalFirst">
          <FormattedMessage id={'forbiddenError'} />
        </span>
      );
    } else {
      try {
        const dataErr = errorData?.Data?.Failures[0]?.ErrorKey;
        if (dataErr) {
          error(
            <span className="capitalFirst">
              <FormattedMessage id={dataErr} />
            </span>
          );
        }
      } catch (err) {
        error(
          <span className="capitalFirst">
            <FormattedMessage id={'unknownError'} />
          </span>
        );
      }
    }
  } else {
    toast.error(
      <span className="capitalFirst">
        <FormattedMessage id={'unknownError'} />
      </span>
    );
  }
}

import React from 'react';
import CityHistoryDetailModalComponent from '../Component/CityHistoryDetailModalComponent';

interface IProps {
  selectedData: any;
  modalInfoIsShow: boolean;
  setModalInfoIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function CityHistoryDetailModalContainer(props: IProps) {
  const { modalInfoIsShow, setModalInfoIsShow, selectedData } = props;
  const handleCancel = () => {
    setModalInfoIsShow(false);
  };
  return (
    <CityHistoryDetailModalComponent
      selectedData={selectedData}
      modalInfoIsShow={modalInfoIsShow}
      handleCancel={handleCancel}
    />
  );
}

import { Tag } from 'antd';
import moment from 'moment';
import React from 'react';
import ModalNewAgentMonitoringComponent from '../Component/ModalNewAgentMonitoringComponent';
import { licenseAPI } from '../../../Services/license.api';
import { useQuery } from '@tanstack/react-query';
import { getCandidateFormDataAPI } from '../../../Services/newAgentMonitoring';
import { getCandidateDetailAPI } from '../../../Services/qaProcess.api';

interface IProps {
  modalIsShow: boolean;
  selectedData: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<any>;
}

export default function ModalNewAgentMonitoringContainer(props: IProps) {
  const { modalIsShow, selectedData, setModalIsShow, setSelectedData } = props;
  const formatDOB = 'DD-MMM-YYYY';

  const { data: agreementListData, isFetching: isFetchingAgreementList } =
    useQuery(
      ['GetDocumentAgreementAPI', selectedData?.agentLevel],
      licenseAPI.getDocumentAgreementData,
      {
        enabled: selectedData !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const { data: candidateFormData, isFetching: isFetchingCandidateForm } =
    useQuery(
      ['GetCandidateFormAPI', selectedData?.agentLevel],
      getCandidateFormDataAPI,
      {
        enabled: selectedData !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const { data: candidateGroupData, isFetching: isFetchingCandidateGroup } =
    useQuery(
      ['GetCandidateGroupAPI', selectedData?.caseId, selectedData?.taskId],
      licenseAPI.getCandidateGroupData,
      {
        enabled: selectedData !== undefined,
        refetchOnWindowFocus: false,
      }
    );

  const { data: candidateDetail } = useQuery(
    ['GetCandidateDetailAPI', selectedData?.code],
    getCandidateDetailAPI,
    {
      enabled: selectedData?.code !== undefined,
    }
  );
  const candidateApprovers = candidateDetail?.[0]?.candidateApprovers?.find(
    (val: any) => val.approverRole === 'RECRUITER'
  );

  const { data: contentListData, isFetching: isFetchingContentList } = useQuery(
    ['GetContentInquiryAPI', selectedData?.code],
    licenseAPI.getContentInquiryData,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
    }
  );
  const listFilenet: any = [];
  if (contentListData) {
    for (const iterator of contentListData) {
      const fileName = iterator.properties.filename.split('-')[0];
      listFilenet.push({
        id: `${iterator.properties.objectid}_${iterator.id}`,
        name: fileName,
      });
    }
  }

  const renderValue = (name: 'from' | 'to', row: any) => {
    const values = row.row.original.fromto;
    const newValues =
      name === 'from' ? values.split('-')[0] : values.split('-')[1];
    return <div>{newValues}</div>;
  };

  const column = {
    beneficiary: [
      {
        Header: 'Nama Lengkap',
        accessor: 'fullname',
      },
      {
        Header: 'Hubungan dengan Anda',
        accessor: 'relationship',
      },
      {
        Header: 'Pekerjaan',
        accessor: 'job',
      },
      {
        Header: 'Telepon Rumah',
        accessor: 'homephone',
      },
      {
        Header: 'Nomor HP',
        accessor: 'mobilephone',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
    ],
    workexperience: [
      {
        Header: 'Nama Perusahaan',
        accessor: 'companyname',
      },
      {
        Header: 'Bidang Usaha',
        accessor: 'businessfields',
      },
      {
        Header: 'Jabatan',
        accessor: 'position',
      },
      {
        Header: 'Dari',
        Cell: row => renderValue('from', row),
      },
      {
        Header: 'Sampai',
        Cell: row => renderValue('to', row),
      },
      {
        Header: 'Alasan Keluar',
        accessor: 'resigningreason',
      },
    ],
  };

  const tagStat = (
    tagStatus: string,
    color: string,
    fontColor: string,
    borderColor: number
  ) => {
    return (
      <Tag color={color} className={`tagColorProgress${borderColor}`}>
        <div style={{ color: fontColor, width: 150, textAlign: 'center' }}>
          {tagStatus}
        </div>
      </Tag>
    );
  };

  const renderProgress = (progress: string) => {
    switch (progress) {
      case 'Berlangsung': {
        return tagStat('On Progress', '#FFFFFF', '#009EE0', 1);
      }
      default: {
        return tagStat('Selesai', '#00000029', '#000000', 2);
      }
    }
  };

  const renderStatus = (status: string) => {
    return (
      <Tag color="#00000029">
        <div className={'fontColorAgentMonitoringTag'}>{status}</div>
      </Tag>
    );
  };

  const columnData = [
    {
      Header: 'Status',
      accessor: 'journeyStatusName',
      disableSort: true,
      Cell: (row: any) => renderStatus(row.row.original.journeyStatusName),
    },
    {
      Header: 'Diperoleh',
      accessor: (row: any) =>
        row.startTime ? moment(row.startTime).format(formatDOB) : '-',
      disableSort: true,
    },
    {
      Header: 'Progress',
      accessor: 'progress',
      disableSort: true,
      Cell: (row: any) => renderProgress(row.row.original.progress),
    },
    {
      Header: 'Approver',
      accessor: 'approverName',
      disableSort: true,
    },
  ];

  const handleCancel = () => {
    setModalIsShow(false);
    setSelectedData(undefined);
  };

  return (
    <ModalNewAgentMonitoringComponent
      candidateApprovers={candidateApprovers}
      column={column}
      columnData={columnData}
      detailCandidate={selectedData}
      documents={candidateGroupData?.documentContentData || []}
      handleCancel={handleCancel}
      isLoading={
        isFetchingAgreementList ||
        isFetchingCandidateForm ||
        isFetchingCandidateGroup ||
        isFetchingContentList
      }
      listAgreement={agreementListData || []}
      listContent={contentListData || []}
      listFilenet={listFilenet}
      listHistory={selectedData?.taskHistories || []}
      modalIsShow={modalIsShow}
      schema={candidateFormData}
      submission={candidateGroupData?.dataGroupDetail}
    />
  );
}

import instance from './interceptor';

export const getElearningProvinceAPI = async () => {
  const { data } = await instance.get(`/Elearning/Province`);
  return data;
};

export const getElearningCityAPI = async params => {
  const idProvince = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/City?provinceId=${idProvince}`
  );
  return data;
};

export const getElearningLocationDataAPI = async params => {
  const id = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/Location/Data?CodemiLocationId=${id}`
  );
  return data;
};

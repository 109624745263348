import CButton from '../../../../Assets/Components/CButton';
import Table, {
  generateColumnData,
} from '../../../../Assets/Components/CTable';
import React from 'react';

interface IProps {
  column: any;
  data: any[];
  handleUpload: () => void;
  handleRefresh: () => void;
  isLoading: boolean;
}

export default function LicenseTabUploadComponent(props: IProps) {
  const { column, data, handleUpload, isLoading, handleRefresh } = props;
  const buttonData = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2',
      icon: 'UploadOutlined',
      onClick: handleUpload,
      content: 'Unggah',
      id: 'btnPopupModalUpload',
    },
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshUploadLicenseList',
    },
  ];
  return (
    <div className="containerTabWhiteZurich">
      <CButton
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={isLoading}
      />
      <Table
        isLoading={isLoading}
        columns={generateColumnData(column)}
        data={data}
        pagination={true}
        useFilterGlobal={true}
      />
    </div>
  );
}

import React, { useEffect, useState } from 'react';
import {
  getChannelBankAPI,
  getTrainerDetailAPI,
} from '../../../Services/elearning.api';
import {
  getRoleAPI,
  mutateDeleteTrainerAPI,
  mutateMappingAuthUserRole,
  mutateRegisterTrainerAPI,
  searchLDAP,
} from '../../../Services/user.api';
import { useMutation, useQuery } from '@tanstack/react-query';

import HasPermission from '../../../App/HasPermission';
import ModalUserRoleComponent from '../Component/ModalAuthUserRoleComponent';
import { toast } from 'react-toastify';
import { useDebounce } from 'use-debounce';

interface IProps {
  intl: any;
  listUserRole: any[];
  refetchUserRole: () => void;
  selectedData: any;
  setModalIsShow: (modalIsShow: boolean) => void;
  setSelectedData: (selectedData: any) => void;
}

export default function ModalUserRoleContainer(props: IProps) {
  const {
    intl,
    listUserRole,
    refetchUserRole,
    selectedData,
    setModalIsShow,
    setSelectedData,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [searchUser, setSearchUser] = useState<any>();
  const [authUserList, setAuthUserList] = useState<any[]>([]);
  const [userLDAP] = useDebounce(searchUser, 1000);
  const initialValues: any = {};

  useEffect(() => {
    if (selectedData) {
      const { userId, username } = selectedData;
      setAuthUserList([{ id: userId, username }]);
    }
  }, [selectedData]);

  const hasPermissionModule = HasPermission('r', '/sec/rol');
  const { data: dataRole } = useQuery(['Get Role'], getRoleAPI, {
    enabled: hasPermissionModule,
    refetchOnWindowFocus: false,
  });

  const { data: dataUserLDAP } = useQuery(
    ['Get User LDAP', userLDAP],
    searchLDAP,
    {
      enabled: userLDAP !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataChannel } = useQuery(
    ['Get Channel Non Self', 'active'],
    getChannelBankAPI,
    {
      refetchOnWindowFocus: false,
    }
  );
  const trainerData = dataRole?.find(val => val.name === 'Trainer');

  const { data: dataTrainer } = useQuery(
    ['Get Trainer Detail', selectedData?.userId],
    getTrainerDetailAPI,
    {
      enabled:
        selectedData?.userId !== undefined &&
        selectedData?.userRoles[0]?.roleId === trainerData?.id,
      refetchOnWindowFocus: false,
    }
  );

  const mutateUserRole = useMutation(mutateMappingAuthUserRole, {
    onSuccess: () => {
      let toastMessage = (
        <span className="capitalFirst">
          {intl.formatMessage({
            id: selectedData
              ? 'updateMappingAuthUserRoleSuccess'
              : 'createMappingAuthUserRoleSuccess',
          })}
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
      refetchUserRole();
    },
  });

  const mutateDeleteTrainer = useMutation(mutateDeleteTrainerAPI);

  const mutateTrainer = useMutation(mutateRegisterTrainerAPI);

  useEffect(() => {
    if (dataUserLDAP !== undefined && dataUserLDAP.username) {
      const findUserRole = listUserRole.find((item: any) => {
        return (
          item.username.toLowerCase() === dataUserLDAP.username.toLowerCase()
        );
      });
      if (!findUserRole) {
        const tempUserList: any = [...authUserList];
        tempUserList.push(dataUserLDAP);
        const result = tempUserList.reduce((unique, o) => {
          if (
            !unique.some(obj => obj.id === o.id && obj.username === o.username)
          ) {
            unique.push(o);
          }
          return unique;
        }, []);
        setAuthUserList(result);
      }
    }
    // eslint-disable-next-line
  }, [dataUserLDAP]);

  const handleCancel = () => {
    setModalIsShow(false);
    setEditMode(false);
    setSelectedData(undefined);
  };

  const handleSubmit = (values: any) => {
    const { name, channel } = values;
    let isTrainer = false;
    if (trainerData && trainerData?.id === values.role) {
      isTrainer = true;
    }
    if (isTrainer) {
      mutateTrainer.mutate(
        { name, channel, trainerCode: authUserList[0].id },
        {
          onSuccess: () => {
            mutateUserRole.mutate(values);
          },
        }
      );
    } else {
      if (initialValues.role === trainerData.id) {
        mutateDeleteTrainer.mutate(selectedData?.userId);
      }
      mutateUserRole.mutate(values);
    }
  };

  if (selectedData && Object.keys(selectedData).length > 0) {
    const { userRoles, username } = selectedData;
    initialValues.role = userRoles[0].roleId;
    initialValues.userList = [username];
    if (dataTrainer) {
      initialValues.channel = parseInt(dataTrainer.channelCode);
      initialValues.name = dataTrainer.name;
    }
  }

  return (
    <ModalUserRoleComponent
      authRoleList={dataRole}
      authUserList={authUserList}
      dataChannel={dataChannel}
      editMode={editMode}
      handleCancel={handleCancel}
      handleSearch={setSearchUser}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      intl={intl}
      isLoading={mutateUserRole.isLoading || mutateTrainer.isLoading}
      modalAction={selectedData ? 'update' : 'register'}
      setEditMode={setEditMode}
    />
  );
}

import { Button, Col, Empty, Row, Skeleton } from 'antd';
import React from 'react';
import { formatTracker, trackerType } from '../../../App/Enums';
import CCard from '../../../Assets/Components/CCard';
import CIcon from '../../../Assets/Components/CIcon';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import Select from '../../../Assets/Components/Select';
import moment from 'moment';
import { Field, Form } from 'react-final-form';
import validation from '../Validation/TrackerValidation';
import { InputDate } from '../../../Assets/Components/InputDate';

interface IProps {
  isLoading: boolean;
  isDownloading: boolean;
  handleDownloadTracker: any;
  trackerList: any[];
  generateColumn: any;
  selectedType?: any;
  selectedFormat?: any;
  setSelectedFormat: React.Dispatch<React.SetStateAction<any>>;
  handleSubmit: (values: any) => void;
  emptyData: any;
  setEmptyData: any;
}

export default function TrackerComponent(props: IProps) {
  const {
    isLoading,
    isDownloading,
    trackerList,
    generateColumn,
    selectedType,
    setSelectedFormat,
    selectedFormat,
    handleDownloadTracker,
    handleSubmit,
    emptyData,
    setEmptyData,
  } = props;
  const tabContent = () => {
    if (
      trackerList &&
      trackerList.length > 0 &&
      selectedType !== null &&
      emptyData &&
      emptyData.length > 0
    ) {
      return (
        <Skeleton active loading={isLoading}>
          <React.Fragment>
            <Table
              isLoading={isLoading}
              columns={generateColumnData(generateColumn())}
              data={trackerList || []}
              pagination={true}
            />
            <Row gutter={24} className="rowDownloadReport">
              <Col span={6}>
                <label className="labelBtnFilter">Download Report Format</label>
                <Select
                  dataOption={formatTracker}
                  defaultItemName="Select format report"
                  idComponent="inputTrackerFormat"
                  meta={{ touched: false, error: false }}
                  input={{
                    onBlur: null,
                    onChange: setSelectedFormat,
                    value: selectedFormat
                      ? selectedFormat
                      : 'Select format report',
                  }}
                  className="btnFeedbackBank"
                />
              </Col>
              <Col span={24}>
                <Button
                  onClick={handleDownloadTracker}
                  icon={<CIcon type="DownloadOutlined" />}
                  type="primary"
                  disabled={!selectedFormat || selectedFormat === 'pdf'}
                  loading={isDownloading}
                  id="btnDownloadTRackerReport"
                  className="buttonTable"
                >
                  Download as file
                </Button>
              </Col>
            </Row>
          </React.Fragment>
        </Skeleton>
      );
    }
    return <Empty style={{ margin: 'auto' }} />;
  };
  const cardFilterTrackerContent = () => {
    const disabledDate = (d: any) =>
      !d || d.isAfter(moment().format('YYYY-MM-DD'));
    return (
      <div className="containerWhiteZurich">
        <Form onSubmit={handleSubmit} validate={validation}>
          {(props: any) => {
            const isDisabled =
              !props.values.dateFilter || !props.values.trackerType
                ? true
                : false;
            return (
              <form onSubmit={props.handleSubmit || props.form.submit}>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={8}>
                    <label className="labelBtnFilter">Tracker Type</label>
                    <Field
                      name="trackerType"
                      component={Select}
                      idComponent="inputTrackerType"
                      dataOption={trackerType}
                      defaultItemName="Select tracker type"
                      className="btnFeedbackBank"
                      onSelect={() => setEmptyData([])}
                    />
                  </Col>
                  <Col span={12}>
                    <label className="labelBtnFilter">Date Filter</label>
                    <Field
                      name="dateFilter"
                      component={InputDate}
                      idComponent="inputDateFilter"
                      typeDate="dateRangePicker"
                      disabledDate={disabledDate}
                      className="btnFeedbackBank"
                    />
                  </Col>
                  <Col span={4}>
                    <Button
                      type="primary"
                      icon={<CIcon type="SearchOutlined" />}
                      disabled={isDisabled}
                      htmlType="submit"
                      id="btnSearch"
                      style={{ marginTop: '30px' }}
                      loading={isLoading}
                    >
                      Search
                    </Button>
                  </Col>
                </Row>
              </form>
            );
          }}
        </Form>
        {tabContent()}
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Tracker" rightText="Data" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Tracker Report</span>}
        cardContent={cardFilterTrackerContent()}
      />
    </React.Fragment>
  );
}

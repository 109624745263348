import {
  IResultModuleListDAO,
  IResultPermissionRoleDetailDAO,
  IResultPermissionRoleListDAO,
} from '../../Modules/Permission/DAO/permission.dao';
import { UseQueryOptions } from '@tanstack/react-query';
import instance from '../interceptor';

export const getPermissionRoleAPI = async (): Promise<
  IResultPermissionRoleListDAO[]
> => {
  const { data } = await instance.get(`/role`);
  return data;
};

export const getModuleDataAPI = async (): Promise<IResultModuleListDAO[]> => {
  const { data } = await instance.get(`/module`);
  return data;
};

export const getUserByRoleIdAPI = async (
  params: UseQueryOptions
): Promise<IResultPermissionRoleDetailDAO> => {
  const id = params?.queryKey?.[1];
  const { data } = await instance.get(`/role/permission/${id}`);
  return data;
};

export const mutateRoleAndPermissionAPI = async (params: any) => {
  const { roleName, permissionSetting, roleId } = params;
  let currentId = roleId;

  // ROLE API
  if (!currentId) {
    const { data: roleId } = await instance.post(`/role`, { name: roleName });
    currentId = roleId;
  } else {
    await instance.put(`/role`, { id: currentId, name: roleName });
  }

  if (!permissionSetting || Object.keys(permissionSetting).length === 0) return;

  const rolePermissions: any[] = [];
  for (const menuName in permissionSetting) {
    let tempPermissions = {};
    const permissions = permissionSetting[menuName];
    for (const permission in permissions) {
      tempPermissions[permission] = permissions[permission] ? 1 : 0;
    }
    rolePermissions.push({
      moduleCode: menuName.replace(/\//g, '.'),
      permission: tempPermissions,
    });
  }

  const payload = { roleId: currentId, rolePermissions };
  // PERMISSION API
  const { data } = await instance.put(`/role/permission`, payload);

  return data;
};

import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Switch } from 'antd';
import {
  deleteNonSelfTrainingChannelAPI,
  getNonSelfTrainingChannelAPI,
  mutateNonSelfTrainingChannelAPI,
} from '../../../../Services/channel.api';

import moment from 'moment';
import React, { useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import ChannelComponent from '../Component/ChannelComponent';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  token?: any;
  selectedStatus?: string;
  selectedData?: any;
  setTabData: (val: any) => void;
}

export default function ChannelContainer({ setTabData }: IProps) {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [filter, setFilter] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  const {
    data: dataChannel,
    isLoading: isLoadingChannel,
    isRefetching: isRefetchChannel,
    refetch,
  } = useQuery(
    ['getNonSelfTrainingChannelAPI', filter],
    getNonSelfTrainingChannelAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteChannel = useMutation(
    (vals: any) => {
      return deleteNonSelfTrainingChannelAPI(vals);
    },
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Channel Berhasil Dihapus</span>
        );
        refetch();
      },
    }
  );

  const mutateChannel = useMutation(mutateNonSelfTrainingChannelAPI, {
    onSuccess: () => {
      setModalIsShow(false);
      refetch();
      toast.success(
        <span className="capitalFirst">Channel Berhasil Diupdate!</span>
      );
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const processStatusChannel = (id: number, status: any, body: any) => {
    mutateChannel.mutate({ ...body, isActive: status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const body = {
      code: oriObj.code,
      name: oriObj.name,
      headOfTrainer: oriObj.headOfTrainer,
      signOffCaption: oriObj.signOffCaption,
      isActive: status,
    };
    const action = status === true ? 'mengaktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusChannel(id, status, body),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Channel "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteChannel.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Channel "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteChannel`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditChannel`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
      {
        id: 'btnNextChannel',
        icon: 'ArrowRightOutlined',
        onClick: () => setTabData(prev => ({ ...prev, channel: rows })),
        className: 'btnNext',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      id: 'expanderChannel',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Channel Code',
      accessor: 'code',
    },
    {
      Header: 'Channel Name',
      accessor: 'name',
    },
    {
      Header: 'Head of Trainer',
      accessor: 'headOfTrainer',
    },
    {
      Header: 'Sign Off Caption',
      accessor: 'signOffCaption',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];
  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };
  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { createdDate, totalStage, totalClass } = row.original;
    const createDate = moment(createdDate).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Created Date',
          createDate === '01-Jan-0001' ? '-' : createDate
        )}
        {renderCol(
          'Total Stage',
          totalStage === null || totalStage === '' ? '-' : totalStage
        )}

        {renderCol(
          'Total Class',
          totalClass === null || totalClass === '' ? '-' : totalClass
        )}
      </div>
    );
  }, []);

  const handleApplyfilter = () => {
    setFilter(selectedStatus);
  };

  return (
    <ChannelComponent
      columnData={columnData}
      dataChannel={dataChannel || []}
      renderRowSubComponent={renderRowSubComponent}
      handleRefresh={() => {
        refetch();
        setSelectedStatus(undefined);
        setFilter(undefined);
      }}
      isLoading={isLoadingChannel || isRefetchChannel}
      modalIsShow={modalIsShow}
      setModalIsShow={setModalIsShow}
      setSelectedStatus={setSelectedStatus}
      handleApplyfilter={handleApplyfilter}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      selectedStatus={selectedStatus}
    />
  );
}

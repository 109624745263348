import moment from 'moment';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { toBase64 } from '../App/Helper';
import { IResultDisclaimerMappingListDAO } from '../Modules/Disclaimer/DAO/disclaimer.dao';

export const getLinkStatusAPI = async (params: any) => {
  const { agentCode, encodedData } = params?.queryKey?.[1];
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/Agent/recruit/link/anovalidate/${agentCode}?identifier=${encodedData}`
  );
  return data;
};

export const getNewLinkDataAPI = async (params: any) => {
  const { agentCode } = params?.queryKey?.[1];
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/Agent/recruit/anolink/${agentCode}`
  );
  return data;
};

export const getDisclaimerListAPI = async (): Promise<
  IResultDisclaimerMappingListDAO[]
> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/Configuration/disclaimer/module/anomapping`
  );
  return data;
};

export const getCityListAPI = async () => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_URL}/Configuration/AnoregionsNymous`
  );
  return data;
};

export const postValidateLeadAnoAPI = async (payload: any) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL}/Lead/anocheck/validation`,
    payload
  );
};

export const postLeadAnoAPI = async (val: any) => {
  const {
    ageRange,
    ktp,
    selfie,
    name,
    idNumber,
    gender,
    dob,
    email,
    phoneNumber,
    city,
    enthusiasm,
    agentData,
  } = val;

  const selfieFile = selfie[0];
  const selfieBase64: any = await toBase64(selfieFile.file);
  const leadDocuments = [
    {
      documentType: 'PersonalPhoto',
      name: selfieFile.name,
      mimeType: 'image/jpeg',
      fileName: selfieFile.name,
      size: selfieFile.size,
      extension: selfieFile.extension,
      fileDocument: selfieBase64.split(',')[1],
    },
  ];
  let ktpFile, ktpBase64;
  if (ktp) {
    ktpFile = ktp[0];
    ktpBase64 = await toBase64(ktpFile.file);
    leadDocuments.push({
      documentType: 'NationalId',
      name: ktpFile.name,
      mimeType: 'image/jpeg',
      fileName: ktpFile.name,
      size: ktpFile.size,
      extension: ktpFile.extension,
      fileDocument: ktpBase64.split(',')[1],
    });
  }

  let scoring = 7;
  if (dob) scoring += 1;
  if (enthusiasm) scoring += 1;
  if (ktp) scoring += 1;
  if (idNumber) scoring += 1;

  const payload = [
    {
      additionalAddress: '-/-',
      address: '',
      age: dob ? moment().diff(dob, 'years') : parseInt(ageRange),
      bloodType: '',
      city,
      dateOfBirth: dob ? moment(dob).format('YYYY-MM-DD') : '',
      enthusiasm: enthusiasm ? enthusiasm : 0,
      expiryDate: 'Selamanya',
      gender,
      id: 0,
      idNumber: idNumber ? idNumber : '',
      maritalStatus: '',
      mobileId: uuidv4(),
      name,
      nationality: '',
      occupation: '',
      placeOfBirth: '',
      refAgencyCode: agentData?.[2],
      refAgencyName: agentData?.[3],
      refAgentCode: agentData?.[0],
      refAgentName: agentData?.[1],
      region: '',
      religion: '',
      scoring,
      subDistrict: '',
      village: '',
      leadContacts: [
        {
          contactType: 'MobilePhone',
          contact: `+62${phoneNumber}`,
        },
        {
          contactType: 'PersonalEmail',
          contact: email ? email : '',
        },
      ],
      leadDocuments,
    },
  ];
  return await axios.post(`${process.env.REACT_APP_API_URL}/Lead/ano`, payload);
};

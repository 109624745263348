import Table, { generateColumnData } from '../../../Assets/Components/CTable';

import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IResultNotificationListDAO } from '../DAO/notification.dao';

interface IProps {
  intl: any;
  notificationList: IResultNotificationListDAO[];
  isLoading: boolean;
  columnData: any;
}

function NotificationComponent(props: IProps) {
  const { intl, notificationList, isLoading, columnData } = props;
  const cardNotificationContent = () => {
    return (
      <div className="containerWhiteZurich">
        <p>*Please submit CR in cases there are some changes</p>
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={notificationList || []}
          pagination={true}
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Notification" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'notificationCardTitle',
            })}
          </span>
        }
        cardContent={cardNotificationContent()}
      />
    </React.Fragment>
  );
}

export default injectIntl(NotificationComponent);

import { Button } from 'antd';
import { FormApi } from 'final-form';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../../App/HasPermission';
import CIcon from '../../../../Assets/Components/CIcon';
import {
  InputSwitch,
  InputText,
  InputTextNumber,
} from '../../../../Assets/Components/CInput';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import Select from '../../../../Assets/Components/Select';
import stageValidation from '../../Validation/stageValidation';

interface IProps {
  isShow: boolean;
  modalAction: string;
  editMode: boolean;
  predecessorList: any[];
  handleCancel: () => void;
  handleSubmit: (val: any) => void;
  handleAddtionalOnChangeSequence: (
    val: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleAddtionalSelectPredecessor: (
    val: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  initialValues: any;
  isLoading: boolean;
}

export default function StageModalComponent(props: IProps) {
  const {
    modalAction,
    editMode,
    isShow,
    handleCancel,
    handleSubmit,
    handleAddtionalOnChangeSequence,
    handleAddtionalSelectPredecessor,
    predecessorList,
    initialValues,
    isLoading,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailStageElearning" />
        ) : (
          <FormattedMessage id="addStageElearning" />
        )}
      </h3>
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { form } = formProps;
    const disabled =
      editMode === false && modalAction === 'update' ? true : false;
    return (
      <>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="name"
            idComponent="inputNameStage"
            component={InputText}
            placeholder="Type Here"
            disabled={disabled}
            label="Stage Name"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="description"
            idComponent="inputDescStage"
            component={InputText}
            placeholder="Type Here"
            label="Description"
            disabled={disabled}
          />
        </div>
        <div style={{ display: 'flex', gap: '50px' }}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Field
              name="predecessor"
              component={Select}
              idComponent="inputPredecessor"
              dataOption={predecessorList}
              onSelect={e => handleAddtionalSelectPredecessor(e, form)}
              disabled={disabled}
              label="Predecessor"
              defaultItemName="-Select-"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Field
              name="sequence"
              component={InputTextNumber}
              addtionalOnChange={e => handleAddtionalOnChangeSequence(e, form)}
              idComponent="inputSequence"
              placeholder="Type Here"
              disabled={disabled}
              label="Sequence"
              min={1}
            />
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label className="labelInputFormModal">Stage Status</label>
          <Field
            name="isActive"
            idComponent="inputStatusStage"
            component={InputSwitch}
            disabled={disabled}
          />
        </div>
      </>
    );
  };

  const footerComponent = propsFooter => {
    const { invalid } = propsFooter;
    const idComponent =
      modalAction === 'register'
        ? 'btnCreate'
        : editMode === false
        ? 'btnEdit'
        : 'btnSaveChange';
    const content =
      modalAction === 'register'
        ? 'Create'
        : editMode === false
        ? 'Edit'
        : 'Save Change';

    if (HasPermission('u') || HasPermission('c')) {
      return (
        <Button
          className={
            editMode
              ? invalid
                ? 'btnSubmitDisabled'
                : 'btnSubmit'
              : 'btnSubmit'
          }
          icon={<CIcon type="CheckOutlined" />}
          disabled={modalAction === 'update' && !editMode ? false : invalid}
          id={idComponent}
          htmlType="submit"
          loading={isLoading}
        >
          {content}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={val => stageValidation(editMode, val)}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

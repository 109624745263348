import {
  IResultChannelElearningDAO,
  IResultUserLdapElearningDAO,
} from '../../Modules/ElearningContentManagement/Channel/DAO/channelElearning.dao';
import instance from '../interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getElearningUserLdapAPI = async (
  params: UseQueryOptions
): Promise<IResultUserLdapElearningDAO> => {
  const user = params?.queryKey?.[1];
  const { data } = await instance.get(`/User/searchldap/${user}`);
  return data;
};

export const getElearningChannelAPI = async (): Promise<
  IResultChannelElearningDAO[]
> => {
  const { data } = await instance.get(`/Elearning/Channel`);
  return data;
};

export const deleteElearningChannelAPI = async (id: string) => {
  return await instance.delete(`/Elearning/Channel/${id}`);
};

export const mutateElearningChannelAPI = async params => {
  const {
    code,
    name,
    headOfTrainer,
    trainerName,
    signOffCaption,
    isActive,
    id,
  } = params;
  const payload: any = {
    code,
    name,
    headOfTrainer,
    trainerName,
    signOffCaption,
    isActive,
  };
  if (!isActive) payload.isActive = false;

  if (id) {
    return await instance.put(`/Elearning/Channel/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/Channel`, payload);
  }
};

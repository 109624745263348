import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import { Breadcrumb, Button, List } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';

interface IProps {
  modalMoveShow: boolean;
  collectionList: any;
  selectedMovePath: never[];
  isMoving: boolean;
  handleCancel: () => void;
  handleBack: () => void;
  handleSubmitMove: () => void;
  handleSelectedFolderCollection: (last: any, content: any, path: any) => void;
}
export default function ModalMoveLibraryComponent(props: IProps) {
  const {
    modalMoveShow,
    handleCancel,
    collectionList,
    handleSelectedFolderCollection,
    selectedMovePath,
    handleSubmitMove,
    isMoving,
    handleBack,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="collectionLibraryForm" />
      </h3>
    );
  };
  const contentComponent = (formProps: any) => {
    const renderBreadCrumb = () => {
      return selectedMovePath?.map((val: any, index: any) => {
        return (
          <React.Fragment>
            <Breadcrumb.Item
              key={`breadcrumb-${val}-${index}`}
              className="breadcrumbList"
            >
              {val}
            </Breadcrumb.Item>
            <Breadcrumb.Separator></Breadcrumb.Separator>
          </React.Fragment>
        );
      });
    };
    return (
      <React.Fragment>
        <Breadcrumb separator="" className="breadcrumbLibrary">
          <Breadcrumb.Item>Move to</Breadcrumb.Item>
          <Breadcrumb.Separator>:</Breadcrumb.Separator>
          {renderBreadCrumb()}
        </Breadcrumb>
        <List
          bordered
          dataSource={collectionList || []}
          renderItem={(x: any) => (
            <List.Item>
              <CIcon type="FolderFilled" className="iconDirTree" />
              {x.name}
              <CIcon
                onClick={() =>
                  handleSelectedFolderCollection(
                    collectionList,
                    x.content,
                    x.name
                  )
                }
                style={{ float: 'right', cursor: 'pointer' }}
                type="RightOutlined"
              />
            </List.Item>
          )}
        />
        <div style={{ display: 'block' }}>
          <Button
            icon={<CIcon type="LeftOutlined" />}
            id="btnBackCollection"
            style={{ float: 'left', marginTop: '30px' }}
            onClick={handleBack}
            loading={isMoving}
          >
            Back
          </Button>
          <Button
            type="primary"
            icon={<CIcon type="ExportOutlined" />}
            id="btnBackCollection"
            style={{ float: 'right', marginTop: '30px' }}
            onClick={handleSubmitMove}
            loading={isMoving}
          >
            Move here
          </Button>
        </div>
      </React.Fragment>
    );
  };

  const footerComponent = (formProps: any) => {
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={modalMoveShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={null}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '500px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

import React from 'react';
import DashboardUniqueLoginComponent from '../Component/DashboardUniqueLoginComponent';
import { useQuery } from '@tanstack/react-query';
import { getUniqueLoginDataAPI } from '../../../Services/Dashboard/dashboard.api';

export default function DashboardUniqueLoginContainer() {
  const { data } = useQuery(
    ['getUniqueLoginDashboardAPI'],
    getUniqueLoginDataAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  return <DashboardUniqueLoginComponent data={data} />;
}

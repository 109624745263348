import React from 'react';
import { injectIntl } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table from '../../../Assets/Components/CTable';
import { IResultPermissionRoleListDAO } from '../DAO/permission.dao';

interface IProps {
  authRoleList: IResultPermissionRoleListDAO[];
  columnData: any;
  handleOnClick: () => void;
  handleRefresh: () => void;
  isLoading: boolean;
  intl?: any;
}

function PermissionComponent(props: IProps) {
  const {
    authRoleList,
    columnData,
    handleOnClick,
    handleRefresh,
    intl,
    isLoading,
  } = props;

  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'PlusOutlined',
          onClick: handleOnClick,
          content: 'Add Role',
          id: 'btnAddRole',
        }
      : null,
    {
      type: 'default',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefresh',
    },
  ];

  const cardContestTargetContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
        />
        <Table
          isLoading={isLoading}
          columns={columnData}
          data={authRoleList}
          pagination={true}
        />
      </div>
    );
  };
  
  return (
    <>
      <CLabelCard leftText="Permission" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'roleCardTitle',
            })}
          </span>
        }
        cardContent={cardContestTargetContent()}
      />
    </>
  );
}

export default injectIntl(PermissionComponent);

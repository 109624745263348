import React from 'react';
import { useQuery } from '@tanstack/react-query';
import DashboardMostVisitedMenuComponent from '../Component/DashboardMostVisitedMenuComponent';
import { getMostVisitedMenuDataAPI } from '../../../Services/Dashboard/dashboard.api';

export default function DashboardMostVisitedMenuContainer() {
  const { data } = useQuery(
    ['getMostVisitedMenuAPI'],
    getMostVisitedMenuDataAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  return <DashboardMostVisitedMenuComponent data={data} />;
}

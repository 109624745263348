import { useEffect, useState } from 'react';

import React from 'react';
import ContentManagementComponent from './ContentManagementComponent';

export default function ContentManagementContainer() {
  const [tabData, setTabData] = useState<{
    channel: any;
    stage: any;
    module: any;
    submodule: any;
  }>({
    channel: undefined,
    stage: undefined,
    module: undefined,
    submodule: undefined,
  });
  const [allDataList, setAllDataList] = useState<{
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  }>({
    channelList: [],
    stageList: [],
    moduleList: [],
    submoduleList: [],
  });
  const { channel, stage, module, submodule } = tabData;

  const [breadCrumbData, setBreadCrumbData]: any = useState({
    breadcrumbItem: [],
    breadcrumbTitle: [],
    breadcrumbItemClick: () => {},
  });

  const handleOnTabClick = (params: string) => {
    if (params === 'Channel') {
      setTabData(prev => ({
        channel: undefined,
        stage: undefined,
        module: undefined,
        submodule: undefined,
      }));
      setAllDataList(prev => ({
        channelList: [],
        stageList: [],
        moduleList: [],
        submoduleList: [],
      }));
    } else if (params === 'Stage') {
      setTabData(prev => ({
        ...prev,
        stage: undefined,
        module: undefined,
        submodule: undefined,
      }));
      setAllDataList(prev => ({
        ...prev,
        stageList: [],
        moduleList: [],
        submoduleList: [],
      }));
    } else if (params === 'Module') {
      setTabData(prev => ({
        ...prev,
        module: undefined,
        submodule: undefined,
      }));
      setAllDataList(prev => ({
        ...prev,
        moduleList: [],
        submoduleList: [],
      }));
    } else if (params === 'Sub Module') {
      setTabData(prev => ({
        ...prev,
        submodule: undefined,
      }));
      setAllDataList(prev => ({
        ...prev,
        submoduleList: [],
      }));
    } 
  };

  const handleOnClickBreadcrumb = item => {
    if (item === channel.name) {
      setTabData(prev => ({
        channel: undefined,
        stage: undefined,
        module: undefined,
        submodule: undefined,
      }));
    } else if (item === stage.name) {
      setTabData(prev => ({
        ...prev,
        stage: undefined,
        module: undefined,
        submodule: undefined,
      }));
    } else if (item === module.name) {
      setTabData(prev => ({
        ...prev,
        module: undefined,
        submodule: undefined,
      }));
    } else if (item === submodule.name) {
      setTabData(prev => ({
        ...prev,
        submodule: undefined,
      }));
    }
  };

  useEffect(() => {
    const tempBreadCrumbData = { ...breadCrumbData };

    if (submodule) {
      tempBreadCrumbData.breadcrumbItem = [
        channel?.name,
        stage?.name,
        module?.name,
        submodule?.name,
      ];
      tempBreadCrumbData.breadcrumbTitle = [
        'Channel',
        'Stage',
        'Module',
        'Sub Module',
        'Content',
      ];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else if (module) {
      tempBreadCrumbData.breadcrumbItem = [
        channel?.name,
        stage?.name,
        module?.name,
      ];
      tempBreadCrumbData.breadcrumbTitle = [
        'Channel',
        'Stage',
        'Module',
        'Sub Module',
      ];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else if (stage) {
      tempBreadCrumbData.breadcrumbItem = [channel?.name, stage?.name];
      tempBreadCrumbData.breadcrumbTitle = ['Channel', 'Stage', 'Module'];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else if (channel) {
      tempBreadCrumbData.breadcrumbItem = [channel?.name];
      tempBreadCrumbData.breadcrumbTitle = ['Channel', 'Stage'];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else {
      tempBreadCrumbData.breadcrumbItem = [];
      tempBreadCrumbData.breadcrumbTitle = ['Channel'];
    }
    setBreadCrumbData(tempBreadCrumbData);
    // eslint-disable-next-line
  }, [tabData]);

  return (
    <ContentManagementComponent
      tabData={tabData}
      allDataList={allDataList}
      setTabData={setTabData}
      setAllDataList={setAllDataList}
      handleOnTabClick={handleOnTabClick}
      breadCrumbData={breadCrumbData}
    />
  );
}

import React, { useState } from 'react';
import ModalDisclaimerContentComponent from '../Component/ModalDisclaimerContentComponent';
import { toast } from 'react-toastify';
import { mutateDisclaimerContentAPI } from '../../../Services/Disclaimer/disclaimerContent.api';
import { useMutation } from '@tanstack/react-query';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  showModal: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  intl: any;
  handleRefresh: () => void;
}

export default function ModalDisclaimerContentContainer(props: IProps) {
  const {
    showModal,
    setModalIsShow,
    selectedData,
    setSelectedData,
    intl,
    handleRefresh,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const modalAction = selectedData ? 'update' : 'register';
  let initialValues: any = selectedData;

  const mutateDisclaimerContent = useMutation(mutateDisclaimerContentAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} disclaimer content!
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });
  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateDisclaimerContent.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  const handleCancel = () => {
    setSelectedData(undefined);
    setModalIsShow(false);
    setEditMode(false);
  };
  return (
    <ModalDisclaimerContentComponent
      isShow={showModal}
      handleCancel={handleCancel}
      initialValues={initialValues}
      modalAction={modalAction}
      intl={intl}
      editMode={editMode}
      disabled={editMode === false && modalAction === 'update' ? true : false}
      handleSubmit={handleSubmit}
      isLoading={mutateDisclaimerContent.isLoading}
    />
  );
}

import React from 'react';

import Dashboard from '@uppy/react/lib/Dashboard';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { localeUppy } from '../../Internationalization/translations/en';
import CModalFile from '../../../Assets/Components/CModalFile';
import Store from '../../../Store';
import history from '../../../App/History';
import { toast } from 'react-toastify';

interface IProps {
  modalUploadWorldIsShow: boolean;
  handleCancel: () => void;
}
export default function ModalUploadWorldCheckComponent(props: IProps) {
  const { modalUploadWorldIsShow, handleCancel } = props;

  const authState: any = Store.getState();
  const uppyUploadWorldCheck = new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.xlsx'],
    },
    locale: localeUppy,
  }).use(XHRUpload, {
    id: `uppyFileGalleryXHR`,
    headers: {
      Authorization: `bearer ${authState.token}`,
    },
    endpoint: `${process.env.REACT_APP_API_URL}/Candidate/file/upload/worldcheck`,
    method: 'post',
    formData: true,
    fieldName: 'file',
    allowedMetaFields: ['originalFilename'],
    disableThumbnailGenerator: true,
    showRemoveButtonAfterComplete: true,
    inline: true,
  });
  uppyUploadWorldCheck.on('file-added', file => {
    uppyUploadWorldCheck.setFileMeta(file.id, {
      originalFilename: file.name,
    });
  });
  uppyUploadWorldCheck.on('upload-success', () => {
    toast.success('File Berhasil Diunggah');
    handleCancel();
  });

  uppyUploadWorldCheck.on('upload-error', (file, error, response: any) => {
    if (response?.status === 401) {
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      authState.logOut();
    }
  });

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Unggah World Check</span>
      </h3>
    );
  };

  const contentComponent = () => {
    return (
      <React.Fragment>
        <p className="titleUploadFileCityModal">Upload File</p>
        <Dashboard
          id="uppyUploadCity"
          uppy={uppyUploadWorldCheck}
          width={'100%'}
          disableThumbnailGenerator={true}
          showRemoveButtonAfterComplete={true}
          inline={true}
          proudlyDisplayPoweredByUppy={true}
          height={300}
        />
      </React.Fragment>
    );
  };
  return (
    <CModalFile
      modalIsShow={modalUploadWorldIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      isForm={true}
      formLayout="horizontal"
    />
  );
}

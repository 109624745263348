import React, { useState } from 'react';
import CButton from '../../../Assets/Components/CButton';
import UploadScheduleComponent from '../Component/UploadScheduleComponent';
import moment from 'moment';
import { useQuery } from '@tanstack/react-query';
import { getScheduleUploadListAPI } from '../../../Services/uploadSchedule.api';

export default function UploadScheduleContainer() {
  const [modalDetailScheduleIsShow, setModalDetailScheduleIsShow] =
    useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [modalUploadScheduleIsShow, setModalUploadScheduleIsShow] =
    useState(false);

  const {
    data: uploadScheduleList,
    isLoading,
    isRefetching,
    fetchStatus,
    refetch,
  } = useQuery(['getUploadScheduleList'], getScheduleUploadListAPI, {
    enabled: !modalUploadScheduleIsShow,
    refetchOnWindowFocus: false,
  });

  const handleRefresh = () => {
    refetch();
  };

  const renderAction = (row: any) => {
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailQa',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: () => {
          setSelectedData(row.row.original);
          setModalDetailScheduleIsShow(true);
        },
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderStatus = (row: any) => {
    return (
      <div className="spanContestStatus">
        <span
          className={`uploadScheuleStatusColor uploadScheuleStatusColor${row.row.original.status}`}
        />
        <span className={`uploadScheduleStatusName`}>
          {row.row.original.status}
        </span>
      </div>
    );
  };
  const columnData = [
    {
      Header: 'Nama Berkas',
      accessor: 'originalFilename',
    },
    {
      Header: 'Status Unggah',
      Cell: (row: any) => renderStatus(row),
      disableSort: true,
    },
    {
      Header: 'Tanggal Unggah',
      accessor: (row: any) => moment(row.uploadDate).format('DD-MMM-YYYY'),
      disableSort: true,
    },
    {
      Header: ' ',
      Cell: (row: any) => renderAction(row),
    },
  ];
  return (
    <UploadScheduleComponent
      columnData={columnData}
      handleRefresh={handleRefresh}
      uploadScheduleList={uploadScheduleList}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      modalDetailScheduleIsShow={modalDetailScheduleIsShow}
      setModalDetailScheduleIsShow={setModalDetailScheduleIsShow}
      selectedData={selectedData}
      modalUploadScheduleIsShow={modalUploadScheduleIsShow}
      setModalUploadScheduleIsShow={setModalUploadScheduleIsShow}
    />
  );
}

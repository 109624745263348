const DisclaimerMappingValidation = ({ disclaimerId, disclaimerModuleId }) => {
  const errors: any = {};
  if (!disclaimerId) {
    errors.disclaimerId = 'Disclaimer required!';
  }
  if (!disclaimerModuleId) {
    errors.disclaimerModuleId = 'Module required!';
  }
  return errors;
};
export default DisclaimerMappingValidation;

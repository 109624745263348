import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Alert, Button, Col, Divider, Row } from 'antd';
import HasPermission from '../../../App/HasPermission';
import CIcon from '../../../Assets/Components/CIcon';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Field, FormRenderProps } from 'react-final-form';
import { InputSwitch, InputText } from '../../../Assets/Components/CInput';
import { InputRichText } from '../../../Assets/Components/CRichText';
import invitationValidation from '../Validation/invitationValidation';

interface IProps {
  editMode: boolean;
  handleCancel: () => void;
  handleSubmit: (val: any) => void;
  initialValues: any;
  isLoading: boolean;
  modalAction: 'update' | 'register';
  showModal: boolean;
  intl?: any;
}

function ModalInvitationComponent(props: IProps) {
  const {
    editMode,
    handleCancel,
    handleSubmit,
    initialValues,
    intl,
    isLoading,
    modalAction,
    showModal,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="invitationForm" />
      </h3>
    );
  };
  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { values } = formProps;
    const disabled =
      editMode === false && modalAction === 'update' ? true : false;

    const smsLength =
      values && values.templateSms ? values.templateSms.length : 0;
    const totalSms = Math.ceil(smsLength / 160);

    return (
      <>
        <Alert
          message={`At least must have 1 contains`}
          type="warning"
          showIcon
        />
        <Field
          name="name"
          component={InputText}
          idComponent="inputTemplateName"
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeholderTemplateName',
          })}
          label={intl.formatMessage({ id: 'templateName' })}
          editMode={editMode}
          disabled={disabled}
          maxlength={255}
        />
        <Divider />
        <p className="labelInvitationBig">SMS Content</p>
        <div className="templateSms">
          <Field
            name="templateSms"
            component={InputText}
            idComponent="inputTemplateSms"
            editMode={editMode}
            disabled={disabled}
            textArea={true}
          />
        </div>
        <Row>
          <Col span="12">*Maximum length of 1 SMS is 160</Col>
          <Col span="12" style={{ textAlign: 'right' }}>
            {smsLength} character ({totalSms === 0 ? 1 : totalSms} times SMS)
          </Col>
        </Row>
        <Divider />
        <Field
          name="generateBarcodeSms"
          label={intl.formatMessage({ id: 'generateBarcode' })}
          component={InputSwitch}
          idComponent="inputGenerateBarcodeSms"
          editMode={editMode}
          disabled={disabled}
        />
        <p className="labelInvitationBig">Email Content</p>
        <Field
          name="emailSubject"
          component={InputText}
          idComponent="inputTemplateEmailSubject"
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeholderTemplateEmailSubject',
          })}
          label={intl.formatMessage({ id: 'templateEmailSubject' })}
          editMode={editMode}
          disabled={disabled}
        />
        <Field
          name="templateEmail"
          component={InputRichText}
          idComponent="inputInvitationContent"
          label={intl.formatMessage({ id: 'emailBody' })}
          editMode={editMode}
          disabled={disabled}
          initValues={initialValues?.templateEmail}
        />
        <Field
          name="generateBarcode"
          label={intl.formatMessage({ id: 'generateBarcode' })}
          component={InputSwitch}
          idComponent="inputGenerateBarcode"
          editMode={editMode}
          disabled={disabled}
        />
        <Divider />
        <p className="labelInvitationBig">Social Media Content</p>
        <Field
          name="templateSocialMedia"
          component={InputText}
          idComponent="inputTemplateSocialMedia"
          editMode={editMode}
          disabled={disabled}
          textArea={true}
        />
      </>
    );
  };

  const buttonContent = () => {
    if (editMode === false) {
      if (modalAction === 'register') return 'Create';
      else return 'Edit';
    }
    return 'Save Change';
  };

  const handleDisableButton = (
    action: string,
    invalid,
    hasErrors,
    editMode: boolean
  ) => {
    if (action === 'register') {
      if (invalid || hasErrors) {
        return true;
      }
      return false;
    } else {
      if (editMode === false) {
        return false;
      } else {
        if (invalid || hasErrors) {
          return true;
        }
        return false;
      }
    }
  };

  const footerComponent = (formProps: FormRenderProps<any, any>) => {
    const { error, invalid } = formProps;

    if (
      (modalAction === 'register' && HasPermission('c')) ||
      (modalAction === 'update' && HasPermission('u'))
    ) {
      return (
        <Button
          className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
          icon={<CIcon type="CheckOutlined" />}
          loading={isLoading}
          disabled={handleDisableButton(modalAction, invalid, error, editMode)}
          id="btnSubmit"
          htmlType="submit"
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      formLayout="vertical"
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleValidation={invitationValidation}
      headerComponent={headerComponent()}
      initialValues={initialValues}
      isForm={true}
      isLoading={isLoading}
      modalIsShow={showModal}
    />
  );
}

export default injectIntl(ModalInvitationComponent);

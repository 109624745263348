import React from 'react';
import FeedbackDetailModalComponent from '../Component/ModalFeedbackDetailComponent';

interface IProps {
  isShow: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedFeedbackDetail: any;
  setSelectedFeedbackDetail: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function FeedbackDetailModalContainer(props: IProps) {
  const { setShowModal, setSelectedFeedbackDetail } = props;
  const handleCancel = () => {
    setShowModal(false);
    setSelectedFeedbackDetail(undefined);
  };

  return (
    <FeedbackDetailModalComponent {...props} handleCancel={handleCancel} />
  );
}

import React, { useState } from 'react';
import {
  generateColumnData,
  renderActionComponent,
} from '../../../Assets/Components/CTable';
import {
  getRoleAPI,
  getUserRoleListAPI,
  mutateDeleteTrainerAPI,
  mutateUnassignAuthUserRoleAPI,
} from '../../../Services/user.api';
import { useMutation, useQuery } from '@tanstack/react-query';

import AuthUserRoleComponent from '../Component/AuthUserRoleComponent';
import HasPermission from '../../../App/HasPermission';
import ModalAuthUserRoleContainer from './ModalAuthUserRoleContainer';
import { injectIntl } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import ErrorHandler from '../../../App/ErrorHandler';

function AuthUserRoleContainer({ intl }) {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: dataUserRole,
    refetch: refetchUserRole,
    isLoading: isLoadingUserRole,
    isRefetching: isRefetchingUserRole,
  } = useQuery([], getUserRoleListAPI, {
    refetchOnWindowFocus: false,
  });

  const hasPermissionModule = HasPermission('r', '/sec/rol');
  const { data: dataRole } = useQuery(['Get Role'], getRoleAPI, {
    enabled: hasPermissionModule,
    refetchOnWindowFocus: false,
  });
  const trainerData = dataRole?.find(val => val.name === 'Trainer');

  const mutateUnassignUserRole = useMutation(mutateUnassignAuthUserRoleAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">Success delete mapping user roles!</span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchUserRole();
    },
  });

  const mutateDeleteTrainer = useMutation(mutateDeleteTrainerAPI);

  const handleCreate = () => setModalIsShow(true);

  const renderRole = row => {
    return row.row.original.userRoles[0].roleName;
  };

  const renderAction = row => {
    const dataRow = row.row.original;
    const { username, userRoles } = dataRow;

    const editClick = () => {
      setModalIsShow(true);
      setSelectedData(dataRow);
    };
    const handleRemove = () => {
      setSelectedData(dataRow);
      const toastrConfirmOptions = {
        onOk: () => {
          mutateUnassignUserRole.mutate(
            {
              username,
              roleId: userRoles[0].roleId,
            },
            {
              onSuccess: () => {
                if (dataRow.userRoles[0].roleId === trainerData.id) {
                  mutateDeleteTrainer.mutate(dataRow.userId);
                }
                setSelectedData(undefined);
              },
            }
          );
        },
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Are you sure to delete mapping user "${row.row.original.username}"?`,
        toastrConfirmOptions
      );
    };
    return renderActionComponent(
      isLoadingUserRole || isRefetchingUserRole,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
        renderDelete: HasPermission('d'),
      },
      {
        handleUpdate: editClick,
        handleRemove,
      }
    );
  };

  const columnData = [
    {
      Header: 'Username',
      accessor: 'username',
    },
    {
      Header: 'Role',
      Cell: row => renderRole(row),
    },
    HasPermission('u') || HasPermission('d')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
        }
      : {
          accessor: 'hideAction',
        },
  ];

  return (
    <>
      <AuthUserRoleComponent
        columns={generateColumnData(columnData)}
        handleCreate={handleCreate}
        handleRefresh={refetchUserRole}
        intl={intl}
        isLoading={isLoadingUserRole || isRefetchingUserRole}
        userRoleList={dataUserRole || []}
      />
      {modalIsShow && (
        <ModalAuthUserRoleContainer
          intl={intl}
          listUserRole={dataUserRole || []}
          refetchUserRole={refetchUserRole}
          selectedData={selectedData}
          setModalIsShow={setModalIsShow}
          setSelectedData={setSelectedData}
        />
      )}
    </>
  );
}
export default injectIntl(AuthUserRoleContainer);

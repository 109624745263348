import React, { useEffect, useState } from 'react';
import SlideModalComponent from '../../Component/Modal/SlideModalComponent';
import { useMutation } from '@tanstack/react-query';
import { contentManagementContentSlideAPI } from '../../../../../Services/ElearningContentManagement/contentSlide';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { FormApi } from 'final-form';
import {
  convertUrlToFilename,
  convertUrlToFilesize,
} from '../../../../../App/Helper';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  stage: any;
  submodule: any;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  handleRefresh: () => void;
  selectedData: any;
}

export default function SlideModalContainer({
  stage,
  submodule,
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  handleRefresh,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [slideFileProperties, setSlideFileProperties] = useState<any>(null);
  const [slideCoverProperties, setSlideCoverProperties] = useState<any>(null);
  const [slideFileSize, setSlideFileSize] = useState(0);
  const initialValues: any = selectedData;

  useEffect(() => {
    if (!initialValues) return;

    async function getSize() {
      if (initialValues.file) {
        await convertUrlToFilesize(initialValues?.file).then(result => {
          const data: any = result;
          setSlideFileSize(data.size);
          setSlideFileProperties({
            name: initialValues.fileName,
            size: data.size,
            direct_url: initialValues.file,
            file_url: initialValues.file,
          });
        });
      }
      if (initialValues.thumbnail) {
        await convertUrlToFilesize(initialValues?.thumbnail).then(result => {
          const data: any = result;
          setSlideCoverProperties({
            name: convertUrlToFilename(initialValues?.thumbnail),
            size: data.size,
            direct_url: initialValues.thumbnail,
          });
        });
      }
    }

    if (initialValues.fileType === 'youtube')
      initialValues.embedLink = initialValues.file;
    else if (initialValues.fileType === 'pdf') initialValues.pdf = true;
    else if (initialValues.fileType === 'video') initialValues.video = true;
    else if (initialValues.fileType === 'audio') {
      initialValues.audio = true;
      initialValues.audioCover = true;
    }
    getSize();
  }, [initialValues]);

  const { mutate: mutateSlide } = useMutation(
    contentManagementContentSlideAPI.mutateSlide,
    {
      onSuccess: () => {
        const toastMessage = selectedData ? (
          <span className="capitalFirst">Slide Berhasil Diupdate!</span>
        ) : (
          <span className="capitalFirst">Slide Berhasil Ditambahkan!</span>
        );
        setSlideFileProperties(null);
        setSlideCoverProperties(null);
        setShowModal(false);
        handleRefresh();
        setSelectedData(undefined);
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const { isLoading: isLoadingUpload, mutateAsync: mutateUpload } = useMutation(
    contentManagementContentSlideAPI.uploadSlide
  );

  const handleUpload = async (
    type: 'file' | 'cover',
    values: any,
    form: FormApi<any, Partial<any>>,
    fieldName: string
  ) => {
    try {
      const res = await mutateUpload(values);
      if (type === 'file') {
        setSlideFileProperties(res);
        form.change(fieldName, true);
      } else setSlideCoverProperties(res);
    } catch (error) {
      const responseStatus = error?.status;
      const errorData = error?.data;
      if (responseStatus === 500)
        toast.error(
          <span className="capitalFirst">
            <FormattedMessage id={errorData?.Message} />
          </span>
        );
    }
  };

  const handleCancel = () => {
    setSlideFileProperties(null);
    setSlideCoverProperties(null);
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
    handleRefresh();
  };

  const handleChangeFileType = () => {
    setSlideFileProperties(null);
    setSlideCoverProperties(null);
  };

  const handleDeleteFile = (
    type: 'file' | 'cover',
    form: FormApi<any, Partial<any>>,
    fieldName: string
  ) => {
    if (type === 'file') {
      if (initialValues) initialValues.file = null;
      setSlideFileProperties(null);
      form.change(fieldName, null);
    } else {
      // if (initialValues) initialValues.thumbnail = null;
      setSlideCoverProperties(null);
      form.change(`${fieldName}Cover`, null);
    }
  };

  const handleSubmit = (vals: any) => {
    if (initialValues && !editMode) setEditMode(true);
    else {
      mutateSlide({
        ...vals,
        slideId: vals.id,
        slideCoverProperties,
        slideFileProperties,
        channelId: submodule.channelId,
        stageId: submodule.stageId,
        moduleId: submodule.moduleId,
        subModuleId: submodule.id,
        codemiModuleId: stage.codemiUserLevelId,
      });
    }
  };

  return (
    <SlideModalComponent
      isShow={showModal}
      modalAction={initialValues ? 'update' : 'register'}
      editMode={editMode}
      isLoading={isLoadingUpload || false}
      handleCancel={handleCancel}
      handleDeleteFile={handleDeleteFile}
      handleUpload={handleUpload}
      handleSubmit={handleSubmit}
      handleChangeFileType={handleChangeFileType}
      initialValues={initialValues}
      slideFileProperties={slideFileProperties}
      setSlideFileProperties={setSlideFileProperties}
      slideCoverProperties={slideCoverProperties}
      setSlideCoverProperties={setSlideCoverProperties}
      slideFileSize={slideFileSize}
    />
  );
}

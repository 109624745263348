import { IResultStageNonSelfTrainingDAO } from '../Modules/ElearningNonSelfTraining/Stage/DAO/stageNonSelfTraining.dao';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getNonSelfTrainingStageAPI = async (
  params: UseQueryOptions
): Promise<IResultStageNonSelfTrainingDAO[]> => {
  const channelId = params?.queryKey?.[1];
  const isActive = params?.queryKey?.[2];
  const { data } = await instance.get(
    `Elearning/Stage/NonSelf?channelId=${channelId}${
      isActive === 'active'
        ? '&isActive=true'
        : isActive === 'inactive'
        ? '&isActive=false'
        : ''
    }`
  );
  return data;
};

export const deleteNonSelfTrainingStageAPI = async (id: string) => {
  return await instance.delete(`Elearning/Stage/NonSelf/${id}`);
};

export const mutateNonSelfTrainingStageAPI = async params => {
  const { code, name, isActive, id, channelId, description } = params;
  const payload: any = { code, name, isActive, channelId, description };
  if (!isActive) {
    payload.isActive = false;
  }

  if (id) {
    return await instance.put(`/Elearning/Stage/NonSelf/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/Stage/NonSelf`, payload);
  }
};

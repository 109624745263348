import 'react-toastify/dist/ReactToastify.css';

import { Route, Switch, withRouter } from 'react-router-dom';

import AgreementContentContainer from '../Modules/Agreement/Container/AgreementContentContainer';
import AgreementMappingContainer from '../Modules/Agreement/Container/AgreementMappingContainer';
import CityContainer from '../Modules/City/Container/CityContainer';
import ConfigurationContainer from '../Modules/Configuration/Container/ConfigurationContainer';
import DisclaimerContentContainer from '../Modules/Disclaimer/Container/DisclaimerContentContainer';
import DisclaimerMappingContainer from '../Modules/Disclaimer/Container/DisclaimerMappingContainer';
import ExamScheduleContainer from '../Modules/ExamSchedule/Container/ExamScheduleContainer';
import { Helmet } from 'react-helmet';
import LibraryContainer from '../Modules/Library/Container/LibraryContainer';
import LicenseContainer from '../Modules/License/Container/LicenseContainer';
import LoginContainer from './../Modules/Auth/Container/LoginContainer';
import NewAgentMonitoringContainer from '../Modules/NewAgentMonitoring/Container/NewAgentMonitoringContainer';
import NonSelfTraining from '../Modules/ElearningNonSelfTraining/NonSelfTrainingContainer';
import NotFoundComponent from '../Assets/Components/NotFoundComponent';
import NotificationContainer from '../Modules/Notification/Container/NotificationContainer';
import PendingRemarksContainer from '../Modules/CatatanPending/Container/CatatanPendingContainer';
import PrivyIDStatementContainer from '../Modules/PrivyIDStatement/Container/PrivyIDStatementContainer';
import QAProcessContainer from '../Modules/QAProcess/Container/QAProcessContainer';
import QuestionBankContainer from '../Modules/ElearningQuestionBank/Container/QuestionBankContainer';
import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import ReportContainer from '../Modules/Report/Container/ReportContainer';
import Role from './Role';
import TemplateContainer from '../Modules/Template/Container/TemplateContainer';
import { ToastContainer } from 'react-toastify';
import TrackerContainer from '../Modules/Tracker/Container/TrackerContainer';
import UploadScheduleContainer from '../Modules/UploadSchedule/Container/UploadScheduleContainer';
import UserRoleContainer from '../Modules/AuthUser/Container/AuthUserRoleContainer';
import WorldCheckContainer from '../Modules/WorldCheck/Container/WorldCheckContainer';
import CategoryBankContainer from '../Modules/ElearningCategoryBank/Container/CategoryBankContainer';
import withTemplate from '../App/WithTemplate';
import ContentManagementContainer from '../Modules/ElearningContentManagement/ContentManagementContainer';
import LearningReportContainer from '../Modules/ElearningLearningReport/Container/LearningReportContainer';
import DashboardContainer from '../Modules/Dashboard/DashboardContainer';
import ContestTargetContainer from '../Modules/ContestTarget/Container/ContestTargetContainer';
import ContestRewardContainer from '../Modules/ContestReward/Container/ContestRewardContainer';
import ContestUploadContainer from '../Modules/ContestUpload/Container/ContestUploadContainer';
import FeedbackBankContainer from '../Modules/ElearningFeedbackBank/FeedbackBankContainer';
import PermissionContainer from '../Modules/Permission/Container/PermissionContainer';
import InvitationContainer from '../Modules/Invitation/Container/InvitationContainer';
import AuthMiddleware from './AuthMiddleware';
import RecruitmentContainer from '../Modules/Recruitment/Container/RecruitmentContainer';

function Navigation() {
  const login = LoginContainer;
  const authenticatedPage = component => {
    return AuthMiddleware(withTemplate(TemplateContainer, Role(component)));
  };

  const index = authenticatedPage(DashboardContainer);
  const Tracker = authenticatedPage(TrackerContainer);
  const Report = authenticatedPage(ReportContainer);
  const ContestUpload = authenticatedPage(ContestUploadContainer);
  const ContestReward = authenticatedPage(ContestRewardContainer);
  const ContestTarget = authenticatedPage(ContestTargetContainer);
  const DisclaimerContent = authenticatedPage(DisclaimerContentContainer);
  const DisclaimerMapping = authenticatedPage(DisclaimerMappingContainer);
  const Configuration = authenticatedPage(ConfigurationContainer);
  const Permission = authenticatedPage(PermissionContainer);
  const QAProcess = authenticatedPage(QAProcessContainer);
  const WorldCheck = authenticatedPage(WorldCheckContainer);
  const PendingRemarks = authenticatedPage(PendingRemarksContainer);
  const AgreementContent = authenticatedPage(AgreementContentContainer);
  const AgreementMapping = authenticatedPage(AgreementMappingContainer);
  const UserRole = authenticatedPage(UserRoleContainer);
  const City = authenticatedPage(CityContainer);
  const Library = authenticatedPage(LibraryContainer);
  const License = authenticatedPage(LicenseContainer);
  const Notification = authenticatedPage(NotificationContainer);
  const Invitation = authenticatedPage(InvitationContainer);
  const examSchedule = authenticatedPage(ExamScheduleContainer);
  const UploadSchedule = authenticatedPage(UploadScheduleContainer);
  const NewAgentMonitoring = authenticatedPage(NewAgentMonitoringContainer);
  const PrivyStatement = authenticatedPage(PrivyIDStatementContainer);
  const Elearning = authenticatedPage(ContentManagementContainer);
  const ElearningQuestionBank = authenticatedPage(QuestionBankContainer);
  const ElearningCategoryBank = authenticatedPage(CategoryBankContainer);
  const ElearningFeedbackBank = authenticatedPage(FeedbackBankContainer);
  const ElearningLearningReport = authenticatedPage(LearningReportContainer);
  const ElearningNonSelfTraining = authenticatedPage(NonSelfTraining);
  const Recruitment = RecruitmentContainer;

  return (
    <React.Fragment>
      <Helmet titleTemplate="Zurich CMS" defaultTitle="Zurich CMS">
        <title>Zurich CMS</title>
        <meta name="description" content="Zurich CMS System" />
      </Helmet>
      <ReduxToastr
        timeOut={4000}
        newestOnTop={false}
        preventDuplicates={true}
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar={true}
        closeOnToastrClick={true}
      />
      <ToastContainer autoClose={2000} />
      <Switch>
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning`}
          component={Elearning}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning/questionbank`}
          component={ElearningQuestionBank}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning/categorybank`}
          component={ElearningCategoryBank}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning/feedbackbank`}
          component={ElearningFeedbackBank}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning/learningreport`}
          component={ElearningLearningReport}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/elearning/nonselftraining`}
          component={ElearningNonSelfTraining}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/login`}
          component={login}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/`}
          component={index}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/recruitment/:encode`}
          component={Recruitment}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/privyid/statement`}
          component={PrivyStatement}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/tracker`}
          component={Tracker}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/contest/upload`}
          component={ContestUpload}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/contest/reward`}
          component={ContestReward}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/contest/target`}
          component={ContestTarget}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/disclaimer/content`}
          component={DisclaimerContent}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/disclaimer/mapping`}
          component={DisclaimerMapping}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/agreement/content`}
          component={AgreementContent}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/agreement/mapping`}
          component={AgreementMapping}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/config`}
          component={Configuration}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/role/permission`}
          component={Permission}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/role/user`}
          component={UserRole}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/city`}
          component={City}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/library`}
          component={Library}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/license`}
          component={License}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/notification`}
          component={Notification}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/report`}
          component={Report}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/invitation`}
          component={Invitation}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/qa/process`}
          component={QAProcess}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/world/check`}
          component={WorldCheck}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/pending/remarks`}
          component={PendingRemarks}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/upload/schedule`}
          component={UploadSchedule}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/agent/monitoring`}
          component={NewAgentMonitoring}
        />
        <Route
          exact={true}
          path={`${process.env.REACT_APP_PUBLIC_URL}/exam/schedule`}
          component={examSchedule}
        />
        <Route path={'*'} component={NotFoundComponent} />
      </Switch>
    </React.Fragment>
  );
}
export default withRouter(Navigation);

import ClassModalExamComponent from '../Component/ClassModalExamComponent';
import { FormApi } from 'final-form';
import React from 'react';

interface IProps {
  disabled?: boolean;
  form?: FormApi<any, Partial<any>>;
  selectedData?: any;
}

export default function ClassModalExamContainer({
  disabled,
  form,
  selectedData,
}: IProps) {
  const handleReset = () => {
    if (form) {
      form.change('examDuration', undefined);
      form.change('examMinScore', undefined);
      form.change('examMaxAttempt', undefined);
    }
  };
  return (
    <ClassModalExamComponent
      disabled={disabled || false}
      handleReset={handleReset}
      selectedData={selectedData}
    />
  );
}

import React, { useState } from 'react';
import ChannelModalComponent from '../Component/ChannelModalComponent';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDebouncedCallback } from 'use-debounce/lib';
import { FormattedMessage } from 'react-intl';
import {
  getElearningUserLdapAPI,
  mutateElearningChannelAPI,
} from '../../../../Services/ElearningContentManagement/channel.api';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  handleRefresh: () => void;
}

export default function ChannelModalContainer({
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  handleRefresh,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [searchTrainer, setSearchTrainer] = useState();
  const [trainerList, setTrainerList] = useState([]);

  const { data: dataUser } = useQuery(
    ['getContentManagementUserLdapAPI', searchTrainer],
    getElearningUserLdapAPI,
    {
      enabled: searchTrainer !== undefined && searchTrainer !== '',
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };

  const handleSearch = useDebouncedCallback((value: any, form: any) => {
    if (value !== '' || !value) {
      setSearchTrainer(value);
      const tempArr: any = trainerList.length > 0 ? [...trainerList] : [];

      if (dataUser && dataUser.id !== 0)
        tempArr.push({ id: dataUser?.id, name: dataUser?.username || '' });
      setTrainerList(tempArr);
    }
  }, 1000);

  const mutate = useMutation(mutateElearningChannelAPI, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage
            id={
              selectedData
                ? 'updateChannelSuccess'
                : 'createChannelElearningSuccess'
            }
          />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
      handleRefresh();
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const initialValues: any = selectedData;

  const onSubmit = vals => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  return (
    <ChannelModalComponent
      isShow={showModal}
      handleCancel={handleCancel}
      handleSearch={handleSearch[0]}
      onSubmit={onSubmit}
      initialValues={initialValues}
      modalAction={selectedData ? 'update' : 'register'}
      editMode={editMode}
      trainerList={trainerList}
      isLoading={mutate.isLoading}
    />
  );
}

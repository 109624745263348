import Declined from '../Assets/Images/stop.png';
import { Empty } from 'antd';
import React from 'react';
import Store from '../Store';

export default function Role(ComposedComponent: any) {
  const state: any = Store.getState();

  const grantRole = (props: any) => {
    const { location } = props;
    const { permission } = state;

    let granted = false;
    if (permission) {
      for (const iterator of permission) {
        if (
          Object.keys(iterator)[0] === location.pathname ||
          ((Object.keys(iterator)[0] === '/dashboard/dosd' ||
            Object.keys(iterator)[0] === '/dashboard/elearning' ||
            Object.keys(iterator)[0] === '/dashboard/trainer') &&
            location.pathname === '/')
        ) {
          granted = true;
          break;
        }
      }
    }
    if (granted) {
      return <ComposedComponent />;
    } else {
      return (
        <Empty
          image={Declined}
          imageStyle={{
            height: 150,
            marginTop: '22vh',
            marginBottom: '30px',
          }}
          description={
            <span style={{ color: 'white' }}>
              You don't have permission to access this page!
            </span>
          }
        />
      );
    }
  };

  return grantRole;
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row } from 'antd';
import { FormApi } from 'final-form';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import CButton from '../../../Assets/Components/CButton';
import CIcon from '../../../Assets/Components/CIcon';
import { getNonSelfTrainingChannelAPI } from '../../../Services/channel.api';
import {
  downloadLearningReportAPI,
  getClassByChannelIdAPI,
  getLearningReportAPI,
  getModuleByChannelIdAPI,
} from '../../../Services/ElearningLearningReport/learningReport.api';
import LearningReportComponent from '../Component/LearningReportComponent';
import FeedbackDetailModalContainer from './ModalFeedbackDetailContainer';
import ModalDownloadNotificationContainer from './ModalDownloadNotificationContainer';
import { getElearningChannelAPI } from '../../../Services/ElearningContentManagement/channel.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function LearningReportContainer() {
  const [isFilter, setIsFilter] = useState(false);
  const [showModalFeedback, setShowModalFeedback] = useState(false);
  const [showModalNotification, setShowModalNotification] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [channelId, setChannelId] = useState();
  const [fileType, setFileType] = useState('');
  const [filterParams, setFilterParams] = useState<{
    channelCode?: number;
    channelId?: number;
    classId?: number;
    classResult?: string;
    classStatus?: string;
    endDate?: string;
    moduleId?: string;
    reportType?: string;
    startDate?: string;
    typeSchedule?: string;
  }>();

  const { data: dataChannel } = useQuery(
    ['getContentManagementChannelAPI'],
    getElearningChannelAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );
  const channelList: any = [];
  (dataChannel || []).forEach(channel => {
    const { id, code, name } = channel;
    channelList.push({
      id: `${id}|${code}`,
      code,
      name,
    });
  });

  const { data: channelNonSelfList } = useQuery(
    ['getNonSelfChannelAPI', true],
    getNonSelfTrainingChannelAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  const { data: moduleList, fetchStatus: fetchStatusModule } = useQuery(
    ['getModuleByChannelIdAPI', channelId],
    getModuleByChannelIdAPI,
    {
      enabled: channelId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: classList, fetchStatus: fetchStatusClass } = useQuery(
    ['getClassByChannelIdAPI', channelId],
    getClassByChannelIdAPI,
    {
      enabled: channelId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataReport,
    fetchStatus: fetchStatusReport,
    refetch: refetchReport,
  } = useQuery(
    [
      'getReportAPI',
      {
        channelCode: filterParams?.channelCode,
        classId: filterParams?.classId,
        classResult: filterParams?.classResult,
        classStatus: filterParams?.classStatus,
        endDate: filterParams?.endDate,
        moduleId: filterParams?.moduleId,
        reportType: filterParams?.reportType,
        startDate: filterParams?.startDate,
        typeSchedule: filterParams?.typeSchedule,
      },
    ],
    getLearningReportAPI,
    {
      enabled: false,
      refetchOnWindowFocus: false,
    }
  );

  const downloadReport = useMutation(downloadLearningReportAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      setFileType('');
    },
  });

  useEffect(() => {
    if (!filterParams) return;
    refetchReport();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterParams]);

  const handleSearch = (
    form: FormApi<any, Partial<any>>,
    values: any,
    type: 'main' | 'filter'
  ) => {
    setIsFilter(true);
    setFilterParams({
      channelCode: values?.channelFilter
        ? values?.channelFilter.split('|')[1]
        : '',
      channelId: values?.channelIdFilter
        ? values?.channelIdFilter
        : values?.channelFilter
        ? parseInt(values?.channelFilter.split('|')[0])
        : '',
      classId: values?.classFilter || '',
      classResult: values?.resultFilter || '',
      classStatus: values?.statusFilter || '',
      endDate:
        values?.dateFilter && values?.dateFilter.length > 0
          ? values?.dateFilter[1]._d
          : '',
      moduleId: values?.moduleFilter || '',
      reportType: values?.learningReportType || '',
      startDate:
        values?.dateFilter && values?.dateFilter.length > 0
          ? values?.dateFilter[0]._d
          : '',
      typeSchedule: values?.typeScheduleFilter || '',
    });

    if (type === 'main') {
      form.change('channelFilter', null);
      form.change('moduleFilter', null);
      form.change('formatReport', null);
      form.change('formatReport', null);
      form.change('typeScheduleFilter', null);
      form.change('classFilter', null);
      form.change('statusFilter', null);
      form.change('resultFilter', null);
    }
  };

  const handleOnChangeReportType = (
    form: FormApi<any, Partial<any>>,
    reportType: string
  ) => {
    const setToday = [moment().startOf('second'), moment().endOf('second')];
    form.change('channelFilter', null);
    form.change('channelIdFilter', null);
    form.change('classFilter', null);
    form.change('moduleFilter', null);
    form.change('dateFilter', reportType === 'sessionPin' ? setToday : null);
    form.change('typeScheduleFilter', null);
    form.change('statusFilter', null);
    form.change('resultFilter', null);
    setIsFilter(false);
    setChannelId(undefined);
  };

  const handleOnChangeChannelWithModuleFilter = (
    e: any,
    form: FormApi<any, Partial<any>>
  ) => {
    form.change('moduleFilter', null);
    const id = e?.split('|');
    setChannelId(id[0] || undefined);
  };

  const handleOnChangeChannelWithClassFilter = (
    channelId: any,
    form: FormApi<any, Partial<any>>
  ) => {
    form.change('classFilter', null);
    setChannelId(channelId || undefined);
  };

  const handleResetFilter = (form: FormApi<any, Partial<any>>) => {
    form.change('channelFilter', null);
    form.change('channelIdFilter', null);
    form.change('moduleFilter', null);
    form.change('dateFilter', null);
    form.change('formatReport', null);
    form.change('learningReportType', null);
    form.change('typeScheduleFilter', null);
    form.change('classFilter', null);
    form.change('statusFilter', null);
    form.change('resultFilter', null);
    setIsFilter(false);
  };

  const handleOnChangeDownloadFile = (e: string) => setFileType(e);

  const handleDownloadLearningReport = async (
    learningReportType: string,
    formValues?: any
  ) => {
    if (
      learningReportType === 'learningHour' ||
      learningReportType === 'trainingAttendance'
    )
      setShowModalNotification(true);

    if (formValues) {
      downloadReport.mutate({
        channelCode: formValues?.channelFilter
          ? formValues.channelFilter.split('|')[1]
          : '',
        channelId: formValues?.channelIdFilter,
        classId: formValues?.classFilter,
        endDate:
          formValues?.dateFilter && formValues?.dateFilter.length > 0
            ? formValues?.dateFilter[1]._d
            : '',
        formatReport: fileType || 'xlsx',
        moduleId: formValues?.moduleFilter,
        reportType: learningReportType,
        result: formValues?.resultFilter,
        startDate:
          formValues?.dateFilter && formValues?.dateFilter.length > 0
            ? formValues?.dateFilter[0]._d
            : '',
        status: formValues?.statusFilter,
        typeSchedule: formValues?.typeScheduleFilter,
      });
    } else
      downloadReport.mutate({
        channelCode: filterParams?.channelCode,
        channelId: filterParams?.channelId,
        classId: filterParams?.classId,
        endDate: filterParams?.endDate,
        formatReport: fileType || 'xlsx',
        moduleId: filterParams?.moduleId,
        reportType: learningReportType,
        result: filterParams?.classResult,
        startDate: filterParams?.startDate,
        status: filterParams?.classStatus,
        typeSchedule: filterParams?.typeSchedule,
      });
  };

  const renderAction = row => {
    const rowData = row.row.original;
    const handleOnclick = () => {
      setSelectedData(rowData);
      setShowModalFeedback(true);
    };
    const dataButton = [
      {
        id: 'btnDetailFeedback',
        type: 'primary',
        icon: 'EyeOutlined',
        className: 'btnDetail',
        onClick: () => handleOnclick(),
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const generateFormData = (reportTypeForm: string) => {
    const formatDOB = 'DD-MMM-YYYY';
    let columnData: object = [];

    switch (reportTypeForm) {
      case 'journey':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Kode Agen',
            accessor: 'agentCode',
          },
          {
            Header: 'Nama Agen',
            accessor: 'agentName',
          },
          {
            Header: 'Level Agen',
            accessor: 'agentLevelDesc',
          },
          {
            Header: 'Channel',
            accessor: 'channelCode',
          },
          {
            Header: 'Status Training',
            accessor: 'statusTraining',
          },
          {
            Header: 'Sub-Module',
            accessor: 'subModuleName',
          },
        ];
        break;
      case 'progress':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Kode Agen',
            accessor: 'agentCode',
          },
          {
            Header: 'Nama Agen',
            accessor: 'agentName',
          },
          {
            Header: 'Channel',
            accessor: 'channelCode',
          },
          {
            Header: 'Level Agen',
            accessor: 'agentLevelDesc',
          },
          {
            Header: 'Status Learning',
            accessor: 'statusTraining',
          },
          {
            Header: 'Sub-Module',
            accessor: 'subModuleName',
          },
        ];
        break;
      case 'exam':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Kode Agen',
            accessor: 'agentCode',
          },
          {
            Header: 'Nama Agen',
            accessor: 'agentName',
          },
          {
            Header: 'Level',
            accessor: 'agentLevelDesc',
            Cell: row =>
              row.row.original.agentLevelDesc
                ? row.row.original.agentLevelDesc
                : '-',
          },
          {
            Header: 'Status Ujian',
            accessor: 'examStatus',
          },
          {
            Header: 'Sub-Module',
            accessor: 'subModuleName',
          },
          {
            Header: 'Score',
            accessor: 'score',
          },
        ];
        break;
      case 'selfLearning':
      case 'nonSelfLearning':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Kode Agen',
            accessor: 'agentCode',
          },
          {
            Header: 'Nama Agen',
            accessor: 'agentName',
          },
          {
            Header: 'Nama Trainer',
            accessor: 'trainerName',
          },
          {
            Header: 'Feedback Category',
            accessor: 'category',
          },
          {
            Header: 'Sub-Module',
            accessor: 'subModuleName',
          },
          {
            Header: ' ',
            Cell: row => renderAction(row),
          },
        ];
        break;
      case 'sessionPin':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Nama Kelas',
            accessor: 'className',
          },
          {
            Header: 'Nama Trainer',
            accessor: 'trainerName',
            Cell: row =>
              row.row.original.trainerName ? row.row.original.trainerName : '-',
          },
          {
            Header: 'Nama Sesi',
            accessor: 'sessionName',
            Cell: (row: any) =>
              row.row.original.sessionName ? row.row.original.sessionName : '-',
          },
          {
            Header: 'Tanggal',
            accessor: 'sessionDate',
            Cell: (row: any) =>
              row.row.original.sessionDate
                ? moment(row.row.original.sessionDate).format(formatDOB)
                : '-',
          },
          {
            Header: 'Check In Pin',
            accessor: 'checkInPin',
          },
          {
            Header: 'Check Out Pin',
            accessor: 'checkOutPin',
          },
        ];
        break;
      case 'trainingAttendance':
        columnData = [
          {
            id: 'expander',
            Cell: ({ row }) => (
              <span {...row.getToggleRowExpandedProps()}>
                {row.isExpanded ? (
                  <CIcon type="CaretDownOutlined" />
                ) : (
                  <CIcon type="CaretRightOutlined" />
                )}
              </span>
            ),
          },
          {
            Header: 'Agent Name',
            accessor: 'agentName',
          },
          {
            Header: 'Agent Code',
            accessor: 'agentCode',
          },
          {
            Header: 'Nama Kelas',
            accessor: 'className',
          },
          {
            Header: 'Status',
            accessor: (row: any) => renderSearchStatus(row),
            Cell: (row: any) => renderStatusJourney(row),
          },
          {
            Header: 'Result',
            accessor: 'result',
            Cell: (row: any) =>
              row.row.original.result ? row.row.original.result : '-',
          },
        ];
        break;
      case 'learningHour':
        columnData = [
          {
            Header: 'Agent Name',
            accessor: 'agentName',
          },
          {
            Header: 'Agent Code',
            accessor: 'agentCode',
          },
          {
            Header: 'Learning Hour Self Learning',
            accessor: 'learningHourSelfDesc',
            Cell: (row: any) =>
              row.row.original.learningHourSelfDesc
                ? row.row.original.learningHourSelfDesc
                : '-',
          },
          {
            Header: 'Total Module',
            accessor: 'totalModule',
          },
          {
            Header: 'Learning Hour Non Self Learning',
            accessor: 'learningHourNonSelfDesc',
            Cell: (row: any) =>
              row.row.original.learningHourNonSelfDesc
                ? row.row.original.learningHourNonSelfDesc
                : '-',
          },
          {
            Header: 'Total Kelas',
            accessor: 'totalClass',
          },
          {
            Header: 'Total Learning Hour',
            accessor: 'totalLearningHour',
            Cell: (row: any) =>
              row.row.original.totalLearningHour
                ? row.row.original.totalLearningHour
                : '-',
          },
        ];
        break;
      default:
        break;
    }
    return columnData;
  };

  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponentJourney = React.useCallback(({ row }) => {
    const { moduleName, stageName, channelCode, agentStatus, completedDate } =
      row.original;
    const trainingCompletedDate = completedDate
      ? moment(completedDate).format('DD-MMM-YYYY')
      : '01-Jan-0001';
    return (
      <div>
        {renderCol(
          'SOB',
          channelCode === null || channelCode === '' ? '-' : channelCode
        )}
        {renderCol(
          'Stage',
          stageName === null || stageName === '' ? '-' : stageName
        )}
        {renderCol(
          'Module',
          moduleName === null || moduleName === '' ? '-' : moduleName
        )}
        {renderCol(
          'Status',
          agentStatus === null || agentStatus === '' ? '-' : agentStatus
        )}
        {renderCol(
          'Tanggal Selesai',
          trainingCompletedDate === '01-Jan-0001' ? '-' : trainingCompletedDate
        )}
      </div>
    );
  }, []);

  const renderRowSubComponentProgress = React.useCallback(({ row }) => {
    const {
      moduleName,
      stageName,
      startedDate,
      agentStatus,
      learningHourDesc,
    } = row.original;
    const learningStartedDate = startedDate
      ? moment(startedDate).format('DD-MMM-YYYY')
      : '01-Jan-0001';
    return (
      <div>
        {renderCol('Stage', stageName ? stageName : '-')}
        {renderCol('Module', moduleName ? moduleName : '-')}
        {renderCol(
          'Date',
          learningStartedDate === '01-Jan-0001' ? '-' : learningStartedDate
        )}
        {renderCol('Status', agentStatus ? agentStatus : '-')}
        {renderCol('Learning Hours', learningHourDesc ? learningHourDesc : '-')}
      </div>
    );
  }, []);

  const renderRowSubComponentExam = React.useCallback(({ row }) => {
    const { moduleName, agentStatus, startedDate, stageName, channelCode } =
      row.original;
    const examDate = startedDate
      ? moment(startedDate).format('DD-MMM-YYYY')
      : '01-Jan-0001';
    return (
      <div>
        {renderCol(
          'Channel',
          channelCode === null || channelCode === '' ? '-' : channelCode
        )}
        {renderCol(
          'Status Agen',
          agentStatus === null || agentStatus === '' ? '-' : agentStatus
        )}
        {renderCol(
          'Stage',
          stageName === null || stageName === '' ? '-' : stageName
        )}
        {renderCol(
          'Module',
          moduleName === null || moduleName === '' ? '-' : moduleName
        )}
        {renderCol(
          'Tanggal Ujian',
          examDate === '01-Jan-0001' ? '-' : examDate
        )}
      </div>
    );
  }, []);

  const renderRowSubComponentFeedback = React.useCallback(({ row }) => {
    const {
      agentStatus,
      submittedDate,
      agentLevelDesc,
      stageName,
      moduleName,
      totalPoint,
      totalFeedbackQuestion,
    } = row.original;
    return (
      <div>
        {renderCol(
          'Date',
          submittedDate ? moment(submittedDate).format('DD-MMM-YYYY') : '-'
        )}

        {renderCol(
          'Level Agen',
          agentLevelDesc === null || agentLevelDesc === ''
            ? '-'
            : agentLevelDesc
        )}
        {renderCol(
          'Status',
          agentStatus === null || agentStatus === '' ? '-' : agentStatus
        )}
        {renderCol(
          'Stage',
          stageName === null || stageName === '' ? '-' : stageName
        )}
        {renderCol(
          'Module',
          moduleName === null || moduleName === '' ? '-' : moduleName
        )}
        {renderCol(
          'Total Pertanyaan',
          totalFeedbackQuestion === null || totalFeedbackQuestion === ''
            ? '-'
            : totalFeedbackQuestion
        )}
        {renderCol(
          'Jumlah Poin',
          totalPoint === null || totalPoint === '' ? '-' : totalPoint
        )}
      </div>
    );
  }, []);

  const renderRowSubComponentReportPin = React.useCallback(({ row }) => {
    const {
      scheduleName,
      scheduleType,
      platform,
      address,
      cityName,
      startTime,
      endTime,
    } = row.original;
    return (
      <div>
        {renderCol('Nama Schedule', scheduleName ? scheduleName : '-')}
        {renderCol('Tipe Schedule', scheduleType ? scheduleType : '-')}
        {renderCol('Platform', platform ? platform : '-')}
        {renderCol('Venue', address ? address : '-')}
        {renderCol('City', cityName ? cityName : '-')}
        {renderCol('Jam Mulai', startTime ? startTime : '-')}
        {renderCol('Jam selesai', endTime ? endTime : '-')}
      </div>
    );
  }, []);

  const renderRowSubComponentTrainingAttendance = React.useCallback(
    ({ row }) => {
      const { enrollmentDate, scheduleName, startDate, completionDate } =
        row.original;
      return (
        <div>
          {renderCol(
            'Enrollment Date',
            enrollmentDate ? moment(enrollmentDate).format('DD-MMM-YYYY') : '-'
          )}
          {renderCol('Schedule Name', scheduleName ? scheduleName : '-')}
          {renderCol(
            'Start Date',
            startDate ? moment(startDate).format('DD-MMM-YYYY') : '-'
          )}
          {renderCol(
            'Completion Date',
            completionDate ? moment(completionDate).format('DD-MMM-YYYY') : '-'
          )}
        </div>
      );
    },
    []
  );

  const renderSearchStatus = (row: any) => {
    if (row.status === 'not_started') return 'Not Started';
    else if (row.status === 'on_progress') return 'In Progress';
    else if (row.status === 'completed') return 'Completed';
    else return row.status;
  };

  const renderStatusJourney = (row: any) => {
    if (row.row.original?.status === 'not_started') return 'Not Started';
    else if (row.row.original?.status === 'on_progress') return 'In Progress';
    else if (row.row.original?.status === 'completed') return 'Completed';
    else return row.row.original?.status;
  };

  return (
    <>
      <LearningReportComponent
        channelList={channelList}
        channelNonSelfList={channelNonSelfList || []}
        classList={classList || []}
        columnData={generateFormData}
        handleDownloadLearningReport={handleDownloadLearningReport}
        handleOnChangeChannelWithClassFilter={
          handleOnChangeChannelWithClassFilter
        }
        handleOnChangeChannelWithModuleFilter={
          handleOnChangeChannelWithModuleFilter
        }
        handleOnChangeDownloadFile={handleOnChangeDownloadFile}
        handleOnChangeReportType={handleOnChangeReportType}
        handleResetFilter={handleResetFilter}
        handleSearch={handleSearch}
        handleSubmit={() => {}}
        isFilter={isFilter}
        isLoading={fetchStatusReport === 'fetching'}
        isLoadingDownload={downloadReport.isLoading}
        isLoadingFilter={
          fetchStatusClass === 'fetching' ||
          fetchStatusModule === 'fetching' ||
          fetchStatusReport === 'fetching'
        }
        learningReportList={dataReport || []}
        moduleList={moduleList || []}
        renderRowSubComponentExam={renderRowSubComponentExam}
        renderRowSubComponentFeedback={renderRowSubComponentFeedback}
        renderRowSubComponentJourney={renderRowSubComponentJourney}
        renderRowSubComponentTrainingAttendance={
          renderRowSubComponentTrainingAttendance
        }
        renderRowSubComponentProgress={renderRowSubComponentProgress}
        renderRowSubComponentReportPin={renderRowSubComponentReportPin}
      />
      {showModalFeedback && (
        <FeedbackDetailModalContainer
          isShow={showModalFeedback}
          setShowModal={setShowModalFeedback}
          selectedFeedbackDetail={selectedData}
          setSelectedFeedbackDetail={setSelectedData}
        />
      )}
      {showModalNotification && (
        <ModalDownloadNotificationContainer
          isShow={showModalNotification}
          setShowModal={setShowModalNotification}
        />
      )}
    </>
  );
}

import React from 'react';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';

interface IProps {
  modalValidationIsShow: any;
  handleCancel: () => void;
  resultValidation: any;
}
export default function ModalValidationComponent(props: IProps) {
  const { modalValidationIsShow, handleCancel, resultValidation } = props;

  const buttonData = [
    {
      type: 'primary',
      className: 'btnClodeValidationModal',
      onClick: handleCancel,
      content: 'Tutup',
      id: 'btnCloseValidation',
    },
  ];

  const contentComponent = () => {
    return (
      <div className="contentModalValidation">
        <div>
          <CIcon
            type="InfoCircleFilled"
            style={{ fontSize: '1.5em', color: '#ffe17d' }}
          />
        </div>
        <br></br>
        <p className="textBold">
          Beberapa data sudah pernah/digunakan oleh kandidat lain!
        </p>
        <br></br>
        {resultValidation?.map((x: any, i: number) => (
          <p key={x.name}>
            {i + 1}.
            <b>
              {x.name === 'IdNumber'
                ? 'NIK'
                : x.name === 'Email'
                ? 'Email'
                : x.name === 'PhoneNumber'
                ? 'Telepon HP'
                : x.name}
            </b>{' '}
            ({x.value}) telah digunakan oleh{' '}
            <span className="labelRequired">{x?.usedBy}</span>
          </p>
        ))}
        <br></br>
        <p>Silahkan isi kembali menggunakan data lainnya</p>
        <div style={{ textAlign: 'center' }}>
          <CButton buttonData={buttonData} />
        </div>
      </div>
    );
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      initialValues={{}}
      headerComponent={null}
      contentComponent={contentComponent()}
      footerComponent={null}
      modalIsShow={modalValidationIsShow}
      handleCancel={handleCancel}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '500px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

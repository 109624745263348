import { UseQueryOptions } from '@tanstack/react-query';
import { toBase64 } from '../../App/Helper';
import {
  IResultContestRewardDAO,
  IResultContestRewardDetailDAO,
} from '../../Modules/ContestReward/DAO/contestReward.dao';
import instance from '../interceptor';

export const getContestRewardAPI = async (): Promise<
  IResultContestRewardDAO[]
> => {
  const { data } = await instance.get(`/contest/reward/`);
  return data;
};

export const deleteContestRewardAPI = async (id: string) => {
  return await instance.delete(`/contest/reward/${id}`);
};

export const getContestRewardDetailAPI = async (
  params: UseQueryOptions
): Promise<IResultContestRewardDetailDAO> => {
  const id = params?.queryKey?.[1];
  const { data } = await instance.get(`/contest/reward/detail/${id}`);
  data.rewardPicture = null;
  return data;
};

export const mutateContestRewardAPI = async (params: any) => {
  const {
    documentType,
    editMode,
    extension,
    fileName,
    fileDocument,
    id,
    name,
    rewardPicture,
    size,
  } = params;
  let payload: any;

  if (editMode && rewardPicture === null) {
    payload = {
      name,
      documentType,
      fileName,
      size,
      extension,
      fileDocument,
    };
  } else {
    const fileDetail = rewardPicture[0];
    const resBase64: any = await toBase64(fileDetail.file);
    payload = {
      name,
      documentType: fileDetail.type,
      fileName: fileDetail.name,
      size: fileDetail.size,
      extension: fileDetail.extension,
      fileDocument: resBase64.split(',')[1],
    };
  }

  if (id) {
    return await instance.put(`/contest/reward/${id}`, payload);
  } else {
    return await instance.post(`/contest/reward`, payload);
  }
};

import ModalDetailUploadScheduleComponent from '../Component/ModalDetailUploadScheduleComponent';
import React from 'react';
interface IProps {
  modalDetailScheduleIsShow: boolean;
  setModalDetailScheduleIsShow: any;
  selectedData: any;
}

export default function ModalDetailUploadSheduleContainer(props: IProps) {
  const {
    modalDetailScheduleIsShow,
    setModalDetailScheduleIsShow,
    selectedData,
  } = props;

  const handleCancel = () => {
    setModalDetailScheduleIsShow(false);
  };
  return (
    <ModalDetailUploadScheduleComponent
      handleCancel={handleCancel}
      modalDetailScheduleIsShow={modalDetailScheduleIsShow}
      selectedData={selectedData}
    />
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import { Col, Row, Switch } from 'antd';

import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import ChannelComponent from '../Component/ChannelComponent';
import ChannelModalContainer from './ChannelModalContainer';
import {
  deleteElearningChannelAPI,
  getElearningChannelAPI,
  mutateElearningChannelAPI,
} from '../../../../Services/ElearningContentManagement/channel.api';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
}

export default function ChannelContainer({
  setTabData,
  setAllDataList,
}: IProps) {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [filter, setFilter] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  const {
    data: dataChannel = [],
    isLoading: isLoadingChannel,
    isRefetching: isRefetchChannel,
    refetch,
  } = useQuery(['getContentManagementChannelAPI'], getElearningChannelAPI, {
    refetchOnWindowFocus: false,
  });

  const deleteChannel = useMutation(deleteElearningChannelAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="deleteChannelElearningSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetch();
    },
  });

  const mutateChannel = useMutation(mutateElearningChannelAPI, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id={'updateChannelSuccess'} />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetch();
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const processStatusChannel = (id: number, status: any, body: any) => {
    mutateChannel.mutate({ ...body, isActive: status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const body = {
      code: oriObj.code,
      name: oriObj.name,
      headOfTrainer: oriObj.headOfTrainer,
      signOffCaption: oriObj.signOffCaption,
      isActive: status,
      trainerName: oriObj.trainerName,
    };
    const action = status === true ? 'mengaktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusChannel(id, status, body),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Channel "${row.row.original.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteChannel.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Channel "${rows.name}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteChannel`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
      {
        id: `btnEditChannel`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
      {
        id: 'btnNextChannel',
        icon: 'ArrowRightOutlined',
        onClick: () => {
          setFilter(undefined);
          setSelectedStatus(undefined);
          setTabData(prev => ({ ...prev, channel: rows }));
          setAllDataList(prev => ({ ...prev, channelList: dataChannel }));
        },
        className: 'btnNext',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Channel Code',
      accessor: 'code',
    },
    {
      Header: 'Channel Name',
      accessor: 'name',
    },
    {
      Header: 'Head of Trainer',
      accessor: 'headOfTrainer',
    },
    {
      Header: 'Sign Off Caption',
      accessor: 'signOffCaption',
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const renderCol = (label, content) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const {
      createdDate,
      totalStage,
      totalModule,
      totalSubModule,
      totalContent,
    } = row.original;
    const createDate = moment(createdDate).format('DD-MMM-YYYY');
    return (
      <div>
        {renderCol(
          'Created Date',
          createDate === '01-Jan-0001' ? '-' : createDate
        )}
        {renderCol(
          'Stage',
          totalStage === null || totalStage === '' ? '-' : totalStage
        )}

        {renderCol(
          'Total Module',
          totalModule === null || totalModule === '' ? '-' : totalModule
        )}

        {renderCol(
          'Total Sub-Module',
          totalSubModule === null || totalSubModule === ''
            ? '-'
            : totalSubModule
        )}
        {renderCol(
          'Total Content',
          totalContent === null || totalContent === '' ? '-' : totalContent
        )}
      </div>
    );
  }, []);

  const handleApplyfilter = () => {
    setFilter(selectedStatus);
  };

  const handleRefresh = () => {
    refetch();
    setSelectedStatus(undefined);
    setFilter(undefined);
  };

  return (
    <>
      <ChannelComponent
        columnData={columnData}
        dataChannel={
          filter === 'active'
            ? dataChannel.filter(item => item.isActive)
            : filter === 'inactive'
            ? dataChannel.filter(item => !item.isActive)
            : dataChannel
        }
        renderRowSubComponent={renderRowSubComponent}
        handleRefresh={handleRefresh}
        isLoading={
          isLoadingChannel ||
          isRefetchChannel ||
          deleteChannel.isLoading ||
          mutateChannel.isLoading
        }
        setModalIsShow={setModalIsShow}
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        handleApplyfilter={handleApplyfilter}
      />
      <ChannelModalContainer
        showModal={modalIsShow}
        setShowModal={setModalIsShow}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

import instance from '../interceptor';

const getStage = async params => {
  const { channelId } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/Stage?channelId=${channelId}`
  );
  return data;
};

const deleteStage = async (id: string) => {
  return await instance.delete(`/Elearning/Stage/${id}`);
};

const mutateStage = async params => {
  const { name, description, predecessor, sequence, isActive, channelId, id } =
    params;
  const payload: any = {
    name,
    description,
    predecessor: predecessor ? predecessor : null,
    sequence: sequence ? sequence : null,
    isActive: isActive !== undefined ? isActive : false,
    channelId,
  };

  if (id) {
    return await instance.put(`/Elearning/Stage/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/Stage`, payload);
  }
};

export const contentManagementStageAPI = {
  getStage,
  deleteStage,
  mutateStage,
};

import { Tabs } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import CityListTabContainer from '../Container/CityListTabContainer';
import CityHistoryTabContainer from '../Container/CityHistoryTabContainer';

interface IProps {
  intl: any;
}

function CityComponent(props: IProps) {
  const { intl } = props;
  const { TabPane } = Tabs;
  const cardCityContent = () => {
    const cardTabPane = (title: string, id: any) => {
      return (
        <div className="tabPaneContestUpload" id={id}>
          {title}
        </div>
      );
    };
    return (
      <Tabs defaultActiveKey="uploadFile" className="tabContestUpload">
        <TabPane
          tab={cardTabPane('List of City', 'tabListOfCity')}
          key="uploadFile"
        >
          <CityListTabContainer intl={intl} />
        </TabPane>
        <TabPane
          tab={cardTabPane('Upload & History', 'tabUploadHistory')}
          key="historyUpload"
        >
          <CityHistoryTabContainer />
        </TabPane>
      </Tabs>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="City" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'cityCardTitle',
            })}
          </span>
        }
        cardContent={cardCityContent()}
      />
    </React.Fragment>
  );
}
export default injectIntl(CityComponent);

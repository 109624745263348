import React, { Dispatch, SetStateAction, useState } from 'react';
import ModalMoveLibraryComponent from '../Component/ModalMoveLibraryComponent';
import { mutateMoveLibraryAPI } from '../../../Services/library.api';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  moveType: string;
  refetch: any;
  modalMoveShow: boolean;
  libraryCollection: any;
  selectedMovePath: never[];
  setSelectedMovePath: any;
  selectedData: any;
  setLibraryCollection: any;
  setModalMoveShow: Dispatch<SetStateAction<boolean>>;
  setModalUploadIsShow: Dispatch<SetStateAction<boolean>>;
  setIsRoot: Dispatch<SetStateAction<boolean>>;
  setModalFolderIsShow: Dispatch<SetStateAction<boolean>>;
  setSelectedData: Dispatch<SetStateAction<undefined>>;
}
export default function ModalMoveLibraryContainer(props: IProps) {
  const {
    modalMoveShow,
    libraryCollection,
    selectedMovePath,
    setSelectedMovePath,
    selectedData,
    setLibraryCollection,
    refetch,
    moveType,
    setIsRoot,
    setModalMoveShow,
    setSelectedData,
    setModalFolderIsShow,
    setModalUploadIsShow,
  } = props;

  const [selectedLastCollection, setSelectedLastCollection] = useState([]);

  const { mutate: mutateMoveLibrary, isLoading: isMoving } = useMutation(
    mutateMoveLibraryAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(<span className="capitalFirst">Move success!</span>);
        setModalMoveShow(false);
        setIsRoot(true);
        setSelectedData(undefined);
        refetch();
        if (moveType === 'file') {
          setModalUploadIsShow(false);
        } else {
          setModalFolderIsShow(false);
        }
      },
    }
  );

  const handleCancel = () => {
    setModalMoveShow(false);
    setSelectedMovePath(null);
  };

  const handleSelectedFolderCollection = (
    last: any,
    content: any,
    path: any
  ) => {
    const pathData: any = selectedMovePath;
    pathData.push(path);
    setSelectedMovePath(pathData);
    setSelectedLastCollection(last);
    const lastCollection: any = selectedLastCollection;
    lastCollection.push(last);
    setSelectedLastCollection(lastCollection);
    const filteredData: any = [];
    for (const key in content) {
      if (Object.prototype.hasOwnProperty.call(content, key)) {
        const element = content[key];
        if (element.type === 'folder') {
          filteredData.push(element);
        }
      }
    }
    setLibraryCollection(filteredData);
  };

  const handleSubmitMove = () => {
    mutateMoveLibrary({ selectedMovePath, selectedData });
  };

  const handleBack = () => {
    const collection = selectedLastCollection;
    if (collection?.length > 0) {
      setLibraryCollection(collection[collection?.length - 1]);
      collection?.pop();
      setSelectedLastCollection(collection);
      const pathData: any = selectedMovePath;
      pathData.pop();
      setSelectedMovePath(pathData);
    }
  };

  return (
    <ModalMoveLibraryComponent
      modalMoveShow={modalMoveShow}
      handleCancel={handleCancel}
      handleSelectedFolderCollection={handleSelectedFolderCollection}
      collectionList={libraryCollection}
      selectedMovePath={selectedMovePath}
      handleSubmitMove={handleSubmitMove}
      isMoving={isMoving}
      handleBack={handleBack}
    />
  );
}

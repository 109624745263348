import React, { useState } from 'react';
import CityHistoryTabComponent from '../Component/CityHistoryTabComponent';
import HasPermission from '../../../App/HasPermission';
import { useQuery } from '@tanstack/react-query';
import { getCityHistoryListAPI } from '../../../Services/city.api';
import moment from 'moment';
import { formatDate, formatTime } from '../../../App/Enums';
import { renderActionComponent } from '../../../Assets/Components/CTable';

export default function CityHistoryTabContainer() {
  const [modalInfoIsShow, setModalInfoIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState(false);
  const [modalUploadIsShow, setModalUploadIsShow] = useState(false);

  const {
    data: historyList,
    fetchStatus,
    isLoading,
    isRefetching,
    refetch: refetchList,
  } = useQuery(['getCityHistoryList'], getCityHistoryListAPI, {
    enabled: !modalUploadIsShow,
    refetchOnWindowFocus: false,
  });

  const renderFileName = (row: any) => {
    const rows = row.row.original;
    const { regionFailedDetails } = rows;
    const selectedStatus =
      Object.keys(regionFailedDetails).length > 0
        ? 'regionFailedDetails'
        : 'regionSuccessDetails';
    return rows[selectedStatus][0].originalFilename;
  };

  const renderStatus = (row: any) => {
    const { regionFailedDetails } = row.row.original;
    return Object.keys(regionFailedDetails).length > 0 ? 'Failed' : 'Success';
  };

  const renderUploadDate = (row: any) => {
    const { regionFailedDetails } = row.row.original;
    const selectedStatus =
      Object.keys(regionFailedDetails).length > 0
        ? 'regionFailedDetails'
        : 'regionSuccessDetails';
    const dataHistory = row.row.original[selectedStatus];
    return moment(dataHistory[0].uploadDate).format(
      `${formatDate} ${formatTime}`
    );
  };

  const renderAction = (row: any) => {
    return renderActionComponent(
      isLoading,
      row.row.id,
      {
        renderInfo: true,
      },
      {
        handleInfo: () => {
          setSelectedData(row.row.original);
          setModalInfoIsShow(true);
        },
      }
    );
  };

  const columnData = [
    {
      Header: 'File Name',
      accessor: 'filenameregionFailedDetails',
      Cell: renderFileName,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: renderStatus,
    },
    {
      Header: 'Upload Date',
      accessor: 'uploaddate',
      Cell: renderUploadDate,
    },
    HasPermission('u')
      ? {
          Header: 'Action',
          Cell: (row: any) => renderAction(row),
        }
      : {
          accessor: 'hideAction',
        },
  ];
  return (
    <CityHistoryTabComponent
      columnData={columnData}
      historyList={historyList || []}
      isLoadingList={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      handleRefresh={refetchList}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      modalInfoIsShow={modalInfoIsShow}
      setModalInfoIsShow={setModalInfoIsShow}
      modalUploadIsShow={modalUploadIsShow}
      setModalUploadIsShow={setModalUploadIsShow}
    />
  );
}

import { Button, Form as FormAntd, Layout, Skeleton } from 'antd';

import CIcon from './CIcon';
import { Form } from 'react-final-form';
import Modal from 'react-modal';
import React from 'react';
import arrayMutators from 'final-form-arrays';

interface IProps {
  headerComponent: any;
  contentComponent: any;
  footerComponent: any;
  isLoading?: boolean;
  modalIsShow: boolean;
  handleCancel: () => void;
  handleSubmit: (val: any) => void;
  handleValidation?: any;
  modalstyles?: any;
  isForm?: boolean;
  formLayout?: any;
  contentClassName?: any;
  initialValues: any;
  formRef?: any;
}

const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '1000px',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '99',
  },
};

export default function CModalRFF(props: IProps) {
  const { Header, Content, Footer } = Layout;
  const {
    isForm,
    modalIsShow,
    modalstyles,
    handleCancel,
    headerComponent,
    footerComponent,
    contentComponent,
    handleSubmit,
    handleValidation,
    isLoading,
    formLayout,
    contentClassName,
    initialValues,
    formRef,
  } = props;

  const renderContent = () => {
    if (isForm) {
      const layout =
        formLayout === 'horizontal'
          ? {
              labelCol: { span: 6 },
              wrapperCol: { span: 18 },
            }
          : null;
      return (
        <>
          <Form
            onSubmit={handleSubmit}
            validate={handleValidation}
            initialValues={initialValues || {}}
            keepDirtyOnReinitialize={true}
            mutators={{ ...arrayMutators }}
          >
            {formProps => {
              const { handleSubmit: handleSubmitForm, values } = formProps;
              if (formRef) {
                formRef.current = formProps;
              }

              return (
                <FormAntd
                  values={values}
                  onFinish={handleSubmitForm}
                  layout={formLayout || 'vertical'}
                  {...layout}
                >
                  <Content
                    className={
                      contentClassName ? contentClassName : 'contentModal'
                    }
                  >
                    {isLoading !== undefined && isLoading ? (
                      <Skeleton active />
                    ) : (
                      contentComponent(formProps)
                    )}
                  </Content>
                  <Footer className="footerModal">
                    {footerComponent !== null
                      ? footerComponent(formProps)
                      : null}
                  </Footer>
                </FormAntd>
              );
            }}
          </Form>
        </>
      );
    } else {
      return (
        <React.Fragment>
          <Content
            className={contentClassName ? contentClassName : 'contentModal'}
          >
            {isLoading !== undefined && isLoading ? (
              <Skeleton active />
            ) : (
              contentComponent
            )}
          </Content>
          <Footer className="footerModal">{footerComponent}</Footer>
        </React.Fragment>
      );
    }
  };

  return (
    <Modal
      isOpen={modalIsShow}
      style={modalstyles ? modalstyles : defaultModalstyles}
      className={
        modalIsShow
          ? 'modalStyleContainer'
          : 'modalStyleContainer modalStyleContainerClose'
      }
    >
      <Layout>
        <Button className={'buttonCloseModal'} onClick={handleCancel}>
          <CIcon type="CloseOutlined" />
        </Button>
        <Header
          className={headerComponent ? 'headerModal' : 'headerModalNotif'}
        >
          {headerComponent}
        </Header>
        {renderContent()}
      </Layout>
    </Modal>
  );
}

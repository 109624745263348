import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import { FormApi } from 'final-form';
import {
  checkValidationAPI,
  getPlaceOfBirthListAPI,
  getLastEducationListAPI,
  getListRegionAPI,
  getLookupAgencyAPI,
  getLookupBankAPI,
  getLookupSalesOfficeAPI,
  getReligionListAPI,
  submitEditQAProcessAPI,
  submitQAProcessAPI,
} from '../../../../Services/qaProcess.api';
import CButton from '../../../../Assets/Components/CButton';
import ModalCandidateComponent from '../../Component/Modal/ModalCandidateComponent';
import moment from 'moment';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import {
  generateLeadCandidate,
  generateSubmissionAddForm,
  generateSubmissionEditForm,
} from './GenerateSubmission';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  modalCandidateIsShow: boolean;
  setModalCandidateIsShow: Dispatch<SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: Dispatch<SetStateAction<undefined>>;
  schemaData: any;
  detailCandidate: any;
  isEditedCandidate: boolean;
  setIsEditedCandidate: Dispatch<SetStateAction<boolean>>;
  documentList: any;
  totalOtherattachments: any;
  setTotalOtherattachments: Dispatch<SetStateAction<any>>;
  recruiter: any;
  directLeaderList: any;
  setAgentCode: any;
  setAgentLevel: any;
  setCandidateLevel: any;
  documentsContent: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  setContentDocument: any;
  contentDocument: any;
  setModalDetailIsShow: any;
  documentMeta: any;
  refreshList: any;
  newListBeneficiary: any;
  setNewListBeneficiary: Dispatch<SetStateAction<never[]>>;
  isUploading: any;
  newCityDomicile: any[];
  setNewCityDomicile: Dispatch<SetStateAction<never[]>>;
  newDistrictDomicile: any[];
  setNewDistrictDomicile: Dispatch<SetStateAction<never[]>>;
  newVillageDomicile: any[];
  setNewVillageDomicile: Dispatch<SetStateAction<never[]>>;
  newCity: any[];
  setNewCity: Dispatch<SetStateAction<never[]>>;
  newDistrict: any[];
  setNewDistrict: Dispatch<SetStateAction<never[]>>;
  newVillage: any[];
  setNewVillage: Dispatch<SetStateAction<never[]>>;
  candidateApprovers: any;
}
export default function ModalCandidateContainer(props: IProps) {
  const {
    modalCandidateIsShow,
    setModalCandidateIsShow,
    selectedData,
    setSelectedData,
    schemaData,
    detailCandidate,
    isEditedCandidate,
    setIsEditedCandidate,
    documentList,
    totalOtherattachments,
    setTotalOtherattachments,
    recruiter,
    directLeaderList,
    setAgentCode,
    setAgentLevel,
    setCandidateLevel,
    documentsContent,
    handleUploadContent,
    setContentDocument,
    contentDocument,
    setModalDetailIsShow,
    documentMeta,
    refreshList,
    newListBeneficiary,
    setNewListBeneficiary,
    newCityDomicile,
    setNewCityDomicile,
    newDistrictDomicile,
    setNewDistrictDomicile,
    newVillageDomicile,
    setNewVillageDomicile,
    newCity,
    setNewCity,
    newDistrict,
    setNewDistrict,
    newVillage,
    setNewVillage,
    candidateApprovers,
    isUploading,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [newListWorkexperience, setNewListWorkexperience] = useState([]);
  const [modalEditBeneficiaryIsShow, setModalEditBeneficiaryIsShow] =
    useState(false);
  const [modalEditWorkExperienceIsShow, setModalEditWorkExperienceIsShow] =
    useState(false);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState();
  const [selectedWorkexperience, setSelectedWorkexperience] = useState();
  const [idRegion, setIdRegion] = useState(1);
  const [regionName, setRegionName] = useState('');
  const [modalValidationIsShow, setModalValidationIsShow] = useState(false);
  const [idCity, setIdCity] = useState(0);
  const [idDistrict, setIdDistrict] = useState(0);
  const [idProvincedomicile, setProvincedomicile] = useState(0);
  const [idCitydomicile, setCitydomicile] = useState(0);
  const [idDistrictdomicile, setIdDistrictdomicile] = useState(0);
  const [handlersName, setHandlersName] = useState('');
  const [valuesChange, setValuesChange] = useState<any>();

  let values: any;
  let isNext: boolean;
  const { data: bankList } = useQuery(['getLookupBank'], getLookupBankAPI, {});
  const bank: any = [];
  if (bankList && bankList?.length > 0) {
    for (const iterator of bankList) {
      if (iterator) {
        bank.push({
          id: `${iterator.bankDesc}|${iterator.bankAccountTypeCode}|${iterator.bankId}`,
          name: iterator.bankDesc,
        });
      }
    }
  }

  const { data: salesOffice } = useQuery(
    ['getLookupSalesOffice'],
    getLookupSalesOfficeAPI
  );

  const { data: agency } = useQuery(['getLookupAgency'], getLookupAgencyAPI);

  const { data: religionList } = useQuery(
    ['getReligionList'],
    getReligionListAPI
  );
  const religion: any = [];
  if (religionList?.length > 0) {
    for (const iterator of religionList) {
      religion.push({
        id: `${iterator.religionName}|${iterator.religionID}`,
        name: iterator.religionName,
      });
    }
  }

  const { data: provinceList } = useQuery(
    [
      'getProvinceList',
      {
        idRegion: 1,
        regionName: 'province',
        formName: '',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI
  );

  const { data: cityList } = useQuery(
    [
      'getCityList',
      {
        idRegion,
        regionName: 'city',
        formName: '',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'city',
    }
  );

  useEffect(() => {
    if (cityList) {
      setNewCity(cityList);
    }
    //eslint-disable-next-line
  }, [cityList]);

  const { data: districtList } = useQuery(
    [
      'getDistrictList',
      {
        idRegion: idCity,
        regionName: 'district',
        formName: '',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'district',
    }
  );

  useEffect(() => {
    if (districtList) {
      setNewDistrict(districtList);
    }
    //eslint-disable-next-line
  }, [districtList]);

  const { data: subdistrictList } = useQuery(
    [
      'getSubdistrictList',
      {
        idRegion: idDistrict,
        regionName: 'village',
        formName: '',
        fromPostalCode: true,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'subdistrict',
    }
  );

  useEffect(() => {
    if (subdistrictList) {
      setNewVillage(subdistrictList);
    }
    //eslint-disable-next-line
  }, [subdistrictList]);

  const { data: provinceDomicileList } = useQuery(
    [
      'getProvinceDomicileList',
      {
        idRegion: 1,
        regionName: 'province',
        formName: 'domicile',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow,
    }
  );

  const { data: citydomicileList } = useQuery(
    [
      'getCitydomicileList',
      {
        idRegion: idProvincedomicile,
        regionName: 'city',
        formName: 'domicile',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'citydomicile',
    }
  );
  useEffect(() => {
    if (citydomicileList) {
      setNewCityDomicile(citydomicileList);
    }
    // eslint-disable-next-line
  }, [citydomicileList]);

  const { data: districtdomicileList } = useQuery(
    [
      'getDistrictdomicileList',
      {
        idRegion: idCitydomicile,
        regionName: 'district',
        formName: 'domicile',
        fromPostalCode: false,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'districtdomicile',
    }
  );

  useEffect(() => {
    if (districtdomicileList) {
      setNewDistrictDomicile(districtdomicileList);
    }
    // eslint-disable-next-line
  }, [districtdomicileList]);

  const { data: subdistrictdomicileList } = useQuery(
    [
      'getSubdistrictdomicileList',
      {
        idRegion: idDistrictdomicile,
        regionName: 'village',
        formName: 'domicile',
        fromPostalCode: true,
        dynamicForm: true,
      },
    ],
    getListRegionAPI,
    {
      enabled: modalCandidateIsShow && handlersName === 'subdistrictdomicile',
    }
  );

  useEffect(() => {
    if (subdistrictdomicileList) {
      setNewVillageDomicile(subdistrictdomicileList);
    }
    //eslint-disable-next-line
  }, [subdistrictdomicileList]);

  const { data: lastEducationList } = useQuery(
    ['getLastEducationList'],
    getLastEducationListAPI
  );

  const { data: pobList } = useQuery(
    [
      'getPlaceOfBirthList',
      {
        regionName: 'city',
        formName: 'POB',
      },
    ],
    getPlaceOfBirthListAPI,
    {
      enabled: modalCandidateIsShow,
    }
  );

  const formaleducation: any = [];
  if (lastEducationList?.length > 0) {
    for (const iterator of lastEducationList) {
      formaleducation.push({
        id: `${iterator.educationLevelDesc}|${iterator.educationLevelCode}`,
        name: iterator.educationLevelDesc,
      });
    }
  }
  const { mutate: mutateSubmitQAProcess, isLoading: isLoadingAdd } =
    useMutation(submitQAProcessAPI, {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success('Data berhasil ditambahkan');
        setSelectedData(undefined);
        setModalCandidateIsShow(false);
        refreshList();
        setIsEditedCandidate(false);
        setCurrentStep(0);
        setNewListBeneficiary([]);
        setContentDocument({});
      },
    });

  const { mutate: mutateEditQAProcess, isLoading: isLoadingEdit } = useMutation(
    submitEditQAProcessAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success('Data berhasil diubah');
        setSelectedData(undefined);
        setModalCandidateIsShow(false);
        refreshList();
        setIsEditedCandidate(false);
        setNewListBeneficiary([]);
        setCurrentStep(0);
        setContentDocument({});
      },
    }
  );

  const {
    data: resultValidation,
    mutate: mutateCheckValidation,
    isLoading: isLoadingValidation,
  } = useMutation(checkValidationAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: (data: any) => {
      const documents = contentDocument;
      const listRecruiter = recruiter;
      const listDirectLeader = directLeaderList;
      const listKeyDocuments = documents ? Object.keys(documents) : [];
      const listValueDocuments = documents ? Object.values(documents) : [];
      const listbeneficiary =
        selectedData && !newListBeneficiary
          ? detailCandidate?.listbeneficiary
          : newListBeneficiary || [];
      const listworkexperience =
        selectedData && !newListWorkexperience
          ? detailCandidate?.listworkexperience
          : newListWorkexperience || [];
      const formValue = values;
      if (selectedData && currentStep === 0) {
        if (data && data?.length > 0) {
          setModalValidationIsShow(true);
        } else {
          if (isNext) {
            setCurrentStep(currentStep + 1);
          } else {
            mutateEditQAProcess({
              payloadLead: generateLeadCandidate(
                formValue,
                documents,
                listRecruiter,
                listDirectLeader,
                listKeyDocuments,
                listValueDocuments
              ),
              detailCandidate: selectedData,
              formValue: generateSubmissionEditForm(formValue),
              listbeneficiary,
              listworkexperience,
              totalOtherDocument: totalOtherattachments,
              currentStep,
            });
          }
        }
      }
    },
  });

  useEffect(() => {
    if (selectedData) {
      setNewListBeneficiary(detailCandidate?.listbeneficiary);
    }
    // eslint-disable-next-line
  }, [selectedData]);

  useEffect(() => {
    if (!selectedData) {
      if (resultValidation && resultValidation?.length > 0) {
        setModalValidationIsShow(true);
        setCurrentStep(0);
      } else if (resultValidation?.length === 0) {
        setCurrentStep(currentStep + 1);
      } else if (resultValidation?.length === 0) {
        setModalValidationIsShow(false);
      }
    }
    // eslint-disable-next-line
  }, [resultValidation]);

  const handleCancel = () => {
    if (selectedData) {
      setModalDetailIsShow(true);
    }
    setModalCandidateIsShow(false);
    setContentDocument({});
    setCurrentStep(0);
    setNewListBeneficiary([]);
    setIsEditedCandidate(false);
  };

  const handleStep = (step: any) => {
    setCurrentStep(step);
    if (selectedData) {
      setIsEditedCandidate(false);
    }
  };

  const handleNextForm = (ref: any, formValue: any) => {
    if (currentStep === 0) {
      isNext = true;
      mutateCheckValidation({
        idNumber: formValue.personaldata.profile.nik,
        email: formValue.personaldata.profile.email,
        phoneNumber: formValue.personaldata.profile.mobilephone,
        code: selectedData?.code || null,
      });
    } else {
      setCurrentStep(currentStep + 1);
      ref.current.scrollIntoView();
    }
    if (selectedData) {
      setIsEditedCandidate(false);
    }
  };

  const handleBackForm = (ref: any) => {
    setCurrentStep(currentStep - 1);
    if (selectedData) {
      setIsEditedCandidate(false);
    }
    ref.current.scrollIntoView();
  };

  const handleEditForm = () => {
    setIsEditedCandidate(true);
  };

  const handleSave = (formValue: any) => {
    isNext = false;
    setContentDocument(documentsContent);
    const documents = contentDocument;
    const listRecruiter = recruiter;
    const listDirectLeader = directLeaderList;
    const listKeyDocuments = documents ? Object.keys(documents) : [];
    const listValueDocuments = documents ? Object.values(documents) : [];
    const listbeneficiary =
      selectedData && !newListBeneficiary
        ? detailCandidate?.listbeneficiary
        : newListBeneficiary || [];
    const listworkexperience =
      selectedData && !newListWorkexperience
        ? detailCandidate?.listworkexperience
        : newListWorkexperience || [];
    values = formValue;

    if (currentStep === 0) {
      mutateCheckValidation({
        idNumber: formValue.personaldata.profile.nik,
        email: formValue.personaldata.profile.email,
        phoneNumber: formValue.personaldata.profile.mobilephone,
        code: selectedData?.code || null,
      });
    }
    if (selectedData && !isEditedCandidate) {
      setIsEditedCandidate(true);
    }

    if (!selectedData) {
      mutateSubmitQAProcess({
        payloadLead: generateLeadCandidate(
          formValue,
          documents,
          listRecruiter,
          listDirectLeader,
          listKeyDocuments,
          listValueDocuments
        ),
        submission: generateSubmissionAddForm(
          formValue,
          listbeneficiary,
          listworkexperience
        ),
        documents,
        detailCandidate: selectedData,
        currentStep,
      });
    } else {
      if (selectedData && currentStep !== 0) {
        mutateEditQAProcess({
          payloadLead: generateLeadCandidate(
            formValue,
            documents,
            listRecruiter,
            listDirectLeader,
            listKeyDocuments,
            listValueDocuments
          ),
          detailCandidate: selectedData,
          formValue: generateSubmissionEditForm(formValue),
          listbeneficiary,
          listworkexperience,
          totalOtherDocument: totalOtherattachments,
          currentStep,
        });
      }
    }
  };

  const handleOnChange = (values: any, form: any) => {
    setValuesChange(values);
  };

  const additionalOnChange = (
    domicileaddress: any,
    formValue: any,
    form: any
  ) => {
    const values = formValue;
    if (domicileaddress && values.personaldata?.addresseducation) {
      const personaldata = values.personaldata;
      const addresseducation = values.personaldata.addresseducation;
      form?.change(
        `personaldata.addresseducation.domicileaddress`,
        personaldata && addresseducation && addresseducation.idcardaddress
          ? addresseducation.idcardaddress
          : null
      );
      form?.change(
        `personaldata.addresseducation.provincedomicile`,
        personaldata && addresseducation && addresseducation.province
          ? addresseducation.province
          : null
      );
      form?.change(
        `personaldata.addresseducation.citydomicile`,
        personaldata && addresseducation && addresseducation.city
          ? addresseducation.city
          : null
      );
      form?.change(
        `personaldata.addresseducation.districtdomicile`,
        personaldata && addresseducation && addresseducation.district
          ? addresseducation.district
          : null
      );
      form?.change(
        `personaldata.addresseducation.subdistrictdomicile`,
        personaldata && addresseducation && addresseducation.subdistrict
          ? addresseducation.subdistrict
          : null
      );
      form?.change(
        `personaldata.addresseducation.postalcodedomicile`,
        personaldata && addresseducation && addresseducation.postalcode
          ? addresseducation.postalcode
          : null
      );
    }
  };

  useEffect(() => {
    if (valuesChange?.domicileaddress) {
      setNewCityDomicile(cityList);
      setNewDistrictDomicile(districtList);
      setNewVillageDomicile(subdistrictList);
    }
    //eslint-disable-next-line
  }, [valuesChange?.domicileaddress]);

  const renderActionbeneficiary = (row: any) => {
    if (selectedData && !newListBeneficiary) {
      setNewListBeneficiary(detailCandidate?.listbeneficiary);
    }
    const dataButton = [
      {
        type: 'ghost',
        id: 'btnEditBeneficiary',
        icon: 'EditOutlined',
        className: 'btnEditTableRegis',
        onClick: () => {
          setModalEditBeneficiaryIsShow(true);
          setSelectedBeneficiary(row.row.original);
        },
        disabled: !isEditedCandidate,
      },
      {
        type: 'ghost',
        id: 'btnEditBeneficiary',
        icon: 'DeleteOutlined',
        className: 'btnDeleteTableRegis',
        onClick: () => {
          const myArray = newListBeneficiary?.filter((obj: any) => {
            return obj.id !== row.row.original.id;
          });
          setNewListBeneficiary(myArray);
        },
        disabled: !isEditedCandidate,
      },
    ];
    return <CButton buttonData={dataButton} />;
  };
  const renderValue = (name: any, row: any) => {
    const values = row.row.original.fromto;
    let newValues: any;
    if (typeof values === 'string') {
      newValues = name === 'from' ? values.split('-')[0] : values.split('-')[1];
    } else {
      newValues = name === 'from' ? values[0] : values[1];
    }
    return <div>{values !== '' ? newValues : '-'}</div>;
  };

  const handleAddBeneficiary = (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => {
    const valueForm = values.beneficiary.form;
    let listBeneficiary: any;
    if (selectedData) {
      listBeneficiary = detailCandidate?.listbeneficiary || [];
    } else {
      listBeneficiary = newListBeneficiary;
    }
    const obj = {
      id: `beneficiary_${listBeneficiary?.length - 1}`,
      fullname: valueForm.fullname,
      relationship: valueForm.relationship,
      job: valueForm.job,
      homephone: valueForm.homephone ? `+62${valueForm.homephone}` : '',
      mobilephone: valueForm.mobilephone ? `+62${valueForm.mobilephone}` : '',
      address: valueForm.address,
    };
    listBeneficiary?.push(obj);

    setNewListBeneficiary(listBeneficiary);
    form?.change('beneficiary.form', null);
  };

  const handleResetBeneficiary = (form: FormApi<any, Partial<any>>) => {
    form?.change('beneficiary.form', null);
  };

  const handleAddWorkexperience = (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => {
    const valueForm = values.workexperience.form;
    let listworkexperience: any;
    if (selectedData) {
      listworkexperience = detailCandidate?.listworkexperience || [];
    } else {
      listworkexperience = newListWorkexperience;
    }
    const obj = {
      id: `workexperience_${listworkexperience.length - 1}`,
      companyname: valueForm.companyname,
      businessfields: valueForm.businessfields,
      position: valueForm.position ? valueForm.position : '',
      fromto: valueForm.fromto
        ? `${moment(valueForm.fromto[0]).format('YYYY')}-${moment(
            valueForm.fromto[1]
          ).format('YYYY')}`
        : '',
      resigningreason: valueForm.resigningreason
        ? valueForm.resigningreason
        : '',
    };
    listworkexperience?.push(obj);
    setNewListWorkexperience(listworkexperience);
    form?.change('workexperience.form', null);
  };

  const handleResetWorkexperience = (form: FormApi<any, Partial<any>>) => {
    form?.change('workexperience.form', null);
  };

  const renderActionworkexperience = (row: any) => {
    if (selectedData && !newListWorkexperience) {
      setNewListWorkexperience(detailCandidate?.listworkexperience);
    }
    const dataButton = [
      {
        type: 'ghost',
        id: 'btnEditworkexperience',
        icon: 'EditOutlined',
        className: 'btnEditTableRegis',
        onClick: () => {
          setModalEditWorkExperienceIsShow(true);
          setSelectedWorkexperience(row.row.original);
        },
        disabled: !isEditedCandidate,
      },
      {
        type: 'ghost',
        id: 'btnDeleteworkexperience',
        icon: 'DeleteOutlined',
        className: 'btnDeleteTableRegis',
        onClick: () => {
          const myArray = newListWorkexperience?.filter((obj: any) => {
            return obj.id !== row.row.original.id;
          });
          setNewListWorkexperience(myArray);
        },
        disabled: !isEditedCandidate,
      },
    ];
    return <CButton buttonData={dataButton} />;
  };
  const column = {
    beneficiary: [
      {
        Header: 'Nama Lengkap',
        accessor: 'fullname',
      },
      {
        Header: 'Hubungan dengan Anda',
        accessor: 'relationship',
      },
      {
        Header: 'Pekerjaan',
        accessor: 'job',
      },
      {
        Header: 'Telepon Rumah',
        accessor: 'homephone',
      },
      {
        Header: 'Nomor HP',
        accessor: 'mobilephone',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
      {
        Header: 'Aksi',
        Cell: (row: any) => renderActionbeneficiary(row),
      },
    ],
    workexperience: [
      {
        Header: 'Nama Perusahaan',
        accessor: 'companyname',
      },
      {
        Header: 'Bidang Usaha',
        accessor: 'businessfields',
      },
      {
        Header: 'Jabatan',
        accessor: 'position',
      },
      {
        Header: 'Dari',
        Cell: (row: any) => renderValue('from', row),
      },
      {
        Header: 'Sampai',
        Cell: (row: any) => renderValue('to', row),
      },
      {
        Header: 'Alasan Keluar',
        accessor: 'resigningreason',
      },
      {
        Header: 'Aksi',
        Cell: (row: any) => renderActionworkexperience(row),
      },
    ],
  };

  const handleSelectHaveNPWP = (e: any, form: FormApi<any, Partial<any>>) => {
    if (!e?.target?.value) {
      form?.change(`personaldata.taxbankaccount.tax.taxnumber`, null);
      form?.change(`personaldata.taxbankaccount.tax.taxpayer`, null);
      form?.change(`personaldata.taxbankaccount.tax.taxownership`, null);
      form?.change(`personaldata.taxbankaccount.tax.taxdependents`, null);
    }
  };

  const onClickOtherDocument = (values: any) => {
    setTotalOtherattachments(totalOtherattachments + 1);
  };

  let dataSubmission = detailCandidate?.dataSubmission;
  let initialSubmission: any = dataSubmission ? dataSubmission?.submission : {};
  useEffect(() => {
    if (dataSubmission) {
      const addresEducation =
        dataSubmission.submission.personaldata.addresseducation;
      const profile = dataSubmission.submission.personaldata.profile;
      const agency = dataSubmission.submission.personaldata.agency;
      if (agency) {
        initialSubmission.personaldata.agency.agencyLevel =
          candidateApprovers?.approverLevel;
      }
      initialSubmission.personaldata.agency.recruiter = `${selectedData?.refAgentName}|${selectedData?.refAgentCode}`;
      if (
        addresEducation.idcardaddress.toString() ===
          addresEducation.domicileaddress.toString() &&
        addresEducation.province === addresEducation.provincedomicile &&
        addresEducation.city === addresEducation.citydomicile &&
        addresEducation.district === addresEducation.districtdomicile &&
        addresEducation.subdistrict === addresEducation.subdistrictdomicile &&
        addresEducation.postalcode === addresEducation.postalcodedomicile
      ) {
        initialSubmission.domicileaddress = true;
      }
      if (profile.mobilephone) {
        initialSubmission.personaldata.profile.mobilephone =
          profile.mobilephone.substring(3, profile.mobilephone.length + 1);
      }
      if (profile.homephone) {
        initialSubmission.personaldata.profile.homephone =
          profile.homephone.substring(3, profile.homephone.length + 1);
      }
      if (profile.workphone) {
        initialSubmission.personaldata.profile.workphone =
          profile.workphone.substring(3, profile.workphone.length + 1);
      }
      if (selectedData) {
        initialSubmission.personaldata.agency.candidateLevel =
          selectedData.candidateLevel;
      }
    } // eslint-disable-next-line
  }, [initialSubmission]);

  const initialValues = dataSubmission ? initialSubmission : {};

  return (
    <ModalCandidateComponent
      modalCandidateIsShow={modalCandidateIsShow}
      handleCancel={handleCancel}
      selectedData={selectedData}
      schemaData={schemaData}
      detailCandidate={detailCandidate}
      handleStep={handleStep}
      isEditedCandidate={isEditedCandidate}
      setIsEditedCandidate={setIsEditedCandidate}
      currentStep={currentStep}
      handleNextForm={handleNextForm}
      handleBackForm={handleBackForm}
      handleEditForm={handleEditForm}
      handleSave={handleSave}
      documentList={documentList}
      handleSelectHaveNPWP={handleSelectHaveNPWP}
      totalOtherattachments={totalOtherattachments}
      onClickOtherDocument={onClickOtherDocument}
      column={column}
      bank={bank}
      recruiter={recruiter || []}
      salesOffice={salesOffice || []}
      agency={agency || []}
      directLeader={directLeaderList || []}
      setAgentCode={setAgentCode}
      setAgentLevel={setAgentLevel}
      setCandidateLevel={setCandidateLevel}
      religion={religion}
      province={provinceList || []}
      provincedomicile={provinceDomicileList || []}
      pob={pobList?.newData || []}
      city={newCity || []}
      district={newDistrict || []}
      subdistrict={newVillage || []}
      citydomicile={newCityDomicile || []}
      districtdomicile={newDistrictDomicile || []}
      subdistrictdomicile={newVillageDomicile || []}
      handleAddBeneficiary={handleAddBeneficiary}
      handleResetBeneficiary={handleResetBeneficiary}
      listbeneficiary={
        selectedData && !newListBeneficiary
          ? detailCandidate?.listbeneficiary
          : newListBeneficiary || []
      }
      listworkexperience={
        selectedData && !newListWorkexperience
          ? detailCandidate?.listworkexperience
          : newListWorkexperience || []
      }
      modalEditBeneficiaryIsShow={modalEditBeneficiaryIsShow}
      setModalEditBeneficiaryIsShow={setModalEditBeneficiaryIsShow}
      modalEditWorkExperienceIsShow={modalEditWorkExperienceIsShow}
      setModalEditWorkExperienceIsShow={setModalEditWorkExperienceIsShow}
      selectedBeneficiary={selectedBeneficiary}
      setSelectedBeneficiary={setSelectedBeneficiary}
      setNewListBeneficiary={setNewListBeneficiary}
      handleAddWorkexperience={handleAddWorkexperience}
      handleResetWorkexperience={handleResetWorkexperience}
      selectedWorkexperience={selectedWorkexperience}
      setSelectedWorkexperience={setSelectedWorkexperience}
      setNewListWorkexperience={setNewListWorkexperience}
      setIdRegion={setIdRegion}
      setRegionName={setRegionName}
      regionName={regionName}
      formaleducation={formaleducation}
      handleUploadContent={handleUploadContent}
      documentMeta={documentMeta}
      documentsContent={documentsContent}
      initialValues={initialValues}
      modalValidationIsShow={modalValidationIsShow}
      setModalValidationIsShow={setModalValidationIsShow}
      resultValidation={resultValidation}
      isLoading={
        selectedData
          ? isLoadingEdit || isLoadingValidation || isUploading
          : isLoadingAdd
      }
      setIdCity={setIdCity}
      setIdDistrict={setIdDistrict}
      setProvincedomicile={setProvincedomicile}
      setCitydomicile={setCitydomicile}
      setIdDistrictdomicile={setIdDistrictdomicile}
      setHandlersName={setHandlersName}
      handleOnChange={handleOnChange}
      additionalOnChange={additionalOnChange}
      setNewVillageDomicile={setNewVillageDomicile}
      setNewDistrictDomicile={setNewDistrictDomicile}
      setNewDistrict={setNewDistrict}
      setNewVillage={setNewVillage}
      candidateApprovers={candidateApprovers}
      isUploading={isUploading}
    />
  );
}

import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { IResultContestUploadDAO } from '../DAO/contestUpload.dao';

interface IProps {
  columnData: any;
  contestList: IResultContestUploadDAO[];
  handleRefresh: () => void;
  isLoading: boolean;
}

export default function ContestTabHistoryComponent(props: IProps) {
  const { isLoading, contestList, handleRefresh, columnData } = props;
  return (
    <div className="containerTabWhiteZurich">
      <CButton
        buttonFloat={'right'}
        isLoading={isLoading}
        handleRefresh={handleRefresh}
      />
      <Table
        isLoading={isLoading}
        columns={generateColumnData(columnData)}
        data={contestList}
        pagination={true}
      />
    </div>
  );
}

import { Col, Row } from 'antd';
import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CModalRFF from '../../../Assets/Components/CModalRFF';

interface IProps {
  isShow: boolean;
  handleCancel: () => void;
  selectedFeedbackDetail: any;
}

export default function FeedbackDetailModalComponent(props: IProps) {
  const { isShow, handleCancel, selectedFeedbackDetail } = props;
  const {
    essayQuestions,
    multipleChoiceQuestions,
    totalPoint,
    category,
    subModuleName,
  } = selectedFeedbackDetail;

  const defaultModalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      maxWidth: '95vw',
      width: '85vw',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '99',
    },
  };

  const buttonData = [
    {
      id: 'btnCloseModalDetailFeedback',
      className: 'btnClose',
      content: 'Tutup',
      onClick: () => handleCancel(),
    },
  ];

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <p>Feedback Detail</p>
      </h3>
    );
  };

  const renderMultipleChoiceContent = () => {
    return (
      <div>
        <h2 className="questionTypeHeading">Multiple Choice</h2>
        {multipleChoiceQuestions.map((multipleChoice, index) => {
          const renderQuestions = () => {
            return (
              <div>
                <p className="textBold">Pertanyaan {index + 1}</p>
                <p className="containerQuestion textHighlightYellow">
                  {multipleChoice.question}
                </p>
                <Row className="containerItemFeedback">
                  <Col span={2}>
                    <span>Jawaban</span>
                  </Col>
                  <Col
                    className="containerMultipleChoiceAnswer textHighlightGrey"
                    span={12}
                  >
                    <span>
                      {multipleChoice.answer ? multipleChoice.answer : '-'}
                    </span>
                  </Col>
                  <Col className="spanPointFeedbackBank" span={2}>
                    <span>Point</span>
                  </Col>
                  <Col
                    className="containerMultipleChoicePoint textHighlightGrey"
                    span={2}
                  >
                    <span>{multipleChoice.point}</span>
                  </Col>
                </Row>
              </div>
            );
          };
          return (
            <CCard
              cardClassName={'cardDetailFeedback'}
              cardTitle={''}
              cardContent={renderQuestions()}
            />
          );
        })}
      </div>
    );
  };
  const renderEssayContent = () => {
    return (
      <div>
        <h2 className="questionTypeHeading">Essay</h2>
        {essayQuestions.map((essay, index) => {
          const renderQuestions = () => {
            return (
              <div>
                <p className="textBold">Pertanyaan {index + 1}</p>
                <p className="containerQuestion textHighlightYellow">
                  {essay.question}
                </p>
                <p>Jawaban</p>
                <Row>
                  <Col
                    className="containerEssayAnswer textHighlightGrey"
                    span={18}
                  >
                    <span>{essay.answer ? essay.answer : '-'}</span>
                  </Col>
                </Row>
              </div>
            );
          };
          return (
            <div>
              <CCard
                cardClassName={'cardDetailFeedback'}
                cardTitle={''}
                cardContent={renderQuestions()}
              />
            </div>
          );
        })}
      </div>
    );
  };

  const contentComponent = () => {
    return (
      <div>
        <span className="textBold">Sub-Module</span>
        <p>{category}</p>
        <span className="textBold">Feedback Category</span>
        <p>{subModuleName}</p>
        <hr className="lineModalDetailFeedback" />
        {multipleChoiceQuestions ? renderMultipleChoiceContent() : null}
        <Row>
          <Col span={24}>
            <span className="textBold totalPoint">
              Total Point : {totalPoint}
            </span>
          </Col>
        </Row>
        {essayQuestions ? renderEssayContent() : null}
        <CButton buttonData={buttonData} />
      </div>
    );
  };

  // tslint:disable-next-line: no-empty
  const footerComponent = () => {};

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={footerComponent()}
      handleCancel={handleCancel}
      handleSubmit={() => {}}
      formLayout="vertical"
      isLoading={false}
      initialValues={[]}
      modalstyles={defaultModalstyles}
      isForm={false}
    />
  );
}

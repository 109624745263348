import { Avatar } from 'antd';
import CIcon from '../CIcon';
import React from 'react';

export const removeElemenIsHidden = (
  properties,
  currentSteps,
  modalAction,
  propsDynamic,
  disableSaveButton,
  isEdited,
  globalValidation
) => {
  const form: any = properties;
  const newList: any = [];
  const newListKey = Object.keys(form);
  for (const iterator of newListKey) {
    if (
      form[iterator]['config']['web'] &&
      !form[iterator]['config']['web']['isHidden']
    ) {
      newList.push({
        key: iterator,
        content: form[iterator],
      });
    }
  }

  const processStatus = key => {
    const index = newListKey.findIndex(x => x === key);
    if (currentSteps === index) {
      return 'process';
    }
    return 'wait';
  };
  const renderIconStep = (icon, status, isDisabled) => {
    const isActived = status === 'process';
    const classNameAvatar = () => {
      if (isDisabled) {
        return 'avatarDisabled';
      } else if (isActived) {
        return 'avatarActived';
      } else {
        return 'avatarRegistration';
      }
    };
    return (
      <Avatar
        size={50}
        className={classNameAvatar()}
        icon={<CIcon type={icon} />}
      />
    );
  };
  const stepData: any = [];
  const validationStep = (num, key) => {
    if (num === 0) {
      if (isEdited) {
        return num === currentSteps || key === 'personaldata'
          ? false
          : globalValidation;
      }
      return false;
    } else if (num === 1) {
      if (modalAction === 'register') {
        return disableSaveButton(num - 1);
      } else {
        if (isEdited) {
          return globalValidation;
        }
        return false;
      }
    } else {
      if (modalAction === 'register') {
        return num === currentSteps || num < currentSteps
          ? false
          : num !== currentSteps &&
            propsDynamic.formValue &&
            Object.keys(propsDynamic.formValue).length > 0 &&
            propsDynamic.formValue[key]
          ? globalValidation
          : true;
      } else {
        if (isEdited && num === currentSteps) {
          return false;
        } else if (isEdited && num !== currentSteps) {
          return globalValidation;
        }
        return false;
      }
    }
  };
  newList.forEach((data, index) => {
    const newData: any = data;
    newData.title = data.content.title;

    if (newData.key) {
      switch (newData.key) {
        case 'personaldata': {
          newData.icon = renderIconStep(
            'UserOutlined',
            processStatus(newData.key),
            validationStep(index, newData.key)
          );
          newData.status = processStatus(newData.key);
          newData.disabled = validationStep(index, newData.key);
          break;
        }
        case 'beneficiary': {
          newData.icon = renderIconStep(
            'TeamOutlined',
            processStatus(newData.key),
            validationStep(index, newData.key)
          );
          newData.status = processStatus(newData.key);
          newData.disabled = validationStep(index, newData.key);
          break;
        }
        case 'workexperience': {
          newData.icon = renderIconStep(
            'ReconciliationOutlined',
            processStatus(newData.key),
            validationStep(index, newData.key)
          );
          newData.status = processStatus(newData.key);
          newData.disabled = validationStep(index, newData.key);
          break;
        }
        case 'documents': {
          newData.icon = renderIconStep(
            'FolderOutlined',
            processStatus(newData.key),
            validationStep(index, newData.key)
          );
          newData.status = processStatus(newData.key);
          newData.disabled = validationStep(index, newData.key);
          break;
        }
      }
    }
    stepData.push(newData);
  });
  return stepData;
};

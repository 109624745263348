import React from 'react';
import { Tabs } from 'antd';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import LicenseTabListContainer from '../Container/Tabs/LicenseTabListContainer';
import LicenseTabUploadContainer from '../Container/Tabs/LicenseTabUploadContainer';

const { TabPane } = Tabs;

export default function LicenseComponent() {
  const cardLicenseContent = () => {
    const componentTabPane = (title: string, id: string) => {
      return (
        <div className="tabPaneContestUpload" id={id}>
          {title}
        </div>
      );
    };
    return (
      <Tabs defaultActiveKey="licenseList" className="tabContestUpload">
        <TabPane
          tab={componentTabPane('Daftar Lisensi', 'tabLicenseList')}
          key="licenseList"
        >
          <LicenseTabListContainer />
        </TabPane>
        <TabPane
          tab={componentTabPane('Unggah Lisensi', 'tabLicenseUpload')}
          key="licenseUpload"
        >
          <LicenseTabUploadContainer />
        </TabPane>
      </Tabs>
    );
  };
  
  return (
    <>
      <CLabelCard leftText="Lisensi" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich"></span>}
        cardContent={cardLicenseContent()}
      />
    </>
  );
}

import React, { useEffect, useState } from 'react';

import ModalQuestionBankComponent from '../Component/ModalQuestionBankComponent';
import { mutateQuestionBankAPI } from '../../../Services/questionbank.api';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { IResultChannelElearningDAO } from '../../ElearningContentManagement/Channel/DAO/channelElearning.dao';
import { IResultCategoryBankDAO } from '../../ElearningCategoryBank/DAO/categoryBank.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  handleRefresh: () => void;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  listElearningChannel: IResultChannelElearningDAO[];
  listCategoryBank: IResultCategoryBankDAO[];
  selectedStatus?: string;
}

export default function ModalQuestionBankContainer({
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  listElearningChannel,
  listCategoryBank,
  handleRefresh,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [totalQuestion, setTotalQuestion] = useState(1);
  const [initialValues, setinitialValues] = useState(null);

  const mutate = useMutation(mutateQuestionBankAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} question
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setShowModal(false);
      setSelectedData(undefined);
      setEditMode(false);
      handleRefresh();
      setTotalQuestion(1);
    },
  });

  const onSubmit = (vals: any) => {
    const payloadSubmit: any[] = [];
    let payloadUpdate: any = {};
    for (
      let currentQuestion = 0;
      currentQuestion < totalQuestion;
      currentQuestion++
    ) {
      if (!selectedData) {
        const index = currentQuestion ? currentQuestion : '';
        const listAnswers: any[] = [];
        const answerSplit = Number(vals[`answer${index}`].split('_')[1]);
        vals[`answerOption${index}`].forEach((element: any, i: number) => {
          if (answerSplit === i + 1) {
            listAnswers.push({
              answer: element,
              correct: true,
            });
          } else {
            listAnswers.push({
              answer: element,
              correct: false,
            });
          }
        });
        const channelId = vals[`channelId${index}`];
        const categoryId = vals[`categoryId${index}`];
        const title = vals[`title${index}`];
        const point = vals[`point${index}`];
        const moduleId = 0;
        const answers = listAnswers;
        payloadSubmit.push({
          channelId,
          moduleId,
          categoryId,
          title,
          point,
          answers,
        });
      } else {
        const {
          channelId,
          moduleId,
          categoryId,
          title,
          point,
          answerOption,
          answer,
        } = vals;
        const indexAnswer = answer.split('_')[1];
        const answersUpdate: any = [];
        answerOption.forEach((option, index) => {
          if (index + 1 === Number(indexAnswer)) {
            answersUpdate.push({
              answer: option,
              correct: true,
            });
          } else {
            answersUpdate.push({
              answer: option,
              correct: false,
            });
          }
        });
        payloadUpdate = {
          channelId,
          moduleId,
          categoryId,
          title,
          point,
          answers: answersUpdate,
        };
      }
    }
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({
        payloadSubmit,
        payloadUpdate,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  const initialValue: any = {};
  useEffect(() => {
    if (selectedData) {
      const { channelId, moduleId, categoryId, title, point, answers } =
        selectedData;
      initialValue.channelId = channelId;
      initialValue.moduleId = moduleId;
      initialValue.categoryId = categoryId;
      initialValue.title = title;
      initialValue.point = point;
      const answersOption: any = [];
      answers.forEach((element, index) => {
        const { answer, correct } = element;
        if (correct === true) {
          initialValue.answer = `answer_${index + 1}`;
        }
        answersOption.push(answer);
      });
      initialValue.answerOption = answersOption;

      setinitialValues(initialValue);
    }
    // eslint-disable-next-line
  }, [selectedData]);

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
    setTotalQuestion(1);
    setinitialValues(null);
  };

  const handleAddQuestion = () => {
    setTotalQuestion(prev => prev + 1);
  };

  return (
    <ModalQuestionBankComponent
      editMode={editMode}
      handleAddQuestion={handleAddQuestion}
      handleCancel={handleCancel}
      initialValues={initialValues}
      isLoading={false}
      isShow={showModal}
      listCategoryBank={listCategoryBank}
      listElearningChannel={listElearningChannel}
      onSubmit={onSubmit}
      questionBankModalAction={selectedData ? 'update' : 'register'}
      setTotalQuestion={setTotalQuestion}
      totalQuestion={totalQuestion}
    />
  );
}

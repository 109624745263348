import { Button } from 'antd';
import { Field } from 'react-final-form';
import { InputTextNumber } from '../../../../Assets/Components/CInput';
import React from 'react';

interface IProps {
  disabled: boolean;
  handleReset: () => void;
}

export default function ClassModalExamComponent({
  disabled,
  handleReset,
}: IProps) {
  return (
    <div>
      <Field
        name="examDuration"
        component={InputTextNumber}
        idComponent="examDuration"
        placeholder="0"
        disabled={disabled}
        min={1}
        label="Duration"
      />
      <Field
        name="examMinScore"
        component={InputTextNumber}
        idComponent="examMinScore"
        placeholder="0"
        disabled={disabled}
        min={1}
        label="Minimum Score"
      />
      <Field
        name="examMaxAttempt"
        component={InputTextNumber}
        idComponent="examMaxAttempt"
        placeholder="0"
        disabled={disabled}
        min={1}
        label="Max. Attempt"
      />
      <Button
        className="btnResetSubModule"
        disabled={disabled}
        id="btnResetSchedule"
        htmlType="button"
        onClick={handleReset}
      >
        Reset
      </Button>
    </div>
  );
}

import { FormApi } from 'final-form';
import React from 'react';
import SubmoduleModalGeneralComponent from '../../Component/Modal/SubmoduleModalGeneralComponent';

interface IProps {
  disabled: boolean;
  modalAction: string;
  submodule: any;
  selectedData: any;
  form?: FormApi<any, Partial<any>>;
  formError?: any;
  formValues?: any;
}

export default function SubmoduleModalGeneralContainer(props: IProps) {
  const { form, modalAction, submodule, selectedData } = props;
  const predecessorList: any = [];

  if (modalAction === 'update') {
    const filterListPredecessor = submodule.filter(
      x => !x.name.includes(selectedData.name)
    );
    filterListPredecessor.forEach(data => {
      predecessorList.push({
        id: data.codemiCourseId,
        name: data.name,
        desc: data.predecessorDesc,
        sequence: data.sequence + 1,
      });
    });
  } else
    submodule.forEach(data => {
      predecessorList.push({
        id: data.codemiCourseId,
        name: data.name,
        desc: data.predecessorDesc,
        sequence: data.sequence + 1,
      });
    });

  const handleAddtionalSelectSequence = (
    e: any,
  ) => {
    const data = predecessorList.find(item => item.sequence === e);
    form?.change('general.predecessor', data?.id || null);
  };

  const handleAddtionalSelectPredecessor = (
    e: any,
  ) => {
    const data = predecessorList.find(item => item.id === e);
    form?.change('general.sequence', data?.sequence || null);
  };

  return (
    <SubmoduleModalGeneralComponent
      predecessorList={predecessorList}
      handleAddtionalOnChangeSequence={handleAddtionalSelectSequence}
      handleAddtionalSelectPredecessor={handleAddtionalSelectPredecessor}
      {...props}
    />
  );
}

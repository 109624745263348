import React from 'react';
import ModalListExamScheduleComponent from '../Component/ModalListExamScheduleComponent';

interface IProps {
  listExamSchedule: any[];
  setModalAction: React.Dispatch<React.SetStateAction<string>>;
  setSelectedList: React.Dispatch<React.SetStateAction<any[]>>;
  setShowModalDetail: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModalList: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedDetail: React.Dispatch<any>;
  showModal: boolean;
  selectedDate: any;
}

export default function ModalListExamScheduleContainer(props: IProps) {
  const {
    listExamSchedule,
    setModalAction,
    setSelectedList,
    setShowModalDetail,
    setShowModalList,
    setSelectedDetail,
    showModal,
    selectedDate,
  } = props;

  const handleCancel = () => {
    setShowModalList(false);
    setSelectedDetail(undefined);
    setSelectedList([]);
  };

  const handleOnClickDetail = (data: any) => {
    setSelectedDetail(data);
    setModalAction('month');
    setShowModalList(false);
    setShowModalDetail(true);
  };

  return (
    <ModalListExamScheduleComponent
      handleCancel={handleCancel}
      handleOnClickDetail={handleOnClickDetail}
      listExamSchedule={listExamSchedule}
      modalIsShow={showModal}
      selectedDate={selectedDate}
    />
  );
}

import moment from 'moment';
import { formatDate } from '../../App/Enums';
import instance from '../interceptor';
import Store from '../../Store';
import {
  IResultDashboardDosdDAO,
  IResultHistoryTrainingProcessDAO,
  IResultInitialLoginAnalyticDAO,
  IResultLoginTrackerDAO,
  IResultMostVisitedMenuDAO,
  IResultUpcomingTrainingProcessDAO,
} from '../../Modules/Dashboard/DAO/dashboard.dao';

export const getDashboardDosdDataAPI = async (): Promise<
  IResultDashboardDosdDAO[]
> => {
  const startDay = moment().startOf('month').format('YYYY-MM-DD');
  const endDay = moment().endOf('month').format('YYYY-MM-DD');
  const { data } = await instance.get(
    `/Candidate/workflow/agent/monitoring?StartFrom=${startDay}&StartTo=${endDay}&CaseTemplate=MZA&JourneyStatus=${' '}`
  );
  return data;
};

export const getLoginTrackerDataAPI =
  async (): Promise<IResultLoginTrackerDAO> => {
    const startOfMonth = moment().startOf('month').format(formatDate);
    const startOfMonthPrev = moment()
      .startOf('month')
      .subtract(1, 'months')
      .format(formatDate);
    const today = moment().format(formatDate);
    const yesterday = moment().add(-1, 'days').format(formatDate);
    const resStartMonth =
      startOfMonth === today ? startOfMonthPrev : startOfMonth;

    const { data } = await instance.get(
      `/analytic/useraccess/dashboard/periode?startdate=${resStartMonth}&enddate=${yesterday}`
    );
    return data;
  };

export const getUniqueLoginDataAPI = async (): Promise<{
  totalCurrPeriodUniqueLogin: number;
  totalPrevPeriodUniqueLogin: number;
}> => {
  const endOfMonthPrev = moment()
    .subtract(1, 'months')
    .endOf('month')
    .format(formatDate);
  const startOfMonth = moment().startOf('month').format(formatDate);
  const startOfMonthPrev = moment()
    .startOf('month')
    .subtract(1, 'months')
    .format(formatDate);
  const today = moment().format(formatDate);
  const yesterday = moment().add(-1, 'days').format(formatDate);
  const resStartMonth =
    startOfMonth === today ? startOfMonthPrev : startOfMonth;

  const { data: dataUniqueLoginNow }: { data: IResultInitialLoginAnalyticDAO } =
    await instance.get(
      `/analytic/initiallogin/dashboard/periode/${resStartMonth}/${yesterday}`
    );
  const {
    data: dataUniqueLoginPrev,
  }: { data: IResultInitialLoginAnalyticDAO } = await instance.get(
    `/analytic/initiallogin/dashboard/periode/${startOfMonthPrev}/${endOfMonthPrev}`
  );

  const data = {
    totalCurrPeriodUniqueLogin: dataUniqueLoginNow?.totalUniqueLogin,
    totalPrevPeriodUniqueLogin: dataUniqueLoginPrev?.totalUniqueLogin,
  };
  return data;
};

export const getMostVisitedMenuDataAPI =
  async (): Promise<IResultMostVisitedMenuDAO> => {
    const startOfMonth = moment().startOf('month').format(formatDate);
    const startOfMonthPrev = moment()
      .startOf('month')
      .subtract(1, 'months')
      .format(formatDate);
    const today = moment().format(formatDate);
    const yesterday = moment().add(-1, 'days').format(formatDate);
    const resStartMonth =
      startOfMonth === today ? startOfMonthPrev : startOfMonth;

    const { data } = await instance.get(
      `/analytic/module/tracking/dashboard/periode?numOfRecord=5&startdate=${resStartMonth}&enddate=${yesterday}`
    );
    return data;
  };

export const getUpcomingTrainingProcessAPI = async (): Promise<
  IResultUpcomingTrainingProcessDAO[]
> => {
  const { data } = await instance.get(`/Elearning/training/upcoming`);
  return data;
};

export const getHistoryTrainingProcessAPI = async (): Promise<
  IResultHistoryTrainingProcessDAO[]
> => {
  const { data } = await instance.get(`/Elearning/training/history`);
  return data;
};

export const getDetailTrainerAPI = async (): Promise<{
  joinData: string;
  lastLogin: string;
}> => {
  const AuthState: any = Store.getState();
  const trainerId =
    AuthState?.auth?.[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
    ] || '0';
  const { data } = await instance.get(`/User/${trainerId}`);
  const trainerData = {
    joinData: data?.initialLogin,
    lastLogin: data?.lastLogin,
  };
  return trainerData;
};

export const downloadHistoryTrainingProcessAPI = async (params: any) => {
  const { downloadType } = params;
  await instance
    .get(`/ElearningReport/Training/history/download/${downloadType}`, {
      responseType: 'blob',
    })
    .then(response => {
      const DateNow = moment().format('DDMMYYYY');
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute(
        'download',
        `History_Training_${DateNow}.${downloadType}`
      );
      document.body.appendChild(link);
      link.click();
    });
};

export const downloadUpcomongTrainingProcessAPI = async (params: any) => {
  const { downloadType } = params;
  await instance
    .get(`/ElearningReport/Training/upcoming/download/${downloadType}`, {
      responseType: 'blob',
    })
    .then(response => {
      const DateNow = moment().format('DDMMYYYY');
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute(
        'download',
        `Upcoming_Training_${DateNow}.${downloadType}`
      );
      document.body.appendChild(link);
      link.click();
    });
};

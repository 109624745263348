import instance from '../interceptor';

const getCertificate = async params => {
  const { data } = await instance.get(`/Elearning/certificate/template`);
  return data;
};

const getLearners = async (params: any) => {
  const { data } = await instance.get(
    // `/Elearning/Module/Learner?learningPathId=${codemiLearningPathId}`
    `/Elearning/Module/Learner?learningPathId=63297f65aaff5c000adc372a`
  );
  return data?.data?.users;
};

const getModule = async params => {
  const { stageId } = params.queryKey[1];
  const { data } = await instance.get(`/Elearning/Module?stageId=${stageId}`);
  return data;
};

const deleteModule = async (id: string) => {
  return await instance.delete(`/Elearning/Module/${id}`);
};

const assignParticipant = async (id: number) => {
  const { data } = await instance.post(
    `/Elearning/Module/AssignParticipant/${id}`,
    null
  );
  return data;
};

const mutateModule = async params => {
  const {
    agentLevels,
    certificateId,
    channelId,
    codemiUserLevelId,
    cpdPoint,
    cpdRefreshName,
    defaultLearningHour,
    isActive,
    isCertificate,
    isCpdStatus,
    isRefreshment,
    moduleId,
    name,
    predecessor,
    sequence,
    stageId,
  } = params;

  const payload: any = {
    agentLevels,
    agentLevelsDesc: [],
    certificateId,
    channelId,
    codemiUserLevelId,
    conditionDays: null,
    conditionLogic: null,
    cpdPoint: cpdPoint || null,
    cpdRefreshName: cpdRefreshName || null,
    defaultLearningHour: defaultLearningHour.toString(),
    isActive: isActive !== undefined ? isActive : false,
    isAutoAssign: true,
    isCertificate,
    isCpdStatus: isCpdStatus !== undefined ? isCpdStatus : false,
    isRefreshment: isRefreshment !== undefined ? isRefreshment : false,
    name,
    participantCondition: null,
    predecessor: predecessor || null,
    sequence: sequence || null,
    stageId,
  };

  if (moduleId) {
    return await instance.put(`/Elearning/Module/${moduleId}`, payload);
  } else {
    return await instance.post(`/Elearning/Module`, payload);
  }
};

const mutateMigrateAgent = async params => {
  const { codemiLearningPathId, module, learnerList } = params;
  const learners: any[] = [];
  learnerList.forEach(item => learners.push(item.user_id));
  const payload = {
    oldLearningPath: codemiLearningPathId, // codemi learning path
    newLearningPath: module, // codemi learning path
    learners, // user_id[]
  };
  const { data } = await instance.post(
    `/Elearning/Module/Migrate/Learner`,
    payload
  );
  return data;
};

export const contentManagementModuleAPI = {
  assignParticipant,
  getCertificate,
  getModule,
  getLearners,
  deleteModule,
  mutateModule,
  mutateMigrateAgent,
};

import { Checkbox, Col, Collapse, Row, Tabs } from 'antd';

import CButton from './CButton';
import CIcon from './CIcon';
import React from 'react';
import Table from '../../Assets/Components/CTable';
import { ViewImage } from './CViewImageFormRegis';
import bgViewImg from '../../Assets/Images/bgImg.jpg';
import moment from 'moment';
import { trimPhoneNumber } from '../../App/Helper';
import { IntlShape } from 'react-intl';

const { TabPane } = Tabs;
const { Panel } = Collapse;

interface IProps {
  json: any;
  intl: IntlShape;
  column?: any;
  schema: any;
  candidateLevel: any;
  documents: any;
  docAgreement?: any;
  listContent?: any;
}

const joinArray = (firstArray: any, secondArray: any) => {
  const array: any = [];
  firstArray.forEach((element, index) => {
    array.push({ title: element, value: secondArray[index] });
  });
  return array;
};
const convertValueToArray = obj => {
  const listKey = Object.keys(obj);
  const listValue = Object.values(obj);
  const newList: any = [];
  listKey.forEach((data, index) => {
    newList.push({ label: data, value: listValue[index] });
  });
  return newList;
};
export const DynamicTabs = (props: IProps) => {
  const {
    json,
    intl,
    column,
    schema,
    candidateLevel,
    documents,
    docAgreement,
  } = props;

  const convertObjectToArray = () => {
    const listKey = Object.keys(json);
    const listValue = Object.values(json);
    const list: any = [];
    listKey.forEach((data, index) => {
      const newData: any = {};
      if (typeof listValue[index] !== 'string') {
        newData.title = data;
        newData.content = listValue[index];
        list.push(newData);
      }
    });
    return list;
  };

  const renderValueInput = (title, value) => {
    const valueTidak = () => {
      if (value === false) {
        return 'Tidak';
      }
      return '';
    };
    if (title === 'dob') {
      return moment(value).format('DD-MMM-YYYY');
    } else if (title === 'gender') {
      return value === 'M' ? 'Laki-laki' : 'Perempuan';
    } else if (title === 'hastax') {
      return value === true ? 'Ya' : valueTidak;
    } else if (title === 'candidateLevel') {
      return candidateLevel;
    } else if (
      (title === 'mobilephone' && value) ||
      (title === 'homephone' && value) ||
      (title === 'workphone' && value)
    ) {
      return `+62${trimPhoneNumber(value)}`;
    } else if (typeof value === 'number') {
      return value;
    } else {
      if (typeof value === 'string' && title === 'formaleducation') {
        return value.split('|')[1];
      } else if (typeof value === 'string') {
        return value.split('|')[0];
      } else {
        return value;
      }
    }
  };

  const renderViewImg = detDocument => {
    if (detDocument.properties.ismovedtofilenet === 'true') {
      const onClick = () => {
        window.open(
          `http://hkwapp69:9080/navigator/bookmark.jsp?desktop=User&repositoryId=Zurich&docid=${detDocument.properties.objectid}`
        );
      };
      const dataButton = [
        {
          type: 'link',
          id: 'btnFileNet',
          className: 'btnFileNet',
          content: 'Klik untuk melihat file',
          onClick: () => onClick(),
        },
      ];
      return (
        <div style={{ backgroundColor: '#FAFBFB', padding: 10 }}>
          <div
            style={{ backgroundImage: `url(${bgViewImg})` }}
            className="imgFileNet"
          >
            <CIcon type="FileImageOutlined" />
            <CButton buttonData={dataButton} />
          </div>
        </div>
      );
    } else {
      return (
        <>
          <ViewImage
            base64={detDocument['content']}
            visibleButton={true}
            visibleInformation={true}
            extension={detDocument['properties']['extension']}
            width={300}
            height={300}
          />
          <p className="textBold">{detDocument?.originalFileName}</p>
        </>
      );
    }
  };

  const renderComponent = listComponent => {
    return listComponent.map(data => {
      if (data?.label !== 'photoselfie') {
        return (
          <div key={data?.label}>
            <Row style={{ marginBottom: 10 }}>
              <Col span={8}>
                <b>
                  {intl.formatMessage({
                    id: data?.label,
                  })}
                </b>
              </Col>
              <Col span={1}>:</Col>
              <Col span={8} key={data?.label}>
                {typeof data.value === 'string' ||
                typeof data.value === 'number' ||
                data.value === null
                  ? renderValueInput(data?.label, data.value)
                  : data.value.map(data => {
                      return (
                        <div key={data?.label} style={{ marginBottom: 5 }}>
                          {data}
                        </div>
                      );
                    })}
              </Col>
            </Row>
          </div>
        );
      }
      return null;
    });
  };

  const renderUserAgreements = list => {
    return list.map((iterator, index) => {
      return docAgreement.map(element => {
        if (
          `${intl.formatMessage({
            id: iterator.title,
          })} ` === element.disclaimerModuleName.split('(')[0]
        ) {
          return (
            <div key={`${iterator.title}_${index}`}>
              <Collapse
                defaultActiveKey={[iterator.title]}
                expandIconPosition="right"
                style={{ marginBottom: 20 }}
              >
                <Panel
                  header={intl.formatMessage({
                    id: iterator.title,
                  })}
                  key={iterator.title}
                  className="panelDynamicTabs"
                >
                  <div className="containerContentCollapse">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: element.disclaimerDesc,
                      }}
                    />
                    <hr className="lineDynamicTabs" />
                    <Checkbox
                      checked={iterator.value.agreement}
                      disabled={true}
                      className="checkboxDynamicTabs"
                    >
                      Saya telah Baca dan Setuju
                    </Checkbox>
                  </div>
                </Panel>
              </Collapse>
            </div>
          );
        }
        return null;
      });
    });
  };

  const renderQuis = (value, sectionName, title) => {
    const component = schema[sectionName]['properties'][title]['properties'];
    const listKeyContent = Object.keys(value);
    return listKeyContent.map(data => {
      if (component[data]) {
        const configComponent = component[data];
        const listValue = value[data];
        if (listValue instanceof Array) {
          return (
            <Col span={12} key={data}>
              <b style={{ fontSize: 16 }}>
                {intl.formatMessage({
                  id: data,
                })}
              </b>
              <div className="questionText">{configComponent.title}</div>
              {listValue.map((data, index) => {
                const answerText = () => {
                  if (data === false) {
                    return 'Tidak';
                  } else if (data === true) {
                    return 'Ya';
                  } else if (data === null || data === '') {
                    return '-';
                  } else {
                    return data;
                  }
                };
                return (
                  <div key={`answer${index}`}>
                    <b>
                      {index === 0
                        ? 'Jawaban'
                        : configComponent.properties[index].title}
                    </b>
                    <div className="answerText">{answerText()}</div>
                  </div>
                );
              })}
            </Col>
          );
        }
        return null;
      } else {
        return null;
      }
    });
  };

  const renderBank = listComponent => {
    const listKey = Object.keys(listComponent);
    const listValue = Object.values(listComponent);
    const bankList: any = [];
    const taxList: any = [];
    listKey.forEach((data, index) => {
      if (typeof listValue[index] === 'object') {
        const newData: any = listValue[index];
        const newListKey = Object.keys(newData);
        const newListValue = Object.values(newData);
        newListKey.forEach((element, key) => {
          if (data === 'bank') {
            bankList.push({
              label: `${data}${element}`,
              value: newListValue[key],
            });
          } else {
            taxList.push({
              label: `${data}${element}`,
              value: newListValue[key],
            });
          }
        });
      } else {
        taxList.push({ label: data, value: listValue[index] });
      }
    });
    return (
      <Row gutter={20}>
        <Col span={12}>
          {bankList.map((data, index) => {
            return (
              <Row style={{ marginBottom: 10 }} key={`${data?.label}${index}`}>
                <Col span={8}>
                  <b>
                    {intl.formatMessage({
                      id: data?.label,
                    })}
                  </b>
                </Col>
                <Col span={1}>:</Col>
                <Col span={8}>{renderValueInput(data?.label, data.value)}</Col>
              </Row>
            );
          })}
        </Col>
        <Col span={12}>
          {taxList.map((data: any, index: number) => {
            return (
              <Row style={{ marginBottom: 10 }} key={`${data?.label}${index}`}>
                <Col span={8}>
                  <b>
                    {intl.formatMessage({
                      id: data?.label,
                    })}
                  </b>
                </Col>
                <Col span={1}>
                  {data?.label !== 'bankandtaxdisclaimer' ? ':' : null}
                </Col>
                <Col span={8}>{renderValueInput(data?.label, data.value)}</Col>
              </Row>
            );
          })}
        </Col>
      </Row>
    );
  };

  const renderProfile = listComponent => {
    const listLeft: any = [];
    const listRight: any = [];
    listComponent.forEach(element => {
      if (element.label === 'idcard') {
        listLeft.push(element);
      } else {
        listRight.push(element);
      }
    });
    return (
      <Row gutter={20}>
        <Col span={10}>
          {listLeft.map(data => {
            return (
              <div key={data?.label}>
                <div style={{ fontWeight: 'bold' }}>
                  {intl.formatMessage({
                    id: data?.label,
                  })}
                </div>
                <br />
                {documents[data?.label]
                  ? renderViewImg(documents[data?.label])
                  : null}
              </div>
            );
          })}
        </Col>
        <Col span={14}>{renderComponent(listRight)}</Col>
      </Row>
    );
  };

  const renderValue = (content, title) => {
    if (title === 'documents') {
      return (
        <Row>
          {content.map(data => {
            if (documents[data.title]) {
              return (
                <Col key={data.title} span={8}>
                  <div className="labelDocuments">
                    {intl.formatMessage({
                      id: `${title}${data.title}`,
                    })}
                  </div>
                  {renderViewImg(documents[data.title])}
                </Col>
              );
            }
            return null;
          })}
        </Row>
      );
    } else if (title === 'useragreements') {
      content.forEach((element, index) => {
        if (element.title === 'list') {
          content.splice(index, 1);
        }
      });
      const newListContent: any = content;
      return <div>{renderUserAgreements(newListContent)}</div>;
    } else {
      if (content.title === 'list') {
        return (
          <Table
            columns={column[title]}
            data={content.value.values ? content.value.values : []}
          />
        );
      } else if (content.title === 'profile') {
        const list = convertValueToArray(content.value);
        return (
          <div>
            <div className="titleSectionRegis">
              {intl.formatMessage({
                id: content.title,
              })}
            </div>
            {renderProfile(list)}
            <hr className="lineDynamicTabs" />
          </div>
        );
      } else if (content.title === 'taxbankaccount') {
        return (
          <div>
            <div className="titleSectionBank">
              {intl.formatMessage({
                id: content.title,
              })}
            </div>
            <p className="descSectionBank">
              *Pemilik Rekening Adalah Agen Yang Bersangkutan
            </p>
            {renderBank(content.value)}
          </div>
        );
      } else if (content.title === 'questionnaire') {
        return (
          <div>
            <div className="titleSectionRegis">
              {intl.formatMessage({
                id: content.title,
              })}
            </div>
            <Row gutter={20}>
              {renderQuis(content.value, title, content.title)}
            </Row>
            <hr className="lineDynamicTabs" />
          </div>
        );
      } else {
        const list = convertValueToArray(content.value);
        return (
          <div>
            <div className="titleSectionRegis">
              {intl.formatMessage({
                id: content.title,
              })}
            </div>
            {renderComponent(list)}
            <hr className="lineDynamicTabs" />
          </div>
        );
      }
    }
  };

  const renderContentTabs = (content, title) => {
    const listkey = Object.keys(content);
    const listValue = Object.values(content);
    listkey.forEach((data, index) => {
      if (data === 'form') {
        listkey.splice(index, 1);
        listValue.splice(index, 1);
      }
    });
    const newJoinList = joinArray(listkey, listValue);
    if (title === 'documents' || title === 'useragreements') {
      return <div>{renderValue(newJoinList, title)}</div>;
    } else {
      return newJoinList.map(data => {
        return <div key={data.title}>{renderValue(data, title)}</div>;
      });
    }
  };

  const renderTabs = list => {
    return list.map(data => {
      if (data.title !== 'domicileaddress') {
        return (
          <TabPane
            tab={intl.formatMessage({
              id: data.title,
            })}
            key={data.title}
            className="tabsPaneDynamic"
          >
            <div className="containerDynamicTabPane">
              {renderContentTabs(data.content, data.title)}
            </div>
          </TabPane>
        );
      }
      return null;
    });
  };

  const newList = convertObjectToArray();
  return (
    <div className="containerDynamicTabs">
      <Tabs className="dynamicTabs" type="card">
        {renderTabs(newList)}
      </Tabs>
    </div>
  );
};

import React from 'react';

interface IProps {
  leftText?;
  rightText?;
  topText?: any;
}
export default function CLabelCard(props: IProps) {
  const { leftText, rightText, topText } = props;
  return (
    <span className="labelCardTitle">
      <h3>{topText}</h3>
      <p>
        {leftText} <span>{rightText}</span>
      </p>
    </span>
  );
}

import Table, { generateColumnData } from '../../../Assets/Components/CTable';

import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import ModalDetailWorldCheckContainer from '../Container/ModalDetailWorldCheckContainer';
import ModalUploadWorldCheckContainer from '../Container/ModalUploadWorldCheckContainer';
import React from 'react';
import { IResultWorldCheckHistoryDAO } from '../DAO/worldCheck.dao';

interface IProps {
  worldCheckList: IResultWorldCheckHistoryDAO[];
  columnData: any;
  handleRefresh: () => void;
  isLoading: boolean;
  selectedData: any;
  modalDetailWorldIsShow: boolean;
  setModalDetailWorldIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalUploadWorldIsShow: boolean;
  setModalUploadWorldIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}
export default function WorldCheckComponent(props: IProps) {
  const {
    worldCheckList,
    columnData,
    handleRefresh,
    isLoading,
    selectedData,
    modalDetailWorldIsShow,
    setModalDetailWorldIsShow,
    modalUploadWorldIsShow,
    setModalUploadWorldIsShow,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      icon: 'UploadOutlined',
      content: 'Unggah',
      onClick: () => setModalUploadWorldIsShow(true),
      id: 'btnUploadWorldCheck',
    },
  ];

  const worldCheckContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={worldCheckList}
          pagination={true}
          useFilterGlobal
        />
      </div>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="World Check" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            Daftar & Unggah World Check
          </span>
        }
        cardContent={worldCheckContent()}
      />
      <ModalUploadWorldCheckContainer
        modalUploadWorldIsShow={modalUploadWorldIsShow}
        setModalUploadWorldIsShow={setModalUploadWorldIsShow}
      />
      <ModalDetailWorldCheckContainer
        selectedData={selectedData}
        modalDetailWorldIsShow={modalDetailWorldIsShow}
        setModalDetailWorldIsShow={setModalDetailWorldIsShow}
      />
    </React.Fragment>
  );
}

import { useMutation } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { agnetLevel } from '../../../../App/Enums';
import { contentManagementSubmoduleAPI } from '../../../../Services/ElearningContentManagement/submodule.api';
import SubmoduleModalComponent from '../Component/SubmoduleModalComponent';
import SubmoduleModalExamContainer from './Modal/SubmoduleModalExamContainer';
import SubmoduleModalGeneralContainer from './Modal/SubmoduleModalGeneralContainer';

interface IProps {
  allDataList: {
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  };
  channel: any;
  stage: any;
  module: any;
  submodule: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setModalAction: React.Dispatch<React.SetStateAction<string>>;
  modalAction: string;
  refetch: () => void;
  refetchDetail: () => void;
  isLoading: boolean;
  detailSubmodule?: any;
  selectedData?: any;
}

export default function SubmoduleModalContainer(props: IProps) {
  const {
    setShowModal,
    setModalAction,
    selectedData,
    detailSubmodule,
    setSelectedData,
    modalAction,
    channel,
    stage,
    module,
    isLoading,
    submodule,
    refetch,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [currentSteps, setCurrentSteps] = useState(0);

  // Untuk level mapping
  const [listUserLevel, setListUserLevel] = useState<any[]>([]);
  const [listAgent, setListAgent] = useState<any[]>([]);

  // Untuk start time condition
  const [additionalConditionStart, setAdditionalConditionStart] = useState<
    any[]
  >([]);
  const [otherConditionStart, setOtherConditionStart] = useState('');
  const [joinDateCondition, setJoinDateCondition] = useState('');
  const [licenseDateCondition, setLicenseDateCondition] = useState('');
  const [afterCondition, setAfterCondition] = useState('');
  const [joinDateDays, setJoinDateDays] = useState(null);
  const [licenseDateDays, setLicenseDateDays] = useState(null);
  const [afterDateDays, setAfterDateDays] = useState(0);

  // Untuk expired time condition
  const [additionalConditionExpired, setAdditionalConditionExpired] = useState<
    any[]
  >([]);
  const [otherConditionExpired, setOtherConditionExpired] = useState('');
  const [expiredAfterCondition, setExpiredAfterCondition] = useState('');
  const [expiredDays, setExpiredDays] = useState(0);

  const [initialValues, setInitialValues] = useState<any>();

  useEffect(() => {
    if (detailSubmodule?.levels.length > 0) {
      const ids = detailSubmodule.levels.map(
        item => `${item.level}_${item.description}`
      );
      const tempArr: any[] = [];
      ids.forEach(id => {
        const item = agnetLevel.find(item => item.id === id);
        tempArr.push(item);
      });
      setListUserLevel(tempArr);
    }
    if (detailSubmodule?.users.length > 0) setListAgent(detailSubmodule.users);

    if (detailSubmodule?.additionalStarts.length > 0)
      setAdditionalConditionStart(detailSubmodule.additionalStarts);
    if (detailSubmodule?.otherConditionStart) {
      const conditions = detailSubmodule.otherConditionStart.split('|');
      conditions.forEach((item: any) => {
        const values = item.split(',');
        setOtherConditionStart(values[0]);
        if (values[0] === 'joinDate') {
          setJoinDateCondition(values[1]);
          setJoinDateDays(values[2]);
        } else if (values[0] === 'licenseDate') {
          setLicenseDateCondition(values[1]);
          setLicenseDateDays(values[2]);
        } else if (values[0] === 'after') {
          setAfterCondition(values[1]);
          setAfterDateDays(parseInt(values[2]));
        }
      });
    }

    if (detailSubmodule?.additionalExpireds.length > 0)
      setAdditionalConditionExpired(detailSubmodule.additionalExpireds);
    if (detailSubmodule?.otherConditionExpired) {
      const conditions = detailSubmodule.otherConditionExpired.split('|');
      conditions.forEach(item => {
        const values = item.split(',');
        setOtherConditionExpired(values[0]);
        if (values[0] === 'expiredAfter') {
          setExpiredAfterCondition(values[1]);
          setExpiredDays(parseInt(values[2]));
        }
      });
    }

    if (selectedData) {
      const ver = selectedData.version;
      const verSplit = selectedData.version.split('.');
      setInitialValues({
        general: {
          name: selectedData.name,
          description: selectedData.description,
          predecessor: selectedData.predecessor,
          sequence: selectedData.sequence,
          effectiveDate: [
            selectedData.effectiveDateStart,
            selectedData.effectiveDateEnd,
          ],
          version: ver === '1.0' ? ver : 'custom',
          versionFirst: ver !== '1.0' ? parseInt(verSplit[0]) : null,
          versionSecond: ver !== '1.0' ? parseInt(verSplit[1]) : null,
          cpdRefreshName: selectedData.cpdRefreshName,
          isRefreshment: selectedData.isRefreshment,
          isCpdStatus: selectedData.isCpdStatus,
          isPublish: selectedData.isPublish,
        },
        exam: {
          type: selectedData.type,
          questionLimit: selectedData.questionLimit,
          duration: selectedData.duration,
          minimumScore: selectedData.minimumScore,
          maxAttempt: selectedData.maxAttemp,
        },
        levelMapping: { isNeedAssignedUser: selectedData.isNeedAssignedUser },
        startTime: {
          licenseStatus:
            selectedData.licenseStatusStart.length > 0
              ? selectedData.licenseStatusStart.split('|')
              : [],
          otherCondition: otherConditionStart,
          joinDateCondition,
          licenseDateCondition,
          afterCondition,
          joinDateDays,
          licenseDateDays,
          afterDateDays,
        },
        expiredTime: {
          licenseStatus:
            selectedData.licenseStatusExpired.length > 0
              ? selectedData.licenseStatusExpired.split('|')
              : [],
          otherCondition: otherConditionExpired,
          expiredAfterCondition,
          expiredDays,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailSubmodule, selectedData]);

  const disabledTabs = (index: number, step: number) => {
    if (editMode) return false;
    else {
      if (index <= step) return false;
      else return true;
    }
  };

  const steps = [
    {
      id: 'general',
      title: 'General',
      content: (
        <SubmoduleModalGeneralContainer
          disabled={
            editMode === false && modalAction === 'update' ? true : false
          }
          selectedData={selectedData}
          {...props}
        />
      ),
      disabled: disabledTabs(0, currentSteps),
    },
    {
      id: 'exam',
      title: 'Exam / Quiz',
      content: (
        <SubmoduleModalExamContainer
          disabled={
            editMode === false && modalAction === 'update' ? true : false
          }
          editMode={editMode}
          selectedData={selectedData}
          {...props}
        />
      ),
      disabled: disabledTabs(1, currentSteps),
    },
  ];

  const handleSteps = (step: any) => setCurrentSteps(step);

  const handleNext = () => {
    if (currentSteps !== steps.length - 1) setCurrentSteps(currentSteps + 1);
  };
  const handleBack = () => {
    if (currentSteps !== 0) setCurrentSteps(currentSteps - 1);
  };

  const handleEditClick = () => setEditMode(true);

  const handleCancel = () => {
    setCurrentSteps(0);
    setSelectedData(undefined);
    setModalAction('');
    setEditMode(false);
    setShowModal(false);
  };

  const mutate = useMutation(contentManagementSubmoduleAPI.mutateSubmodule, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} submodule
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
      refetch();
    },
  });
  const onSubmit = (vals: any) => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate.mutate({
        ...vals,
        listUserLevel,
        listAgent,
        additionalConditionStart,
        additionalConditionExpired,
        codemiLearningPathId: module.codemiLearningPathId,
        channelId: selectedData?.channelId || channel.id,
        stageId: selectedData?.stageId || stage.id,
        moduleId: selectedData?.moduleId || module.id,
        submoduleId: modalAction === 'update' ? selectedData?.id : undefined,
      });
    }
  };

  return (
    <SubmoduleModalComponent
      currentSteps={currentSteps}
      disabledTabs={disabledTabs}
      editMode={editMode}
      handleBack={handleBack}
      handleCancel={handleCancel}
      handleEditClick={handleEditClick}
      handleNext={handleNext}
      handleStep={handleSteps}
      initialValues={initialValues}
      isLoading={modalAction === 'update' ? isLoading : false}
      isLoadingSubmit={mutate.isLoading}
      modalAction={modalAction}
      onSubmit={onSubmit}
      setEditMode={setEditMode}
      steps={steps}
      submodule={submodule || []}
    />
  );
}

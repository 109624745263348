import instance from './interceptor';

export const getScheduleListAPI = async params => {
  const id = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ClassNonSelf/ScheduleList?id=${id}`
  );
  return data?.data?.schedules;
};

export const getScheduleDetailAPI = async params => {
  const id = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ScheduleNonSelf/Detail/ByCodemiId?CodemiScheduleId=${id}`
  );
  return data;
};

export const deleteNonSelfScheduleAPI = async (id: string) => {
  return await instance.delete(`/Elearning/ScheduleNonSelf?id=${id}`);
};

import React from 'react';

interface IProps {
  listMultipleChoice: any[];
  listAnswer: any[];
  listFreeText: any[];
}

export default function CShowDetailFeedback(props: IProps) {
  const { listMultipleChoice, listAnswer, listFreeText } = props;
  return (
    <React.Fragment>
      <div className="title_box_transfet_text">
        <span className="text_title_box_transfer">
          Multiple Choice Question{' '}
        </span>
      </div>
      <div className="title_multiple_choice">
        <b>Question Title</b>
      </div>
      <div className="container_list_question">
        {listMultipleChoice.map((question, index) => {
          return (
            <div
              key={`multipleChoice_${index}`}
              className="question_multiple_choice"
            >
              {question.question}
            </div>
          );
        })}
      </div>
      <div className="container_total_list_multiple_choice">
        Total: {listMultipleChoice.length}
      </div>
      <hr className="line_detail_feedback" />
      <div className="title_multiple_choice">
        <b>Answer List</b>
      </div>
      <div className="answerContentFeedback">
        {listAnswer.map((answer, index) => {
          return (
            <div className="container_list_answer" key={`answer_${index}`}>
              <div className="label_list_answer">Jawaban {index + 1}</div>
              <div className="value_list_answer">{answer.answer}</div>
              <div className="label_list_answer">Point</div>
              <div className="value_list_point">{answer.point}</div>
            </div>
          );
        })}
      </div>
      <div className="title_box_transfet_text">
        <span className="text_title_box_transfer">Free Text Question </span>
      </div>
      <div className="title_multiple_choice">
        <b>Question Title</b>
      </div>
      <div className="container_list_question">
        {listFreeText.map((question, index) => {
          return (
            <div
              key={`multipleChoice_${index}`}
              className="question_multiple_choice"
            >
              {question.question}
            </div>
          );
        })}
      </div>
      <div className="container_total_list_multiple_choice">
        Total: {listFreeText.length}
      </div>
    </React.Fragment>
  );
}

import React, { useState } from 'react';

import StageModalComponent from '../Component/StageModalComponent';
import { mutateNonSelfTrainingStageAPI } from '../../../../Services/stage.api';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';

interface IProps {
  channel: any;
  stageElearningModalAction: string;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  refetchStage: () => void;
  modalIsShow: boolean;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
}
export default function StageModalContainer({
  channel,
  setModalIsShow,
  refetchStage,
  modalIsShow,
  selectedData,
  setSelectedData,
}: IProps) {
  const [editMode, setEditMode] = useState(false);

  const mutate = useMutation(mutateNonSelfTrainingStageAPI, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} stage
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setModalIsShow(false);
      refetchStage();
      setEditMode(false);
      setSelectedData(undefined);
    },
  });

  const handleCancel = () => {
    setModalIsShow(false);
    setSelectedData(undefined);
  };

  const handleSubmit = vals => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({ ...vals, channelId: channel.id });
    }
  };

  const handleValidation = (values: any) => {
    let errors: any = {};
    if (!values.name) errors.name = 'Stage Name Wajib Diisi';
    if (!values.desc) errors.desc = 'Description Wajib Diisi';

    return errors;
  };

  const initialValues: any = selectedData;

  return (
    <StageModalComponent
      isShow={modalIsShow}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleValidation={handleValidation}
      initialValues={initialValues}
      stageElearningModalAction={selectedData ? 'update' : 'register'}
      editMode={editMode}
      isLoading={mutate.isLoading}
    />
  );
}

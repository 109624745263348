import React, { useEffect, useState } from 'react';
import { convertAgentLevel, groupObjectBy } from '../../../../../App/Helper';
import {
  getLookupAgentAPI,
  getLookupAgentLevelAPI,
  getLookupAgentLOSAPI,
  mutateClassParticipantAPI,
} from '../../../../../Services/elearning.api';

import { useMutation, useQuery } from '@tanstack/react-query';
import CButton from '../../../../../Assets/Components/CButton';
import ParticipantComponent from '../../Component/MainModal/ParticipantComponent';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  channelCode: string;
  classDetail: any;
  disabled: boolean;
  form: any;
  formValues: any;
  isLoading: boolean;
  modalAction: string;
  refetchClassDetail: () => void;
  selectedAgentManual: any[];
  setSelectedAgentManual: React.Dispatch<React.SetStateAction<any[]>>;
}

export default function ParticipantContainer(props: IProps) {
  const [countingParticipantManual, setCountingParticipantManual] = useState(0);
  const {
    channelCode,
    classDetail,
    form,
    formValues,
    modalAction,
    refetchClassDetail,
    selectedAgentManual,
    setSelectedAgentManual,
  } = props;
  const {
    levelParticipantType,
    participantType,
    fromLengthType,
    toLengthType,
  } = formValues;

  const { data: dataParticipant } = useQuery(
    ['Get List Agent', channelCode],
    getLookupAgentAPI,
    { enabled: participantType === 'MANUAL', refetchOnWindowFocus: false }
  );

  const { data: dataAgentLevel } = useQuery(
    ['Get Lookup Agent Level', levelParticipantType, channelCode],
    getLookupAgentLevelAPI,
    {
      enabled:
        participantType === 'LEVEL' &&
        modalAction !== 'update' &&
        levelParticipantType !== undefined &&
        levelParticipantType.length > 0,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataAgentLOS } = useQuery(
    ['Get Lookup Agent LOS', fromLengthType, toLengthType, channelCode],
    getLookupAgentLOSAPI,
    {
      enabled:
        participantType === 'LOS' &&
        modalAction !== 'update' &&
        fromLengthType !== undefined &&
        fromLengthType !== null &&
        toLengthType !== undefined &&
        toLengthType !== null,
      refetchOnWindowFocus: false,
    }
  );

  const updateParticipant = useMutation(mutateClassParticipantAPI, {
    onSuccess: () => refetchClassDetail(),
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  useEffect(() => {
    if (dataAgentLevel) {
      const dataForm: any = [];
      const tempListAgent = dataAgentLevel ? [...dataAgentLevel] : [];
      const sortedData = tempListAgent.sort((a, b) =>
        a.agentLevel > b.agentLevel ? 1 : -1
      );
      let dataList = groupObjectBy(sortedData, 'agentLevel');
      const currentSet = new Set(Object.keys(dataList));
      const levelTypeWithoutData = levelParticipantType.filter(
        x => !currentSet.has(x)
      );
      if (levelTypeWithoutData.length > 0) {
        levelTypeWithoutData.forEach((type: string) => (dataList[type] = []));
        dataList = Object.keys(dataList)
          .sort()
          .reduce((obj, key) => {
            obj[key] = dataList[key];
            return obj;
          }, {});
      }

      for (const key in dataList) {
        if (Object.prototype.hasOwnProperty.call(dataList, key)) {
          const element = dataList[key];
          dataForm.push({
            level: key,
            total: element.length,
          });
        }
      }
      form.change('participantLevel', dataForm);
    }
    // eslint-disable-next-line
  }, [dataAgentLevel]);

  useEffect(() => {
    if (dataAgentLOS) form.change('totalAgentLOS', dataAgentLOS.length);
    // eslint-disable-next-line
  }, [dataAgentLOS]);

  if (dataParticipant) {
    for (const iterator of dataParticipant) {
      const { agentCode, agentName } = iterator;
      iterator.id = agentCode;
      iterator.name = `${agentCode} - ${agentName}`;
    }
  }

  useEffect(() => {
    if (classDetail && modalAction === 'update') {
      const { manualParticipants } = classDetail;
      if (participantType === 'LEVEL') {
        // LEVEL
        const dataForm: any = [];
        manualParticipants.forEach((iterator, index) => {
          const { agentCode, name } = iterator;
          form.registerField(`participantLevel.${index}.level`, () => {});
          form.registerField(`participantLevel.${index}.total`, () => {});
          dataForm.push({
            level: name,
            total: parseInt(agentCode, 10),
          });
        });
        form.change('participantLevel', dataForm);
      } else if (participantType === 'LOS') {
        // LOS
        form.registerField('totalAgentLOS', () => {});
        form.change('totalAgentLOS', manualParticipants[0]?.agentCode);
      } else if (participantType === 'MANUAL' && dataParticipant) {
        // MANUAL
        const tempParticipantManual = classDetail.manualParticipants || [];
        for (const iterator of tempParticipantManual) {
          const { agentName, agentLevel, agencyName } =
            dataParticipant.find(val => val.agentCode === iterator.agentCode) ||
            {};

          iterator.oldData = true;
          iterator.agentName = agentName;
          iterator.agentLevel = agentLevel;
          iterator.agencyName = agencyName;
        }
        setSelectedAgentManual(tempParticipantManual);
      }
    }
    // eslint-disable-next-line
  }, [classDetail, participantType, dataParticipant]);

  const columnData = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: row => parseInt(row.row.id, 10) + 1,
    },
    {
      Header: 'Agent Code',
      accessor: 'agentCode',
    },
    {
      Header: 'Agent Name',
      accessor: 'agentName',
    },
    {
      Header: 'Level',
      accessor: 'agentLevel',
      Cell: row => convertAgentLevel(row.value),
    },
    {
      Header: 'Agency Name',
      accessor: 'agencyName',
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const renderAction = (row: any) => {
    const rowIndex = row.row.index;
    const rowData = row.row.original;
    const handleDelete = () => {
      const tempData = [...selectedAgentManual];
      if (modalAction === 'register') {
        form.mutators.remove('agentManual', rowIndex);
      } else {
        const filteredData = tempData.filter(v => !v.oldData);
        const indexDelete = filteredData.findIndex(v => {
          return v.agentCode === rowData.agentCode;
        });
        form.mutators.remove('agentManual', indexDelete);
      }
      setCountingParticipantManual(countingParticipantManual - 1);
      tempData.splice(rowIndex, 1);
      setSelectedAgentManual(tempData);
    };
    const dataButton = [
      {
        id: `btnDeleteChannel`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
      },
    ];
    return rowData.oldData ? null : <CButton buttonData={dataButton} />;
  };

  const filteredOptions = dataParticipant?.filter(o => {
    return !selectedAgentManual.some((item: any) => {
      return item.agentCode === o.id;
    });
  });

  const handleUpdateParticipant = () => {
    // Untuk counting agent jika type level & LOS
    updateParticipant.mutate({ id: classDetail.id });
  };

  const handleAddAgentManual = () => {
    const tempAgentManual: any = [...selectedAgentManual];
    const selectedAgentCode = formValues?.agentManual
      ? formValues?.agentManual[countingParticipantManual]?.code
      : '';

    const dataAgentCode = dataParticipant.find(
      e => e.agentCode === selectedAgentCode
    );
    tempAgentManual.push(dataAgentCode);
    setSelectedAgentManual(tempAgentManual);
    setCountingParticipantManual(countingParticipantManual + 1);
  };

  return (
    <ParticipantComponent
      dataParticipantManual={filteredOptions || []}
      columnData={columnData}
      countingParticipantManual={countingParticipantManual}
      handleAddAgentManual={handleAddAgentManual}
      handleUpdateParticipant={handleUpdateParticipant}
      {...props}
    />
  );
}

const BeneficiaryValidation = (values: any) => {
  const errors: any = {};
  if (values.mobilephone) {
    if (
      values.mobilephone.charAt(0) === '0' ||
      values.mobilephone.charAt(values.mobilephone) === '0'
    ) {
      errors.mobilephone = 'Masukkan telepon HP dengan benar';
    } else if (!/^\d+$/i.test(values.mobilephone)) {
      errors.mobilephone = 'Hanya diperbolehkan angka';
    }
  }
  if (values.homephone) {
    if (
      values.homephone.charAt(0) === '0' ||
      values.homephone.charAt(values.homephone) === '0'
    ) {
      errors.homephone = 'Masukkan telepon rumah dengan benar';
    } else if (!/^\d+$/i.test(values.homephone)) {
      errors.homephone = 'Hanya diperbolehkan angka';
    }
  }
  return errors;
};
export default BeneficiaryValidation;

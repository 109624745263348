import Dashboard from '@uppy/react/lib/Dashboard';
import React from 'react';
import Uppy from '@uppy/core';
import XHRUpload from '@uppy/xhr-upload';
import { injectIntl } from 'react-intl';
import { localeUppy } from '../../../Modules/Internationalization/translations/en';
import CModalFile from '../../../Assets/Components/CModalFile';
import Store from '../../../Store';
import history from '../../../App/History';
import { toast } from 'react-toastify';

interface IProps {
  modalUploadScheduleIsShow: boolean;
  handleCancel: () => void;
}
function ModalUploadScheduleComponent(props: IProps) {
  const { handleCancel, modalUploadScheduleIsShow } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Unggah Schedule</span>
      </h3>
    );
  };

  const authState: any = Store.getState();
  const uppyUploadSchedule = new Uppy({
    restrictions: {
      minNumberOfFiles: 1,
      maxNumberOfFiles: 1,
      allowedFileTypes: ['.xlsx'],
    },
    locale: localeUppy,
  }).use(XHRUpload, {
    id: `uppyFileGalleryXHR`,
    headers: {
      Authorization: `bearer ${authState.token}`,
    },
    endpoint: `${process.env.REACT_APP_API_URL}/Candidate/file/upload/examschedule`,
    method: 'post',
    formData: true,
    fieldName: 'file',
    allowedMetaFields: ['originalFilename'],
    disableThumbnailGenerator: true,
    showRemoveButtonAfterComplete: true,
    inline: true,
    timeout: 1000000,
  });
  uppyUploadSchedule.on('file-added', file => {
    uppyUploadSchedule.setFileMeta(file.id, {
      originalFilename: file.name,
    });
  });
  uppyUploadSchedule.on('upload-success', () => {
    toast.success('Berkas Berhasil Diunggah');
    handleCancel();
  });
  uppyUploadSchedule.on('upload-error', (file, error, response: any) => {
    if (response.status === 401) {
      history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
      authState.logOut();
    }
  });
  const contentComponent = () => {
    return (
      <React.Fragment>
        <p className="titleUploadFileLicenseModal">Unggah Berkas</p>
        <Dashboard
          id="uppyUploadLicense"
          uppy={uppyUploadSchedule}
          width={'100%'}
          disableThumbnailGenerator={true}
          showRemoveButtonAfterComplete={true}
          inline={true}
          proudlyDisplayPoweredByUppy={true}
          height={300}
        />
      </React.Fragment>
    );
  };
  return (
    <CModalFile
      modalIsShow={modalUploadScheduleIsShow}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      isForm={true}
      formLayout="vertical"
    />
  );
}

export default injectIntl(ModalUploadScheduleComponent);

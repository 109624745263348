import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import HasPermission from '../../../App/HasPermission';
import {
  generateColumnData,
  renderActionComponent,
} from '../../../Assets/Components/CTable';
import PermissionComponent from '../Component/PermissionComponent';
import ModalPermissionContainer from './ModalPermissionContainer';
import { getPermissionRoleAPI } from '../../../Services/Permission/permission.api';

export default function PermissionContainer() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const {
    data: dataAuthRole,
    isLoading: isLoadingAuthRole,
    refetch: refetchAuthRole,
  } = useQuery(['getAuthRoleAPI'], getPermissionRoleAPI, {
    refetchOnWindowFocus: false,
  });

  const handleOnClick = () => setShowModal(true);

  const handleRefresh = () => refetchAuthRole();

  const renderAction = row => {
    const rows = row.row.original;
    const editClick = () => {
      setSelectedData(rows);
      setShowModal(true);
    };
    return renderActionComponent(
      isLoadingAuthRole,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
      },
      {
        handleUpdate: editClick,
      }
    );
  };

  const columnData = [
    {
      Header: 'Role Name',
      accessor: 'name',
    },
    HasPermission('u')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
        }
      : {
          accessor: 'hideAction',
        },
  ];

  return (
    <>
      <PermissionComponent
        authRoleList={dataAuthRole || []}
        columnData={generateColumnData(columnData)}
        handleOnClick={handleOnClick}
        handleRefresh={handleRefresh}
        isLoading={isLoadingAuthRole}
      />
      <ModalPermissionContainer
        handleRefresh={handleRefresh}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  );
}

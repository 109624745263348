import React, { useState } from 'react';
import ModalContestRewardComponent from '../Component/ModalContestRewardComponent';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { mutateContestRewardAPI } from '../../../Services/Contest/contestReward.api';
import { IResultContestRewardDetailDAO } from '../DAO/contestReward.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  isLoading: boolean;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  handleRefresh: () => void;
  contestDetail?: IResultContestRewardDetailDAO;
}

export default function ModalContestRewardContainer(props: IProps) {
  const {
    isLoading,
    contestDetail,
    selectedData,
    showModal,
    setShowModal,
    setSelectedData,
    handleRefresh,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const modalAction = !selectedData ? 'register' : 'update';
  let initialValues = { ...contestDetail };

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    mutateContestRewardAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage
              id={
                !selectedData
                  ? 'createContestRewardSuccess'
                  : 'updateContestRewardSuccess'
              }
            />
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        handleCancel();
        handleRefresh();
      },
    }
  );

  const handleCancel = () => {
    setSelectedData(null);
    setShowModal(false);
    setEditMode(false);
  };

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate({
        ...vals,
        id: selectedData?.id,
        editMode,
      });
    }
  };

  return (
    <ModalContestRewardComponent
      disabled={editMode === false && modalAction === 'update' ? true : false}
      editMode={editMode}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isShow={showModal}
      isLoading={
        modalAction === 'update'
          ? isLoading || isLoadingMutation
          : isLoadingMutation
      }
      modalAction={modalAction}
      initialValues={initialValues}
    />
  );
}

import React, { useState } from 'react';
import ContentComponent from '../Component/ContentComponent';

interface IProps {
  channel: any;
  stage: any;
  module: any;
  submodule: any;
  allDataList: {
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  };
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  selectedStatus?: string;
  selectedData?: any;
}

export default function ContentContainer(props: IProps) {
  const [currentTab, setCurrentTab] = useState('Slide');

  const handleOnTabClick = (params: string) => setCurrentTab(params);

  return (
    <ContentComponent
      currentTab={currentTab}
      handleChangeTab={handleOnTabClick}
      {...props}
    />
  );
}

import moment from 'moment';
import instance from '../interceptor';
import { UseQueryOptions } from '@tanstack/react-query';
import { IResultClassNonSelfListDAO, IResultModuleListDAO } from '../../Modules/ElearningLearningReport/DAO/learningReport.dao';

const formatDate = 'YYYY-MM-DD';

export const getLearningReportAPI = async (params: any) => {
  const {
    channelCode,
    channelId,
    classId,
    classResult,
    classStatus,
    endDate,
    moduleId,
    reportType,
    startDate,
    typeSchedule,
  } = params.queryKey[1];

  const learningReportPath =
    reportType === 'journey'
      ? 'journey/training'
      : reportType === 'progress'
      ? 'progress/agent'
      : reportType === 'exam'
      ? 'exam'
      : reportType === 'selfLearning' || reportType === 'nonSelfLearning'
      ? `feedback/${reportType}`
      : '';

  const date =
    startDate && endDate
      ? `?startDate=${moment(startDate).format(formatDate)}&endDate=${moment(
          endDate
        ).format(formatDate)}`
      : '';

  const filter =
    reportType === 'sessionPin'
      ? `${typeSchedule ? `&scheduleType=${typeSchedule}` : ''}${
          channelId ? `&channelId=${channelId}` : ''
        }${classId ? `&classNonSelfId=${classId}` : ''}`
      : reportType === 'trainingAttendance'
      ? `${classStatus ? `&status=${classStatus}` : ''}${
          classResult ? `&result=${classResult}` : ''
        }${channelId ? `&channelId=${channelId}` : ''}${
          classId ? `&classNonSelfId=${classId}` : ''
        }`
      : date &&
        reportType !== 'sessionPin' &&
        reportType !== 'trainingAttendance'
      ? `${channelCode ? `&channelCode=${channelCode}` : ''}${
          moduleId ? `&moduleId=${moduleId}` : ''
        }`
      : `${channelCode ? `?channelCode=${channelCode}` : ''}${
          moduleId ? `&moduleId=${moduleId}` : ''
        }`;

  const url =
    date && reportType === 'sessionPin'
      ? `/Elearning/SessionPin/Report${date}${filter}`
      : !date && reportType === 'sessionPin'
      ? `/Elearning/SessionPin/Report?${filter}`
      : date && reportType === 'trainingAttendance'
      ? `/Elearning/Journey/NonSelf/Report${date}${filter}`
      : !date && reportType === 'trainingAttendance'
      ? `/Elearning/Journey/NonSelf/Report?${filter}`
      : date && reportType === 'learningHour'
      ? `/Elearning/LearningHour/Report${date}${filter}`
      : !date && reportType === 'learningHour'
      ? `/Elearning/LearningHour/Report${date}`
      : `/Elearning/user/${learningReportPath}/report${date}${filter}`;

  const { data } = await instance.get(url);
  return data;
};

export const getModuleByChannelIdAPI = async (
  params: UseQueryOptions
): Promise<IResultModuleListDAO[]> => {
  const channelId = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Elearning/ModuleByChannel?channelId=${channelId}`
  );
  return data;
};

export const getClassByChannelIdAPI = async (params: UseQueryOptions)
: Promise<IResultClassNonSelfListDAO[]> => {
  const channelId = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Elearning/ClassNonSelf?channelId=${channelId}`
  );
  return data;
};

export const downloadLearningReportAPI = async params => {
  const {
    channelCode,
    channelId,
    classId,
    endDate,
    formatReport,
    moduleId,
    reportType,
    result,
    startDate,
    status,
    typeSchedule,
  } = params;

  const learningReportPath =
    reportType === 'journey'
      ? 'journey/training'
      : reportType === 'progress'
      ? 'progress/agent'
      : reportType === 'exam'
      ? 'exam'
      : reportType === 'selfLearning' || reportType === 'nonSelfLearning'
      ? `feedback/${formatReport}/${reportType}`
      : reportType === 'sessionPin'
      ? 'SessionPin'
      : reportType === 'trainingAttendance'
      ? 'Journey/Nonself'
      : reportType === 'learningHour'
      ? 'LearningHour'
      : '';

  const date =
    startDate && endDate
      ? `?startDate=${moment(startDate).format(formatDate)}&endDate=${moment(
          endDate
        ).format(formatDate)}`
      : '';

  const filter =
    reportType === 'sessionPin'
      ? `${typeSchedule ? `&scheduleType=${typeSchedule}` : ''}${
          channelId ? `&channelId=${channelId}` : ''
        }${classId ? `&classNonSelfId=${classId}` : ''}`
      : reportType === 'trainingAttendance'
      ? `${
          status && date
            ? `&status=${status}`
            : status && !date
            ? `?status=${status}`
            : ''
        }${
          (result && date) || (result && status)
            ? `&result=${result}`
            : result && !date && !status
            ? `?result=${result}`
            : ''
        }${
          (channelId && date) || (channelId && status) || (channelId && result)
            ? `&channelId=${channelId}`
            : (channelId && !date) || (channelId && !status && !result && !date)
            ? `?channelId=${channelId}`
            : ''
        }${classId ? `&classNonSelfId=${classId}` : ''}`
      : date &&
        reportType !== 'sessionPin' &&
        reportType !== 'trainingAttendance'
      ? `${channelCode ? `&channelCode=${channelCode}` : ''}${
          moduleId ? `&moduleId=${moduleId}` : ''
        }`
      : `${channelCode ? `?channelCode=${channelCode}` : ''}${
          moduleId ? `&moduleId=${moduleId}` : ''
        }`;

  let downloadFileName = '';
  switch (reportType) {
    case 'journey':
      downloadFileName = 'Journey Training Report';
      break;
    case 'progress':
      downloadFileName = 'Progress Report';
      break;
    case 'exam':
      downloadFileName = 'Exam Report';
      break;
    case 'selfLearning':
      downloadFileName = `${
        formatReport === 'summary' ? 'Feedback Summary' : 'Feedback Detail'
      }`;
      break;
    case 'nonSelfLearning':
      downloadFileName = `${
        formatReport === 'summary' ? 'Feedback Summary' : 'Feedback Detail'
      }`;
      break;
    case 'sessionPin':
      downloadFileName = 'Report PIN';
      break;
    case 'trainingAttendance':
      downloadFileName = 'Report Training Attendance';
      break;
    case 'learningHour':
      downloadFileName = 'Report Learning Hour';
      break;
  }

  await instance
    .get(
      `/ElearningReport/${learningReportPath}/download/xlsx${date}${filter}`,
      { responseType: 'blob' }
    )
    .then(response => {
      const DateNow = moment().format('DDMMYYYY');
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', `${downloadFileName}-${DateNow}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
};

import moment from 'moment';
import instance from '../Services/interceptor';
import Store from '../Store';

export const getReportListAPI = async (params: any) => {
  const { reportType, notificationType, dateFilter } = params;

  const formatDate = 'YYYY-MM-DD';
  let url: any;
  if (reportType === 'leadReport') {
    url = `/lead/report`;
  } else if (reportType === `leadInvitationReport`) {
    url = `/Zurich/notification/invitation/report`;
  } else if (reportType === 'notificationReport') {
    url = `/Zurich/notification/policy/report`;
  } else {
    url = '/Candidate/report/application';
  }

  if (notificationType) {
    url = `${url}?type=${notificationType}`;
  }

  if (dateFilter) {
    if (reportType === 'notificationReport' && notificationType) {
      url = `${url}&startdate=${moment(dateFilter[0]).format(
        formatDate
      )}&enddate=${moment(dateFilter[1]).format(formatDate)}`;
    } else {
      url = `${url}?startdate=${moment(dateFilter[0]).format(
        formatDate
      )}&enddate=${moment(dateFilter[1]).format(formatDate)}`;
    }
  }

  const { data } = await instance.get(url);
  return data;
};

export const downloadReportAsFile = async (params: any) => {
  const { reportType, reportDate, notificationType, formatReport } = params;
  const AuthState: any = Store.getState();
  const formatDate = 'YYYY-MM-DD';

  let url: any;
  if (reportType === 'leadReport') {
    url = `/lead/report/${formatReport}`;
  } else if (reportType === 'leadInvitationReport') {
    url = `/Zurich/notification/invitation/report/${formatReport}`;
  } else if (reportType === 'notificationReport' && !notificationType) {
    url = `/Zurich/notification/policy/report/${formatReport}`;
  } else if (reportType === 'notificationReport' && notificationType !== null) {
    url =
      `/Zurich/notification/policy/report/${formatReport}?type=` +
      notificationType;
  } else if (reportType === 'applicationNumberReport') {
    url = `/Candidate/report/application/${formatReport}`;
  }

  if (reportDate) {
    if (reportType === 'notificationReport' && notificationType) {
      url = `${url}&startdate=${moment(reportDate[0]).format(
        formatDate
      )}&enddate=${moment(reportDate[1]).format(formatDate)}`;
    } else {
      url = `${url}?startdate=${moment(reportDate[0]).format(
        formatDate
      )}&enddate=${moment(reportDate[1]).format(formatDate)}`;
    }
  }

  await instance
    .get(`${url}`, {
      responseType: 'blob',
      headers: { Authorization: `bearer ${AuthState.token}` },
      method: 'GET',
    })
    .then((response: any) => {
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      const fileName =
        reportType === 'leadReport'
          ? `Lead's Report`
          : reportType === 'leadInvitationReport'
          ? `Lead Invitation Report`
          : reportType === 'notificationReport'
          ? `Notification Report`
          : 'Application Number Report';
      link.href = urlDownload;
      link.setAttribute('download', `${fileName}.${formatReport}`);
      document.body.appendChild(link);
      link.click();
    });
};

import React, { useState } from 'react';
import { getHistoryPrivyAPI } from '../../../Services/privy.api';
import HistoryPrivyComponent from '../Component/HistoryPrivyComponent';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import CButton from '../../../Assets/Components/CButton';

export default function HistoryPrivyContainer() {
  const [modalDetailIsShow, setModalDetailIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState(false);

  const {
    data: privyHistoryList,
    fetchStatus,
    isLoading,
    isRefetching,
    refetch: refetchList,
  } = useQuery(['getHistoryPrivy'], getHistoryPrivyAPI, {
    refetchOnWindowFocus: false,
  });

  const renderStatus = (row: any) => {
    return (
      <div className="spanContestStatus">
        <span
          className={`uploadScheuleStatusColor uploadScheuleStatusColor${row.row.original.status}`}
        />
        <span className={`uploadScheduleStatusName`}>
          {row.row.original.status}
        </span>
      </div>
    );
  };

  const renderAction = (row: any) => {
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailPrivy',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: () => {
          setSelectedData(row.row.original);
          setModalDetailIsShow(true);
        },
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderAgreementType = (row: any) => {
    const rows = row?.row?.original;
    if (rows?.agreementType === 'NonLeader') {
      return 'Non Leader';
    } else {
      return 'Leader';
    }
  };

  const renderSearchAgreementType = (row: any) => {
    if (row?.agreementType === 'NonLeader') {
      return 'Non Leader';
    } else {
      return 'Leader';
    }
  };

  const columnData = [
    {
      Header: 'Nama Berkas',
      accessor: 'originalFilename',
    },
    {
      Header: 'Tipe Statement',
      accessor: (row: any) => renderSearchAgreementType(row),
      Cell: (row: any) => renderAgreementType(row),
    },
    {
      Header: 'Status Unggah',
      Cell: (row: any) => renderStatus(row),
      disableSort: true,
    },
    {
      Header: 'Tanggal Unggah',
      accessor: (row: any) => moment(row.uploadDate).format('DD-MMM-YYYY'),
      disableSort: true,
    },
    {
      Header: ' ',
      Cell: (row: any) => renderAction(row),
    },
  ];
  return (
    <HistoryPrivyComponent
      columnData={columnData}
      privyHistoryList={privyHistoryList || []}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      handleRefresh={refetchList}
      modalDetailIsShow={modalDetailIsShow}
      setModalDetailIsShow={setModalDetailIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
    />
  );
}

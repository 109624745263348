export const regExEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const RegistrationValidation = (
  values: any,
  resultValidation: any,
  selectedData: any
) => {
  const errors: any = {};
  // PROFILE
  const personaldata: any = {};
  if (values.personaldata && values.personaldata.agency) {
    const agency: any = {};
    if (
      values.personaldata.agency.candidateLevel &&
      values.personaldata.agency.candidateLevel === 'AD'
    ) {
      // tslint:disable-next-line: no-empty
    } else {
      if (!values.personaldata.agency.directLeader) {
        agency.directLeader = 'Wajib diisi';
      }
    }
    if (Object.keys(agency).length > 0) {
      personaldata.agency = agency;
    }
  }
  if (values.personaldata && values.personaldata.profile) {
    const profile: any = {};
    if (!values.personaldata.profile.idcard) {
      profile.idcard = 'Wajib diisi!';
    }
    if (!/^[A-Za-z .,']+$/.test(values.personaldata.profile.fullname)) {
      profile.fullname = `Hanya boleh menginputkan huruf dan karakter titik(.), koma(,) dan kutip(')`;
    }
    if (!/^\d+$/i.test(values.personaldata.profile.nik)) {
      profile.nik = 'Hanya diperbolehkan angka';
    } else if (values.personaldata.profile.nik.length !== 16) {
      profile.nik = 'Nik harus 16 Digit';
    }
    const validationNIK = resultValidation?.find(
      (x: any) => x.name === 'IdNumber'
    );
    if (values.personaldata.profile.nik === validationNIK?.value) {
      profile.nik = `NIK telah digunakan oleh ${validationNIK?.usedBy}`;
    }

    if (!regExEmail.test(values.personaldata.profile.email)) {
      profile.email = 'Masukan email dengan benar';
    }

    const validationEmail = resultValidation?.find(
      (x: any) => x.name === 'Email'
    );
    if (values.personaldata.profile.email === validationEmail?.value) {
      profile.email = `Email telah digunakan oleh ${validationEmail?.usedBy}`;
    }

    if (values.personaldata.profile.mobilephone) {
      if (
        values.personaldata.profile.mobilephone.charAt(0) === '0' ||
        values.personaldata.profile.mobilephone.charAt(
          values.personaldata.profile.mobilephone.length
        ) === '0'
      ) {
        profile.mobilephone = 'Masukan telepon HP dengan benar';
      } else if (
        (!/^\d+$/i.test(
          values.personaldata.profile.mobilephone.substring(
            3,
            values.personaldata.profile.mobilephone.length + 1
          )
        ) ||
          !/^\d+$/i.test(values.personaldata.profile.mobilephone)) &&
        selectedData
      ) {
        profile.mobilephone = 'Hanya diperbolehkan angka';
      } else if (
        !/^\d+$/i.test(values.personaldata.profile.mobilephone) &&
        !selectedData
      ) {
        profile.mobilephone = 'Hanya diperbolehkan angka';
      } else if (values.personaldata.profile.mobilephone.length < 8) {
        profile.mobilephone = 'Minimal 8 Digit';
      }

      const validationPhone = resultValidation?.find(
        (x: any) => x.name === 'PhoneNumber'
      );
      if (
        `+62${values.personaldata.profile.mobilephone}` ===
        validationPhone?.value
      ) {
        profile.mobilephone = `Telepon HP telah digunakan oleh ${validationPhone?.usedBy}`;
      }
    }
    if (values.personaldata.profile.homephone) {
      if (
        values.personaldata.profile.homephone.charAt(0) === '0' ||
        values.personaldata.profile.homephone.charAt(
          values.personaldata.profile.homephone.length
        ) === '0'
      ) {
        profile.homephone = 'Masukan telepon rumah dengan benar';
      } else if (!/^\d+$/i.test(values.personaldata.profile.homephone)) {
        profile.homephone = 'Hanya diperbolehkan angka';
      } else if (values.personaldata.profile.homephone.length < 8) {
        profile.homephone = 'Minimal 8 Digit';
      }
    }
    if (values.personaldata.profile.workphone) {
      if (
        values.personaldata.profile.workphone.charAt(0) === '0' ||
        values.personaldata.profile.workphone.charAt(
          values.personaldata.profile.workphone.length
        ) === '0'
      ) {
        profile.workphone = 'Masukan telepon kantor dengan benar';
      } else if (!/^\d+$/i.test(values.personaldata.profile.workphone)) {
        profile.workphone = 'Hanya diperbolehkan angka';
      } else if (values.personaldata.profile.workphone.length < 8) {
        profile.workphone = 'Minimal 8 Digit';
      }
    }
    if (Object.keys(profile).length > 0) {
      personaldata.profile = profile;
    }
  }
  // ADDRESS
  if (values.personaldata && values.personaldata.addresseducation) {
    const addresseducation: any = {};
    if (values.personaldata.addresseducation.postalcode) {
      if (values.personaldata.addresseducation.postalcode.length !== 5) {
        addresseducation.postalcode = 'Masukkan kode pos dengan benar';
      }
      if (!/^\d+$/i.test(values.personaldata.addresseducation.postalcode)) {
        addresseducation.postalcode = 'Hanya diperbolehkan angka';
      }
    }
    if (values.personaldata.addresseducation.postalcodedomicile) {
      if (
        values.personaldata.addresseducation.postalcodedomicile.length !== 5
      ) {
        addresseducation.postalcodedomicile = 'Masukkan kode pos dengan benar';
      }
      if (
        !/^\d+$/i.test(values.personaldata.addresseducation.postalcodedomicile)
      ) {
        addresseducation.postalcodedomicile = 'Hanya diperbolehkan angka';
      }
    }
    if (Object.keys(addresseducation).length > 0) {
      personaldata.addresseducation = addresseducation;
    }
  }

  // BANK
  const taxbankaccount: any = {};
  if (
    values.personaldata &&
    values.personaldata.taxbankaccount &&
    values.personaldata.taxbankaccount.bank
  ) {
    const bank: any = {};
    if (values.personaldata.taxbankaccount.bank.name) {
      if (
        !values.personaldata.taxbankaccount.bank.branch ||
        values.personaldata.taxbankaccount.bank.branch === ''
      ) {
        bank.branch = 'Wajib diisi';
      }
      if (
        !values.personaldata.taxbankaccount.bank.accountnumber ||
        values.personaldata.taxbankaccount.bank.accountnumber === ''
      ) {
        bank.accountnumber = 'Wajib diisi';
      } else {
        if (
          !/^\d+$/i.test(values.personaldata.taxbankaccount.bank.accountnumber)
        ) {
          bank.accountnumber = 'Hanya diperbolehkan angka';
        }
      }
      if (
        !values.personaldata.taxbankaccount.bank.accountholder ||
        values.personaldata.taxbankaccount.bank.accountholder === ''
      ) {
        bank.accountholder = 'Wajib diisi';
      }
    }
    if (Object.keys(bank).length > 0) {
      taxbankaccount.bank = bank;
    }
  }
  // TAX
  if (
    values.personaldata &&
    values.personaldata.taxbankaccount &&
    values.personaldata.taxbankaccount.hastax
  ) {
    const tax: any = {};
    if (!values.personaldata.taxbankaccount.tax) {
      tax.taxpayer = 'Wajib diisi';
      tax.taxownership = 'Wajib diisi';
      tax.taxdependents = 'Wajib diisi';
    } else {
      if (
        !values.personaldata.taxbankaccount.tax.taxpayer ||
        values.personaldata.taxbankaccount.tax.taxpayer === ''
      ) {
        tax.taxpayer = 'Wajib diisi';
      }
      if (
        !values.personaldata.taxbankaccount.tax.taxownership ||
        values.personaldata.taxbankaccount.tax.taxownership === ''
      ) {
        tax.taxownership = 'Wajib diisi';
      }
      if (
        !values.personaldata.taxbankaccount.tax.taxdependents ||
        values.personaldata.taxbankaccount.tax.taxdependents === ''
      ) {
        tax.taxdependents = 'Wajib diisi';
      }
    }
    if (Object.keys(tax).length > 0) {
      taxbankaccount.tax = tax;
    }
  }
  if (Object.keys(taxbankaccount).length > 0) {
    personaldata.taxbankaccount = taxbankaccount;
  }
  errors.personaldata = personaldata;

  const beneficiary: any = {};
  if (values.beneficiary && values.beneficiary.form) {
    const form: any = {};
    beneficiary.form = form;
    if (values.beneficiary.form.homephone) {
      if (
        values.beneficiary.form.homephone.charAt(0) === '0' ||
        values.beneficiary.form.homephone.charAt(
          values.beneficiary.form.homephone.length
        ) === '0'
      ) {
        form.homephone = 'Masukkan telepon rumah dengan benar';
      } else if (!/^\d+$/i.test(values.beneficiary.form.homephone)) {
        form.homephone = 'Hanya diperbolehkan angka';
      } else if (values.beneficiary.form.homephone.length < 8) {
        form.homephone = 'Minimal 8 Digit';
      }
    }
    if (values.beneficiary.form.mobilephone) {
      if (
        values.beneficiary.form.mobilephone.charAt(0) === '0' ||
        values.beneficiary.form.mobilephone.charAt(
          values.beneficiary.form.mobilephone.length
        ) === '0'
      ) {
        form.mobilephone = 'Masukkan telepon HP dengan benar';
      } else if (!/^\d+$/i.test(values.beneficiary.form.mobilephone)) {
        form.mobilephone = 'Hanya diperbolehkan angka';
      } else if (values?.personaldata?.profile?.mobilephone?.length < 8) {
        form.mobilephone = 'Minimal 8 Digit';
      }
    }
  }
  errors.beneficiary = beneficiary;
  return errors;
};
export default RegistrationValidation;

import {
  InputText,
  InputTimePicker,
} from '../../../../Assets/Components/CInput';
import React, { useEffect, useRef } from 'react';
import {
  getElearningCityAPI,
  getElearningLocationDataAPI,
  getElearningProvinceAPI,
} from '../../../../Services/elearninglocation.api';
import { useMutation, useQuery } from '@tanstack/react-query';

import CButton from '../../../../Assets/Components/CButton';
import ClassModalScheduleComponent from '../Component/ClassModalScheduleComponent';
import { Field } from 'react-final-form';
import { InputDatePicker } from '../../../../Assets/Components/CInputDate';
import { getScheduleDetailAPI } from '../../../../Services/scheduleNonSelf.api';
import moment from 'moment';
import {
  getTrainerListAPI,
  mutateScheduleNonSelf,
} from '../../../../Services/elearning.api';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Select from '../../../../Assets/Components/Select';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  dataClassNonSelf: any;
  listSchedule: any[];
  modalAction: 'update' | 'register';
  showModal: boolean;
  refetchSchedule: () => void;
  selectedData: any;
  setListSchedule: React.Dispatch<React.SetStateAction<any[]>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const validateDate = dateData => {
  const momentDate = moment.isMoment(dateData) ? dateData : moment(dateData);
  const momentNow = moment();
  return dateData && momentDate.diff(momentNow, 'seconds') < 1;
};

export default function ClassModalScheduleContainer({
  dataClassNonSelf,
  listSchedule,
  modalAction,
  refetchSchedule,
  selectedData,
  setListSchedule,
  setSelectedData,
  setShowModal,
  showModal,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [selectedProvince, setSelectedProvince] = useState();
  const [listSession, setListSession] = useState<any[]>([]);
  const [listDeleteSchedule, setListDeleteSchedule] = useState([]);
  const [initialValues, setInitialValues] = useState<any>();

  const formRef: React.MutableRefObject<any> = useRef(null);

  const { data: dataProvince } = useQuery(
    ['Get Province'],
    getElearningProvinceAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: listTrainer } = useQuery(
    ['getTrainerList'],
    getTrainerListAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const {
    data: dataScheduleDetail,
    isLoading: isLoadingScheduleDetail,
    fetchStatus: fetchStatusScheduleDetail,
  } = useQuery(
    ['Get Schedule Detail', selectedData?.id],
    getScheduleDetailAPI,
    {
      enabled: selectedData?.id !== undefined && listSession.length === 0,
      refetchOnWindowFocus: false,
    }
  );

  const mutationSchedule = useMutation(mutateScheduleNonSelf);

  const { data: dataLocation } = useQuery(
    [
      'Get Location Data',
      dataScheduleDetail?.codemiData?.location_classrooms[0]?.location_id,
    ],
    getElearningLocationDataAPI,
    {
      enabled:
        dataScheduleDetail?.codemiData?.location_classrooms[0]?.location_id !==
        undefined,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!selectedData) return;
    setInitialValues(selectedData);
  }, [selectedData]);

  useEffect(() => {
    const sessions: any[] = dataScheduleDetail?.codemiData?.sessions;

    if (
      sessions &&
      fetchStatusScheduleDetail === 'idle' &&
      initialValues &&
      !initialValues.sessions
    ) {
      sessions.sort(function compare(a, b) {
        const dateA: any = new Date(a.start_time);
        const dateB: any = new Date(b.start_time);
        return dateA - dateB;
      });
      const detailedSessions: any[] = [];
      sessions.forEach(item => {
        const initSession: any = initialValues.schedule?.find(
          val => val.id === item.id
        );
        detailedSessions.push({
          ...item,
          disabled: initSession?.disabled || false,
          date: initSession?.date || null,
          startTime: initSession?.startTime || null,
          endTime: initSession?.endTime || null,
          trainerName: item.trainers_id || [],
        });
      });

      setListSession(sessions);
      setInitialValues((prev: any) => ({
        ...prev,
        sessions: detailedSessions,
      }));
    }
    // eslint-disable-next-line
  }, [dataScheduleDetail?.codemiData?.sessions, fetchStatusScheduleDetail]);

  useEffect(() => {
    if (modalAction === 'register' && selectedData) {
      setSelectedProvince(selectedData.province);
    }
    // eslint-disable-next-line
  }, []);

  const { data: dataCity } = useQuery(
    ['Get City', selectedProvince || dataLocation?.provinceId],
    getElearningCityAPI,
    {
      enabled:
        selectedProvince !== undefined ||
        dataLocation?.provinceId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const handleClose = () => {
    formRef.current.form.restart();
    formRef.current = undefined;
    setListSession([]);
    setListDeleteSchedule([]);
    setSelectedData(undefined);
    setInitialValues(undefined);
    setShowModal(false);
    setEditMode(false);
  };

  const onSubmit = vals => {
    const {
      type,
      date,
      endTime,
      meetingLink,
      platform,
      startTime,
      locationDetails,
      province,
      venue,
      city,
      locationLink,
      sessions,
    } = vals;

    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      const objClass: any = { type, date, startTime, endTime };
      if (type === 'online') {
        objClass.platform = platform;
        objClass.meetingLink = meetingLink;
      } else {
        objClass.city = city;
        objClass.province = province;
        objClass.venue = venue;
        objClass.locationDetails = locationDetails;
        objClass.locationLink = locationLink;
      }

      if (modalAction === 'register') {
        const tempList = [...listSchedule];
        if (selectedData) {
          tempList[selectedData.index] = objClass;
        } else {
          tempList.push(objClass);
        }
        setListSchedule(tempList);
        setShowModal(false);
      } else {
        if (!selectedData) {
          mutationSchedule.mutate(
            {
              ...objClass,
              classId: dataClassNonSelf.id,
            },
            {
              onSuccess: () => {
                formRef.current.form.restart();
                setListSession([]);
                setListDeleteSchedule([]);
                setSelectedData(undefined);
                setInitialValues(undefined);
                toast.success(
                  <span className="capitalFirst">Success create schedule!</span>
                );
                setShowModal(false);
                setEditMode(false);
                refetchSchedule();
              },
              onError: (error: any) => {
                ErrorHandler(error);
              },
            }
          );
        } else {
          mutationSchedule.mutate(
            {
              ...objClass,
              classId: dataClassNonSelf.id,
              sessions,
              deletedSessions: listDeleteSchedule,
              id: selectedData?.id,
              learning_schedule_id:
                dataScheduleDetail?.codemiData?.schedule_detail?.id,
              minAttendRequire: sessions.length,
            },
            {
              onSuccess: () => {
                formRef.current.form.restart();
                setListSession([]);
                setListDeleteSchedule([]);
                setSelectedData(undefined);
                setInitialValues(undefined);
                toast.success(
                  <span className="capitalFirst">Success update schedule!</span>
                );
                setShowModal(false);
                setEditMode(false);
                refetchSchedule();
              },
              onError: (error: any) => {
                ErrorHandler(error);
              },
            }
          );
        }
      }
    }
  };

  if (
    modalAction === 'update' &&
    selectedData &&
    fetchStatusScheduleDetail === 'idle'
  ) {
    const { info } = selectedData;
    if (initialValues) {
      initialValues.type = info.toLowerCase();
      initialValues.platform =
        dataScheduleDetail?.codemiData?.location_classrooms[0]?.platform;
      initialValues.meetingLink =
        dataScheduleDetail?.codemiData?.location_classrooms[0]?.meeting_url;
      initialValues.locationLink = dataScheduleDetail?.locationLink;
    }

    if (dataScheduleDetail && modalAction === 'update') {
      const sessionList = dataScheduleDetail?.codemiData?.sessions;
      const scheduleData: any = [];
      for (const key in sessionList) {
        if (Object.prototype.hasOwnProperty.call(sessionList, key)) {
          const element = sessionList[key];
          const { start_time, end_time, id, trainers_id } = element;
          moment.locale('id');
          scheduleData.push({
            id,
            date: moment(start_time),
            startTime: moment(start_time),
            endTime: moment(end_time),
            disabled: validateDate(start_time),
            trainerName: trainers_id || [],
          });
        }
      }
      if (initialValues) initialValues.schedule = scheduleData;
    }
    if (dataLocation) {
      if (initialValues) {
        initialValues.venue = dataLocation.address;
        initialValues.locationDetails = dataLocation.description;
        initialValues.province = String(dataLocation.provinceId);
        initialValues.city = String(dataLocation.cityId);
      }
    }
  }

  const columnData = [
    {
      Header: 'No',
      accessor: 'no',
      Cell: row => parseInt(row.row.id, 10) + 1,
    },
    {
      Header: 'Date',
      Cell: row => {
        const rowIndex = row.row.index;
        const rowData = row.row.original;
        return (
          <>
            <div style={{ display: 'none' }}>
              <Field
                name={`sessions.[${rowIndex}].id`}
                component={InputText}
                disabled={true}
              />
            </div>
            <Field
              name={`sessions.[${rowIndex}].date`}
              component={InputDatePicker}
              typeDate="datePicker"
              disabledDate={current =>
                current.isBefore(moment().subtract(1, 'day'))
              }
              formatInput="DD-MMM-YYYY"
              disabled={!editMode || validateDate(rowData.start_time)}
            />
            <div style={{ display: 'none' }}>
              <Field
                name={`sessions.[${rowIndex}].disabled`}
                component="input"
                type="checkbox"
                disabled={true}
                checked={validateDate(rowData.start_time)}
              />
            </div>
          </>
        );
      },
    },
    {
      Header: 'StartTime',
      accessor: 'start_time',
      Cell: row => {
        const rowIndex = row.row.index;
        const rowData = row.row.original;
        return (
          <Field
            name={`sessions.[${rowIndex}].startTime`}
            component={InputTimePicker}
            disabled={!editMode || validateDate(rowData.start_time)}
          />
        );
      },
    },
    {
      Header: 'End Time',
      accessor: 'end_time',
      Cell: row => {
        const rowIndex = row.row.index;
        const rowData = row.row.original;
        return (
          <Field
            name={`sessions.[${rowIndex}].endTime`}
            component={InputTimePicker}
            disabled={!editMode || validateDate(rowData.start_time)}
          />
        );
      },
    },
    {
      Header: 'Trainer',
      accessor: 'trainer_name',
      Cell: row => {
        const rowIndex = row.row.index;
        const rowData = row.row.original;
        return (
          <Field
            allowClear={true}
            name={`sessions.[${rowIndex}].trainerName`}
            component={Select}
            idComponent="modalTrainerName"
            dataOption={listTrainer || []}
            placeholder="-Select-"
            showSearch={true}
            mode="multiple"
            optionFilterProp="label"
            filterOption={true}
            customValue="userId"
            disabled={!editMode || validateDate(rowData.start_time)}
            className="selectInTable"
          />
        );
      },
    },
    {
      Header: 'Check IN PIN',
      accessor: 'code_in',
    },
    {
      Header: 'Check OUT PIN',
      accessor: 'code_out',
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const renderAction = (row: any) => {
    const rowData = row.row.original;
    const rowIndex = row.row.index;

    const handleDelete = () => {
      const tmpListDelete: any = [...listDeleteSchedule];
      const tmplistSession = [...listSession].filter((_, index) => {
        return index !== rowIndex;
      });
      formRef.current.form.mutators.remove('schedule', rowIndex);
      if (rowData.id) tmpListDelete.push(rowData.id);

      const detailedSessions: any[] = [];
      tmplistSession.forEach(item => {
        const initSession: any = initialValues.schedule?.find(
          val => val.id === item.id
        );
        detailedSessions.push({
          ...item,
          disabled: initSession?.disabled || false,
          date: initSession?.date || null,
          startTime: initSession?.startTime || null,
          endTime: initSession?.endTime || null,
          trainerName: initSession?.trainerName || [],
        });
      });

      setInitialValues((prev: any) => ({
        ...prev,
        sessions: detailedSessions,
      }));
      setListSession(detailedSessions);
      setListDeleteSchedule(tmpListDelete);
    };

    const dataButton = [
      {
        id: `btnDeleteClass`,
        icon: 'DeleteOutlined',
        onClick: handleDelete,
        className: 'btnDelete',
        disabled: !editMode || validateDate(rowData.start_time),
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const handleAddSession = () => {
    const tmpList = [...listSession];
    const sessionLastIdx = tmpList.length - 1;
    tmpList.push({ index: sessionLastIdx + 1 });

    const detailedSessions: any[] = [];
    tmpList.forEach(item => {
      const initSession: any = initialValues.schedule?.find(
        val => val.id === item.id
      );
      detailedSessions.push({
        ...item,
        disabled: initSession?.disabled || false,
        date: initSession?.date || null,
        startTime: initSession?.startTime || null,
        endTime: initSession?.endTime || null,
        trainerName: initSession?.trainerName || [],
      });
    });

    setInitialValues((prev: any) => ({
      ...prev,
      sessions: detailedSessions,
    }));
    setListSession(detailedSessions);
  };

  return (
    <ClassModalScheduleComponent
      classAction={modalAction}
      columnData={columnData}
      dataCity={dataCity?.data.cities}
      dataProvince={dataProvince?.data.provinces}
      dataScheduleDetail={dataScheduleDetail}
      editMode={editMode}
      formRef={formRef}
      handleAddSession={handleAddSession}
      handleCancel={handleClose}
      handleClose={handleClose}
      initialValues={initialValues}
      isLoading={
        mutationSchedule.isLoading ||
        (modalAction === 'update' && selectedData && !dataScheduleDetail)
      }
      isLoadingTable={isLoadingScheduleDetail}
      isShow={showModal}
      listSession={listSession}
      modalAction={selectedData ? 'update' : 'register'}
      onSubmit={onSubmit}
      setEditMode={setEditMode}
      setSelectedProvince={setSelectedProvince}
    />
  );
}

import { Field } from 'react-final-form';
import React, { useEffect, useState } from 'react';
import CButton from './CButton';
import { Checkbox } from 'antd';
import { InputText } from './CInput';
import { multipleCheckbox } from './CCheckbox';
import { FormApi } from 'final-form';

interface IProps {
  formName: string;
  titleContentLeft: string;
  titleContentRight: string;
  listBank: any[];
  listSelected: any[];
  actionSetListBank: (val: any) => void;
  actionSetListSelected: (val: any) => void;
  form: FormApi<any, Partial<any>>;
  formValues: any;
}

export default function CTransferTextRFF(props: IProps) {
  const {
    titleContentLeft,
    titleContentRight,
    listBank,
    listSelected,
    actionSetListBank,
    actionSetListSelected,
    form,
    formValues,
  } = props;
  const [listFilterBank, setlistFilterBank] = useState(listBank);
  const [listFilterSelected, setlistFilterSelected] = useState(listSelected);
  const listIdBank =
    formValues && formValues.multipleCheckItemSelected
      ? formValues.multipleCheckItemSelected
      : [];

  useEffect(() => {
    setlistFilterBank(listBank);
  }, [listBank]);

  useEffect(() => {
    setlistFilterSelected(listSelected);
  }, [listSelected]);

  const handleTransferQuestion = () => {
    const listIdQuestion = formValues.multipleCheckItemSelected;
    const listQuestion: any = [...listBank];
    const listSelected: any = [];
    listQuestion.forEach(question => {
      for (const id of listIdQuestion) {
        if (question.value === id) {
          listSelected.push(question);
        }
      }
    });
    for (const id of listIdQuestion) {
      const index = listQuestion.findIndex(question => question.value === id);
      listQuestion.splice(index, 1);
    }
    actionSetListBank(listQuestion);
    setlistFilterBank(listQuestion);

    const newListSelected: any = listFilterSelected;
    for (const iterator of listSelected) {
      newListSelected.push(iterator);
    }
    actionSetListSelected(newListSelected);
    setlistFilterSelected(newListSelected);

    form.change('multipleCheckItemSelected', []);
  };

  const handleOnChangeCheckbox = (e: any) => {
    if (e.target.checked) {
      const listId: any = [];
      for (const bank of listFilterBank) {
        listId.push(bank.value);
      }
      form.change('multipleCheckItemSelected', listId);
    } else {
      form.change('multipleCheckItemSelected', []);
    }
  };

  const dataButtonTransfer = [
    {
      type: 'primary',
      className: 'btnTransferText',
      icon: 'RightOutlined',
      id: 'btnTransferText',
      onClick: handleTransferQuestion,
      disabled: listIdBank.length > 0 ? false : true,
    },
  ];

  const handleOnChangeSearchBank = (e: any) => {
    const listFilter: any = [];
    for (const bank of listBank) {
      if (bank.label.toLowerCase().indexOf(e.toLowerCase()) > -1) {
        listFilter.push(bank);
      }
    }
    setlistFilterBank(listFilter);
  };

  const handleOnchangeSearchSelected = (e: any) => {
    const listFilter: any = [];
    for (const selected of listSelected) {
      if (selected.label.toLowerCase().indexOf(e.toLowerCase()) > -1) {
        listFilter.push(selected);
      }
    }
    setlistFilterSelected(listFilter);
  };
  return (
    <div className="container_transfer_text">
      <div className="box_transfer_text">
        <div className="title_box_transfet_text">
          <span className="text_title_box_transfer">{titleContentLeft}</span>
        </div>
        <div className="container_checkbox_transfer_text">
          <Checkbox
            onChange={handleOnChangeCheckbox}
            checked={
              listIdBank.length === 0
                ? false
                : listIdBank.length === listFilterBank.length
                ? true
                : false
            }
          >
            {listFilterBank.length} Item
          </Checkbox>
        </div>
        <hr className="line_transfer_text" />
        <div className="content_box_transfer_text">
          <>
            <Field
              name="searchTextBank"
              component={InputText}
              allowClear={true}
              placeholder="Cari"
              icon="SearchOutlined"
              addtionalFunctionOnChange={handleOnChangeSearchBank}
            />
            <Field
              name="multipleCheckItemSelected"
              component={multipleCheckbox}
              options={listFilterBank}
              className="multipleCheckboxTransferText"
            />
          </>
        </div>
      </div>
      <div className="container_button_transfer">
        <CButton buttonData={dataButtonTransfer} />
      </div>
      <div className="box_transfer_text">
        <div className="title_box_transfet_text">
          <span className="text_title_box_transfer">{titleContentRight}</span>
        </div>
        <div className="container_checkbox_transfer_text">
          {listFilterSelected.length} Item
        </div>
        <hr className="line_transfer_text" />
        <div className="content_box_transfer_text">
          <Field
            name="searchTextSelected"
            component={InputText}
            placeholder="Cari"
            icon="SearchOutlined"
            addtionalFunctionOnChange={handleOnchangeSearchSelected}
            allowClear={true}
          />
          {listFilterSelected.map(option => {
            return (
              <div key={option.value} className="seleceted_transfer_text">
                {option.label}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import CButton from '../../../Assets/Components/CButton';
import HasPermission from '../../../App/HasPermission';
import { injectIntl } from 'react-intl';
import { IResultInvitationListDAO } from '../DAO/invitation.dao';

interface IProps {
  columnData: any;
  handleAdd: () => void;
  handleRefresh: () => void;
  invitationList: IResultInvitationListDAO[];
  isLoading: boolean;
  intl?: any;
}

function InvitationComponent(props: IProps) {
  const {
    columnData,
    handleAdd,
    handleRefresh,
    intl,
    invitationList,
    isLoading,
  } = props;

  const buttonData = [
    HasPermission('c')
      ? {
          type: 'primary',
          icon: 'PlusOutlined',
          onClick: handleAdd,
          content: 'Add New',
          id: 'btnAddInvitationContent',
        }
      : null,
  ];

  const cardInvitationContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          isLoading={isLoading}
          handleRefresh={handleRefresh}
        />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={invitationList}
          pagination={true}
          useFilterGlobal
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Invitation" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'invitationCardTitle',
            })}
          </span>
        }
        cardContent={cardInvitationContent()}
      />
    </>
  );
}

export default injectIntl(InvitationComponent);

import { FormApi } from 'final-form';
import React, { useState, useEffect } from 'react';
import ModalSelfLearningComponent from '../Component/ModalSelfLearningComponent';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { mutateFeedbackBankAPI } from '../../../Services/ELearningFeedbackBank/feedbackBank.api';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  channelData: any[];
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  handleRefresh: () => void;
}

export default function ModalSelfLearningContainer(props: IProps) {
  const {
    channelData,
    showModal,
    selectedData,
    handleRefresh,
    setSelectedData,
    setShowModal,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [freeTextQuestions, setFreeTextQuestions] = useState<any[]>([]);
  const [multipleChoiceQuestions, setMultipleChoiceQuestions] = useState<any[]>(
    []
  );
  const modalAction = selectedData ? 'update' : 'register';
  let initialValues: any = selectedData;

  useEffect(() => {
    if (!selectedData) return;

    initialValues.category = selectedData.category;
    initialValues.channel = selectedData.channelId;
    selectedData?.answers.forEach((item: any, index: number) => {
      const { answer, point, isActive } = item;
      initialValues[`answer${index}`] = answer;
      initialValues[`point${index}`] = point;
      initialValues[`isActive${index}`] = isActive;
    });
    const freeTextQuestion: any[] = [];
    selectedData.essayQuestions.forEach(item =>
      freeTextQuestion.push(item.question)
    );
    setFreeTextQuestions(freeTextQuestion);
    const multipleChoiceQuestion: any[] = [];
    selectedData.multipleChoiceQuestions.forEach(item =>
      multipleChoiceQuestion.push(item.question)
    );
    setMultipleChoiceQuestions(multipleChoiceQuestion);
    //  eslint-disable-next-line
  }, [selectedData]);

  const { mutate } = useMutation(mutateFeedbackBankAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage
            id={
              selectedData
                ? 'updateFeedbackBankSuccess'
                : 'submitFeedbackBankSuccess'
            }
          />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleRefresh();
      setEditMode(false);
      setShowModal(false);
      setFreeTextQuestions([]);
      setMultipleChoiceQuestions([]);
    },
  });

  const handleSubmitMultipleChoiceQuestion = (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => {
    const tempArr: any[] = [...multipleChoiceQuestions];
    tempArr.push(values);
    setMultipleChoiceQuestions(tempArr);
    form.change('multipleQuestionTitle', null);
  };

  const handleRemoveMultipleChoiceQuestion = (
    index: number,
    form: FormApi<any, Partial<any>>
  ) => {
    const tempArr: any[] = [...multipleChoiceQuestions];
    const newArr = tempArr.filter((_, i) => i !== index);
    setMultipleChoiceQuestions(newArr);
    form.change('multipleQuestionTitle', null);
  };

  const handleSubmitFreeTextQuestion = (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => {
    const tempArr: any[] = [...freeTextQuestions];
    tempArr.push(values);
    setFreeTextQuestions(tempArr);
    form.change('freeTextQuestionTitle', null);
  };

  const handleRemoveFreeTextQuestion = (
    index: number,
    form: FormApi<any, Partial<any>>
  ) => {
    const tempArr: any[] = [...freeTextQuestions];
    const newArr = tempArr.filter((_, i) => i !== index);
    setFreeTextQuestions(newArr);
    form.change('freeTextQuestionTitle', null);
  };

  const handleCancel = () => {
    setFreeTextQuestions([]);
    setMultipleChoiceQuestions([]);
    setEditMode(false);
    setSelectedData(undefined);
    setShowModal(false);
  };

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate({
        ...vals,
        moduleId: selectedData?.moduleId,
        feedbackType: 'selfLearning',
        freeTextQuestions,
        multipleChoiceQuestions,
      });
    }
  };

  return (
    <ModalSelfLearningComponent
      channelList={channelData}
      disabled={editMode === false && modalAction === 'update' ? true : false}
      editMode={editMode}
      isLoading={false}
      modalAction={modalAction}
      showModal={showModal}
      listFreeTextQuestion={freeTextQuestions}
      listMultipleChoiceQuestion={multipleChoiceQuestions}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleSubmitMultipleChoiceQuestion={handleSubmitMultipleChoiceQuestion}
      handleRemoveMultipleChoiceQuestion={handleRemoveMultipleChoiceQuestion}
      handleSubmitFreeTextQuestion={handleSubmitFreeTextQuestion}
      handleRemoveFreeTextQuestion={handleRemoveFreeTextQuestion}
      initialValues={initialValues}
    />
  );
}

import React, { useState } from 'react';

import HasPermission from '../../../App/HasPermission';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import { getConfigurationListAPI } from '../../../Services/config.api';
import { useQuery } from '@tanstack/react-query';
import ConfigurationComponent from '../Component/ConfigurationComponent';

export default function ConfigurationContainerprops() {
  const [modalConfigIsShow, setModalConfigIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: configurationList,
    isLoading,
    isRefetching,
    fetchStatus,
    refetch,
  } = useQuery(['getConfigurationList'], getConfigurationListAPI, {
    enabled: !modalConfigIsShow,
    refetchOnWindowFocus: false,
  });

  const renderAction = (row: any) => {
    return renderActionComponent(
      isLoading,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
      },
      {
        handleUpdate: () => {
          setSelectedData(row.row.original);
          setModalConfigIsShow(true);
        },
      }
    );
  };
  const columnData = [
    {
      Header: 'Parameter',
      accessor: 'parameter',
    },
    {
      Header: 'Parameter Type',
      accessor: 'parameterType',
    },
    {
      Header: 'Parameter Value',
      accessor: 'parameterValue',
    },
    HasPermission('u')
      ? {
          Header: 'Action',
          Cell: (row: any) => renderAction(row),
        }
      : {
          accessor: 'hideAction',
        },
  ];
  return (
    <ConfigurationComponent
      columnData={columnData}
      configurationList={configurationList || []}
      handleRefresh={refetch}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
      modalConfigIsShow={modalConfigIsShow}
      selectedData={selectedData}
      setModalConfigIsShow={setModalConfigIsShow}
      setSelectedData={setSelectedData}
    />
  );
}

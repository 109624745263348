import instance from './interceptor';
import moment from 'moment';
import Store from '../Store';

export const getTrackerListAPI = async (params: any) => {
  const { trackerType, dateFilter } = params;
  const formatDate = 'YYYY-MM-DD';
  const { data } = await instance.get(
    `/analytic/${trackerType}/${moment(dateFilter[0]).format(
      formatDate
    )}/${moment(dateFilter[1]).format(formatDate)}`
  );
  return data;
};

export const downloadTrackerAsFile = async (params: any) => {
  const { trackerType, formatReport, trackerDate } = params;
  const AuthState: any = Store.getState();
  const formatDate = 'YYYY-MM-DD';
  const nameFileDownload = () => {
    if (trackerType === 'initiallogin') {
      return 'Tracker Unique Login Report';
    } else if (trackerType === 'module/tracking') {
      return 'Tracker Module Report';
    } else if (trackerType === 'useraccess/summary') {
      return 'Tracker Login Summary Report';
    } else if (trackerType === 'useraccess/detail') {
      return 'Tracker Login Detail Report';
    } else {
      return 'Tracker';
    }
  };
  await instance
    .get(
      `/analytic/${trackerType}/report/${formatReport}/${moment(
        trackerDate[0]
      ).format(formatDate)}/${moment(trackerDate[1]).format(formatDate)}`,
      {
        responseType: 'blob',
        headers: { Authorization: `bearer ${AuthState.token}` },
      }
    )
    .then((response: any) => {
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', `${nameFileDownload()}.${formatReport}`);
      document.body.appendChild(link);
      link.click();
    });
};

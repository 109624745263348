import React from 'react';
import ContestTabUploadFileComponent from '../Component/ContestTabUploadFileComponent';

export default function ContestTabUploadFileContainer() {
  return (
    <ContestTabUploadFileComponent
      // handleUpload={handleUpload}
      isLoading={false}
    />
  );
}

import React from 'react';
import { injectIntl } from 'react-intl';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import ModalDisclaimerMappingContainer from '../Container/ModalDisclaimerMappingContainer';
import { IResultDisclaimerContentListDAO, IResultDisclaimerMappingListDAO, IResultDisclaimerModuleListDAO } from '../DAO/disclaimer.dao';

interface IProps {
  columnData: any;
  disclaimerContentList: IResultDisclaimerContentListDAO[];
  disclaimerMappingList: IResultDisclaimerMappingListDAO[];
  disclaimerModuleList: IResultDisclaimerModuleListDAO[];
  handleRefresh: () => void;
  initialValues: any;
  isLoading: boolean;
  modalIsShow: boolean;
  selectedData: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  intl?: any;
}

function DisclaimerMappingComponent(props: IProps) {
  const {
    columnData,
    disclaimerContentList,
    disclaimerMappingList,
    disclaimerModuleList,
    handleRefresh,
    initialValues,
    intl,
    isLoading,
    modalIsShow,
    selectedData,
    setModalIsShow,
    setSelectedData,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      icon: 'PlusOutlined',
      onClick: () => setModalIsShow(true),
      content: 'Add Mapping',
      id: 'btnAddDisclaimerMapping',
      className: 'spacingBtnSecondRow2 btnRadius10',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshDisclaimerContentMapping',
      className: 'btnRadius10',
      onClick: handleRefresh,
    },
  ];

  const cardDisclaimerMapping = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton buttonData={buttonData} buttonFloat={'right'} />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={disclaimerMappingList}
          pagination={true}
        />
        <ModalDisclaimerMappingContainer
          showModal={modalIsShow}
          setModalIsShow={setModalIsShow}
          intl={intl}
          handleRefresh={handleRefresh}
          initialValues={initialValues}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          disclaimerContentList={disclaimerContentList}
          disclaimerModuleList={disclaimerModuleList}
        />
      </div>
    );
  };

  return (
    <>
      <CLabelCard leftText="Disclaimer" rightText="Mapping" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'disclaimerMappingCardTitle',
            })}
          </span>
        }
        cardContent={cardDisclaimerMapping()}
      />
    </>
  );
}
export default injectIntl(DisclaimerMappingComponent);

import React, { useState } from 'react';
import UploadPrivyComponent from '../Component/UploadPrivyComponent';
import { mutateRenewTokenAPI } from '../../../Services/login.api';
import { useMutation } from '@tanstack/react-query';

export default function UploadPrivyContainer() {
  const [selectedType, setSelectedType] = useState();

  const { mutate: mutateRenewToken } = useMutation(mutateRenewTokenAPI);

  const renewToken = () => {
    mutateRenewToken();
  };
  return (
    <UploadPrivyComponent
      selectedType={selectedType}
      setSelectedType={setSelectedType}
      renewToken={renewToken}
    />
  );
}

import instance from './interceptor';
import Store from '../Store';

const state: any = Store.getState();

export const mutateLoginAPI = async (params: any) => {
  const { username, password } = params;
  const payload = { username, password };
  const { data } = await instance.post(`/login`, payload);
  return data;
};

export const mutateRenewTokenAPI = async () => {
  const { data } = await instance.post(
    `/token/refresh/${state.tokenRefresh}`,
    `"${state.token}"`,
    { headers: { 'Content-Type': 'application/json' } }
  );
  return data;
};

const TrackerValidation = ({ trackerType, dateFilter }) => {
  const errors: any = {};
  if (!trackerType) {
    errors.trackerType = 'Tracker type required!';
  }
  if (!dateFilter || dateFilter === null) {
    errors.dateFilter = 'Date filter required!';
  }
  return errors;
};
export default TrackerValidation;

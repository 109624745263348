import React from 'react';

interface IProps {
  title;
  description?;
  value;
  unit?;
}

export default function CDashboardComponent(props: IProps) {
  const { title, value, unit, description } = props;
  const dashboardValueDesc = () => {
    if (description) {
      return 'dashboardValueDesc';
    }
    return '';
  };
  const dashboardValueTitleHasDesc = () => {
    if (description) {
      return 'dashboardValueTitleHasDesc';
    }
    return '';
  };
  return (
    <React.Fragment>
      <div className="dashboardValueContainer">
        <div className={`dashboardValueTitle ${dashboardValueTitleHasDesc}`}>
          {title}
        </div>
        {description ? (
          <div className={`dashboardValueTitle ${dashboardValueDesc}`}>
            {description}
          </div>
        ) : null}
        <span className="dashboardValue">{value}</span>
        {unit ? <p className="dashboardValueUnit">{unit}</p> : null}
      </div>
    </React.Fragment>
  );
}

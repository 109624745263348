const AgreementMappingValidation = ({
  disclaimerId,
  disclaimerModuleId,
  agreementType,
}) => {
  const errors: any = {};
  if (!disclaimerId) {
    errors.disclaimerId = 'Agreement name is required!';
  }
  if (!disclaimerModuleId) {
    errors.disclaimerModuleId = 'Module is required!';
  }
  if (!agreementType) {
    errors.agreementType = 'Type is required!';
  }
  return errors;
};
export default AgreementMappingValidation;

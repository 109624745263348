import React, { useState } from 'react';
import {
  getAgreementContentAPI,
  mutateStatusAgreementContentAPI,
} from '../../../Services/Agreement/agreementContent.api';
import AgreementContentComponent from '../Component/AgreementContentComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import CButton from '../../../Assets/Components/CButton';
import ErrorHandler from '../../../App/ErrorHandler';

export default function AgreementContentContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: agreementContentList,
    isLoading,
    isRefetching,
    refetch,
    fetchStatus,
  } = useQuery(['getAgreementContent'], getAgreementContentAPI, {
    refetchOnWindowFocus: false,
  });

  const mutateStatus = useMutation(mutateStatusAgreementContentAPI, {
    onSuccess: () => {
      refetch();
      toast.success(
        <span className="capitalFirst">Update status agreement sukses!</span>
      );
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const processStatus = (id: number, status: any, body: any) => {
    mutateStatus.mutate({ ...body, isActive: status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const oriObj = row.row.original;
    const body = {
      name: oriObj.name,
      isActive: status,
    };
    const action = status === true ? 'active' : 'inactive';
    const toastrConfirmOptions = {
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => processStatus(id, status, body),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Are you sure to ${action} this agreement ?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = (row: any) => {
    const rowData = row.row.original;
    const processEnabled = (isChecked: any) =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };
  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'primary',
        id: `btnEditAgreementContent`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'buttonTable',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };
  const columnData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];
  const handleRefresh = () => {
    setSelectedData(undefined);
    refetch();
  };
  return (
    <AgreementContentComponent
      columnData={columnData}
      agreementContentList={agreementContentList}
      handleRefresh={handleRefresh}
      modalIsShow={modalIsShow}
      setModalIsShow={setModalIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      isLoading={fetchStatus !== 'idle' && (isLoading || isRefetching)}
    />
  );
}

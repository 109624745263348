import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import { contentManagementContentQuestionAPI } from '../../../../../Services/ElearningContentManagement/contentQuestion';
import QuestionModalComponent from '../../Component/Modal/QuestionModalComponent';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  handleRefresh: () => void;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  stage: any;
  submodule: any;
}

export default function QuestionModalContainer({
  handleRefresh,
  selectedData,
  setSelectedData,
  setShowModal,
  showModal,
  stage,
  submodule,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const [listQuestion, setListQuestion] = useState<any[]>([]);
  const [selectedQuestion, setSelectedQuestion] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  let initialValues: any;

  if (selectedData) {
    initialValues = { ...selectedData };
    const listAnswer = selectedData.answers;
    const answerOption: any = [];
    const answers: any = [];
    let answer;
    listAnswer.forEach((element, index) => {
      answerOption[index] = element.answer;
      answers[index] = element.correct;
      const indexAnswer = answers.indexOf(true);
      answer = `answer_${indexAnswer + 1}`;
    });
    initialValues.answer = answer;
    initialValues.answerOption = answerOption;
  }

  const {
    data: dataQuestionBank,
    isLoading: isLoadingQuestion,
    isRefetching: isRefetchQuestion,
  } = useQuery(
    [
      'getContentManagementQuestionBankByIdAPI',
      { id: submodule?.id, category: selectedCategory },
    ],
    contentManagementContentQuestionAPI.getQuestionBankById,
    {
      enabled: submodule?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataCategoryBank, isLoading: isLoadingCategoryBank } = useQuery(
    ['getContentManagementCategoryBankAPI'],
    contentManagementContentQuestionAPI.getCategoryBank,
    {
      enabled: submodule?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (dataQuestionBank) setListQuestion(dataQuestionBank);
  }, [dataQuestionBank]);

  const mutateQuestion = useMutation(
    contentManagementContentQuestionAPI.mutateQuestion,
    {
      onSuccess: () => {
        handleCancel();
        handleRefresh();
        toast.success(
          <span className="capitalFirst">
            Question Berhasil {selectedData ? 'Diupdate' : 'Ditambahkan'}!
          </span>
        );
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const mutateQuestionStatus = useMutation(
    contentManagementContentQuestionAPI.mutateQuestionStatus,
    {
      onSuccess: () => {
        handleCancel();
        handleRefresh();
        toast.success(
          <span className="capitalFirst">Question Berhasil Diupdate!</span>
        );
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const handleOnChangeCategory = (e: any) => setSelectedCategory(e);

  const handleOnChangeStatus = (isActive: boolean) => {
    const { id, title } = selectedData;
    const action = isActive === true ? 'mengaktifkan' : 'menon-aktifkan';
    const actionConfirm = isActive === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => mutateQuestionStatus.mutate({ id, status: isActive }),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Question "${title}"?`,
      toastrConfirmOptions
    );
  };

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
    setSelectedQuestion([]);
    setListQuestion([]);
  };

  const handleResetQuestion = () => {
    setListQuestion(dataQuestionBank ? dataQuestionBank : []);
    setSelectedQuestion([]);
  };

  const onSubmit = (vals: any) => {
    if (selectedData) setEditMode(true);
    else
      mutateQuestion.mutate({
        selectedQuestion,
        channelId: submodule.channelId,
        stageId: submodule.stageId,
        moduleId: submodule.moduleId,
        subModuleId: submodule.id,
        type: 'SelfLearning',
        codemiModuleId: stage.codemiUserLevelId,
      });
  };

  return (
    <QuestionModalComponent
      categoryBankList={dataCategoryBank || []}
      editMode={editMode}
      handleCancel={handleCancel}
      handleOnChangeCategory={handleOnChangeCategory}
      handleOnChangeStatus={handleOnChangeStatus}
      handleResetQuestion={handleResetQuestion}
      handleSetListBank={setListQuestion}
      handleSetListSelected={setSelectedQuestion}
      initialValues={initialValues}
      isLoading={
        selectedData
          ? false
          : isLoadingQuestion || isRefetchQuestion || isLoadingCategoryBank
      }
      isShow={showModal}
      listQuestion={listQuestion}
      modalAction={selectedData ? 'update' : 'register'}
      onSubmit={onSubmit}
      selectedQuestion={selectedQuestion}
    />
  );
}

import { Layout, Menu } from 'antd';

import CIcon from '../../../Assets/Components/CIcon';
import HasPermission from '../../../App/HasPermission';
import { Link } from 'react-router-dom';
import React from 'react';
import ZLogo1 from '../../../Assets/Images/zurichWhiteNew.png';
import ZLogo2 from '../../../Assets/Images/zurich.svg';

interface IProps {
  siderIsCollapse: boolean;
  location: any;
}
export default function SiderComponent(props: IProps) {
  const { Sider } = Layout;
  const { SubMenu } = Menu;
  const { siderIsCollapse, location } = props;
  const defaultSelectedKeys = location.pathname;
  const logo = () => {
    if (siderIsCollapse) {
      return <img className="logoTextMin" src={ZLogo2} alt="logo" />;
    } else {
      return <img className="logoText" src={ZLogo1} alt="logo" />;
    }
  };

  const processSiderObject = (component, path, icon, label, id) => {
    return {
      component,
      path,
      icon,
      label,
      id,
    };
  };
  const configMenuSider = [
    processSiderObject(
      'menuItem',
      '/',
      'PieChartOutlined',
      'Dashboard',
      'menuSiderDashboard'
    ),
    processSiderObject(
      'menuItem',
      '/qa/process',
      'ProfileOutlined',
      'QA Process',
      'menuSiderQAProcess'
    ),
    {
      component: 'subMenu',
      keyName: 'subMenuElearning',
      icon: 'ReadOutlined',
      label: 'E-Learning',
      id: 'menuSiderElearning',
      componentMenuItem: [
        processSiderObject(
          'menuItem',
          '/elearning',
          null,
          'Content Management',
          'menuSiderELearning'
        ),
        processSiderObject(
          'menuItem',
          '/elearning/questionbank',
          null,
          'Question Bank',
          'menuSiderQuestionBank'
        ),
        processSiderObject(
          'menuItem',
          '/elearning/categorybank',
          null,
          'Category Bank',
          'menuSiderCategoryBank'
        ),
        processSiderObject(
          'menuItem',
          '/elearning/feedbackbank',
          null,
          'Feedback Bank',
          'menuSiderFeedbackBank'
        ),
        processSiderObject(
          'menuItem',
          '/elearning/learningreport',
          null,
          'Learning Report',
          'menuSiderLearningReport'
        ),
        processSiderObject(
          'menuItem',
          '/elearning/nonselftraining',
          null,
          'Non-Self Training',
          'menuSiderLearningReport'
        ),
      ],
    },
    {
      component: 'subMenu',
      keyName: 'subMenuUploads',
      icon: 'UploadOutlined',
      label: 'Unggah Berkas',
      id: 'menuSiderWorldCheck',
      componentMenuItem: [
        processSiderObject('menuItem', '/city', null, 'City', 'menuSiderCity'),
        processSiderObject(
          'menuItem',
          '/pending/remarks',
          null,
          'Catatan Pending',
          'menuSiderPendingRemarks'
        ),
        processSiderObject(
          'menuItem',
          '/privyid/statement',
          null,
          'PrivyID Statement',
          'menuSiderPrivyStatement'
        ),
        processSiderObject(
          'menuItem',
          '/world/check',
          null,
          'World Check',
          'menuSiderWorldCheck'
        ),
        processSiderObject(
          'menuItem',
          '/upload/schedule',
          null,
          'Schedule',
          'menuSiderUploadSchedule'
        ),
      ],
    },
    processSiderObject(
      'menuItem',
      '/license',
      'CarryOutOutlined',
      'License',
      'menuSiderLicense'
    ),
    processSiderObject(
      'menuItem',
      '/exam/schedule',
      'CalendarOutlined',
      'Schedule',
      'menuSiderSchedule'
    ),
    processSiderObject(
      'menuItem',
      '/agent/monitoring',
      'LaptopOutlined',
      'New Agent Monitoring',
      'menuSiderNewAgentMonitoring'
    ),
    {
      component: 'subMenu',
      keyName: 'subMenuContest',
      icon: 'FlagOutlined',
      label: 'Contest',
      id: 'menuSiderContest',
      componentMenuItem: [
        processSiderObject(
          'menuItem',
          '/contest/reward',
          null,
          'Reward',
          'submenuSiderContestReward'
        ),
        processSiderObject(
          'menuItem',
          '/contest/target',
          null,
          'Target',
          'submenuSiderContestTarget'
        ),
        processSiderObject(
          'menuItem',
          '/contest/upload',
          null,
          'Monthly Upload',
          'submenuSiderContestMonthlyUpload'
        ),
      ],
    },
    {
      component: 'subMenu',
      keyName: 'subMenuDisclaimer',
      icon: 'InfoCircleOutlined',
      label: 'Disclaimer',
      id: 'menuSiderDisclaimer',
      componentMenuItem: [
        processSiderObject(
          'menuItem',
          '/disclaimer/content',
          null,
          'Disclaimer Content',
          'submenuSiderDisclaimerContent'
        ),
        processSiderObject(
          'menuItem',
          '/disclaimer/mapping',
          null,
          'Disclaimer Mapping',
          'submenuSiderDisclaimerMapping'
        ),
      ],
    },
    {
      component: 'subMenu',
      keyName: 'subMenuAgreement',
      icon: 'EditOutlined',
      label: 'Agreement',
      id: 'menuSiderAgreementMenu',
      componentMenuItem: [
        processSiderObject(
          'menuItem',
          '/agreement/content',
          null,
          'Agreement Content',
          'submenuSiderAgreementContent'
        ),
        processSiderObject(
          'menuItem',
          '/agreement/mapping',
          null,
          'Agreement Mapping',
          'submenuSiderAgreementMapping'
        ),
      ],
    },
    processSiderObject(
      'menuItem',
      '/tracker',
      'AreaChartOutlined',
      'Tracker',
      'menuSiderTracker'
    ),
    processSiderObject(
      'menuItem',
      '/config',
      'SettingOutlined',
      'Config',
      'menuSiderConfig'
    ),
    {
      component: 'subMenu',
      keyName: 'subMenuRolePermission',
      icon: 'UserOutlined',
      label: 'Roles & Permission',
      id: 'menuSiderRolesPermission',
      componentMenuItem: [
        processSiderObject(
          'menuItem',
          '/role/permission',
          null,
          'Permission',
          'submenuSiderRolePermission'
        ),
        processSiderObject(
          'menuItem',
          '/role/user',
          null,
          'User Roles',
          'submenuSiderRoleUser'
        ),
      ],
    },
    processSiderObject(
      'menuItem',
      '/report',
      'FileTextOutlined',
      'Report',
      'menuSiderReport'
    ),
    processSiderObject(
      'menuItem',
      '/library',
      'FolderOutlined',
      'Library',
      'menuSiderLibrary'
    ),
    processSiderObject(
      'menuItem',
      '/invitation',
      'SendOutlined',
      'Invitation',
      'menuSiderInvitation'
    ),
    processSiderObject(
      'menuItem',
      '/notification',
      'BellOutlined',
      'Notification',
      'menuSiderNotification'
    ),
  ];

  const renderSubMenu = x => {
    const renderSub = renderMenuSider(x.componentMenuItem);
    let isNull = true;
    for (const iterator of renderSub) {
      if (iterator !== null) {
        isNull = false;
        break;
      }
    }
    if (isNull === false) {
      return (
        <SubMenu
          id={x.id}
          key={x.keyName}
          title={
            <span>
              <CIcon type={x.icon} />
              <span>{x.label}</span>
            </span>
          }
        >
          {renderSub}
        </SubMenu>
      );
    }
    return null;
  };

  function renderMenuSider(menuData) {
    return menuData.map(x => {
      if (
        (x.component === 'menuItem' && HasPermission('r', x.path)) ||
        (x.path === '/' &&
          (HasPermission('r', '/dashboard/dosd') ||
            HasPermission('r', '/dashboard/elearning') ||
            HasPermission('r', '/dashboard/trainer')))
      ) {
        return (
          <Menu.Item key={x.path} id={x.id}>
            <Link
              to={`${process.env.REACT_APP_PUBLIC_URL}${x.path}`}
              onClick={() => {}}
            >
              {x.icon ? <CIcon type={x.icon} /> : null}
              <span>{x.label}</span>
            </Link>
          </Menu.Item>
        );
      }
      if (x.component === 'subMenu') {
        return renderSubMenu(x);
      }
      return null;
    });
  }

  return (
    <Sider
      className="siderTemplate"
      collapsed={siderIsCollapse}
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
      }}
    >
      <div className="logo">{logo()}</div>
      <Menu
        theme="dark"
        defaultSelectedKeys={[defaultSelectedKeys]}
        mode="inline"
        className="menuSider"
      >
        {renderMenuSider(configMenuSider)}
      </Menu>
    </Sider>
  );
}

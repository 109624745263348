import React, { useRef, Dispatch, SetStateAction } from 'react';
import { Skeleton } from 'antd';
import { injectIntl } from 'react-intl';
import { FormApi } from 'final-form';
import DynamicForm from '../../../../Assets/Components/DynamicForm/DynamicForm';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import validate from '../../Validation/RegistrationValidation';
import ModalEditBeneficiaryContainer from '../../Container/Modal/ModalEditBeneficiaryContainer';
import ModalEditWorkExperienceContainer from '../../Container/Modal/ModalEditWorkExperienceContainer';
import ModalValidationContainer from '../../Container/Modal/ModalValidationContainer';

interface IProps {
  modalCandidateIsShow: boolean;
  handleCancel: () => void;
  selectedData: any;
  schemaData: any;
  detailCandidate: any;
  currentStep: number;
  handleStep: (step: any) => void;
  handleNextForm: (ref: any, formValue: any) => void;
  handleBackForm: (ref: any) => void;
  handleEditForm: () => void;
  handleSave: (formValue: any) => void;
  documentList: any;
  isEditedCandidate: boolean;
  handleSelectHaveNPWP: (e: any, form: FormApi<any, Partial<any>>) => void;
  totalOtherattachments: number;
  onClickOtherDocument: () => void;
  column: any;
  bank: any;
  recruiter: any;
  salesOffice: any;
  agency: any;
  setAgentCode: any;
  setAgentLevel: any;
  setCandidateLevel: any;
  religion: any;
  listbeneficiary: any;
  handleAddBeneficiary: (values: any, form: FormApi<any, Partial<any>>) => {};
  handleResetBeneficiary: (form: FormApi<any, Partial<any>>) => void;
  handleAddWorkexperience: (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => {};
  handleResetWorkexperience: (form: FormApi<any, Partial<any>>) => void;
  modalEditBeneficiaryIsShow: boolean;
  setModalEditBeneficiaryIsShow: Dispatch<SetStateAction<boolean>>;
  modalEditWorkExperienceIsShow: boolean;
  setModalEditWorkExperienceIsShow: Dispatch<SetStateAction<boolean>>;
  selectedBeneficiary: any;
  setSelectedBeneficiary: Dispatch<SetStateAction<undefined>>;
  setNewListBeneficiary: any;
  selectedWorkexperience: any;
  setSelectedWorkexperience: Dispatch<SetStateAction<undefined>>;
  listworkexperience: any;
  setNewListWorkexperience: Dispatch<SetStateAction<never[]>>;
  formName: string;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  documentMeta: any;
  documentsContent: any;
  initialValues: any;
  modalValidationIsShow: boolean;
  setModalValidationIsShow: Dispatch<SetStateAction<boolean>>;
  resultValidation: any;
  isLoading: any;
  setIdRegion: Dispatch<SetStateAction<number>>;
  setIdCity: Dispatch<SetStateAction<number>>;
  setIdDistrict: Dispatch<SetStateAction<number>>;
  setProvincedomicile: Dispatch<SetStateAction<number>>;
  setCitydomicile: Dispatch<SetStateAction<number>>;
  setIdDistrictdomicile: Dispatch<SetStateAction<number>>;
  setHandlersName: Dispatch<SetStateAction<string>>;
  handleOnChange: (values: any, form: any) => void;
  additionalOnChange: (domicileaddress: any, formValue: any, form: any) => void;
  setNewVillageDomicile: Dispatch<SetStateAction<never[]>>;
  setNewDistrictDomicile: Dispatch<SetStateAction<never[]>>;
  setNewDistrict: Dispatch<SetStateAction<never[]>>;
  setNewVillage: Dispatch<SetStateAction<never[]>>;
  isUploading: boolean;
}

function ModalCandidateComponent(props: IProps) {
  const {
    modalCandidateIsShow,
    handleCancel,
    selectedData,
    schemaData,
    handleStep,
    currentStep,
    handleNextForm,
    handleBackForm,
    handleEditForm,
    handleSave,
    detailCandidate,
    documentList,
    isEditedCandidate,
    handleSelectHaveNPWP,
    totalOtherattachments,
    onClickOtherDocument,
    recruiter,
    setAgentCode,
    setAgentLevel,
    setCandidateLevel,
    handleAddBeneficiary,
    handleResetBeneficiary,
    handleAddWorkexperience,
    handleResetWorkexperience,
    modalEditBeneficiaryIsShow,
    setModalEditBeneficiaryIsShow,
    modalEditWorkExperienceIsShow,
    setModalEditWorkExperienceIsShow,
    selectedBeneficiary,
    setSelectedBeneficiary,
    listbeneficiary,
    setNewListBeneficiary,
    selectedWorkexperience,
    setSelectedWorkexperience,
    listworkexperience,
    setNewListWorkexperience,
    handleUploadContent,
    initialValues,
    modalValidationIsShow,
    setModalValidationIsShow,
    resultValidation,
    isLoading,
    setIdRegion,
    setIdCity,
    setIdDistrict,
    setProvincedomicile,
    setCitydomicile,
    setIdDistrictdomicile,
    setHandlersName,
    handleOnChange,
    additionalOnChange,
    setNewVillageDomicile,
    setNewDistrictDomicile,
    setNewDistrict,
    setNewVillage,
    isUploading,
  } = props;
  const contentModalref = useRef(null);

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {selectedData ? 'Edit Kandidat' : 'Add Kandidat Baru'}
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    const formError = formProps.errors;
    const formValue = formProps.values;
    const form = formProps.form;
    const modalAction = selectedData ? 'update' : 'register';
    const handlers: any = {};
    if (
      currentStep === 0 &&
      isEditedCandidate &&
      !formValue?.personaldata?.taxbankaccount?.hastax
    ) {
      form.change(`personaldata.taxbankaccount.hastax`, true);
    }
    const resetRegion = (regionName, formName) => {
      switch (regionName) {
        case 'province':
          form.change(`personaldata.addresseducation.city${formName}`, null);
          form.change(
            `personaldata.addresseducation.district${formName}`,
            null
          );
          form.change(
            `personaldata.addresseducation.subdistrict${formName}`,
            null
          );
          form.change(
            `personaldata.addresseducation.postalcode${formName}`,
            null
          );
          break;
        case 'city':
          form.change(
            `personaldata.addresseducation.district${formName}`,
            null
          );
          form.change(
            `personaldata.addresseducation.subdistrict${formName}`,
            null
          );
          form.change(
            `personaldata.addresseducation.postalcode${formName}`,
            null
          );
          break;
        case 'district':
          form.change(
            `personaldata.addresseducation.subdistrict${formName}`,
            null
          );
          form.change(
            `personaldata.addresseducation.postalcode${formName}`,
            null
          );

          break;
      }
    };

    handlers.province = (e: any) => {
      if (e) {
        const idRegion = Number(e?.split('|')[1]);
        setIdRegion(idRegion);
        setHandlersName('city');
      }
      setNewDistrict([]);
      setNewVillage([]);
      resetRegion('province', '');
    };

    handlers.city = (e: any) => {
      if (e) {
        const idCity = Number(e?.split('|')[1]);
        setIdCity(idCity);
        setHandlersName('district');
      }
      setNewVillage([]);
      resetRegion('city', '');
    };

    handlers.district = (e: any) => {
      if (e) {
        const idDistrict = Number(e.split('|')[1]);
        setIdDistrict(idDistrict);
        setHandlersName('subdistrict');
      }
      resetRegion('district', '');
    };

    handlers.subdistrict = (e: any) => {
      const postalCode = String(e.split('|')[2]);
      form.change(`personaldata.addresseducation.postalcode`, postalCode);
    };

    handlers.provincedomicile = e => {
      if (e) {
        const idProvinceDomicile = Number(e.split('|')[1]);
        setProvincedomicile(idProvinceDomicile);
        setHandlersName('citydomicile');
      }
      setNewDistrictDomicile([]);
      setNewVillageDomicile([]);
      resetRegion('province', 'domicile');
    };

    handlers.citydomicile = e => {
      if (e) {
        const idCityDomicile = Number(e.split('|')[1]);
        setCitydomicile(idCityDomicile);
        setHandlersName('districtdomicile');
      }
      setNewVillageDomicile([]);
      resetRegion('city', 'domicile');
    };

    handlers.districtdomicile = (e: any) => {
      if (e) {
        const idDistrictDomicile = Number(e.split('|')[1]);
        setIdDistrictdomicile(idDistrictDomicile);
        setHandlersName('subdistrictdomicile');
      }
      resetRegion('district', 'domicile');
    };

    handlers.subdistrictdomicile = (e: any) => {
      const postalCode = String(e.split('|')[2]);
      form.change(
        `personaldata.addresseducation.postalcodedomicile`,
        postalCode
      );
    };

    const convertLevel = (descLevel: any) => {
      if (descLevel === 'FC') {
        return 'A1';
      } else if (descLevel === 'AM') {
        return 'A2';
      } else if (descLevel === 'SAM') {
        return 'A3';
      } else {
        return 'A4';
      }
    };

    handlers.candidateLevel = (e: any) => {
      const candidateLevel = convertLevel(e);
      const recruiterName =
        formValue &&
        formValue.personaldata &&
        formValue.personaldata.agency &&
        formValue.personaldata.agency.recruiter
          ? formValue.personaldata.agency.recruiter.split('|')[0]
          : null;
      if (recruiterName) {
        let code = null;
        let level = null;
        for (const iterator of recruiter) {
          if (recruiterName === iterator.agentName) {
            code = iterator.agentCode;
            level = iterator.agentLevel;
          }
        }
        setAgentCode(code);
        setAgentLevel(level);
        setCandidateLevel(candidateLevel);
        form.change(`personaldata.agency.directLeader`, null);
      }
    };
    handlers.recruiter = (e: any) => {
      let agentCode: any;
      let agentLevel: any;
      let agencyName: any;
      let agencyCode: any;
      for (const iterator of recruiter) {
        if (e === iterator.id) {
          agentCode = iterator.agentCode;
          agentLevel = iterator.agentLevel;
          agencyName = iterator.agencyName;
          agencyCode = iterator.agencyCode;
        }
      }
      const candidateLevel =
        formValue &&
        formValue.personaldata &&
        formValue.personaldata.agency &&
        formValue.personaldata.agency.candidateLevel
          ? formValue.personaldata.agency.candidateLevel
          : null;
      const candidateLevelCode = convertLevel(candidateLevel);
      setAgentCode(agentCode);
      setAgentLevel(agentLevel);
      setCandidateLevel(candidateLevelCode);
      form.change(`personaldata.agency.directLeader`, null);
      let agencyLevel: any;
      if (agentLevel === 'A1') {
        agencyLevel = 'FC';
      } else if (agentLevel === 'A2') {
        agencyLevel = 'AM';
      } else if (agentLevel === 'A3') {
        agencyLevel = 'SAM';
      } else {
        agencyLevel = 'AD';
      }
      form.change(`personaldata.agency.agencyLevel`, `${agencyLevel}`);
      form.change(`personaldata.agency.agency`, `${agencyName}|${agencyCode}`);
    };

    const disabled = () => {
      if (!isEditedCandidate) {
        return !isEditedCandidate;
      } else {
        if (Object.keys(formError).length > 0 && formError.workexperience) {
          return true;
        } else {
          return false;
        }
      }
    };

    const disabledCandidateLevel =
      selectedData?.origin === 'MZA' ? true : false;
    const disabledRecruiterDL = selectedData?.origin === 'MZA' ? true : false;
    const disabledAgency = () => {
      if (formValue && formValue.personaldata) {
        if (selectedData?.origin === 'MZA') {
          return true;
        } else if (formValue.personaldata?.agency?.candidateLevel === 'AD') {
          return false;
        } else {
          return true;
        }
      } else if (selectedData?.origin === 'MZA') {
        return true;
      } else if (selectedData?.candidateLevel === 'AD') {
        return false;
      } else {
        return true;
      }
    };

    const dataButton = {
      beneficiary: [
        {
          type: 'primary',
          content: 'Tambah Ahli Waris',
          id: 'btnAddBeneficary',
          className: 'btnAddRegisForm',
          icon: 'PlusOutlined',
          onClick: () => handleAddBeneficiary(formValue, form),
          disabled: !isEditedCandidate
            ? !isEditedCandidate
            : Object.keys(formError).length > 0 &&
              formError.beneficiary &&
              formError.beneficiary.form &&
              Object.keys(formError.beneficiary.form).length > 0
            ? true
            : false,
        },
        {
          type: 'primary',
          content: 'Reset',
          id: 'btnResetBeneficary',
          className: 'btnResetRegisForm',
          onClick: () => handleResetBeneficiary(form),
          disabled: !isEditedCandidate ? !isEditedCandidate : false,
        },
      ],
      workexperience: [
        {
          type: 'primary',
          content: 'Tambah Pengalaman Kerja',
          id: 'btnAddBeneficary',
          className: 'btnAddRegisForm',
          icon: 'PlusOutlined',
          onClick: () => handleAddWorkexperience(formValue, form),
          disabled: disabled(),
        },
        {
          type: 'primary',
          content: 'Reset',
          id: 'btnResetBeneficary',
          className: 'btnResetRegisForm',
          onClick: () => handleResetWorkexperience(form),
          disabled: !isEditedCandidate || false,
        },
      ],
    };

    const validationSaveButton = (steps: any) => {
      if (steps === 0 && formError) {
        return formError &&
          formError.personaldata &&
          Object.keys(formError.personaldata).length > 0
          ? true
          : false;
      } else if (steps === 2) {
        return formError &&
          formError.workexperience &&
          formError.workexperience.questionnaire &&
          Object.keys(formError.workexperience.questionnaire).length > 0
          ? true
          : false;
      } else if (steps === 3) {
        const personaldata = formValue.personaldata;
        return (formValue &&
          formValue.documents &&
          !formValue.documents.photo) ||
          (formValue && !formValue.documents) ||
          (formValue &&
            personaldata &&
            personaldata.taxbankaccount &&
            personaldata.taxbankaccount.bank &&
            personaldata.taxbankaccount.bank.name &&
            formValue.documents &&
            !formValue.documents.bankpassbook) ||
          isUploading
          ? true
          : false;
      }
      return false;
    };

    const globalValidation =
      (formError &&
        formError.personaldata &&
        Object.keys(formError.personaldata).length > 0) ||
      validationSaveButton(2) ||
      validationSaveButton(3) ||
      isLoading
        ? true
        : false;

    const newProps = {
      ...props,
      ...formProps,
    };

    return (
      <>
        <div ref={contentModalref}>
          <Skeleton
            active
            loading={isLoading || false}
            paragraph={{ rows: 20 }}
          >
            {schemaData ? (
              <DynamicForm
                schema={schemaData.schema}
                footerStep
                formModulName="editQa"
                disabledCandidateLevel={disabledCandidateLevel}
                disabledRecruiterDL={disabledRecruiterDL}
                disabledAgency={disabledAgency()}
                dataButton={dataButton}
                propsDynamic={newProps}
                handleStep={handleStep}
                currentSteps={currentStep}
                handleNext={() => handleNextForm(contentModalref, formValue)}
                handleBack={() => handleBackForm(contentModalref)}
                handleEdit={handleEditForm}
                handleSave={handleSave}
                documentList={documentList}
                documents={detailCandidate?.documentContentData || []}
                formAction="update"
                isEdited={isEditedCandidate}
                handleSelectHaveNPWP={(e: any) => handleSelectHaveNPWP(e, form)}
                totalOtherattachment={totalOtherattachments}
                onClickOtherDocument={onClickOtherDocument}
                handlers={handlers}
                modalAction={modalAction}
                disableSaveButton={validationSaveButton}
                globalValidation={globalValidation}
                handleUploadContent={handleUploadContent}
                handleOnChange={handleOnChange}
                additionalOnChange={additionalOnChange}
              />
            ) : null}
          </Skeleton>
        </div>
      </>
    );
  };

  return (
    <>
      <CModalRFF
        modalIsShow={modalCandidateIsShow}
        headerComponent={headerComponent()}
        contentComponent={(formProps: any) => contentComponent(formProps)}
        footerComponent={null}
        handleCancel={handleCancel}
        handleValidation={(values: any) =>
          validate(values, resultValidation, selectedData)
        }
        isForm={true}
        handleSubmit={() => {}}
        formLayout="vertical"
        initialValues={initialValues}
        modalstyles={{
          content: {
            position: 'relative',
            background: 'none',
            maxWidth: '95vw',
            width: '85vw',
            padding: '0px',
            border: 'none',
            marginLeft: 'auto',
            marginRight: 'auto',
          },
          overlay: {
            background: 'rgba(0, 0, 0, 0.75)',
            zIndex: '99',
          },
        }}
      />
      <ModalEditBeneficiaryContainer
        modalEditBeneficiaryIsShow={modalEditBeneficiaryIsShow}
        setModalEditBeneficiaryIsShow={setModalEditBeneficiaryIsShow}
        schemaData={schemaData}
        selectedBeneficiary={selectedBeneficiary}
        setSelectedBeneficiary={setSelectedBeneficiary}
        listbeneficiary={listbeneficiary}
        setNewListBeneficiary={setNewListBeneficiary}
      />
      <ModalEditWorkExperienceContainer
        modalEditWorkExperienceIsShow={modalEditWorkExperienceIsShow}
        setModalEditWorkExperienceIsShow={setModalEditWorkExperienceIsShow}
        schema={schemaData}
        selectedWorkexperience={selectedWorkexperience}
        setSelectedWorkexperience={setSelectedWorkexperience}
        listworkexperience={listworkexperience}
        setNewListWorkexperience={setNewListWorkexperience}
      />
      <ModalValidationContainer
        modalValidationIsShow={modalValidationIsShow}
        setModalValidationIsShow={setModalValidationIsShow}
        resultValidation={resultValidation}
      />
    </>
  );
}
export default injectIntl(ModalCandidateComponent);

import { Alert, Button, Col, Empty, Row, Skeleton } from 'antd';
import React from 'react';
import Table from '../../../Assets/Components/CTable';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import { Field, Form } from 'react-final-form';
import Select from '../../../Assets/Components/Select';
import {
  formatReport,
  notificationFilterType,
  reportType,
} from '../../../App/Enums';
import { InputDate } from '../../../Assets/Components/InputDate';
import CIcon from '../../../Assets/Components/CIcon';
import moment from 'moment';
import validation from '../Validation/ReportValidation';
import { FormApi } from 'final-form';

interface IProps {
  columnData: any;
  reportList: any;
  handleSubmit: (values: any) => void;
  isLoading: boolean;
  isNotif: boolean;
  notifType: any;
  setNotifType: any;
  selectedType?: string;
  handleSelectType: (form: FormApi<any, Partial<any>>) => void;
  renderRowSubComponent: any;
  selectedFormat?: any;
  setSelectedFormat: React.Dispatch<React.SetStateAction<any>>;
  handleDownloadReport: () => void;
  isDownloading: boolean;
  handleSelectNotif: (e: any) => void;
  selectedNotif: any;
  setSelectedNotif: React.Dispatch<React.SetStateAction<any>>;
}
export default function ReportComponent(props: IProps) {
  const {
    columnData,
    reportList,
    handleSubmit,
    isLoading,
    isNotif,
    handleSelectType,
    renderRowSubComponent,
    setSelectedFormat,
    selectedFormat,
    handleDownloadReport,
    isDownloading,
    selectedNotif,
    setSelectedNotif,
    handleSelectNotif,
  } = props;

  const schedulerMsg = () => {
    /* eslint-disable */
    return (
      <ul>
        <li>
          Jam 9 pagi adalah data DWH H-1 (dari jam 12 siang) sampai hari H (jam
          3 pagi)
        </li>
        <li>
          Jam 3 sore adalah data DWH hari H dari jam 3 pagi sampai jam 12 siang
        </li>
      </ul>
    );
  };
  const tabContent = () => {
    return (
      <React.Fragment>
        <div className="containerWhiteZurich">
          <Form onSubmit={handleSubmit} validate={validation}>
            {(props: any) => {
              const { values, invalid, form } = props;
              const setDisabledDate = (d: any) =>
                values?.reportType === 'notificationReport' ||
                values?.reportType === 'applicationNumberReport'
                  ? !d || d.isAfter(moment().add(1, 'day').format('YYYY-MM-DD'))
                  : !d || d.isAfter(moment().format('YYYY-MM-DD'));
              return (
                <form onSubmit={props.handleSubmit || props.form.submit}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col span={8}>
                      <label className="labelBtnFilter">Report Type</label>
                      <Field
                        name="reportType"
                        component={Select}
                        idComponent="inputReportType"
                        dataOption={reportType}
                        defaultItemName="Select report type"
                        onSelect={() => handleSelectType(form)}
                        className="btnFeedbackBank"
                      />
                    </Col>
                    <Col span={12}>
                      <label className="labelBtnFilter">Date Filter</label>
                      <Field
                        name="dateFilter"
                        component={InputDate}
                        idComponent="inputDateFilter"
                        typeDate="dateRangePicker"
                        disabledDate={setDisabledDate}
                        className="btnFeedbackBank"
                      />
                    </Col>
                    <Col span={4}>
                      <Button
                        type="primary"
                        icon={<CIcon type="SearchOutlined" />}
                        id="btnSearch"
                        style={{ marginTop: '30px' }}
                        htmlType="submit"
                        disabled={invalid}
                        loading={isLoading}
                      >
                        Search
                      </Button>
                    </Col>
                  </Row>
                  {isNotif ? (
                    <div>
                      <Alert
                        message={<b>Scheduler Notif MZA setiap hari :</b>}
                        description={schedulerMsg()}
                        type="warning"
                      />
                      <label className="labelBtnFilter">
                        Notification Type
                      </label>
                      <Select
                        dataOption={notificationFilterType}
                        defaultItemName="All"
                        idComponent="inputNotifType"
                        meta={{ touched: false, error: false }}
                        input={{
                          onBlur: null,
                          onChange: setSelectedNotif,
                          value: selectedNotif ? selectedNotif : 'All',
                        }}
                        className="selectReportType"
                        onSelect={(e: any) => handleSelectNotif(e)}
                      />
                    </div>
                  ) : (
                    ''
                  )}
                </form>
              );
            }}
          </Form>
          {reportList && reportList?.length > 0 ? (
            <Skeleton active loading={isLoading}>
              <Table
                isLoading={isLoading}
                columns={columnData()}
                data={reportList || []}
                pagination={true}
                useFilterGlobal
                renderRowSubComponent={renderRowSubComponent
                }
              />
              <Row gutter={24} className="rowDownloadReport">
                <Col span={6}>
                  <label className="labelBtnFilter">
                    Download Report Format
                  </label>
                  <Select
                    dataOption={formatReport}
                    idComponent="inputReportFormat"
                    meta={{ touched: false, error: false }}
                    input={{
                      onBlur: null,
                      onChange: setSelectedFormat,
                      value: selectedFormat
                    }}
                    className="btnFeedbackBank"
                  />
                </Col>
                <Col span={24}>
                  <Button
                    onClick={handleDownloadReport}
                    icon={<CIcon type="DownloadOutlined" />}
                    type="primary"
                    disabled={!selectedFormat || selectedFormat === 'pdf'}
                    loading={isDownloading}
                    id="btnDownloadTRackerReport"
                    className="buttonTable"
                  >
                    Download as file
                  </Button>
                </Col>
              </Row>
            </Skeleton>
          ) : (
            <Empty style={{ margin: 'auto' }} />
          )}
        </div>
      </React.Fragment>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="Report" rightText="Data" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Report List</span>}
        cardContent={tabContent()}
      />
    </React.Fragment>
  );
}

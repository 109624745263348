import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CIcon from '../../../Assets/Components/CIcon';
import CModal from '../../../Assets/Components/CModal';

interface IProps {
  modalIsShow: boolean;
  handleCancel: () => void;
}

export default function ModalDownloadNotificationComponent(props: IProps) {
  const { modalIsShow, handleCancel } = props;
  const defaultModalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      maxWidth: '95vw',
      width: '450px',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '100px',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '99',
    },
  };
  const buttonData = [
    {
      className: 'btnCloseReport',
      onClick: handleCancel,
      content: 'Tutup',
      id: 'btnCloseDownloadInfoModal',
    },
  ];

  const contentComponent = () => {
    return (
      <div className="contentModalDownload">
        <div className="iconDownload">
          <CIcon type="DownloadOutlined" />
        </div>
        <p className="infoDownloadingReport">Sedang Mendownload..</p>
        <p>
          Anda dapat mengakses menu lain selama proses <br /> download report
          sedang berlangsung
        </p>
        <div>
          <CButton buttonData={buttonData} />
        </div>
      </div>
    );
  };

  return (
    <CModal
      headerComponent={null}
      contentComponent={contentComponent()}
      footerComponent={null}
      modalIsShow={modalIsShow}
      handleCancel={handleCancel}
      modalstyles={defaultModalstyles}
    />
  );
}

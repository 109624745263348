import instance from '../interceptor';

const getFeedback = async params => {
  const { id, type } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ContentFeedback?subModuleId=${id}&type=${type}`
  );
  return data;
};

const getFeedbackDetail = async params => {
  const { id } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ContentFeedback/detail?feedbackBankId=${id}`
  );
  return data;
};

const getFeedbackBankById = async params => {
  const { id, type } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/FeedbackBankBySubModuleId?type=${type}&subModuleId=${id}`
  );
  const newData: any[] = [];
  if (data && data.length > 0)
    data.forEach((item: any) => {
      newData.push({ ...item, value: item.id, label: item.category });
    });

  return newData;
};

const deleteFeedback = async (id: string) => {
  return await instance.delete(`/Elearning/ContentFeedback/${id}`);
};

const mutateFeedbackStatus = async params => {
  const { id, status } = params;
  const { data } = await instance.put(
    `/Elearning/ContentFeedback/status/${id}/${
      status !== undefined ? status : false
    }`
  );
  return data;
};

const mutateFeedback = async params => {
  const {
    channelId,
    stageId,
    moduleId,
    subModuleId,
    codemiModuleId,
    feedbackId,
    category,
    feedbacks,
    feedbackBankId,
    isActive,
    multipleChoiceQuestions,
    answers,
    essayQuestions,
    type,
  } = params;

  if (feedbackId) {
    const payload: any = {
      channelId,
      moduleId,
      category,
      feedbackBankId,
      isActive: isActive !== undefined ? isActive : false,
      multipleChoiceQuestions,
      answers,
      essayQuestions,
    };
    console.log('put', feedbackId, payload);
    return;
  } else {
    const newFeedbacks: any[] = [];
    for (const feedback of feedbacks) {
      const tempObj: any = feedback;
      tempObj.feedbackBankId = feedback.id;
      delete tempObj.id;
      delete tempObj.value;
      delete tempObj.label;
      newFeedbacks.push(tempObj);
    }
    const payload: any = {
      channelId,
      stageId,
      moduleId,
      subModuleId,
      codemiModuleId,
      type,
      feedbacks: newFeedbacks,
    };
    return await instance.post(`/Elearning/ContentFeedback`, payload);
  }
};

const mutateFeedbackBulk = async params => {
  const { id, feedbackBankId, isActive } = params;
  const payload: any = [
    {
      id,
      feedbackBankId,
      isActive: isActive !== undefined ? isActive : false,
    },
  ];

  return await instance.post(`/Elearning/ContentFeedback/bulk`, payload);
};

export const contentManagementContentFeedbackAPI = {
  getFeedback,
  getFeedbackDetail,
  getFeedbackBankById,
  deleteFeedback,
  mutateFeedback,
  mutateFeedbackStatus,
  mutateFeedbackBulk,
};

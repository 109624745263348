import { deleteUTCToGlobal, toBase64 } from '../App/Helper';

import { createContentAPI } from './content.api';
import instance from './interceptor';
import moment from 'moment';

export const getELearningCertificate = async () => {
  const { data } = await instance.get(
    `/Elearning/certificate/template?learningType=NonSelfLearning`
  );
  return data;
};

export const getChannelBankAPI = async () => {
  const { data } = await instance.get('/Elearning/ChannelBank');
  return data;
};

export const getTrainerDetailAPI = async params => {
  const id = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/user/trainer/detail?id=${id}`
  );
  return data;
};

export const getTrainerListAPI = async () => {
  const { data } = await instance.get(
    `/Elearning/user/trainer?UserType=trainer`
  );
  return data;
};

export const mutateClassNonSelf = async values => {
  const {
    channel,
    codemiCetificateId,
    cpdPoint,
    cpdRefreshName,
    description,
    embed,
    file,
    fromLengthType,
    haveCertificate,
    id,
    isCpdStatus,
    isCreated,
    levelParticipantType,
    listSchedule,
    name,
    participantLevel,
    participantType,
    selectedAgentManual,
    stage,
    status,
    toLengthType,
    totalAgentLOS,
    trainingType,
    type,
  } = values;

  const manualParticipants: any = [];
  const schedules: any = [];
  const embededLink: any = [];
  let classId = id;

  if (participantType === 'MANUAL' && selectedAgentManual) {
    for (const iterator of selectedAgentManual) {
      if (id && !isCreated) {
        // Jika update data hanya kirim agent yg baru ditambah
        if (!iterator.oldData)
          manualParticipants.push({
            agentCode: iterator.agentCode,
            name: iterator.agentName, // dari api agent -> agentName
            channelCode: iterator.SOB, // channel code
          });
      } else
        manualParticipants.push({
          agentCode: iterator.agentCode,
          name: iterator.agentName,
          channelCode: iterator.SOB,
        });
    }
  }

  if (
    participantType === 'LEVEL' &&
    participantLevel &&
    participantLevel.length > 0
  ) {
    for (const iterator of participantLevel) {
      manualParticipants.push({
        name: iterator.level,
        agentCode: iterator.total,
      });
    }
  }

  if (participantType === 'LOS' && totalAgentLOS) {
    manualParticipants.push({
      agentCode: totalAgentLOS,
    });
  }

  if (embed) {
    for (const iterator of embed) {
      embededLink.push(iterator.link);
    }
  }

  if (listSchedule) {
    for (const iterator of listSchedule) {
      const {
        date,
        endTime,
        meetingLink,
        platform,
        type,
        startTime,
        province,
        city,
        locationLink,
        locationDetails,
        venue,
      } = iterator;
      schedules.push({
        city,
        // endDate: deleteUTCToGlobal(date[1].toISOString()),
        endDate: moment(date[1]).format('YYYY-MM-DD HH:mm:ss'),
        endTime: deleteUTCToGlobal(
          moment(
            `${date[0].format('YYYY-MM-DD')} ${endTime.format('HH:mm')}`
          ).toISOString()
        ),
        locationDetail: locationDetails,
        locationLink,
        meetingLink,
        platform,
        province,
        // startDate: deleteUTCToGlobal(date[0].toISOString()),
        startDate: moment(date[0]).format('YYYY-MM-DD HH:mm:ss'),
        startTime: deleteUTCToGlobal(
          moment(
            `${date[0].format('YYYY-MM-DD')} ${startTime.format('HH:mm')}`
          ).toISOString()
        ),
        type,
        venue,
      });
    }
  }

  const payloadCreate = {
    agentLevels: levelParticipantType,
    channelId: channel.id,
    codemiCetificateId: haveCertificate === true ? codemiCetificateId : null,
    cpdPoint: isCpdStatus ? cpdPoint : null,
    cpdRefreshName: isCpdStatus ? cpdRefreshName : null,
    description,
    embededLink,
    endLos: toLengthType,
    isActive: status || false,
    isCertificate: haveCertificate || false,
    isCpdStatus,
    manualParticipants,
    name,
    participantType: !participantType ? 'NONE' : participantType,
    schedules,
    stageNonselfId: stage.id,
    startLos: fromLengthType,
    trainerName: [],
    trainingType,
    type,
  };

  const payloadUpdate = {
    classId,
    codemiCetificateId: haveCertificate === true ? codemiCetificateId : null,
    cpdPoint: isCpdStatus ? cpdPoint : null,
    cpdRefreshName: isCpdStatus ? cpdRefreshName : null,
    description,
    embededLink,
    isActive: status || false,
    isCertificate: haveCertificate || false,
    isCpdStatus,
    name,
    trainingType,
    type,
  };

  if (id && !isCreated) {
    await instance.put(`/Elearning/ClassNonSelf`, payloadUpdate);
    if (participantType === 'MANUAL' && manualParticipants.length > 0) {
      await mutateParticipantNonSelf({ classId, manualParticipants });
    }
  } else {
    if (participantType !== 'LEVEL') {
      payloadCreate.agentLevels = [];
    }
    if (participantType !== 'LOS') {
      payloadCreate.startLos = 0;
      payloadCreate.endLos = 0;
    }
    const { data: classNonSelf } = await instance.post(
      `/Elearning/ClassNonSelf`,
      payloadCreate
    );
    classId = classNonSelf;
  }

  if (file) {
    if (file instanceof Array) {
      for (const iterator of file) {
        const resBase64: any = await toBase64(iterator.file);
        await createContentAPI({
          queryKey: [
            'Create Content',
            'ClassNonSelf',
            resBase64.split(',')[1],
            iterator.name,
            {
              classId,
            },
          ],
        });
      }
    } else {
      const resBase64: any = await toBase64(file.file);
      await createContentAPI({
        queryKey: [
          'Create Content',
          'ClassNonSelf',
          resBase64.split(',')[1],
          file.name,
          {
            classId,
          },
        ],
      });
    }
  }
};

export const mutateScheduleNonSelf = async values => {
  const {
    city,
    classId,
    date,
    deletedSessions,
    endTime,
    id,
    learning_schedule_id,
    locationDetails,
    locationLink,
    meetingLink,
    platform,
    province,
    sessions,
    startTime,
    type,
    venue,
  } = values;
  const newSessions: any = [];
  const updatedSessions: any = [];
  let payload;

  if (id) {
    const allStartDate = sessions.map(item =>
      deleteUTCToGlobal(
        `${moment(item.date).format('YYYY-MM-DD')} ${moment(
          item.startTime
        ).format('HH:mm:ss')}`
      )
    );
    const allEndDate = sessions.map(item =>
      deleteUTCToGlobal(
        `${moment(item.date).format('YYYY-MM-DD')} ${moment(
          item.endTime
        ).format('HH:mm:ss')}`
      )
    );
    const earliestStartDateTime = moment(
      moment.min(allStartDate.map(item => moment(item)))
    ).format('YYYY-MM-DD HH:mm:ss');
    const latestEndDateTime = moment(
      moment.max(allEndDate.map(item => moment(item)))
    ).format('YYYY-MM-DD HH:mm:ss');

    const filteredSessions = sessions?.filter(v => !v.disabled);
    for (const session of filteredSessions) {
      const { id, startTime, endTime, date, trainerName } = session;
      const end_time = deleteUTCToGlobal(
        moment(
          `${moment(date).format('YYYY-MM-DD')} ${moment(endTime).format(
            'HH:mm'
          )}`
        ).toISOString()
      );
      const start_time = deleteUTCToGlobal(
        moment(
          `${moment(date).format('YYYY-MM-DD')} ${moment(startTime).format(
            'HH:mm'
          )}`
        ).toISOString()
      );

      if (session.id) {
        updatedSessions.push({
          id,
          learning_schedule_id,
          start_break: end_time,
          end_break: end_time,
          start_time,
          end_time,
          timezone: '7',
          effective_time: '28800',
          trainers_id: trainerName || [],
        });
      } else {
        newSessions.push({
          learning_schedule_id,
          start_break: end_time,
          end_break: end_time,
          start_time,
          end_time,
          timezone: '7',
          effective_time: '28800',
          trainers_id: trainerName || [],
        });
      }
    }

    payload = {
      city,
      deletedSessions,
      endDate: latestEndDateTime,
      endTime: latestEndDateTime,
      locationDetail: locationDetails,
      locationLink,
      meetingLink,
      minAttendRequire: 1,
      newSessions,
      platform,
      province,
      scheduleId: id,
      startDate: earliestStartDateTime,
      startTime: earliestStartDateTime,
      updatedSessions,
      venue,
    };
    return await instance.put('Elearning/ScheduleNonSelf', payload);
  } else {
    payload = {
      city,
      classId,
      endDate: deleteUTCToGlobal(date[1].toISOString()),
      endTime: deleteUTCToGlobal(
        moment(
          `${date[0].format('YYYY-MM-DD')} ${endTime.format('HH:mm')}`
        ).toISOString()
      ),
      locationDetail: locationDetails,
      locationLink,
      meetingLink,
      platform,
      province,
      startDate: deleteUTCToGlobal(date[0].toISOString()),
      startTime: deleteUTCToGlobal(
        moment(
          `${date[0].format('YYYY-MM-DD')} ${startTime.format('HH:mm')}`
        ).toISOString()
      ),
      type,
      venue,
    };
    return await instance.post('Elearning/ScheduleNonSelf', payload);
  }
};

export const mutateParticipantNonSelf = async values => {
  const { classId, manualParticipants } = values;
  return await instance.post('Elearning/ClassParticipant', {
    classId,
    manualParticipants,
  });
};

export const getLookupAgentAPI = async params => {
  const channelCode = params.queryKey[1];
  const { data } = await instance.get(
    `Lookup/configuration/agentCode?sob=${channelCode}`
  );
  return data;
};

export const getLookupAgentLevelAPI = async params => {
  const level = params.queryKey[1];
  const channelCode = params.queryKey[2];
  const { data } = await instance.get(
    `/Lookup/configuration/agent?AgentLevels=${level.toString()}&Sob=${channelCode}`
  );
  return data;
};

export const getLookupAgentLOSAPI = async params => {
  const startLOS = params.queryKey[1];
  const endLOS = params.queryKey[2];
  const channelCode = params.queryKey[3];
  const { data } = await instance.get(
    `/Lookup/configuration/agent?GtLos=${startLOS}&LeLos=${endLOS}&Sob=${channelCode}`
  );
  return data;
};

export const mutateClassParticipantAPI = async params => {
  const { id } = params;
  const { data } = await instance.put(
    `Elearning/ClassNonSelf/UpdateParticipant?id=${id}`
  );
  return data;
};

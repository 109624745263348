import { Col, Row, Tag, Form as FormANTD } from 'antd';
import moment from 'moment';
import React from 'react';
import { yearList } from '../../../App/Enums';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CICon from '../../../Assets/Components/CIcon';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import { RadioButton } from '../../../Assets/Components/CRadio';
import { Field, Form } from 'react-final-form';
import CCalenderScheduleRFF from '../../../Assets/Components/Calender/CCalenderScheduleRFF';
import Select from '../../../Assets/Components/Select';
import { IResultExamScheduleListDAO } from '../DAO/examSchedule.dao';

interface IProps {
  handleOnclickDetail: (e: any) => void;
  handleSelectedYear: (e: any) => void;
  initialValues: any;
  listExamSchedule: IResultExamScheduleListDAO[];
  listExamYear: any[];
  selectDateSchedule: (e: any, x: any) => void;
}

export default function ExamScheduleComponent(props: IProps) {
  const {
    handleOnclickDetail,
    handleSelectedYear,
    initialValues,
    listExamSchedule,
    listExamYear,
    selectDateSchedule,
  } = props;

  const contentCard = () => {
    return (
      <div className="containerWhiteZurich">
        <div className="containerHeaderExamSchedule">
          <Form onSubmit={() => {}} initialValues={initialValues}>
            {formProps => {
              const { form, values } = formProps;
              const renderSchedule = () => {
                if (values?.scheduleType === 'year') {
                  let examMobile = {};

                  for (const iterator of listExamSchedule) {
                    if (iterator.examType === 1) {
                      examMobile = iterator;
                    }
                  }
                  const dataButtonMobile = [
                    {
                      type: 'link',
                      id: `btnDetailScheduleMobileApps`,
                      className: 'btnDetailSchedule',
                      content: 'Detail',
                      onClick: () => handleOnclickDetail(examMobile),
                    },
                  ];

                  return (
                    <>
                      <Row gutter={12}>
                        <Col span={5}>
                          <Field
                            name="yearList"
                            component={Select}
                            dataOption={yearList()}
                            idComponent="InputYearCalender"
                            className="selectExamSchedule"
                            onSelect={e => handleSelectedYear(e)}
                            defaultItemName="Pilih Tahun"
                          />
                        </Col>
                      </Row>
                      <div className="containerExamSchedule">
                        <Row>
                          <Col span={4}></Col>
                          <Col span={20}>
                            <Tag
                              color={'#FB6915'}
                              style={{
                                borderRadius: 5,
                                width: 120,
                                textAlign: 'center',
                              }}
                            >
                              Examination
                            </Tag>
                            <CICon
                              type="MobileOutlined"
                              style={{
                                marginLeft: 10,
                                marginRight: 10,
                                color: '#009EE0',
                                fontWeight: 'bold',
                              }}
                            />
                            <span
                              style={{ color: '#009EE0', fontWeight: 'bold' }}
                            >
                              Mobile Apps
                            </span>
                            <CButton
                              buttonData={dataButtonMobile}
                              buttonFloat="right"
                            />
                          </Col>
                        </Row>
                      </div>
                      {listExamYear.map((data, index) => {
                        if (data.examType === 0) {
                          const dataButton = [
                            {
                              type: 'link',
                              id: `btnDetailSchedule${index}`,
                              className: 'btnDetailSchedule',
                              content: 'Detail',
                              onClick: () => handleOnclickDetail(data),
                            },
                          ];
                          return (
                            <div
                              key={`${data.code}${index}`}
                              className="containerExamSchedule"
                            >
                              <Row>
                                <Col span={4}>
                                  <CICon
                                    type="CalendarOutlined"
                                    style={{ marginRight: 10 }}
                                  />
                                  {moment(data.examDate).format('dddd')},{' '}
                                  {moment(data.examDate).format('DD-MMM')}
                                </Col>
                                <Col span={20}>
                                  <Tag
                                    color={'#FB6915'}
                                    style={{
                                      borderRadius: 5,
                                      width: 120,
                                      textAlign: 'center',
                                    }}
                                  >
                                    Examination
                                  </Tag>
                                  <CICon
                                    type="FieldTimeOutlined"
                                    style={{ marginLeft: 10, marginRight: 10 }}
                                  />
                                  {data.startTime} - {data.endTime}
                                  <CICon
                                    type="DesktopOutlined"
                                    style={{
                                      marginLeft: 30,
                                      marginRight: 10,
                                      color: '#1DB227',
                                    }}
                                  />
                                  <span style={{ color: '#1DB227' }}>
                                    Online
                                  </span>
                                  <CICon
                                    type="EnvironmentOutlined"
                                    style={{ marginLeft: 30, marginRight: 10 }}
                                  />
                                  {data.city}
                                  <CButton
                                    buttonData={dataButton}
                                    buttonFloat="right"
                                  />
                                </Col>
                              </Row>
                            </div>
                          );
                        }
                        return null;
                      })}
                    </>
                  );
                } else
                  return (
                    <CCalenderScheduleRFF
                      form={form}
                      listSchedule={listExamSchedule}
                      onClickDate={selectDateSchedule}
                    />
                  );
              };

              return (
                <FormANTD>
                  <div className="dateNowExamSchedule">
                    <CICon
                      type="CalendarOutlined"
                      style={{ marginRight: 10 }}
                    />
                    {moment().format('dddd')}, {moment().format('DD-MMM-YYYY')}
                  </div>
                  <Field
                    name="scheduleType"
                    component={RadioButton}
                    idComponent="radioScheduleType"
                    radioType="button"
                    className="radioExamSchedule"
                    formClassName="examSchedule"
                    dataOption={[
                      {
                        id: 'month',
                        name: 'Dalam Bulan',
                      },
                      {
                        id: 'year',
                        name: 'Dalam Tahun',
                      },
                    ]}
                  />
                  {renderSchedule()}
                </FormANTD>
              );
            }}
          </Form>
        </div>
      </div>
    );
  };

  return (
    <>
      <CLabelCard leftText="Schedule" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Kalender</span>}
        cardContent={contentCard()}
      />
    </>
  );
}

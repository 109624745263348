import React, { useState } from 'react';
import FormComponent from '../Component/FormComponent';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  getCityListAPI,
  postLeadAnoAPI,
  postValidateLeadAnoAPI,
} from '../../../Services/recruitment.api';
import ErrorHandler from '../../../App/ErrorHandler';
import CModal from '../../../Assets/Components/CModal';
import CIcon from '../../../Assets/Components/CIcon';
import { Button } from 'antd';

interface IProps {
  setShowSuccessPage: React.Dispatch<React.SetStateAction<boolean>>;
  agentData: string[];
}
const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '450px',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '100px',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '2000',
  },
};

export default function FormContainer(props: IProps) {
  const { setShowSuccessPage, agentData } = props;
  const [showModal, setShowModal] = useState(false);
  const [showModalRes, setShowModalRes] = useState(false);
  const [formValue, setFormValue] = useState({});
  const [response, setResponse] = useState<any[]>([]);
  const [enthusiasm, setEnthusiasm] = useState<number>(0);

  const { data } = useQuery(['getCityList'], getCityListAPI, {
    refetchOnWindowFocus: false,
  });

  const mutateValidate = useMutation(postValidateLeadAnoAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: res => {
      if (res.data.length > 0) {
        setShowModal(false);
        setShowModalRes(true);
        setResponse(res.data);
      } else mutateLead.mutate({ ...formValue, enthusiasm, agentData });
    },
  });

  const mutateLead = useMutation(postLeadAnoAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      setShowSuccessPage(true);
    },
  });

  const handleClickEnthusiasm = (val: number) => {
    setEnthusiasm(enthusiasm !== val ? val : 0);
  };

  const handleTambahKandidat = (val: any) => {
    setShowModal(true);
    setFormValue(val);
  };

  const handleSubmit = (val: any) => {
    setShowModal(true);
    const { email, phoneNumber, idNumber } = val;
    mutateValidate.mutate({
      idNumber: idNumber ? idNumber : '',
      email: email ? email : '',
      phoneNumber: `+62${phoneNumber}`,
    });
  };

  const contentComponent = () => {
    const isLoadingMutate = mutateValidate.isLoading || mutateLead.isLoading;
    return (
      <div className="contentModalDownload" style={{ gap: '15px' }}>
        <div className="iconDownload" style={{ backgroundColor: 'white' }}>
          <CIcon type="ExclamationCircleFilled" style={{ color: '#FBC713' }} />
        </div>
        <p className="infoDownloadingReport">
          Anda Yakin Ingin Menambahkan Lead?
        </p>
        <p>Pastikan Anda sudah mengecek kembali data yang telah diisikan.</p>
        <p>Yakin ingin melanjutkan proses?</p>
        <div className="divBtnRecruitmentFormModal">
          <Button
            style={{ width: '100%' }}
            onClick={() => handleSubmit(formValue)}
            htmlType="submit"
            loading={isLoadingMutate}
            className={isLoadingMutate ? 'btnSubmitDisabled' : 'btnSubmit'}
            disabled={isLoadingMutate}
          >
            Ya
          </Button>
          <Button
            onClick={() => setShowModal(false)}
            htmlType="submit"
            className={
              isLoadingMutate
                ? 'btnCancelDisabled'
                : 'btnCancelRecruitmentFormModal'
            }
            disabled={isLoadingMutate}
            loading={isLoadingMutate}
          >
            Tidak
          </Button>
        </div>
      </div>
    );
  };

  const contentResponseComponent = () => {
    return (
      <div className="contentModalDownload" style={{ gap: '15px' }}>
        <div className="iconDownload" style={{ backgroundColor: 'white' }}>
          <CIcon type="ExclamationCircleFilled" style={{ color: '#FBC713' }} />
        </div>
        <p className="infoDownloadingReport">
          Beberapa data sudah pernah/digunakan oleh kandidat lain!
        </p>
        {response.map((item, i) => {
          const label = item.name === 'PhoneNumber' ? 'Nomor HP' : item.name;
          return (
            <p key={item?.value}>
              {i + 1}. <span className="labelRequired">{label}</span> (
              {item?.value}) telah digunakan
            </p>
          );
        })}
        <p>Silakan isi kembali menggunakan data lainnya</p>
        <div>
          <Button
            onClick={() => setShowModalRes(false)}
            className="btnEditFeedbackNonSelf"
            htmlType="button"
          >
            Tutup
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <FormComponent
        cityList={data || []}
        enthusiasm={enthusiasm}
        handleClickEnthusiasm={handleClickEnthusiasm}
        handleSubmit={handleTambahKandidat}
        response={response}
      />
      <CModal
        headerComponent={undefined}
        contentComponent={contentComponent()}
        footerComponent={null}
        modalIsShow={showModal}
        handleCancel={() => setShowModal(false)}
        modalstyles={defaultModalstyles}
        contentClassName="contentRecruitmentFormModal"
      />
      <CModal
        headerComponent={undefined}
        contentComponent={contentResponseComponent()}
        footerComponent={null}
        modalIsShow={showModalRes}
        handleCancel={() => setShowModalRes(false)}
        modalstyles={defaultModalstyles}
        contentClassName="contentRecruitmentFormModal"
      />
    </>
  );
}

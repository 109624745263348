import React from 'react';
import ModalNotifIsZelComponent from '../../Component/Modal/ModalNotifIsZelComponent';

interface IProps {
  modalIsShow: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalNotifIsZelContainer(props: IProps) {
  const { modalIsShow, setModalIsShow } = props;
  const handleCancel = () => setModalIsShow(false);
  return (
    <ModalNotifIsZelComponent
      modalIsShow={modalIsShow}
      handleCancel={handleCancel}
    />
  );
}

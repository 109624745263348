const slideValidation = (values: any, slideCoverProperties: any) => {
  const { name, fileType, video, audio, pdf, embedLink } = values;
  const errors: any = {};

  if (!name) errors.name = 'Slide Content Name Wajib Diisi';
  else if (name && !/^[^\s].*/.test(name))
    errors.name = 'Gunakan huruf/angka di awal kata!';

  if (!values.sequence) errors.sequence = 'Sequence Wajib Diisi';

  if (!fileType) errors.fileType = 'Tipe Konten Wajib Diisi!';
  else {
    if (fileType === 'video') {
      if (video !== true) {
        errors.video = 'Video Wajib Diisi!';
      }
    } else if (fileType === 'audio') {
      if (audio !== true) {
        errors.audio = 'Content Audio Wajib Diisi!';
      }
      if (!slideCoverProperties) {
        errors.audioCover = 'Cover Wajib Diisi!';
      }
    } else if (fileType === 'pdf') {
      if (pdf !== true) {
        errors.pdf = 'PDF Wajib Diisi!';
      }
    } else if (fileType === 'youtube') {
      if (!embedLink) {
        errors.embedLink = 'Video Link Wajib Diisi';
      } else if (
        embedLink &&
        !/^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/.test(
          embedLink
        )
      ) {
        errors.embedLink = 'Link Video Wajib Dari Youtube!';
      }
    }
  }

  return errors;
};

export default slideValidation;

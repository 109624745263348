import React from 'react';
import { Tabs } from 'antd';
import CBreadcrumb from '../../Assets/Components/CBreadcrumb';
import CCard from '../../Assets/Components/CCard';
import CLabelCard from '../../Assets/Components/CLabelCard';
import ChannelContainer from './Channel/Container/ChannelContainer';
import StageContainer from './Stage/Container/StageContainer';
import ModuleContainer from './Module/Container/ModuleContainer';
import SubmoduleContainer from './Submodule/Container/SubmoduleContainer';
import ContentContainer from './Content/Container/ContentContainer';

interface IProps {
  tabData: {
    channel?: any;
    stage?: any;
    module?: any;
    submodule?: any;
  };
  allDataList: {
    channelList: any;
    stageList: any;
    moduleList: any;
    submoduleList: any;
  };
  setTabData: (val: any) => void;
  setAllDataList: (val: any) => void;
  handleOnTabClick: (params: string) => void;
  breadCrumbData: any;
}

export default function ContentManagementComponent({
  tabData,
  allDataList,
  setTabData,
  setAllDataList,
  handleOnTabClick,
  breadCrumbData,
}: IProps) {
  const { TabPane } = Tabs;
  const { channel, stage, module, submodule } = tabData;

  const handleActiveKey = () => {
    if (channel && stage && module && submodule) return 'Content';
    else if (channel && stage && module) return 'Sub Module';
    else if (channel && stage) return 'Module';
    else if (channel) return 'Stage';

    return 'Channel';
  };

  const renderContent = content => {
    const { breadcrumbItem, breadcrumbTitle, breadcrumbItemClick } =
      breadCrumbData;
    return (
      <>
        <CBreadcrumb
          breadcrumbItem={breadcrumbItem}
          breadcrumbTitle={breadcrumbTitle}
          breadcrumbItemClick={breadcrumbItemClick}
        />
        <div className="containerElearningTabPane">{content}</div>
      </>
    );
  };

  const tabContent = () => {
    return (
      <>
        <Tabs
          className="dynamicTabs"
          type="card"
          activeKey={handleActiveKey()}
          onTabClick={handleOnTabClick}
        >
          <TabPane
            tab="Channel"
            key="Channel"
            className="tabsPaneDynamic"
            style={{ padding: 0 }}
          >
            {renderContent(
              <ChannelContainer
                setTabData={setTabData}
                setAllDataList={setAllDataList}
              />
            )}
          </TabPane>
          <TabPane
            tab="Stage"
            key="Stage"
            className="tabsPaneDynamic"
            disabled={!channel}
          >
            {renderContent(
              <StageContainer
                channel={channel}
                setTabData={setTabData}
                setAllDataList={setAllDataList}
              />
            )}
          </TabPane>
          <TabPane
            tab="Module"
            key="Module"
            className="tabsPaneDynamic"
            disabled={!stage}
          >
            {renderContent(
              <ModuleContainer
                channel={channel}
                stage={stage}
                setTabData={setTabData}
                setAllDataList={setAllDataList}
              />
            )}
          </TabPane>
          <TabPane
            tab="Sub Module"
            key="Sub Module"
            className="tabsPaneDynamic"
            disabled={!module}
          >
            {renderContent(
              <SubmoduleContainer
                channel={channel}
                stage={stage}
                module={module}
                allDataList={allDataList}
                setTabData={setTabData}
                setAllDataList={setAllDataList}
              />
            )}
          </TabPane>
          <TabPane
            tab="Content"
            key="Content"
            className="tabsPaneDynamic"
            disabled={!submodule}
          >
            {renderContent(
              <ContentContainer
                channel={channel}
                stage={stage}
                module={module}
                submodule={submodule}
                allDataList={allDataList}
                setTabData={setTabData}
                setAllDataList={setAllDataList}
              />
            )}
          </TabPane>
        </Tabs>
      </>
    );
  };

  return (
    <>
      <CLabelCard leftText="Content Management" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Content List</span>}
        cardContent={tabContent()}
      />
    </>
  );
}

import moment from 'moment';
import React, { useState } from 'react';
import CButton from '../../../../Assets/Components/CButton';
import LicenseTabUploadComponent from '../../Component/Tabs/LicenseTabUploadComponent';
import { useQuery } from '@tanstack/react-query';
import { licenseAPI } from '../../../../Services/license.api';
import ModalUploadDetailLicenseContainer from '../Modal/ModalUploadDetailLicenseContainer';
import ModalUploadLicenseContainer from '../Modal/ModalUploadLicenseContainer';

export default function LicenseTabUploadContainer() {
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalUpload, setShowModalUpload] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const formatDOB = 'DD-MMM-YYYY';

  const {
    data: licenseUploadList,
    refetch: refetchLicenseUpload,
    isLoading: isLoadingLicenseUpload,
    fetchStatus: fetchStatusLicenseUpload,
  } = useQuery(['GetLicenseUploadAPI'], licenseAPI.getLicenseUploadData, {
    enabled: !showModalUpload,
    refetchOnWindowFocus: false,
  });

  const handleUpload = () => setShowModalUpload(true);

  const handleRefresh = () => refetchLicenseUpload();

  const renderAction = (row: any) => {
    const detailClick = () => {
      const detail = row.row.original;
      const errorM = detail.errorMessage.split('|');
      let arrayError: any = [];
      for (const iterator of errorM) {
        arrayError.push(iterator);
      }
      const totalError = errorM[0] !== '' ? errorM.length - 1 : null;
      const detailUpload = {
        originalFilename: detail.originalFilename,
        formatFile: '.xlsx',
        uploadStatus: detail.status,
        uploadDate: moment(detail.uploadDate).format(formatDOB),
        errorMessage: errorM,
        totalError,
      };
      setSelectedData(detailUpload);
      setShowModalDetail(true);
    };
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailQa',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: detailClick,
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderStatus = row => {
    return (
      <div className="spanContestStatus">
        <span
          className={`licenseStatusColor licenseStatusColor${row.row.original.status}`}
        />
        <span className={`licenseStatusName`}>{row.row.original.status}</span>
      </div>
    );
  };

  const columnData = [
    {
      Header: 'Nama File',
      accessor: 'originalFilename',
    },
    {
      Header: 'Status Unggah',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Tanggal Unggah',
      accessor: row =>
        row.uploadDate ? moment(row.uploadDate).format(formatDOB) : '-',
      disableSort: true,
    },
    {
      Header: ' ',
      Cell: row => renderAction(row),
    },
  ];

  return (
    <>
      <LicenseTabUploadComponent
        column={columnData}
        data={licenseUploadList || []}
        handleUpload={handleUpload}
        handleRefresh={handleRefresh}
        isLoading={
          isLoadingLicenseUpload || fetchStatusLicenseUpload === 'fetching'
        }
      />
      <ModalUploadDetailLicenseContainer
        showModal={showModalDetail}
        setShowModal={setShowModalDetail}
        licenseDetailUpload={selectedData}
      />
      <ModalUploadLicenseContainer
        showModal={showModalUpload}
        setShowModal={setShowModalUpload}
      />
    </>
  );
}

import Table, { generateColumnData } from '../../../Assets/Components/CTable';

import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import ModalConfigurationContainer from '../Container/ModalConfigurationContainer';
import React from 'react';
import { injectIntl } from 'react-intl';
import { IResultConfigurationListDAO } from '../DAO/configuration.dao';

interface IProps {
  columnData: any;
  configurationList: IResultConfigurationListDAO[];
  handleRefresh: () => void;
  isLoading: boolean;
  modalConfigIsShow: boolean;
  selectedData: any;
  setModalConfigIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  intl?: any;
}

function ConfigurationComponent(props: IProps) {
  const {
    intl,
    configurationList,
    isLoading,
    columnData,
    modalConfigIsShow,
    setModalConfigIsShow,
    selectedData,
    setSelectedData,
    handleRefresh,
  } = props;

  const cardConfigurationContent = () => {
    return (
      <div className="containerWhiteZurich">
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={configurationList || []}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="System" rightText="Configuration" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'configurationCardTitle',
            })}
          </span>
        }
        cardContent={cardConfigurationContent()}
      />
      <ModalConfigurationContainer
        modalConfigIsShow={modalConfigIsShow}
        setModalConfigIsShow={setModalConfigIsShow}
        intl={intl}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

export default injectIntl(ConfigurationComponent);

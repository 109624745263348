import { Form, Switch } from 'antd';

import React from 'react';

interface IPropsText {
  input;
  meta;
  idComponent;
  disabled;
  labelTitle;
  defaultChecked?;
  path?;
  addtionalFunction?;
  className?;
}

export const CInputSwitch = ({
  input,
  meta: { touched, error },
  idComponent,
  disabled,
  labelTitle,
  defaultChecked,
  path,
  addtionalFunction,
  className,
}: IPropsText) => {
  const onChange = e => {
    input.onChange(e);
    if (addtionalFunction) {
      addtionalFunction(e, path);
    }
  };

  const checked = () => {
    if (typeof input.value === 'boolean') {
      return input.value;
    } else {
      return false;
    }
  };

  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <Switch
        disabled={disabled}
        id={idComponent}
        onChange={onChange}
        checked={defaultChecked ? defaultChecked : checked()}
        className={className}
      />
    </Form.Item>
  );
};

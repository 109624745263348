import React from 'react';
import ModalCancelCandidateComponent from '../../Component/Modal/ModalCancelCandidateComponent';
import { useMutation } from '@tanstack/react-query';
import { mutateRejectCandidateAPI } from '../../../../Services/qaProcess.api';
import { toast } from 'react-toastify';
import Store from '../../../../Store';
import { toastr } from 'react-redux-toastr';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  modalCancelIsShow: boolean;
  setModalCancelIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  detailCandidate: any;
  setModalDetailIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  refreshList: any;
}
export default function ModalCancelCandidateContainer(props: IProps) {
  const {
    modalCancelIsShow,
    setModalCancelIsShow,
    detailCandidate,
    setModalDetailIsShow,
    refreshList,
  } = props;

  const { mutate: mutateReject, isLoading } = useMutation(
    mutateRejectCandidateAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">Berhasil Dibatalkan</span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        setModalCancelIsShow(false);
        setModalDetailIsShow(false);
        refreshList();
      },
    }
  );

  const handleReject = (vals: any) => {
    const authState: any = Store.getState();
    const username =
      authState.auth[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ];

    const toastrConfirmOptions = {
      onOk: () => {
        mutateReject({
          ...vals,
          detailCandidate,
          username,
        });
      },
      id: 'toastRConfirm',
    };
    toastr.confirm(
      'Anda yakin ingin membatalkan pengajuan kandidat?',
      toastrConfirmOptions
    );
  };

  const handleCancel = () => {
    setModalCancelIsShow(false);
  };
  return (
    <ModalCancelCandidateComponent
      modalCancelIsShow={modalCancelIsShow}
      handleCancel={handleCancel}
      handleReject={handleReject}
      isLoading={isLoading}
    />
  );
}

import 'antd/dist/antd.css';

import { Form, Image } from 'antd';
import React from 'react';

import CButton from './CButton';
interface IProps {
  documents?;
  name?;
  imgClassName?;
  label?;
  fileName?;
  size?;
  path?;
  formName?;
  disabled?;
  uppyConfig?;
  base64?;
  formClassName?;
  visibleButton?;
  width?;
  height?;
  extension?;
  addtionalLabel?;
  visibleInformation?;
  styleImg?;
  labelInfoNew?;
  form?;
}

export const ViewImage = (props: IProps) => {
  const {
    documents,
    name,
    label,
    fileName,
    size,
    disabled,
    base64,
    formClassName,
    visibleButton,
    width,
    height,
    extension,
    addtionalLabel,
    visibleInformation,
    styleImg,
    labelInfoNew,
    form,
    path,
  } = props;
  const handleDelete = () => {
    form?.change(path, null);
  };
  const dataButton = [
    visibleButton
      ? null
      : {
          type: 'primary',
          id: 'btnDeleteImage',
          onClick: handleDelete,
          className: 'btnDeleteImage',
          icon: 'DeleteOutlined',
          disabled,
        },
  ];

  const additionalLabel = () => {
    if (addtionalLabel) {
      return ` ${addtionalLabel}`;
    } else {
      return null;
    }
  };

  const labelTitle = label ? (
    <span>
      <b className={`capital`}>{label}</b>
      <span style={{ color: '#BFBFBF' }}>{additionalLabel()}</span>
    </span>
  ) : null;

  const srcImg = documents ? documents[name].content : base64;

  const styleImage = () => {
    if (styleImg) {
      return null;
    } else {
      return 200;
    }
  };
  const hiddenLabel = () => {
    if (labelInfoNew) {
      return !labelInfoNew;
    } else {
      return !false;
    }
  };

  return (
    <Form.Item label={labelTitle} className={formClassName}>
      {extension === 'pdf' ? (
        <iframe title={fileName} src={srcImg} style={{ width, height }} />
      ) : (
        <Image
          src={srcImg}
          width={width || styleImage()}
          height={height || styleImage()}
          style={styleImg}
        />
      )}

      {visibleInformation ? null : (
        <div className="containerTitle">
          <div className="containerDetail">
            <b>{fileName}</b> <br />
            {`${size} KB`}
            <div hidden={hiddenLabel()} className="imgInfoNew">
              Baru
            </div>
          </div>
          <CButton buttonData={dataButton} />
        </div>
      )}
    </Form.Item>
  );
};

import { Button, Col, Row } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import HasPermission from '../../../App/HasPermission';
import CCard from '../../../Assets/Components/CCard';
import CIcon from '../../../Assets/Components/CIcon';
import { InputText, InputTextNumber } from '../../../Assets/Components/CInput';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import Select from '../../../Assets/Components/Select';
import { FormattedMessage } from 'react-intl';
import {
  contestChanel,
  contestGroup,
  contestLevel,
  contestType,
} from '../../../App/Enums';
import { injectIntl } from 'react-intl';
import contestTargetValidation from '../Validation/contestTargetValidation';
import { IResultContestRewardDAO } from '../../ContestReward/DAO/contestReward.dao';

interface IProps {
  contestRewardList: IResultContestRewardDAO[];
  disabled: boolean;
  editMode: boolean;
  handleCancel: () => void;
  handleSubmit: (vals: any) => void;
  initialValues: any;
  intl: any;
  isLoading: boolean;
  isLoadingSubmit: boolean;
  isShow: boolean;
  modalAction: 'register' | 'update';
}

function ContestTargetModalComponent(props: IProps) {
  const {
    contestRewardList,
    disabled,
    editMode,
    handleCancel,
    handleSubmit,
    initialValues,
    intl,
    isLoading,
    isLoadingSubmit,
    isShow,
    modalAction,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="contestTargetForm" />
      </h3>
    );
  };

  const handleDisableButton = (invalid, hasErrors) => {
    if (modalAction === 'register') {
      if (invalid || hasErrors) {
        return true;
      } else return false;
    } else {
      if (editMode === false) return false;
      else {
        if (invalid || hasErrors) {
          return true;
        } else return false;
      }
    }
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid, error } = propsFooter;
    const buttonContent = () => {
      if (editMode === false) {
        if (modalAction === 'register') {
          return 'Submit';
        }
        return 'Edit';
      }
      return 'Save Change';
    };

    if (
      (modalAction === 'register' && HasPermission('c')) ||
      (modalAction === 'update' && HasPermission('u'))
    ) {
      return (
        <Button
          className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
          icon={<CIcon type="CheckOutlined" />}
          disabled={handleDisableButton(invalid, error)}
          htmlType="submit"
          id="btnSubmit"
          loading={isLoadingSubmit}
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { values } = propsContent;
    const numberOfTarget = values
      ? values.numberOfTarget
        ? parseInt(values.numberOfTarget, 10)
        : null
      : null;

    const contentCardFormContest = () => {
      return (
        <Row gutter={20}>
          <Col span={12}>
            <Field
              name="contestType"
              component={Select}
              idComponent="inputContestType"
              dataOption={contestType}
              defaultItemName="Select contest type"
              label={intl.formatMessage({ id: 'contestType' })}
              disabled={disabled}
            />
            <Field
              name="group"
              component={Select}
              idComponent="inputContestGroup"
              dataOption={contestGroup}
              defaultItemName="Select contest group"
              label={intl.formatMessage({ id: 'contestGroup' })}
              disabled={disabled}
            />
            <Field
              name="chanel"
              component={Select}
              idComponent="inputContestChanel"
              dataOption={contestChanel}
              defaultItemName="Select contest channel"
              label={intl.formatMessage({ id: 'contestChanel' })}
              disabled={disabled}
            />
            <Field
              name={`contestLevel`}
              component={Select}
              idComponent={`inputContestLevel`}
              dataOption={contestLevel}
              defaultItemName="Select contest level"
              label={intl.formatMessage({ id: 'contestLevel' })}
              disabled={disabled}
            />
          </Col>
          <Col span={12}>
            <Field
              name="monitoringPeriode"
              component={InputTextNumber}
              idComponent="inputMonitoringperiod"
              label={intl.formatMessage({ id: 'monitoringPeriodContest' })}
              min={1}
              disabled={disabled}
            />
            <Field
              name="numberOfTarget"
              component={InputTextNumber}
              idComponent="inputNumberOfTarget"
              label={intl.formatMessage({ id: 'numberOfTargetContest' })}
              min={1}
              // defaultValue={1}
              disabled={disabled}
            />
            <Field
              name="dateContest"
              component={InputDatePicker}
              idComponent="inputDateSchedule"
              label={intl.formatMessage({ id: 'dateContest' })}
              typeDate="dateRangePicker"
              update={modalAction === 'update' ? true : false}
              disabled={disabled}
              initialValues={initialValues}
              initialKey="dateContest"
              formName="contestTarget"
              fieldName="dateContest"
            />
          </Col>
        </Row>
      );
    };

    const targetRender = (targetLoop: number[], formValue: any) => {
      return targetLoop.map((val, idx) => {
        const cardTargetRewardContent = x => {
          const contestTypeForm = x.contestType;
          return (
            <Row gutter={20}>
              <Col span={12}>
                <Field
                  name={`targetName${idx}`}
                  component={InputText}
                  idComponent={`inputTargetName${idx}`}
                  placeholder={intl.formatMessage({
                    id: 'placeholderTargetName',
                  })}
                  label={intl.formatMessage({ id: 'targetName' })}
                  disabled={disabled}
                />
                {contestTypeForm !== 'MDRT' ? (
                  <Field
                    name={`rewardId${idx}`}
                    component={Select}
                    idComponent={`inputRewardId${idx}`}
                    dataOption={contestRewardList}
                    defaultItemName="Select contest reward"
                    label={intl.formatMessage({ id: 'contestReward' })}
                    disabled={disabled}
                  />
                ) : null}
                <Field
                  name={`rewardPersistency${idx}`}
                  component={InputTextNumber}
                  idComponent={`inputPersistency${idx}`}
                  label={intl.formatMessage({
                    id: 'contestTargetPersistency',
                  })}
                  min={1}
                  max={100}
                  disabled={disabled}
                />
              </Col>
              <Col span={12}>
                {contestTypeForm !== 'MDRT' ? (
                  <Field
                    name={`rewardNumberOfTicket${idx}`}
                    component={InputTextNumber}
                    idComponent={`inputNumberOfTicket${idx}`}
                    label={intl.formatMessage({
                      id: 'contestNumberOfTicket',
                    })}
                    min={1}
                    disabled={disabled}
                  />
                ) : null}
                <Field
                  name={`rewardFyp${idx}`}
                  component={InputTextNumber}
                  idComponent={`inputFyp${idx}`}
                  label={intl.formatMessage({ id: 'contestTargetFyp' })}
                  min={0}
                  disabled={disabled}
                />
                <Field
                  name={`rewardNbFyp${idx}`}
                  component={InputTextNumber}
                  idComponent={`inputNbFyp${idx}`}
                  label={intl.formatMessage({ id: 'contestTargetNbFyp' })}
                  min={0}
                  disabled={disabled}
                />
              </Col>
            </Row>
          );
        };
        return (
          <CCard
            key={`${idx}`}
            cardTitle={`${intl.formatMessage({
              id: 'cardTitleRewardTarget',
            })} ${idx + 1}`}
            cardContent={cardTargetRewardContent(formValue)}
          />
        );
      });
    };

    const renderCardContestTarget = () => {
      const targetLoop: number[] = [];
      if (numberOfTarget !== null && numberOfTarget > 0) {
        for (
          let indexTargetLoop = 0;
          indexTargetLoop < numberOfTarget;
          indexTargetLoop++
        ) {
          targetLoop.push(indexTargetLoop);
        }
        return (
          <CCard
            cardContent={targetRender(targetLoop, values)}
            cardTitle={intl.formatMessage({ id: 'cardTitleContestTarget' })}
          />
        );
      } else return null;
    };

    return (
      <>
        <CCard
          cardContent={contentCardFormContest()}
          cardTitle={intl.formatMessage({ id: 'cardTitleContestDetail' })}
        />
        {renderCardContestTarget()}
      </>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={contestTargetValidation}
      isForm={true}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}
export default injectIntl(ContestTargetModalComponent);

const QuestionBankValidation = (
  values: any,
  questionBankModalAction: any,
  totalQuestion: number,
  condition: boolean
) => {
  const errors: any = {};
  for (let index = 0; index < totalQuestion; index++) {
    const indexName = index ? index : '';
    if (!values[`channelId${indexName}`]) {
      errors[`channelId${indexName}`] = 'Channel Wajib Diisi!';
    }
    if (!values[`categoryId${indexName}`] && !condition) {
      errors[`categoryId${indexName}`] = 'Category Wajib Diisi!';
    }
    if (!values[`title${indexName}`]) {
      errors[`title${indexName}`] = 'Question Title Wajib Diisi!';
    }
    if (!values[`point${indexName}`]) {
      errors[`point${indexName}`] = 'Point Wajib Diisi!';
    }
    const listAnswerOption = values[`answerOption${indexName}`];
    if (
      !values[`answer${indexName}`] ||
      !listAnswerOption ||
      listAnswerOption?.length < 4
    ) {
      errors[`answer${indexName}`] = 'Answer List - Text box Wajib Diisi!';
    } else {
      const answerErr: any = [];
      if (listAnswerOption && listAnswerOption.length > 0) {
        listAnswerOption?.map((item: any) => {
          const errOption: any = {};
          if (!item || item === undefined) {
            errOption.option = 'Answer List - Text box Wajib Diisi!';
          }
          answerErr.push(errOption);
          answerErr?.map((item: any) => {
            if (item.option) {
              errors[`answer${indexName}`] =
                'Answer List - Text box Wajib Diisi!';
            }
          });
        });
        errors[`answerOption${indexName}`] = answerErr;
      }
    }
  }
  if (questionBankModalAction === 'update') {
    if (!values.channelId) {
      errors.channel = 'Channel Wajib Diisi!';
    }
    if (!values.categoryId && !condition) {
      errors.categoryId = 'Category Wajib Diisi!';
    }
    if (!values.title) {
      errors.title = 'Question Title Wajib Diisi!';
    }
    if (!values.point) {
      errors.point = 'Point Wajib Diisi!';
    }
    if (!values.answer) {
      errors.answer = 'Jawaban yang benar wajib dipilih!';
    } else if (
      (values.answer && !values.answerOption) ||
      (values.answer && values.answerOption.length === 0)
    ) {
      errors.answerOption = 'Answer List - Text box Wajib Diisi!';
    }
  }
  return errors;
};
export default QuestionBankValidation;

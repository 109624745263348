import { Dashboard, useUppy } from '@uppy/react';
import React from 'react';

import FormItem from 'antd/lib/form/FormItem';
import Uppy from '@uppy/core';
import { useForm } from 'react-final-form';
import { FormattedMessage } from 'react-intl';

interface IProps {
  meta?: any;
  label?: any;
  uppyConfig?: any;
  height?: any;
  labelKey?: any;
  input?: any;
  actionDispatch?: any;
  actionDeleteDispatch?: any;
  paramsDispatch?: any;
  useFile?: boolean;
  additionalOnchange?: any;
  disabled?: any;
  addtionalLabel?: string;
  labelClassName?: any;
  defaultLabel?: boolean;
  formClassName?: string;
}
const InputFile = (props: IProps) => {
  const {
    meta: { error },
    label,
    uppyConfig,
    height,
    labelKey,
    input,
    formClassName,
    paramsDispatch,
    useFile,
    additionalOnchange,
    actionDeleteDispatch,
    disabled,
    addtionalLabel,
    labelClassName,
    defaultLabel,
  } = props;
  const { maxFile, allowedFile, maxSize, minFile, maxTotalFileSize } =
    uppyConfig;

  const formAPI = useForm();
  const formValue = formAPI.getState().values;
  const uppyInput: any = useUppy(() => {
    return new Uppy({
      id: input?.name,
      autoProceed: true,
      restrictions: {
        minNumberOfFiles: minFile,
        maxNumberOfFiles: maxFile,
        allowedFileTypes: allowedFile,
        maxFileSize: maxSize,
        maxTotalFileSize,
      },
    });
  });

  uppyInput
    .on('file-added', file => {
      const handleFile = useFile === true ? file : undefined;
      const fileArr: any = [];
      fileArr.push({
        ...additionalOnchange,
        ...handleFile,
        file: file.data,
        name: file.name,
        label: labelKey,
        size: file.size,
        type: file.type,
        extension: file.extension,
        meta: file.meta,
      });
      input.onChange(fileArr);
    })
    .on('file-removed', (file: any) => {
      if (input.value.length > 1) {
        const tempData = [...input.value];
        const filteredData = tempData.filter(val => {
          return val.name !== file.name;
        });
        input.onChange(filteredData);
      } else {
        input.onChange(null);
      }
      if (actionDeleteDispatch) {
        actionDeleteDispatch(file, formValue, { ...paramsDispatch });
      }
    });

  const labelTitle = () => {
    if (defaultLabel) {
      return (
        <span>
          <b className={`capital ${labelClassName}`}>
            <FormattedMessage id={label} />
          </b>
          <span style={{ color: '#BFBFBF' }}>
            {addtionalLabel ? ` ${addtionalLabel}` : null}
          </span>
        </span>
      );
    } else {
      return label;
    }
  };

  return (
    <FormItem
      validateStatus={error !== undefined ? 'error' : ''}
      help={error !== undefined ? error : ''}
      label={labelTitle()}
      colon={false}
      className={formClassName}
    >
      <Dashboard
        uppy={uppyInput}
        width={'100%'}
        height={height ? height : 150}
        hideUploadButton={true}
        proudlyDisplayPoweredByUppy={false}
        disabled={disabled}
        defaultLabel={defaultLabel}
      />
    </FormItem>
  );
};
export default React.memo(InputFile);

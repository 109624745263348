import { Button, Skeleton } from 'antd';
import { InputSwitch, InputText } from '../../../../Assets/Components/CInput';

import CIcon from '../../../../Assets/Components/CIcon';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../../App/HasPermission';
import React from 'react';
import validate from '../../Validation/StageValidation';

interface IProps {
  isShow: boolean;
  stageElearningModalAction: string;
  editMode: boolean;
  isLoading: boolean;
  intl?: any;
  handleCancel: () => void;
  handleSubmit: (val: any) => void;
  initialValues: any;
}

export default function StageModalComponent(props: IProps) {
  const {
    stageElearningModalAction,
    isLoading,
    editMode,
    isShow,
    handleCancel,
    handleSubmit,
    initialValues,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {stageElearningModalAction === 'update' ? (
          <FormattedMessage id="detailStageElearning" />
        ) : (
          <FormattedMessage id="addStageElearning" />
        )}
      </h3>
    );
  };

  const contentComponent = formProps => {
    const disabled =
      editMode === false && stageElearningModalAction === 'update'
        ? true
        : false;
    return (
      <Skeleton active loading={isLoading}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="name"
            idComponent="inputNameStage"
            component={InputText}
            placeholder="Type Here"
            disabled={disabled}
            label="Stage Name"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Field
            name="description"
            idComponent="inputDescStage"
            component={InputText}
            placeholder="Type Here"
            label="Description"
            disabled={disabled}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <label className="labelInputFormModal">Stage Status</label>
          <Field
            name="isActive"
            idComponent="inputStatusStage"
            component={InputSwitch}
            disabled={disabled}
          />
        </div>
      </Skeleton>
    );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = ({ invalid }) => {
    if (HasPermission('u') || HasPermission('c')) {
      return (
        <Button
          className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
          icon={<CIcon type="CheckOutlined" />}
          disabled={isLoading}
          id="btnSubmit"
          htmlType="submit"
        >
          {buttonContent(stageElearningModalAction, editMode)}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      isLoading={false}
      initialValues={initialValues}
    />
  );
}

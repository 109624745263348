import instance from "../interceptor";

export const getAgreementMappingAPI = async (params:any) => {
  const { data } = await instance.get(`/configuration/disclaimer/module/mapping?menuType=Agreement`);
    return data;
  };
export const getAgreementModuleAPI = async (params:any) => {
    const { data } = await instance.get(`/configuration/disclaimer/module?menuType=Agreement`);
    return data;
  };

export const mutateAgreementMappingAPI = async (params:any) => {  
    const { disclaimerId, disclaimerModuleId, agreementType, id } = params;
    const payload = { disclaimerId, disclaimerModuleId, agreementType,  menuType: 'Agreement'}    
    if (id) {
      return await instance.put(`/configuration/disclaimer/module/mapping/${id}`, payload);
    } else {
      return await instance.post(`/configuration/disclaimer/module/mapping`, payload);
    }
  };
import { useMutation, useQuery } from '@tanstack/react-query';
import { Switch } from 'antd';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import CButton from '../../../../../Assets/Components/CButton';
import SlideTabComponent from '../../Component/Tabs/SlideTabComponent';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import { contentManagementContentSlideAPI } from '../../../../../Services/ElearningContentManagement/contentSlide';
import SlideModalContainer from '../Modal/SlideModalContainer';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  currentTab: string;
  stage: any;
  submodule: any;
}

export default function SlideTabContainer(props: IProps) {
  const { currentTab, stage, submodule } = props;
  const [showModal, setShowModal] = useState(false);
  const [filter, setFilter] = useState();
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedData, setSelectedData] = useState();

  useEffect(() => {
    if (submodule) return;
    setSelectedStatus(undefined);
    setFilter(undefined);
  }, [submodule]);

  useEffect(() => {
    if (currentTab !== 'Slide') return;
    setSelectedStatus(undefined);
    setFilter(undefined);
  }, [currentTab]);

  const {
    data: dataSlide,
    isLoading: isLoadingSlide,
    isRefetching: isRefetchSlide,
    refetch,
  } = useQuery(
    ['getContentManagementSlideAPI', { id: submodule?.id }],
    contentManagementContentSlideAPI.getSlide,
    {
      enabled: submodule?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const mutateDelete = useMutation(
    contentManagementContentSlideAPI.deleteSlide,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Slide Berhasil Dihapus!</span>
        );
        refetch();
      },
    }
  );

  const mutateSlideStatus = useMutation(
    contentManagementContentSlideAPI.mutateSlideStatus,
    {
      onSuccess: () => {
        setShowModal(false);
        refetch();
        toast.success(
          <span className="capitalFirst">Slide Berhasil Diupdate!</span>
        );
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  const renderName = row => {
    return <p className="textSlideName">{row.row.original.name}</p>;
  };
  const renderFileType = row => {
    return <div className="textFileTypeSlide">{row.row.original.fileType}</div>;
  };

  const processStatusChannel = (id: number, status?: boolean) => {
    mutateSlideStatus.mutate({ status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const rowData = row.row.original;
    const action = status === true ? 'mengaktifkan' : 'menon-aktifkan';
    const actionConfirm = status === true ? 'Aktifkan' : 'Non-Aktifkan';
    const toastrConfirmOptions = {
      okText: actionConfirm,
      cancelText: 'Batalkan',
      onOk: () => processStatusChannel(id, status),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Anda yakin ingin ${action} Slide "${rowData.name}"?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = row => {
    const rowData = row.row.original;
    const processEnabled = isChecked =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = row => {
    const rowData = row.row.original;
    const handleEdit = () => {
      setSelectedData(rowData);
      setShowModal(true);
    };
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => mutateDelete.mutate(rowData.id),
        id: 'toastConfirmSlideContent',
      };
      toastr.confirm(
        `Delete Slide content "${rowData.name}"`,
        toastrConfirmOptions
      );
    };
    const dataButton = [
      {
        id: `btnDeleteContentSlide`,
        type: 'danger',
        icon: 'DeleteOutlined',
        className: 'buttonTable',
        onClick: handleDelete,
      },
      {
        id: `btnEditContentSlide`,
        type: 'primary',
        icon: 'EditOutlined',
        onClick: handleEdit,
        className: 'buttonTable',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Slide Name',
      accessor: 'name',
      Cell: row => renderName(row),
    },
    {
      Header: 'Type',
      Cell: row => renderFileType(row),
    },
    {
      Header: 'Sequence',
      accessor: 'sequence',
    },
    {
      Header: 'Created Date',
      accessor: row => moment(row.createdDate).format('DD-MMM-YYYY'),
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const handleApplyfilter = () => setFilter(selectedStatus);
  const handleAdd = () => setShowModal(true);
  const handleRefresh = () => {
    refetch();
    setSelectedStatus(undefined);
    setFilter(undefined);
  };

  return (
    <>
      <SlideTabComponent
        columnData={columnData}
        disabled={submodule && submodule.type !== null ? true : false}
        selectedStatus={selectedStatus}
        dataSlide={
          dataSlide
            ? filter === 'active'
              ? dataSlide.filter(item => item.isActive)
              : filter === 'inactive'
              ? dataSlide.filter(item => !item.isActive)
              : dataSlide
            : []
        }
        handleRefresh={handleRefresh}
        handleAdd={handleAdd}
        isLoading={
          isLoadingSlide ||
          isRefetchSlide ||
          mutateDelete.isLoading ||
          mutateSlideStatus.isLoading
        }
        setSelectedStatus={setSelectedStatus}
        handleApplyfilter={handleApplyfilter}
      />
      <SlideModalContainer
        stage={stage}
        submodule={submodule}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
      />
    </>
  );
}

import * as _ from 'lodash';

export const booleanEnums = [
  {
    id: true,
    name: 'True',
  },
  {
    id: false,
    name: 'False',
  },
];
export const contestType = [
  {
    id: 'AmazingJourney',
    name: 'Amazing Journey',
  },
  {
    id: 'MDRT',
    name: 'MDRT',
  },
];
export const contestGroup = [
  {
    id: 'Producer',
    name: 'Producer',
  },
  {
    id: 'Group',
    name: 'Group',
  },
];

export const contestLevel = [
  {
    id: 'Rookie',
    name: 'Rookie',
  },
  {
    id: 'NonRookie',
    name: 'Non Rookie',
  },
];

export const contestChanel = [
  {
    id: 'Agency',
    name: 'Agency',
  },
  {
    id: 'Banccas',
    name: 'Banccas',
  },
];

export const progressStatus = [
  {
    id: 'QAClean',
    name: 'QA Clean',
  },
  {
    id: 'UploadPaymentSlip',
    name: 'Uploading Payment Slip',
  },
  {
    id: 'PaymentSlipChecking',
    name: 'Payment Slip Checking',
  },
  {
    id: 'WaitExamResult',
    name: 'Waiting For Exam Result',
  },
  {
    id: 'ExamPassed',
    name: 'Exam Passed',
  },
  {
    id: 'CPDTraining',
    name: 'CPD Training',
  },
  {
    id: 'WaitForZELTraining',
    name: 'Waiting For ZEL Training',
  },
  {
    id: 'NeedToActivate',
    name: 'DOSD Need to Activate',
  },
];

export const licenseStatus = [
  {
    id: 'NOLICENSE',
    name: 'No License',
  },
  {
    id: 'EXPIRED',
    name: 'Expired License',
  },
  {
    id: 'MUTASI',
    name: 'Mutation',
  },
];

export const notesType = [
  {
    id: 'template',
    name: 'Template',
  },
  {
    id: 'custom',
    name: 'Custom',
  },
];
export const notesTemplates = [
  {
    id: 'TANDA TANGAN PADA KTP DAN APLIKASI BERBEDA. KIRIM ULANG APLIKASI DENGAN TANDA TANGAN YANG SESUAI KTP',
    name: 'TANDA TANGAN PADA KTP DAN APLIKASI BERBEDA. KIRIM ULANG APLIKASI DENGAN TANDA TANGAN YANG SESUAI KTP',
  },
  {
    id: 'BUKTI SETOR AAJI BURAM. KIRIM ULANG BUKTI SETOR AAJI YANG JELAS',
    name: 'BUKTI SETOR AAJI BURAM. KIRIM ULANG BUKTI SETOR AAJI YANG JELAS',
  },
  {
    id: 'BUKTI SETOR AAJI TIDAK DI LAMPIRKAN. KIRIM BUKTI SETOR AAJI',
    name: 'BUKTI SETOR AAJI TIDAK DI LAMPIRKAN. KIRIM BUKTI SETOR AAJI',
  },
  {
    id: 'NOMOR KTP DAN NAMA AGENT BERBEDA DI AAJI. KIRIM AKTE KELAHIRAN/IJAZAH TERAKHIR DAN KARTU KELUARGA UNTUK VERIFIKASI AAJI',
    name: 'NOMOR KTP DAN NAMA AGENT BERBEDA DI AAJI. KIRIM AKTE KELAHIRAN/IJAZAH TERAKHIR DAN KARTU KELUARGA UNTUK VERIFIKASI AAJI',
  },
];

export const trackerType = [
  {
    id: 'module/tracking',
    name: 'Most visited menu',
  },
  {
    id: 'initiallogin',
    name: 'Unique login',
  },
  {
    id: 'useraccess/summary',
    name: 'Login tracker summary',
  },
  {
    id: 'useraccess/detail',
    name: 'Login tracker detail',
  },
];
export const dosdListOnProgress = [
  {
    id: 'QA Not Clean',
    name: 'QA Not Clean',
  },
  {
    id: 'CPD Training',
    name: 'CPD Training',
  },
  {
    id: 'Exam Passed',
    name: 'Exam Passed',
  },
  {
    id: 'Uploading Payment Slip',
    name: 'Uploading Payment Slip',
  },
];
export const dosdListToDoList = [
  {
    id: 'AAJI Screening',
    name: 'AAJI Screening',
  },
  {
    id: 'QA Process',
    name: 'QA Process',
  },
  {
    id: 'QA Clean',
    name: 'QA Clean',
  },
  {
    id: 'Payment Slip Checking',
    name: 'Payment Slip Checking',
  },
  {
    id: 'Waiting For Exam Result',
    name: 'Waiting For Exam Result',
  },
  {
    id: 'Pending Update',
    name: 'Pending Update',
  },
];
export const dosdListCompleted = [
  {
    id: 'Rejected By DOSD',
    name: 'Rejected By DOSD',
  },
  {
    id: 'Rejected By System',
    name: 'Rejected By System',
  },
  {
    id: 'Agent Activated',
    name: 'Agent Activated',
  },
];
export const listJourneyCandidate = [
  {
    id: 'AAJI Screening',
    name: 'AAJI Screening',
  },
  {
    id: 'QA Process',
    name: 'QA Proses',
  },
  {
    id: 'QA Not Clean',
    name: 'QA Not Clean',
  },
  {
    id: 'QA Clean',
    name: 'QA Clean',
  },
  {
    id: 'Pending Update',
    name: 'Pending Update',
  },
];
export const candidateLevel = [
  {
    id: 'FC',
    name: 'FC',
  },
  {
    id: 'AM',
    name: 'AM',
  },
  {
    id: 'SAM',
    name: 'SAM',
  },
  {
    id: 'AD',
    name: 'AD',
  },
];
export const fileNet = [
  {
    id: 'Foto KTP',
    name: 'Foto KTP',
  },
  {
    id: 'Foto Selfie',
    name: 'Foto Selfie',
  },
  {
    id: 'Unggahan Screenshot AAJI',
    name: 'Unggahan Screenshot AAJI',
  },
  {
    id: 'Pas Foto Berwarna 3x4',
    name: 'Pas Foto Berwarna 3x4',
  },
  {
    id: 'Buku Tabungan',
    name: 'Buku Tabungan',
  },
  {
    id: 'NPWP',
    name: 'NPWP',
  },
  {
    id: 'Kartu Keluarga',
    name: 'Kartu Keluarga',
  },
  {
    id: 'Surat Referensi Dari Perusahaan Asuransi Sebelumnya',
    name: 'Surat Referensi Dari Perusahaan Asuransi Sebelumnya',
  },
  {
    id: 'Surat Kelurahan',
    name: 'Surat Kelurahan',
  },
  {
    id: 'Lampiran Tambahan 1',
    name: 'Lampiran Tambahan 1',
  },
  {
    id: 'Lampiran Tambahan 2',
    name: 'Lampiran Tambahan 2',
  },
  {
    id: 'Lampiran Tambahan 3',
    name: 'Lampiran Tambahan 3',
  },
  {
    id: 'Lampiran Tambahan 4',
    name: 'Lampiran Tambahan 4',
  },
  {
    id: 'Lampiran Tambahan 5',
    name: 'Lampiran Tambahan 5',
  },
  {
    id: 'Upload Payment Slip',
    name: 'Upload Payment Slip',
  },
  {
    id: 'File Document PrivyID',
    name: 'File Document PrivyID',
  },
  {
    id: 'Tanda Tangan Kandidat',
    name: 'Tanda Tangan Kandidat',
  },
  {
    id: 'Tanda Tangan Direct Leader',
    name: 'Tanda Tangan Direct Leader',
  },
  {
    id: 'Tanda Tangan Agency Director',
    name: 'Tanda Tangan Agency Director',
  },
  {
    id: 'Tanda Tangan AGAD',
    name: 'Tanda Tangan AGAD',
  },
];
export const agreementType = [
  {
    id: 'agencyAplication',
    name: 'Aplikasi Keagenan',
    documentMustbeUploaded: 1,
  },
  {
    id: 'zurichAgentTopasLife',
    name: 'Agent Zurich Topas Life',
    documentMustbeUploaded: 4,
  },
  {
    id: 'agencyLeader',
    name: 'Agency Leader',
    documentMustbeUploaded: 3,
  },
];
export const createCategoryList = schema => {
  const listCategory: any = [];
  const listKey = Object.keys(schema);
  for (const iterator of listKey) {
    if (iterator !== 'useragreements') {
      listCategory.push({
        id: `${iterator}`,
        name: schema[iterator]['title'],
      });
    }
  }
  return listCategory;
};

export const createPathList = (categoryValue, schema) => {
  const listPath: any = [];
  const detailSchema = _.get(schema, `${categoryValue}.properties`);
  const listKey = Object.keys(detailSchema);
  for (const iterator of listKey) {
    if (categoryValue === 'documents') {
      listPath.push({
        id: `${categoryValue}.properties.${iterator}`,
        name: detailSchema[iterator]['title'],
      });
    } else {
      const section = detailSchema[iterator]['properties'];
      const listKeySection = Object.keys(section);
      for (const data of listKeySection) {
        if (section[data]['type'] === 'object') {
          const detailSection = section[data]['properties'];
          const listKeySection = Object.keys(detailSection);
          for (const element of listKeySection) {
            const fieldPath = _.get(
              schema,
              `${categoryValue}.properties.${iterator}.properties.${data}.properties.${element}`
            );
            const sectionPath = _.get(
              schema,
              `${categoryValue}.properties.${iterator}`
            );
            listPath.push({
              id: `${categoryValue}.properties.${iterator}.properties.${data}.properties.${element}`,
              name: `${sectionPath['title']} - ${fieldPath['title']}`,
            });
          }
        } else {
          const fieldPath = _.get(
            schema,
            `${categoryValue}.properties.${iterator}.properties.${data}`
          );
          const sectionPath = _.get(
            schema,
            `${categoryValue}.properties.${iterator}`
          );
          listPath.push({
            id: `${categoryValue}.properties.${iterator}.properties.${data}`,
            name: `${sectionPath['title']} - ${fieldPath['title']}`,
          });
        }
      }
    }
  }
  return listPath;
};
export const pendingRemarks = [
  {
    id: 'template',
    name: 'Template Catatan',
  },
  {
    id: 'custom',
    name: 'Kustomisasi Catatan',
  },
];
export const templatePendingRemarks = list => {
  const newList: any = [];
  for (const iterator of list) {
    const { pathForm, note } = iterator;
    newList.push({ id: `${pathForm}_${note}`, name: note });
  }
  return newList;
};
export const reportType = [
  {
    id: 'leadInvitationReport',
    name: 'Lead Invitation Report',
  },
  {
    id: 'leadReport',
    name: 'Lead Report',
  },
  {
    id: 'notificationReport',
    name: 'Notification Report',
  },
  {
    id: 'applicationNumberReport',
    name: 'Application Number Report',
  },
];
export const notificationFilterType = [
  {
    id: 1,
    name: 'Surrender Policy',
  },
  {
    id: 2,
    name: 'Overdue Policy',
  },
  {
    id: 3,
    name: 'No Premium Payment',
  },
  {
    id: 4,
    name: 'Insufficient Investment Fund',
  },
  {
    id: 5,
    name: 'Death Claim',
  },
  {
    id: 6,
    name: 'Payment Failure',
  },
];

export const formatTracker = [
  {
    id: 'xlsx',
    name: 'Excel',
  },
  {
    id: 'pdf',
    name: 'PDF',
  },
];
export const formatReport = [
  {
    id: 'xlsx',
    name: 'Excel',
  },
];
export const paramterTypeConfig = [
  {
    id: 'string',
    name: 'String',
  },
  {
    id: 'int',
    name: 'Integer',
  },
];
export const contestStatus = [
  {
    id: 'Upcoming',
    name: 'Upcoming',
  },
  {
    id: 'Current',
    name: 'Current',
  },
  {
    id: 'Completed',
    name: 'Completed',
  },
  {
    id: 'Droped',
    name: 'Droped',
  },
];
export const progressStatusUpload = [
  {
    id: 'InQueue',
    name: 'In Queue',
  },
  {
    id: 'InProgress',
    name: 'In Progress',
  },
  {
    id: 'Success',
    name: 'Success',
  },
  {
    id: 'Failed',
    name: 'Failed',
  },
];
export const dataTableNumberIndex = {
  headerClassName: 'headerDatatableClass',
  Header: 'No',
  accessor: 'No',
  className: 'numberIndexDatatable',
  Cell: row => parseInt(row.row.id, 10) + 1,
  width: 35,
};
export const formatTime = 'HH:mm:ss';
export const formatDate = 'YYYY-MM-DD';
export const qaAgentType = [
  {
    id: 'Karyawan Baru',
    name: 'Karyawan Baru',
  },
  {
    id: 'Banking Hire',
    name: 'Banking Hire',
  },
  {
    id: 'Industry Hire',
    name: 'Industry Hire',
  },
];
export const qaAgentClassification = [
  {
    id: 'Humming Bird 1',
    name: 'Humming Bird 1',
  },
  {
    id: 'Humming Bird 2',
    name: 'Humming Bird 2',
  },
  {
    id: 'Existing',
    name: 'Existing',
  },
];
export const worldCheck = [
  {
    id: 'HIT',
    name: 'HIT',
  },
  {
    id: 'NO HIT',
    name: 'NO HIT',
  },
];
export const monthList = [
  {
    id: '01',
    name: 'Januari',
  },
  {
    id: '02',
    name: 'Februari',
  },
  {
    id: '03',
    name: 'Maret',
  },
  {
    id: '04',
    name: 'April',
  },
  {
    id: '05',
    name: 'Mei',
  },
  {
    id: '06',
    name: 'Juni',
  },
  {
    id: '07',
    name: 'Juli',
  },
  {
    id: '08',
    name: 'Agustus',
  },
  {
    id: '09',
    name: 'September',
  },
  {
    id: '10',
    name: 'Oktober',
  },
  {
    id: '11',
    name: 'November',
  },
  {
    id: '12',
    name: 'Desember',
  },
];
export const yearList = () => {
  const d = new Date();
  const n = d.getFullYear();
  const x = n + 40;
  const yearData: any = [];
  for (let index = x; index >= x - 80; index--) {
    const element = {
      id: index,
      name: index,
    };
    yearData.push(element);
  }
  return yearData;
};

export const listJourney = [
  {
    id: 'directLeaderApproval',
    name: 'Persetujuan Direct Leader',
  },
  {
    id: 'agencyDirectorApproval',
    name: 'Persetujuan Agency Director',
  },
  {
    id: 'AGADApproval',
    name: 'Persetujuan ABD',
  },
  {
    id: 'RejectByDirectLeader',
    name: 'Rejected By DL',
  },
  {
    id: 'RejectByAD',
    name: 'Rejected By AD',
  },
  {
    id: 'RejectByAGAD',
    name: 'Rejected by ABD',
  },
  {
    id: 'RejectByDosd',
    name: 'Rejected By DOSD',
  },
  {
    id: 'RejectBySystem',
    name: 'Rejected By System',
  },
  {
    id: 'AAJIScreening',
    name: 'AAJI Screening',
  },
  {
    id: 'QAPROCESS',
    name: 'QA Process',
  },
  {
    id: 'NOTCLEAN',
    name: 'QA Not Clean',
  },
  {
    id: 'PendingUpdate',
    name: 'Pending Update',
  },
  {
    id: 'QAClean',
    name: 'QA Clean',
  },
  {
    id: 'UploadPaymentSlip',
    name: 'Uploading Payment Slip',
  },
  {
    id: 'PaymentSlipChecking',
    name: 'Payment Slip Checking',
  },
  {
    id: 'WaitExamResult',
    name: 'Waiting For Exam ',
  },
  {
    id: 'ExamPassed',
    name: 'Exam Passed',
  },
  {
    id: 'CPDTraining',
    name: 'CPD Training',
  },
  {
    id: 'WaitForZELTraining',
    name: 'Waiting For Training',
  },
  {
    id: 'NeedToActivate',
    name: 'DOSD Need to Activated',
  },
  {
    id: 'AgentActivated',
    name: 'Agent Activated',
  },
];
export const typePrivyStatement = [
  {
    id: 'Leader',
    name: 'Leader',
  },
  {
    id: 'NonLeader',
    name: 'Non Leader',
  },
];
export const typeAgreement = [
  {
    id: 'Leader',
    name: 'Leader',
  },
  {
    id: 'NonLeader',
    name: 'NonLeader',
  },
];
export const downloadType = [
  {
    id: 'xls',
    name: 'Excel (.xls)',
  },
];

export const filterStatus = [
  {
    id: 'active',
    name: 'Aktif',
  },
  {
    id: 'inactive',
    name: 'Tidak Aktif',
  },
];
export const stageStatus = [
  {
    id: 'active',
    name: 'Aktif',
  },
  {
    id: 'inactive',
    name: 'Tidak Aktif',
  },
];
export const classType = [
  {
    id: 'private',
    name: 'Private',
  },
  {
    id: 'public',
    name: 'Non Private',
  },
];
export const classTrainingType = [
  {
    id: 'roadmap',
    name: 'Roadmap',
  },
  {
    id: 'nonroadmap',
    name: 'Non Roadmap',
  },
];
export const classParticipantType = [
  {
    id: 'LEVEL',
    name: 'By Level Agent',
  },
  {
    id: 'LOS',
    name: 'LOS (Length Of Service)',
  },
  {
    id: 'MANUAL',
    name: 'Add Manually (One By One)',
  },
];
export const classScheduleType = [
  {
    id: 'online',
    name: 'Online',
  },
  {
    id: 'onsite',
    name: 'Onsite',
  },
];
export const classParticipantLevel = [
  { label: 'FC', value: 'A1' },
  { label: 'AM', value: 'A2' },
  { label: 'SAM', value: 'A3' },
  { label: 'AD', value: 'A4' },
];
export const isCertificate = [
  {
    id: 'true',
    name: 'Bersertifikat',
  },
  {
    id: 'false',
    name: 'Tidak Bersertifikat',
  },
];
export const elearningStatus = [
  {
    id: 'true',
    name: 'Aktif',
  },
  {
    id: 'false',
    name: 'Tidak Aktif',
  },
];
export const agnetLevel = [
  {
    id: 'A1_FC',
    name: 'AG-Financial Consultant (FC)',
  },
  {
    id: 'A2_AM',
    name: 'AG-Agency Manager (AM)',
  },
  {
    id: 'A3_SAM',
    name: 'AG-Senior Agency Manager (SAM)',
  },
  {
    id: 'A4_AD',
    name: 'AG-Agency Director (AD)',
  },
];
export const additionalCondition = [
  {
    id: 'AfterPassed',
    name: 'After Passed',
  },
  {
    id: 'AfterAssigned',
    name: 'After Assigned',
  },
  {
    id: 'AfterTakes',
    name: 'After Takes',
  },
];

export const contentLevel = [
  {
    id: 'Channel',
    name: 'Channel',
  },
  {
    id: 'Stage',
    name: 'Stage',
  },
  {
    id: 'Module',
    name: 'Module',
  },
  {
    id: 'SubModule',
    name: 'Sub Module',
  },
];
export const learningReportType = [
  {
    id: 'journey',
    name: 'Journey Training Report',
  },
  {
    id: 'progress',
    name: 'Progress Report',
  },
  {
    id: 'exam',
    name: 'Exam Report',
  },
  {
    id: 'selfLearning',
    name: 'Feedback Self Learning Report',
  },
  {
    id: 'nonSelfLearning',
    name: 'Feedback Non-Self Learning Report',
  },
  {
    id: 'sessionPin',
    name: 'Report PIN Sesi',
  },
  {
    id: 'trainingAttendance',
    name: 'Training Attendance Report',
  },
  {
    id: 'learningHour',
    name: 'Report Learning Hour',
  },
];
export const formatReportFeedback = [
  {
    id: 'summary',
    name: 'Summary Report (.xls)',
  },
  {
    id: 'detail',
    name: 'Detail Report (.xls)',
  },
];
export const platformOnlineType = [
  { id: 'Zoom', name: 'Zoom' },
  { id: 'MsTeams', name: 'Microsoft Teams' },
  { id: 'Gmeet', name: 'Google Meet' },
  { id: 'Webex', name: 'Webex' },
  { id: 'GDuo', name: 'GDuo' },
  { id: 'Jitsy', name: 'Jitsy' },
  { id: 'Facebook', name: 'Facebook' },
];
export const classCompletionStatus = [
  {
    id: 'not_started',
    name: 'Not Started',
  },
  {
    id: 'on_progress',
    name: 'In Progress',
  },
  {
    id: 'completed',
    name: 'Completed',
  },
];
export const classResult = [
  {
    id: 'passed',
    name: 'Passed',
  },
  {
    id: 'failed',
    name: 'Failed',
  },
];
export const classScheduleReportType = [
  {
    id: 'ONLINE',
    name: 'Online',
  },
  {
    id: 'ONSITE',
    name: 'Onsite',
  },
];

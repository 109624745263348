import CButton from '../../../../Assets/Components/CButton';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { FormRenderProps } from 'react-final-form';
import React from 'react';
import { Steps } from 'antd';
import submoduleValidation from '../../Validation/submoduleValidation';
import CIcon from '../../../../Assets/Components/CIcon';

interface IProps {
  currentSteps: number;
  disabledTabs: (index: number, steps: number) => boolean;
  editMode: boolean;
  handleBack: () => void;
  handleCancel: () => void;
  handleEditClick: () => void;
  handleNext: () => void;
  handleStep: (step: any) => void;
  initialValues: any;
  isLoading: boolean;
  isLoadingSubmit: boolean;
  modalAction: string;
  onSubmit: (val: any) => void;
  steps: any;
  submodule: any;
}

const { Step } = Steps;

export default function SubmoduleModalComponent(props: IProps) {
  const {
    currentSteps,
    editMode,
    handleBack,
    handleCancel,
    handleEditClick,
    handleNext,
    handleStep,
    initialValues,
    isLoading,
    isLoadingSubmit,
    modalAction,
    onSubmit,
    steps,
    submodule,
  } = props;

  const renderTitle = () => {
    if (modalAction === 'register') return 'Sub-Module Baru';
    else if (modalAction === 'update') return 'Edit Sub-Module';
    else return 'Sub-Module Baru (Duplicate)';
  };
  const headerComponent = () => {
    return <h3 className="headerTitle capital">{renderTitle()}</h3>;
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { form, errors, values } = propsContent;
    return (
      <div>
        <div className="containerStepSubModul">
          <Steps
            labelPlacement="vertical"
            className="stepsSubModule"
            current={currentSteps}
            onChange={handleStep}
          >
            {steps.map((step: any) => (
              <Step
                key={step.title}
                title={step.title}
                disabled={step.disabled}
              />
            ))}
          </Steps>
        </div>
        <div className="containerContentStepSubModul">
          <h2 className="TitleStepsSubModul">{steps[currentSteps].title}</h2>
          {React.cloneElement(steps[currentSteps].content, {
            form,
            formError: errors,
            formValues: values,
          })}
        </div>
      </div>
    );
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { errors, values } = propsFooter;
    const currentTabModal = steps[currentSteps].id;
    let disabledNext = true;
    let disabledSubmit = errors?.[currentTabModal] ? true : false;
    if (currentTabModal === 'exam') {
      if (!values.exam) disabledNext = false;
      else disabledNext = errors?.[currentTabModal] ? true : false;
    } else if (currentTabModal === 'startTime') {
      if (!values.startTime) disabledNext = false;
      else disabledNext = errors?.[currentTabModal] ? true : false;
    } else if (currentTabModal === 'expiredTime') {
    } else disabledNext = errors?.[currentTabModal] ? true : false;

    const dataButtonModalSubModule = [
      currentSteps !== 0
        ? {
            type: 'primary',
            content: 'Kembali',
            id: 'btnBackForm',
            className: 'btnBackRegsitration',
            icon: 'ArrowLeftOutlined',
            onClick: handleBack,
          }
        : null,
      modalAction === 'update' && !editMode
        ? {
            type: 'primary',
            content: 'Edit',
            id: 'btnEditForm',
            className: 'btnEditRegsitration',
            icon: 'EditOutlined',
            onClick: handleEditClick,
          }
        : null,
      currentSteps < steps.length - 1
        ? {
            type: 'primary',
            content: (
              <div>
                Lanjutkan{' '}
                <CIcon
                  type="ArrowRightOutlined"
                  style={{ marginLeft: '3px' }}
                />
              </div>
            ),
            id: 'btnNextForm',
            className: 'btnNextRegistration',
            onClick: handleNext,
            disabled: disabledNext,
          }
        : null,
      currentSteps === steps.length - 1 &&
      ((modalAction === 'update' && editMode) ||
        modalAction === 'register' ||
        modalAction === 'copy')
        ? {
            type: 'primary',
            content: modalAction !== 'update' ? 'Create' : 'Simpan',
            id: 'btnSaveForm',
            className: 'btnSaveSubmodule',
            onClick: () => onSubmit(values),
            disabled: disabledSubmit,
          }
        : null,
    ];

    return (
      <div className="ContainerButtonSubModule">
        <CButton
          buttonData={dataButtonModalSubModule}
          isLoading={isLoadingSubmit}
        />
      </div>
    );
  };

  return (
    <CModalRFF
      modalIsShow={true}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={(values: any) =>
        submoduleValidation({
          values,
          submodule,
          detailSubmodule: initialValues,
        })
      }
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
      isLoading={isLoading}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '80vw',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

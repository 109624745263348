import { Col, Row } from 'antd';
import React from 'react';
import { Field } from 'react-final-form';
import { singleCheckbox } from '../../../../../Assets/Components/CCheckbox';
import {
  InputText,
  InputTextNumber,
  TextNumberInput,
} from '../../../../../Assets/Components/CInput';
import { InputDatePicker } from '../../../../../Assets/Components/CInputDate';
import { RadioButton } from '../../../../../Assets/Components/CRadio';
import Select from '../../../../../Assets/Components/Select';

interface IProps {
  disabled: boolean;
  predecessorList: any[];
  handleAddtionalOnChangeSequence: (val: any) => void;
  handleAddtionalSelectPredecessor: (val: any) => void;
  formValues?: any;
}

export default function SubmoduleModalGeneralComponent({
  predecessorList,
  disabled,
  formValues,
  handleAddtionalOnChangeSequence,
  handleAddtionalSelectPredecessor,
}: IProps) {
  const statusIsPublish = () => {
    if (formValues?.general?.isPublish === true) return 'Active';
    else return 'Not Active';
  };

  const version = [
    {
      id: '1.0',
      name: '1.0',
    },
    {
      id: 'custom',
      component: (
        <React.Fragment>
          <Field
            name="general.versionFirst"
            component={TextNumberInput}
            className="inputNumberSubModuleInline"
            min={0}
            disabled={disabled}
          />
          <Field
            name="general.versionSecond"
            component={TextNumberInput}
            className="inputNumberSubModuleInline"
            min={0}
            disabled={disabled}
          />
          <span>Custom</span>
        </React.Fragment>
      ),
    },
  ];
  return (
    <div className="containerGridTwo">
      <div>
        <Field
          name={'general.name'}
          component={InputText}
          label="Sub-module Name"
          className="inputSubModul"
          idComponent="inputName"
          placeholder="Type Here"
          labelIsRequired={true}
          disabled={disabled}
        />
        <Field
          name={'general.description'}
          component={InputText}
          label="Description"
          className="inputSubModul"
          idComponent="inputDescription"
          placeholder="Type Here"
          disabled={disabled}
        />
        <div className="containerInputTwo">
          <Field
            name={'general.predecessor'}
            component={Select}
            label="Predecessor"
            className="inputSubModul"
            dataOption={predecessorList}
            defaultItemName="-Select-"
            idComponent="inputPredecessor"
            onSelect={handleAddtionalSelectPredecessor}
            disabled={disabled}
          />
          <Field
            name={'general.sequence'}
            component={InputTextNumber}
            label="Sequence"
            className="inputSubModul"
            idComponent="inputSequence"
            addtionalOnChange={handleAddtionalOnChangeSequence}
            disabled={disabled}
            min={1}
          />
        </div>
        <Field
          name={'general.version'}
          component={RadioButton}
          label="Version"
          dataOption={version}
          idComponent="inputVersion"
          layout="vertical"
          disabled={disabled}
          labelIsRequired={true}
        />
        {/* <Field
          name={'general.isRefreshment'}
          component={singleCheckbox}
          label="Refreshment"
          idComponent="inputRefreshment"
          disabled={disabled}
          type="checkbox"
        /> */}
      </div>
      <div>
        <div className="containerBackgroundInputSubModul">
          <Field
            name={'general.effectiveDate'}
            component={InputDatePicker}
            label="Effective Date"
            className="inputSubModul"
            idComponent="inputEffectiveDate"
            typeDate="dateRangePicker"
            disabled={disabled}
            labelIsRequired={true}
          />
          <p className="textBold">Publish Status</p>
          <Row>
            <Col span={2}>
              <Field
                name={'general.isPublish'}
                component={singleCheckbox}
                idComponent="inputPublish"
                disabled={disabled}
                type="checkbox"
              />
            </Col>
            <Col span={8}>
              <span className="labelInfoStatus">{statusIsPublish()}</span>
            </Col>
          </Row>
        </div>
        {/* <div className="containerBackgroundInputSubModul">
          <Field
            name={'general.isCpdStatus'}
            component={singleCheckbox}
            label="CPD Status"
            idComponent="inputCPDStatus"
            disabled={disabled}
            type="checkbox"
          />
          <Field
            name={'general.cpdRefreshName'}
            component={InputText}
            label="CPD Refresh Name"
            className="inputSubModul"
            idComponent="inputcpdRefreshName"
            placeholder="Type Here"
            disabled={disabled}
          />
        </div> */}
      </div>
    </div>
  );
}

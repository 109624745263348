import { useQuery, useMutation } from '@tanstack/react-query';
import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import CIcon from '../../../Assets/Components/CIcon';
import {
  downloadHistoryTrainingProcessAPI,
  downloadUpcomongTrainingProcessAPI,
  getDetailTrainerAPI,
  getHistoryTrainingProcessAPI,
  getUpcomingTrainingProcessAPI,
} from '../../../Services/Dashboard/dashboard.api';
import DashboardTrainerComponent from '../Component/DashboardTrainerComponent';
import ErrorHandler from '../../../App/ErrorHandler';

export default function DashboardTrainerContainer() {
  const {
    data: dataUpcomingTraining,
    isLoading: isLoadingUpcomingTraining,
    refetch: refetchUpcomingTraining,
  } = useQuery(
    ['getUpcomingTrainingDashboardAPI'],
    getUpcomingTrainingProcessAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  const {
    data: dataHistoryTraining,
    isLoading: isLoadingHistoryTraining,
    refetch: refetchHistoryTraining,
  } = useQuery(
    ['getHistoryTrainingDashboardAPI'],
    getHistoryTrainingProcessAPI,
    { staleTime: Infinity, refetchOnWindowFocus: false }
  );

  const { data: dataTrainer, isLoading: isLoadingTrainer } = useQuery(
    ['getDetailTrainerAPI'],
    getDetailTrainerAPI,
    {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  const downloadHistoryTraining = useMutation(
    downloadHistoryTrainingProcessAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    }
  );

  const downloadUpcomongTraining = useMutation(
    downloadUpcomongTrainingProcessAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    }
  );

  const handleRefresh = (tabKey: string) => {
    if (tabKey === 'upcomingTraining') refetchUpcomingTraining();
    else refetchHistoryTraining();
  };

  const handleDownload = (tabKey: string, values: any) => {
    if (tabKey === 'upcomingTraining')
      downloadUpcomongTraining.mutate({ downloadType: values.downloadType });
    else downloadHistoryTraining.mutate({ downloadType: values.downloadType });
  };

  const renderCol = (label: string, content) => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={3}>
          <b>{label}</b>
        </Col>
        <Col span={8}>{': ' + content}</Col>
      </Row>
    );
  };

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Nama Kelas',
      accessor: 'classname',
    },
    {
      Header: 'Trainer',
      accessor: 'trainer',
    },
    {
      Header: 'Tanggal',
      accessor: 'trainingDate',
      disableSort: true,
      Cell: row =>
        row.row.original.trainingDate
          ? moment(row.row.original.trainingDate).format('DD-MMM-YYYY')
          : '-',
    },
    {
      Header: 'Tempat',
      accessor: 'trainingPlace',
    },
    {
      Header: 'Type',
      accessor: 'type',
    },
  ];

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { participants } = row.original;
    return <div>{renderCol('Peserta', participants)}</div>;
  }, []);

  return (
    <DashboardTrainerComponent
      isLoading={
        isLoadingUpcomingTraining ||
        isLoadingHistoryTraining ||
        isLoadingTrainer
      }
      columnData={columnData}
      renderRowSubComponent={renderRowSubComponent}
      listUpcomingTraining={dataUpcomingTraining || []}
      listHistoryTraining={dataHistoryTraining || []}
      handleRefresh={handleRefresh}
      handleDownload={handleDownload}
      detailTrainer={dataTrainer}
    />
  );
}

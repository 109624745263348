import React from 'react';
import { useQuery } from '@tanstack/react-query';
import CityListTabComponent from '../Component/CityListTabComponent';
import { getCityListAPI } from '../../../Services/city.api';

interface IProps {
  intl: any;
}

export default function CityListTabContainer(props: IProps) {
  const { intl } = props;

  const {
    data: cityList = [],
    fetchStatus,
    isLoading,
    isRefetching,
    refetch: refetchList,
  } = useQuery(['getCityList'], getCityListAPI, {
    refetchOnWindowFocus: false,
  });

  const columnData = [
    {
      Header: 'City',
      accessor: 'name',
    },
  ];

  return (
    <CityListTabComponent
      columnData={columnData}
      intl={intl}
      handleRefresh={refetchList}
      cityList={cityList}
      isLoadingList={fetchStatus !== 'idle' && (isLoading || isRefetching)}
    />
  );
}

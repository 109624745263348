const submoduleValidation = ({
  values,
  submodule,
  detailSubmodule,
}: {
  values: any;
  submodule: any;
  detailSubmodule: any;
}) => {
  let errors: any = {};
  let general: any = {};
  let exam: any = {};
  let startTime: any = {};
  let expiredTime: any = {};

  // General validation
  if (!values?.general?.name) general.name = 'Sub-module Name Wajib Diisi!';
  if (values?.general?.name && !/^[^\s].*/.test(values?.general?.name))
    general.name = 'Use letters/numbers at the beginning of words!';
  if (
    values?.general?.description &&
    !/^[^\s].*/.test(values?.general?.description)
  )
    general.description = 'Use letters/numbers at the beginning of words!';
  if (values?.general?.sequence && !values?.general?.predecessor)
    general.predecessor = 'Predecessor Wajib Diisi!';
  // if (
  //   !detailSubmodule ||
  //   (detailSubmodule &&
  //     detailSubmodule.general.predecessor !== values.general.predecessor)
  // ) {
  //   for (const item of submodule) {
  //     if (
  //       values.general.predecessor === item.predecessor &&
  //       item.isPublish === true
  //     )
  //       general.predecessor = 'Predecessor sudah digunakan';
  //   }
  // }
  if (!values?.general?.effectiveDate)
    general.effectiveDate = 'Effective Date Wajib Diisi!';
  if (!values?.general?.version) general.version = 'Version Wajib Diisi!';
  else if (
    values?.general?.version === 'custom' &&
    !values?.general?.versionFirst &&
    !values?.general?.versionSecond
  )
    general.version = 'Version Wajib Diisi!';
  if (values?.general?.isCpdStatus === true && !values?.general?.cpdRefreshName)
    general.cpdRefreshName = 'CPD Refreshment Name Wajib Diisi!';

  // Exam validation
  if (values.exam && values.exam.type) {
    if (!values.exam.questionLimit)
      exam.questionLimit = 'Question Limit Wajib Diisi!';
    if (!values.exam.duration) exam.duration = 'Duration Wajib Diisi!';
    if (!values.exam.minimumScore)
      exam.minimumScore = 'Minimum Score Wajib Diisi!';
    if (values.exam.type === 'Exam' && !values.exam.maxAttempt)
      exam.maxAttempt = 'Maximum Attempt Wajib Diisi!';
  }

  // Start time validation
  if (values.startTime) {
    if (values.startTime.otherCondition) {
      const conditions = values.startTime.otherCondition
        ? [...values.startTime.otherCondition]
        : [];
      conditions.forEach(item => {
        if (item === 'joinDate') {
          if (!values.startTime.joinDateCondition)
            startTime.otherCondition = 'Join Date Condition Wajib Diisi!';
          if (!values.startTime.joinDateDays)
            startTime.otherCondition = 'Join Date Wajib Diisi!';
        } else if (item === 'licenseDate') {
          if (!values.startTime.licenseDateCondition)
            startTime.otherCondition = 'License Date Condition Wajib Diisi!';
          if (!values.startTime.licenseDateDays)
            startTime.otherCondition = 'License Date Wajib Diisi!';
        } else if (item === 'after') {
          if (!values.startTime.afterCondition)
            startTime.otherCondition = 'After Condition Wajib Diisi!';
          if (!values.startTime.afterDateDays)
            startTime.otherCondition = 'After Wajib Diisi!';
        } else return;
      });
    }
    if (values.startTime.period) {
      if (!values.startTime.condition)
        startTime.condition = 'Condition Wajib Diisi!';
      if (!values.startTime.contentLevel)
        startTime.contentLevel = 'Content Level Wajib Diisi! ';
      if (!values.startTime.contentName)
        startTime.contentName = 'Content Name Wajib Diisi! ';
    }
  }

  // Expired time validation
  if (values.expiredTime) {
    if (values.expiredTime.otherCondition) {
      const conditions = values.expiredTime.otherCondition
        ? [...values.expiredTime.otherCondition]
        : [];
      conditions.forEach(item => {
        if (item === 'expiredAfter') {
          if (!values.expiredTime.expiredAfterCondition)
            expiredTime.otherCondition =
              'Expired After Date Condition Wajib Diisi!';
          if (!values.expiredTime.expiredDays)
            expiredTime.otherCondition = 'Expired After days Wajib Diisi!';
        } else return;
      });
    }
    if (values.expiredTime.period) {
      if (!values.expiredTime.condition)
        expiredTime.condition = 'Condition Wajib Diisi!';
      if (!values.expiredTime.contentLevel)
        expiredTime.contentLevel = 'Content Level Wajib Diisi! ';
      if (!values.expiredTime.contentName)
        expiredTime.contentName = 'Content Name Wajib Diisi! ';
    }
  }

  errors = { general, exam };
  return errors;
};

export default submoduleValidation;

const FeedbackBankValidation = (values: any) => {
  const errors: any = {};
  const { channel, category, freeTextQuestionTitle, multipleQuestionTitle } =
    values;

  if (!channel) errors.channel = 'Channel Wajib Diisi!';

  if (!category) errors.category = 'Category Wajib Diisi!';
  else if (category && !/^[^\s].*/.test(category))
    errors.category = 'Gunakan huruf/angka di awal kata!';

  if (multipleQuestionTitle && !/^[^\s].*/.test(multipleQuestionTitle))
    errors.multipleQuestionTitle = 'Gunakan huruf/angka di awal kata!';

  if (freeTextQuestionTitle && !/^[^\s].*/.test(freeTextQuestionTitle))
    errors.freeTextQuestionTitle = 'Gunakan huruf/angka di awal kata!';

  const listAnswer: any = [];
  for (let index = 0; index < 6; index++) {
    if (values[`isActive${index}`]) {
      if (!values[`answer${index}`])
        errors[`answer${index}`] = 'Answer List - Text Box Wajib Diisi!';
      else if (
        values[`answer${index}`] &&
        !/^[^\s].*/.test(values[`answer${index}`])
      )
        errors[`answer${index}`] = 'Gunakan huruf/angka di awal kata!';

      if (values[`point${index}`] === null)
        errors[`point${index}`] = 'Point Wajib Diisi!';

      if (
        (values[`answer${index}`] && values[`point${index}`]) ||
        (values[`answer${index}`] && values[`point${index}`] === 0)
      )
        listAnswer.push(values[`answer${index}`]);
    }
    if (listAnswer.length === 0) {
      errors[`isActive${index}`] = 'Answer List - Text Box Wajib Diisi!';
    }
  }
  return errors;
};

export default FeedbackBankValidation;

import { devtools, persist, subscribeWithSelector } from 'zustand/middleware';

import createAuthSlice from './AuthSlice';
import { create } from 'zustand';

const store: any = persist(
  subscribeWithSelector((set: any, get: any) => ({
    ...createAuthSlice(set, get),
  })),
  {
    name: 'zurichCMS',
    partialize: (state: any) => {
      return {
        token: state.token,
        tokenRefresh: state.tokenRefresh,
        auth: state.auth,
        permission: state.permission,
        lastActionTimeStamp: state.lastActionTimeStamp,
      };
    },
  }
);

const createStore = create(devtools(store, { name: 'zurichCMS' }));

export default createStore;

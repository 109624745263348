import React from 'react';
import { Field, Form } from 'react-final-form';
import { Button, Col, Layout, Row, Form as FormANTD, Input } from 'antd';
import LoginImage from '../../../Assets/Images/login.jpg';
import ZLogo1 from '../../../Assets/Images/zurich-logo-big-white.png';
import ZLogo2 from '../../../Assets/Images/zurichWhiteNew.png';
import moment from 'moment';
import packageJson from './../../../../package.json';

interface IProps {
  handleSubmit: (values: any) => void;
  isLoading: any;
}

export default function LoginComponent(props: IProps) {
  const { handleSubmit, isLoading } = props;
  const { Content } = Layout;
  const year = moment().format('YYYY');

  const InputUsername = ({ input, id }: any) => {
    return (
      <Input
        id={id}
        className="formInputLogin formInputLoginUsername"
        placeholder="Username"
        {...input}
      />
    );
  };

  const InputPassword = ({ input, id }: any) => {
    return (
      <Input.Password
        id={id}
        className="formInputLogin"
        placeholder="Password"
        {...input}
      />
    );
  };

  return (
    <Content className="backgroundLoginComponent">
      <Row>
        <Col
          span={15}
          className="loginBackgroundImage"
          style={{ backgroundImage: `url(${LoginImage})` }}
        >
          <img src={ZLogo1} className="logoCenterLogin" alt="logoCenterLogin" />
          <div className="zurichCMSLogo">
            <p className="pZurichCMSLogo">
              <b>Zurich</b> CMS
            </p>
          </div>
          <p className="zurichCopyrightLogin">
            Copyright © {year} Zurich Indonesia - Zurich CMS V.
            {packageJson.version}
          </p>
        </Col>
        <Col span={9} className="colRightFormLogin">
          <img
            src={ZLogo2}
            className="logoCenterFormLogin"
            alt="logoCenterFormLogin"
          />
          <Form onSubmit={handleSubmit}>
            {formProps => {
              const { values } = formProps;
              return (
                <FormANTD layout="vertical" className="formLogin">
                  <Field
                    name="username"
                    component={InputUsername}
                    idcomponent="inputUsername"
                    placeholder="Username"
                  />
                  <Field
                    name="password"
                    component={InputPassword}
                    idcomponent="inputPassword"
                    placeholder="Password"
                  />
                  <Button
                    type="primary"
                    className={
                      !values.username || !values.password
                        ? 'btnLoginDisabled'
                        : 'btnLogin'
                    }
                    htmlType="submit"
                    size="large"
                    disabled={!values.username || !values.password}
                    loading={isLoading}
                    id="btnLogin"
                    onClick={() => handleSubmit(values)}
                  >
                    Login
                  </Button>
                  <p className="forgotPasswordText">Forgot password?</p>
                </FormANTD>
              );
            }}
          </Form>
        </Col>
      </Row>
    </Content>
  );
}

import React, { useState } from 'react';
import ModalDetailQAComponent from '../../Component/Modal/ModalDetailQAComponent';
import { IntlShape } from 'react-intl';
import { useQuery } from '@tanstack/react-query';
import { getDocAgreementListAPI } from '../../../../Services/qaProcess.api';
import { getSchemaCleanAPI } from '../../../../Services/qaProcessClean.api';

interface IProps {
  intl: IntlShape;
  modalDetailIsShow: boolean;
  setModalDetailIsShow: any;
  selectedData: any;
  setSelectedData: any;
  schema: any;
  detailCandidate: any;
  setModalCandidateIsShow: any;
  isFetchingSubmission: boolean;
  refreshList: any;
  qaStatus: string;
  setQaStatus: any;
  documentsContent: any;
  handleUploadContent: (file: any, additionalMeta: any, form: any) => void;
  documentMeta: any;
  setContentDocument: any;
  setNewListBeneficiary: React.Dispatch<React.SetStateAction<never[]>>;
  candidateApprovers: any;
  isUploading: boolean;
}
export default function ModalDetailQAContainer(props: IProps) {
  const {
    intl,
    modalDetailIsShow,
    setModalDetailIsShow,
    selectedData,
    setSelectedData,
    schema,
    detailCandidate,
    setModalCandidateIsShow,
    isFetchingSubmission,
    refreshList,
    qaStatus,
    setQaStatus,
    documentsContent,
    handleUploadContent,
    documentMeta,
    setContentDocument,
    setNewListBeneficiary,
    candidateApprovers,
    isUploading,
  } = props;

  const [modalCancelIsShow, setModalCancelIsShow] = useState(false);
  const [modalContentCleanIsShow, setModalContentCleanIsShow] = useState(false);
  const [valuesAAJI, setValuesAAJI] = useState();

  const { data: agreementList } = useQuery(
    ['getDocAgreementList', { candidateLevel: selectedData?.candidateLevel }],
    getDocAgreementListAPI,
    {
      enabled: selectedData !== undefined,
    }
  );

  const { data: schemaClean, refetch: refreshSchema } = useQuery(
    ['getSchemaClean', { detailCandidate: selectedData }],
    getSchemaCleanAPI
  );

  const renderValue = (name: string, row: any) => {
    const values = row.row.original.fromto;
    let newValues: any;
    if (typeof values === 'string') {
      newValues = name === 'from' ? values.split('-')[0] : values.split('-')[1];
    } else {
      newValues = name === 'from' ? values[0] : values[1];
    }
    return <div>{values !== '' ? newValues : '-'}</div>;
  };

  const column = {
    beneficiary: [
      {
        Header: 'Nama Lengkap',
        accessor: 'fullname',
      },
      {
        Header: 'Hubungan dengan Anda',
        accessor: 'relationship',
      },
      {
        Header: 'Pekerjaan',
        accessor: 'job',
      },
      {
        Header: 'Telepon Rumah',
        accessor: 'homephone',
      },
      {
        Header: 'Nomor HP',
        accessor: 'mobilephone',
      },
      {
        Header: 'Alamat',
        accessor: 'address',
      },
    ],
    workexperience: [
      {
        Header: 'Nama Perusahaan',
        accessor: 'companyname',
      },
      {
        Header: 'Bidang Usaha',
        accessor: 'businessfields',
      },
      {
        Header: 'Jabatan',
        accessor: 'position',
      },
      {
        Header: 'Dari',
        Cell: (row: any) => renderValue('from', row),
      },
      {
        Header: 'Sampai',
        Cell: (row: any) => renderValue('to', row),
      },
      {
        Header: 'Alasan Keluar',
        accessor: 'resigningreason',
      },
    ],
  };

  const handleCancel = () => {
    setModalDetailIsShow(false);
    setSelectedData(undefined);
    setNewListBeneficiary([]);
  };

  const handleEdit = () => {
    setModalDetailIsShow(false);
    setModalCandidateIsShow(true);
    setContentDocument(detailCandidate?.documentContentData);
  };

  return (
    <ModalDetailQAComponent
      intl={intl}
      modalDetailIsShow={modalDetailIsShow}
      handleCancel={handleCancel}
      selectedData={selectedData}
      column={column}
      schema={schema}
      schemaClean={schemaClean}
      agreementList={agreementList || []}
      detailCandidate={detailCandidate}
      handleEdit={handleEdit}
      modalCancelIsShow={modalCancelIsShow}
      setModalCancelIsShow={setModalCancelIsShow}
      isLoading={isFetchingSubmission}
      setModalDetailIsShow={setModalDetailIsShow}
      refreshList={refreshList}
      modalContentCleanIsShow={modalContentCleanIsShow}
      setModalContentCleanIsShow={setModalContentCleanIsShow}
      qaStatus={qaStatus}
      documentsContent={documentsContent}
      setQaStatus={setQaStatus}
      refreshSchema={refreshSchema}
      handleUploadContent={handleUploadContent}
      documentMeta={documentMeta}
      setValuesAAJI={setValuesAAJI}
      valuesAAJI={valuesAAJI}
      candidateApprovers={candidateApprovers}
      isUploading={isUploading}
    />
  );
}

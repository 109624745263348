import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { Alert, Col, Row } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { contestType, progressStatusUpload } from '../../../App/Enums';

interface IProps {
  handleCancel: () => void;
  showModal: boolean;
  initialValues: any;
}

export default function ModalContestUploadComponent(props: IProps) {
  const { handleCancel, showModal, initialValues } = props;
  const renderTime = time => {
    return moment(time).format('DD-MM-YYYY HH:mm:ss');
  };

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="contestUploadModalResult" />
      </h3>
    );
  };
  const contentComponent = () => {
    if (initialValues?.id) {
      let totalError = 0;
      const errMessage = initialValues.errorMessageCollection;
      let errMessageLoop: any = null;
      if (errMessage !== null) {
        errMessageLoop = Object.keys(errMessage).map(key => {
          totalError++;
          return <li value={key}>{errMessage[key]}</li>;
        });
      }
      return (
        <div className="divInfoUpload">
          <Row>
            <Col span={6} className="titleHistoryDetailUpload">
              File Name
            </Col>
            <Col span={1}>:</Col>
            <Col span={17}>{initialValues.originalFilename}</Col>
          </Row>
          <Row>
            <Col span={6} className="titleHistoryDetailUpload">
              Contest Type
            </Col>
            <Col span={1}>:</Col>
            <Col span={17}>
              {initialValues.contestType
                ? contestType[initialValues.contestType - 1].name
                : null}
            </Col>
          </Row>
          <Row>
            <Col span={6} className="titleHistoryDetailUpload">
              Year Version
            </Col>
            <Col span={1}>:</Col>
            <Col span={17}>{initialValues.year}</Col>
          </Row>
          <Row>
            <Col span={6} className="titleHistoryDetailUpload">
              Upload Date
            </Col>
            <Col span={1}>:</Col>
            <Col span={17}>{renderTime(initialValues.uploadDate)}</Col>
          </Row>
          <Row>
            <Col span={6} className="titleHistoryDetailUpload">
              Status
            </Col>
            <Col span={1}>:</Col>
            <Col span={17}>
              {progressStatusUpload[initialValues.progressStatus].name}
            </Col>
          </Row>
          {errMessage !== null ? (
            <Alert
              className="alertErrorModuleUploadHistory"
              message={`${totalError} row data failed to upload`}
              type="error"
              showIcon
            />
          ) : null}

          <ul>{errMessageLoop}</ul>
        </div>
      );
    }
    return null;
  };
  return (
    <CModalRFF
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      handleSubmit={() => {}}
      isLoading={false}
      formLayout="vertical"
      initialValues={{}}
    />
  );
}

import { IResultConfigurationListDAO } from '../Modules/Configuration/DAO/configuration.dao';
import instance from '../Services/interceptor';

export const getConfigurationListAPI = async (): Promise<
  IResultConfigurationListDAO[]
> => {
  const { data } = await instance.get(`/configuration`);
  return data;
};

export const mutateConfigurationAPI = async (params: any) => {
  const { parameter, parameterType, parameterValue, id } = params;
  const payload = { parameter, parameterType, parameterValue };
  return await instance.put(`/configuration/${id}`, payload);
};

import React from 'react';
import ModalEditWorkExperienceComponent from '../../Component/Modal/ModalEditWorkExperienceComponent';
import moment from 'moment';

interface IProps {
  modalEditWorkExperienceIsShow: boolean;
  setModalEditWorkExperienceIsShow: any;
  schema: any;
  selectedWorkexperience: any;
  setSelectedWorkexperience: any;
  listworkexperience: any;
  setNewListWorkexperience: any;
}
export default function ModalEditWorkExperienceContainer(props: IProps) {
  const {
    modalEditWorkExperienceIsShow,
    setModalEditWorkExperienceIsShow,
    schema,
    selectedWorkexperience,
    setSelectedWorkexperience,
    listworkexperience,
    setNewListWorkexperience,
  } = props;

  const handleCancel = () => {
    setModalEditWorkExperienceIsShow(false);
    setSelectedWorkexperience(null);
  };

  const handleReset = () => {
    setSelectedWorkexperience(null);
  };

  const handleUpdate = (formValue: any) => {
    for (const iterator of listworkexperience) {
      if (iterator.id === selectedWorkexperience.id) {
        iterator.companyname = formValue.companyname;
        iterator.businessfields = formValue.businessfields;
        iterator.position = formValue.position;
        iterator.fromto =
          formValue.fromto && formValue.fromto[0]?.length !== 0
            ? `${moment(formValue.fromto[0]).format('YYYY')}-${moment(
                formValue.fromto[1]
              ).format('YYYY')}`
            : '';
        iterator.resigningreason = formValue.resigningreason;
      }
    }
    setModalEditWorkExperienceIsShow(false);
    setNewListWorkexperience(listworkexperience);
    setSelectedWorkexperience(null);
  };

  if (
    selectedWorkexperience &&
    typeof selectedWorkexperience.fromto === 'string'
  ) {
    selectedWorkexperience.fromto = selectedWorkexperience.fromto.split('-');
  }

  return (
    <ModalEditWorkExperienceComponent
      modalEditWorkExperienceIsShow={modalEditWorkExperienceIsShow}
      handleCancel={handleCancel}
      schema={schema}
      selectedWorkexperience={selectedWorkexperience}
      handleReset={handleReset}
      handleUpdate={handleUpdate}
    />
  );
}

import Uppy from '@uppy/core';
import Webcam from '@uppy/webcam';
import { Dashboard as DashboardReact } from '@uppy/react';
import FormItem from 'antd/lib/form/FormItem';

import React from 'react';

interface IProps {
  input?: any;
  uppyConfig?: any;
  meta?: any;
  labelTitle?: any;
  labelKey?: any;
  height?: number;
  formClassName?: string;
}

const InputSelfie = (props: IProps) => {
  const {
    meta: { error },
    labelTitle,
    uppyConfig,
    labelKey,
    input,
    height,
    formClassName,
  } = props;

  const {
    customCountdown,
    customModes,
    maxFile,
    allowedFile,
    maxSize,
    minFile,
    maxTotalFileSize,
  } = uppyConfig;
  function useUppy(factory: any) {
    return React.useMemo(factory, []);
  }
  const uppyInput: any = useUppy(() => {
    return new Uppy({
      id: input?.name,
      debug: true,
      autoProceed: true,
      restrictions: {
        minNumberOfFiles: minFile,
        maxNumberOfFiles: maxFile,
        allowedFileTypes: allowedFile,
        maxFileSize: maxSize,
        maxTotalFileSize,
      },
    }).use(Webcam, {
      showVideoSourceDropdown: false,
      showRecordingLength: false,
      countdown: customCountdown,
      modes: customModes,
    });
  });
  uppyInput
    .on('file-added', file => {
      const fileArr: any = [];
      fileArr.push({
        file: file.data,
        name: file.name,
        label: labelKey,
        size: file.size,
        type: file.type,
        extension: file.extension,
        meta: file.meta,
      });
      input.onChange(fileArr);
    })
    .on('file-removed', (file: any) => {
      if (input.value.length > 1) {
        const tempData = [...input.value];
        const filteredData = tempData.filter(val => {
          return val.name !== file.name;
        });
        input.onChange(filteredData);
      } else {
        input.onChange(null);
      }
    });
  return (
    <FormItem
      validateStatus={error !== undefined ? 'error' : ''}
      help={error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
      className={formClassName}
    >
      <DashboardReact
        plugins={['Webcam']}
        uppy={uppyInput}
        height={height || 150}
        proudlyDisplayPoweredByUppy={false}
        hideUploadButton={true}
      />
    </FormItem>
  );
};

export default React.memo(InputSelfie);

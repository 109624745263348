import { Col, Row, Tag } from 'antd';
import React, { useState } from 'react';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import moment from 'moment';
import LicenseTabListComponent from '../../Component/Tabs/LicenseTabListComponent';
import { licenseAPI } from '../../../../Services/license.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import { IResultLicenseListDao } from '../../DAO/license.dao';
import ModalNotificationLicenseContainer from '../Modal/ModalNotificationLicenseContainer';
import ModalNotifIsZelContainer from '../Modal/ModalNotifIsZelContainer';
import ModalLicenseContainer from '../Modal/ModalLicenseContainer';
import ErrorHandler from '../../../../App/ErrorHandler';

export default function LicenseTabListContainer() {
  const [filter, setFilter] = useState<{
    progressStatus: string;
    licenseStatus: string;
    dateFilter: any;
  }>({ progressStatus: '', licenseStatus: '', dateFilter: null });
  const [dataFilter, setDataFilter] = useState<any[]>([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalNotif, setShowModalNotif] = useState(false);
  const [showModalNotifIsZel, setShowModalNotifIsZel] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const {
    data: licenseList,
    refetch: refetchLicense,
    fetchStatus: fetchStatusLicense,
  } = useQuery(
    ['GetLicenseAPI', filter?.progressStatus],
    licenseAPI.getLicenseData,
    {
      enabled:
        filter?.progressStatus !== '' ||
        filter?.licenseStatus !== '' ||
        filter?.dateFilter !== null,
      onSuccess: res => {
        const licenseStatus = filter?.licenseStatus || '';
        const dateFilter = filter?.dateFilter;

        if (res?.length > 0) {
          const users = res?.filter(x => {
            const startDate =
              dateFilter === null
                ? dateFilter
                : moment(dateFilter ? dateFilter[0] : '').format(
                    moment.HTML5_FMT.DATE
                  );
            const finishDate =
              dateFilter === null
                ? dateFilter
                : moment(dateFilter ? dateFilter[1] : '').format(
                    moment.HTML5_FMT.DATE
                  );
            const convertDate = moment(x.timestamp).format(
              moment.HTML5_FMT.DATE
            );
            if (!dateFilter && licenseStatus) {
              return x.candidateLicenseStatus === licenseStatus;
            } else if (dateFilter && !licenseStatus) {
              return convertDate >= startDate && convertDate <= finishDate;
            } else if (dateFilter && licenseStatus) {
              return (
                convertDate >= startDate &&
                convertDate <= finishDate &&
                x.candidateLicenseStatus === licenseStatus
              );
            } else {
              return res;
            }
          });
          setDataFilter(users);
        }
      },
      refetchOnWindowFocus: false,
    }
  );

  const handleFilter = (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>,
    values: any
  ) => {
    const progressStatus = values?.progressStatus || 'SEMUA';
    const licenseStatus = values?.licenseStatus || '';
    const dateFilter = values?.dateFilter || null;
    setFilter({ progressStatus, licenseStatus, dateFilter });
    refetchLicense();
  };

  const downloadReport = useMutation(licenseAPI.downloadLicenseReport, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {},
  });

  const handleRefresh = (
    form: FormApi<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    form.reset();
    setFilter({ progressStatus: 'SEMUA', licenseStatus: '', dateFilter: null });
    refetchLicense();
  };

  const handleDownload = () => downloadReport.mutate();

  const renderAction = row => {
    const handleClick = () => {
      const detail: IResultLicenseListDao = row.row.original;
      const detailCandidate = {
        agencyCode: detail.refAgencyCode,
        agencyName: detail.refAgencyName,
        agentLevel: detail.candidateLevel,
        agentNumber: detail.payeeId,
        aplicationNumber: detail.contractNumber,
        candidateLevel: detail.candidateLevel,
        candidateName: detail.name,
        caseId: detail.caseId,
        clasificationAgent: detail.classificationType,
        code: detail.code,
        origin: detail.origin,
        progressStatus: detail.candidateStatus,
        salesOffice: detail.salesOfficeDesc ? detail.salesOfficeDesc : '-',
        sob: 'AG',
        subChannel: 'AG',
        taskId: detail.taskId,
        licenseStatus: detail.candidateLicenseStatus
          ? detail.candidateLicenseStatus
          : '-',
      };
      setSelectedData(detailCandidate);
      setShowModalDetail(true);
    };

    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailQa',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: handleClick,
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const renderCol = (label: string, content: string) => {
    return (
      <Row style={{ marginBottom: 10 }}>
        <Col span={4}>
          <b>{label}</b>
        </Col>
        <Col span={8}>{': ' + content}</Col>
      </Row>
    );
  };

  const tagStat = (tagStatus: string, color: string) => {
    return (
      <Tag color={color} className="tagStatLicenseCenter">
        <div className="tagStatLicenseProgress">{tagStatus}</div>
      </Tag>
    );
  };

  const renderProgress = (status: string) => {
    switch (status) {
      case 'QA Clean': {
        return tagStat('QA Clean', '#009EE0');
      }
      case 'Uploading Payment Slip': {
        return tagStat('Uploading Payment Slip', '#32BFB6');
      }
      case 'Payment Slip Checking': {
        return tagStat('Payment Slip Checking', '#5D4CD5');
      }
      case 'Waiting For Exam Result': {
        return tagStat('Waiting For Exam Result', '#F8B720');
      }
      case 'Exam Passed': {
        return tagStat('Exam Passed', '#E9406E');
      }
      case 'Waiting for Training': {
        return tagStat('Waiting For Training', '#FB6915');
      }
      case 'CPD Training': {
        return tagStat('CPD Training', '#B16A19');
      }
      case 'Agent Activated': {
        return tagStat('Agent Activated', '#3ACE3A');
      }
      case 'DOSD Need to Activate': {
        return tagStat('DOSD Need to Activated', '#A648B8');
      }
    }
    return null;
  };

  const labelLicenseStatus = (status: string) => {
    switch (status) {
      case 'MUTASI': {
        return 'Mutation';
      }
      case 'EXPIRED': {
        return 'Expired';
      }
      case 'NOLICENSE': {
        return 'No License';
      }
    }
    return null;
  };

  const formatDOB = 'DD-MMM-YYYY';
  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Nomor Agen',
      accessor: 'payeeId',
    },
    {
      Header: 'Nama Kandidat',
      accessor: 'name',
    },
    {
      Header: 'Status Lisensi',
      accessor: 'candidateLicenseStatus',
      Cell: row =>
        row.row.original.candidateLicenseStatus
          ? labelLicenseStatus(row.row.original.candidateLicenseStatus)
          : '-',
    },
    {
      Header: 'Tanggal Pengajuan',
      accessor: row =>
        row.dosdStartDate ? moment(row.dosdStartDate).format(formatDOB) : '-',
      disableSort: true,
    },
    {
      Header: 'Progress',
      accessor: 'candidateStatus',
      disableSort: true,
      Cell: row => renderProgress(row.row.original.candidateStatus),
    },
    {
      Header: 'Action',
      Cell: row => renderAction(row),
    },
  ];

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { refAgencyName, contractNumber, refAgencyCode, origin } =
      row.original;

    return (
      <div>
        {renderCol('SOB', 'AG')}
        {renderCol(
          'Nomor Aplikasi',
          contractNumber === null || contractNumber === ''
            ? '-'
            : contractNumber
        )}
        {renderCol('Origin', origin)}
        {renderCol(
          'Kode Agensi',
          refAgencyCode === null || refAgencyCode === '' ? '-' : refAgencyCode
        )}
        {renderCol(
          'Nama Agensi',
          refAgencyName === null || refAgencyName === '' ? '-' : refAgencyName
        )}
      </div>
    );
  }, []);

  return (
    <>
      <LicenseTabListComponent
        columnData={columnData}
        dataFilter={dataFilter}
        handleDownload={handleDownload}
        handleFilter={handleFilter}
        handleRefresh={handleRefresh}
        isGlobalLoading={false}
        isLoading={fetchStatusLicense === 'fetching'}
        licenseList={licenseList || []}
        renderRowSubComponent={renderRowSubComponent}
      />
      {showModalDetail && (
        <ModalLicenseContainer
          modalIsShow={showModalDetail}
          refetchLicense={refetchLicense}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          setShowModalDetail={setShowModalDetail}
          setShowModalNotif={setShowModalNotif}
          setShowModalNotifIsZel={setShowModalNotifIsZel}
        />
      )}
      <ModalNotificationLicenseContainer
        modalIsShow={showModalNotif}
        setModalIsShow={setShowModalNotif}
      />
      <ModalNotifIsZelContainer
        modalIsShow={showModalNotifIsZel}
        setModalIsShow={setShowModalNotifIsZel}
      />
    </>
  );
}

import { Form, Select as SelectANTD } from 'antd';

import React from 'react';

interface IProps {
  defaultItemName?: any;
  dataOption?: any;
  label?: any;
  idComponent?: any;
  formClassName?: any;
  labelIsRequired?: boolean;
  addtionalLabel?: any;
  addtionalLabelPos?: 'side' | 'bottom';
  allowClear?: boolean;
  mode?: any;
  loading?: boolean;
  placeholder?: string;
  className?: string;
  disabled?: boolean;
  input?: any;
  onSelect?: any;
  meta?: any;
  showSearch?: boolean;
  onSearch?: any;
  filterOption?: any;
  optionFilterProp?: any;
  optionLabelProp?: any;
  customLabel?: string;
  customValue?: string;
  customStyleInput?: any;
  defaultValue?: any;
}

export default function Select(props: IProps) {
  const {
    defaultItemName,
    dataOption,
    label,
    idComponent,
    formClassName,
    labelIsRequired,
    addtionalLabel,
    addtionalLabelPos,
    allowClear,
    mode,
    loading,
    placeholder,
    className,
    disabled,
    input,
    onSelect,
    meta,
    showSearch,
    onSearch,
    filterOption,
    optionFilterProp,
    optionLabelProp,
    customLabel,
    customValue,
    customStyleInput,
    defaultValue,
  } = props;

  const { error, touched } = meta || {};

  const items = dataOption.map((item, key) => {
    return (
      <SelectANTD.Option
        id={`optionValue${label}-${key}`}
        key={`${item.id}${key}`}
        value={customValue ? item[customValue] : item.id}
        label={customLabel ? item[customLabel] : item.name}
      >
        {customLabel ? item[customLabel] : item.name}
      </SelectANTD.Option>
    );
  });
  const lebelRequired = () => {
    if (labelIsRequired) {
      return <span className="labelRequired">*</span>;
    }
    return null;
  };
  const labelTitle = () => {
    if (addtionalLabelPos && addtionalLabelPos === 'bottom') {
      return (
        <span>
          <b className="capital">
            {label}
            {lebelRequired()}
          </b>
          <p style={{ marginBottom: '0px' }}>
            {addtionalLabel ? addtionalLabel : null}
          </p>
        </span>
      );
    } else
      return (
        <span>
          <b className="capital">{label}</b>
          <span style={{ color: '#BFBFBF' }}>
            {addtionalLabel ? ` ${addtionalLabel}` : null}
            {lebelRequired()}
          </span>
        </span>
      );
  };

  const defaultStyleInput = { width: '100%' };
  const handleOnChange = (e: any) => {
    input.onChange(e);
  };

  return (
    <Form.Item
      label={labelTitle()}
      colon={false}
      id={idComponent}
      className={formClassName}
      validateStatus={error && touched ? 'error' : ''}
      help={error && touched ? error : ''}
    >
      <SelectANTD
        allowClear={allowClear ? true : undefined}
        mode={mode}
        id={idComponent}
        style={customStyleInput ? customStyleInput : defaultStyleInput}
        disabled={disabled}
        className={className}
        placeholder={placeholder}
        loading={loading}
        filterOption={filterOption ? filterOption : false}
        onSelect={onSelect}
        showSearch={showSearch}
        onSearch={onSearch}
        optionFilterProp={optionFilterProp}
        optionLabelProp={optionLabelProp}
        defaultValue={defaultValue}
        {...input}
        value={input.value || (mode === 'multiple' ? undefined : '')}
        onChange={handleOnChange}
      >
        {defaultItemName ? (
          <SelectANTD.Option value="">{defaultItemName}</SelectANTD.Option>
        ) : (
          <></>
        )}
        {items}
      </SelectANTD>
    </Form.Item>
  );
}

import instance from '../Services/interceptor';

export const getLookupBlacklistAPI = async (params: any) => {
  const { submission } = params.queryKey[1];
  const personalData = submission.personaldata.profile;
  const mobilephone = personalData.mobilephone;
  const subsMobilePhone = mobilephone.substring(1, mobilephone.length);
  const { data } = await instance.get(
    `/Candidate/blacklisted/checking?IdNumber=${personalData.nik}&Email=${personalData.email}&PhoneNumber=%2B${subsMobilePhone}`
  );

  const duplicateData: any = [];
  for (const iterator of data.duplicateItems) {
    const subsData = iterator.split('.')[4];
    duplicateData.push(subsData);
  }

  return { data, duplicateData };
};

export const mutateSubmitAAJIAPI = async (params: any) => {
  const {
    duplicateData,
    code,
    agentTengarai,
    agentBlackList,
    statusWorld,
    agentRef,
    caseId,
  } = params;
  const candidateCode = code;
  const statusWorldCheck = statusWorld;

  const aajiData: any = {
    note: {
      type: 'object',
      config: {},
      properties: {},
    },
    documents: {
      type: 'object',
      config: {},
      properties: {},
    },
  };
  const documentAaji = [
    'screenshotAaji1',
    'screenshotAaji2',
    'screenshotAaji3',
  ];

  for (const iterator of documentAaji) {
    if (params[iterator]) {
      aajiData.documents.properties[iterator] = params[iterator];
    }
  }

  if (agentTengarai) {
    const dataAgentTengarai: any = {
      config: {},
    };
    dataAgentTengarai.title = 'Agen masih aktif di perusahaan lain(tengarai)';
    dataAgentTengarai.config.pendingStatus = 'active';
    dataAgentTengarai.type = 'string';
    aajiData.note.properties.agenttengarai = dataAgentTengarai;
  }

  if (agentBlackList) {
    const dataAgentBlackList: any = {
      config: {},
    };
    dataAgentBlackList.title = 'Agen masuk daftar hitam';
    dataAgentBlackList.config.pendingStatus = 'active';
    dataAgentBlackList.type = 'string';
    aajiData.note.properties.agentblackList = dataAgentBlackList;
  }

  if (statusWorldCheck && statusWorldCheck !== 'NO HIT') {
    const dataStatusWorld: any = {
      config: {},
    };
    dataStatusWorld.title = `World check: ${statusWorldCheck}`;
    dataStatusWorld.config.pendingStatus = 'active';
    dataStatusWorld.type = 'string';
    aajiData.note.properties.statusworld = dataStatusWorld;
  }

  for (const iterator of duplicateData) {
    const detailDuplicate: any = {
      config: {},
    };
    if (iterator === 'nik') {
      detailDuplicate.title = 'No KTP sudah tercatat pada sistem';
    } else if (iterator === 'email') {
      detailDuplicate.title = 'Email sudah tercatat pada sistem';
    } else {
      detailDuplicate.title = 'No HP telah tercatat pada sistem';
    }
    detailDuplicate.config.pendingStatus = 'active';
    aajiData.note.properties[iterator] = detailDuplicate;
  }
  const payload = {
    name: `FRMCR0001.${candidateCode}`,
    code: `FRMCR0001.${candidateCode}`,
    type: 'object',
    description: 'Form QA Process Candidate',
    config: {
      web: {},
      mobile: {},
    },
    schema: {
      id: 1,
      name: `FRMCR0001.${candidateCode}`,
      description: 'Form QA Process Candidate',
      type: 'object',
      config: {
        web: {},
        mobile: {},
      },
      properties: {
        aaji: {
          type: 'object',
          config: {
            web: {
              isHidden: false,
            },
            mobile: {
              isHidden: true,
            },
          },
          properties: aajiData,
        },
        qachecking: {
          type: 'object',
          config: {},
          properties: {},
        },
        aajichecking: {
          type: 'object',
          config: {},
          properties: {},
        },
      },
    },
  };
  await instance.post(`/form`, payload);
  await instance.post(
    `/Candidate/workflow/approve/submission/${caseId}/QAPROCESS`,
    {
      isProxyOri: agentRef ? agentRef : false,
    }
  );
};

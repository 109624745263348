import React, { useState } from 'react';
import {
  getAgreementMappingAPI,
  getAgreementModuleAPI,
} from '../../../Services/Agreement/agreementMapping.api';
import AgreementMappingComponent from '../Component/AgreementMappingComponent';
import { useQuery } from '@tanstack/react-query';
import { getAgreementContentAPI } from '../../../Services/Agreement/agreementContent.api';
import CButton from '../../../Assets/Components/CButton';

export default function AgreementMappingContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: agreementMappingList,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['getAgreementMapping'], getAgreementMappingAPI, {
    refetchOnWindowFocus: false,
  });

  const {
    data: agreementContentList,
    isLoading: isLoadingAgreementContent,
    refetch: refetchAgreementContent,
  } = useQuery(['getAgreementContent'], getAgreementContentAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const {
    data: agreementModuleList,
    isLoading: isLoadingAgreementModule,
    refetch: refetchAgreementModule,
  } = useQuery(['getAgreementModule'], getAgreementModuleAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'primary',
        id: `btnEditAgreementMapping`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'buttonTable',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const renderAgreementType = (row: any) => {
    const rows = row?.row?.original;
    if (rows?.agreementType === 'NonLeader') {
      return 'Non Leader';
    } else {
      return 'Leader';
    }
  };

  const renderSearchAgreementType = (row: any) => {
    if (row?.agreementType === 'NonLeader') {
      return 'Non Leader';
    } else {
      return 'Leader';
    }
  };
  const columnData = [
    {
      Header: 'Agreement code',
      accessor: 'disclaimerCode',
    },
    {
      Header: 'Agreement name',
      accessor: 'disclaimerName',
    },
    {
      Header: 'Type',
      accessor: (row: any) => renderSearchAgreementType(row),
      Cell: (row: any) => renderAgreementType(row),
    },
    {
      Header: 'Module code',
      accessor: 'disclaimerModuleCode',
    },
    {
      Header: 'Module name',
      accessor: 'disclaimerModuleName',
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];
  const handleRefresh = () => {
    setSelectedData(undefined);
    refetch();
    refetchAgreementContent();
    refetchAgreementModule();
  };

  return (
    <AgreementMappingComponent
      columnData={columnData}
      agreementMappingList={agreementMappingList}
      handleRefresh={handleRefresh}
      modalIsShow={modalIsShow}
      setModalIsShow={setModalIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      isLoading={
        isLoading ||
        isRefetching ||
        isLoadingAgreementContent ||
        isLoadingAgreementModule
      }
      agreementContentList={agreementContentList}
      agreementModuleList={agreementModuleList}
    />
  );
}

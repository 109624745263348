const stageValidation = (editMode: boolean, values: any) => {
  const { description, name, predecessor, sequence } = values;

  const errors: any = {};
  if (!name) errors.name = 'Stage Name Wajib Diisi!'
  else if (name && !/^[^\s].*/.test(name))
    errors.name = 'Gunakan huruf/angka di awal kata!';

  if (!description) errors.description = 'Description Wajib Diisi!';
  else if (description && !/^[^\s].*/.test(description))
    errors.description = 'Gunakan huruf/angka di awal kata!';

  if (sequence && (!predecessor || predecessor === null))
    errors.predecessor = 'Predecessor Wajib Diisi!';

  return errors;
};

export default stageValidation;

import {
  InputSwitch,
  InputText,
  InputTextNumber,
} from '../../../../../Assets/Components/CInput';

import { Field } from 'react-final-form';
import React from 'react';
import Select from '../../../../../Assets/Components/Select';
import { singleCheckbox } from '../../../../../Assets/Components/CCheckbox';

interface IProps {
  disabled: boolean;
  dataCertificate: any[];
  formValues: any;
  modalAction: 'update' | 'register';
}

export default function FieldComponent({
  disabled,
  dataCertificate,
  formValues,
  modalAction,
}: IProps) {
  return (
    <div className="containerGridTwo">
      <div>
        <Field
          name="trainingType"
          component={Select}
          idComponent="modalType"
          dataOption={[
            { id: 'roadmap', name: 'Roadmap' },
            { id: 'nonroadmap', name: 'Non Roadmap' },
          ]}
          defaultItemName="-Select-"
          label="Type"
          disabled={disabled}
        />
        <Field
          name="name"
          component={InputText}
          placeholder="Type Here"
          label="Class Name"
          disabled={disabled}
          maxlength={30}
        />
        <Field
          name="type"
          component={Select}
          idComponent="modalClassType"
          dataOption={[
            { id: 'private', name: 'Private' },
            { id: 'public', name: 'Non Private' },
          ]}
          defaultItemName="-Select-"
          label="Class Type"
          disabled={modalAction === 'update'}
        />
        <Field
          name="haveCertificate"
          component={singleCheckbox}
          label="Have Certificate"
          idComponent="modalHaveCertificate"
          disabled={disabled}
          type="checkbox"
        />
        {formValues.haveCertificate && (
          <Field
            name="codemiCetificateId"
            component={Select}
            idComponent="modalCertificate"
            dataOption={dataCertificate}
            defaultItemName="-Select-"
            label="Certificate List"
            disabled={disabled}
          />
        )}
        <Field
          name="isCpdStatus"
          component={singleCheckbox}
          label="CPD Status"
          idComponent="modalHaveCertificate"
          disabled={disabled}
          type="checkbox"
        />
        {formValues.isCpdStatus && (
          <>
            <Field
              name="cpdRefreshName"
              component={InputText}
              placeholder="Type Here"
              label="CPD Refresh Name"
              disabled={disabled}
              maxlength={30}
            />
            <Field
              name="cpdPoint"
              component={InputTextNumber}
              placeholder="Type Here"
              label="CPD Poin"
              min={1}
              disabled={disabled}
            />
          </>
        )}
      </div>
      <div>
        <Field
          name="description"
          component={InputText}
          placeholder="Type Here"
          label="Descrtiption"
          disabled={disabled}
          textArea={true}
          maxlength={500}
        />
        <Field
          name="status"
          idComponent="modalStatusClass"
          component={InputSwitch}
          disabled={disabled}
          label="Status"
        />
      </div>
    </div>
  );
}

import { Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import NumberFormat from 'react-number-format';
import CCard from '../../../Assets/Components/CCard';
import CDashboardComponent from '../../../Assets/Components/CDashboardComponent';
import CIcon from '../../../Assets/Components/CIcon';
import { injectIntl } from 'react-intl';
import { IResultLoginTrackerDAO } from '../DAO/dashboard.dao';

interface IProps {
  data: IResultLoginTrackerDAO;
  intl?: any;
}

function DashboardLoginTrackerComponent({ data, intl }: IProps) {
  const content = () => (
    <>
      <p className="periodDashboard">
        <CIcon className="iconPeriodDashboard" type="CalendarOutlined" />
        {`${moment(data.startPeriode).format('DD MMMM YYYY')} - ${moment(
          data.endPeriode
        ).format('DD MMMM YYYY')}`}
      </p>
      <CDashboardComponent
        title="Device Login"
        value={
          <Row>
            <Col
              span={11}
              style={{ marginRight: 5 }}
              className="dashboardValueContainer2"
            >
              <p>Android</p>
              <p style={{ fontSize: 25 }}>{data.android}</p>
            </Col>
            <Col
              span={11}
              style={{ marginLeft: 10 }}
              className="dashboardValueContainer2"
            >
              <p>IOS</p>
              <p style={{ fontSize: 25 }}>{data.ios}</p>
            </Col>
          </Row>
        }
      />
      <CDashboardComponent
        title="Login Attempts (Total)"
        value={
          <NumberFormat
            value={data.totalLoginAttempts}
            displayType={'text'}
            thousandSeparator="."
            decimalSeparator=","
          />
        }
      />
      <CDashboardComponent
        title="Time Logging (Total)"
        value={
          <NumberFormat
            value={data.totalTimeLogging}
            displayType={'text'}
            thousandSeparator="."
            decimalSeparator=","
          />
        }
        unit="Minutes"
      />
    </>
  );
  return (
    <CCard
      cardClassName="cardDashboard"
      cardTitle={
        <span className="titleCardDashboard">
          {intl.formatMessage({
            id: 'dashboardLoginTrackerTitle',
          })}
        </span>
      }
      cardContent={content()}
    />
  );
}
export default injectIntl(DashboardLoginTrackerComponent);

import { Button, Skeleton } from 'antd';
import { InputSwitch, InputText } from '../../../../Assets/Components/CInput';

import CIcon from '../../../../Assets/Components/CIcon';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { Field } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../../App/HasPermission';
import React from 'react';
import validate from '../../Validation/ChannelValidation';

interface IProps {
  editMode: boolean;
  handleCancel: () => void;
  initialValues: any;
  intl?: any;
  isLoading: boolean;
  isShow: boolean;
  onSubmit: (vals: any) => void;
  stageElearningModalAction: 'update' | 'register';
}

export default function ChannelModalComponent(props: IProps) {
  const {
    stageElearningModalAction,
    isLoading,
    editMode,
    isShow,
    handleCancel,
    onSubmit,
    initialValues,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {stageElearningModalAction === 'update' ? (
          <FormattedMessage id="detailChannelElearning" />
        ) : (
          <FormattedMessage id="addChannelElearning" />
        )}
      </h3>
    );
  };

  const contentComponent = propsContent => {
    const disabled =
      editMode === false && stageElearningModalAction === 'update'
        ? true
        : false;

    return (
      <Skeleton active loading={isLoading}>
        <Field
          name="code"
          component={InputText}
          placeholder="Type Here"
          label="Channel Code"
          disabled={disabled}
          maxlength={2}
        />
        <Field
          name="name"
          component={InputText}
          placeholder="Type Here"
          label="Channel Name"
          disabled={disabled}
          maxlength={15}
        />
        <Field
          name="headOfTrainer"
          component={InputText}
          placeholder="Type Here"
          label="Head of Trainer"
          disabled={disabled}
          addtionalCaption="*Untuk dicantumkan pada sertifikat"
        />
        <Field
          name="signOffCaption"
          component={InputText}
          placeholder="Type Here"
          label="Sign of Caption"
          disabled={disabled}
        />
        <Field
          name="isActive"
          idComponent="inputStatusStage"
          component={InputSwitch}
          disabled={disabled}
          label="Status"
        />
      </Skeleton>
    );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = propsFooter => {
    const { invalid } = propsFooter;
    if (HasPermission('u') || HasPermission('c')) {
      if (stageElearningModalAction === 'update' && !editMode) {
        return (
          <Button
            loading={isLoading}
            className={
              editMode
                ? invalid
                  ? 'btnSubmitDisabled'
                  : 'btnSubmit'
                : 'btnSubmit'
            }
            icon={<CIcon type="CheckOutlined" />}
            disabled={
              stageElearningModalAction === 'update' && !editMode
                ? false
                : invalid
            }
            id="btnSubmit"
            htmlType="submit"
          >
            {buttonContent(stageElearningModalAction, editMode)}
          </Button>
        );
      } else {
        return (
          <Button
            loading={isLoading}
            className={
              editMode
                ? invalid
                  ? 'btnSubmitDisabled'
                  : 'btnSubmit'
                : 'btnSubmit'
            }
            icon={<CIcon type="CheckOutlined" />}
            disabled={
              stageElearningModalAction === 'update' && !editMode
                ? false
                : invalid
            }
            id="btnSubmit"
            htmlType="submit"
          >
            {buttonContent(stageElearningModalAction, editMode)}
          </Button>
        );
      }
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

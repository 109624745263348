import React, { useState } from 'react';

import ChannelModalComponent from '../Component/ChannelModalComponent';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { mutateNonSelfTrainingChannelAPI } from '../../../../Services/channel.api';
import { FormattedMessage } from 'react-intl';
import ErrorHandler from '../../../../App/ErrorHandler';
interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  selectedData: any;
  handleRefresh: () => void;
}
export default function ChannelModalContainer({
  showModal,
  setShowModal,
  selectedData,
  setSelectedData,
  handleRefresh,
}: IProps) {
  const [editMode, setEditMode] = useState(false);

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };
  const mutate = useMutation(mutateNonSelfTrainingChannelAPI, {
    onError: (error: any) => {
      const responseStatus = error?.response.status;
      const errorData = error?.response.data;
      if (responseStatus === 400) {
        toast.error(errorData?.data[0]?.message);
      } else if (
        responseStatus === 500 &&
        errorData.Message === 'query did not return a unique result: 2'
      ) {
        toast.error(
          <span className="capitalFirst">
            <FormattedMessage id="channelDuplicated" />
          </span>
        );
      } else {
        ErrorHandler(error);
      }
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} channel
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setShowModal(false);
      handleRefresh();
      setEditMode(false);
    },
  });

  const initialValues: any = selectedData;

  const onSubmit = vals => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  return (
    <ChannelModalComponent
      isShow={showModal}
      handleCancel={handleCancel}
      onSubmit={onSubmit}
      initialValues={initialValues}
      stageElearningModalAction={selectedData ? 'update' : 'register'}
      editMode={editMode}
      isLoading={mutate.isLoading}
    />
  );
}

import CBreadcrumb from '../../Assets/Components/CBreadcrumb';
import CCard from '../../Assets/Components/CCard';
import CLabelCard from '../../Assets/Components/CLabelCard';
import ChannelContainer from './Channel/Container/ChannelContainer';
import ClassContainer from './Class/Container/ClassContainer';
import React from 'react';
import StageContainer from './Stage/Container/StageContainer';
import { Tabs } from 'antd';
// import ContentContainer from './Content/Container/ContentContainer';

interface IProps {
  tabData: {
    channel?: any;
    class?: any;
    stage?: any;
  };
  setTabData: (val: any) => void;
  handleOnTabClick: (params: string) => void;
  breadCrumbData;
}

export default function NonSelfTrainingComponent({
  tabData,
  setTabData,
  handleOnTabClick,
  breadCrumbData,
}: IProps) {
  const { TabPane } = Tabs;
  const { channel, stage } = tabData;

  const handleActiveKey = () => {
    // dicomment  karena tab content belum dipake & akan dipake taun depan
    // if (tabData.class && channel && stage) {
    //   return 'Content';
    // } else
    if (channel && stage) {
      return 'Class';
    } else if (channel) {
      return 'Stage';
    }
    return 'Channel'; // ganti namanya kalo mau dites
  };

  const renderContent = content => {
    const { breadcrumbItem, breadcrumbTitle, breadcrumbItemClick } =
      breadCrumbData;
    return (
      <>
        <CBreadcrumb
          breadcrumbItem={breadcrumbItem}
          breadcrumbTitle={breadcrumbTitle}
          breadcrumbItemClick={breadcrumbItemClick}
        />
        <div className="containerElearningTabPane">{content}</div>
      </>
    );
  };
  const tabContent = () => {
    return (
      <>
        <Tabs
          className="dynamicTabs"
          type="card"
          activeKey={handleActiveKey()}
          onTabClick={handleOnTabClick}
        >
          <TabPane
            tab="Channel"
            key="Channel"
            className="tabsPaneDynamic"
            style={{ padding: 0 }}
          >
            {renderContent(<ChannelContainer setTabData={setTabData} />)}
          </TabPane>
          <TabPane
            tab="Stage"
            key="Stage"
            className="tabsPaneDynamic"
            disabled={!channel}
          >
            {renderContent(
              <StageContainer channel={channel} setTabData={setTabData} />
            )}
          </TabPane>
          <TabPane
            tab="Class"
            key="Class"
            className="tabsPaneDynamic"
            disabled={!stage}
          >
            {renderContent(
              <ClassContainer
                channel={channel}
                stage={stage}
                setTabData={setTabData}
              />
            )}
          </TabPane>
          {/* <TabPane
            tab="Content"
            key="Content"
            className="tabsPaneDynamic"
            disabled={!tabData.class}
          >
            {renderContent(
              <ContentContainer
                channel={channel}
                class={tabData.class}
                stage={stage}
                setTabData={setTabData}
              />
            )}
          </TabPane> */}
        </Tabs>
      </>
    );
  };

  return (
    <>
      <CLabelCard leftText="Non-Self Training" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Training Content</span>
        }
        cardContent={tabContent()}
      />
    </>
  );
}

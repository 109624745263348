const contestTargetValidation = (values: any) => {
  const errors: any = {};
  const numberOfTarget = values.numberOfTarget
    ? parseInt(values.numberOfTarget, 10)
    : 0;

  if (!values.contestType) {
    errors.contestType = 'Contest type required!';
  }
  if (!values.group) {
    errors.group = 'Contest group required!';
  }
  if (!values.chanel) {
    errors.chanel = 'Contest chanel required!';
  }
  if (!values.dateContest) {
    errors.dateContest = 'Contest date required!';
  } else if (values.dateContest) {
    if (values.dateContest.length !== 2) {
      errors.dateContest = 'Please re-pick for contest date';
    }
  }
  if (!values.monitoringPeriode) {
    errors.monitoringPeriode = 'Contest monitoring date required!';
  }
  if (!values.numberOfTarget) {
    errors.numberOfTarget = 'Number of target required!';
  }
  if (!values.contestLevel) {
    errors.contestLevel = 'Contest level required!';
  }
  for (let index = 0; index < numberOfTarget; index++) {
    if (!values[`targetName${index}`]) {
      errors[`targetName${index}`] = 'Target name required!';
    }
    if (values.contestType !== 'MDRT') {
      if (!values[`rewardId${index}`]) {
        errors[`rewardId${index}`] = 'Reward required!';
      }
      if (!values[`rewardNumberOfTicket${index}`]) {
        errors[`rewardNumberOfTicket${index}`] = 'Number of ticket required!';
      }
    }
    if (!values[`rewardFyp${index}`]) {
      errors[`rewardFyp${index}`] = 'Target Fyp required!';
    } else {
      if (index !== 0) {
        if (
          parseInt(values[`rewardFyp${index}`], 10) <
          parseInt(values[`rewardFyp${index - 1}`], 10)
        ) {
          errors[`rewardFyp${index}`] = `Target ${
            index + 1
          } Fyp must grater than target ${index} Fyp!`;
        }
      }
    }
    if (!values[`rewardNbFyp${index}`]) {
      errors[`rewardNbFyp${index}`] = 'Target Nbfyp required!';
    } else {
      if (index !== 0) {
        if (
          parseInt(values[`rewardNbFyp${index}`], 10) <
          parseInt(values[`rewardNbFyp${index - 1}`], 10)
        ) {
          errors[`rewardNbFyp${index}`] = `Target ${
            index + 1
          } Nbfyp must grater than target ${index} Nbfyp!`;
        }
      }
    }
    if (!values[`rewardPersistency${index}`]) {
      errors[`rewardPersistency${index}`] = 'Persistency required!';
    }
  }
  return errors;
};

export default contestTargetValidation;

import {
  IResultDisclaimerMappingListDAO,
  IResultDisclaimerModuleListDAO,
} from '../../Modules/Disclaimer/DAO/disclaimer.dao';
import instance from '../interceptor';

export const getDisclaimerMappingAPI = async (): Promise<
  IResultDisclaimerMappingListDAO[]
> => {
  const { data } = await instance.get(
    `/configuration/disclaimer/module/mapping`
  );
  return data;
};

export const getDisclaimerModuleAPI = async (): Promise<
  IResultDisclaimerModuleListDAO[]
> => {
  const { data } = await instance.get(`/configuration/disclaimer/module`);
  return data;
};

export const mutateDisclaimerMappingAPI = async (params: any) => {
  const { disclaimerId, disclaimerModuleId, id } = params;
  const payload = { disclaimerId, disclaimerModuleId };
  if (id) {
    return await instance.put(
      `/configuration/disclaimer/module/mapping/${id}`,
      payload
    );
  } else {
    return await instance.post(
      `/configuration/disclaimer/module/mapping`,
      payload
    );
  }
};
 
import React from 'react';
import { Col, Row } from 'antd';
import HasPermission from '../../App/HasPermission';
import CLabelCard from '../../Assets/Components/CLabelCard';
import DashboardMostVisitedMenuContainer from './Container/DashboardMostVisitedMenuContainer';
import DashboardUniqueLoginContainer from './Container/DashboardUniqueLoginContainer';
import DashboardLoginTrackerContainer from './Container/DashboardLoginTrackerContainer';
import DashboardDosdContainer from './Container/DashboardDosdContainer';
import DashboardElearningContainer from './Container/DashboardElearningContainer';
import DashboardTrainerContainer from './Container/DashboardTrainerContainer';

export default function DashboardComponent() {
  return (
    <>
      <CLabelCard leftText="Dashboard" />
      {HasPermission('r', '/') ? (
        <Row gutter={40}>
          <Col span={16}>
            <DashboardMostVisitedMenuContainer />
            <DashboardUniqueLoginContainer />
          </Col>
          <Col span={8}>
            <DashboardLoginTrackerContainer />
          </Col>
        </Row>
      ) : null}
      {HasPermission('r', '/dashboard/dosd') ? (
        <Row gutter={40}>
          <DashboardDosdContainer />
        </Row>
      ) : null}
      {HasPermission('r', '/dashboard/elearning') ? (
        <Row>
          <DashboardElearningContainer />
        </Row>
      ) : null}
      {HasPermission('r', '/dashboard/trainer') ? (
        <Row>
          <DashboardTrainerContainer />
        </Row>
      ) : null}
    </>
  );
}

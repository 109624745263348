import instance from './interceptor';

export const createContentAPI = async params => {
  const repository = params.queryKey[1];
  const content = params.queryKey[2];
  const label = params.queryKey[3];
  const properties = params.queryKey[4];

  const payload = {
    repositorySymbolicName: repository,
    name: label,
    originalFileName: label,
    content,
    properties,
    // autoRotateContent: true,
  };
  const { data } = await instance.post(`/Content/CreateContent`, payload);
  return data;
};

export const inquiryContentAPI = async params => {
  const repository = params.queryKey[1];
  const query = params.queryKey[2];
  const { data } = await instance.get(
    `/Content/InquiryContent?repositorySymbolicName=${repository}&${query}`
  );
  return data;
};

export const getContentAPI = async params => {
  const contentId = params.queryKey[1];
  const { data } = await instance.get(
    `/Content/GetContent?contentId=${contentId}`
  );
  return data;
};

export const deleteContentAPI = async id => {
  return await instance.delete(`/Content/DeleteContent?contentId=${id}`);
};

import { Alert, Col, Row } from 'antd';
import React from 'react';
import { injectIntl } from 'react-intl';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import moment from 'moment';

interface IProps {
  modalDetailScheduleIsShow: boolean;
  handleCancel: () => void;
  selectedData: any;
}

function ModalDetailUploadScheduleComponent(props: IProps) {
  const { modalDetailScheduleIsShow, handleCancel, selectedData } = props;
  const uploadStatus = selectedData?.status === 'Failed' ? 'error' : 'success';

  const notifyUploadStatus = () => {
    const arrayError: any = [];
    const errorMsg = selectedData?.errorMessage.split('|');
    for (const iterator of errorMsg) {
      arrayError.push(iterator);
    }
    const totalError = errorMsg?.[0] !== '' ? errorMsg?.length - 1 : null;
    if (selectedData?.status === 'Success') {
      return <span>File uploaded successfully</span>;
    } else {
      return <span>{`${totalError} row data failed to upload`}</span>;
    }
  };

  const renderErrorMsg = () => {
    const arrayError: any = [];
    const errorMsg = selectedData.errorMessage.split('|');
    for (const iterator of errorMsg) {
      arrayError.push(iterator);
    }
    const dataComponent: any = [];
    for (const key in errorMsg) {
      if (Object.prototype.hasOwnProperty.call(errorMsg, key)) {
        const element = errorMsg[key];
        dataComponent.push(
          element === '' ? null : (
            <ul>
              <li key={key?.toString()}>{element}</li>
            </ul>
          )
        );
      }
    }
    return dataComponent;
  };

  const renderDetailUpload = (label: string, value: any) => {
    return (
      <Row style={{ marginBottom: 5 }}>
        <Col span={8}>{label}</Col>
        <Col span={1}>:</Col>
        <Col span={10}>{value}</Col>
      </Row>
    );
  };
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Detail Unggahan</span>
      </h3>
    );
  };

  const contentComponent = () => {
    const formatDOB = 'DD-MMM-YYYY';
    return (
      <React.Fragment>
        {renderDetailUpload('Nama Berkas', selectedData?.originalFilename)}
        {renderDetailUpload('Status Unggah', selectedData?.status)}
        {renderDetailUpload(
          'Tanggal Unggah',
          moment(selectedData?.uploadDate).format(formatDOB)
        )}
        <hr className="lineUploadLicense" />
        <Alert
          className="alertErrorModuleUploadHistory"
          message={notifyUploadStatus()}
          type={uploadStatus}
          showIcon
        />
        {renderErrorMsg()}
      </React.Fragment>
    );
  };
  return (
    <CModalRFF
      modalIsShow={modalDetailScheduleIsShow}
      headerComponent={headerComponent()}
      contentComponent={() => contentComponent()}
      footerComponent={() => {}}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={{}}
    />
  );
}

export default injectIntl(ModalDetailUploadScheduleComponent);

import moment from 'moment';
import instance from '../Services/interceptor';
import * as _ from 'lodash';

export const getSchemaCleanAPI = async (params: any) => {
  const { detailCandidate } = params?.queryKey[1];
  const { data } = await instance.get(
    `/form?Code=FRMCR0001.${detailCandidate.code}`
  );
  const schema = data[0];
  const documentAaji =
    schema.schema.properties.aaji.properties.documents.properties;
  const listValueDocumentAaji = Object.values(documentAaji);
  const document: any = {};
  for (const iterator of listValueDocumentAaji) {
    const { data: content } = await instance.get(
      `/Content/GetContent?contentId=${iterator}`
    );
    const newData: any = content;
    newData.content = `data:${content.properties.mime};base64, ${content.content}`;
    document[content.name] = newData;
  }
  const noteAaji = schema.schema.properties.aaji.properties.note.properties;
  const listItemPending: any = [];
  const listNoteAaji = Object.keys(noteAaji);
  if (listNoteAaji.length > 0) {
    for (const iterator of listNoteAaji) {
      const data = noteAaji[iterator];
      data.config.path = `aaji.properties.note.properties.${iterator}`;
      listItemPending.push(data);
    }
  }

  const qachecking = schema.schema.properties.qachecking.properties;

  // if (qachecking.customNote) {
  //   yield put(
  //     ActionQaClean.setCountCustomNote(
  //       Object.keys(qachecking.customNote.properties).length + 1
  //     )
  //   );
  // }

  const listKeyQaChecking = Object.keys(qachecking);
  if (listKeyQaChecking.length > 0) {
    for (const iterator of listKeyQaChecking) {
      const data = qachecking[iterator];
      if (iterator === 'beneficiary' || iterator === 'workexperience') {
        listItemPending.push(data);
      } else if (iterator === 'personaldata') {
        const dataProperties = data.properties;
        const listKeyDataProperties = Object.keys(dataProperties);
        for (const element of listKeyDataProperties) {
          const detailProperties = dataProperties[element]['properties'];
          if (element !== 'taxbankaccount') {
            const listValueDetailProperties = Object.values(detailProperties);
            for (const values of listValueDetailProperties) {
              listItemPending.push(values);
            }
          } else {
            const listKeyDetailProperties = Object.keys(detailProperties);
            for (const x of listKeyDetailProperties) {
              if (x !== 'hastax') {
                const listValuex = Object.values(
                  detailProperties[x]['properties']
                );
                for (const y of listValuex) {
                  listItemPending.push(y);
                }
              } else {
                listItemPending.push(detailProperties[x]['properties']);
              }
            }
          }
        }
      } else {
        const detailProperties = data.properties;
        const listValueDetailProperties = Object.values(detailProperties);
        for (const iterator of listValueDetailProperties) {
          listItemPending.push(iterator);
        }
      }
    }
  }

  return { qachecking, listItemPending, document, data };
};

export const submitSchemaPendingItemAPI = async (params: any) => {
  const { schemaQa, itemPending } = params;
  const newSchemaQa: any = schemaQa;
  const currentDate = moment();
  newSchemaQa.schema.config.timestamp = currentDate.toISOString();

  // PersonaData
  if (
    newSchemaQa.schema.properties.qachecking.properties.personaldata ===
    undefined
  ) {
    newSchemaQa.schema.properties.qachecking.properties.personaldata = {
      type: 'object',
      title: 'Data Pribadi',
      config: {
        web: {},
        mobile: {
          mode: 'wizard',
        },
      },
      properties: {
        agency: {
          type: 'object',
          title: 'Agency',
          isFormSection: true,
          isRequired: true,
          config: {
            web: {
              isHidden: false,
            },
            mobile: {
              isHidden: true,
            },
          },
          properties: {},
        },
        profile: {
          type: 'object',
          title: 'Profil',
          isFormSection: true,
          isRequired: true,
          properties: {},
        },
        addresseducation: {
          type: 'object',
          title: 'Alamat & Pendidikan',
          isFormSection: true,
          isRequired: true,
          properties: {},
        },
        taxbankaccount: {
          type: 'object',
          title: 'Bank & Pajak',
          isFormSection: true,
          isRequired: true,
          properties: {
            bank: {
              type: 'object',
              properties: {},
            },
            tax: {
              type: 'object',
              config: {
                isEnabled: false,
              },
              properties: {},
            },
          },
        },
      },
    };
  }

  for (const iterator of itemPending) {
    if (!iterator.config.path) {
      newSchemaQa.schema.properties.qachecking.properties.customNote = {
        type: 'object',
        config: {},
        properties: {},
      };
      break;
    }
  }

  for (const iterator of itemPending) {
    if (!iterator.config.path) {
      _.set(
        newSchemaQa,
        `schema.properties.qachecking.properties.customNote.properties.${iterator.config.name}`,
        iterator
      );
    } else {
      if (iterator.config.path === 'workexperience') {
        const newWorkexperience = {
          type: iterator.type,
          title: iterator.title,
          config: iterator.config,
          events: iterator.events,
          id: iterator.id,
          properties: {
            form: iterator.properties.form,
            list: iterator.properties.list,
          },
        };
        _.set(
          newSchemaQa,
          `schema.properties.qachecking.properties.${iterator.config.path}`,
          newWorkexperience
        );
      } else {
        _.set(
          newSchemaQa,
          `schema.properties.qachecking.properties.${iterator.config.path}`,
          iterator
        );
      }
    }
  }

  return await instance.post(`/form`, schemaQa);
};

export const submitQANotCleanAPI = async (params: any) => {
  const { caseId, taskId, username, schemaQa, itemPending, candidateLevel } =
    params;
  const newSchemaQa: any = schemaQa;
  const currentDate = moment();
  newSchemaQa.schema.config.timestamp = currentDate.toISOString();
  // PersonaData
  if (
    newSchemaQa.schema.properties.qachecking.properties.personaldata ===
    undefined
  ) {
    newSchemaQa.schema.properties.qachecking.properties.personaldata = {
      type: 'object',
      title: 'Data Pribadi',
      config: {
        web: {},
        mobile: {
          mode: 'wizard',
        },
      },
      properties: {
        agency: {
          type: 'object',
          title: 'Agency',
          isFormSection: true,
          isRequired: true,
          config: {
            web: {
              isHidden: false,
            },
            mobile: {
              isHidden: true,
            },
          },
          properties: {},
        },
        profile: {
          type: 'object',
          title: 'Profil',
          isFormSection: true,
          isRequired: true,
          properties: {},
        },
        addresseducation: {
          type: 'object',
          title: 'Alamat & Pendidikan',
          isFormSection: true,
          isRequired: true,
          properties: {},
        },
        taxbankaccount: {
          type: 'object',
          title: 'Bank & Pajak',
          isFormSection: true,
          isRequired: true,
          properties: {
            bank: {
              type: 'object',
              properties: {},
            },
            tax: {
              type: 'object',
              config: {
                isEnabled: false,
              },
              properties: {},
            },
          },
        },
      },
    };
  }

  for (const iterator of itemPending) {
    if (
      !iterator.config.path &&
      !newSchemaQa.schema.properties.qachecking.properties.customNote
    ) {
      newSchemaQa.schema.properties.qachecking.properties.customNote = {
        type: 'object',
        config: {},
        properties: {},
      };
      break;
    }
  }

  for (const iterator of itemPending) {
    if (!iterator.config.path) {
      _.set(
        newSchemaQa,
        `schema.properties.qachecking.properties.customNote.properties.${iterator.config.name}`,
        iterator
      );
    } else {
      if (iterator.config.path === 'workexperience') {
        const newWorkexperience = {
          type: iterator.type,
          title: iterator.title,
          config: iterator.config,
          events: iterator.events,
          id: iterator.id,
          properties: {
            form: iterator.properties.form,
            list: iterator.properties.list,
          },
        };
        _.set(
          newSchemaQa,
          `schema.properties.qachecking.properties.${iterator.config.path}`,
          newWorkexperience
        );
      } else {
        _.set(
          newSchemaQa,
          `schema.properties.qachecking.properties.${iterator.config.path}`,
          iterator
        );
      }
    }
  }
  await instance.post(`/form`, schemaQa);
  await instance.post(
    `/Candidate/workflow/approve/submission/${caseId}/NOTCLEAN`,
    {
      caseId,
      taskId,
      candidateLevel: candidateLevel,
      variables: {
        approver: {
          value: username,
        },
        approve: {
          value: true,
        },
        candidateLevel: {
          value: candidateLevel,
        },
        reason: {
          value: '',
        },
        qaStatus: {
          value: 'NOTCLEAN',
        },
      },
    }
  );
};

export const submitQACleanAPI = async (params: any) => {
  const {
    caseId,
    taskId,
    candidateLevel,
    schemaQa,
    agentType,
    classificationType,
    username,
  } = params;

  await instance.post(`/form`, schemaQa);
  await instance.post(
    `/Candidate/workflow/approve/submission/${caseId}/CLEAN`,
    {
      agentType,
      classificationType,
      caseId,
      taskId,
      candidateLevel: candidateLevel,
      variables: {
        approver: {
          value: username,
        },
        approve: {
          value: true,
        },
        candidateLevel: {
          value: candidateLevel,
        },
        reason: {
          value: '',
        },
        qaStatus: {
          value: 'CLEAN',
        },
      },
    }
  );
};

import { Col, Row } from 'antd';
import React from 'react';
import { Field } from 'react-final-form';
import { InputText } from '../../../../Assets/Components/CInput';
import Select from '../../../../Assets/Components/Select';
import { createListSelectBox } from '../../../../App/Helper';
import { InputDatePicker } from '../../../../Assets/Components/CInputDate';
import CButton from '../../../../Assets/Components/CButton';
import CModalRFF from '../../../../Assets/Components/CModalRFF';

interface IProps {
  modalEditWorkExperienceIsShow: boolean;
  handleCancel: () => void;
  schema: any;
  selectedWorkexperience: any;
  handleReset: () => void;
  handleUpdate: (values: any) => void;
}
export default function ModalEditWorkExperienceComponent(props: IProps) {
  const {
    modalEditWorkExperienceIsShow,
    handleCancel,
    schema,
    selectedWorkexperience,
    handleReset,
    handleUpdate,
  } = props;

  const required = (value: any) => (value ? undefined : 'Wajib Diisi!');

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Edit Pengalaman Kerja</h3>;
  };

  const contentComponent = (formProps: any) => {
    const values = formProps.values;
    const formError = formProps.errors;
    const databutton = [
      {
        type: 'primary',
        content: 'Simpan',
        id: 'btnSaveEditBeneficary',
        className: 'btnSaveEditBeneficary',
        onClick: () => handleUpdate(values),
        disabled: Object.keys(formError).length > 0 ? true : false,
      },
      {
        type: 'primary',
        content: 'Reset',
        id: 'btnResetEditBeneficary',
        className: 'btnResetRegisForm',
        onClick: handleReset,
      },
    ];

    const buttonBack = [
      {
        type: 'primary',
        onClick: handleCancel,
        content: 'Kembali',
        id: 'btnBackForm',
        className: 'btnBackRegsitration',
        icon: 'ArrowLeftOutlined',
      },
    ];

    if (schema) {
      const formWorkexperience =
        schema.schema.properties.workexperience.properties.form.properties;
      const valueForm = Object.values(formWorkexperience);
      const keysForm = Object.keys(formWorkexperience);
      const listFormWorkexperience: any = [];
      valueForm.forEach((data, index) => {
        const newData: any = data;
        newData.name = keysForm[index];
        listFormWorkexperience.push(newData);
      });
      return (
        <>
          <Row gutter={32}>
            {listFormWorkexperience.map((data: any, index: number) => {
              if (data.template === 'TextInput') {
                return (
                  <Col span={12} key={`formWorkexperience${index}`}>
                    <Field
                      name={data.name}
                      component={InputText}
                      idComponent={`InputEditComponent${data.name}`}
                      placeholder={data.config.placeholder}
                      label={data.title}
                      addtionalLabel={data.isRequired ? null : '(Optional)'}
                      textArea={data.config.multiline}
                      validate={data.isRequired ? required : undefined}
                    />
                  </Col>
                );
              } else if (data.template === 'SelectBox') {
                return (
                  <Col span={12} key={`formWorkexperience${index}`}>
                    <Field
                      name={data.name}
                      component={Select}
                      defaultItemName={data.config.placeholder}
                      label={data.title}
                      addtionalLabel={data.isRequired ? null : '(Optional)'}
                      validate={data.isRequired ? required : undefined}
                      dataOption={
                        data.enumValue
                          ? createListSelectBox(data.enum, data.enumValue)
                          : createListSelectBox(data.enum)
                      }
                    />
                  </Col>
                );
              }
              return (
                <Col span={12} key={`formWorkexperience${index}`}>
                  <Field
                    name={data.name}
                    component={InputDatePicker}
                    idComponent={`InputEditComponent${data.name}`}
                    typeDate={
                      data?.customTemplate ? 'dateRangePicker' : 'datePicker'
                    }
                    label={data?.title ? data?.title : data?.config?.web?.title}
                    addtionalLabel={data.isRequired ? null : '(Optional)'}
                    formatPicker={data.config.format ? 'year' : null}
                    formatInput={data.config.format ? 'YYYY' : null}
                    formClassName="registrationForm"
                  />
                </Col>
              );
            })}
          </Row>
          <CButton buttonData={databutton} />
          <hr className="lineSectionRegis" />
          <div className="containerButtonBack">
            <CButton buttonData={buttonBack} />
          </div>
        </>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={modalEditWorkExperienceIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={null}
      handleCancel={handleCancel}
      handleValidation={null}
      isForm={true}
      handleSubmit={() => {}}
      formLayout="vertical"
      initialValues={selectedWorkexperience}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '70vw',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

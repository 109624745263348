import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { injectIntl } from 'react-intl';
import ModalAgreementContentContainer from '../Container/ModalAgreementContentContainer';

interface IProps {
  columnData: any;
  intl: any;
  agreementContentList: any[];
  handleRefresh: () => void;
  modalIsShow: boolean;
  initialValues: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  isLoading: any;
}

function AgreementContentComponent(props: IProps) {
  const {
    columnData,
    intl,
    agreementContentList,
    handleRefresh,
    modalIsShow,
    setModalIsShow,
    initialValues,
    selectedData,
    setSelectedData,
    isLoading,
  } = props;
  const buttonData = [
    {
      type: 'primary',
      icon: 'PlusOutlined',
      onClick: () => setModalIsShow(true),
      content: 'Add New',
      id: 'btnAddAgreementContent',
      className: 'spacingBtnSecondRow2 btnRadius10',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshAgreementContent',
      className: 'btnRadius10',
      onClick: handleRefresh,
    },
  ];
  const cardAgreementContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton buttonData={buttonData} buttonFloat={'right'} />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={agreementContentList || []}
          pagination={true}
          useFilterGlobal
        />
        <ModalAgreementContentContainer
          showModal={modalIsShow}
          initialValues={initialValues}
          setModalIsShow={setModalIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          intl={intl}
          handleRefresh={handleRefresh}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Agreement" rightText="Content" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'agreementCardTitle',
            })}
          </span>
        }
        cardContent={cardAgreementContent()}
      />
    </>
  );
}
export default injectIntl(AgreementContentComponent);

import CKEditor from 'ckeditor4-react';
import { Form } from 'antd';
import React from 'react';

interface IProps {
  input: any;
  idComponent: string;
  placeholder: string;
  isMounted?: boolean;
  meta: any;
  initValues?: any;
  label?: string;
  disabled: boolean;
  className: string;
}
export const InputRichText = (props: IProps) => {
  const {
    input,
    meta: { error },
    idComponent,
    label,
    disabled,
    className,
    initValues,
  } = props;

  const handleOnChange = e => input.onChange(e.editor.getData());

  const labelTitle = label ? (
    <span>
      <b className={`capital ${className}`}>{label}</b>
    </span>
  ) : null;

  return (
    <Form.Item
      validateStatus={error !== undefined ? 'error' : ''}
      help={error !== undefined ? error : ''}
      label={labelTitle}
      colon={false}
    >
      <CKEditor
        onChange={handleOnChange}
        data={initValues || input.value}
        readOnly={disabled}
        id={idComponent}
        config={{ versionCheck: false }}
      />
    </Form.Item>
  );
};

const ReportValidation = ({ reportType, dateFilter }) => {
  const errors: any = {};
  if (!reportType) {
    errors.reportType = 'Report type required!';
  }
  if (!dateFilter || dateFilter === null) {
    errors.dateFilter = 'Date filter required!';
  }
  return errors;
};
export default ReportValidation;

import { IResultClassDetailNonSelfTrainingDAO, IResultClassNonSelfTrainingDAO } from '../Modules/ElearningNonSelfTraining/Class/DAO/classNonSelfTraining.dao';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getNonSelfTrainingClassAPI = async (
  params: UseQueryOptions
): Promise<IResultClassNonSelfTrainingDAO[]> => {
  const channel = params?.queryKey?.[1];
  const stage = params?.queryKey?.[2];
  const filter: any = params?.queryKey?.[3];
  const { status, class: classFilter, training } = filter || {};

  const { data } = await instance.get(
    `/Elearning/ClassNonSelf?channelid=${channel}&stageNonSelfId=${stage}${
      status === 'active'
        ? '&isActive=true'
        : status === 'inactive'
        ? '&isActive=false'
        : ''
    }`
  );

  let datafilterClass = data;
  if (classFilter) {
    datafilterClass = data.filter(item => {
      return item.type === classFilter;
    });
  }
  let datafilterTraining = datafilterClass;

  if (training) {
    datafilterTraining = datafilterTraining.filter(item => {
      return item.trainingType === training;
    });
  }
  return datafilterTraining;
};

export const deleteNonSelfTrainingClassAPI = async (id: string) => {
  return await instance.delete(`/Elearning/ClassNonSelf?id=${id}`);
};

export const mutateChangeStatusClass = async ({ id, status }) => {
  return await instance.put(
    `/Elearning/ClassNonSelf/status?id=${id}&isActive=${status}`
  );
};

export const mutateNonSelfTrainingAPI = async params => {
  const { code, name, headOfTrainer, signOffCaption, isActive, id } = params;
  const payload: any = { code, name, headOfTrainer, signOffCaption, isActive };
  if (!isActive) {
    payload.isActive = false;
  }
  payload.trainerName = headOfTrainer;
  payload.isNonSelf = true;

  if (id) {
    return await instance.put(`/Elearning/Class/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/Class`, payload);
  }
};

export const getClassDetailAPI = async (
  params: UseQueryOptions
): Promise<IResultClassDetailNonSelfTrainingDAO> => {
  const id = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Elearning/ClassNonSelf/Detail?id=${id}`
  );
  return data;
};

import { Steps } from 'antd';
import React from 'react';
import { FormRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { moduleValidation } from '../../Validation/moduleValidation';

interface IProps {
  currentSteps: number;
  isShow: boolean;
  modalAction: string;
  editMode: boolean;
  handleBack: () => void;
  handleCancel: () => void;
  handleEditClick: () => void;
  handleNext: () => void;
  handleSubmit: (val: any) => void;
  handleStep: (step: any) => void;
  initialValues: any;
  isLoading: boolean;
  isLoadingSubmit: boolean;
  steps: any;
  errorFormValue?: any;
  hasErrors?: any;
}

const { Step } = Steps;

export default function ModuleModalComponent(props: IProps) {
  const {
    currentSteps,
    modalAction,
    editMode,
    isShow,
    handleBack,
    handleCancel,
    handleEditClick,
    handleNext,
    handleSubmit,
    handleStep,
    initialValues,
    isLoading,
    isLoadingSubmit,
    steps,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailElearningModuleContent" />
        ) : (
          <FormattedMessage id="addElearningModuleContent" />
        )}
      </h3>
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { form, errors, values } = formProps;
    return (
      <div>
        <div className="containerStepSubModul">
          <Steps
            labelPlacement="vertical"
            className="stepsSubModule"
            current={currentSteps}
            onChange={handleStep}
          >
            {steps.map(step => (
              <Step
                key={step.title}
                title={step.title}
                disabled={step.disabled}
              />
            ))}
          </Steps>
        </div>
        <div className="containerContentStepSubModule">
          <h2 className="TitleStepsSubModul">{steps[currentSteps].title}</h2>
          {React.cloneElement(steps[currentSteps].content, {
            form,
            formError: errors,
            formValues: values,
          })}
        </div>
      </div>
    );
  };

  // const contentComponent = (formProps: FormRenderProps<any, any>) => {
  //   const { form, values } = formProps;
  //   const disabled =
  //     editMode === false && modalAction === 'update' ? true : false;

  //   if (values.isCertificate !== undefined && values.isCertificate === false)
  //     form.change('certificateId', undefined);

  //   return (
  //     <Skeleton active loading={isLoading}>
  //       <div style={{ display: 'flex', flexDirection: 'column' }}>
  //         <Field
  //           name="name"
  //           idComponent="inputModuleName"
  //           component={InputText}
  //           placeholder="Type Here"
  //           disabled={disabled}
  //           label="Module Name"
  //         />
  //       </div>
  //       <div style={{ display: 'flex', gap: '50px' }}>
  //         <div style={{ display: 'flex', flexDirection: 'column' }}>
  //           <Field
  //             name="predecessor"
  //             component={Select}
  //             idComponent="inputPredecessor"
  //             dataOption={predecessorList}
  //             onSelect={e => handleAddtionalSelectPredecessor(e, form)}
  //             placeholder="-Select-"
  //             disabled={disabled}
  //             label="Predecessor"
  //           />
  //         </div>
  //         <div style={{ display: 'flex', flexDirection: 'column' }}>
  //           <Field
  //             name="sequence"
  //             component={InputTextNumber}
  //             addtionalOnChange={e => handleAddtionalOnChangeSequence(e, form)}
  //             idComponent="inputSequence"
  //             placeholder="Type Here"
  //             disabled={disabled}
  //             label="Sequence"
  //             min={1}
  //           />
  //         </div>
  //       </div>
  //       <div style={{ marginBottom: '30px' }}>
  //         <Field
  //           name="isCertificate"
  //           idComponent="inputCertificate"
  //           component={InputChecbox}
  //           disabled={disabled}
  //           // onChange={handleResetListSertifikat}
  //         />
  //         <label style={{ marginLeft: '10px' }}>Have Certificate</label>
  //       </div>
  //       <Field
  //         name="certificateId"
  //         idComponent="selectCertificate"
  //         component={Select}
  //         disabled={disabled}
  //         dataOption={certificateList || []}
  //         placeholder="-Select-"
  //         label="List Sertifikat"
  //       />
  //       <div style={{ display: 'flex', flexDirection: 'column' }}>
  //         <label className="labelInputFormModal">Status</label>
  //         <Field
  //           name="isActive"
  //           idComponent="inputStatus"
  //           component={InputSwitch}
  //           disabled={disabled}
  //         />
  //       </div>
  //     </Skeleton>
  //   );
  // };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { errors, values } = propsFooter;
    const currentTabModal = steps[currentSteps].id;
    let disabledNext = true;
    let disabledSubmit = errors?.[currentTabModal] ? true : false;
    if (currentTabModal === 'exam') {
      if (!values.exam) disabledNext = false;
      else disabledNext = errors?.[currentTabModal] ? true : false;
    } else if (currentTabModal === 'startTime') {
      if (!values.startTime) disabledNext = false;
      else disabledNext = errors?.[currentTabModal] ? true : false;
    } else if (currentTabModal === 'expiredTime') {
      // if (!values.expiredTime) disabledSubmit = false;
      // disabledSubmit = errors?.[currentTabModal] ? true : false;
    } else disabledNext = errors?.[currentTabModal] ? true : false;

    const dataButtonModalSubModule = [
      currentSteps !== 0
        ? {
            type: 'primary',
            content: 'Kembali',
            id: 'btnBackForm',
            className: 'btnBackRegsitration',
            icon: 'ArrowLeftOutlined',
            onClick: handleBack,
          }
        : null,
      modalAction === 'update' && !editMode
        ? {
            type: 'primary',
            content: 'Edit',
            id: 'btnEditForm',
            className: 'btnEditRegsitration',
            icon: 'EditOutlined',
            onClick: handleEditClick,
          }
        : null,
      currentSteps < steps.length - 1
        ? {
            type: 'primary',
            content: (
              <div>
                Lanjutkan{' '}
                <CIcon
                  type="ArrowRightOutlined"
                  style={{ marginLeft: '3px' }}
                />
              </div>
            ),
            id: 'btnNextForm',
            className: 'btnNextRegistration',
            onClick: handleNext,
            disabled: disabledNext,
          }
        : null,
      currentSteps === steps.length - 1 &&
      ((modalAction === 'update' && editMode) ||
        modalAction === 'register' ||
        modalAction === 'copy')
        ? {
            type: 'primary',
            content: modalAction !== 'update' ? 'Create' : 'Simpan',
            id: 'btnSaveForm',
            className: 'btnSaveSubmodule',
            onClick: () => handleSubmit(values),
            disabled: disabledSubmit,
          }
        : null,
    ];

    return (
      <div className="ContainerButtonSubModule">
        <CButton
          buttonData={dataButtonModalSubModule}
          isLoading={isLoadingSubmit}
        />
      </div>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={moduleValidation}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      isLoading={isLoading}
      initialValues={initialValues}
    />
  );
}

import React, { useState } from 'react';
import { mutateAgreementContentAPI } from '../../../Services/Agreement/agreementContent.api';
import ModalAgreementContentComponent from '../Component/ModalAgreementContentComponent';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  showModal: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  handleRefresh: () => void;
}

export default function ModalAgreementContentContainer(props: IProps) {
  const {
    showModal,
    setModalIsShow,
    selectedData,
    setSelectedData,
    handleRefresh,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const modalAction = selectedData ? 'update' : 'register';
  let initialValues: any = selectedData;

  const mutateAgreementContent = useMutation(mutateAgreementContentAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} agreement content!
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });
  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateAgreementContent.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  const handleCancel = () => {
    setSelectedData(undefined);
    setModalIsShow(false);
    setEditMode(false);
  };
  return (
    <ModalAgreementContentComponent
      {...props}
      isShow={showModal}
      handleCancel={handleCancel}
      initialValues={initialValues}
      modalAction={modalAction}
      editMode={editMode}
      disabled={editMode === false && modalAction === 'update' ? true : false}
      handleSubmit={handleSubmit}
      isLoading={mutateAgreementContent.isLoading}
    />
  );
}

import { IResultDisclaimerContentListDAO } from '../../Modules/Disclaimer/DAO/disclaimer.dao';
import instance from '../interceptor';

export const getDisclaimerContentAPI = async (): Promise<
  IResultDisclaimerContentListDAO[]
> => {
  const { data } = await instance.get(`/configuration/disclaimer`);
  return data;
};

export const mutateStatusDisclaimerContentAPI = async (params: any) => {
  const { id, isActive } = params;
  const action = isActive === false ? 'inactive' : 'active';
  return await instance.put(`/configuration/disclaimer/${action}/${id}`);
};

export const mutateDisclaimerContentAPI = async (params: any) => {
  const { name, desc, id } = params;
  const payload = { name, desc };
  if (id) {
    return await instance.put(`/configuration/disclaimer/${id}`, payload);
  } else {
    return await instance.post(`/configuration/disclaimer`, payload);
  }
};

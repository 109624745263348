import React, { useEffect, useState } from 'react';
import { IntlShape } from 'react-intl';
import ModalContentCleanComponent from '../../../Component/Modal/ModalContent/ModalContentCleanComponent';
import { toast } from 'react-toastify';
import * as _ from 'lodash';
import {
  submitQACleanAPI,
  submitQANotCleanAPI,
  submitSchemaPendingItemAPI,
} from '../../../../../Services/qaProcessClean.api';
import { useMutation, useQuery } from '@tanstack/react-query';
import Store from '../../../../../Store';
import { getPendingRemarksAPI } from '../../../../../Services/catatanPending.api';
import ErrorHandler from '../../../../../App/ErrorHandler';

interface IProps {
  intl: IntlShape;
  modalContentCleanIsShow: boolean;
  setModalContentCleanIsShow: any;
  qaStatus: any;
  formValue: any;
  form: any;
  selectedData: any;
  schema: any;
  schemaCandidate: any;
  setQaStatus: any;
  setModalDetailIsShow: any;
  refreshList: any;
  refreshSchema: any;
}
export default function ModalContentCleanContainer(props: IProps) {
  const {
    modalContentCleanIsShow,
    intl,
    qaStatus,
    formValue,
    form,
    schema,
    schemaCandidate,
    setQaStatus,
    setModalDetailIsShow,
    refreshList,
    refreshSchema,
  } = props;
  const [pendingItemList, setPendingItemList] = useState<any>([]);
  const [listItemPending, setListItemPending] = useState<any>([]);
  const [countCustomNote, setCountCustomNote] = useState(0);

  useEffect(() => {
    setListItemPending(schema?.listItemPending || []);
  }, [schema]);

  const { data: pendingRemarksList } = useQuery(
    ['getPendingRemarksQA'],
    getPendingRemarksAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: mutateSchemaPendingItem, isLoading: isLoadingPendingItem } =
    useMutation(submitSchemaPendingItemAPI, {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        setModalDetailIsShow(false);
        refreshList();
        refreshSchema();
        const toastMessage = (
          <span className="capitalFirst">Data berhasil diperbarui</span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
      },
    });

  const { mutate: mutateQANotClean, isLoading: isLoadingNotClean } =
    useMutation(submitQANotCleanAPI, {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        setModalDetailIsShow(false);
        refreshList();
        refreshSchema();
        const toastMessage = (
          <span className="capitalFirst">
            Data berhasil dilanjutkan ke Not Clean
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
      },
    });
  const { mutate: mutateQAClean, isLoading: isLoadingClean } = useMutation(
    submitQACleanAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        setModalDetailIsShow(false);
        refreshList();
        refreshSchema();
        const toastMessage = (
          <span className="capitalFirst">
            Data berhasil dilanjutkan ke Clean
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
      },
    }
  );

  const handleSubmitPendingQA = (x: any, vals: any) => {
    const authState: any = Store.getState();
    const username =
      authState.auth[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'
      ];

    if (x === 'pendingItem') {
      mutateSchemaPendingItem({
        ...vals,
        schemaQa: schema?.data[0],
        itemPending: pendingItemList,
      });
    } else {
      if (qaStatus === 'Not Clean') {
        mutateQANotClean({
          ...vals,
          schemaQa: schema?.data[0],
          username: username,
          itemPending: pendingItemList,
        });
      } else {
        mutateQAClean({
          ...vals,
          agentType: formValue?.agentType,
          classificationType: formValue?.classificationType,
          schemaQa: schema?.data[0],
          username: username,
          itemPending: pendingItemList,
        });
      }
    }
    form.change('pendingTemplate', null);
    form.change('pendingCustom', null);
    form.change('pendingRemarks', 'template');
    form.change('agentType', null);
    form.change('agentClassification', null);
  };

  const validationPendingItem = (path: any, newData: any) => {
    if (pendingItemList?.length > 0) {
      const myArray = pendingItemList?.filter((obj: any) => {
        return obj.config.path === path;
      });
      if (myArray.length > 0) {
        toast.error('Item pending sudah ada');
      } else {
        const newItemPending: any = pendingItemList;
        newItemPending.push(newData);
        setPendingItemList(newItemPending);
      }
    } else {
      const newItemPending: any = pendingItemList;
      newItemPending.push(newData);
      setPendingItemList(newItemPending);
    }
  };

  const handleAddItemPending = () => {
    if (formValue.pendingRemarks === 'template') {
      const path = formValue.pendingTemplate.split('_')[0];
      const note = formValue.pendingTemplate.split('_')[1];
      if (path === '') {
        const newData: any = {
          config: {
            pendingStatus: 'active',
            name: `customNote${countCustomNote}`,
          },
          template: 'LabelField',
          title: note,
          id: Math.random(),
        };
        setCountCustomNote(countCustomNote + 1);
        const newItemPending: any = pendingItemList;
        newItemPending.push(newData);
        setPendingItemList(newItemPending);
      } else {
        const newData: any = _.get(schemaCandidate.schema.properties, path);
        newData.id = Math.random();
        if (!newData.config) {
          newData.config = {};
        }
        newData.config.pendingStatus = 'active';
        newData.config.note = note;
        newData.config.path = path;
        validationPendingItem(path, newData);
      }
    } else {
      const newData: any = {
        config: {
          pendingStatus: 'active',
          name: `customNote${countCustomNote}`,
        },
        template: 'LabelField',
        title: formValue.pendingCustom,
        type: 'string',
        id: Math.random(),
      };
      setCountCustomNote(countCustomNote + 1);
      const newItemPending: any = pendingItemList;
      newItemPending.push(newData);
      setPendingItemList(newItemPending);
    }
    form.change('pendingTemplate', null);
    form.change('pendingCustom', null);
    form.change('pendingRemarks', null);
  };

  const handleSelectNotes = () => {
    form.change('pendingTemplate', null);
    form.change('pendingCustom', null);
  };

  const handleDelete = (id: any) => {
    const myArray = pendingItemList?.filter(obj => {
      return obj.id !== id;
    });
    setPendingItemList(myArray);
  };

  const handleNotClean = () => {
    setQaStatus('Not Clean');
    form.change('agentType', null);
    form.change('agentClassification', null);
  };

  const handleClean = () => {
    setQaStatus('Clean');
    form.change('pendingTemplate', null);
    form.change('pendingCustom', null);
    form.change('pendingRemarks', 'template');
    setPendingItemList([]);
  };

  const handleChangePendingStatus = (e: any, path: any) => {
    const newSchemaQa = schema.data[0].schema.properties;
    const section = path.split('.')[0];
    const index = listItemPending.findIndex(
      i => path === i.config.path || path === i.config.name
    );
    let newPath: any;
    if (section.includes('custom')) {
      newPath = `qachecking.properties.customNote.properties.${path}.config.pendingStatus`;
    } else if (section.includes('aaji')) {
      newPath = `${path}.config.pendingStatus`;
    } else {
      newPath = `qachecking.properties.${path}.config.pendingStatus`;
    }
    if (e) {
      _.set(newSchemaQa, newPath, 'active');
      const newArr: any = [];
      listItemPending.forEach((x, i) => {
        if (i !== index) newArr.push(x);
        else {
          newArr.push({
            ...listItemPending[index],
            config: {
              ...listItemPending[index].config,
              pendingStatus: 'active',
            },
          });
        }
      });
      setListItemPending(newArr);
    } else {
      _.set(newSchemaQa, newPath, 'finish');
      const newArr: any = [];
      listItemPending.forEach((x, i) => {
        if (i !== index) newArr.push(x);
        else {
          newArr.push({
            ...listItemPending[index],
            config: {
              ...listItemPending[index].config,
              pendingStatus: 'finish',
            },
          });
        }
      });
      setListItemPending(newArr);
    }
  };

  return (
    <ModalContentCleanComponent
      modalContentCleanIsShow={modalContentCleanIsShow}
      intl={intl}
      statusQa={qaStatus}
      pendingRemarksList={pendingRemarksList || []}
      formValue={formValue}
      handleSelectNotes={handleSelectNotes}
      listPendingItem={pendingItemList || []}
      listItemPending={listItemPending}
      documentsAaji={schema?.document || {}}
      handleAddItemPending={handleAddItemPending}
      handleDelete={handleDelete}
      handleNotClean={handleNotClean}
      handleClean={handleClean}
      handleChangePendingStatus={handleChangePendingStatus}
      handleSubmitPendingQA={handleSubmitPendingQA}
      isLoading={isLoadingNotClean || isLoadingClean || isLoadingPendingItem}
    />
  );
}

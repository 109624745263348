import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { injectIntl } from 'react-intl';
import ModalAgreementMappingContainer from '../Container/ModalAgreementMappingContainer';

interface IProps {
  columnData: any;
  intl: any;
  agreementMappingList: any[];
  handleRefresh: () => void;
  modalIsShow: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  initialValues: any;
  isLoading: any;
  agreementContentList: any[];
  agreementModuleList: any[];
}
function AgreementMappingComponent(props: IProps) {
  const {
    columnData,
    intl,
    agreementMappingList,
    handleRefresh,
    modalIsShow,
    setModalIsShow,
    selectedData,
    setSelectedData,
    initialValues,
    isLoading,
    agreementContentList,
    agreementModuleList,
  } = props;
  const buttonData = [
    {
      type: 'primary',
      icon: 'PlusOutlined',
      onClick: () => setModalIsShow(true),
      content: 'Add New',
      id: 'btnAddAgreementMapping',
      className: 'spacingBtnSecondRow2 btnRadius10',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshAgreementMapping',
      className: 'btnRadius10',
      onClick: handleRefresh,
    },
  ];
  const cardAgreementMapping = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton buttonData={buttonData} buttonFloat={'right'} />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={agreementMappingList || []}
          pagination={true}
          useFilterGlobal
        />
        <ModalAgreementMappingContainer
          showModal={modalIsShow}
          setModalIsShow={setModalIsShow}
          intl={intl}
          handleRefresh={handleRefresh}
          initialValues={initialValues}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          agreementContentList={agreementContentList}
          agreementModuleList={agreementModuleList}
        />
      </div>
    );
  };
  return (
    <>
      <CLabelCard leftText="Agreement" rightText="Mapping" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'agreementMappingCardTitle',
            })}
          </span>
        }
        cardContent={cardAgreementMapping()}
      />
    </>
  );
}
export default injectIntl(AgreementMappingComponent);

import {
  IResultCityHistoryListDAO,
  IResultCityListDAO,
} from '../Modules/City/DAO/city.dao';
import instance from '../Services/interceptor';

export const getCityListAPI = async (): Promise<IResultCityListDAO[]> => {
  const { data } = await instance.get(`/configuration/regions`);
  return data;
};

export const getCityHistoryListAPI =
  async (): Promise<IResultCityHistoryListDAO[]> => {
    const { data } = await instance.get(`/configuration/regions/history/excel`);
    return data;
  };

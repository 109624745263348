import React, { useEffect, useState } from 'react';
import ModalEditBeneficiaryComponent from '../../Component/Modal/ModalEditBeneficiaryComponent';

interface IProps {
  modalEditBeneficiaryIsShow: boolean;
  setModalEditBeneficiaryIsShow: any;
  schemaData: any;
  selectedBeneficiary: any;
  setSelectedBeneficiary: any;
  listbeneficiary: any;
  setNewListBeneficiary: any;
}
export default function ModalEditBeneficiaryContainer(props: IProps) {
  const {
    modalEditBeneficiaryIsShow,
    setModalEditBeneficiaryIsShow,
    schemaData,
    selectedBeneficiary,
    setSelectedBeneficiary,
    listbeneficiary,
    setNewListBeneficiary,
  } = props;

  const [initialValues, setinitialValues] = useState(null);

  const handleCancel = () => {
    setModalEditBeneficiaryIsShow(false);
    setinitialValues(null);
    setSelectedBeneficiary(null);
  };

  const initialValue: any = {};
  useEffect(() => {
    if (selectedBeneficiary) {
      if (selectedBeneficiary?.mobilephone) {
        initialValue.mobilephone = selectedBeneficiary?.mobilephone?.substring(
          3,
          selectedBeneficiary?.mobilephone?.length + 1
        );
      }
      if (selectedBeneficiary?.homephone) {
        initialValue.homephone = selectedBeneficiary?.homephone?.substring(
          3,
          selectedBeneficiary?.homephone?.length + 1
        );
      }
      initialValue.fullname = selectedBeneficiary?.fullname;
      initialValue.relationship = selectedBeneficiary?.relationship;
      initialValue.address = selectedBeneficiary?.address;
      initialValue.job = selectedBeneficiary?.job
        ? selectedBeneficiary?.job
        : '';
      setinitialValues(initialValue);
    } else {
      setinitialValues(null);
    }
    // eslint-disable-next-line
  }, [selectedBeneficiary]);

  const handleReset = () => {
    setSelectedBeneficiary(null);
  };

  const handleUpdate = (formValue: any) => {
    for (const iterator of listbeneficiary) {
      if (iterator.id === selectedBeneficiary.id) {
        iterator.id = selectedBeneficiary.id;
        iterator.fullname = formValue.fullname;
        iterator.relationship = formValue.relationship;
        iterator.job = formValue.job;
        iterator.homephone = formValue.homephone
          ? `+62${formValue.homephone}`
          : '';
        iterator.mobilephone = `+62${formValue.mobilephone}`;
        iterator.address = formValue.address;
      }
    }
    setModalEditBeneficiaryIsShow(false);
    setNewListBeneficiary(listbeneficiary);
    setSelectedBeneficiary(null);
    setinitialValues(null);
  };

  return (
    <ModalEditBeneficiaryComponent
      modalEditBeneficiaryIsShow={modalEditBeneficiaryIsShow}
      handleCancel={handleCancel}
      schema={schemaData || []}
      selectedBeneficiary={initialValues}
      handleReset={handleReset}
      handleUpdate={handleUpdate}
    />
  );
}

import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import { Field } from 'react-final-form';
import { InputText } from '../../../Assets/Components/CInput';
import { InputRichText } from '../../../Assets/Components/CRichText';
import CIcon from '../../../Assets/Components/CIcon';
import { Button, Skeleton } from 'antd';
import validate from '../Validation/DisclaimerContentValidation';

interface IProps {
  isShow: boolean;
  initialValues: any;
  modalAction: string;
  intl: any;
  editMode: boolean;
  disabled: boolean;
  isLoading: any;
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
}

export default function ModalDisclaimerContentComponent(props: IProps) {
  const {
    isShow,
    initialValues,
    modalAction,
    editMode,
    disabled,
    isLoading,
    handleCancel,
    handleSubmit,
    intl,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailDisclaimerContent" />
        ) : (
          <FormattedMessage id="addDisclaimerContent" />
        )}
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    return (
      <>
        <Skeleton active loading={isLoading}>
          <Field
            name="name"
            component={InputText}
            idComponent="inputDisclaimerName"
            placeholder={intl.formatMessage({
              id: 'placeholderDisclaimerName',
            })}
            label={intl.formatMessage({ id: 'disclaimerName' })}
            editMode={editMode}
            disabled={disabled}
          />
          <Field
            name="desc"
            component={InputRichText}
            idComponent="inputDisclaimerContent"
            label={intl.formatMessage({ id: 'disclaimerContent' })}
            editMode={editMode}
            disabled={disabled}
          />
        </Skeleton>
      </>
    );
  };
  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };
  const footerComponent = (propsFooter: any) => {
    const { invalid } = propsFooter;
    return (
      <Button
        loading={isLoading}
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        icon={<CIcon type="CheckOutlined" />}
        disabled={modalAction === 'update' && !editMode ? false : invalid}
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(modalAction, editMode)}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="horizontal"
      initialValues={initialValues}
    />
  );
}

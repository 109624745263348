import React from 'react';
import { Alert, Col, Row } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CButton from '../../../../Assets/Components/CButton';
import CCard from '../../../../Assets/Components/CCard';
import CModalRFF from '../../../../Assets/Components/CModalRFF';

interface IProps {
  modalIsShow: boolean;
  handleCancel: () => void;
}

export default function ModalNotificationLicenseComponent(props: IProps) {
  const { modalIsShow, handleCancel } = props;
  const defaultModalstyles = {
    content: {
      position: 'relative',
      background: 'none',
      maxWidth: '95vw',
      width: '500px',
      padding: '0px',
      border: 'none',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    overlay: {
      background: 'rgba(0, 0, 0, 0.75)',
      zIndex: '99',
    },
  };

  const buttonData = [
    {
      type: 'primary',
      className: 'btnCloseNotificationModal',
      onClick: handleCancel,
      content: 'Tutup',
      id: 'btnCloseNotification',
    },
  ];

  const notifContent = () => {
    const messageTitleAlert = () => {
      return (
        <>
          <Row>
            <Col span={24}>
              <p className="licenseWarningIconCenter">
                <ExclamationCircleOutlined className="colorIconWarning" />
              </p>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <p className="licenseTextCenter">
                Calon agent ini Belum Lulus Exam!
              </p>
            </Col>
          </Row>
        </>
      );
    };
    return (
      <>
        <Alert
          message={messageTitleAlert()}
          description={null}
          type="warning"
        />
        <Row>
          <Col span={24} className="licenseTextCenter">
            Pengajuan aktivasi agen belum dapat dilakukan, silahkan coba lagi
            nanti
          </Col>
        </Row>
        <Row>
          <Col span={24} className="licenseTextCenter">
            <CButton buttonData={buttonData} />
          </Col>
        </Row>
      </>
    );
  };

  const contentComponent = () => {
    return (
      <CCard
        cardClassName="cardLicense"
        cardTitle={null}
        cardContent={notifContent()}
      />
    );
  };

  return (
    <CModalRFF
      handleSubmit={() => {}}
      initialValues={[]}
      modalIsShow={modalIsShow}
      headerComponent={null}
      contentComponent={contentComponent()}
      footerComponent={null}
      handleCancel={handleCancel}
      contentClassName="contentLicense"
      modalstyles={defaultModalstyles}
    />
  );
}

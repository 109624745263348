import { useEffect, useState } from 'react';

import NonSelfTrainingComponent from './NonSelfTrainingComponent';
import React from 'react';

export default function NonSelfTrainingContainer() {
  const [tabData, setTabData] = useState<{
    channel: any;
    class: any;
    stage: any;
  }>({
    channel: undefined,
    class: undefined,
    stage: undefined,
  });
  const { channel, stage } = tabData;

  const [breadCrumbData, setBreadCrumbData]: any = useState({
    breadcrumbItem: [],
    breadcrumbTitle: [],
    breadcrumbItemClick: () => {},
  });

  const handleOnTabClick = (params: string) => {
    if (params === 'Channel') {
      setTabData(prev => ({
        stage: undefined,
        channel: undefined,
        class: undefined,
      }));
    } else if (params === 'Stage') {
      setTabData(prev => ({ ...prev, stage: undefined, class: undefined }));
    } else if (params === 'Class') {
      setTabData(prev => ({ ...prev, class: undefined }));
    }
  };

  const handleOnClickBreadcrumb = item => {
    if (item === channel.name) {
      setTabData(prev => ({
        stage: undefined,
        channel: undefined,
        class: undefined,
      }));
    } else if (item === stage.name) {
      setTabData(prev => ({ ...prev, stage: undefined, class: undefined }));
    } else if (item === tabData.class.name) {
      setTabData(prev => ({ ...prev, class: undefined }));
    }
  };

  useEffect(() => {
    const tempBreadCrumbData = { ...breadCrumbData };

    if (tabData.class) {
      tempBreadCrumbData.breadcrumbItem = [
        channel?.name,
        stage?.name,
        tabData.class?.name,
      ];
      tempBreadCrumbData.breadcrumbTitle = [
        'Channel',
        'Stage',
        'Class',
        'Content',
      ];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else if (stage) {
      tempBreadCrumbData.breadcrumbItem = [channel?.name, stage?.name];
      tempBreadCrumbData.breadcrumbTitle = ['Channel', 'Stage', 'Class'];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else if (channel) {
      tempBreadCrumbData.breadcrumbItem = [channel?.name];
      tempBreadCrumbData.breadcrumbTitle = ['Channel', 'Stage'];
      tempBreadCrumbData.breadcrumbItemClick = handleOnClickBreadcrumb;
    } else {
      tempBreadCrumbData.breadcrumbItem = [];
      tempBreadCrumbData.breadcrumbTitle = ['Channel'];
    }
    setBreadCrumbData(tempBreadCrumbData);
    // eslint-disable-next-line
  }, [tabData]);

  return (
    <NonSelfTrainingComponent
      tabData={tabData}
      setTabData={setTabData}
      handleOnTabClick={handleOnTabClick}
      breadCrumbData={breadCrumbData}
    />
  );
}

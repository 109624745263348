import instance from '../interceptor';

const getLookupAgent = async params => {
  const { data } = await instance.get(`/Lookup/configuration/agentCode`);
  const listLookupAgent: any = [];
  for (const item of data) {
    listLookupAgent.push({
      id: `${item.agentCode}_${item.agentName}`,
      name: `${item.agentCode} - ${item.agentName}`,
    });
  }
  return listLookupAgent;
};

const getSubmodule = async params => {
  const { moduleId } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/SubModule?moduleId=${moduleId}`
  );
  return data;
};

const getSubmoduleDetail = async params => {
  const { submoduleId } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/SubModule/detail?subModuleId=${submoduleId}`
  );
  return data;
};

const deleteSubmodule = async (id: string) => {
  return await instance.delete(`/Elearning/SubModule/${id}`);
};

const mutateSubmoduleStatus = async (params: {
  submoduleId: number;
  status?: boolean;
}) => {
  const { submoduleId, status } = params;
  const { data } = await instance.put(
    `Elearning/SubModule/status/${submoduleId}/${
      status !== undefined ? status : false
    }`
  );
  return data;
};

interface IProps {
  general: {
    name: string;
    description: string;
    predecessor: string;
    sequence: number;
    effectiveDate: { _d: string }[];
    version: string;
    versionFirst: number;
    versionSecond: number;
    cpdRefreshName?: string;
    isRefreshment?: boolean;
    isCpdStatus?: boolean;
    isPublish?: boolean;
  };
  exam: {
    type: string;
    questionLimit: number;
    duration: number;
    minimumScore: number;
    maxAttempt: number;
  };
  levelMapping: { isNeedAssignedUser?: boolean };
  startTime: {
    licenseStatus: string[];
    otherCondition: string[];
    joinDateCondition: string;
    licenseDateCondition: string;
    afterCondition: string;
    joinDateDays: number;
    licenseDateDays: number;
    afterDateDays: number;
  };
  expiredTime: {
    licenseStatus: string[];
    otherCondition: string[];
    expiredAfterCondition: string;
    expiredDays: number;
  };
  listUserLevel: {
    id: string;
    name: string;
  }[];
  listAgent: {
    agentCode: string;
    agentName: string;
  }[];
  additionalConditionStart: {
    id: number;
    period: number;
    condition: string;
    contentLevel: string;
    contentId: number;
  }[];
  additionalConditionExpired: {
    id: number;
    period: number;
    condition: string;
    contentLevel: string;
    contentId: number;
  }[];
  channelId: number;
  stageId: number;
  moduleId: number;
  submoduleId: number;
  codemiLearningPathId: string;
  isActive?: boolean;
}

const mutateSubmodule = async (params: IProps) => {
  const {
    general,
    exam,
    levelMapping,
    startTime,
    expiredTime,
    listUserLevel,
    listAgent,
    additionalConditionStart,
    additionalConditionExpired,
    channelId,
    stageId,
    moduleId,
    submoduleId,
    codemiLearningPathId,
    // isActive,
  } = params;

  const levels: any[] = [];
  listUserLevel.forEach(item => {
    const splitId = item.id.split('_');
    levels.push({
      level: splitId[0],
      description: splitId[1],
    });
  });

  const additionalStarts: any[] = [];
  additionalConditionStart.forEach(item => {
    additionalStarts.push({
      period: item.period.toString(),
      condition: item.condition,
      contentLevel: item.contentLevel,
      contentId: item.contentId,
    });
  });
  const listOtherConditionStart: any = [];
  if (startTime && startTime.otherCondition)
    for (const condition of startTime.otherCondition) {
      if (condition === 'joinDate')
        listOtherConditionStart.push(
          `${condition},${startTime.joinDateCondition},${startTime.joinDateDays}`
        );
      else if (condition === 'licenseDate')
        listOtherConditionStart.push(
          `${condition},${startTime.licenseDateCondition},${startTime.licenseDateDays}`
        );
      else
        listOtherConditionStart.push(
          `${condition},${startTime.afterCondition},${startTime.afterDateDays}`
        );
    }

  const additionalExpireds: any[] = [];
  additionalConditionExpired.forEach(item => {
    additionalExpireds.push({
      period: item.period.toString(),
      condition: item.condition,
      contentLevel: item.contentLevel,
      contentId: item.contentId,
    });
  });
  const listOtherConditionExpired: any = [];
  if (expiredTime && expiredTime.otherCondition)
    for (const condition of expiredTime.otherCondition) {
      if (condition === 'expiredAfter')
        listOtherConditionExpired.push(
          `${condition},${expiredTime.expiredAfterCondition},${expiredTime.expiredDays}`
        );
    }

  const effectiveDateStart = general.effectiveDate[0]._d
    ? general.effectiveDate[0]._d
    : general.effectiveDate[0];
  const effectiveDateEnd = general.effectiveDate[1]._d
    ? general.effectiveDate[1]._d
    : general.effectiveDate[1];

  const payload: any = {
    // GENERAL
    name: general.name,
    description: general.description || null,
    predecessor:
      !general.predecessor || general.predecessor === null
        ? null
        : general.predecessor,
    sequence:
      !general.sequence || general.sequence === null ? null : general.sequence,
    version:
      general.version === 'custom'
        ? `${general.versionFirst ? general.versionFirst : 0}.${
            general.versionSecond ? general.versionSecond : 0
          }`
        : general.version,
    isCPDStatus:
      general.isCpdStatus !== undefined ? general.isCpdStatus : false,
    cpdRefreshName:
      general.cpdRefreshName !== undefined ? general.cpdRefreshName : null,
    isRefreshment:
      general.isRefreshment !== undefined ? general.isRefreshment : false,
    effectiveDateStart,
    effectiveDateEnd,
    isPublish: general.isPublish !== undefined ? general.isPublish : false,

    // EXAM
    type: exam?.type || null,
    questionLimit:
      exam?.questionLimit !== undefined && exam?.questionLimit !== null
        ? exam?.questionLimit.toString()
        : null,
    duration:
      exam?.duration !== undefined && exam?.duration !== null
        ? exam?.duration.toString()
        : null,
    minimumScore:
      exam?.minimumScore !== undefined && exam?.minimumScore !== null
        ? exam?.minimumScore.toString()
        : null,
    maxAttemp:
      exam?.maxAttempt !== undefined && exam?.maxAttempt !== null
        ? exam.maxAttempt.toString()
        : null,

    // LEVEL
    levels,
    isNeedAssignedUser: levelMapping?.isNeedAssignedUser || false,
    users: listAgent,

    // START
    licenseStatusStart:
      startTime?.licenseStatus.length > 0
        ? startTime.licenseStatus.join('|')
        : '',
    otherConditionStart:
      listOtherConditionStart.length > 0
        ? listOtherConditionStart.join('|')
        : '',
    additionalStarts,

    // EXPIRED
    licenseStatusExpired:
      expiredTime?.licenseStatus.length > 0
        ? expiredTime.licenseStatus.join('|')
        : '',
    otherConditionExpired:
      listOtherConditionExpired.length > 0
        ? listOtherConditionExpired.join('|')
        : '',
    additionalExpireds,

    // ADDITIONAL INFO
    channelId,
    stageId,
    moduleId,
    codemiLearningPathId,
    // isActive: isActive !== undefined ? isActive : false,
  };

  if (submoduleId)
    return await instance.put(`/Elearning/SubModule/${submoduleId}`, payload);
  else return await instance.post(`/Elearning/SubModule`, payload);
};

export const contentManagementSubmoduleAPI = {
  getLookupAgent,
  getSubmodule,
  getSubmoduleDetail,
  deleteSubmodule,
  mutateSubmoduleStatus,
  mutateSubmodule,
};

import React from 'react';
import CCard from '../../../Assets/Components/CCard';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import CIcon from '../../../Assets/Components/CIcon';
import moment from 'moment';
import { injectIntl } from 'react-intl';
import { IResultMostVisitedMenuDAO } from '../DAO/dashboard.dao';

function DashboardMostVisitedMenuComponent({
  intl,
  data,
}: {
  intl: any;
  data?: IResultMostVisitedMenuDAO;
}) {
  const dataLoop = data?.collection || [];
  const COLORS = ['#2FDF66', '#2FDF66', '#2FDF66', '#2FDF66'];
  const content = () => {
    if (dataLoop) {
      const maxValue = Math.max.apply(
        Math,
        dataLoop.map(o => {
          return o.value;
        })
      );
      return (
        <>
          <p className="periodDashboard">
            <CIcon className="iconPeriodDashboard" type="CalendarOutlined" />
            {`${moment(data?.startPeriode).format('DD MMMM YYYY')} - ${moment(
              data?.endPeriode
            ).format('DD MMMM YYYY')}`}
          </p>
          <ResponsiveContainer width="100%" minHeight={250}>
            <BarChart
              layout="vertical"
              data={dataLoop}
              margin={{
                top: 5,
                right: 5,
                left: 5,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis type="number" tick={{ fill: 'white' }} />
              <YAxis
                type="category"
                tick={{ fill: 'white' }}
                dataKey="name"
                width={200}
              />
              <Tooltip />
              <Bar dataKey="value" fill="#8884d8">
                {dataLoop.map((entry, index) => {
                  const color = () => {
                    if (entry.value >= maxValue) {
                      return COLORS[0];
                    } else if (entry.value >= maxValue * 0.75) {
                      return COLORS[1];
                    } else if (entry.value >= maxValue * 0.5) {
                      return COLORS[2];
                    } else {
                      return COLORS[3];
                    }
                  };
                  return <Cell key={index} fill={color()} />;
                })}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </>
      );
    }
    return null;
  };
  return (
    <CCard
      cardClassName="cardDashboard"
      cardTitle={
        <span className="titleCardDashboard">
          {intl.formatMessage({
            id: 'dashboardMostVisitedTitle',
          })}
        </span>
      }
      cardContent={content()}
    />
  );
}
export default injectIntl(DashboardMostVisitedMenuComponent);

import { Tabs } from 'antd';
import React from 'react';
import CCard from '../../Assets/Components/CCard';
import CLabelCard from '../../Assets/Components/CLabelCard';
import NonSelfLearningContainer from './Container/NonSelfLearningContainer';
import SelfLearningContainer from './Container/SelfLearningContainer';
import { injectIntl } from 'react-intl';

const { TabPane } = Tabs;

function FeedbackBankComponent(props: any) {
  const { intl } = props;
  const cardFeedbackBankContent = () => {
    const componentTabPane = (title: string, id: string) => {
      return (
        <div className="tabPaneFeedbackBank" id={id}>
          {title}
        </div>
      );
    };
    return (
      <Tabs defaultActiveKey="selflearning" className="tabFeedbackBank">
        <TabPane
          tab={componentTabPane('Self Learning', 'tabSelfLearning')}
          key="selflearning"
        >
          <SelfLearningContainer />
        </TabPane>
        <TabPane
          tab={componentTabPane('Non-Self Learning', 'tabNonSelfLearning')}
          key="nonselflearning"
        >
          <NonSelfLearningContainer />
        </TabPane>
      </Tabs>
    );
  };
  return (
    <>
      <CLabelCard leftText="Feedback Bank" topText="E-Learning" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'feedbackBankCardTitle',
            })}
          </span>
        }
        cardContent={cardFeedbackBankContent()}
      />
    </>
  );
}
export default injectIntl(FeedbackBankComponent);

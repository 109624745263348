import React from 'react';
import { Button, Col, Row, Form as FormAntd } from 'antd';
import { Field, FormRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import CIcon from '../../../Assets/Components/CIcon';
import { InputText } from '../../../Assets/Components/CInput';
import { InputChecbox } from '../../../Assets/Components/CInputCheckbox';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import validate from '../Validation/permissionValidation';
import { IResultModuleListDAO } from '../DAO/permission.dao';

interface IProps {
  authModuleList: IResultModuleListDAO[];
  canRenderPermissionCreate: boolean;
  canRenderPermissionUpdate: boolean;
  editMode: boolean;
  handleCancel: () => void;
  handleSubmit: (values:any) => void;
  initialValues: any;
  isLoading:boolean
  isLoadingSubmit:boolean
  modalAction: 'register' | 'update';
  showModal: boolean;
}

export default function ModalPermissionComponent(props: IProps) {
  const {
    authModuleList,
    canRenderPermissionCreate,
    canRenderPermissionUpdate,
    editMode,
    handleCancel,
    handleSubmit,
    initialValues,
    isLoading,
    isLoadingSubmit,
    modalAction,
    showModal,
  } = props;

  const canRenderPermission = (
    canRenderPermissionCreate: boolean,
    canRenderPermissionUpdate: boolean,
    action: string
  ) => {
    if (
      (action === 'register' && canRenderPermissionCreate === true) ||
      (action === 'update' && canRenderPermissionUpdate === true)
    ) {
      return true;
    }
    return false;
  };

  const renderTablePermission = (
    data: any,
    disabled: boolean,
    canRenderPermissionCreate: boolean,
    canRenderPermissionUpdate: boolean,
    modalAction: string
  ) => {
    const canRenderPermissionTable = canRenderPermission(
      canRenderPermissionCreate,
      canRenderPermissionUpdate,
      modalAction
    );

    const itemCol = data.map((x, z) => {
      const renderPermission = type => {
        const module = x.code.replace('.', '/');
        return (
          <Field
            name={`permissionSetting.${module}.${type}`}
            component={InputChecbox}
            idComponent={`inputRoleName${module}.${type}`}
            disabled={disabled}
          />
        );
      };
      return (
        <Row
          key={`${x.code}.${z}`}
          className={`rowTablePermission ${
            z % 2 === 0 ? '' : 'rowTablePermissionOdd'
          }`}
        >
          <Col span={1}>{z + 1}</Col>
          <Col span={15}>{x.name}</Col>
          {canRenderPermissionTable === true ? (
            <>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('view')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('create')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('update')}
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                {renderPermission('delete')}
              </Col>
            </>
          ) : null}
        </Row>
      );
    });

    return (
      <FormAntd.Item
        label={
          <span>
            <b className="capital labelFormItemZurich">{'Module Permission'}</b>
          </span>
        }
        colon={false}
      >
        <Row className="rowTablePermission rowTablePermissionHeader">
          <Col span={1}>No.</Col>
          <Col span={15}>Permission name</Col>
          {canRenderPermissionTable === true ? (
            <>
              <Col span={2} className="rowCheckboxPermission">
                View
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                Create
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                Update
              </Col>
              <Col span={2} className="rowCheckboxPermission">
                Delete
              </Col>
            </>
          ) : null}
        </Row>
        {itemCol}
      </FormAntd.Item>
    );
  };

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="roleForm" />
      </h3>
    );
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const disabled =
      editMode === false && modalAction === 'update' ? true : false;

    return (
      <>
        <Field
          name="roleName"
          component={InputText}
          idComponent="inputRoleName"
          icon={<CIcon type="FileTextOutlined" />}
          label="Role name"
          disabled={disabled}
        />
        {renderTablePermission(
          authModuleList,
          disabled,
          canRenderPermissionCreate,
          canRenderPermissionUpdate,
          modalAction
        )}
      </>
    );
  };

  const buttonContent = () => {
    if (editMode === false) {
      if (modalAction === 'register') return 'Create';
      else return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (formProps: FormRenderProps<any, any>) => {
    const { invalid } = formProps;
    if (
      (modalAction === 'register' && HasPermission('c')) ||
      (modalAction === 'update' && HasPermission('u'))
    ) {
      const handleDisableButton = (
        action: string,
        invalid,
        editMode: boolean
      ) => {
        if (action === 'register') {
          if (invalid) {
            return true;
          }
          return false;
        } else {
          if (editMode === false) {
            return false;
          } else {
            if (invalid) {
              return true;
            }
            return false;
          }
        }
      };

      return (
        <Button
          className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
          icon={<CIcon type="CheckOutlined" />}
          disabled={handleDisableButton(
            modalAction,
            invalid,
            // hasErrors,
            editMode
          )}
          htmlType="submit"
          id="btnSubmit"
          loading={isLoadingSubmit}
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={(formProps: FormRenderProps<any, any>) =>
        contentComponent(formProps)
      }
      footerComponent={(formProps: FormRenderProps<any, any>) =>
        footerComponent(formProps)
      }
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

import { IResultCategoryBankDAO } from '../Modules/ElearningCategoryBank/DAO/categoryBank.dao';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getCategoryBankAPI = async (
  params: UseQueryOptions
): Promise<IResultCategoryBankDAO[]> => {
  const isActive = params?.queryKey?.[1];

  const { data } = await instance.get(
    `/Elearning/CategoryBank${
      isActive === 'active'
        ? '?isActive=true'
        : isActive === 'inactive'
        ? '?isActive=false'
        : ''
    }`
  );
  return data;
};

export const mutateCategoryBankAPI = async (params: any) => {
  const { name, isActive, id } = params;
  const payload: any = { name, isActive };
  if (!isActive) {
    payload.isActive = false;
  }

  if (id) {
    return await instance.post(`/Elearning/CategoryBank?Id=${id}`, payload);
  } else {
    return await instance.post(`/Elearning/CategoryBank`, payload);
  }
};

export const deleteCategoryBankAPI = async (id: string) => {
  return await instance.delete(`/Elearning/CategoryBank?Id=${id}`);
};

import { useLocation } from 'react-router-dom';
import Store from '../Store';

export default function HasPermission(ability: any, path: any = null) {
  const state: any = Store.getState();
  const permission = state.permission;
  const location = useLocation();

  let res = false;
  const currentPath = path === null ? location.pathname : path;
  if (permission) {
    for (const iterator of permission) {
      for (const key in iterator) {
        if (
          Object.prototype.hasOwnProperty.call(iterator, key) &&
          key === currentPath
        ) {
          const element = iterator[key];
          res = processAbility(element, ability);
        }
      }
    }
  }
  return res;
}

const processAbility = (element, ability) => {
  let res = false;
  for (const v of element) {
    if (v === ability) {
      res = true;
      break;
    }
  }
  return res;
};

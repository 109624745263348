import { useMutation, useQuery } from '@tanstack/react-query';
import { FormApi } from 'final-form';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { contentManagementModuleAPI } from '../../../../Services/ElearningContentManagement/module.api';
import { contentManagementStageAPI } from '../../../../Services/ElearningContentManagement/stage.api';
import ModuleModalMigrationComponent from '../Component/ModuleModalMigrationComponent';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  modalIsShow: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function ModuleModalMigrationContainer(props: IProps) {
  const {
    channel,
    setModalIsShow,
    modalIsShow,
    selectedData,
    setSelectedData,
  } = props;

  const [stageId, setStageId] = useState();
  const [listModule, setListModule] = useState([]);

  const { data: listStage, isLoading: isLoadingStage } = useQuery(
    ['GetStageList', { channelId: channel?.id }],
    contentManagementStageAPI.getStage,
    {
      enabled: modalIsShow,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataModule, isLoading: isLoadingModule } = useQuery(
    ['GetContentManagementModuleAPI', { stageId }],
    contentManagementModuleAPI.getModule,
    {
      enabled: stageId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { data: dataLearners } = useQuery(
    [
      'GetDataLearnersAPI',
      { codemiLearningPathId: selectedData?.codemiLearningPathId },
    ],
    contentManagementModuleAPI.getLearners,
    {
      enabled: selectedData?.codemiLearningPathId !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate } = useMutation(
    contentManagementModuleAPI.mutateMigrateAgent,
    {
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">Success Migrate Module!</span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        setStageId(undefined);
        setSelectedData(undefined);
        setModalIsShow(false);
      },
      onError: (error: any) => {
        ErrorHandler(error);
      },
    }
  );

  useEffect(() => {
    if (!dataModule) return;
    const listModuleFiltered = dataModule?.filter(
      item => item.id !== selectedData?.id
    );
    setListModule(listModuleFiltered);
  }, [dataModule, selectedData]);

  const handleOnChange = (e: any) => setStageId(e.target.value);

  const handleCancel = () => {
    setStageId(undefined);
    setSelectedData(undefined);
    setModalIsShow(false);
  };

  const handlePrev = (form: FormApi<any, Partial<any>>) => {
    form.change('stage', null);
    form.change('module', null);
    setStageId(undefined);
  };

  const handleSubmit = (vals: any) => {
    mutate({
      ...vals,
      codemiLearningPathId: selectedData?.codemiLearningPathId,
      learnerList: dataLearners || [],
    });
  };

  return (
    <ModuleModalMigrationComponent
      stageId={stageId}
      isLoading={!stageId ? isLoadingStage : isLoadingModule}
      isShow={modalIsShow}
      handleCancel={handleCancel}
      handleOnChange={handleOnChange}
      handlePrev={handlePrev}
      handleSubmit={handleSubmit}
      learnerList={dataLearners || []}
      listStage={listStage || []}
      listModule={listModule || []}
      initialValues={selectedData}
    />
  );
}

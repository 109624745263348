import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { IResultPrivyFileDAO } from '../DAO/privy.dao';

interface IProps {
  columnData: any;
  fileList: IResultPrivyFileDAO[];
  handleRefresh: () => void;
  isLoading: boolean;
}
export default function DownloadPrivyComponent(props: IProps) {
  const { columnData, fileList, handleRefresh, isLoading } = props;
  const buttonData = [
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      onClick: handleRefresh,
      content: 'Refresh',
      id: 'btnRefreshDownloadPrivy',
    },
  ];
  return (
    <div className="containerWhiteZurich">
      <CButton
        buttonData={buttonData}
        buttonFloat={'right'}
        isLoading={isLoading}
      />
      <Table
        isLoading={isLoading}
        columns={generateColumnData(columnData)}
        data={fileList}
        pagination={true}
        useFilterGlobal
      />
    </div>
  );
}

import { convertBase64 } from '../App/Helper';
import instance from '../Services/interceptor';

export const autoUploadEditContentAPI = async (
  params: any
): Promise<{
  data: any;
  documents: any;
  form: any;
}> => {
  const {
    form,
    fileData,
    addtionalMeta,
    detailCandidate,
    schemaCandidate,
    documentCandidate,
  } = params;

  const base64 = await convertBase64(fileData.data);

  const payload: any = {
    repositoryId: addtionalMeta.repositoryId,
    contentId: addtionalMeta.contentId,
    folderId: addtionalMeta.folderId,
    folderSymbolicName: 'submission',
    name: addtionalMeta.field,
    originalFileName: fileData.name,
    content: base64,
    properties: {
      fieldPath: `FRMCR0001.${addtionalMeta.field}`,
      fileName: `${addtionalMeta.fileName}.${fileData.extension}`,
      relationId: addtionalMeta.contentId,
      module: 'candidate',
      type: fileData.type,
      size: fileData.size,
      mime: fileData.type,
      docId: addtionalMeta.contentId,
      contentId: addtionalMeta.contentId,
      lastContentId: addtionalMeta.contentId,
      extension: fileData.extension,
      isoTimestamp: addtionalMeta.isoTimeStamp,
      timestamp: addtionalMeta.dateNow,
      candidateCode: detailCandidate ? detailCandidate.code : '',
      context: 'Candidate Self Registration',
      formId: 'FRMCR0001',
      documentCode: addtionalMeta.documentCode,
      documentType: addtionalMeta.documentType,
      documentGroup: addtionalMeta.documentGroup,
      objectId: '',
      isMovedToFilenet: 'false',
    },
  };

  if (addtionalMeta.folderId) {
    const { data } = await instance.put(`/Content/UpdateContent`, payload);
    const documents: any = documentCandidate;
    const { data: docContent } = await instance.get(
      `/Content/GetContent?contentId=${data}`
    );
    form.change(addtionalMeta.field, data);
    const detailDocContent = docContent;
    const newData: any = detailDocContent;
    newData.content = `data:${detailDocContent.properties.mime};base64, ${detailDocContent.content}`;
    documents[addtionalMeta.name] = newData;
    if (addtionalMeta.sectionKey === 'licenseForm') {
      const listHistory: any =
        Object.keys(schemaCandidate.schema.properties.aajichecking.properties)
          .length > 0
          ? schemaCandidate.schema.properties.aajichecking.properties
              .paymentslip.properties.history
          : [];
      listHistory.unshift(data);
      const newSchemaCandidate: any = schemaCandidate;
      newSchemaCandidate.schema.properties.aajichecking.properties = {
        paymentslip: {
          config: {},
          type: 'object',
          properties: {
            history: listHistory,
          },
        },
      };
      return await instance.post(`/form`, newSchemaCandidate);
    }
    return { data, documents, form };
  } else {
    const createPayload: any = payload;
    createPayload.repositorySymbolicName = 'submission';

    const { data } = await instance.post(
      `/Content/CreateContent`,
      createPayload
    );
    let documents: any = documentCandidate;
    const { data: docContent } = await instance.get(
      `/Content/GetContent?contentId=${data}`
    );
    form.change(addtionalMeta.field, data);
    const detailDocContent = docContent;
    const newData: any = detailDocContent;
    newData.content = `data:${detailDocContent.properties.mime};base64, ${detailDocContent.content}`;
    documents[addtionalMeta.name] = newData;
    if (addtionalMeta.sectionKey === 'licenseForm') {
      const listHistory: any =
        Object.keys(schemaCandidate.schema.properties.aajichecking.properties)
          .length > 0
          ? schemaCandidate.schema.properties.aajichecking.properties
              .paymentslip.properties.history
          : [];
      listHistory.push(data);
      const newSchemaCandidate: any = schemaCandidate;
      newSchemaCandidate.schema.properties.aajichecking.properties = {
        paymentslip: {
          config: {},
          type: 'object',
          properties: {
            history: listHistory,
          },
        },
      };
      form.change(addtionalMeta.field, data);
      await instance.post(`/form`, newSchemaCandidate);
    }
    return { data, documents, form };
  }
};

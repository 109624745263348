import React from 'react';
import ModalUploadLicenseComponent from '../../Component/Modal/ModalUploadLicenseComponent';

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ModalUploadLicenseContainer(props: IProps) {
  const { showModal, setShowModal } = props;
  const handleCancel = () => setShowModal(false);

  return (
    <ModalUploadLicenseComponent
      showModal={showModal}
      handleCancel={handleCancel}
    />
  );
}

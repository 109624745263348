import { Checkbox, Form } from 'antd';

import React from 'react';

interface IPropsCheckBox {
  input?;
  meta?;
  label?;
  disabled?;
  defaultValues?;
  className?;
  classNameLabel?;
  formClassName?;
  labelRight?;
}

interface IPropsMultipleCheckBox extends IPropsCheckBox {
  options?;
  optionsData?;
  handleChange?;
}

export const singleCheckbox = (props: IPropsCheckBox) => {
  const {
    input,
    meta: { touched, error },
    label,
    className,
    classNameLabel,
    disabled,
    labelRight,
  } = props;

  const handleOnChange = e => input.onChange(e);
  const labelTitle = label ? (
    <span>
      <b className={`capital ${classNameLabel}`}>{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={touched && error !== undefined ? 'error' : ''}
      help={touched && error !== undefined ? error : ''}
      label={labelTitle}
      style={{ marginBottom: '0px' }}
    >
      <Checkbox
        onChange={handleOnChange}
        className={className}
        checked={input.checked}
        disabled={disabled}
      >
        {labelRight}
      </Checkbox>
    </Form.Item>
  );
};

export const multipleCheckbox = (props: IPropsMultipleCheckBox) => {
  const {
    input,
    meta: { error },
    label,
    options,
    optionsData,
    className,
    disabled,
    formClassName,
    handleChange,
  } = props;
  const handleOnChange = checkedValues => {
    input.onChange(checkedValues);
  };
  const labelTitle = label ? (
    <span>
      <b className={`capital}`}>{label}</b>
    </span>
  ) : null;
  return (
    <Form.Item
      validateStatus={error !== undefined ? 'error' : ''}
      help={error !== undefined ? error : ''}
      label={labelTitle}
      style={{ marginBottom: '0px' }}
      className={formClassName}
    >
      <Checkbox.Group
        options={options || optionsData}
        onChange={e => {
          handleOnChange(e);
          handleChange && handleChange(e);
        }}
        className={className}
        value={input.value}
        disabled={disabled}
      ></Checkbox.Group>
    </Form.Item>
  );
};

import { Col, Row } from 'antd';
import {
  classTrainingType,
  classType,
  filterStatus,
} from '../../../../App/Enums';

import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import React from 'react';
import Select from '../../../../Assets/Components/Select';
import Table from '../../../../Assets/Components/CTable';
import { IResultClassNonSelfTrainingDAO } from '../DAO/classNonSelfTraining.dao';

interface IProps {
  columnData: any;
  dataClass: IResultClassNonSelfTrainingDAO[];
  invalid: boolean;
  isLoading: boolean;
  renderRowSubComponent: any;
  handleFilter: () => void;
  handleAdd: () => void;
  handleRefresh: () => void;
  selectedStatus?: string;
  selectedClass?: string;
  selectedTraining?: string;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  setSelectedClass: React.Dispatch<React.SetStateAction<any>>;
  setSelectedTraining: React.Dispatch<React.SetStateAction<any>>;
}

export default function ClassComponent(props: IProps) {
  const {
    dataClass,
    columnData,
    isLoading,
    invalid,
    renderRowSubComponent,
    handleAdd,
    handleFilter,
    handleRefresh,
    selectedStatus,
    setSelectedStatus,
    setSelectedClass,
    setSelectedTraining,
    selectedClass,
    selectedTraining,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      content: 'Cari',
      id: 'btnSearch',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      onClick: handleFilter,
      icon: <CIcon type="SearchOutlined" />,
      disabled: invalid,
      loading: isLoading,
    },
  ];
  const buttonDataRight = [
    {
      type: 'primary',
      content: 'Tambah Baru',
      id: 'btnModalAddNewClass',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      onClick: handleAdd,
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshClassList',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];

  return (
    <div className="containerWhiteZurich">
      <Row gutter={20}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus || []}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            idComponent="inputClassStatus"
            meta={{ touched: false, error: false }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={4}>
          <label className="labelBtnFilter">Class Type</label>
          <Select
            dataOption={classType}
            input={{
              onBlur: null,
              onChange: setSelectedClass,
              value: selectedClass ? selectedClass : 'Semua',
            }}
            idComponent="inputClassType"
            meta={{ touched: false, error: false }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={4}>
          <label className="labelBtnFilter">Training Type</label>
          <Select
            dataOption={classTrainingType}
            input={{
              onBlur: null,
              onChange: setSelectedTraining,
              value: selectedTraining ? selectedTraining : 'Semua',
            }}
            idComponent="inputTrainingType"
            meta={{ touched: false, error: false }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={2} style={{ paddingTop: 22 }}>
          <CButton
            buttonData={buttonData}
            isLoading={isLoading}
            buttonFloat="left"
          />
        </Col>
        <Col span={10} style={{ paddingTop: 22 }}>
          <CButton
            buttonData={buttonDataRight}
            isLoading={isLoading}
            buttonFloat="right"
          />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        columns={columnData}
        pagination={true}
        data={dataClass}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
}

import React from 'react';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import { Alert, Button } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import { Field } from 'react-final-form';
import { InputText } from '../../../Assets/Components/CInput';
import validate from '../Validation/LibraryFolderValidation';
import { IntlShape } from 'react-intl';

interface IProps {
  intl: IntlShape;
  isLoading: boolean;
  initialValues: any;
  editMode: boolean;
  modalFolderIsShow: boolean;
  handleCancel: () => void;
  handleMove: () => void;
  handleSubmit: (vals: any) => void;
}

export default function ModalLibraryFolderComponent(props: IProps) {
  const {
    modalFolderIsShow,
    handleCancel,
    intl,
    handleSubmit,
    isLoading,
    initialValues,
    editMode,
    handleMove,
  } = props;
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="folderLibraryForm" />
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    const isDisabled = !editMode && initialValues ? true : false;
    return (
      <React.Fragment>
        <Alert
          // eslint-disable-next-line
          message={`Name must not contain  \\\:*?"<>/|`}
          type="warning"
          showIcon
        />
        <Field
          name="foldername"
          component={InputText}
          idComponent="folderName"
          icon={<CIcon type="FileTextOutlined" />}
          placeholder={intl.formatMessage({
            id: 'placeHolderFolderName',
          })}
          label={intl.formatMessage({ id: 'folderName' })}
          disabled={!formProps.values || isDisabled}
        />
        {initialValues ? (
          <>
            <Button
              icon={<CIcon type="ExportOutlined" />}
              id="btnAddfile"
              style={{ float: 'left' }}
              onClick={handleMove}
              loading={isLoading}
            >
              Move
            </Button>
          </>
        ) : null}
      </React.Fragment>
    );
  };

  const buttonContent = () => {
    if (editMode === false) {
      if (!initialValues) {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (formProps: any) => {
    return (
      <Button
        icon={<CIcon type="CheckOutlined" />}
        className={'btnSubmit'}
        id="btnSubmit"
        htmlType="submit"
        disabled={formProps.invalid}
        loading={isLoading}
      >
        {buttonContent()}
      </Button>
    );
  };
  return (
    <CModalRFF
      modalIsShow={modalFolderIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

import React from 'react';
import { Field } from 'react-final-form';
import { classParticipantLevel } from '../../../../../App/Enums';
import CButton from '../../../../../Assets/Components/CButton';
import { multipleCheckbox } from '../../../../../Assets/Components/CCheckbox';
import { ExclamationCircleFilled } from '@ant-design/icons';

interface IProps {
  handleReset: () => void;
  handleAssignParticipant: () => void;
  editMode: boolean;
  modalAction: string;
  selectedData: any;
  formValues?: any;
}

export default function ModuleModalParticipantComponent(props: IProps) {
  const {
    // handleReset,
    handleAssignParticipant,
    editMode,
    modalAction,
    selectedData,
    // formValues,
  } = props;

  // const disabled = modalAction === 'update' ? true : false;

  // const renderOptionOtherCondition = ({
  //   title,
  //   conditions,
  //   selectName,
  //   numberName,
  //   description,
  // }) => {
  //   return (
  //     <>
  //       <div className="titleMultipleChecboxOtherCondition">{title}</div>
  //       <Field
  //         name={`participant.${selectName}`}
  //         component="select"
  //         className="selectSubModul"
  //         disabled={disabled}
  //       >
  //         <option className="optionDefault">Select</option>
  //         {conditions.map((element: any, index: number) => {
  //           return (
  //             <option key={`${element}_${index}`} value={element}>
  //               {element}
  //             </option>
  //           );
  //         })}
  //       </Field>
  //       <Field
  //         name={`participant.${numberName}`}
  //         component={TextNumberInput}
  //         className="inputNumberSubModuleInlineWithoutButton"
  //         min={1}
  //         disabled={disabled}
  //       />
  //       <span>{description}</span>
  //     </>
  //   );
  // };

  // const condition = ['<', '<=', '>=', '>'];
  // const otherCondition = [
  //   {
  //     id: 'EffectiveDate',
  //     component: renderOptionOtherCondition({
  //       title: 'Effective Date',
  //       conditions: condition,
  //       selectName: 'conditionLogicEffectiveDate',
  //       numberName: 'effectiveDateDays',
  //       description: 'Days',
  //     }),
  //   },
  //   {
  //     id: 'AfterGetAgentCode',
  //     component: renderOptionOtherCondition({
  //       title: 'After',
  //       conditions: condition,
  //       selectName: 'conditionLogicAfterGetAgentCode',
  //       numberName: 'afterGetAgentCodeDays',
  //       description: 'Days Get Agent Code',
  //     }),
  //   },
  // ];

  // const dataButtonAddAdditionalCondition = [
  //   {
  //     type: 'primary',
  //     id: 'btnResetParticipant',
  //     className: 'btnResetSubModule',
  //     content: 'Reset',
  //     onClick: handleReset,
  //     disabled:
  //       (modalAction === 'register' && formValues?.participant?.agentLevels) ||
  //       (modalAction === 'register' &&
  //         formValues?.participant?.participantCondition)
  //         ? false
  //         : true,
  //   },
  // ];

  const btnAddParticipant = [
    {
      type: 'primary',
      id: 'btnAssignParticipantModule',
      className: 'btnAssignParticipant',
      content: 'Assign Participant',
      onClick: handleAssignParticipant,
      disabled: editMode
        ? selectedData?.isParticipantAssigned === true
          ? true
          : selectedData?.isAutoAssign === true
          ? false
          : true
        : true,
    },
  ];
  return (
    <>
      <div className="disclaimerParticipant">
        <div>
          <ExclamationCircleFilled className="iconWarningModule" />
        </div>
        <ul>
          <li>
            Participant akan ditambahkan jika
            <span className="spanDisclaimerModule">Sub-Module </span>sudah
            selesai dibuat
          </li>
          <li>
            Silahkan assign participant di bagian
            <span className="spanDisclaimerModule">Edit Module</span> untuk
            menambahkan participant
          </li>
        </ul>
      </div>
      <p className="labelModule">Select Level</p>
      <div className="containerFormLicense">
        <Field
          name="participant.agentLevels"
          component={multipleCheckbox}
          options={classParticipantLevel}
          className="multipleCheckboxLicenseStatus"
          disabled
        />
      </div>
      {/* <div>
        <p className="labelModule">Condition</p>
        <div className="containerOtherCondition">
          <Field
            name="participant.participantCondition"
            component={RadioButton}
            dataOption={otherCondition}
            layout="vertical"
            disabled={disabled}
            className="multipleRadioButton"
          />
          <CButton buttonData={dataButtonAddAdditionalCondition} />
        </div>
      </div> */}
      {modalAction === 'update' ? (
        <CButton buttonData={btnAddParticipant} />
      ) : null}
      <hr className="lineSubModulLevelMapping" />
    </>
  );
}

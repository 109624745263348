import { Button, Col, Row } from 'antd';
import {
  classParticipantLevel,
  classParticipantType,
} from '../../../../../App/Enums';

import CIcon from '../../../../../Assets/Components/CIcon';
import { Field } from 'react-final-form';
import { InputTextNumber } from '../../../../../Assets/Components/CInput';
import React from 'react';
import Select from '../../../../../Assets/Components/Select';
import Table from '../../../../../Assets/Components/CTable';
import { convertAgentLevel } from '../../../../../App/Helper';
import { multipleCheckbox } from '../../../../../Assets/Components/CCheckbox';

interface IProps {
  columnData: any[];
  countingParticipantManual: any;
  dataParticipantManual: any[];
  disabled: boolean;
  form: any;
  formValues: any;
  handleAddAgentManual: () => void;
  handleUpdateParticipant: () => void;
  isLoading: any;
  modalAction: string;
  selectedAgentManual: any[];
}

export default function ParticipantComponent({
  columnData,
  countingParticipantManual,
  dataParticipantManual,
  disabled,
  formValues,
  handleAddAgentManual,
  handleUpdateParticipant,
  isLoading,
  modalAction,
  selectedAgentManual,
}: IProps) {
  const {
    fromLengthType,
    participantLevel,
    participantType,
    toLengthType,
    totalAgentLOS,
  } = formValues;

  function AddTypeComponent() {
    const currentAgentValue =
      formValues.agentManual?.[countingParticipantManual]?.code;

    return (
      <div>
        <Field
          name={`agentManual.[${countingParticipantManual}].code`}
          component={Select}
          idComponent="searchAgents"
          dataOption={dataParticipantManual}
          label="Search Agents"
          placeholder="Search Agent Name"
          defaultItemName="Select"
          disabled={disabled}
          showSearch={true}
          optionFilterProp="label"
          filterOption={true}
        />
        <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
          <Button
            className="btnTransferText"
            icon={<CIcon type="PlusOutlined" />}
            disabled={disabled || currentAgentValue === undefined}
            id="btnAddSchedule"
            htmlType="button"
            onClick={handleAddAgentManual}
          >
            Add Data
          </Button>
        </div>
        <Table
          columns={columnData}
          pagination={true}
          data={selectedAgentManual || []}
          useFilterGlobal
          isLoading={isLoading}
        />
      </div>
    );
  }

  function LevelTypeComponent() {
    return (
      <div>
        <Field
          name="levelParticipantType"
          component={multipleCheckbox}
          optionsData={classParticipantLevel}
          label="Select Level"
          disabled={modalAction === 'update'}
          className="divCheckbox"
          defaultItemName="Select"
        />
        {participantLevel && participantLevel.length > 0 && (
          <div style={{ fontWeight: 'bolder', marginBottom: '10px' }}>
            Total Agent :{' '}
            {participantLevel?.map((v, i) => {
              return (
                <span key={i}>
                  {`${i !== 0 ? ', ' : ''}${convertAgentLevel(v.level)} (${
                    v.total
                  })`}
                  <div style={{ display: 'none' }}>
                    <Field
                      name={`participantLevel.${i}.level`}
                      component="input"
                      disabled
                    />
                    <Field
                      name={`participantLevel.${i}.total`}
                      component="input"
                      type="number"
                      disabled
                    />
                  </div>
                </span>
              );
            })}
          </div>
        )}
      </div>
    );
  }

  function LosTypeComponent() {
    return (
      <div>
        <b>Date Range (in months)</b>
        <div className="divParticipantLOSType">
          <b className="capital">From</b>
          <Field
            name="fromLengthType"
            component={InputTextNumber}
            idComponent="fromLengthType"
            disabled={modalAction === 'update'}
            min={0}
          />
          <b className="capital">To</b>
          <Field
            name="toLengthType"
            component={InputTextNumber}
            idComponent="toLengthType"
            disabled={modalAction === 'update'}
            min={0}
          />
        </div>
        {totalAgentLOS !== undefined &&
          !isNaN(totalAgentLOS) &&
          fromLengthType !== undefined &&
          !isNaN(fromLengthType) &&
          toLengthType !== undefined &&
          !isNaN(toLengthType) && (
            <>
              <p style={{ fontWeight: 'bolder' }}>
                Total Agent : {totalAgentLOS}
              </p>
              <span style={{ display: 'none' }}>
                <Field
                  name="totalAgentLOS"
                  component="input"
                  type="number"
                  disabled
                />
              </span>
            </>
          )}
      </div>
    );
  }

  return (
    <Row gutter={20}>
      <Col span={12}>
        <Field
          name="participantType"
          component={Select}
          idComponent="modalParticipantType"
          dataOption={classParticipantType}
          label="Type"
          placeholder="-Select-"
          defaultItemName="Select"
          disabled={modalAction === 'update'}
        />
      </Col>
      {modalAction === 'update' &&
        (participantType === 'LEVEL' || participantType === 'LOS') && (
          <Col span={12}>
            <Button
              type="primary"
              ghost
              icon={<CIcon type="ReloadOutlined" />}
              htmlType="button"
              onClick={handleUpdateParticipant}
              style={{ marginTop: 30 }}
            >
              Update Participant
            </Button>
          </Col>
        )}
      <Col span={24}>
        {participantType === 'LEVEL' ? (
          LevelTypeComponent()
        ) : participantType === 'LOS' ? (
          LosTypeComponent()
        ) : participantType === 'MANUAL' ? (
          AddTypeComponent()
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
}

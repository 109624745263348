import React, { useState } from 'react';
import HasPermission from '../../../App/HasPermission';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import ContestRewardComponent from '../Component/ContestRewardComponent';
import { toastr } from 'react-redux-toastr';
import { useQuery, useMutation } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import ModalContestRewardContainer from './ModalContestRewardContainer';
import {
  deleteContestRewardAPI,
  getContestRewardAPI,
  getContestRewardDetailAPI,
} from '../../../Services/Contest/contestReward.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function ContestRewardContainer() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>(null);

  const {
    data: contestData,
    isLoading: isLoadingContest,
    fetchStatus: fetchStatusContest,
    refetch: refetchContest,
  } = useQuery(['getContestRewardAPI'], getContestRewardAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const { data: contestDetail, isLoading: isLoadingContestDetail } = useQuery(
    ['getContestRewardDetailAPI', selectedData?.id],
    getContestRewardDetailAPI,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const deleteContest = useMutation(deleteContestRewardAPI, {
    onError: (error: any) => {
      ErrorHandler(error, true);
      toast.error(<span className="capitalFirst">Contest reward used!</span>);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="deleteContestRewardSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchContest();
    },
  });

  const handleOnClick = () => setShowModal(true);

  const handleRefresh = () => refetchContest();

  const renderAction = row => {
    const rowOri = row.row.original;
    const handleUpdate = () => {
      setSelectedData(rowOri);
      setShowModal(true);
    };

    const handleRemove = () => {
      const toastrConfirmOptions = {
        onOk: () => deleteContest.mutate(rowOri.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Are you sure to delete contest reward "${rowOri.name}"?`,
        toastrConfirmOptions
      );
    };

    return renderActionComponent(
      isLoadingContest,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
        renderDelete: HasPermission('d'),
      },
      {
        handleUpdate,
        handleRemove,
      }
    );
  };

  const columnData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    HasPermission('d') || HasPermission('u')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
          width: 50,
        }
      : {
          accessor: 'null',
        },
  ];

  return (
    <>
      <ContestRewardComponent
        handleOnClick={handleOnClick}
        handleRefresh={handleRefresh}
        contestRewardList={contestData || []}
        isLoading={isLoadingContest || fetchStatusContest === 'fetching'}
        columnData={columnData}
      />
      <ModalContestRewardContainer
        isLoading={isLoadingContestDetail}
        showModal={showModal}
        setShowModal={setShowModal}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
        contestDetail={contestDetail}
      />
    </>
  );
}

import React, { useState } from 'react';
import ModalDisclaimerMappingComponent from '../Component/ModalDisclaimerMappingComponent';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import { mutateDisclaimerMappingAPI } from '../../../Services/Disclaimer/disclaimerMapping.api';
import {
  IResultDisclaimerContentListDAO,
  IResultDisclaimerModuleListDAO,
} from '../DAO/disclaimer.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  disclaimerContentList: IResultDisclaimerContentListDAO[];
  disclaimerModuleList: IResultDisclaimerModuleListDAO[];
  handleRefresh: () => void;
  intl: any;
  selectedData: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  showModal: boolean;
}

export default function ModalDisclaimerMappingContainer(props: IProps) {
  const {
    disclaimerContentList,
    disclaimerModuleList,
    handleRefresh,
    intl,
    selectedData,
    setModalIsShow,
    setSelectedData,
    showModal,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const modalAction = selectedData ? 'update' : 'register';
  let initialValues: any = selectedData;

  const mutateDisclaimerMapping = useMutation(mutateDisclaimerMappingAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success {selectedData ? 'updated' : 'created'} disclaimer mapping!
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });
  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateDisclaimerMapping.mutate({
        ...vals,
        isCreated: selectedData === 'update',
        id: selectedData?.id,
      });
    }
  };

  const handleCancel = () => {
    setSelectedData(undefined);
    setModalIsShow(false);
    setEditMode(false);
  };
  return (
    <ModalDisclaimerMappingComponent
      disabled={editMode === false && modalAction === 'update' ? true : false}
      disclaimerContentList={disclaimerContentList}
      disclaimerModuleList={disclaimerModuleList}
      editMode={editMode}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      intl={intl}
      isLoading={mutateDisclaimerMapping.isLoading}
      isShow={showModal}
      modalAction={modalAction}
    />
  );
}

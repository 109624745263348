import moment from 'moment';
import React, { useState } from 'react';
import HasPermission from '../../../App/HasPermission';
import { toastr } from 'react-redux-toastr';
import ContestTargetComponent from '../Component/ContestTargetComponent';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import { useQuery, useMutation } from '@tanstack/react-query';
import ContestTargetModalContainer from './ContestTargetModalContainer';
import { toast } from 'react-toastify';
import { contestGroup, contestStatus, contestType } from '../../../App/Enums';
import {
  deleteContestTargetAPI,
  getContestTargetAPI,
  getContestTargetDetailAPI,
} from '../../../Services/Contest/contestTarget.api';
import ErrorHandler from '../../../App/ErrorHandler';

const formatDate = 'YYYY-MM-DD';

export default function ContestTargetContainer() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const {
    data: contestData,
    isLoading: isLoadingContest,
    fetchStatus: fetchStatusContest,
    refetch: refetchContest,
  } = useQuery(['getContestTargetAPI'], getContestTargetAPI, {
    refetchOnWindowFocus: false,
  });

  const { data: contestDetail, isLoading: isLoadingContestDetail } = useQuery(
    ['getContestTargetDetailAPI', { id: selectedData?.id }],
    getContestTargetDetailAPI,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const deleteContest = useMutation(deleteContestTargetAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        <span className="capitalFirst">Success drop contest</span>,
        {
          className: 'toastSuccessBackground',
        }
      );
      refetchContest();
    },
  });

  const handleOnClick = () => setShowModal(true);

  const handleRefresh = () => refetchContest();

  const renderStatus = row => {
    return (
      <div className="spanContestStatus">
        <span
          className={`contestStatusColor contestStatusColor${row.row.original.contestStatus}`}
        />
        <span className={`contestStatusName`}>
          {contestStatus[parseInt(row.row.original.contestStatus, 10) - 1].name}
        </span>
      </div>
    );
  };

  const renderAction = row => {
    const rows = row.row.original;
    const editClick = () => {
      setSelectedData(rows);
      setShowModal(true);
    };

    const handleRemove = () => {
      const toastrConfirmOptions = {
        onOk: () => deleteContest.mutate(rows.id),
        id: 'toastRConfirm',
        okText: 'Delete',
        cancelText: 'Batalkan',
      };
      toastr.confirm(
        `Are you sure to delete contest target?`,
        toastrConfirmOptions
      );
    };

    return renderActionComponent(
      isLoadingContest,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
        renderDelete: HasPermission('d'),
      },
      {
        handleUpdate: editClick,
        handleRemove,
      }
    );
  };

  const columnData = [
    {
      Header: 'Contest Type',
      Cell: row =>
        contestType[parseInt(row.row.original.contestType, 10) - 1].name,
    },
    {
      Header: 'Category',
      Cell: row => contestGroup[parseInt(row.row.original.group, 10) - 1].name,
    },
    {
      Header: 'Start Date',
      Cell: row => moment(row.row.original.startDate).format(formatDate),
    },
    {
      Header: 'End Date',
      Cell: row => moment(row.row.original.endDate).format(formatDate),
    },
    {
      Header: 'Number of Target',
      Cell: row => row.row.original.targets.length,
    },
    {
      Header: 'Status',
      Cell: row => renderStatus(row),
      width: 35,
    },
    HasPermission('d') || HasPermission('u')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
        }
      : {
          accessor: 'null',
        },
  ];

  return (
    <>
      <ContestTargetComponent
        columnData={columnData}
        contestTargetList={contestData || []}
        handleOnClick={handleOnClick}
        handleRefresh={handleRefresh}
        isLoading={
          isLoadingContest ||
          fetchStatusContest === 'fetching' ||
          deleteContest.isLoading
        }
      />
      <ContestTargetModalContainer
        contestDetail={contestDetail}
        handleRefresh={handleRefresh}
        isLoading={isLoadingContestDetail}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  );
}

import {
  IResultInvitationListDAO,
  IResultInvitationDetailDAO,
} from '../Modules/Invitation/DAO/invitation.dao';
import { IInvitationDTO } from '../Modules/Invitation/DTO/invitation.dto';
import instance from './interceptor';
import { UseQueryOptions } from '@tanstack/react-query';

export const getInvitationDataAPI = async (): Promise<
  IResultInvitationListDAO[]
> => {
  const { data } = await instance.get(`/configuration/template`);
  return data;
};

export const getInvitationByIdAPI = async (
  params: UseQueryOptions
): Promise<IResultInvitationDetailDAO> => {
  const id = params?.queryKey?.[1];
  const { data } = await instance.get(`/configuration/template/${id}`);
  return data;
};

export const deleteInvitationAPI = async (id: number) => {
  return await instance.delete(`/configuration/template/${id}`);
};

export const mutateInvitationAPI = async (params: IInvitationDTO) => {
  const {
    emailSubject,
    generateBarcode,
    generateBarcodeSms,
    id,
    name,
    templateEmail,
    templateSms,
    templateSocialMedia,
  } = params;

  const contents: any = [];
  if (templateSms) {
    contents.push({
      type: 'SMS',
      description: templateSms,
      generateQrCode:
        generateBarcodeSms !== undefined ? generateBarcodeSms : false,
    });
  }
  if (emailSubject && templateEmail) {
    contents.push({
      type: 'Email',
      subject: emailSubject,
      description: templateEmail,
      generateQrCode: generateBarcode !== undefined ? generateBarcode : false,
    });
  }
  if (templateSocialMedia) {
    contents.push({
      type: 'SocialMedia',
      description: templateSocialMedia,
    });
  }

  const payload = { contents, name };
  if (id) {
    await instance.put(`/configuration/template/${id}`, payload);
  } else {
    await instance.post(`/configuration/template`, payload);
  }
};

import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import { injectIntl } from 'react-intl';
import ModalDisclaimerContentContainer from '../Container/ModalDisclaimerContentContainer';
import { IResultDisclaimerContentListDAO } from '../DAO/disclaimer.dao';

interface IProps {
  disclaimerContentList: IResultDisclaimerContentListDAO[];
  columnData: any;
  intl: any;
  modalIsShow: boolean;
  initialValues: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  handleRefresh: () => void;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  isLoading: any;
}

function DisclaimerContentComponent(props: IProps) {
  const {
    disclaimerContentList,
    columnData,
    modalIsShow,
    setModalIsShow,
    intl,
    initialValues,
    handleRefresh,
    selectedData,
    setSelectedData,
    isLoading,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      icon: 'PlusOutlined',
      onClick: () => setModalIsShow(true),
      content: 'Add Disclaimer Content',
      id: 'btnAddDisclaimerContent',
      className: 'spacingBtnSecondRow2 btnRadius10',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshDisclaimerContent',
      className: 'btnRadius10',
      onClick: handleRefresh,
    },
  ];
  const cardDisclaimerContent = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton buttonData={buttonData} buttonFloat={'right'} />
        <Table
          isLoading={isLoading}
          columns={generateColumnData(columnData)}
          data={disclaimerContentList || []}
          pagination={true}
        />
        <ModalDisclaimerContentContainer
          showModal={modalIsShow}
          initialValues={initialValues}
          setModalIsShow={setModalIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          intl={intl}
          handleRefresh={handleRefresh}
        />
      </div>
    );
  };

  return (
    <>
      <CLabelCard leftText="Disclaimer" rightText="Content" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">
            {intl.formatMessage({
              id: 'disclaimerCardTitle',
            })}
          </span>
        }
        cardContent={cardDisclaimerContent()}
      />
    </>
  );
}

export default injectIntl(DisclaimerContentComponent);

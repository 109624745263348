import CCard from '../../../Assets/Components/CCard';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import React from 'react';
import { Tabs } from 'antd';
import { injectIntl } from 'react-intl';
import HistoryPrivyContainer from '../Container/HistoryPrivyContainer';
import UploadPrivyContainer from '../Container/UploadPrivyContainer';
import DownloadPrivyContainer from '../Container/DownloadPrivyContainer';

const { TabPane } = Tabs;

function PrivyStatementComponent() {
  const cardPrivyContent = () => {
    const componentTabPane = (title: string, id: string) => {
      return (
        <div className="tabPaneContestUpload" id={id}>
          {title}
        </div>
      );
    };
    return (
      <Tabs defaultActiveKey="uploadFile" className="tabContestUpload">
        <TabPane
          tab={componentTabPane('Unggah Baru', 'uploadPrivyStatement')}
          key="uploadFile"
        >
          <UploadPrivyContainer />
        </TabPane>
        <TabPane
          tab={componentTabPane('Riwayat Unggah', 'historyPrivyStatement')}
          key="historyFile"
        >
          <HistoryPrivyContainer />
        </TabPane>
        <TabPane
          tab={componentTabPane('Unduh Statement', 'downloadPrivyStatement')}
          key="downloadFile"
        >
          <DownloadPrivyContainer />
        </TabPane>
      </Tabs>
    );
  };
  return (
    <React.Fragment>
      <CLabelCard leftText="PrivyID Statement" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={
          <span className="titleCardStyleZurich">Unggah Statement</span>
        }
        cardContent={cardPrivyContent()}
      />
    </React.Fragment>
  );
}

export default injectIntl(PrivyStatementComponent);

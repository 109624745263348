import { Button } from 'antd';
import { Field, FormRenderProps } from 'react-final-form';

import CIcon from '../../../Assets/Components/CIcon';
import { FormattedMessage } from 'react-intl';
import HasPermission from '../../../App/HasPermission';
import { InputText } from '../../../Assets/Components/CInput';
import React from 'react';
import SearchBox from '../../../Assets/Components/SearchBox';
import Select from '../../../Assets/Components/Select';
import validate from '../Validation/MappingUserRoleValidation';
import CModalRFF from '../../../Assets/Components/CModalRFF';

interface IProps {
  authRoleList: any[];
  authUserList: any[];
  dataChannel: any[];
  editMode: boolean;
  handleCancel: () => void;
  handleSearch: React.Dispatch<any>;
  handleSubmit: (values: any) => void;
  initialValues: any;
  intl: any;
  isLoading: boolean;
  modalAction: 'update' | 'register';
  setEditMode: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function FormModalUserRoleComponent(props: IProps) {
  const {
    authRoleList,
    authUserList,
    dataChannel,
    editMode,
    handleCancel,
    handleSearch,
    handleSubmit,
    initialValues,
    intl,
    isLoading,
    modalAction,
    setEditMode,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="userRoleForm" />
      </h3>
    );
  };

  const disabledStatus = () => {
    if (editMode === false && modalAction === 'update') {
      return true;
    } else {
      return false;
    }
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const {
      values: { role },
    } = formProps;
    let isTrainer = false;

    const trainerData = authRoleList?.find(val => val.name === 'Trainer');
    if (trainerData && trainerData?.id === role) {
      isTrainer = true;
    }

    return (
      <>
        {HasPermission('r', '/sec/rol') ? (
          <Field
            name="role"
            component={Select}
            idComponent="inputRole"
            dataOption={authRoleList || []}
            defaultItemName="Select role"
            label={intl.formatMessage({ id: 'role' })}
            editMode={editMode}
            disabled={disabledStatus()}
            showSearch={true}
            optionFilterProp="label"
            filterOption={true}
          />
        ) : null}
        {isTrainer && (
          <Field
            name="channel"
            component={Select}
            idComponent="inputChannel"
            dataOption={dataChannel || []}
            defaultItemName="Select channel"
            label={intl.formatMessage({ id: 'nameChannel' })}
            editMode={editMode}
            disabled={disabledStatus()}
            customLabel="code"
          />
        )}
        <Field
          name="userList"
          component={SearchBox}
          idComponent="inputUser"
          label={intl.formatMessage({ id: 'labelUserList' })}
          disabled={modalAction === 'update'}
          dataItem={authUserList || []}
          maxItem={isTrainer ? 1 : undefined}
          handleSearch={handleSearch}
          customKeyValue="username"
          customKeyLabel="username"
          forceHelpLabel={
            isTrainer
              ? `*Type first to search username.`
              : `*Type first to search username. Can choose more than one.`
          }
          classnameForm="classNameFormSearchUsername"
          isSingle={true}
        />
        {isTrainer && (
          <Field
            name="name"
            component={InputText}
            idComponent="inputTrainerName"
            label={intl.formatMessage({ id: 'name' })}
            disabled={disabledStatus()}
            maxlength={30}
          />
        )}
      </>
    );
  };

  const footerComponent = (formProps: FormRenderProps<any, any>) => {
    const buttonContent = () => {
      if (editMode === false) {
        if (modalAction === 'register') {
          return 'Create';
        }
        return 'Edit';
      }
      return 'Save Change';
    };
    if (
      (modalAction === 'register' && HasPermission('c')) ||
      (modalAction === 'update' && HasPermission('u'))
    ) {
      return (
        <Button
          className={
            modalAction === 'update' && editMode === false
              ? 'btnSubmit'
              : formProps.invalid
              ? 'btnSubmitDisabled'
              : 'btnSubmit'
          }
          icon={<CIcon type="CheckOutlined" />}
          disabled={
            modalAction === 'update' && editMode === false
              ? false
              : formProps.invalid
          }
          loading={isLoading}
          id="btnSubmit"
          onClick={() =>
            modalAction === 'update' && editMode === false
              ? setEditMode(true)
              : handleSubmit(formProps.values)
          }
        >
          {buttonContent()}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={true}
      headerComponent={headerComponent()}
      contentComponent={(formProps: FormRenderProps<any, any>) =>
        contentComponent(formProps)
      }
      footerComponent={(formProps: FormRenderProps<any, any>) =>
        footerComponent(formProps)
      }
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      isLoading={isLoading}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

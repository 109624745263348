import moment from 'moment';

const LibraryFileValidation = (
  values: any,
  totalUpload: number,
  selectedData: any
) => {
  const errors: any = {};
  if (selectedData) {
    if (values[`startDate`] && values[`endDate`]) {
      const startDate = moment(values[`startDate`]);
      const endDate = moment(values[`endDate`]);
      if (startDate > endDate) {
        errors[`endDate`] = 'End date must higer than start date';
      }
    }
    if (values[`embededLinkTitle`] && !values[`embededLink`]) {
      errors[`embededLink`] = 'If title filled, url required!';
    }
    if (values[`embededLink`] && !values[`embededLinkTitle`]) {
      errors[`embededLinkTitle`] = 'If url filled, title required!';
    }
  } else {
    for (let index = 0; index < totalUpload; index++) {
      if (
        values[`embededLinkTitle-${index}`] &&
        values[`embededLinkTitle-${index}`].replace(/\s/g, '') === ''
      ) {
        errors[`embededLinkTitle-${index}`] = 'Name not valid format!';
      }
      if (
        !values[`embededLinkTitle-${index}`] &&
        !values[`embededLink-${index}`] &&
        !values[`uploadLibrary-${index}`]?.[0]
      ) {
        errors[`uploadLibrary-${index + 1}`] = 'At least select file or link!';
      }
      if (
        values[`embededLinkTitle-${index}`] &&
        !values[`embededLink-${index}`]
      ) {
        errors[`embededLink-${index}`] = 'If title filled, url required!';
      }
      if (
        values[`embededLink-${index}`] &&
        !values[`embededLinkTitle-${index}`]
      ) {
        errors[`embededLinkTitle-${index}`] = 'If url filled, title required!';
      }
      const startDate = moment(values[`startDate-${index}`]);
      const endDate = moment(values[`endDate-${index}`]);
      if (
        values[`startDate-${index}`] &&
        values[`endDate-${index}`] &&
        startDate > endDate
      ) {
        errors[`endDate-${index}`] = 'End date must higer than start date';
      }
    }
  }
  return errors;
};
export default LibraryFileValidation;

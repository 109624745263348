import { Button, Tag } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import CIcon from '../../../../Assets/Components/CIcon';
import { RadioButton } from '../../../../Assets/Components/CRadio';
import { moduleMigrationValidation } from '../../Validation/moduleValidation';
import { FormApi } from 'final-form';

interface IProps {
  isShow: boolean;
  isLoading: boolean;
  handleCancel: () => void;
  handleOnChange: (e: any) => void;
  handlePrev: (form: FormApi<any, Partial<any>>) => void;
  handleSubmit: (val: any) => void;
  learnerList: any[];
  listStage: any[];
  listModule: any[];
  initialValues: any;
  stageId?: number;
}

export default function ModuleModalMigrationComponent(props: IProps) {
  const {
    isLoading,
    isShow,
    handleCancel,
    handleOnChange,
    handlePrev,
    handleSubmit,
    learnerList,
    listStage,
    listModule,
    initialValues,
    stageId,
  } = props;

  const learnerInProgress = learnerList.filter(
    item => item.percentage_completed < 100
  );

  const headerComponent = () => {
    return <h3 className="headerTitle capital">Migrate Agent</h3>;
  };

  const contentComponent = (formProps: FormRenderProps<any, any>) => {
    const { form, values } = formProps;
    const selectedStage = listStage?.find(item => item.id === values?.stage);

    return (
      <>
        <div className="titleModuleMigration">
          {!stageId ? (
            <p>
              <b>Stage</b>
            </p>
          ) : (
            <p>
              <button
                onClick={() => handlePrev(form)}
                type="button"
                style={{ all: 'unset', cursor: 'pointer' }}
              >
                Stage
              </button>{' '}
              &gt; <b>Module</b>
            </p>
          )}
        </div>
        <Tag
          color="warning"
          icon={<ExclamationCircleOutlined />}
          className="tagsContentManagement"
        >
          <p className="textTagsContentManagement">
            {learnerInProgress.length} dari {learnerList.length} Agen yang belum
            menyelesaikan pada Module <b>"{initialValues?.name}"</b>
            saat ini.{' '}
          </p>
        </Tag>
        {!stageId ? (
          <Field
            name={'stage'}
            component={RadioButton}
            label="Stage Name"
            dataOption={listStage}
            idComponent="inputStage"
            layout="vertical"
            disabled={false}
            addtionalFunction={handleOnChange}
          />
        ) : (
          <>
            <p>
              Stage : <b style={{ color: '#003399' }}>{selectedStage?.name}</b>
            </p>
            <Field
              name={'module'}
              component={RadioButton}
              label="Module Name"
              dataOption={listModule}
              idComponent="inputVersion"
              layout="vertical"
              disabled={false}
              valueName="codemiLearningPathId"
            />
          </>
        )}
      </>
    );
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid } = propsFooter;
    return (
      <div style={{ display: 'flex', gap: '10px' }}>
        <Button
          className={'btnResetSubModule'}
          id="btnSubmit"
          htmlType="button"
          onClick={handleCancel}
        >
          Batalkan{' '}
        </Button>
        {stageId && (
          <Button
            className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
            icon={<CIcon type="CheckOutlined" />}
            id="btnSubmit"
            htmlType="submit"
            disabled={invalid}
          >
            Pilih{' '}
          </Button>
        )}
      </div>
    );
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={moduleMigrationValidation}
      isForm={true}
      handleSubmit={handleSubmit}
      formLayout="vertical"
      isLoading={isLoading}
      initialValues={initialValues}
    />
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { toastr } from 'react-redux-toastr';
import { toast } from 'react-toastify';
import { formatDate } from '../../../App/Enums';
import HasPermission from '../../../App/HasPermission';
import CIcon from '../../../Assets/Components/CIcon';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import InvitationComponent from '../Component/InvitationComponent';
import ModalInvitationContainer from './ModalInvitationContainer';
import {
  deleteInvitationAPI,
  getInvitationDataAPI,
} from '../../../Services/invitation.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function InvitationContainer() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const {
    data: invitationList,
    isLoading: isLoadingInvitation,
    fetchStatus,
    refetch: refetchInvitation,
  } = useQuery(['getInvitationAPI'], getInvitationDataAPI, {
    refetchOnWindowFocus: false,
  });

  const deleteInvitation = useMutation(deleteInvitationAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="deleteInvitationSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      refetchInvitation();
    },
  });

  const handleAdd = () => setShowModal(true);

  const handleRefresh = () => refetchInvitation();

  const renderAction = row => {
    const rows = row.row.original;

    const handleEdit = () => {
      setSelectedData(rows);
      setShowModal(true);
    };

    const handleRemove = () => {
      const toastrConfirmOptions = {
        onOk: () => deleteInvitation.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Are you sure to delete invitation "${row.row.original.name}"?`,
        toastrConfirmOptions
      );
    };

    return renderActionComponent(
      isLoadingInvitation || fetchStatus === 'fetching',
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
        renderDelete: HasPermission('d'),
      },
      {
        handleUpdate: handleEdit,
        handleRemove,
      }
    );
  };

  const renderIsHaveContent = v => {
    if (v) return <CIcon type="CheckOutlined" />;
    else return <CIcon type="CloseOutlined" />;
  };

  const columnData = [
    {
      Header: 'Template Name',
      accessor: 'name',
    },
    {
      Header: 'Date Modified',
      accessor: 'date',
      Cell: row => moment(row.row.original.modifiedDate).format(formatDate),
      disableSort: true,
    },
    {
      Header: 'SMS',
      accessor: 'sms',
      Cell: row => renderIsHaveContent(row.row.original.templateTypes.sms),
      disableSort: true,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: row => renderIsHaveContent(row.row.original.templateTypes.email),
      disableSort: true,
    },
    {
      Header: 'Social Media',
      accessor: 'socialMedia',
      Cell: row =>
        renderIsHaveContent(row.row.original.templateTypes.socialMedia),
      disableSort: true,
    },
    HasPermission('u')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
        }
      : { accessor: 'nullAction' },
  ];

  const sortedArray = (invitationList || []).sort((a, b) => b.id - a.id);

  return (
    <>
      <InvitationComponent
        columnData={columnData}
        handleAdd={handleAdd}
        handleRefresh={handleRefresh}
        invitationList={sortedArray}
        isLoading={isLoadingInvitation || fetchStatus === 'fetching'}
      />
      <ModalInvitationContainer
        handleRefresh={handleRefresh}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        setShowModal={setShowModal}
        showModal={showModal}
      />
    </>
  );
}

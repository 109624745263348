import ModalDetailWorldCheckComponent from '../Component/ModalDetailWorldCheckComponent';
import React from 'react';

interface IProps {
  selectedData: any;
  modalDetailWorldIsShow: boolean;
  setModalDetailWorldIsShow: any;
}
export default function ModalDetailWorldCheckContainer(props: IProps) {
  const { selectedData, modalDetailWorldIsShow, setModalDetailWorldIsShow } =
    props;
  const handleCancel = () => {
    setModalDetailWorldIsShow(false);
  };
  return (
    <ModalDetailWorldCheckComponent
      selectedData={selectedData}
      modalDetailWorldIsShow={modalDetailWorldIsShow}
      handleCancel={handleCancel}
    />
  );
}

import { Button, Col, Row } from 'antd';
import React from 'react';
import { filterStatus } from '../../../../App/Enums';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import Table from '../../../../Assets/Components/CTable';
import Select from '../../../../Assets/Components/Select';

interface IProps {
  columnData: any;
  dataStage: any;
  isLoading: boolean;
  handleAdd: () => void;
  handleRefresh: () => void;
  renderRowSubComponent: any;
  handleApplyfilter: () => void;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  selectedStatus?: string;
}

export default function StageComponent(props: IProps) {
  const {
    dataStage,
    columnData,
    handleAdd,
    handleRefresh,
    isLoading,
    renderRowSubComponent,
    selectedStatus,
    handleApplyfilter,
    setSelectedStatus,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      content: 'Tambah Baru',
      id: 'btnModalAddNewStage',
      className: 'spacingBtnSecondRow2 btnRadius10',
      onClick: handleAdd,
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshStageList',
      className: 'btnRadius10',
      onClick: handleRefresh,
    },
  ];

  return (
    <div className="containerWhiteZurich">
      <Row gutter={5}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus || []}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            idComponent="inputStageStatus"
            meta={{ touched: false, error: false }}
            defaultItemName="Semua"
          />
        </Col>
        <Col span={4}>
          <Button
            type="primary"
            icon={<CIcon type="SearchOutlined" />}
            onClick={handleApplyfilter}
            loading={isLoading}
            id="btnSearch"
            style={{ marginTop: '22px' }}
            className="btnRadius10"
          >
            Cari
          </Button>
        </Col>
        <Col span={16} className={'btnFilterAplicationList'}>
          <CButton
            buttonData={buttonData}
            buttonFloat={'right'}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        columns={columnData}
        pagination={true}
        data={dataStage}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
}

import instance from '../interceptor';

const getQuestion = async params => {
  const { id } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ContentQuestion?subModuleId=${id}`
  );
  return data;
};

const getQuestionDetail = async params => {
  const { id } = params.queryKey[1];
  const { data } = await instance.get(`/Elearning/ContentQuestion/${id}`);
  return data;
};

const getCategoryBank = async params => {
  const { data } = await instance.get(`/Elearning/CategoryBank?isActive=true`);
  return data;
};

const getQuestionBankById = async params => {
  const { id, category } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/QuestionBankByCategoryId?subModuleId=${id}&categoryId=${category}`
  );
  const newData: any[] = [];
  if (data && data.length > 0)
    data.forEach((item: any) => {
      newData.push({ ...item, value: item.id, label: item.title });
    });

  return newData;
};

const deleteQuestion = async (id: string) => {
  return await instance.delete(`/Elearning/ContentQuestion/${id}`);
};

const mutateQuestionStatus = async params => {
  const { id, status } = params;
  const { data } = await instance.put(
    `/Elearning/ContentQuestion/status/${id}/${
      status !== undefined ? status : false
    }`
  );
  return data;
};

const mutateQuestion = async params => {
  const {
    channelId,
    stageId,
    moduleId,
    subModuleId,
    slideId,
    codemiModuleId,
    selectedQuestion,
  } = params;
  const questions: any[] = [];
  for (const question of selectedQuestion) {
    const tempQuestion: any = question;
    delete tempQuestion.value;
    delete tempQuestion.label;
    tempQuestion.isActive = true;
    tempQuestion.questionBankId = tempQuestion.id;
    questions.push(tempQuestion);
  }

  const payload: any = {
    channelId,
    stageId,
    moduleId,
    subModuleId,
    codemiModuleId,
    questions,
  };

  if (slideId)
    return await instance.put(`/Elearning/ContentQuestion/${slideId}`, payload);
  else return await instance.post(`/Elearning/ContentQuestion`, payload);
};

const mutateQuestionBulk = async params => {
  const { id, questionBankId, isActive } = params;
  const payload: any = [
    {
      id,
      questionBankId,
      isActive: isActive !== undefined ? isActive : false,
    },
  ];

  return await instance.post(`/Elearning/ContentQuestion/bulk`, payload);
};

export const contentManagementContentQuestionAPI = {
  getQuestion,
  getQuestionDetail,
  getQuestionBankById,
  getCategoryBank,
  deleteQuestion,
  mutateQuestion,
  mutateQuestionStatus,
  mutateQuestionBulk,
};

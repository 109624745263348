import { Col, Row } from 'antd';
import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import Select from '../../../Assets/Components/Select';
import { IResultChannelElearningDAO } from '../../ElearningContentManagement/Channel/DAO/channelElearning.dao';
import { IResultFeedbackBankListDAO } from '../DAO/feedbackBank.dao';

interface IProps {
  columnData: any;
  isLoading: boolean;
  data: IResultFeedbackBankListDAO[];
  handleRefresh: () => void;
  handleFilter: () => void;
  handleResetFilter: () => void;
  handleOnClick: () => void;
  channelList: IResultChannelElearningDAO[];
  selectedChannel?: any;
  setSelectedChannel: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function SelfLearningComponent(props: IProps) {
  const {
    columnData,
    isLoading,
    data,
    handleRefresh,
    handleFilter,
    handleResetFilter,
    handleOnClick,
    channelList,
    selectedChannel,
    setSelectedChannel,
  } = props;

  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchSelfLearningList',
      disabled: selectedChannel === undefined,
      onClick: handleFilter,
    },
    {
      type: 'danger',
      className: 'btnReset btnFeedbackBank',
      content: 'Reset',
      id: 'btnResetSelfLearningList',
      disabled: selectedChannel === undefined,
      onClick: handleResetFilter,
    },
  ];

  const buttonData = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10  btnFeedbackBank',
      icon: null,
      content: 'Tambah Baru',
      id: 'btnAddNew',
      onClick: handleOnClick,
    },
    {
      type: 'ghost',
      icon: 'ReloadOutlined',
      content: 'Refresh',
      id: 'btnRefreshSelfLearningList',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];

  return (
    <div className="containerTabWhiteZurich">
      <Row gutter={5}>
        <Col span={4}>
          <label className="labelBtnFilter">Channel</label>
          <Select
            dataOption={channelList || []}
            idComponent="filterChannel"
            meta={{ touched: false, error: false }}
            input={{
              onBlur: null,
              onChange: setSelectedChannel,
              value: selectedChannel ? selectedChannel : 'Semua',
            }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={8} className={'btnFilterAplicationList'}>
          <CButton
            isLoading={isLoading}
            buttonData={buttonDataSearch}
            buttonFloat={'left'}
          />
        </Col>
        <Col span={12} className={'btnFilterAplicationList'}>
          <CButton
            isLoading={isLoading}
            buttonData={buttonData}
            buttonFloat={'right'}
          />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        columns={generateColumnData(columnData)}
        data={data}
        pagination={true}
        useFilterGlobal
      />
    </div>
  );
}

import moment from 'moment';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@tanstack/react-query';
import { FormattedMessage } from 'react-intl';
import {
  contestChanel,
  contestGroup,
  contestType,
  formatTime,
} from '../../../App/Enums';
import ContestTargetModalComponent from '../Component/ContestTargetModalComponent';
import { getContestRewardAPI } from '../../../Services/Contest/contestReward.api';
import { mutateContestTargetAPI } from '../../../Services/Contest/contestTarget.api';
import { IResultContestTargetDAO } from '../DAO/contestTarget.dao';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  handleRefresh: () => void;
  isLoading: boolean;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  contestDetail?: IResultContestTargetDAO;
}

export default function ContestTargetModalContainer(props: IProps) {
  const {
    contestDetail,
    handleRefresh,
    isLoading,
    selectedData,
    setSelectedData,
    setShowModal,
    showModal,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const modalAction = !selectedData ? 'register' : 'update';

  const { data: contestRewardList, isLoading: isLoadingcontestReward } =
    useQuery(['getContestRewardAPI'], getContestRewardAPI, {
      refetchOnWindowFocus: false,
    });

  const { mutate, isLoading: isLoadingMutation } = useMutation(
    mutateContestTargetAPI,
    {
      onSuccess: () => {
        const toastMessage = (
          <span className="capitalFirst">
            <FormattedMessage
              id={
                !selectedData
                  ? 'createContestTargetSuccess'
                  : 'updateContestTargetSuccess'
              }
            />
          </span>
        );
        toast.success(toastMessage, {
          className: 'toastSuccessBackground',
        });
        handleCancel();
        handleRefresh();
      },
      onError: (error: any) => {
        const responseStatus = error?.response.status;
        const errorData = error?.response.data;
        if (
          responseStatus === 500 &&
          errorData?.MessageKey === 'contest.date.intersect'
        ) {
          toast.error(
            <span className="capitalFirst">
              Contest has been added as another contest group
            </span>
          );
        } else {
          ErrorHandler(error);
        }
      },
    }
  );

  let initialValues: any = {};
  if (contestDetail && contestDetail.id) {
    const targetDetails = contestDetail?.targets;
    initialValues = {
      contestType: contestType[contestDetail?.contestType - 1].id,
      group: contestGroup[contestDetail?.group - 1].id,
      chanel: contestChanel[contestDetail?.chanel - 1].id,
      monitoringPeriode: contestDetail?.monitoringPeriode,
      numberOfTarget: contestDetail?.targets.length,
      contestLevel: contestDetail?.contestLevel,
      contestStatus: contestDetail?.contestStatus,
      timeStart: moment(contestDetail?.startDate).format(formatTime),
      timeEnd: moment(contestDetail?.endDate).format(formatTime),
      dateContest: [
        moment(contestDetail?.startDate),
        moment(contestDetail?.endDate),
      ],
    };
    targetDetails?.forEach((v, idx) => {
      initialValues[`targetName${idx}`] = v.name;
      initialValues[`rewardId${idx}`] = v.rewardId;
      initialValues[`rewardPersistency${idx}`] = v.persistency;
      initialValues[`rewardNumberOfTicket${idx}`] = v.numberOfTicket;
      initialValues[`rewardFyp${idx}`] = String(v.fyp);
      initialValues[`rewardNbFyp${idx}`] = String(v.nbFyp);
      initialValues[`rewardIsLocked${idx}`] = v?.isLocked;
    });
  }

  const handleCancel = () => {
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate({
        ...vals,
        id: selectedData?.id,
      });
    }
  };

  return (
    <ContestTargetModalComponent
      contestRewardList={contestRewardList || []}
      disabled={editMode === false && modalAction === 'update' ? true : false}
      editMode={editMode}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      isShow={showModal}
      modalAction={modalAction}
      initialValues={initialValues}
      isLoading={
        modalAction === 'update'
          ? isLoading || isLoadingcontestReward
          : isLoadingcontestReward
      }
      isLoadingSubmit={isLoadingMutation}
    />
  );
}

import instance from "../interceptor";

export const getAgreementContentAPI = async (params:any) => {
  const { data } = await instance.get(`/configuration/disclaimer?menuType=Agreement`);
    return data;
  };
export const mutateStatusAgreementContentAPI = async (params:any) => {
  const {id} = params;
  const action = params?.isActive === false ? 'inactive' : 'active';
    return await instance.put(`/configuration/disclaimer/${action}/${id}`, {});
  };
export const mutateAgreementContentAPI = async (params:any) => {  
  const { name, desc, id } = params;
  const payload = { name, desc, menuType: 'Agreement' }    
    if (id) {
      return await instance.put(`/configuration/disclaimer/${id}`, payload);
    } else {
      return await instance.post(`/configuration/disclaimer`, payload);
    }
  };

import React from 'react';
import history from './History';
import Store from '../Store';
interface IProps {
  isAuthenticated: any;
  redirect: any;
  captureCurrentStamp: any;
  lastActionTimeStamp: any;
  logOut: any;
}

export default function (ComposedComponent: any) {
  const store: any = Store();

  const token = store.token;
  class AuthMiddleware extends React.Component<IProps> {
    public componentDidMount() {
      this._checkAndRedirect();
    }

    public componentDidUpdate() {
      this._checkAndRedirect();
    }

    public _checkAndRedirect() {
      if (!token) {
        history.push(`/login`);
      }
    }

    public render() {
      return <div>{token ? <ComposedComponent {...this.props} /> : null}</div>;
    }
  }

  return AuthMiddleware;
}

import { Button, Col, Row } from 'antd';

import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import React from 'react';
import Table from '../../../../Assets/Components/CTable';
import { filterStatus } from '../../../../App/Enums';
import Select from '../../../../Assets/Components/Select';
import { IResultChannelElearningDAO } from '../DAO/channelElearning.dao';

interface IProps {
  columnData: any;
  dataChannel: IResultChannelElearningDAO[];
  handleApplyfilter: () => void;
  handleRefresh: () => void;
  isLoading: boolean;
  renderRowSubComponent: any;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  selectedStatus?: any;
}

export default function ChannelComponent(props: IProps) {
  const {
    columnData,
    dataChannel,
    selectedStatus,
    handleRefresh,
    isLoading,
    renderRowSubComponent,
    setModalIsShow,
    setSelectedStatus,
    handleApplyfilter,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      onClick: () => setModalIsShow(true),
      content: 'Tambah Baru',
      id: 'btnModalAddNewChannel',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshChannelList',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];
  return (
    <div className="containerWhiteZurich">
      <Row gutter={5}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus}
            idComponent="filterChannel"
            meta={{ touched: false, error: false }}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={4} className={'btnFilterAplicationList'}>
          <Button
            type="primary"
            icon={<CIcon type="SearchOutlined" />}
            onClick={handleApplyfilter}
            id="btnSearch"
            className="spacingBtnSecondRow2 btnRadius10 btnFeedbackBank"
            loading={isLoading}
          >
            Cari
          </Button>
        </Col>
        <Col span={16} className={'btnFilter'}>
          <CButton
            buttonData={buttonData}
            buttonFloat={'right'}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Table
        columns={columnData}
        pagination={true}
        data={dataChannel}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
        isLoading={isLoading}
      />
    </div>
  );
}

import { useMutation, useQuery } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import {
  getContentAPI,
  inquiryContentAPI,
} from '../../../../Services/content.api';
import { mutateClassNonSelf } from '../../../../Services/elearning.api';
import { getScheduleListAPI } from '../../../../Services/scheduleNonSelf.api';
import ClassModalComponent from '../Component/ClassModalComponent';
import ClassModalExamContainer from './ClassModalExamContainer';
import ClassModalGeneralContainer from './ClassModalGeneralContainer';
import { classParticipantType } from '../../../../App/Enums';

interface IProps {
  channel: any;
  isLoading: boolean;
  refetchClassDetail: () => void;
  refetchDataClass: () => void;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  stage: any;
  form?: any;
  formError?: any;
  selectedData?: any;
}

export default function ClassModalContainer(props: IProps) {
  const {
    channel,
    formError,
    isLoading,
    refetchClassDetail,
    refetchDataClass,
    selectedData,
    setSelectedData,
    setShowModal,
    stage,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const [currentSteps, setCurrentSteps] = useState(0);
  const [listSchedule, setListSchedule] = useState([]);
  const [indexLink, setIndexLink] = useState(1);
  const [contentList, setContentList] = useState([]);
  // For manual participant
  const [selectedAgentManual, setSelectedAgentManual] = useState<any[]>([]);

  useEffect(() => {
    if (selectedData) {
      setIndexLink(selectedData.embededLink.length);
    }
    // eslint-disable-next-line
  }, [selectedData?.embededLink]);

  const {
    data: dataSchedule,
    refetch: refetchSchedule,
    isLoading: isLoadingSchedule,
    isRefetching: isRefetchingSchedule,
    fetchStatus: fetchStatusSchedule,
  } = useQuery(['getScheduleDetail', selectedData?.id], getScheduleListAPI, {
    enabled: selectedData?.id !== undefined,
    refetchOnWindowFocus: false,
  });

  const { data: dataContent, refetch: refetchDataContent } = useQuery(
    [
      'getScheduleDetail',
      'ClassNonSelf',
      `contentProperties=classId%3D${selectedData?.id}`,
    ],
    inquiryContentAPI,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const getContentDetail = async dataContent => {
    const tempContent: any = [];
    for (const iterator of dataContent) {
      const data = await getContentAPI({
        queryKey: ['Get Content', iterator.id],
      });
      const decoded = await atob(data.content);
      delete data.content;
      data.size = decoded.length;
      tempContent.push(data);
    }
    setContentList(tempContent);
  };

  useEffect(() => {
    if (dataContent) {
      getContentDetail(dataContent);
    }
  }, [dataContent]);

  const classElearningModalAction = selectedData ? 'update' : 'register';

  const disabledTabs = (index: number, steps: number) => {
    if (!editMode) return false;
    else {
      if (index === steps) return false;
      else {
        if (
          currentSteps === 0 &&
          formError &&
          formError.general &&
          Object.keys(formError.general).length > 0
        ) {
          return true;
        } else if (
          currentSteps === 1 &&
          formError &&
          formError.exam &&
          Object.keys(formError.exam).length > 0
        ) {
          return true;
        } else {
          return false;
        }
      }
    }
  };

  const steps = [
    {
      title: 'General',
      content: (
        <ClassModalGeneralContainer
          channelCode={channel?.code || ''}
          modalAction={classElearningModalAction}
          listSchedule={listSchedule}
          setListSchedule={setListSchedule}
          dataSchedule={dataSchedule}
          indexLink={indexLink}
          setIndexLink={setIndexLink}
          contentList={contentList}
          editMode={editMode}
          refetchDataContent={refetchDataContent}
          refetchSchedule={refetchSchedule}
          refetchClassDetail={refetchClassDetail}
          dataClassNonSelf={selectedData}
          isLoading={isLoading}
          isLoadingSchedule={
            fetchStatusSchedule !== 'idle' &&
            (isLoadingSchedule || isRefetchingSchedule)
          }
          setShowModal={setShowModal}
          classDetail={selectedData}
          selectedAgentManual={selectedAgentManual}
          setSelectedAgentManual={setSelectedAgentManual}
        />
      ),
      disabled: disabledTabs(0, currentSteps),
    },
    {
      title: 'Exam',
      content: (
        <ClassModalExamContainer
          modalAction={classElearningModalAction}
          {...props}
        />
      ),
      disabled: disabledTabs(1, currentSteps),
    },
  ];
  const handleSteps = step => {
    setCurrentSteps(step);
  };
  const handleNext = () => {
    if (currentSteps !== steps.length - 1) {
      setCurrentSteps(currentSteps + 1);
    }
  };
  const handleBack = () => {
    if (currentSteps !== 0) {
      setCurrentSteps(currentSteps - 1);
    }
  };

  const handleEditClick = () => {
    setEditMode(true);
  };

  const handleCancel = () => {
    setCurrentSteps(0);
    setSelectedData(undefined);
    setShowModal(false);
    setEditMode(false);
  };
  const mutateClass = useMutation(mutateClassNonSelf, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          Success{' '}
          {classElearningModalAction === 'register' ? 'create new' : 'update'}{' '}
          class
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setShowModal(false);
      setSelectedData(undefined);
      refetchDataClass();
    },
    onError: err => {
      const toastMessage = (
        <span className="capitalFirst">
          Anda gagal{' '}
          {classElearningModalAction === 'register'
            ? 'membuat class baru'
            : 'update class'}
          , silahkan coba beberapa saat lagi
        </span>
      );
      toast.error(toastMessage);
    },
  });

  const initialValues: any = { ...selectedData };
  if (selectedData) {
    const { agentLevels, startLos, endLos, participantType } = selectedData;
    const valueType = classParticipantType[participantType]?.id || '';
    initialValues.haveCertificate =
      selectedData.isCertificate.toLowerCase() === 'true';
    initialValues.status = selectedData.isActive.toLowerCase() === 'true';
    initialValues.levelParticipantType = agentLevels;
    initialValues.fromLengthType = startLos;
    initialValues.toLengthType = endLos;
    const embededLink: any = [];
    for (const iterator of selectedData.embededLink) {
      embededLink.push({ link: iterator });
    }
    initialValues.embed = embededLink;
    initialValues.participantType = valueType;
  }

  const onSubmit = vals => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      if (
        classElearningModalAction === 'register' &&
        listSchedule.length === 0
      ) {
        const toastMessage = (
          <span className="capitalFirst">Schedule required!</span>
        );
        toast.error(toastMessage, {
          className: 'toastSuccessBackground',
        });
      } else {
        mutateClass.mutate({
          ...vals,
          listSchedule,
          isCreated: selectedData === 'update',
          id: selectedData?.id,
          channel,
          stage,
          selectedAgentManual,
        });
      }
    }
  };

  return (
    <ClassModalComponent
      currentSteps={currentSteps}
      disabledTabs={disabledTabs}
      editMode={editMode}
      handleBack={handleBack}
      handleCancel={handleCancel}
      handleEditClick={handleEditClick}
      handleNext={handleNext}
      handleStep={handleSteps}
      initialValues={initialValues}
      isLoading={mutateClass.isLoading || isLoading}
      modalAction={classElearningModalAction}
      onSubmit={onSubmit}
      selectedAgentManual={selectedAgentManual}
      setEditMode={setEditMode}
      steps={steps}
    />
  );
}

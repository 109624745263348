import moment from 'moment';

interface ClassScheduleProps {
  city?: string;
  date?: any;
  endTime?: any;
  locationDetails?: string;
  locationLink?: string;
  meetingLink?: string;
  platform?: string;
  province?: string;
  schedule?: any[];
  sessions?: any[];
  startTime?: any;
  type?: string;
  venue?: string;
}

export function classScheduleValidation(
  values: ClassScheduleProps,
  action: 'register' | 'update',
  mode: 'register' | 'update',
  editMode: boolean
) {
  const {
    city,
    date,
    endTime,
    locationDetails,
    meetingLink,
    platform,
    province,
    sessions,
    startTime,
    type,
    venue,
  } = values;
  const errors: any = {};
  // for create new class or create new schedule in existing class
  if (action === 'register' || (action === 'update' && mode === 'register')) {
    if (!type) errors.type = 'Schedule Type Wajib Diisi!';
    if (!date || date === null) errors.date = 'Date Wajib Diisi!';
    if (!startTime) errors.startTime = 'Start Time Wajib Diisi!';
    if (!endTime) errors.endTime = 'End Time Wajib Diisi!';
    if (startTime && endTime && date) {
      if (moment.isMoment(startTime)) {
        if (startTime.diff(endTime) > -1) {
          errors.endTime = 'End time harus lebih besar dari Start Time!';
        } else if (
          date[0].diff(moment().startOf('day'), 'days') === 0 &&
          startTime.diff(moment(), 'seconds') <= 0
        ) {
          errors.startTime = 'Start time harus lebih besar dari saat ini!';
        }
      }
    }
  }
  // for updating schedule in existing class
  if (action === 'update' && mode === 'update' && editMode) {
    if (!sessions || sessions.length === 0)
      errors.session = 'Session harus diisi!';
    else {
      const errorSchedule: any = [];
      for (const key in sessions) {
        const errScheduleItem: any = {};
        if (Object.prototype.hasOwnProperty.call(sessions, key)) {
          const element = sessions[key];
          const { date, disabled, startTime, endTime, trainerName } = element;

          let selectedDate;
          let selectedStartTime;
          let selectedEndTime;

          if (!disabled) {
            if (!date) errScheduleItem.date = 'Date required!';
            else selectedDate = date.format('DD-MM-YYYY');

            if (!startTime) errScheduleItem.startTime = 'Time required!';
            else selectedStartTime = startTime.format('HH:mm:ss');

            if (!endTime) errScheduleItem.endTime = 'Time required!';
            else selectedEndTime = endTime.format('HH:mm:ss');

            if (selectedDate && selectedStartTime && selectedEndTime) {
              const fullFormatDTStart = moment(
                `${selectedDate} ${selectedStartTime}`,
                'DD-MM-YYYY HH:mm:ss'
              );
              const fullFormatDTEnd = moment(
                `${selectedDate} ${selectedEndTime}`,
                'DD-MM-YYYY HH:mm:ss'
              );
              if (
                fullFormatDTStart.diff(moment().startOf('day'), 'days') === 0 &&
                fullFormatDTStart.diff(moment(), 'seconds') <= 0
              ) {
                errScheduleItem.startTime = 'Start time must greater than now!';
              }
              if (fullFormatDTEnd.diff(fullFormatDTStart) <= 0) {
                errScheduleItem.endTime =
                  'End time must greater than Start Time!';
              }
            }

            if (!trainerName || trainerName.length === 0)
              errors.trainerName = 'Trainer Name Required!';
          }
        }
        errorSchedule.push(errScheduleItem);
      }
      errors.schedule = errorSchedule;
    }
  }

  if (type) {
    if (type === 'onsite') {
      if (!venue) errors.venue = 'Venue Wajib Diisi!';
      if (!province) errors.province = 'Province Wajib Diisi!';
      if (!city) errors.city = 'City Wajib Diisi!';
      if (!locationDetails)
        errors.locationDetails = 'Location Details Wajib Diisi!';
      if (locationDetails && locationDetails.length > 255)
        errors.locationDetails = 'Max 255 karakter';
    } else if (type === 'online') {
      if (!platform) errors.platform = 'Platform Wajib Diisi!';
      if (!meetingLink) errors.meetingLink = 'Meeting Link Wajib Diisi!';
    }
  }

  return errors;
}

interface ClassGeneralProps {
  agentManual: any[];
  classDesc?: string;
  codemiCetificateId?: string;
  cpdPoint?: number;
  cpdRefreshName?: string;
  examDuration?: string;
  examMaxAttempt?: string;
  examMinScore?: string;
  fromLengthType?: any;
  haveCertificate?: string;
  isCpdStatus?: boolean;
  levelParticipantType?: any;
  name?: string;
  participantLevel?: { level: string; total: number }[];
  participantType?: 'LEVEL' | 'LOS' | 'MANUAL';
  status?: string;
  toLengthType?: any;
  totalAgentLOS?: number;
  trainerName?: string[];
  trainingType?: string;
  type?: string;
}

export function classGeneralValidation(
  values: ClassGeneralProps,
  mode: string,
  selectedAgentManual: any[]
) {
  const {
    // examDuration,
    // examMaxAttempt,
    // examMinScore,
    // trainerName,
    codemiCetificateId,
    cpdPoint,
    cpdRefreshName,
    fromLengthType,
    haveCertificate,
    isCpdStatus,
    levelParticipantType,
    name,
    participantLevel,
    participantType,
    toLengthType,
    totalAgentLOS,
    trainingType,
    type,
  } = values;
  const errors: any = {};

  // General validation
  if (!trainingType) errors.trainingType = 'Training Type Wajib Diisi!';
  if (!trainingType) errors.trainingType = 'Training Type Wajib Diisi!';
  if (!name) errors.name = 'Class Name Wajib Diisi!';
  if (!type) errors.type = 'Class Type Wajib Diisi!';

  if (mode === 'register') {
    // if (type === 'private' && !participantType)
    //   errors.participantType = 'If private, participant required!';

    if (participantType === 'MANUAL') {
      if (!selectedAgentManual || selectedAgentManual.length === 0)
        errors.participantType = 'If selected, agent required!';
    } else if (participantType === 'LOS') {
      if (
        fromLengthType === undefined ||
        toLengthType === undefined ||
        fromLengthType === null ||
        toLengthType === null
      ) {
        errors.participantType = 'If selected, range required!';
      } else {
        if (toLengthType < fromLengthType)
          errors.fromLengthType = 'To must bigger than from';

        if (totalAgentLOS === 0)
          errors.participantType =
            'Belum ada agent yang dipilih, silahkan memilih participant pada tab participant';
      }
    } else if (participantType === 'LEVEL') {
      if (!levelParticipantType || levelParticipantType.length === 0)
        errors.participantType = 'If selected, level required!';
      if (
        levelParticipantType &&
        participantLevel &&
        !participantLevel.find(item => item.total > 0)
      )
        errors.participantType =
          'Belum ada agent yang dipilih, silahkan memilih participant pada tab participant';
    }
  }

  if (isCpdStatus) {
    if (!cpdRefreshName || cpdRefreshName.trim() === '') {
      errors.cpdRefreshName = 'CPD Refresh Name Required!';
    }
    if (!cpdPoint) {
      errors.cpdPoint = 'CPD Point Required';
    }
  }

  if (haveCertificate && !codemiCetificateId) {
    errors.codemiCetificateId = 'Certificate List Required!';
  }

  // // Exam validation
  // if (!examDuration) errors.examDuration = 'Duration Wajib Diisi!';
  // else if (examDuration && isNaN(parseFloat(examDuration)))
  //   errors.examDuration = 'Duration harus angka!';
  // if (!examMinScore) errors.examMinScore = 'Min score Wajib Diisi!';
  // else if (examMinScore && isNaN(parseFloat(examMinScore)))
  //   errors.examMinScore = 'Min score harus angka!';
  // if (!examMaxAttempt) errors.examMaxAttempt = 'Max attempt Wajib Diisi!';
  // else if (examMaxAttempt && isNaN(parseFloat(examMaxAttempt)))
  //   errors.examMaxAttempt = 'Max attempt harus angka!';
  return errors;
}

import { Button, Col, Row } from 'antd';
import React from 'react';
import { elearningStatus, isCertificate } from '../../../../App/Enums';
import CButton from '../../../../Assets/Components/CButton';
import CIcon from '../../../../Assets/Components/CIcon';
import Table from '../../../../Assets/Components/CTable';
import Select from '../../../../Assets/Components/Select';

interface IProps {
  columnData: any;
  handleAdd: () => void;
  handleApplyfilter: () => void;
  handleRefresh: () => void;
  isLoading: boolean;
  moduleList: any;
  renderRowSubComponent: any;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      isActive: string;
      isCertificate: string;
    }>
  >;
  selectedFilter?: {
    isActive: string;
    isCertificate: string;
  };
}

export default function ModuleComponent(props: IProps) {
  const {
    columnData,
    handleAdd,
    handleApplyfilter,
    handleRefresh,
    isLoading,
    moduleList,
    renderRowSubComponent,
    selectedFilter,
    setFilter,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      content: 'Tambah Baru',
      id: 'btnModalAddNewStage',
      className: 'spacingBtnSecondRow2 btnRadius10',
      onClick: handleAdd,
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshStageList',
      className: 'btnRadius10',
      onClick: () => handleRefresh(),
    },
  ];

  return (
    <div className="containerWhiteZurich">
      <Row gutter={20}>
        <Col span={15}>
          <div style={{ display: 'flex' }}>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="labelBtnFilter">Certificate</label>
              <Select
                customStyleInput={{ width: '180px' }}
                dataOption={isCertificate}
                idComponent="filterCertificate"
                meta={{ touched: false, error: false }}
                input={{
                  onBlur: null,
                  onChange: (e: string) =>
                    setFilter(prev => ({
                      ...prev,
                      isCertificate:
                        e === undefined || e === null || e === '' ? '' : e,
                    })),
                  value: selectedFilter
                    ? selectedFilter.isCertificate
                    : 'Semua',
                }}
                defaultItemName="Semua"
              />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <label className="labelBtnFilter">Status</label>
              <Select
                customStyleInput={{ width: '150px' }}
                dataOption={elearningStatus}
                idComponent="filterChannel"
                meta={{ touched: false, error: false }}
                input={{
                  onBlur: null,
                  onChange: (e: string) =>
                    setFilter(prev => ({
                      ...prev,
                      isActive:
                        e === undefined || e === null || e === '' ? '' : e,
                    })),
                  value: selectedFilter ? selectedFilter.isActive : 'Semua',
                }}
                defaultItemName="Semua"
              />
            </div>
            <div style={{ marginLeft: '10px' }}>
              <Button
                type="primary"
                icon={<CIcon type="SearchOutlined" />}
                onClick={handleApplyfilter}
                loading={isLoading}
                id="btnSearch"
                style={{ marginTop: '22px' }}
                className="btnRadius10"
              >
                Cari
              </Button>
            </div>
          </div>
        </Col>
        <Col span={9} className={'btnFilterAplicationList'}>
          <CButton
            buttonData={buttonData}
            buttonFloat={'right'}
            isLoading={isLoading}
          />
        </Col>
      </Row>
      <Table
        isLoading={isLoading}
        columns={columnData}
        pagination={true}
        data={moduleList}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
      />
    </div>
  );
}

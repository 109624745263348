import { Button } from 'antd';
import React from 'react';
import { Field } from 'react-final-form';
import CIcon from '../../../../Assets/Components/CIcon';
import { InputSwitch, InputText } from '../../../../Assets/Components/CInput';
import CModalRFF from '../../../../Assets/Components/CModalRFF';
import HasPermission from '../../../../App/HasPermission';
import Select from '../../../../Assets/Components/Select';
import channelValidation from '../../Validation/channelValidation';
import { FormattedMessage, injectIntl } from 'react-intl';

interface IProps {
  editMode: boolean;
  handleCancel: () => void;
  handleSearch: (val: any, form: any) => void;
  initialValues: any;
  isLoading: boolean;
  isShow: boolean;
  modalAction: 'update' | 'register';
  onSubmit: (vals: any) => void;
  trainerList: string[];
  intl?: any;
}

function ChannelModalComponent({
  editMode,
  handleCancel,
  handleSearch,
  initialValues,
  intl,
  isLoading,
  isShow,
  modalAction,
  onSubmit,
  trainerList,
}: IProps) {
  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailChannelElearning" />
        ) : (
          <FormattedMessage id="addChannelElearning" />
        )}
      </h3>
    );
  };

  const contentComponent = propsContent => {
    const { form } = propsContent;
    const disabled =
      editMode === false && modalAction === 'update' ? true : false;

    return (
      <>
        <Field
          name="code"
          component={InputText}
          placeholder={intl.formatMessage({
            id: 'placeholderCodeChannel',
          })}
          label={intl.formatMessage({ id: 'codeChannel' })}
          idComponent="inputCodeChannel"
          disabled={disabled}
          maxlength={2}
        />
        <Field
          name="name"
          component={InputText}
          placeholder={intl.formatMessage({
            id: 'placeholderNameChannel',
          })}
          label={intl.formatMessage({ id: 'nameChannelSelf' })}
          idComponent="inputNameChannel"
          disabled={disabled}
          maxlength={15}
        />
        <Field
          name="headOfTrainer"
          component={Select}
          idComponent="inputHeadOfTrainerChannel"
          onSearch={val => handleSearch(val, form)}
          dataOption={trainerList}
          showSearch={true}
          placeholder="Type Here"
          label="Head of Trainer"
          disabled={disabled}
          customValue="name"
        />
        <Field
          name="trainerName"
          component={InputText}
          idComponent="inputTrainerNameChannel"
          placeholder="Type Here"
          label="Trainer Name"
          disabled={disabled}
          addtionalCaption="*Untuk dicantumkan pada sertifikat"
        />
        <Field
          name="signOffCaption"
          component={InputText}
          placeholder={intl.formatMessage({
            id: 'placeholderSignOffCaption',
          })}
          label={intl.formatMessage({ id: 'signOfCaptionChannel' })}
          idComponent="inputSignOffCaption"
          disabled={disabled}
        />
        <Field
          name="isActive"
          component={InputSwitch}
          disabled={disabled}
          label={intl.formatMessage({ id: 'statusChannel' })}
          idComponent="inputStatusChannel"
        />
      </>
    );
  };

  const footerComponent = propsFooter => {
    const { invalid } = propsFooter;
    const idComponent =
      modalAction === 'register'
        ? 'btnCreate'
        : editMode === false
        ? 'btnEdit'
        : 'btnSaveChange';
    const content =
      modalAction === 'register'
        ? 'Create'
        : editMode === false
        ? 'Edit'
        : 'Save Change';

    if (HasPermission('u') || HasPermission('c')) {
      return (
        <Button
          loading={isLoading}
          className={
            editMode
              ? invalid
                ? 'btnSubmitDisabled'
                : 'btnSubmit'
              : 'btnSubmit'
          }
          icon={<CIcon type="CheckOutlined" />}
          disabled={modalAction === 'update' && !editMode ? false : invalid}
          id={idComponent}
          htmlType="submit"
        >
          {content}
        </Button>
      );
    }
    return null;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={channelValidation}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

export default injectIntl(ChannelModalComponent);

import { Alert, Col, Row, Skeleton } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import CButton from '../../../../../Assets/Components/CButton';
import {
  InputSwitch,
  InputText,
  InputTextNumber,
  TextInput,
} from '../../../../../Assets/Components/CInput';
import CModalRFF from '../../../../../Assets/Components/CModalRFF';
import { RadioButton } from '../../../../../Assets/Components/CRadio';
import CTransferTextRFF from '../../../../../Assets/Components/CTransferTextRFF';
import Select from '../../../../../Assets/Components/Select';

interface IProps {
  categoryBankList: any[];
  editMode: boolean;
  handleCancel: () => void;
  handleOnChangeCategory: (e: any) => void;
  handleOnChangeStatus: (e: boolean) => void;
  handleResetQuestion: () => void;
  handleSetListBank: React.Dispatch<React.SetStateAction<never[]>>;
  handleSetListSelected: React.Dispatch<React.SetStateAction<never[]>>;
  isLoading: any;
  isShow: boolean;
  listQuestion: any[];
  modalAction: 'update' | 'register';
  onSubmit: (values: any) => void;
  selectedQuestion: any[];
  initialValues?: any;
}

export default function QuestionModalComponent(props: IProps) {
  const {
    categoryBankList,
    editMode,
    handleCancel,
    handleOnChangeCategory,
    handleOnChangeStatus,
    handleResetQuestion,
    handleSetListBank,
    handleSetListSelected,
    initialValues,
    isLoading,
    isShow,
    listQuestion,
    modalAction,
    onSubmit,
    selectedQuestion,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <FormattedMessage id="addContentQuestion" />
      </h3>
    );
  };
  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { values, form } = propsContent;

    const renderOptionAnswer = (title, index) => {
      return (
        <>
          <span className="inputAnswer">{title}</span>
          <Field
            name={`answerOption.${index}`}
            className="inputNumberSubModuleInline"
            component={TextInput}
            disabled={true}
          />{' '}
        </>
      );
    };

    const dataAnswer = [
      {
        id: 'answer_1',
        component: renderOptionAnswer('Jawaban 1', 0),
      },
      {
        id: 'answer_2',
        component: renderOptionAnswer('Jawaban 2', 1),
      },
      {
        id: 'answer_3',
        component: renderOptionAnswer('Jawaban 3', 2),
      },
      {
        id: 'answer_4',
        component: renderOptionAnswer('Jawaban 4', 3),
      },
    ];
    if (modalAction === 'update')
      return (
        <>
          <div className="disclaimerQuestionContent">
            <div className="textBold">Catatan:</div>
            <p>
              Jika ingin Mengedit pertanyaan dibawah. Silahkan mengakses menu{' '}
              <span className="textBold">{`Elearning > `}</span>
              <span className="textBold textUnderline">
                <Link to={'/elearning/questionbank'}>Question Bank</Link>
              </span>
            </p>
          </div>
          <div className="containerGridTwo">
            <div>
              <Field
                name="title"
                component={InputText}
                idComponent="inputQuestionTitle"
                placeholder={'Ketik disini'}
                label={'Question Title'}
                textArea={true}
                disabled={true}
              />
            </div>
            <div>
              <Field
                name="point"
                component={InputTextNumber}
                idComponent="inputPointQuestion"
                label="Point"
                min={1}
                defaultValue={1}
                disabled={true}
              />
            </div>
          </div>
          <div className="statusQuestion">Answer List</div>
          <Alert message="Pilih salah satu untuk jawaban yang benar" banner />
          <Field
            name="answer"
            component={RadioButton}
            layout="vertical"
            disabled={true}
            className="multipleRadioButton"
            dataOption={dataAnswer}
          />
          <div className="statusModal">
            <div className="statusQuestion">Status</div>
            <div>
              <Field
                name="isActive"
                component={InputSwitch}
                idComponent="inputStatusQuestion"
                additionalOnChange={e => handleOnChangeStatus(e)}
              />
            </div>
          </div>
        </>
      );
    else
      return (
        <Skeleton loading={isLoading} active>
          <div className="disclaimerQuestionContent">
            <div className="textBold">Catatan:</div>
            <p>
              Jika ingin Menambahkan, Mengedit atau Menghapus daftar pertanyaan
              dibawah. Silahkan mengakses menu{' '}
              <span className="textBold">{`Elearning > `}</span>
              <span className="textBold textUnderline">
                <Link to={'/elearning/questionbank'}>Question Bank</Link>
              </span>
            </p>
          </div>
          <Row>
            <Col span={10}>
              <p className="labelCategory">Category</p>
              <Field
                name="category"
                component={Select}
                idComponent="inputPredecessorStage"
                dataOption={categoryBankList}
                defaultItemName="-Select-"
                onSelect={handleOnChangeCategory}
              />
            </Col>
          </Row>
          <CTransferTextRFF
            titleContentLeft={'Question Bank'}
            titleContentRight={'Selected Question'}
            listBank={listQuestion}
            listSelected={selectedQuestion}
            formName="formContentQuestion"
            actionSetListBank={handleSetListBank}
            actionSetListSelected={handleSetListSelected}
            form={form}
            formValues={values}
          />
        </Skeleton>
      );
  };

  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const dataButtonModalContentQuestion = [
      {
        type: 'primary',
        className: 'btnResetContentQuestion',
        id: 'btnResetContentQuestion',
        content: 'Reset',
        onClick: handleResetQuestion,
        disabled: selectedQuestion.length > 0 ? false : true,
      },
      {
        type: 'primary',
        className: 'btnCreateContentQuestion',
        id: 'btnCreateContentQuestion',
        content: buttonContent(modalAction, editMode),
        onClick: onSubmit,
        disabled: selectedQuestion.length > 0 ? false : true,
      },
    ];
    if (modalAction === 'update') return null;
    else return <CButton buttonData={dataButtonModalContentQuestion} />;
  };

  return (
    <CModalRFF
      modalIsShow={isShow}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={() => {}}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
    />
  );
}

import React, { useState } from 'react';
import ContestTabHistoryComponent from '../Component/ContestTabHistoryComponent';
import ModalContestUploadContainer from './ModalContestUploadContainer';
import HasPermission from '../../../App/HasPermission';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import { useQuery } from '@tanstack/react-query';
import { progressStatusUpload } from '../../../App/Enums';
import { getContestUploadAPI } from '../../../Services/Contest/contestUpload';

export default function ContestTabHistoryContainer() {
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();

  const {
    data: contestList,
    isLoading: isLoadingContest,
    fetchStatus: fetchStatusContest,
    refetch: refetchContest,
  } = useQuery(['getContestUploadAPI'], getContestUploadAPI, {
    refetchOnWindowFocus: false,
  });

  const handleRefresh = () => refetchContest();

  const renderAction = row => {
    const editClick = () => {
      const rows = row.row.original;
      setSelectedData(rows);
      setShowModal(true);
    };
    return renderActionComponent(
      isLoadingContest || fetchStatusContest === 'fetching',
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
      },
      {
        handleUpdate: editClick,
      }
    );
  };

  const columnData = [
    {
      Header: 'Origial File Name',
      accessor: 'originalFilename',
    },
    {
      Header: 'Status',
      Cell: row => progressStatusUpload[row.row.original.progressStatus].name,
    },
    HasPermission('u')
      ? {
          Header: 'Action',
          Cell: row => renderAction(row),
        }
      : {
          accessor: 'hideAction',
        },
  ];

  return (
    <>
      <ContestTabHistoryComponent
        columnData={columnData}
        contestList={contestList || []}
        handleRefresh={handleRefresh}
        isLoading={isLoadingContest || fetchStatusContest === 'fetching'}
      />
      <ModalContestUploadContainer
        showModal={showModal}
        setShowModal={setShowModal}
        setSelectedData={setSelectedData}
        selectedData={selectedData}
      />
    </>
  );
}

import { Checkbox } from 'antd';
import React from 'react';

interface IPropsText {
  input;
  meta;
  idComponent;
  disabled;
  content?;
  className?;
  additionalOnChange?;
}

export const InputChecbox = ({
  input,
  meta: { touched, error },
  idComponent,
  disabled,
  content,
  className,
  additionalOnChange,
}: IPropsText) => {
  const handleOnChange = e => {
    input.onChange(e.target.checked);
    if (additionalOnChange) {
      additionalOnChange(e.target.checked);
    }
  };
  return (
    <Checkbox
      disabled={disabled}
      id={idComponent}
      onChange={handleOnChange}
      checked={typeof input.value === 'boolean' ? input.value : false}
      className={className}
    >
      {content}
    </Checkbox>
  );
};

import React from 'react';
import { Field } from 'react-final-form';
import { InputText } from '../../../Assets/Components/CInput';
import Select from '../../../Assets/Components/Select';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { createCategoryList, createPathList } from '../../../App/Enums';
import { Button, Skeleton } from 'antd';
import validate from '../Validation/CatatanPendingValidation';
import { FormApi } from 'final-form';

interface IProps {
  modalPendingIsShow: boolean;
  handleCancel: () => void;
  schema: any;
  editMode: boolean;
  modalAction: string;
  initialValues: any;
  onSubmit: any;
  isLoading: boolean;
  handleSelectCategory: (form: FormApi<any, Partial<any>>) => void;
}
export default function ModalCatatanPendingComponent(props: IProps) {
  const {
    handleCancel,
    schema,
    modalPendingIsShow,
    editMode,
    modalAction,
    initialValues,
    onSubmit,
    handleSelectCategory,
    isLoading,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        <span>Catatan Baru</span>
      </h3>
    );
  };

  const contentComponent = (formProps: any) => {
    const { form, values } = formProps;
    const isDisabled =
      editMode === false && modalAction === 'update' ? true : false;
    return (
      <Skeleton active loading={isLoading}>
        <React.Fragment>
          <Field
            name="title"
            component={InputText}
            idComponent="inputTitle"
            placeholder="Isi judul"
            label="Judul Catatan"
            disabled={isDisabled}
          />
          <hr className="linePendingRemarks" />
          <Field
            name="category"
            component={Select}
            idComponent="inputCategory"
            dataOption={
              schema ? createCategoryList(schema.schema.properties) : []
            }
            label={'Kategori'}
            defaultItemName="Pilih"
            disabled={isDisabled}
            onSelect={() => handleSelectCategory(form)}
          />
          <hr className="linePendingRemarks" />
          <Field
            name="note"
            component={InputText}
            idComponent="inputNote"
            placeholder={'Ketik disini'}
            label={'Catatan'}
            textArea={true}
            disabled={isDisabled}
          />
          <hr className="linePendingRemarks" />
          <Field
            name="pathForm"
            component={Select}
            idComponent="inputFormType"
            dataOption={
              values && values.category && schema
                ? createPathList(values.category, schema.schema.properties)
                : []
            }
            label={'Tipe Form'}
            defaultItemName="Pilih"
            disabled={
              (!editMode && modalAction === 'update') ||
              (values && values.category === 'beneficiary') ||
              (values && values.category === 'workexperience')
                ? true
                : false
            }
          />
        </React.Fragment>
      </Skeleton>
    );
  };
  const buttonContent = (action: string, editMode: boolean) => {
    if (editMode === false) {
      if (action === 'register') {
        return 'Buat';
      }
      return 'Edit Catatan';
    }
    return 'Simpan';
  };

  const footerComponent = (formProps: any) => {
    const { invalid } = formProps;
    return (
      <Button
        className={
          editMode ? (invalid ? 'btnSubmitDisabled' : 'btnSubmit') : 'btnSubmit'
        }
        disabled={modalAction === 'update' && !editMode ? false : invalid}
        id="btnSubmit"
        htmlType="submit"
      >
        {buttonContent(modalAction, editMode)}
      </Button>
    );
  };
  return (
    <CModalRFF
      modalIsShow={modalPendingIsShow}
      headerComponent={headerComponent()}
      contentComponent={(formProps: any) => contentComponent(formProps)}
      footerComponent={(formProps: any) => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={validate}
      isForm={true}
      handleSubmit={onSubmit}
      formLayout="vertical"
      initialValues={initialValues}
      modalstyles={{
        content: {
          position: 'relative',
          background: 'none',
          maxWidth: '95vw',
          width: '500px',
          padding: '0px',
          border: 'none',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
        overlay: {
          background: 'rgba(0, 0, 0, 0.75)',
          zIndex: '99',
        },
      }}
    />
  );
}

import React, { useState } from 'react';
import {
  getDisclaimerContentAPI,
  mutateStatusDisclaimerContentAPI,
} from '../../../Services/Disclaimer/disclaimerContent.api';
import DisclaimerContentComponent from '../Component/DisclaimerContentComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toastr } from 'react-redux-toastr';
import { Switch } from 'antd';
import { toast } from 'react-toastify';
import CButton from '../../../Assets/Components/CButton';
import ErrorHandler from '../../../App/ErrorHandler';

export default function DisclaimerContentContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: disclaimerContentList,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['getDisclaimerContent'], getDisclaimerContentAPI, {
    refetchOnWindowFocus: false,
  });

  const mutateStatus = useMutation(mutateStatusDisclaimerContentAPI, {
    onSuccess: () => {
      refetch();
      toast.success(
        <span className="capitalFirst">Update status disclaimer sukses!</span>
      );
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const processStatus = (id: number, status: any) => {
    mutateStatus.mutate({ isActive: status, id });
  };
  const processEnabledStatus = (id: number, status: boolean, row: any) => {
    const action = status === true ? 'active' : 'inactive';
    const toastrConfirmOptions = {
      okText: 'Ok',
      cancelText: 'Cancel',
      onOk: () => processStatus(id, status),
      id: 'toastRConfirm',
    };
    toastr.confirm(
      `Are you sure to ${action} this disclaimer ?`,
      toastrConfirmOptions
    );
  };

  const renderStatus = (row: any) => {
    const rowData = row.row.original;
    const processEnabled = (isChecked: any) =>
      processEnabledStatus(rowData.id, isChecked, row);
    return (
      <Switch
        onChange={processEnabled}
        checked={rowData.isActive}
        id={rowData.id}
      />
    );
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'primary',
        id: `btnEditDisclaimerContent`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'buttonTable',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const handleRefresh = () => {
    setSelectedData(undefined);
    refetch();
  };
  return (
    <DisclaimerContentComponent
      disclaimerContentList={disclaimerContentList || []}
      columnData={columnData}
      modalIsShow={modalIsShow}
      setModalIsShow={setModalIsShow}
      handleRefresh={handleRefresh}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      isLoading={isLoading || isRefetching}
    />
  );
}

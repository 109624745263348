import React from 'react';
import logo from '../../../Assets/Images/zurich-logo-big-blue.png';
import CIcon from '../../../Assets/Components/CIcon';
import moment from 'moment';

export default function SuccessfulNotificationComponent() {
  const year = moment().format('YYYY');

  return (
    <div className="bgSuccessRecruitment">
      <img src={logo} alt="logo" className="logoZurichSuccess" />
      <div className="containerSuccess">
        <CIcon type="CheckCircleOutlined" className="iconCheck" />
        <div className="textBox">Berhasil dikirim</div>
        <hr className="lineBox" />
        <div className="textBox2">
          Selamat! formulir rekrutmen lead telah berhasil dikirimkan
        </div>
      </div>
      <div className="txtCopyRight">Copyright © {year} Zurich Indonesia</div>
    </div>
  );
}

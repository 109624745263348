import React from 'react';
import { Field } from 'react-final-form';
import CButton from '../../../../../Assets/Components/CButton';
import { InputTextNumber } from '../../../../../Assets/Components/CInput';
import { RadioButton } from '../../../../../Assets/Components/CRadio';
import { Form } from 'antd';

interface IProps {
  disabled: boolean;
  handleAddtionalChangeTypeExam: (e: any) => void;
  handleResetExamValue: () => void;
  modalAction: string;
  formValues?: any;
  editMode?: any;
}

const examType = [
  {
    id: 'Exam',
    name: 'Exam',
  },
  {
    id: 'Quiz',
    name: 'Quiz',
  },
];

export default function SubmoduleModalExamComponent(props: IProps) {
  const {
    formValues,
    disabled,
    handleAddtionalChangeTypeExam,
    handleResetExamValue,
    editMode,
    modalAction,
  } = props;

  const dataButtonReset = [
    {
      type: 'primary',
      id: 'btnResetExamValue',
      className: 'btnResetExam',
      content: 'Reset',
      onClick: handleResetExamValue,
      disabled:
        !editMode && modalAction === 'update'
          ? true
          : formValues && formValues.exam
          ? false
          : true,
    },
  ];

  const isExam =
    formValues && formValues.exam && formValues.exam.type === 'Exam';
  const isQuiz =
    formValues && formValues.exam && formValues.exam.type === 'Quiz';

  return (
    <Form layout="vertical" style={{ width: '40%' }}>
      <div className="containerGridTwo">
        <div>
          <Field
            name={'exam.type'}
            component={RadioButton}
            label="Select Type"
            dataOption={examType}
            idComponent="inputType"
            layout="vertical"
            disabled={disabled}
            addtionalFunction={handleAddtionalChangeTypeExam}
          />
          <Field
            name={'exam.questionLimit'}
            component={InputTextNumber}
            label="Question Limit"
            className="inputExam"
            idComponent="inputQuestionLimit"
            labelIsRequired={true}
            min={1}
            disabled={disabled}
          />
          <Field
            name={'exam.duration'}
            component={InputTextNumber}
            label="Duration"
            className="inputSubModul"
            idComponent="inputDuration"
            description="Minute"
            labelIsRequired={true}
            min={1}
            disabled={disabled}
          />
          <CButton buttonData={dataButtonReset} />
        </div>
        <div>
          <Field
            name={'exam.minimumScore'}
            component={InputTextNumber}
            label="Minimum Score"
            className="inputExam"
            idComponent="inputminimumScore"
            labelIsRequired={true}
            min={1}
            disabled={disabled}
          />
          <Field
            name={'exam.maxAttempt'}
            component={InputTextNumber}
            label="Max. Attempt"
            className="inputExam"
            idComponent="inputmaxAttempt"
            min={1}
            disabled={disabled || isQuiz ? true : false}
            labelIsRequired={isExam ? true : false}
          />
        </div>
      </div>
    </Form>
  );
}

import { UseQueryOptions } from '@tanstack/react-query';
import moment from 'moment';
import {
  IResultCandidateFormDao,
  IResultCandidateGroupDao,
} from '../Modules/License/DAO/license.dao';
import { IResultAgentMonitoringDAO } from '../Modules/NewAgentMonitoring/DAO/newAgentMonitoring.dao';
import instance from './interceptor';

export const downloadAgentMonitoringReportAPI = async (params: any) => {
  const { dateFilter, status } = params;
  const startDay = moment().startOf('month').format('YYYY-MM-DD');
  const endDay = moment().endOf('month').format('YYYY-MM-DD');
  const startForm =
    dateFilter !== null ? moment(dateFilter[0]).format('YYYY-MM-DD') : startDay;
  const startTo =
    dateFilter !== null ? moment(dateFilter[1]).format('YYYY-MM-DD') : endDay;
  const url = `/Candidate/report/agent/monitoring/xlsx?StartFrom=${startForm}&StartTo=${startTo}&CaseTemplate=MZA&JourneyStatus=${
    status ? status : ''
  }`;
  await instance
    .get(`${url}`, {
      responseType: 'blob',
      method: 'GET',
    })
    .then((response: any) => {
      const DateNow = moment(new Date()).format('DDMMYYYY');
      const fileName = `New-Agent-Monitoring-${DateNow}.xlsx`;
      const urlDownload = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = urlDownload;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    });
};

export const getAgentMonitoringDataAPI = async (
  params: any
): Promise<IResultAgentMonitoringDAO[]> => {
  const { date, status } = params.queryKey[1];
  const startFrom = moment(date[0]).format('YYYY-MM-DD');
  const startTo = moment(date[1]).format('YYYY-MM-DD');
  const { data } = await instance.get(
    `/Candidate/workflow/agent/monitoring?StartFrom=${startFrom}&StartTo=${startTo}&CaseTemplate=MZA&JourneyStatus=${
      status ? status : ''
    }`
  );
  return data;
};

export const getInvitationById = async (params: any) => {
  const { id } = params.queryKey[1];
  const { data } = await instance.get(`/configuration/template/${id}`);
  return data;
};

export const getCandidateGroupData = async (
  params: UseQueryOptions
): Promise<IResultCandidateGroupDao> => {
  const caseId = params?.queryKey?.[1];
  const taskId = params?.queryKey?.[2];
  const { data } = await instance.get(
    `Candidate/workflow/group/detail?caseId=${caseId}&taskId=${taskId}`
  );
  return data;
};

export const getCandidateFormDataAPI = async (
  params: UseQueryOptions
): Promise<IResultCandidateFormDao> => {
  const candidateCode = params?.queryKey?.[1];
  console.log(candidateCode);
  const { data } = await instance.get(`/form?Code=FRMCR0001`);
  return data[0];
};

export const getCandidateApproverData = async (
  params: UseQueryOptions
): Promise<IResultCandidateFormDao> => {
  const agentCode = params?.queryKey?.[1];
  const candidateLevel = params?.queryKey?.[2];
  const { data } = await instance.get(
    `/Candidate/lookup/approver/${agentCode}/${candidateLevel}`
  );
  return data;
};

import React, { useState } from 'react';
import {
  deleteQuestionBankAPI,
  getQuestionBankAPI,
} from '../../../Services/questionbank.api';
import { useMutation, useQuery } from '@tanstack/react-query';

import CButton from '../../../Assets/Components/CButton';
import QuestionBankComponent from '../Component/QuestionBankComponent';
import { getCategoryBankAPI } from '../../../Services/categorybank.api';
import { toast } from 'react-toastify';
import { toastr } from 'react-redux-toastr';
import { getElearningChannelAPI } from '../../../Services/ElearningContentManagement/channel.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function QuestionBankContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [selectedChannel, setSelectedChannel] = useState();
  const [selectedCategory, setSelectedCategory] = useState();
  const [questionFilter, setQuestionFilter] = useState<any>();

  const {
    data: listQuestionBank,
    isLoading: isLoadingQuestionBank,
    isRefetching: isRefetchQuestionBank,
    refetch,
  } = useQuery(['getQuestionBankAPI'], getQuestionBankAPI, {
    refetchOnWindowFocus: false,
  });

  const { data: listElearningChannel } = useQuery(
    ['getElearningChannelAPI'],
    getElearningChannelAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const { data: listCategoryBank } = useQuery(
    ['getCategoryBankAPI', 'active'],
    getCategoryBankAPI,
    {
      refetchOnWindowFocus: false,
    }
  );

  const deleteQuestionBank = useMutation(deleteQuestionBankAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      toast.success(
        <span className="capitalFirst">Question Berhasil Dihapus</span>
      );
      setQuestionFilter(undefined);
      refetch();
    },
  });

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDelete = () => {
      const toastrConfirmOptions = {
        okText: 'Delete',
        cancelText: 'Batalkan',
        onOk: () => deleteQuestionBank.mutate(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(`Delete Question "${rows.title}"`, toastrConfirmOptions);
    };
    const dataButton = [
      {
        id: `btnDeleteQuestionBank`,
        icon: 'DeleteOutlined',
        className: 'btnDelete',
        onClick: handleDelete,
      },
      {
        id: `btnEditQuestionBank`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'btnEdit',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const handleCategoryName = (row: any) => {
    const rows = row.row.original;
    if (rows.categoryName === null) {
      return '-';
    } else {
      return rows.categoryName;
    }
  };

  const columnData = [
    {
      Header: 'Channel',
      accessor: 'channelName',
    },
    {
      Header: 'Category',
      accessor: 'categoryName',
      Cell: (row: any) => handleCategoryName(row),
    },
    {
      Header: 'Question',
      accessor: 'title',
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const handleFilter = () => {
    const dataChannelFilter = listQuestionBank?.find(
      (x: any) => x.channelId === selectedChannel
    );
    const dataCategoryFilter = listQuestionBank?.find(
      (x: any) => x.categoryId === selectedCategory
    );
    if (
      (selectedChannel === undefined && selectedCategory === undefined) ||
      (!selectedChannel && !selectedCategory)
    ) {
      setQuestionFilter(undefined);
      refetch();
    } else {
      const questionBankFilter = listQuestionBank?.filter((x: any) => {
        if (selectedChannel && selectedCategory) {
          return (
            x.channelName === dataChannelFilter?.channelName &&
            x.categoryName === dataCategoryFilter?.categoryName
          );
        } else if (
          (selectedChannel && !selectedCategory) ||
          (selectedCategory && !selectedChannel)
        ) {
          return (
            x.channelName === dataChannelFilter?.channelName ||
            x.categoryName === dataCategoryFilter?.categoryName
          );
        } else {
          return null;
        }
      });
      setQuestionFilter(questionBankFilter);
    }
  };

  const handleReset = () => {
    setSelectedChannel(undefined);
    setSelectedCategory(undefined);
    setQuestionFilter(undefined);
    refetch();
  };

  const handleRefresh = () => {
    setSelectedChannel(undefined);
    setSelectedCategory(undefined);
    setQuestionFilter(undefined);
    refetch();
  };

  return (
    <QuestionBankComponent
      columnData={columnData}
      handleFilter={handleFilter}
      handleRefresh={handleRefresh}
      handleReset={handleReset}
      isLoading={isLoadingQuestionBank || isRefetchQuestionBank}
      listCategoryBank={listCategoryBank || []}
      listElearningChannel={listElearningChannel || []}
      listQuestionBank={listQuestionBank || []}
      modalIsShow={modalIsShow}
      questionFilter={questionFilter}
      selectedCategory={selectedCategory}
      selectedChannel={selectedChannel}
      selectedData={selectedData}
      setModalIsShow={setModalIsShow}
      setSelectedCategory={setSelectedCategory}
      setSelectedChannel={setSelectedChannel}
      setSelectedData={setSelectedData}
    />
  );
}

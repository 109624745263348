import React, { useState, useEffect } from 'react';
import LibraryComponent from '../Component/LibraryComponent';
import HasPermission from '../../../App/HasPermission';
import {
  deleteLibraryFileAPI,
  deleteLibraryFolderAPI,
  findLibraryListAPI,
  getLibraryListAPI,
} from '../../../Services/library.api';
import { useQuery, useMutation } from '@tanstack/react-query';
import { Tooltip } from 'antd';
import CIcon from '../../../Assets/Components/CIcon';
import moment from 'moment';
import { formatDate } from '../../../App/Enums';
import { renderActionComponent } from '../../../Assets/Components/CTable';
import { toast } from 'react-toastify';
import { toastr } from 'react-redux-toastr';
import { FormattedMessage } from 'react-intl';
import { IResultLibraryListDAO } from '../DAO/library.dao';
import ErrorHandler from '../../../App/ErrorHandler';

export default function LibraryContainer() {
  const [selectedPath, setSelectedPath] = useState('Home');
  const [findMode, setFindMode] = useState(false);
  const [isRoot, setIsRoot] = useState(true);
  const [findName, setFindName] = useState('');
  const [modalFolderIsShow, setModalFolderIsShow] = useState(false);
  const [modalUploadIsShow, setModalUploadIsShow] = useState(false);
  const [libraryList, setLibraryList] = useState<IResultLibraryListDAO[]>([]);
  const [librarySelected, setLibrarySelected] = useState<
    IResultLibraryListDAO[]
  >([]);
  const [selectedData, setSelectedData] = useState();
  const [modalMoveShow, setModalMoveShow] = useState(false);
  const [libraryCollection, setLibraryCollection] = useState();
  const [selectedMovePath, setSelectedMovePath] = useState([]);
  const [moveType, setMoveType] = useState('');

  const {
    data: list,
    isLoading,
    refetch,
    fetchStatus,
    isRefetching,
  } = useQuery(['getLibraryList', selectedPath, isRoot], getLibraryListAPI, {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (list) {
      if (isRoot) {
        setLibraryList(list);
      }
      if (!isRoot || selectedPath === 'Home') {
        setLibrarySelected(list);
      }
    }
    // eslint-disable-next-line
  }, [list, isRoot]);

  const {
    data: librarySearchResult,
    refetch: refetchResult,
    isLoading: isLoadingResult,
    fetchStatus: fetchStatusResult,
    isRefetching: isRefetchingResult,
  } = useQuery(['findLibraryList', findName], findLibraryListAPI, {
    enabled: findName !== '',
    refetchOnWindowFocus: false,
  });

  const { mutate: mutateLibraryFolder, data: listLibraryFolder } = useMutation(
    deleteLibraryFolderAPI,
    {
      onError: (error: any) => {
        const responseStatus = error?.response.status;
        const errorData = error?.response.data;
        if (
          responseStatus === 500 &&
          errorData?.MessageKey === 'file.cannot.deleted'
        ) {
          toast.error(
            <span className="capitalFirst">
              <FormattedMessage id={'failedDeleteLibrary'} />;
            </span>
          );
        } else {
          ErrorHandler(error);
        }
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Success delete folder</span>
        );
        refetch();
        setIsRoot(true);
      },
    }
  );

  const { mutate: mutateLibraryFile, data: listLibraryFile } = useMutation(
    deleteLibraryFileAPI,
    {
      onError: (error: any) => {
        const responseStatus = error?.response.status;
        const errorData = error?.response.data;
        if (
          responseStatus === 500 &&
          errorData?.MessageKey === 'file.cannot.deleted'
        ) {
          toast.error(
            <span className="capitalFirst">
              <FormattedMessage id={'failedDeleteLibrary'} />;
            </span>
          );
        } else {
          ErrorHandler(error);
        }
      },
      onSuccess: () => {
        toast.success(
          <span className="capitalFirst">Success delete file</span>
        );
        refetch();
        setIsRoot(true);
      },
    }
  );

  useEffect(() => {
    if (listLibraryFolder) {
      setLibrarySelected(listLibraryFolder?.data);
    }
    if (listLibraryFile) {
      setLibrarySelected(listLibraryFile?.data);
    }
    // eslint-disable-next-line
  }, [listLibraryFolder, listLibraryFile]);

  const renderName = (text: string) => {
    const name = text.substring(0, 10);
    if (text.length > 10) {
      return `${name}${text.length > 10 ? '...' : ''}`;
    }
    return name;
  };

  const renderItemListLib = (rowData: any) => {
    return (
      <span>
        <CIcon
          type={`${rowData.type === 'folder' ? 'FolderFilled' : 'FileFilled'}`}
          className={`${
            rowData.type === 'folder' ? 'iconDirTree' : 'iconFileTree'
          }`}
        ></CIcon>
        {renderName(rowData.name)}
      </span>
    );
  };

  const renderFileName = (row: any) => {
    const rowData = row.row.original;
    const nameItem = rowData.name;
    if (nameItem.length > 10) {
      return (
        <Tooltip placement="top" title={nameItem}>
          <span>{renderItemListLib(rowData)}</span>
        </Tooltip>
      );
    }
    return renderItemListLib(rowData);
  };

  const renderLocation = (text: string) => {
    const path = text.substring(0, 10);
    if (text.length > 10) {
      return `${path}${text.length > 10 ? '...' : ''}`;
    }
    return path;
  };

  const renderItemLocationLib = (rowData: any) => {
    return (
      <span>
        <CIcon
          type={`${rowData.type === 'folder' ? 'FolderFilled' : 'FileFilled'}`}
          className={`${
            rowData.type === 'folder' ? 'iconDirTree' : 'iconFileTree'
          }`}
        ></CIcon>
        {renderLocation(rowData.path)}
      </span>
    );
  };

  const renderItemLocation = (row: any) => {
    const rowData = row.row.original;
    const nameItem = rowData.path;
    if (nameItem.length > 10) {
      return (
        <Tooltip placement="top" title={nameItem}>
          <span>{renderItemLocationLib(rowData)}</span>
        </Tooltip>
      );
    }
    return renderItemListLib(rowData);
  };

  const renderStatus = (row: any) => {
    const rows = row.row.original;
    if (rows.status === true) {
      return 'Published';
    } else if (rows.type !== 'folder') {
      return 'Not published';
    } else {
      return null;
    }
  };

  const renderEffectiveDate = (row: any) => {
    const rows = row.row.original;
    if (rows.startDate) {
      return moment(rows.startDate).format(formatDate);
    } else if (rows.startDate && rows.endDate) {
      return '-';
    } else if (rows.endDate) {
      return moment(rows.endDate).format(formatDate);
    } else {
      return null;
    }
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleOpenModal = () => {
      if (rows?.type === 'folder') {
        setModalFolderIsShow(true);
      } else {
        setModalUploadIsShow(true);
      }
    };
    const handleDelete = () => {
      const toastrConfirmOptions = {
        onOk: () =>
          rows.type === 'folder'
            ? mutateLibraryFolder(rows.id)
            : mutateLibraryFile(rows.id),
        id: 'toastRConfirm',
      };
      toastr.confirm(
        `Are you sure to delete ${
          rows.type === 'folder' ? 'Folder' : 'File'
        } "${rows.name}"?`,
        toastrConfirmOptions
      );
    };
    return renderActionComponent(
      isLoading,
      row.row.id,
      {
        renderUpdate: HasPermission('u'),
        renderDelete: HasPermission('d'),
      },
      {
        handleUpdate: () => {
          setSelectedData(rows);
          handleOpenModal();
        },
        handleRemove: () => handleDelete(),
      }
    );
  };

  const columnData = [
    {
      Header: 'Name',
      accessor: 'name',
      Cell: renderFileName,
    },
    findMode
      ? {
          Header: 'Location',
          accessor: 'path',
          Cell: renderItemLocation,
        }
      : {
          accessor: 'LocationNull',
        },
    {
      Header: 'Status',
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Effective Date',
      Cell: (row: any) => renderEffectiveDate(row),
    },
    HasPermission('d') || HasPermission('u')
      ? {
          Header: 'Action',
          width: 50,
          Cell: (row: any) => renderAction(row),
        }
      : {
          accessor: 'null',
        },
  ];

  const handleOnClickBreadcrumb = (path: any) => {
    setSelectedPath(path);
    setIsRoot(path === 'Home' ? true : false);
  };

  const handleOnDoubleClickRow = (record: any) => {
    setFindMode(false);
    if (record.type === 'folder') {
      setSelectedPath(
        record.path === 'Home' ? record.path : `Home${record.path}`
      );
    }
    setIsRoot(false);
  };

  const handleLibraryData = (list: any, path: any) => {
    const dataList: any = [];
    if (list) {
      for (const iterator of list) {
        const icon = () => {
          return (
            <CIcon
              className={
                iterator.type === 'folder' ? 'iconDirTree' : 'iconFileTree'
              }
              type={iterator.type === 'folder' ? 'FolderFilled' : 'FileFilled'}
            />
          );
        };
        const pathRes = `${path}/${iterator.name}`;
        const renderTitleTree = () => {
          return (
            <Tooltip placement="top" title={iterator.name}>
              <span>{renderName(iterator.name)}</span>
            </Tooltip>
          );
        };
        if (iterator.content && iterator.content.length > 0) {
          dataList.push({
            title: renderTitleTree(),
            key: pathRes,
            icon,
            type: iterator.type,
            children: handleLibraryData(iterator.content, pathRes),
          });
        } else {
          dataList.push({
            title: renderTitleTree(),
            key: pathRes,
            type: iterator.type,
            icon,
          });
        }
      }
    }
    return dataList;
  };
  const libraryData = handleLibraryData(libraryList, 'Home');

  const handleOnSelectTree = (selectedKeys: string, e: any) => {
    setFindMode(false);
    setIsRoot(false);
    const type = e.selectedNodes[0].type;
    if (type === 'folder') {
      setSelectedPath(selectedKeys[0]);
      refetch();
      setIsRoot(false);
    }
  };

  const handleRefresh = () => {
    setIsRoot(true);
    setFindName('');
    refetchResult();
  };

  const handleOnSearchLibrary = (value: any) => {
    if (value !== '') {
      setFindMode(true);
    } else {
      setFindMode(false);
    }
    setFindName(value);
    refetchResult();
  };

  const isLoadingList = fetchStatus !== 'idle' && (isLoading || isRefetching);
  const isLoadingSearch =
    fetchStatusResult !== 'idle' && (isLoadingResult || isRefetchingResult);

  return (
    <LibraryComponent
      list={libraryList}
      columnData={columnData}
      selectedPath={selectedPath}
      handleOnClickBreadcrumb={(val: any) => handleOnClickBreadcrumb(val)}
      libraryList={findName ? librarySearchResult : librarySelected}
      handleOnDoubleClickRow={(val: any) => handleOnDoubleClickRow(val)}
      libraryListData={libraryData || []}
      handleRefresh={handleRefresh}
      isLoadingList={isLoadingSearch || isLoadingList}
      handleOnSelectTree={handleOnSelectTree}
      handleOnSearchLibrary={(val: any) => handleOnSearchLibrary(val)}
      modalFolderIsShow={modalFolderIsShow}
      setModalFolderIsShow={setModalFolderIsShow}
      modalUploadIsShow={modalUploadIsShow}
      setModalUploadIsShow={setModalUploadIsShow}
      setIsRoot={setIsRoot}
      setLibrarySelected={setLibrarySelected}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      refetch={refetch}
      modalMoveShow={modalMoveShow}
      setModalMoveShow={setModalMoveShow}
      libraryCollection={libraryCollection}
      setLibraryCollection={setLibraryCollection}
      selectedMovePath={selectedMovePath}
      setSelectedMovePath={setSelectedMovePath}
      moveType={moveType}
      setMoveType={setMoveType}
    />
  );
}

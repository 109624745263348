import { Button, Form as FormAntd, Layout, Skeleton } from 'antd';

import CIcon from './CIcon';
import Modal from 'react-modal';
import React from 'react';

interface IProps {
  headerComponent: any;
  contentComponent: any;
  footerComponent: any;
  isLoading?: boolean;
  modalIsShow: boolean;
  handleCancel: () => void;
  handleSubmit?: () => void;
  modalstyles?: any;
  isForm?: boolean;
  formLayout?: any;
  contentClassName?: any;
}

const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '1000px',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '99',
  },
};

export default function CModalFile(props: IProps) {
  const { Header, Content, Footer } = Layout;
  const {
    isForm,
    modalIsShow,
    modalstyles,
    handleCancel,
    headerComponent,
    footerComponent,
    contentComponent,
    handleSubmit,
    isLoading,
    formLayout,
    contentClassName,
  } = props;

  const renderContent = () => {
    if (isForm) {
      const layout =
        formLayout === 'horizontal'
          ? {
              labelCol: { span: 6 },
              wrapperCol: { span: 18 },
            }
          : null;
      return (
        <>
          <FormAntd
            onFinish={handleSubmit}
            layout={formLayout || 'vertical'}
            {...layout}
          >
            <Content
              className={contentClassName ? contentClassName : 'contentModal'}
            >
              {isLoading ? <Skeleton active /> : contentComponent}
            </Content>
            <Footer className="footerModal">{footerComponent}</Footer>
          </FormAntd>
        </>
      );
    } else {
      return (
        <React.Fragment>
          <Content
            className={contentClassName ? contentClassName : 'contentModal'}
          >
            {isLoading ? <Skeleton active /> : contentComponent}
          </Content>
          <Footer className="footerModal">{footerComponent}</Footer>
        </React.Fragment>
      );
    }
  };

  return (
    <Modal
      isOpen={modalIsShow}
      style={modalstyles ? modalstyles : defaultModalstyles}
      className={
        modalIsShow
          ? 'modalStyleContainer'
          : 'modalStyleContainer modalStyleContainerClose'
      }
    >
      <Layout>
        <Button className={'buttonCloseModal'} onClick={handleCancel}>
          <CIcon type="CloseOutlined" />
        </Button>
        <Header
          className={headerComponent ? 'headerModal' : 'headerModalNotif'}
        >
          {headerComponent}
        </Header>
        {renderContent()}
      </Layout>
    </Modal>
  );
}

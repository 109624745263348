import { Col, Row } from 'antd';
import { FormApi } from 'final-form';
import React from 'react';
import { Field } from 'react-final-form';
import { singleCheckbox } from '../../../../../Assets/Components/CCheckbox';
import {
  InputSwitch,
  InputText,
  InputTextNumber,
} from '../../../../../Assets/Components/CInput';
import { InputChecbox } from '../../../../../Assets/Components/CInputCheckbox';
import Select from '../../../../../Assets/Components/Select';

interface IProps {
  certificateList: any[];
  disabled: boolean;
  predecessorList: any[];
  handleAddtionalOnChangeSequence: (
    val: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleAddtionalSelectPredecessor: (
    val: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  // handleResetListSertifikat: (
  //   val: any,
  //   form: FormApi<any, Partial<any>>
  // ) => void;
  formValues?: any;
}

export default function ModuleModalGeneralComponent({
  certificateList,
  predecessorList,
  disabled,
  formValues,
  // handleResetListSertifikat,
  handleAddtionalOnChangeSequence,
  handleAddtionalSelectPredecessor,
}: IProps) {
  return (
    <div className="containerGridTwo">
      <div>
        <Field
          name={'general.name'}
          component={InputText}
          label="Module Name"
          className="inputSubModul"
          idComponent="inputName"
          placeholder="Type Here"
          labelIsRequired={true}
          disabled={disabled}
        />
        <div className="containerInputTwo">
          <Field
            name={'general.predecessor'}
            component={Select}
            label="Predecessor"
            className="inputSubModul"
            dataOption={predecessorList}
            defaultItemName="-Select-"
            idComponent="inputPredecessor"
            onSelect={handleAddtionalSelectPredecessor}
            disabled={disabled}
          />
          <Field
            name={'general.sequence'}
            component={InputTextNumber}
            label="Sequence"
            className="inputSubModul"
            idComponent="inputSequence"
            addtionalOnChange={handleAddtionalOnChangeSequence}
            disabled={disabled}
            min={1}
          />
        </div>
        <div style={{ marginBottom: '30px' }}>
          <Field
            name="general.isCertificate"
            idComponent="inputCertificate"
            component={InputChecbox}
            disabled={disabled}
          />
          <label style={{ marginLeft: '10px' }}>Have Certificate</label>
        </div>
        <Field
          name="general.certificateId"
          idComponent="selectCertificate"
          component={Select}
          dataOption={certificateList}
          placeholder="-Select-"
          defaultItemName="-Select-"
          label="List Sertifikat"
          disabled={disabled || !formValues?.general?.isCertificate}
          labelIsRequired={formValues && formValues?.general?.isCertificate}
        />
        <Field
          name={'general.isRefreshment'}
          component={singleCheckbox}
          label="Refreshment"
          idComponent="inputRefreshment"
          disabled={disabled}
          type="checkbox"
        />
      </div>
      <div>
        <div className="containerBackgroundInputSubModul">
          <p className="textBold">CPD Status</p>
          <Row>
            <Col span={2}>
              <Field
                name={'general.isCpdStatus'}
                component={singleCheckbox}
                idComponent="inputCPDStatus"
                disabled={disabled}
                type="checkbox"
              />
            </Col>
            <Col span={8}>
              <span className="descCheckbox">
                {formValues && formValues?.general?.isCpdStatus === true
                  ? 'Yes'
                  : 'No'}
              </span>
            </Col>
          </Row>
          <Field
            name="general.cpdRefreshName"
            component={InputText}
            label="CPD Refresh Name"
            className="inputSubModul"
            idComponent="inputcpdRefreshName"
            placeholder="Type Here"
            disabled={disabled || !formValues?.general?.isCpdStatus}
            maxlength={40}
            labelIsRequired={
              formValues && formValues?.general?.isCpdStatus ? true : false
            }
          />
          <Field
            name="general.cpdPoint"
            label="CPD Poin"
            component={InputTextNumber}
            className="inputSubModul"
            idComponent="inputCpdPoin"
            placeholder="0"
            disabled={disabled || !formValues?.general?.isCpdStatus}
            labelIsRequired={
              formValues && formValues?.general?.isCpdStatus ? true : false
            }
            min={0}
          />
        </div>
        <div className="statusModal">
          <div className="textBold">Default Learning Hour</div>
          <div className="defaultLearningHourRequired">*</div>
        </div>
        <div className="statusModal">
          <Field
            name="general.defaultLearningHour"
            component={InputTextNumber}
            className="inputSubModul"
            idComponent="inputLearningHour"
            placeholder="0"
            min={0}
            disabled={disabled}
          />
          <span className="descCheckbox">Minute</span>
        </div>
        <Row>
          <Col span={4}>
            <b className="labelSwitchStatusModule">Status</b>
          </Col>
          <Col span={4}>
            <Field
              name="general.isActive"
              idComponent="inputStatus"
              component={InputSwitch}
              disabled={disabled}
            />
          </Col>
          <Col span={6}>
            <span>
              {formValues && formValues?.general?.isActive === true
                ? 'Active'
                : 'Not Active'}
            </span>
          </Col>
        </Row>
      </div>
    </div>
  );
}

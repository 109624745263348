import React from 'react';
import { Field, Form } from 'react-final-form';
import { InputText } from '../../../Assets/Components/CInput';
import Select from '../../../Assets/Components/Select';
import { Button, Form as FormAntd, Radio } from 'antd';
import { InputDatePicker } from '../../../Assets/Components/CInputDate';
import InputFile from '../../../Assets/Components/InputFile';
import validate from '../Validation/formValidation';
import VeryEnthusias from '../../../Assets/Images/sangat-antusias.png';
import Enthusias from '../../../Assets/Images/Antusias.png';
import Normal from '../../../Assets/Images/biasa-saja.png';
import NotEnthusias from '../../../Assets/Images/tidak-tertarik.png';
import NotVeryEnthusias from '../../../Assets/Images/sangat-tidak-tertarik.png';
import IndonesiaIcon from '../../../Assets/Images/indonesia.png';
import InputSelfie from '../../../Assets/Components/InputSelfie';
import moment from 'moment';

interface IProps {
  cityList: any[];
  enthusiasm?: number;
  handleClickEnthusiasm: (val: number) => void;
  handleSubmit: (val: any) => void;
  response: any[];
}

export default function FormComponent(props: IProps) {
  const {
    cityList,
    enthusiasm,
    handleClickEnthusiasm,
    handleSubmit,
    response,
  } = props;

  const renderAntusiasmeBtn = () => {
    const list = [
      {
        alt: 'NotVeryEnthusias',
        value: 1,
        src: NotVeryEnthusias,
        label: 'Sangat tidak tertarik',
      },
      {
        alt: 'NotEnthusias',
        value: 2,
        src: NotEnthusias,
        label: 'Tidak tertarik',
      },
      {
        alt: 'Normal',
        value: 3,
        src: Normal,
        label: 'Biasa saja',
      },
      {
        alt: 'Enthusias',
        value: 4,
        src: Enthusias,
        label: 'Antusias',
      },
      {
        alt: 'VeryEnthusias',
        value: 5,
        src: VeryEnthusias,
        label: 'Sangat antusias',
      },
    ];
    return (
      <div style={{ marginTop: '10px' }}>
        <b>Antusiasme</b>
        <div className="recruiterFormEnthusiasm">
          {list.map(item => {
            return (
              <div className="recruiterLabelImgFormEnthusiasm" key={item.value}>
                <div
                  key={item.value}
                  onClick={() => handleClickEnthusiasm(item.value)}
                  style={{
                    backgroundColor: enthusiasm === item.value ? '#009EE0' : '',
                  }}
                  className="recruiterImgFormEnthusiasm"
                >
                  <img
                    src={item.src}
                    alt={item.alt}
                    style={{ width: '24px' }}
                  />
                </div>
                <p style={{ textAlign: 'center', fontSize: '10px' }}>
                  {item.label}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const calculateAge = (n: number) => {
    let year = '';
    if (n < 20) year = '19';
    else if (n >= 20 && n <= 40) year = '21';
    else if (n >= 41 && n <= 50) year = '41';
    else if (n > 50) year = '51';
    return year;
  };

  return (
    <Form onSubmit={handleSubmit} validate={val => validate(val, response)}>
      {formProps => {
        const {
          values,
          handleSubmit: handleSubmitForm,
          invalid,
          form,
        } = formProps;
        return (
          <FormAntd
            values={values}
            onFinish={handleSubmitForm}
            layout={'vertical'}
          >
            <div className="recruitmentFormContainer">
              <div>
                <Field
                  name="ktp"
                  component={InputFile}
                  height={240}
                  label={
                    <span>
                      <b>Upload KTP</b>
                    </span>
                  }
                  uppyConfig={{
                    minFile: 1,
                    maxFile: 1,
                    allowedFile: ['image/png', 'image/jpg', 'image/jpeg'],
                    maxSize: 15728640,
                  }}
                  useFile={true}
                  formClassName="inputFormRecruitment"
                />
                <Field
                  name="name"
                  component={InputText}
                  placeholder="Nama Lengkap Sesuai KTP"
                  label="Nama Calon Kandidat (Sesuai KTP)"
                  labelIsRequired
                  formClassName="inputFormRecruitment"
                />
                <Field
                  name="idNumber"
                  component={InputText}
                  placeholder="16 Angka Sesuai KTP"
                  label="NIK (Sesuai KTP)"
                  maxlength={16}
                  formClassName="inputFormRecruitment"
                />
                <Field
                  name="city"
                  component={Select}
                  dataOption={cityList}
                  defaultItemName="- Pilih -"
                  label="Kota"
                  labelIsRequired
                  customValue="name"
                  filterOption={true}
                  showSearch
                  formClassName="inputFormRecruitment"
                  className="addMarginBtm"
                />
                <Field
                  name="dob"
                  component={InputDatePicker}
                  idComponent="inputDateFilter"
                  label="Tanggal Lahir"
                  typeDate="datePicker"
                  placeholder="dd-mm-yyyy"
                  formatInput="DD-MMM-YYYY"
                  addtionalLabel={
                    <span>
                      (Wajib diisi<span className="labelRequired">*</span> bila
                      kolom kisaran usia kosong)
                    </span>
                  }
                  addtionalLabelPos="bottom"
                  className="addMarginBtm"
                  formClassName="inputFormRecruitment"
                  additionalOnChange={e => {
                    if (e) {
                      const year = calculateAge(moment().diff(e, 'years'));
                      form.change('ageRange', year);
                    } else if (e === null) form.change('ageRange', '');
                  }}
                />
              </div>
              <div>
                <Field
                  name="ageRange"
                  component={Select}
                  idComponent="modalClassType"
                  formClassName="inputFormRecruitment"
                  dataOption={[
                    { id: '19', name: '<20' },
                    { id: '21', name: '20-40' },
                    { id: '41', name: '40-50' },
                    { id: '51', name: '>50' },
                  ]}
                  defaultItemName="- Pilih -"
                  label="Kisaran Usia"
                  labelIsRequired
                  addtionalLabel={
                    <span>
                      (Wajib diisi<span className="labelRequired">*</span> bila
                      kolom tanggal lahir kosong)
                    </span>
                  }
                  addtionalLabelPos="bottom"
                  className="addMarginBtm"
                />
                <Field
                  name="email"
                  component={InputText}
                  placeholder="emailkandidat@mail.com"
                  label="Email"
                  labelIsRequired
                  formClassName="inputFormRecruitment"
                />
                <div style={{ marginBottom: '0px' }}>
                  <span>
                    <b className="capital">Nomor yang dapat dihubungi</b>
                    <span className="labelRequired">*</span>
                  </span>
                  <div className="divRecruitmentFormPhoneNumber">
                    <div className="divFlagPhoneNumber">
                      <img
                        src={IndonesiaIcon}
                        alt="Indonesia"
                        style={{ width: '31px' }}
                      />
                      <span>+62</span>
                    </div>
                    <Field
                      name="phoneNumber"
                      component={InputText}
                      placeholder="856xxx"
                      labelIsRequired
                      formClassName="recruitmentFormPhoneNumber"
                    />
                  </div>
                </div>
                <FormAntd.Item
                  label={
                    <span>
                      <b className={`capital`}>Jenis Kelamin</b>
                      <span className="labelRequired">*</span>
                    </span>
                  }
                >
                  <Field
                    name="gender"
                    type="radio"
                    render={({ input }) => {
                      const handleChange = e => input.onChange(e);
                      return (
                        <Radio.Group
                          onChange={handleChange}
                          options={[
                            {
                              label: 'Pria',
                              value: 'M',
                            },
                            {
                              label: 'Wanita',
                              value: 'F',
                            },
                          ]}
                        />
                      );
                    }}
                  />
                </FormAntd.Item>
                <Field
                  name="selfie"
                  component={InputSelfie}
                  height={240}
                  labelTitle={
                    <div>
                      <b>Foto Selfie</b>
                      <span className="labelRequired">*</span>
                    </div>
                  }
                  uppyConfig={{
                    minFile: 1,
                    maxFile: 1,
                    allowedFile: ['image/png', 'image/jpg', 'image/jpeg'],
                    maxSize: 15728640,
                    customModes: ['picture'],
                  }}
                  formClassName="inputFormRecruitment"
                />
                {renderAntusiasmeBtn()}
              </div>
            </div>
            <hr className="hrTerms" style={{ marginBottom: '20px' }} />
            <Button
              className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
              style={{ marginBottom: '30px', marginTop: '20px' }}
              disabled={invalid}
              id="btnSubmit"
              htmlType="submit"
            >
              Tambah Kandidat
            </Button>
          </FormAntd>
        );
      }}
    </Form>
  );
}

import React from 'react';
import CButton from '../../../Assets/Components/CButton';
import Table, { generateColumnData } from '../../../Assets/Components/CTable';
import CLabelCard from '../../../Assets/Components/CLabelCard';
import CCard from '../../../Assets/Components/CCard';
import ModalCatatanPendingContainer from '../Container/ModalCatatanPendingContainer';

interface IProps {
  columnData: any;
  remarksList: any[];
  handleRefresh: () => void;
  isLoadingList: boolean;
  schema: any;
  modalPendingIsShow: boolean;
  setModalPendingIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
}
export default function CatatanPendingComponent(props: IProps) {
  const {
    columnData,
    remarksList,
    handleRefresh,
    isLoadingList,
    schema,
    modalPendingIsShow,
    setModalPendingIsShow,
    selectedData,
    setSelectedData,
  } = props;

  const buttonData = [
    {
      type: 'primary',
      icon: 'PlusOutlined',
      content: 'Tambah Baru',
      onClick: () => setModalPendingIsShow(true),
      id: 'btnAddPendingRemarks',
    },
  ];

  const contentTab = () => {
    return (
      <div className="containerWhiteZurich">
        <CButton
          buttonData={buttonData}
          buttonFloat={'right'}
          handleRefresh={handleRefresh}
          isLoading={isLoadingList}
        />
        <Table
          isLoading={isLoadingList}
          columns={generateColumnData(columnData)}
          data={remarksList || []}
          pagination={true}
          useFilterGlobal
        />
        <ModalCatatanPendingContainer
          schema={schema}
          modalPendingIsShow={modalPendingIsShow}
          setModalPendingIsShow={setModalPendingIsShow}
          selectedData={selectedData}
          setSelectedData={setSelectedData}
          handleRefresh={handleRefresh}
        />
      </div>
    );
  };

  return (
    <React.Fragment>
      <CLabelCard leftText="Catatan Pending" />
      <CCard
        cardClassName="cardStyleZurich"
        cardTitle={<span className="titleCardStyleZurich">Daftar Catatan</span>}
        cardContent={contentTab()}
      />
    </React.Fragment>
  );
}

import instance from '../interceptor';

const getSlide = async params => {
  const { id } = params.queryKey[1];
  const { data } = await instance.get(
    `/Elearning/ContentSlide?subModuleId=${id}`
  );
  return data;
};

const deleteSlide = async (id: string) => {
  return await instance.delete(`/Elearning/ContentSlide/${id}`);
};

const uploadSlide = async params => {
  const formData = new FormData();
  formData.append('file', params.file);
  const { data } = await instance.post(
    `/Elearning/ContentSlide/upload`,
    formData
  );
  return data?.data;
};

const mutateSlideStatus = async params => {
  const { id, status } = params;
  const { data } = await instance.put(
    `/Elearning/ContentSlide/status/${id}/${
      status !== undefined ? status : false
    }`
  );
  return data;
};

const mutateSlide = async params => {
  let {
    name,
    embedLink,
    fileType,
    sequence,
    isActive,
    codemiModuleId,
    channelId,
    stageId,
    moduleId,
    subModuleId,
    slideId,
    slideCoverProperties,
    slideFileProperties,
  } = params;

  const payload: any = {
    name,
    description: name,
    fileName: slideFileProperties?.name || '',
    fileType,
    file:
      fileType === 'youtube'
        ? embedLink
        : slideFileProperties
        ? slideFileProperties?.file_url
        : '',
    thumbnail: slideCoverProperties ? slideCoverProperties.file_url : '',
    sequence: sequence ? sequence : null,
    isActive: isActive !== undefined ? isActive : false,
    codemiModuleId,
    durations: slideFileProperties?.durations || 0,
    pages: slideFileProperties?.pages || null,
    channelId,
    stageId,
    moduleId,
    subModuleId,
  };

  if (slideId)
    return await instance.put(`/Elearning/ContentSlide/${slideId}`, payload);
  else return await instance.post(`/Elearning/ContentSlide`, payload);
};

export const contentManagementContentSlideAPI = {
  getSlide,
  deleteSlide,
  mutateSlide,
  mutateSlideStatus,
  uploadSlide,
};

import { Dashboard } from '@uppy/react';
import { Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import React, { useEffect } from 'react';
import Uppy from '@uppy/core';
import { localeUppy } from '../../Modules/Internationalization/translations/en';
import { useForm } from 'react-final-form';
interface IProps {
  addtionalLabel?: string;
  disabled?: boolean;
  documentValue?;
  fieldName?: string;
  formName?: string;
  handleOnChange?: (file: any, meta: any, form: any) => void;
  height?: number;
  idComponent?: string;
  input?: any;
  label?: string;
  labelClassName?: string;
  labelKey?: any;
  meta?: any;
  uppyConfig?: any;
  useFile?: boolean;
  additionalOnchange?: any;
  actionDeleteDispatch?: any;
  paramsDispatch?: any;
}

export const InputFileRegisForm = (props: IProps) => {
  const {
    addtionalLabel,
    documentValue,
    fieldName,
    formName,
    handleOnChange,
    height,
    idComponent,
    label,
    labelClassName,
    meta: { error },
    uppyConfig,
    labelKey,
    useFile,
    input,
    additionalOnchange,
  } = props;

  const {
    maxFile,
    minFile,
    allowedFile,
    maxSize,
    documentCode,
    documentType,
    name,
    contentId,
    folderId,
    repositoryId,
    sectionKey,
    fileName,
    documentGroup,
  } = uppyConfig;

  const formAPI = useForm();
  const formValue = formAPI.getState().values;
  function useUppy(factory: any) {
    return React.useMemo(factory, []);
  }
  const uppyInput: any = useUppy(() => {
    return new Uppy({
      restrictions: {
        minNumberOfFiles: minFile,
        maxNumberOfFiles: maxFile,
        allowedFileTypes: allowedFile,
        maxFileSize: maxSize,
      },
      id: input?.name,
      locale: localeUppy,
      autoProceed: true,
    });
  });

  useEffect(() => {
    const fileArr = [];
    const filesContainer = uppyInput.store.state.files;
    for (const key in filesContainer) {
      if (Object.prototype.hasOwnProperty.call(filesContainer, key)) {
        const element = filesContainer[key];
        const file = element.data;
        const handleFile = useFile === true ? file : undefined;
        const date = new Date();
        const dateNow = Date.now();
        const isoTimeStamp = date.toISOString();
        const additionalMeta = {
          field: fieldName,
          form: formName,
          documentCode,
          documentType,
          name,
          contentId,
          folderId,
          repositoryId,
          sectionKey,
          fileName,
          dateNow,
          isoTimeStamp,
          documentGroup,
        };
        if (handleOnChange) {
          handleOnChange(element, additionalMeta, formAPI);
        }
        const fileArr: any = [];
        fileArr.push({
          ...additionalOnchange,
          ...handleFile,
          file: file,
          name: element.name,
          label: labelKey,
          size: element.size,
          type: element.type,
          extension: element.extension,
        });
      }
    }
    input.onChange(fileArr.length > 1 ? fileArr : fileArr[0]);
    // eslint-disable-next-line
  }, [uppyInput.store.state.files]);

  uppyInput.on('file-added', (file: any) => {
    input.onChange(file);
  });

  uppyInput.on('file-removed', (file, reason) => {
    uppyInput.cancelAll();
  });

  const labelTitle = (
    <span>
      <b className={`capital ${labelClassName}`}>
        <FormattedMessage id={label} />
      </b>
      <span style={{ color: '#BFBFBF' }}>
        {addtionalLabel ? ` ${addtionalLabel}` : null}
      </span>
    </span>
  );

  uppyInput.on('file-removed', (file: any) => {
    if (input.value instanceof Array) {
      const tempData = [...input.value];
      const filteredData = tempData.filter(val => {
        return val.name !== file.name;
      });
      input.onChange(filteredData);
    } else {
      input.onChange(null);
    }
  });

  return (
    <Form.Item
      validateStatus={
        documentValue && formValue === null && error !== undefined
          ? 'error'
          : ''
      }
      help={
        documentValue && formValue === null && error !== undefined ? error : ''
      }
      label={labelTitle}
      colon={false}
    >
      <Dashboard
        id={idComponent}
        uppy={uppyInput}
        width={'100%'}
        height={height ? height : 150}
        hideUploadButton={true}
        proudlyDisplayPoweredByUppy={false}
      />
    </Form.Item>
  );
};

import React, { useState } from 'react';
import QAProcessComponent from '../Component/QAProcessComponent';
import CIcon from '../../../Assets/Components/CIcon';
import { IntlShape } from 'react-intl';
import { useQuery, useMutation } from '@tanstack/react-query';
import {
  downloadAAJICheckingAPI,
  getCandidateDetailAPI,
  getDetailSubmissionAPI,
  getLookupDirectLeaderAPI,
  getLookupRecruiterAPI,
  getQAListAPI,
} from '../../../Services/qaProcess.api';
import { Col, Row, Tag } from 'antd';
import moment from 'moment';
import CButton from '../../../Assets/Components/CButton';
import { getSchemaFormAPI } from '../../../Services/catatanPending.api';
import { autoUploadEditContentAPI } from '../../../Services/contentDocument.api';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  intl: IntlShape;
}
export default function QAProcessContainer(props: IProps) {
  const { intl } = props;

  const [dateFilter, setDateFilter] = useState('');
  const [statusJourney, setStatusJourney] = useState();
  const [level, setLevel] = useState();
  const [dataFilter, setDataFilter] = useState();
  const [modalCandidateIsShow, setModalCandidateIsShow] = useState(false);
  const [modalDetailIsShow, setModalDetailIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [isEditedCandidate, setIsEditedCandidate] = useState(false);
  const [totalOtherattachments, setTotalOtherattachments] = useState(1);
  const [agentCode, setAgentCode] = useState();
  const [agentLevel, setAgentLevel] = useState();
  const [candidateLevel, setCandidateLevel] = useState('');
  const [qaStatus, setQaStatus] = useState('');
  const [contentDocument, setContentDocument] = useState<any>({});
  const [documentMeta, setDocumentMeta] = useState<any>();
  const [newListBeneficiary, setNewListBeneficiary] = useState([]);

  const [newCity, setNewCity] = useState([]);
  const [newDistrict, setNewDistrict] = useState([]);
  const [newVillage, setNewVillage] = useState([]);
  const [newCityDomicile, setNewCityDomicile] = useState([]);
  const [newDistrictDomicile, setNewDistrictDomicile] = useState([]);
  const [newVillageDomicile, setNewVillageDomicile] = useState([]);

  const {
    data: qaProcessList,
    isFetching: isFetchingQA,
    refetch: refreshList,
  } = useQuery(['getQAProcessList'], getQAListAPI, {
    refetchOnWindowFocus: false,
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const { data: schemaData, refetch: refreshSchema } = useQuery(
    ['getSchemaForm'],
    getSchemaFormAPI
  );

  const { data: candidateDetail } = useQuery(
    ['getCandidateDetail', selectedData?.code],
    getCandidateDetailAPI,
    {
      enabled: selectedData?.code !== undefined,
    }
  );

  const candidateApprovers = candidateDetail?.[0]?.candidateApprovers?.find(
    (val: any) => val.approverRole === 'RECRUITER'
  );

  const { data: detailCandidate, isFetching: isFetchingSubmission } = useQuery(
    [
      'getSubmissionCandidate',
      {
        caseId: selectedData?.caseId,
        taskId: selectedData?.taskId,
        detailCandidate: selectedData,
        setTotalOtherattachments,
      },
    ],
    getDetailSubmissionAPI,
    {
      enabled: selectedData !== undefined,
      refetchOnWindowFocus: false,
      onSuccess: (data: any) => {
        setTotalOtherattachments(data?.listAttachment?.length);
        setNewCity(data.listCity);
        setNewDistrict(data.listdistrict);
        setNewVillage(data.listsubdistrict);
        setNewCityDomicile(data.listCityDomicile);
        setNewDistrictDomicile(data.listdistrictDomicile);
        setNewVillageDomicile(data.listsubdistrictdomicile);
      },
    }
  );

  const { data: recruiter } = useQuery(
    ['getLookupRecruiter'],
    getLookupRecruiterAPI
  );

  const { data: directLeaderList } = useQuery(
    ['getLookupDirectLeader', { agentCode, agentLevel, candidateLevel }],
    getLookupDirectLeaderAPI,
    {
      enabled:
        modalCandidateIsShow &&
        agentCode !== undefined &&
        agentLevel !== undefined,
    }
  );

  const { mutate: downloadAAJIChecking } = useMutation(
    downloadAAJICheckingAPI,
    {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {
        refreshList();
      },
    }
  );

  const {
    data: documentsContent,
    mutate: mutateAutoUploadEditContent,
    isLoading: isUploading,
  } = useMutation(autoUploadEditContentAPI, {
    onError: (error: any) => {
      if (error?.response?.status) {
        ErrorHandler(error);
      }
    },
    onSuccess: (data: any) => {},
  });

  const handleUploadContent = (file: any, additionalMeta: any, form: any) => {
    if (!selectedData) {
      setContentDocument(documentsContent?.documents);
      setContentDocument(contentDocument);
    }
    mutateAutoUploadEditContent({
      form,
      fileData: file,
      addtionalMeta: additionalMeta,
      detailCandidate: selectedData,
      schemaCandidate: schemaData,
      documentCandidate: contentDocument
        ? contentDocument
        : detailCandidate?.documentContentData,
    });
    setDocumentMeta(additionalMeta);
  };
  const handleDownloadAAJI = () => {
    downloadAAJIChecking({});
  };

  const handleRefresh = () => {
    setDateFilter('');
    setDataFilter(qaProcessList);
    refreshList();
    refreshSchema();
    setStatusJourney(undefined);
    setLevel(undefined);
  };

  const handleFilter = () => {
    if (!dateFilter && !level && !statusJourney) {
      setDataFilter(qaProcessList);
      refreshList();
    } else {
      const users = qaProcessList?.filter((x: any) => {
        const startDate = dateFilter
          ? moment(dateFilter[0]).format(moment.HTML5_FMT.DATE)
          : dateFilter;
        const finishDate = dateFilter
          ? moment(dateFilter[1]).format(moment.HTML5_FMT.DATE)
          : dateFilter;
        const convertDate = moment(x.dosdStartDate).format(
          moment.HTML5_FMT.DATE
        );
        if (!dateFilter && !level && !statusJourney) {
          return x.candidateLevel === level;
        } else if (!dateFilter && !level && statusJourney) {
          return x.candidateStatus === statusJourney;
        } else if (dateFilter && !level && !statusJourney) {
          return convertDate >= startDate && convertDate <= finishDate;
        } else if (!dateFilter && level && statusJourney) {
          return (
            x.candidateLevel === level && x.candidateStatus === statusJourney
          );
        } else if (dateFilter && level && !statusJourney) {
          return (
            convertDate >= startDate &&
            convertDate <= finishDate &&
            x.candidateLevel === level
          );
        } else if (dateFilter && !level && statusJourney) {
          return (
            convertDate >= startDate &&
            convertDate <= finishDate &&
            x.candidateStatus === statusJourney
          );
        } else if (dateFilter && level && statusJourney) {
          return (
            convertDate >= startDate &&
            convertDate <= finishDate &&
            x.candidateStatus === statusJourney &&
            x.candidateLevel === level
          );
        } else {
          return null;
        }
      });
      setDataFilter(users);
    }
  };

  const tagStat = (tagStatus: string, color: string, fontColor: string) => {
    return (
      <Tag color={color}>
        <div style={{ color: fontColor, width: 100, textAlign: 'center' }}>
          {tagStatus}
        </div>
      </Tag>
    );
  };

  const renderStatus = (row: any) => {
    const status = row.row.original.candidateStatus;
    if (status === 'AAJI Screening') {
      return tagStat('AAJI Screening', '#4066B3', '#FFFFFF');
    } else if (status === 'QA Not Clean') {
      return tagStat('QA Not Clean', '#FB6915', '#FFFFFF');
    } else if (status === 'QA Process') {
      return tagStat('QA Process', '#A9A9A9', '#000000');
    } else if (status === 'Pending Update') {
      return tagStat('Pending Update', '#F69C00', '#FFFFFF');
    } else {
      return '-';
    }
  };

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const handleDetail = () => {
      setSelectedData(rows);
      setModalDetailIsShow(true);
      setContentDocument(detailCandidate?.documentContentData);
      if (rows.candidateStatus !== 'AAJI Screening') {
        setQaStatus('Not Clean');
      }
      setQaStatus('Not Clean');
    };
    const dataButton = [
      {
        type: 'text',
        id: 'btnDetailQa',
        className: 'btnDetailQa',
        content: 'Detail',
        onClick: handleDetail,
      },
    ];

    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      id: 'expander',
      Cell: ({ row }) => (
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? (
            <CIcon type="CaretDownOutlined" />
          ) : (
            <CIcon type="CaretRightOutlined" />
          )}
        </span>
      ),
    },
    {
      Header: 'Nomor Aplikasi',
      accessor: 'contractNumber',
    },
    {
      Header: 'Nama Kandidat',
      accessor: 'name',
    },
    {
      Header: 'Level',
      accessor: 'candidateLevel',
      disableSort: true,
    },
    {
      Header: 'Tanggal Pengajuan',
      accessor: 'dosdStartDate',
      disableSort: true,
      Cell: (row: any) =>
        row.row.original.dosdStartDate
          ? moment(row.row.original.dosdStartDate).format('DD-MMM-YYYY')
          : '-',
    },
    {
      Header: 'Status',
      accessor: 'candidateStatus',
      disableSort: true,
      Cell: (row: any) => renderStatus(row),
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const renderCol = (label: string, content: any) => {
    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <Col span={4}>
            <b>{label}</b>
          </Col>
          <Col span={8}>{': ' + content}</Col>
        </Row>
      </>
    );
  };

  const renderRowSubComponent = React.useCallback(({ row }) => {
    const { refAgencyName, salesOfficeCode, refAgencyCode, salesOfficeDesc } =
      row.original;
    return (
      <div>
        {renderCol('SOB', 'AG')}
        {renderCol(
          'Kode Kantor Sales',
          salesOfficeCode === null || salesOfficeCode === ''
            ? '-'
            : salesOfficeCode
        )}
        {renderCol(
          'Kantor Sales',
          salesOfficeDesc === null || salesOfficeDesc === ''
            ? '-'
            : salesOfficeDesc
        )}
        {renderCol(
          'Kode Agensi',
          refAgencyCode === null || refAgencyCode === '' ? '-' : refAgencyCode
        )}
        {renderCol(
          'Nama Agensi',
          refAgencyName === null || refAgencyName === '' ? '-' : refAgencyName
        )}
      </div>
    );
  }, []);

  return (
    <QAProcessComponent
      columnData={columnData}
      intl={intl}
      isFetchingQA={isFetchingQA}
      renderRowSubComponent={renderRowSubComponent}
      dateFilter={dateFilter}
      setDateFilter={setDateFilter}
      statusJourney={statusJourney}
      setStatusJourney={setStatusJourney}
      level={level}
      setLevel={setLevel}
      handleRefresh={handleRefresh}
      refreshList={refreshList}
      dataFilter={dataFilter ? dataFilter : qaProcessList || []}
      handleFilter={handleFilter}
      handleDownloadAAJI={handleDownloadAAJI}
      modalCandidateIsShow={modalCandidateIsShow}
      setModalCandidateIsShow={setModalCandidateIsShow}
      selectedData={selectedData}
      setSelectedData={setSelectedData}
      schemaData={schemaData}
      isEditedCandidate={isEditedCandidate}
      setIsEditedCandidate={setIsEditedCandidate}
      totalOtherattachments={totalOtherattachments}
      setTotalOtherattachments={setTotalOtherattachments}
      modalDetailIsShow={modalDetailIsShow}
      setModalDetailIsShow={setModalDetailIsShow}
      detailCandidate={detailCandidate}
      recruiter={recruiter || []}
      directLeaderList={directLeaderList || detailCandidate?.listDL || []}
      setAgentCode={setAgentCode}
      setAgentLevel={setAgentLevel}
      setCandidateLevel={setCandidateLevel}
      isFetchingSubmission={isFetchingSubmission}
      qaStatus={qaStatus}
      setQaStatus={setQaStatus}
      documentsContent={documentsContent}
      handleUploadContent={handleUploadContent}
      setContentDocument={setContentDocument}
      contentDocument={contentDocument}
      documentMeta={documentMeta}
      newListBeneficiary={newListBeneficiary}
      setNewListBeneficiary={setNewListBeneficiary}
      isUploading={isUploading}
      newCityDomicile={newCityDomicile}
      setNewCityDomicile={setNewCityDomicile}
      newDistrictDomicile={newDistrictDomicile}
      setNewDistrictDomicile={setNewDistrictDomicile}
      newVillageDomicile={newVillageDomicile}
      setNewVillageDomicile={setNewVillageDomicile}
      newCity={newCity}
      setNewCity={setNewCity}
      newDistrict={newDistrict}
      setNewDistrict={setNewDistrict}
      newVillage={newVillage}
      setNewVillage={setNewVillage}
      candidateApprovers={candidateApprovers}
    />
  );
}

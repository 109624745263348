import React from 'react';
import { Col, Row } from 'antd';
import { filterStatus } from '../../../../../App/Enums';
import CButton from '../../../../../Assets/Components/CButton';
import Table, {
  generateColumnData,
} from '../../../../../Assets/Components/CTable';
import Select from '../../../../../Assets/Components/Select';

interface IProps {
  columnData: any;
  disabled: boolean;
  dataQuestion: any;
  handleAdd: () => void;
  handleRefresh: () => void;
  handleApplyfilter: () => void;
  isLoading: any;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  selectedStatus?: any;
}

export default function QuestionTabComponent(props: IProps) {
  const {
    columnData,
    dataQuestion,
    disabled,
    selectedStatus,
    handleAdd,
    handleRefresh,
    isLoading,
    setSelectedStatus,
    handleApplyfilter,
  } = props;

  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchLicenseList',
      onClick: handleApplyfilter,
      disabled,
    },
  ];
  const buttonData = [
    {
      type: 'primary',
      onClick: handleAdd,
      content: 'Tambah Baru',
      id: 'btnModalAddN',
      className: 'spacingBtnSecondRow2 btnRadius10',
      disabled,
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshChannelList',
      className: 'btnRadius10',
      onClick: handleRefresh,
      disabled,
    },
  ];
  return (
    <div style={{ padding: '16px' }}>
      <Row gutter={20}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus || []}
            idComponent="filterChannel"
            meta={{ touched: false, error: false }}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            defaultItemName="Semua"
          />
        </Col>
        <Col span={8} className={'btnFilterAplicationList'}>
          <CButton buttonData={buttonDataSearch} buttonFloat={'left'} />
        </Col>
        <Col span={12} className={'btnFilterAplicationList'}>
          <CButton buttonData={buttonData} buttonFloat={'right'} />
        </Col>
      </Row>
      <Table
        columns={generateColumnData(columnData)}
        data={dataQuestion}
        pagination={true}
        useFilterGlobal={true}
        isLoading={isLoading}
      />
    </div>
  );
}

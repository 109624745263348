import React, { useState } from 'react';
import ModalInvitationComponent from '../Component/ModalInvitationComponent';
import { useQuery, useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import {
  getInvitationByIdAPI,
  mutateInvitationAPI,
} from '../../../Services/invitation.api';
import ErrorHandler from '../../../App/ErrorHandler';

interface IProps {
  handleRefresh: () => void;
  selectedData: any;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedData: React.Dispatch<any>;
}

export default function ModalInvitationContainer(props: IProps) {
  const {
    selectedData,
    handleRefresh,
    showModal,
    setShowModal,
    setSelectedData,
  } = props;

  const [editMode, setEditMode] = useState(false);
  const modalAction = selectedData ? 'update' : 'register';

  const { data: dataDetail, isFetching } = useQuery(
    ['getInvitationByIdAPI', selectedData?.id],
    getInvitationByIdAPI,
    {
      enabled: selectedData?.id !== undefined,
      refetchOnWindowFocus: false,
    }
  );

  const mutate = useMutation(mutateInvitationAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage
            id={
              selectedData
                ? 'updateInvitationSuccess'
                : 'createInvitationSuccess'
            }
          />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      handleCancel();
      handleRefresh();
    },
  });

  const initialValues: any = {};
  if (dataDetail) {
    const { name, templateTypeDetails } = dataDetail;
    for (const iterator of templateTypeDetails) {
      const { description, generateQrCode, subject, type } = iterator;
      switch (type) {
        case 'SMS':
          initialValues.templateSms = description;
          initialValues.generateBarcodeSms = generateQrCode;
          break;
        case 'Email':
          initialValues.emailSubject = subject;
          initialValues.templateEmail = description;
          initialValues.generateBarcode = generateQrCode;
          break;
        case 'SocialMedia':
          initialValues.templateSocialMedia = description;
          break;
        default:
          break;
      }
    }
    initialValues.name = name;
  }

  const handleCancel = () => {
    setEditMode(false);
    setSelectedData(undefined);
    setShowModal(false);
  };

  const handleSubmit = (values: any) => {
    if (selectedData && !editMode) setEditMode(true);
    else {
      mutate.mutate({
        ...values,
        id: selectedData?.id,
      });
    }
  };

  return (
    <ModalInvitationComponent
      editMode={editMode}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
      isLoading={isFetching || mutate.isLoading}
      modalAction={modalAction}
      showModal={showModal}
    />
  );
}

import Store from '../Store';

import axios from 'axios';
import { toast } from 'react-toastify';
import history from '../App/History';

let isRefreshing = false;
let failedQueue: any = [];
const state: any = Store.getState();

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'api-version': '1.0',
  },
});

// INTERCEPTOR REQUEST
instance.interceptors.request.use(
  async (config: any) => {
    const unAuthenticatedUrls = ['login'];
    const state: any = Store.getState();

    if (unAuthenticatedUrls.indexOf(config.url) === -1) {
      config.headers['Authorization'] = 'Bearer ' + state.token;
    } else {
      config.headers['Authorization'] = undefined;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    const originalRequest = error.config;

    if (error.response) {
      if (error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
          return new Promise(function (resolve, reject) {
            failedQueue.push({ resolve, reject });
          })
            .then(token => {
              originalRequest.headers['Authorization'] = 'Bearer ' + token;
              return instance(originalRequest);
            })
            .catch(err => {
              return err;
            });
        }

        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise(function (resolve, reject) {
          instance
            .post(
              `${process.env.REACT_APP_API_URL}/Token/refresh/${state.tokenRefresh}`,
              `"${state.token}"`,
              { headers: { 'Content-Type': 'application/json' } }
            )
            .then(({ data }) => {
              const stateToken: any = Store.getState();
              stateToken.setToken({
                token: data.token,
                tokenRefresh: state.tokenRefresh,
              });
              instance.defaults.headers.common['Authorization'] =
                'Bearer ' + data.token;
              originalRequest.headers['Authorization'] = 'Bearer ' + data.token;
              processQueue(null, data.token);
              resolve(instance(originalRequest));
            })
            .catch(err => {
              processQueue(err, null);
              reject(err);
              toast.error('Session expired. Please login again.');
              state.logOut();
              history.push(`${process.env.REACT_APP_PUBLIC_URL}/login`);
            })
            .then(() => {
              isRefreshing = false;
            });
        });
      } else if (
        error.response.status === 500 &&
        error.response.data.MessageKey === 'refresh.token.not.match'
      ) {
        history.push(`/login`);
        state.setToken({ token: '', tokenRefresh: '' });
        toast.error('Session expired. Please login again.');
        state.logOut();
      }
    } else {
      toast.error(error.message);
    }

    return Promise.reject(error);
  }
);

export default instance;

import instance from '../Services/interceptor';

export const getPendingRemarksAPI = async () => {
  const { data } = await instance.get(`/Pending/remark`);
  return data;
};

export const getSchemaFormAPI = async () => {
  const { data } = await instance.get(`/form?Code=FRMCR0001`);
  return data[0];
};

export const mutateCatatanPendingAPI = async (params: any) => {
  const { title, category, note, pathForm, id } = params;
  const payload: any = { title, category, note, pathForm };
  if (id) {
    return await instance.put(`/Pending/remark/${id}`, payload);
  } else {
    return await instance.post(`/Pending/remark`, payload);
  }
};

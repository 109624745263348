const StageValidation = ({ description, name }) => {
  const errors: any = {};
  if (!description) {
    errors.description = 'Description Wajib Diisi!';
  } else if (description && !/^[^\s].*/.test(description)) {
    errors.description = 'Use letters/numbers at the beginning of words!';
  }

  if (!name) {
    errors.name = 'Stage Name Wajib Diisi!';
  } else if (name && !/^[^\s].*/.test(name)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  }

  return errors;
};
export default StageValidation;

import React from 'react';

interface IProps {
  breadcrumbItem;
  breadcrumbTitle;
  breadcrumbItemClick;
}

export default function CBreadcrumb(props: IProps) {
  const { breadcrumbItem, breadcrumbTitle, breadcrumbItemClick } = props;
  if (breadcrumbItem.length > 0) {
    return (
      <div className="container_breadcrumb">
        <div className="breadcrumb_first">
          {breadcrumbTitle[0]}
          {`: `}
        </div>
        {breadcrumbItem.map((item, index) => {
          return (
            <div
              key={`${item}_${index}`}
              className="container_breadcrumb_button"
            >
              <button
                onClick={() => breadcrumbItemClick(item)}
                className="button_breadcrumb"
              >
                {item}
              </button>{' '}
              {'>'}
            </div>
          );
        })}
        <div className="breadcrumb_current">
          {breadcrumbTitle.length > 1
            ? breadcrumbTitle[breadcrumbTitle.length - 1]
            : null}
        </div>
      </div>
    );
  }
  return null;
}

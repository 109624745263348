const CatatanPendingValidation = ({ title, note }) => {
  const errors: any = {};
  if (!title) {
    errors.title = 'Judul Wajib Diisi';
  } else if (title && !/^[^\s].*/.test(title)) {
    errors.values.title = 'Use letters/numbers at the beginning of words!';
  }
  if (!note) {
    errors.note = 'Catatan Wajib Diisi';
  } else if (note && !/^[^\s].*/.test(note)) {
    errors.values.note = 'Use letters/numbers at the beginning of words!';
  }
  return errors;
};
export default CatatanPendingValidation;

import * as ReduxStore from '@uppy/store-redux';
import * as _ from 'lodash';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import Language from '../Modules/Internationalization/languageProviderReducer';
import { composeWithDevTools } from 'redux-devtools-extension';
import { connectRouter } from 'connected-react-router';
import { reducer as formReducer } from 'redux-form';
import history from '../App/History';
import storage from 'redux-persist/lib/storage';
import { reducer as toastrReducer } from 'react-redux-toastr';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['AuthState'],
};

const combinedReducer = combineReducers({
  Language,
  router: connectRouter(history),
  toastr: toastrReducer,
  uppy: ReduxStore.reducer,
  form: formReducer.plugin({}),
});
const persistedReducer = persistReducer(persistConfig, combinedReducer);

const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  _.has(window, '__REDUX_DEVTOOLS_EXTENSION_COMPOSE__')
    ? composeWithDevTools({ trace: true, traceLimit: 1000 })
    : compose;

export const store: any = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware())
);
export const persistor = persistStore(store);

import React from 'react';
import ModalValidationComponent from '../../Component/Modal/ModalValidationComponent';

interface IProps {
  modalValidationIsShow: any;
  setModalValidationIsShow: any;
  resultValidation: any;
}
export default function ModalValidationContainer(props: IProps) {
  const { modalValidationIsShow, setModalValidationIsShow, resultValidation } =
    props;

  const handleCancel = () => {
    setModalValidationIsShow(false);
    if (resultValidation) {
      if (resultValidation?.[0]?.name === 'Email') {
        const email = document.getElementById('InputComponentemail');
        if (email) {
          email.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      } else if (resultValidation?.[0]?.name === 'IdNumber') {
        const nik = document.getElementById('InputComponentnik');
        if (nik) {
          nik.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      } else if (resultValidation?.[0]?.name === 'PhoneNumber') {
        const mobilephone = document.getElementById(
          'InputComponentmobilephone'
        );
        if (mobilephone) {
          mobilephone.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          });
        }
      }
    }
  };
  return (
    <ModalValidationComponent
      modalValidationIsShow={modalValidationIsShow}
      handleCancel={handleCancel}
      resultValidation={resultValidation}
    />
  );
}

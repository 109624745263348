import React, { useState } from 'react';
import {
  getDisclaimerMappingAPI,
  getDisclaimerModuleAPI,
} from '../../../Services/Disclaimer/disclaimerMapping.api';
import DisclaimerMappingComponent from '../Component/DisclaimerMappingComponent';
import { useQuery } from '@tanstack/react-query';
import CButton from '../../../Assets/Components/CButton';
import { getDisclaimerContentAPI } from '../../../Services/Disclaimer/disclaimerContent.api';

export default function DisclaimerMappingContainer() {
  const [modalIsShow, setModalIsShow] = useState(false);
  const [selectedData, setSelectedData] = useState();

  const {
    data: disclaimerMappingList,
    isLoading,
    isRefetching,
    refetch,
  } = useQuery(['getDisclaimerMapping'], getDisclaimerMappingAPI, {
    refetchOnWindowFocus: false,
  });

  const {
    data: disclaimerContentList,
    isLoading: isLoadingDisclaimerContent,
    refetch: refetchDisclaimerContent,
  } = useQuery(['getDisclaimerContent'], getDisclaimerContentAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const {
    data: disclaimerModuleList,
    isLoading: isLoadingDisclaimerModule,
    refetch: refetchDisclaimerModule,
  } = useQuery(['getDisclaimerModule'], getDisclaimerModuleAPI, {
    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  const renderAction = (row: any) => {
    const rows = row.row.original;
    const dataButton = [
      {
        type: 'primary',
        id: `btnEditDisclaimerMapping`,
        icon: 'EditOutlined',
        onClick: () => {
          setSelectedData(rows);
          setModalIsShow(true);
        },
        className: 'buttonTable',
      },
    ];
    return <CButton buttonData={dataButton} />;
  };

  const columnData = [
    {
      Header: 'Disclaimer code',
      accessor: 'disclaimerCode',
    },
    {
      Header: 'Disclaimer name',
      accessor: 'disclaimerName',
    },
    {
      Header: 'Module code',
      accessor: 'disclaimerModuleCode',
    },
    {
      Header: 'Module name',
      accessor: 'disclaimerModuleName',
    },
    {
      Header: 'Action',
      Cell: (row: any) => renderAction(row),
    },
  ];

  const handleRefresh = () => {
    setSelectedData(undefined);
    refetch();
    refetchDisclaimerContent();
    refetchDisclaimerModule();
  };
  return (
    <DisclaimerMappingComponent
      columnData={columnData}
      disclaimerContentList={disclaimerContentList || []}
      disclaimerMappingList={disclaimerMappingList || []}
      disclaimerModuleList={disclaimerModuleList || []}
      handleRefresh={handleRefresh}
      modalIsShow={modalIsShow}
      selectedData={selectedData}
      setModalIsShow={setModalIsShow}
      setSelectedData={setSelectedData}
      isLoading={
        isLoading ||
        isRefetching ||
        isLoadingDisclaimerContent ||
        isLoadingDisclaimerModule
      }
    />
  );
}

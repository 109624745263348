import { mutateConfigurationAPI } from '../../../Services/config.api';
import ModalConfigurationComponent from '../Component/ModalConfigurationComponent';
import React, { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import ErrorHandler from '../../../App/ErrorHandler';
interface IProps {
  modalConfigIsShow: boolean;
  setModalConfigIsShow: any;
  intl: any;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
  handleRefresh: any;
}

export default function ModalConfigurationContainer(props: IProps) {
  const {
    modalConfigIsShow,
    setModalConfigIsShow,
    intl,
    selectedData,
    setSelectedData,
    handleRefresh,
  } = props;

  const [editMode, setEditMode] = useState(false);

  const handleCancel = () => {
    setSelectedData(undefined);
    setModalConfigIsShow(false);
  };

  const mutateConfiguration = useMutation(mutateConfigurationAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage id="updateConfigurationSuccess" />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setSelectedData(undefined);
      setModalConfigIsShow(false);
      handleRefresh();
      setEditMode(false);
    },
  });

  const handleSubmit = (vals: any) => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutateConfiguration.mutate({
        ...vals,
        id: selectedData?.id,
      });
    }
  };

  return (
    <ModalConfigurationComponent
      handleCancel={handleCancel}
      modalConfigIsShow={modalConfigIsShow}
      intl={intl}
      initialValues={selectedData}
      handleSubmit={handleSubmit}
      editMode={editMode}
      disabled={editMode === false && selectedData ? true : false}
    />
  );
}

import { IResultQuestionBankDAO } from '../Modules/ElearningQuestionBank/DAO/questionBank.dao';
import instance from './interceptor';

export const getQuestionBankAPI = async (): Promise<
  IResultQuestionBankDAO[]
> => {
  const { data } = await instance.get(`/Elearning/QuestionBank`);
  return data;
};

export const deleteQuestionBankAPI = async (id: string) => {
  return await instance.delete(`/Elearning/QuestionBank/${id}`);
};

export const mutateQuestionBankAPI = async params => {
  const { payloadSubmit, payloadUpdate, id } = params;

  if (id) {
    return await instance.put(`/Elearning/QuestionBank/${id}`, payloadUpdate);
  } else {
    return await instance.post(`/Elearning/QuestionBank`, payloadSubmit);
  }
};

import { IResultFeedbackBankListDAO } from '../../Modules/ElearningFeedbackBank/DAO/feedbackBank.dao';
import { UseQueryOptions } from '@tanstack/react-query';
import instance from '../interceptor';

export const getFeedbackBankAPI = async (
  params: UseQueryOptions
): Promise<IResultFeedbackBankListDAO[]> => {
  const feedbackType = params?.queryKey?.[1];
  const { data } = await instance.get(
    `/Elearning/FeedbackBank/${feedbackType}`
  );
  return data;
};

export const deleteFeedbackBankAPI = async (id: string) => {
  return await instance.delete(`/Elearning/FeedbackBank/${id}`);
};

export const mutateFeedbackBankAPI = async (params: any) => {
  const {
    channel,
    moduleId,
    category,
    feedbackType,
    multipleChoiceQuestions,
    freeTextQuestions,
    id,
  } = params;

  const multipleChoiceObj: any[] = [];
  multipleChoiceQuestions.forEach(item => {
    multipleChoiceObj.push({ question: item });
  });

  const freeTextObj: any[] = [];
  freeTextQuestions.forEach(item => {
    freeTextObj.push({ question: item });
  });

  const answers: any[] = [];
  for (let index = 0; index < 6; index++) {
    if (
      params[`isActive${index}`] &&
      params[`answer${index}`] &&
      params[`point${index}`]
    ) {
      answers.push({
        answer: params[`answer${index}`],
        point: params[`point${index}`],
        isActive: params[`isActive${index}`],
      });
    }
  }

  const payload: any = {
    channelId: channel,
    moduleId: moduleId || 0,
    category,
    type: feedbackType,
    multipleChoiceQuestions: multipleChoiceObj,
    essayQuestions: freeTextObj,
    answers,
  };

  if (id) {
    return await instance.put(`/Elearning/FeedbackBank/${id}`, payload);
  } else {
    return await instance.post(`/Elearning/FeedbackBank`, payload);
  }
};

import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@tanstack/react-query';
import StageModalComponent from '../Component/StageModalComponent';
import { FormApi } from 'final-form';
import { contentManagementStageAPI } from '../../../../Services/ElearningContentManagement/stage.api';
import { FormattedMessage } from 'react-intl';
import ErrorHandler from '../../../../App/ErrorHandler';

interface IProps {
  channel: any;
  stage: any;
  modalAction: string;
  refetchStage: () => void;
  modalIsShow: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<undefined>>;
}

export default function StageModalContainer({
  channel,
  stage,
  setModalIsShow,
  refetchStage,
  modalIsShow,
  selectedData,
  setSelectedData,
}: IProps) {
  const [editMode, setEditMode] = useState(false);
  const initialValues: any = selectedData;

  const mutate = useMutation(contentManagementStageAPI.mutateStage, {
    onSuccess: () => {
      const toastMessage = (
        <span className="capitalFirst">
          <FormattedMessage
            id={
              selectedData
                ? 'updateStageSuccess'
                : 'createStageElearningSuccess'
            }
          />
        </span>
      );
      toast.success(toastMessage, {
        className: 'toastSuccessBackground',
      });
      setModalIsShow(false);
      refetchStage();
      setEditMode(false);
      setSelectedData(undefined);
    },
    onError: (error: any) => {
      ErrorHandler(error);
    },
  });

  const handleCancel = () => {
    setModalIsShow(false);
    setSelectedData(undefined);
  };

  const handleSubmit = vals => {
    if (selectedData && !editMode) {
      setEditMode(true);
    } else {
      mutate.mutate({ ...vals, channelId: channel.id });
    }
  };

  const predecessorList: any = [];
  stage.forEach(item => {
    const { id, codemiUserLevelId, name, sequence } = item;
    if (initialValues && id === initialValues?.id) return;
    predecessorList.push({
      id: codemiUserLevelId,
      name,
      sequence: sequence + 1,
    });
  });

  const handleAddtionalSelectSequence = (
    e: any,
    form: FormApi<any, Partial<any>>
  ) => {
    let predecessorValue = null;
    for (const predecessor of predecessorList) {
      const { id, sequence } = predecessor;
      if (sequence === e) {
        predecessorValue = id;
      }
    }
    form.change('predecessor', predecessorValue);
  };

  const handleAddtionalSelectPredecessor = (
    e: any,
    form: FormApi<any, Partial<any>>
  ) => {
    let sequenceValue = undefined;
    for (const predecessor of predecessorList) {
      const { id, sequence } = predecessor;
      if (id === e) {
        sequenceValue = sequence;
      }
    }
    form.change('sequence', sequenceValue);
  };

  return (
    <StageModalComponent
      isShow={modalIsShow}
      editMode={editMode}
      modalAction={selectedData ? 'update' : 'register'}
      handleCancel={handleCancel}
      handleSubmit={handleSubmit}
      handleAddtionalOnChangeSequence={handleAddtionalSelectSequence}
      handleAddtionalSelectPredecessor={handleAddtionalSelectPredecessor}
      predecessorList={predecessorList}
      initialValues={initialValues}
      isLoading={mutate.isLoading}
    />
  );
}

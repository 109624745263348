import { Col, Row } from 'antd';

import CButton from '../../../../Assets/Components/CButton';
import ChannelModalContainer from '../Container/ChannelModalContainer';
import React from 'react';
import Table from '../../../../Assets/Components/CTable';
import { filterStatus } from '../../../../App/Enums';
import Select from '../../../../Assets/Components/Select';
import { IResultChannelNonSelfTrainingDAO } from '../DAO/channelNonSelfTraining.dao';

interface IProps {
  columnData: any;
  selectedStatus?: any;
  dataChannel: IResultChannelNonSelfTrainingDAO[];
  renderRowSubComponent: any;
  handleRefresh: () => void;
  isLoading: boolean;
  setModalIsShow: React.Dispatch<React.SetStateAction<boolean>>;
  modalIsShow: boolean;
  setSelectedStatus: React.Dispatch<React.SetStateAction<any>>;
  handleApplyfilter: () => void;
  selectedData: any;
  setSelectedData: React.Dispatch<React.SetStateAction<any>>;
}

export default function ChannelComponent(props: IProps) {
  const {
    columnData,
    selectedStatus,
    handleRefresh,
    isLoading,
    renderRowSubComponent,
    setModalIsShow,
    modalIsShow,
    setSelectedStatus,
    handleApplyfilter,
    selectedData,
    setSelectedData,
  } = props;

  const buttonDataSearch = [
    {
      type: 'primary',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
      icon: 'SearchOutlined',
      content: 'Cari',
      id: 'btnSearchCategoryBank',
      onClick: handleApplyfilter,
    },
  ];

  const buttonData = [
    {
      type: 'primary',
      onClick: () => setModalIsShow(true),
      content: 'Tambah Baru',
      id: 'btnModalAddNewChannel',
      className: 'spacingBtnSecondRow2 btnRadius10 btnFeedbackBank',
    },
    {
      type: 'ghost',
      content: 'Refresh',
      icon: 'ReloadOutlined',
      id: 'btnRefreshChannelList',
      className: 'btnRadius10 btnFeedbackBank',
      onClick: handleRefresh,
    },
  ];
  return (
    <div className="containerWhiteZurich">
      <Row gutter={5}>
        <Col span={4}>
          <label className="labelBtnFilter">Status</label>
          <Select
            dataOption={filterStatus || []}
            idComponent="filterChannel"
            meta={{ touched: false, error: false }}
            input={{
              onBlur: null,
              onChange: setSelectedStatus,
              value: selectedStatus ? selectedStatus : 'Semua',
            }}
            defaultItemName="Semua"
            className="btnFeedbackBank"
          />
        </Col>
        <Col span={4} className={'btnFilterAplicationList'}>
          <CButton
            isLoading={isLoading}
            buttonData={buttonDataSearch}
            buttonFloat={'left'}
          />
        </Col>
        <Col span={16} className={'btnFilterAplicationList'}>
          <CButton buttonData={buttonData} buttonFloat={'right'} />
        </Col>
      </Row>
      <Table
        columns={columnData}
        pagination={true}
        data={props.dataChannel}
        useFilterGlobal
        renderRowSubComponent={renderRowSubComponent}
        isLoading={isLoading}
      />
      <ChannelModalContainer
        showModal={modalIsShow}
        setShowModal={setModalIsShow}
        selectedData={selectedData}
        setSelectedData={setSelectedData}
        handleRefresh={handleRefresh}
      />
    </div>
  );
}

import {
  IResultHistoryPrivyDAO,
  IResultPrivyFileDAO,
} from '../Modules/PrivyIDStatement/DAO/privy.dao';
import instance from '../Services/interceptor';

export const getHistoryPrivyAPI = async (): Promise<
  IResultHistoryPrivyDAO[]
> => {
  const { data } = await instance.get(
    `/Candidate/file/upload/history?uploadHistoryType=PrivyAgreement`
  );
  return data;
};

export const getFileListAPI = async (): Promise<IResultPrivyFileDAO[]> => {
  const { data } = await instance.get(
    `/Content/InquiryContent?repositorySymbolicName=privy`
  );
  return data;
};

export const downloadFileAPI = async (params: any) => {
  const { id } = params;
  const { data } = await instance.get(`/Content/GetContent?contentId=${id}`);
  const base64 = `data:${data.properties.contenttype};base64,${data.content}`;
  fetch(`${base64}`)
    .then(res => res.blob())
    .then(conversionResult => {
      const url = window.URL.createObjectURL(conversionResult);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', data.originalFileName);
      link.click();
    });
};

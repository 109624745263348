import React, { useState } from 'react';
import TrackerComponent from '../Component/TrackerComponent';
import { useMutation } from '@tanstack/react-query';
import {
  downloadTrackerAsFile,
  getTrackerListAPI,
} from '../../../Services/tracker.api';
import ErrorHandler from '../../../App/ErrorHandler';

export default function TrackerContainer() {
  const [selectedType, setSelectedType] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [selectedFormat, setSelectedFormat] = useState();
  const [emptyData, setEmptyData] = useState([]);

  const {
    mutate: mutateTrackerList,
    isLoading: isLoadingTracker,
    data: trackerList,
  } = useMutation(getTrackerListAPI, {
    onError: (error: any) => {
      ErrorHandler(error);
    },
    onSuccess: () => {
      setEmptyData(trackerList);
    },
  });

  const { mutate: mutateDownloadTracker, isLoading: isDownloading } =
    useMutation(downloadTrackerAsFile, {
      onError: (error: any) => {
        ErrorHandler(error);
      },
      onSuccess: () => {},
    });

  const handleDownload = () => {
    mutateDownloadTracker({
      trackerType: selectedType,
      formatReport: selectedFormat,
      trackerDate: selectedDate,
    });
  };

  const generateColumn = () => {
    let columnData: object = [];
    if (selectedType === 'initiallogin') {
      columnData = [
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Initial Login',
          accessor: 'initialLogin',
        },
      ];
    } else if (selectedType === 'module/tracking') {
      columnData = [
        {
          Header: 'Module name',
          accessor: 'moduleName',
        },
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Time spent',
          accessor: 'timeSpent',
        },
      ];
    } else if (
      selectedType === 'useraccess/summary' ||
      selectedType === 'useraccess/detail'
    ) {
      columnData = [
        {
          Header: 'Username',
          accessor: 'username',
        },
        {
          Header: 'Unique access',
          accessor: 'numberOfUniqueAccess',
        },
        {
          Header: 'Time spent',
          accessor: 'timeSpent',
        },
      ];
    }
    return columnData;
  };

  const handleSubmit = (values: any) => {
    setEmptyData([]);
    mutateTrackerList({ ...values });
    setSelectedType(values?.trackerType);
    setSelectedDate(values?.dateFilter);
  };

  return (
    <TrackerComponent
      generateColumn={generateColumn}
      selectedType={selectedType}
      setSelectedFormat={setSelectedFormat}
      selectedFormat={selectedFormat}
      trackerList={trackerList}
      isLoading={isLoadingTracker}
      handleDownloadTracker={handleDownload}
      isDownloading={isDownloading}
      handleSubmit={handleSubmit}
      emptyData={emptyData}
      setEmptyData={setEmptyData}
    />
  );
}

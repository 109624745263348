import { Button, Col, Row } from 'antd';
import React from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import CButton from '../../../Assets/Components/CButton';
import CIcon from '../../../Assets/Components/CIcon';
import { InputText, InputTextNumber } from '../../../Assets/Components/CInput';
import { CInputSwitch } from '../../../Assets/Components/CInputSwitch';
import CModalRFF from '../../../Assets/Components/CModalRFF';
import { FormattedMessage } from 'react-intl';
import Select from '../../../Assets/Components/Select';
import CCard from '../../../Assets/Components/CCard';
import { FormApi } from 'final-form';
import validate from '../Validation/FeedbackBankValidation';

const defaultModalstyles = {
  content: {
    position: 'relative',
    background: 'none',
    maxWidth: '95vw',
    width: '85vw',
    padding: '0px',
    border: 'none',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  overlay: {
    background: 'rgba(0, 0, 0, 0.75)',
    zIndex: '99',
  },
};

interface IProps {
  channelList: any[];
  disabled: boolean;
  editMode: boolean;
  modalAction: string;
  showModal: boolean;
  isLoading: boolean;
  listMultipleChoiceQuestion: string[];
  listFreeTextQuestion: string[];
  handleCancel: () => void;
  handleSubmit: (values: any) => void;
  handleSubmitMultipleChoiceQuestion: (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleRemoveMultipleChoiceQuestion: (
    index: number,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleSubmitFreeTextQuestion: (
    values: any,
    form: FormApi<any, Partial<any>>
  ) => void;
  handleRemoveFreeTextQuestion: (
    index: number,
    form: FormApi<any, Partial<any>>
  ) => void;
  initialValues?: any;
}

export default function ModalSelfLearningComponent(props: IProps) {
  const {
    showModal,
    disabled,
    initialValues,
    handleCancel,
    isLoading,
    listMultipleChoiceQuestion,
    listFreeTextQuestion,
    handleSubmitMultipleChoiceQuestion,
    handleRemoveMultipleChoiceQuestion,
    handleSubmitFreeTextQuestion,
    handleRemoveFreeTextQuestion,
    editMode,
    modalAction,
    handleSubmit,
    channelList,
  } = props;

  const headerComponent = () => {
    return (
      <h3 className="headerTitle capital">
        {modalAction === 'update' ? (
          <FormattedMessage id="detailSelfLearning" />
        ) : (
          <FormattedMessage id="addSelfLearning" />
        )}
      </h3>
    );
  };

  const contentComponent = (propsContent: FormRenderProps<any, any>) => {
    const { values, form, errors } = propsContent;

    const renderCardFreeTextContent = () => {
      const dataButtonAddFreeTextQuestion = [
        {
          type: 'primary',
          id: 'btnAddFreeTextQuestion',
          className: 'btnAddMultipleChoiceQuestion',
          icon: 'PlusOutlined',
          content: 'Tambahkan',
          onClick: () =>
            handleSubmitFreeTextQuestion(values?.freeTextQuestionTitle, form),
          disabled:
            values &&
            values.freeTextQuestionTitle &&
            !errors?.freeTextQuestionTitle
              ? false
              : true,
        },
      ];
      return (
        <div>
          <Field
            name="freeTextQuestionTitle"
            component={InputText}
            label={'Question Title'}
            className="inputQuestionTitle"
            idComponent="inputQuestionTitle"
            placeholder="Type Here"
            disabled={disabled}
          />
          <CButton buttonData={dataButtonAddFreeTextQuestion} />
          <div className="containerMultipleChoiceQuestion">
            {listFreeTextQuestion.map((question, index) => {
              return (
                <div
                  className="containerItemListSubModule"
                  key={`${question}_${index}`}
                >
                  <button
                    className="btnDeleteItemList"
                    onClick={() => handleRemoveFreeTextQuestion(index, form)}
                    disabled={disabled}
                  >
                    <CIcon type={'DeleteOutlined'} />
                  </button>
                  <span className="itemNameSubModule">{question}</span>
                </div>
              );
            })}
          </div>
          <div className="containerTotalMultipleChoiceQuestion">
            <span>Total: {listFreeTextQuestion.length}</span>
          </div>
        </div>
      );
    };

    const renderFormAnswerList = totalItem => {
      const AnswerForm: any = [];
      for (let index = 0; index < totalItem; index++) {
        AnswerForm.push(
          <div className="containerAnswerItem">
            <Row>
              <Col span={2} className="spanAnswerFeedbackBank">
                <span>{`Jawaban ${index + 1}`}</span>
              </Col>
              <Col span={2}>
                <Field
                  name={`isActive${index}`}
                  component={CInputSwitch}
                  className="switchStatusFeedbackBank"
                  idComponent="inputAnswerFeedbackBank"
                  disabled={disabled}
                />
              </Col>
              <Col span={2} className="spanAnswerFeedbackBank">
                <span>
                  {values && values[`isActive${index}`]
                    ? 'Active'
                    : 'Not Active'}
                </span>
              </Col>
              <Col span={12}>
                <Field
                  name={`answer${index}`}
                  component={InputText}
                  className="inputAnswerFeedbackBank"
                  idComponent="inputAnswerFeedbackBank"
                  disabled={
                    disabled
                      ? true
                      : values && values[`isActive${index}`]
                      ? false
                      : true
                  }
                  placeholder="Type Here"
                />
              </Col>
              <Col className="spanPointFeedbackBank">
                <span>Point</span>
              </Col>
              <Col span={4}>
                <Field
                  name={`point${index}`}
                  component={InputTextNumber}
                  className="inputPointFeedbackBank"
                  idComponent="inputPointFeedbackBank"
                  disabled={
                    disabled
                      ? true
                      : values && values[`isActive${index}`]
                      ? false
                      : true
                  }
                />
              </Col>
            </Row>
          </div>
        );
      }
      return (
        <div className="containerAnswerFeedbackBank">
          <b>Answer List</b>
          {AnswerForm}
        </div>
      );
    };

    const renderCardMultipleChoiceContent = () => {
      const dataButtonAddMultipleChoiceQuestion = [
        {
          type: 'primary',
          id: 'btnAddMultipleChoiceQuestion',
          className: 'btnAddMultipleChoiceQuestion',
          icon: 'PlusOutlined',
          content: 'Tambahkan',
          onClick: () =>
            handleSubmitMultipleChoiceQuestion(
              values?.multipleQuestionTitle,
              form
            ),
          disabled:
            values &&
            values.multipleQuestionTitle &&
            !errors?.multipleQuestionTitle
              ? false
              : true,
        },
      ];

      return (
        <div>
          <Field
            name="multipleQuestionTitle"
            component={InputText}
            label={'Question Title'}
            className="inputQuestionTitle"
            idComponent="inputQuestionTitle"
            placeholder="Type Here"
            disabled={disabled}
          />
          <CButton buttonData={dataButtonAddMultipleChoiceQuestion} />
          <div className="containerMultipleChoiceQuestion">
            {listMultipleChoiceQuestion.map((question, index) => {
              return (
                <div
                  className="containerItemListSubModule"
                  key={`${question}_${index}`}
                >
                  <button
                    className="btnDeleteItemList"
                    onClick={() =>
                      handleRemoveMultipleChoiceQuestion(index, form)
                    }
                    disabled={disabled}
                  >
                    <CIcon type={'DeleteOutlined'} />
                  </button>
                  <span className="itemNameSubModule">{question}</span>
                </div>
              );
            })}
          </div>
          <div className="containerTotalMultipleChoiceQuestion">
            <span>Total: {listMultipleChoiceQuestion.length}</span>
          </div>
        </div>
      );
    };

    return (
      <>
        <Row>
          <Col span={12}>
            <div>
              <Field
                name="channel"
                component={Select}
                idComponent="inputChannelFeedbackBank"
                className="inputChannelFeedbackBank"
                label={'Channel'}
                defaultItemName="-Select-"
                dataOption={channelList}
                disabled={disabled}
              />
            </div>
          </Col>
          <Col span={8}>
            <div>
              <Field
                name="category"
                component={InputText}
                label={'Feedback Category'}
                className="inputFeedbackCategory"
                idComponent="inputFeedbackCategory"
                textArea={true}
                disabled={disabled}
              />
            </div>
          </Col>
        </Row>
        <CCard
          cardClassName={'cardMultipleChoiceFeedbackBank'}
          cardTitle={'Multiple Choice Question'}
          cardContent={renderCardMultipleChoiceContent()}
        />
        <hr className="lineModalFeedbackBank" />
        {renderFormAnswerList(6)}
        <CCard
          cardClassName={'cardFreeTextFeedbackBank'}
          cardTitle={'Free Text Question'}
          cardContent={renderCardFreeTextContent()}
        />
        <hr className="lineModalFeedbackBank" />
      </>
    );
  };

  const buttonContent = () => {
    if (editMode === false) {
      if (modalAction === 'register') {
        return 'Create';
      }
      return 'Edit';
    }
    return 'Save Change';
  };

  const handleDisabledSubmit = (action, invalid, editMode) => {
    if (action === 'register') {
      if (invalid || listMultipleChoiceQuestion.length === 0) {
        return true;
      }
      return false;
    } else {
      if (editMode === false) {
        return false;
      } else {
        if (invalid || listMultipleChoiceQuestion.length === 0) {
          return true;
        }
        return false;
      }
    }
  };

  const footerComponent = (propsFooter: FormRenderProps<any, any>) => {
    const { invalid } = propsFooter;
    return (
      <Button
        className={invalid ? 'btnSubmitDisabled' : 'btnSubmit'}
        icon={<CIcon type="CheckOutlined" />}
        id="btnSubmit"
        htmlType="submit"
        disabled={handleDisabledSubmit(modalAction, invalid, editMode)}
      >
        {buttonContent()}
      </Button>
    );
  };

  return (
    <CModalRFF
      modalIsShow={showModal}
      headerComponent={headerComponent()}
      contentComponent={formProps => contentComponent(formProps)}
      footerComponent={formProps => footerComponent(formProps)}
      handleCancel={handleCancel}
      handleValidation={(values: any) => validate(values)}
      isLoading={isLoading}
      modalstyles={defaultModalstyles}
      isForm={true}
      handleSubmit={handleSubmit}
      initialValues={initialValues}
    />
  );
}

const ChannelValidation = ({ code, name, headOfTrainer, signOffCaption }) => {
  const errors: any = {};
  if (!code) {
    errors.code = 'Channel Code Wajib Diisi!';
  } else if (code && !/^[A-Za-z0-9 ]+$/.test(code)) {
    errors.code = `Hanya boleh menginputkan huruf dan angka!`;
  } else if (code && !/^[^\s].*/.test(code)) {
    errors.code = 'Use letters/numbers at the beginning of words!';
  } else {
    if (code.length < 2) {
      errors.code = 'Channel Code Harus 2 Karakter!';
    }
  }
  if (!name) {
    errors.name = 'Channel Name Wajib Diisi';
  } else if (name && !/^[^\s].*/.test(name)) {
    errors.name = 'Use letters/numbers at the beginning of words!';
  }
  if (!headOfTrainer) {
    errors.headOfTrainer = 'Head of Trainer Wajib Diisi!';
  } else if (headOfTrainer && !/^[^\s].*/.test(headOfTrainer)) {
    errors.headOfTrainer = 'Use letters/numbers at the beginning of words!';
  }
  if (!signOffCaption) {
    errors.signOffCaption = 'Sign of Caption Wajib Diisi!';
  } else if (signOffCaption && !/^[^\s].*/.test(signOffCaption)) {
    errors.signOffCaption = 'Use letters/numbers at the beginning of words!';
  }

  return errors;
};
export default ChannelValidation;

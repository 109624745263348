const contestRewardValidation = (values: any, modalAction: string) => {
  const errors: any = {};
  if (!values.name) {
    errors.name = 'Reward name required!';
  }
  if (modalAction === 'register') {
    if (!values.rewardPicture || values.rewardPicture.length === 0) {
      errors.rewardPicture = 'Reward picture required!';
    }
  }
  return errors;
};

export default contestRewardValidation;
